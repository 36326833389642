// import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../login/login.service";
import { OnInit, ViewChild, ElementRef, Component, Injector } from "@angular/core";
import { AppComponent } from "../app.component";
import { FormBuilder } from "@angular/forms";
import { forEach } from "lodash";
import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
More(Highcharts);
import Drilldown from "highcharts/modules/drilldown";
Drilldown(Highcharts);
// Load the exporting module.
import Exporting from "highcharts/modules/exporting";
// Initialize exporting module.
Exporting(Highcharts);

// Import utility method class
import { Utility } from "../utility";
// Handle the global property
import { NotifierService } from "angular-notifier";
import { Globals } from "../globals";
import { Router, ActivatedRoute } from "@angular/router";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { AboutComponent } from "../about/about.component";
import { SystemConstants } from "../constants/system.constants";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  userData: any;
  userSlug: string = "";
  userRole: any = null;
  loginUserRole: any = null;
  dashboardDetail: any = {};
  totalCorporate = 0;
  totalExhib = 0;
  totalEvents = 0;
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  pieChartData: number[];
  // pieChartOptions: ChartOptions
  // pieChartLabels: Label[]
  @ViewChild("container", { read: ElementRef }) container: ElementRef;

  // console.log(pieChartData);
  // public pieChartType: ChartType = 'doughnut';
  public pieChartLegend = true;
  //public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [
        "rgba(255,0,0,0.3)",
        "rgba(0,255,0,0.3)",
        "rgba(0,0,255,0.3)",
      ],
    },
  ];
  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public globals: Globals,
    private loginService: LoginService,
    private utility: Utility,
    private injector: Injector
  ) { }

  ngOnInit() {

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   if (this.loginUserRole === 10) {

    //     this.router.navigate(["event"]);
    //   }
    //   if (this.loginUserRole === 20) {

    //     this.router.navigate(["profile"]);
    //   }
    //   if (this.loginUserRole === 0) {
    //     this.getAdminDashborad();
    //   }
    // }

    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.appComponent.userData;
          this.loginUserRole = this.userData.role;
          // if (this.loginUserRole === 10) {
          //   this.router.navigate(["event"]);
          // }
          // if (this.loginUserRole === 20) {
          //   this.router.navigate(["profile"]);
          // }
          if (this.loginUserRole === 0) {
            this.getAdminDashborad();
          }else {
            this.router.navigateByUrl('/home');
          }  
        } else {
          this.router.navigateByUrl('/');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //event redirect
  goToWithStatus(status: number, redirectUrl: any): any {
    this.authService.setEventStatus(status);

    this.router.navigate(["/" + redirectUrl]);
  }
  // exhibitor redirect
  /*
  goToExhibitor(status:number):any{
    this.authService.setEventStatus(status)
    this.router.navigate(['/exhibitors']);
  }
*/
  // Get getAdminDashborad
  getAdminDashborad(): any {
    const queryObj: any = {};
    this.loginService.findAdminDashBoard(queryObj).subscribe(
      (cuData) => {
        this.dashboardDetail = cuData;

        this.totalCountCalculation();
        //highchart start

        Highcharts.chart(this.container.nativeElement, {
          // Created pie chart using Highchart
          chart: {
            type: "pie",
            options3d: {
              enabled: false,
              alpha: 45,
            },
          },
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
            },
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:11px">{series.name}</span><br>',
            //  pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            pointFormat:
              '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>',
          },
          series: [
            {
              name: "Payment Management",
              data: [
                /*  {
                  name: 'Windows',
                  y: 88.19,
                  drilldown: 'windows-versions'
                },*/
                [
                  "Paid",
                  this.dashboardDetail.paidCounts
                    ? this.dashboardDetail.paidCounts.count
                    : 0,
                ],
                [
                  "Pending",
                  this.dashboardDetail.pendingCounts
                    ? this.dashboardDetail.pendingCounts.count
                    : 0,
                ],
                [
                  "Cancel Before Paid",
                  this.dashboardDetail.cancelBeforePaidCounts
                    ? this.dashboardDetail.cancelBeforePaidCounts.count
                    : 0,
                ],
                [
                  "Overdue",
                  this.dashboardDetail.overDueCounts
                    ? this.dashboardDetail.overDueCounts.count
                    : 0,
                ],
                [
                  "Unspecified",
                  this.dashboardDetail.unspecifiedCounts
                    ? this.dashboardDetail.unspecifiedCounts.count
                    : 0,
                ],
              ],
              type: undefined,
            },
          ],
        });
      },
      (err) => { }
    );
  }
  // Pie
  totalCountCalculation(): void {
    if (this.dashboardDetail && this.dashboardDetail.corporateData) {
      let counts = 0;
      forEach(this.dashboardDetail.corporateData, function (value) {
        counts = counts + value.count;
      });
      this.totalCorporate = counts;
    }

    if (this.dashboardDetail && this.dashboardDetail.exhibitorData) {
      let counts = 0;
      forEach(this.dashboardDetail.exhibitorData, function (value) {
        counts = counts + value.count;
      });
      this.totalExhib = counts;
    }
    if (this.dashboardDetail && this.dashboardDetail.eventData) {
      let counts = 0;
      forEach(this.dashboardDetail.eventData, function (value) {
        counts = counts + value.count;
      });
      this.totalEvents = counts;
    }
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  changeLabels() {
    const words = [
      "hen",
      "variable",
      "embryo",
      "instal",
      "pleasant",
      "physical",
      "bomber",
      "army",
      "add",
      "film",
      "conductor",
      "comfortable",
      "flourish",
      "establish",
      "circumstance",
      "chimney",
      "crack",
      "hall",
      "energy",
      "treat",
      "window",
      "shareholder",
      "division",
      "disk",
      "temptation",
      "chord",
      "left",
      "hospital",
      "beef",
      "patrol",
      "satisfied",
      "academy",
      "acceptance",
      "ivory",
      "aquarium",
      "building",
      "store",
      "replace",
      "language",
      "redeem",
      "honest",
      "intention",
      "silk",
      "opera",
      "sleep",
      "innocent",
      "ignore",
      "suite",
      "applaud",
      "funny",
    ];
    const randomWord = () => words[Math.trunc(Math.random() * words.length)];
    // this.pieChartLabels = Array.apply(null, { length: 3 }).map(_ => randomWord());
  }

  addSlice() {
    // this.pieChartLabels.push(['Line 1', 'Line 2', 'Line 3']);
    // this.pieChartData.push(400);
    // this.pieChartColors[0].backgroundColor.push('rgba(196,79,244,0.3)');
  }

  removeSlice() {
    // this.pieChartLabels.pop();
    // this.pieChartData.pop();
    // this.pieChartColors[0].backgroundColor.pop();
  }

  changeLegendPosition() {
    // this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
  }
  isUploadedImageCdnImage() {
    return this.userData.image.type !== SystemConstants.AVATAR;
  }
}
