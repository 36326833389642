<div *ngIf="tabName =='payment';" class="corporate-profile-hd"
  style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <h1> Payment Bundle</h1>
  </div>
</div>

<div class="top-padding exhibitor-event-payment-hd" *ngIf="tabName =='cardInfo';">
  <div class="event-detail-hd"
    style="background-image:url(/assets/images/paymentpage-bg.jpg); background-position: center bottom">
    <div class="event-bg-overly"></div>
    <div class="event-bg-overly-2"></div>
    <div class="container">
      <div class="payment-step-step">
        <ul>
          <!-- {{authorizeStep}} -->
          <li class="run" [ngClass]="{'run': authorizeStep === 'event-step-b-1', 'done' : authorizeStep === 'event-step-b-2',''    : authorizeStep === 'event-step-b-3'}"><i>1</i> Credit card Information</li>
          <li class="run"  [ngClass]="{ 'run' : authorizeStep === 'event-step-b-1','run': authorizeStep === 'event-step-b-2','run' : authorizeStep === 'event-step-b-3'}"><i>2</i> Customer Billing Information</li>
          <!-- <li class="run"  [ngClass]="{'done': authorizeStep === 'event-step-b-3', '' :    authorizeStep === 'event-step-b-1','run' : authorizeStep === 'event-step-b-2'}"><i>3</i> OrderInformation</li> -->
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- "{'my-class': step=='step1', 'my-class2':step=='step2' }" -->

<div class="container">
  <div *ngIf="tabName =='payment'; then paymentSectionTemp"></div>
</div>

<div class="container">
  <div *ngIf="tabName =='cardInfo'; then cardInfoTemplate"></div>
</div>

<ng-template #paymentSectionTemp>

  <!-- {{eventPaymentCheckOutListForPrint}} -->
  <div class="payment-bundle-page">
    <div class="container clearfix">
      <div class="payment-bundle-left">
        <div class="payment-history-box">
          <div class="payment-history-list">
            <div *ngFor="let rowItem of eventPaymentCheckOutList; let idx = index;" class="payment-history-row">
              <div class="payment-history-row-top clearfix">
                <div class="payment-date">
                  <strong>{{rowItem.eventDate | date: 'd MMM, yyyy' }}</strong>
                </div>
                <div class="payment-history-right">
                  <div class="box-close-icon"><a href="javascript:void(0)"
                      (click)="removeEvent(idx,rowItem.eventPayment)"><img
                        src="/assets/images/payment-history-box-close.png" alt=""></a> </div>
                  <div class="p-h-r-left">
                    <div class="p-h-r-i"><img src="/assets/images/recent-img-1.jpg" alt=""></div>
                    <div class="p-h-r-txt">
                      <h3>{{rowItem.eventName}}</h3>
                      <span>{{rowItem.corporateName}}</span>
                    </div>
                  </div>
                  <div class="pay-bonle-right">
                    <strong>${{rowItem.eventPayment}}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="total-payment-info">
              <strong>Total</strong> ${{total}}
            </div>
            <div class="payment-proceed-btn"
              *ngIf="eventPaymentCheckOutList && eventPaymentCheckOutList.length>0 && total > 0">
              <a href="javascript:void(0)" (click)="proceedToPay()">Proceeed to Pay ${{total}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</ng-template>

<ng-template #cardInfoTemplate>
  <div class="">
    <div class="event-pay-page new-pay-page">
      <div id="event-step-b-1" *ngIf="authorizeStep === 'event-step-b-1'">
        <div class="row">
          <div class="col-sm-4">
            <div class="event-pay-left">
              <i><img src="assets/images/exhibitor-pop-icon.png" alt=""></i>
              <div class="paymentevent-list">
                <h4>Event List</h4>
                <ul>

                  <li *ngFor="let localData of eventPaymentCheckOutList; let idx = index;">
                    <h6>{{localData.eventData.eventName}}</h6>
                    <div class="eventdate">{{localData.eventDate | date}}</div>
                    <div class="eventaddress">
                      {{localData.eventData.eventAddress.location ? localData.eventData.eventAddress.location : localData.eventData.eventAddress.additionLocation}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-8 bg__ww">
            <div class="event-pay-right">
              <div class="event-pay-info-icons">
                <i><img src="assets/images/pay-icon-1.png" alt=""></i>
                <div class="event-pay-info-icons-r">
                  <h2>Credit Card Information</h2>
                  <p>Aceepted payment method visa, mastercard, american express, discover</p>
                </div>
              </div>

              <div class="payment-for-event">
                <form [formGroup]="authorizeForm" class="authorize-container" (ngSubmit)="paymentSend()">

                  <div formGroupName="card">
                    <div class="hfp-input-div">
                      <label>Card Number <span class="span_star">*</span></label>
                      <input type="text" formControlName="cardNumber" cc-type="cardType"
                        placeholder="Credit Card Number" ccNumber class="card-nmr">
                      <div
                        *ngIf="authorizeForm.controls['card'].controls['cardNumber'].invalid && (authorizeForm.controls['card'].controls['cardNumber'].dirty || paymentSubmitted)"
                        class="custom_error">
                        <div *ngIf="authorizeForm.controls['card'].controls['cardNumber'].errors.required">Card Number
                          is required. </div>
                        <div
                          *ngIf="!authorizeForm.controls['card'].controls['cardNumber'].errors.required && authorizeForm.controls['card'].controls['cardNumber'].errors.ccNumber">
                          Invalid Card Number. </div>
                      </div>
                    </div>


                    <div class="hfp-input-div">
                      <label>Card Holder’s Name <span class="span_star">*</span></label>
                      <input type="text" value="Jhon Doe" placeholder="Card Holder’s Name" formControlName="fullName">
                      <div
                        *ngIf="authorizeForm.controls['card'].controls['fullName'].invalid && (authorizeForm.controls['card'].controls['fullName'].dirty || paymentSubmitted)"
                        class="custom_error">
                        <div *ngIf="authorizeForm.controls['card'].controls['fullName'].errors.required">Card Holder’s
                          is required. </div>
                        <div
                          *ngIf="!authorizeForm.controls['card'].controls['fullName'].errors.required && authorizeForm.controls['card'].controls['fullName'].errors.minlength">
                          Min length be 2. </div>

                      </div>
                    </div>



                    <div class="clearfix row exhibitors-row-2 new-cus-row">
                      <div class="col-sm-6 no-p-left">
                        <div class="hfp-input-div">
                          <label>Expiry Date<span class="span_star">*</span></label>
                          <input type="text" formControlName="expireAt" placeholder="MM/YY" ccExp>
                          <div
                            *ngIf="authorizeForm.controls['card'].controls['expireAt'].invalid && (authorizeForm.controls['card'].controls['expireAt'].dirty || paymentSubmitted)"
                            class="custom_error">
                            <div *ngIf="authorizeForm.controls['card'].controls['expireAt'].errors.required">Expiry
                              date is required. </div>
                            <div
                              *ngIf="!authorizeForm.controls['card'].controls['expireAt'].errors.required && authorizeForm.controls['card'].controls['expireAt'].errors.expDate">
                              Invalid expiry date. </div>
                            <div *ngIf="!authorizeForm.controls['card'].controls['expireAt'].errors.required
                                && !authorizeForm.controls['card'].controls['expireAt'].errors.minlength
                                && authorizeForm.controls['card'].controls['expireAt'].errors.maxlength">
                              Valid format is YY. </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-sm-3">
                        <div class="hfp-input-div">
                          <label>CVV<span class="span_star">*</span></label>

                          <input type="text" formControlName="cvv" placeholder="CVV" ccCVC>
                          <div
                            *ngIf="authorizeForm.controls['card'].controls['cvv'].invalid && (authorizeForm.controls['card'].controls['cvv'].dirty || paymentSubmitted)"
                            class="custom_error">
                            <div *ngIf="authorizeForm.controls['card'].controls['cvv'].errors.required">CVV number is
                              required. </div>
                            <div
                              *ngIf="!authorizeForm.controls['card'].controls['cvv'].errors.required && authorizeForm.controls['card'].controls['cvv'].errors.minlength">
                              Min length should be 3. </div>
                            <div
                              *ngIf="!authorizeForm.controls['card'].controls['cvv'].errors.required && !authorizeForm.controls['card'].controls['cvv'].errors.minlength && authorizeForm.controls['card'].controls['cvv'].errors.maxlength">
                              Max length should be 4. </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-sm-3 no-p-right">
                        <div class="hfp-input-div">
                          <label>Amount&nbsp;($)</label>
                          <input type="text" value="50" placeholder="Amount" readonly formControlName="amount">
                          <div *ngIf="authorizeForm.controls['card'].controls['amount'].invalid
                            && (authorizeForm.controls['card'].controls['amount'].dirty || paymentSubmitted)"
                            class="custom_error">
                            <div *ngIf="authorizeForm.controls['card'].controls['amount'].errors.required">Amount is
                              required. </div>
                            <div
                              *ngIf="!authorizeForm.controls['card'].controls['amount'].errors.required && authorizeForm.controls['card'].controls['amount'].errors.min">
                              Minimum number should be 1. </div>

                          </div>
                        </div>


                      </div>

                    </div>




                    <div class="event-buttons clearfix">
                      <a  class="refund-policy-link" routerLink="/refund-policy" href="javascript:void(0)" target="_blank">Refund Policy</a>
                      <button type="submit" class="next-btn button-css">Next <i></i></button>
                      <!-- <a href="javascript:void(0)" id="next-b-1" class="btn btn-default next-btn">Next <i><img
                                        src="assets/images/next-icon.png" alt=""></i></a>-->

                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>

        </div>
      </div>
      <!--second form-->
      <div id="event-step-b-2" *ngIf="authorizeStep === 'event-step-b-2'">
        <div class="row">
          <div class="col-sm-4">
            <div class="event-pay-left">
              <i><img src="assets/images/exhibitor-pop-icon.png" alt=""></i>
              <div class="paymentevent-list">
                <h4>Event List</h4>
                <ul>

                  <li *ngFor="let localData of eventPaymentCheckOutList; let idx = index;">
                    <h6>{{localData.eventData.eventName}}</h6>
                    <div class="eventdate">{{localData.eventDate | date}}</div>
                    <div class="eventaddress">
                      {{localData.eventData.eventAddress.location ? localData.eventData.eventAddress.location : localData.eventData.eventAddress.additionLocation}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="event-pay-right">
              <div class="event-pay-info-icons">
                <i><img src="assets/images/pay-icon-2.png" alt=""></i>
                <div class="event-pay-info-icons-r">
                  <h2>CUSTOMER BILLING INFORMATION</h2>
                </div>
              </div>
              <div class="payment-for-event">
                <form [formGroup]="userInfoForm" class="authorize-container" (ngSubmit)="finalPaymentSubmitFormWithUserInfo()">
                  <div formGroupName="users">

                    <div class="hfp-input-div">
                      <label>First Name<span class="span_star">*</span></label>
                      <input type="text" formControlName="firstName" placeholder="First Name">
                    </div>

                    <div *ngIf="userInfoForm.controls['users'].controls['firstName'].invalid && userBillingSubmitted"
                      class="custom_error">
                      <div *ngIf="userInfoForm.controls['users'].controls['firstName'].errors.required">First Name
                        is required. </div>
                    </div>


                    <div class="hfp-input-div">
                      <label>Last Name<span class="span_star">*</span></label>
                      <input type="text" formControlName="lastName" placeholder="Last Name">
                    </div>

                    <div *ngIf="userInfoForm.controls['users'].controls['lastName'].invalid
                                                      &&  userBillingSubmitted" class="custom_error">
                      <div *ngIf="userInfoForm.controls['users'].controls['lastName'].errors.required">Last Name
                        is required. </div>
                    </div>

                    <div class="hfp-input-div">
                      <label>Company<span class="span_star">*</span></label>
                      <input type="text" formControlName="company" placeholder="Company">
                    </div>

                    <div class="hfp-input-div">
                      <label>Address<span class="span_star">*</span></label>
                      <!--  <textarea class="address-txt" placeholder="Address" formControlName="address"
                                myNoSpaces #search> </textarea>-->
                      <input id="autocomplete1" placeholder="Address" formControlName="address" name="address"
                        myNoSpaces #search class="input-field" type="text" required>



                    </div>
                    <div *ngIf="userInfoForm.controls['users'].controls['address'].invalid
                                                      && userBillingSubmitted"
                      class="custom_error">
                      <div *ngIf="userInfoForm.controls['users'].controls['address'].errors.required">Address
                        is required. </div>
                    </div>


                    <div class="hfp-input-div">
                      <label>City<span class="span_star">*</span></label>
                      <input class="" type="text" name="city" placeholder="City" formControlName="city">
                    </div>

                    <div
                      *ngIf="userInfoForm.controls['users'].controls['city'].invalid
                                                                                &&  userBillingSubmitted"
                      class="custom_error">
                      <div *ngIf="userInfoForm.controls['users'].controls['city'].errors.required">City
                        is required. </div>
                    </div>

                    <div class="clearfix row exhibitors-row-2 new-cus-row">
                      <div class="col-sm-6 no-p-left">
                        <div class="hfp-input-div">
                          <label>State/Province<span class="span_star">*</span></label>
                          <input name="state" formControlName="state" placeholder="State/Province" class="input-field"
                            type="text" required>
                          <!-- <select class="hfp-selectbox" formControlName="state">
                                      <option>VA</option>
                                    </select> -->
                        </div>

                        <div *ngIf="userInfoForm.controls['users'].controls['state'].invalid &&  userBillingSubmitted"
                          class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['state'].errors.required">State
                            is required. </div>
                        </div>


                      </div>
                      <div class="col-sm-6 no-p-right">
                        <div class="hfp-input-div">
                          <label>Zip Code<span class="span_star">*</span></label>
                          <input type="text" placeholder="Zip Code" name="zipcode" formControlName="zipcode">
                        </div>
                        <div *ngIf="userInfoForm.controls['users'].controls['zipcode'].invalid && userBillingSubmitted" class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['zipcode'].errors.required">Zip Code
                            is required. </div>
                        </div>


                      </div>
                    </div>
                    <div class="hfp-input-div">
                      <label>Country<span class="span_star">*</span></label>
                      <input class="" type="text" placeholder="Country" name="country" formControlName="country">
                    </div>
                    <div
                      *ngIf="userInfoForm.controls['users'].controls['country'].invalid &&  userBillingSubmitted"
                      class="custom_error">
                      <div *ngIf="userInfoForm.controls['users'].controls['country'].errors.required">Country
                        is required. </div>
                    </div>

                    <div class="clearfix row exhibitors-row-2 new-cus-row">
                      <div class="col-sm-6 no-p-left">
                        <div class="hfp-input-div">
                          <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
                          <app-phone-number [phoneNumberFormControlName]="'phone'"
                          [phoneNumberFormGroup]="phone.parent"></app-phone-number>
                        </div>
                        <div *ngIf="phone.invalid && userBillingSubmitted" class="custom_error">
                          <div *ngIf="phone.errors.required"> {{
                            'REQUIRED_MSG_WITH_PARAMS' |
                            translate: {'name': 'Phone Number' | translate}
                            }} </div>
                          <div *ngIf="phone.errors.invalidNumber">{{translateJson.NOT_A_VALID_PHONE |
                            translate}}</div>
                        </div>
                        <!-- <div class="hfp-input-div">
                          <label>Phone<span class="span_star">*</span></label>
                          <input type="text" placeholder="Phone" formControlName="phone">
                        </div> -->

                        <!-- <div *ngIf="userInfoForm.controls['users'].controls['phone'].invalid
                              &&  userBillingSubmitted" class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['phone'].errors.required">Phone
                            is required. </div>
                          <div *ngIf="!userInfoForm.controls['users'].controls['phone'].errors.required
                                      && userInfoForm.controls['users'].controls['phone'].errors.minlength">
                            Phone number invalid. Shoud be 10. </div>
                          <div *ngIf="!userInfoForm.controls['users'].controls['phone'].errors.required
                                      && !userInfoForm.controls['users'].controls['phone'].errors.minlength
                                       && userInfoForm.controls['users'].controls['phone'].errors.maxlength">
                            Phone number invalid. Shoud not be greater then 16 </div>
                        </div> -->
                      </div>
                      <div class="col-sm-6 no-p-right">
                        <div class="hfp-input-div">
                          <label>Email<span class="span_star">*</span></label>
                          <input class="" type="email" placeholder="Email" formControlName="email">
                        </div>
                        <div *ngIf="userInfoForm.controls['users'].controls['email'].invalid
                                                                     && userBillingSubmitted" class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['email'].errors.required">Email
                            is required. </div>
                          <div *ngIf="userInfoForm.controls['users'].controls['email'].errors.email">
                            Email must be a valid email address
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="event-buttons clearfix">
                      <a href="javascript:void(0)" id="pre-b-2" (click)="backInintFirstForm()"
                        class="btn btn-default pre-btn"><img src="assets/images/prv-icon.png" alt=""> Previous
                        <i></i></a>
                        <button type="submit" class="btn btn-default next-btn">{{translateJson.SUBMIT | translate}} </button>

                      <!-- <button type="submit" class="btn btn-default next-btn">Next <i><img
                            src="assets/images/next-icon.png" alt=""></i></button> -->
                      <!-- <a href="javascript:void(0)" id="next-b-2" class="btn btn-default next-btn">Next <i><img
                                      src="assets/images/next-icon.png" alt=""></i></a> -->

                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>




      </div>
      <!--Third form-->
      <!-- <div id="event-step-b-3" *ngIf="authorizeStep === 'event-step-b-3'">
        <div class="row">
          <div class="col-sm-4">
            <div class="event-pay-left">
              <i><img src="assets/images/exhibitor-pop-icon.png" alt=""></i>
              <div class="paymentevent-list">
                <h4>Event List</h4>
                <ul>

                  <li *ngFor="let localData of eventPaymentCheckOutList; let idx = index;">
                    <h6>{{localData.eventData.eventName}}</h6>
                    <div class="eventdate">{{localData.eventDate | date}}</div>
                    <div class="eventaddress">
                      {{localData.eventData.eventAddress.location ? localData.eventData.eventAddress.location : localData.eventData.eventAddress.additionLocation}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="event-pay-right">
              <div class="event-pay-info-icons">
                <i><img src="assets/images/pay-icon-3.png" alt=""></i>
                <div class="event-pay-info-icons-r">
                  <h2>ORDER INFORMATION</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam metus leo.</p>
                </div>
              </div>
              <div class="payment-for-event">
                <form [formGroup]="step3form" class="authorize-container" (ngSubmit)="finalSubmitForm(); isFormSubmitted= true">
                  <div formGroupName="orderDesc">
                    <div class="hfp-input-div">
                      <label>Description</label>
                      <textarea placeholder="Description" formControlName="description"></textarea>
                    </div>

                    <div
                      *ngIf="step3form.controls['orderDesc'].controls['description'].invalid
                                &&  finalformgSubmitted"
                      class="custom_error">
                      <div *ngIf="step3form.controls['orderDesc'].controls['description'].errors.required">
                        Description
                        is required. </div>
                    </div>

                    <div class="event-buttons clearfix">
                      <a href="javascript:void(0)" id="pre-b-2" (click)="backInintSecondForm()"
                        class="btn btn-default pre-btn">
                        <img src="assets/images/prv-icon.png" alt="">
                        Previous <i></i></a>
                      <button type="submit" [disabled]="isFormSubmitted"  class="btn btn-default next-btn">{{translateJson.SUBMIT | translate}} </button>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</ng-template>
