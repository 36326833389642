import { Component, OnInit, ComponentFactoryResolver, Injector } from '@angular/core';
import { SharedService } from '../shared.service';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { ProfileService } from '../profile/profile.service';
import { AppComponent } from '../app.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { NotifierService } from 'angular-notifier';
import { Globals } from '../globals';
import { EventService } from '../event/event.service';
import * as moment from 'moment';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { ThemeService } from 'ng2-charts';
import { inArray } from 'highcharts';
import { __values } from 'tslib';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { AboutComponent } from '../about/about.component';
import { SystemConstants } from '../constants/system.constants';
import * as TranslatorJson from '../../assets/i18n/en.json'
import { CommonStatus } from '../constants/common-status.constants';

@Component({
  selector: 'app-payment-management',
  templateUrl: './payment-management.component.html',
  styleUrls: ['./payment-management.component.scss'],
  providers: [DatePipe]
})
export class PaymentManagementComponent implements OnInit {
  paymentSearch: number = -1;
  someArray: any = [];
  eventPaymentPendingData: any = [];
  eventPaymentCheckOutList: any = [];
  eventAllDataValue: any = [];
  userData: any;
  userSlug: string = '';
  pendingChildIndex: number;
  userRole: any = null;
  loginUserRole: any = null;
  eventReqAttributes: any = {};
  eventCancelBeforePaidAttributes: any = {};
  eventPaidButCancelAttributes: any = {};
  paymentSpecified: any = [
    { name: 'Unpaid', value: 0 },
    { name: 'Paid', value: 1 },
    { name: 'Cancelled before paid', value: 2 },
    { name: 'Paid before they cancelled', value: 3 },

  ];
  paymentType: any = [
    { lable: 'Register', value: 2 },
    { lable: 'Event', value: 1 },
  ];

  shoPaymentListIndx: number;
  userListOfEventReq: any = [];
  eventSelectData: any = [];
  showExhibStatus = false;
  showEventListIndx: number;
  showExhibListIndx: number;
  eventSingleItem: any = {};
  userSingleItem: any = {};
  eventPaymentManageForm: FormGroup;
  eventPaymentManageFormPaid: FormGroup;
  eventPaymentManageFormPending: FormGroup;
  private notifier: NotifierService;
  paymentManageCounts: any = {};
  activeTabs = 1;
  prodList: Array<string | number>;
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  contactForm: FormGroup;
  modalRef: BsModalRef;
  paymentManageAttributes: any = {};
  paymentPendingAttributes: any = {};
  paymentOverDueAttributes: any = {};
  eventCancelBeforePaid: any = {};
  tempEventData: any
  paymentManageSearchForm: FormGroup;
  productIndex: number;

  paidPaymentFixCount: number = 0;
  pendingPaymentFixCount: number = 0;
  overDueFixCount: number = 0;
  paymentUnspecifiedFixCount: number = 0;
  eventStatus: any;
  systemConstants = SystemConstants;
  translatorJson: typeof TranslatorJson;

  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private appComponent: AppComponent,
    private router: Router,
    private injecotr: Injector,
    private route: ActivatedRoute,
    private authService: AuthService,
    private profileService: ProfileService,
    public globals: Globals,
    private loginService: LoginService,
    private eventService: EventService,
    private modalService: BsModalService,
    private utility: Utility,
    private datePipe: DatePipe,
  ) {
    this.notifier = notifierService;
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  ngOnInit() {
    // This  is event Inq  seach form
    this.eventPaymentManageForm = this.fb.group({
      eventName: ['', [Validators.required]],
      corporateName: ['', [Validators.required]],
      exhibitorName: ['', [Validators.required]],
      eventDate: ['', [Validators.required]],
      //  eventImg: ['', [Validators.required]],
      eventPayment: ['', [Validators.required]],
      paymentStatus: [0, [Validators.required]],
      status: [1, [Validators.required]],
    });

    this.eventPaymentManageFormPaid = this.fb.group({
      invoiceNumber: ['', [Validators.required]],
      created: ['', [Validators.required]],
      //  paymentAmount: ['', [Validators.required]],
    });
    this.eventPaymentManageFormPending = this.fb.group({
      eventDate: ['', []],
      //  paymentStatus: ['', [Validators.required]],
      eventPayment: ['', [Validators.required]],
    });
    //contact form init
    this.contactForm = this.fb.group({
      eventName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
      subject: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
      emailId: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });

    // This is payment Manage Search
    this.paymentManageSearchForm = this.fb.group({
      searchField: ['', []],
      eventName: ['', []],
      paymentType: ['', []],
    });

    // Copy object
    this.eventReqAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    // Copy object
    this.paymentManageAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    // Copy object
    this.paymentPendingAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    this.paymentOverDueAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    // Copy object
    this.eventCancelBeforePaidAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    this.eventPaidButCancelAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // if (this.injecotr.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.userData.role;
    //   if (this.loginUserRole === 0) {

    //     this.eventStatus = this.authService.getEventStatus();
    //     if (this.eventStatus && this.eventStatus >= 0) {
    //       this.activeTabs = this.eventStatus;
    //       this.getListCount();
    //       if (this.activeTabs === 1) {
    //         forkJoin([this.paidEventsList(this.paymentManageAttributes.limits, 0), this.paidEventsCountForSearch()]);
    //       } else if (this.activeTabs === 2) {
    //         forkJoin([this.getPendingPaymentUserList(this.paymentManageAttributes.limits, 0), this.getCountsPaymentPendingUserListSearch()]);
    //       } else if (this.activeTabs === 3) {
    //         forkJoin([this.getListEventCancelBeforePaid(this.paymentManageAttributes.limits, 0), this.getListCountsEventCancelBeforePaid()]);
    //       }
    //       else if (this.activeTabs === 7) {
    //         forkJoin([this.getListEventPaidButCancel(this.paymentManageAttributes.limits, 0),
    //         this.getListCountsEventPaidButCancel()]);
    //       }
    //       else if (this.activeTabs === 4) {
    //         forkJoin([this.getOverDueEventUserList(this.paymentManageAttributes.limits, 0), this.getCountsOverDueEventUserListSearch()]);
    //       } else if (this.activeTabs === 6) {
    //         forkJoin([this.getEventListForUnspecifiedPayment(this.paymentManageAttributes.limits, 0), this.getEventListCountsUnspecifiedPaymentSearch()]);
    //       }


    //     } else {
    //       this.getListCount();
    //     }

    //   }
    //   else {
    //     this.router.navigateByUrl('/home');
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }


    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        if (this.loginUserRole === 0) {

          this.eventStatus = this.authService.getEventStatus();
          if (this.eventStatus && this.eventStatus >= 0) {
            this.activeTabs = this.eventStatus;
            this.getListCount();
            if (this.activeTabs === 1) {
              forkJoin([this.paidEventsList(this.paymentManageAttributes.limits, 0), this.paidEventsCountForSearch()]);
            } else if (this.activeTabs === 2) {
              forkJoin([this.getPendingPaymentUserList(this.paymentManageAttributes.limits, 0), this.getCountsPaymentPendingUserListSearch()]);
            } else if (this.activeTabs === 3) {
              forkJoin([this.getListEventCancelBeforePaid(this.paymentManageAttributes.limits, 0), this.getListCountsEventCancelBeforePaid()]);
            }
            else if (this.activeTabs === 7) {
              forkJoin([this.getListEventPaidButCancel(this.paymentManageAttributes.limits, 0),
              this.getListCountsEventPaidButCancel()]);
            }
            else if (this.activeTabs === 4) {
              forkJoin([this.getOverDueEventUserList(this.paymentManageAttributes.limits, 0), this.getCountsOverDueEventUserListSearch()]);
            } else if (this.activeTabs === 6) {
              forkJoin([this.getEventListForUnspecifiedPayment(this.paymentManageAttributes.limits, 0), this.getEventListCountsUnspecifiedPaymentSearch()]);
            }


          } else {
            this.getListCount();
          }

        }
        else {
          this.router.navigateByUrl('/home');
        }
      } else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });


  }



  /**
   * Get list and counts . By default Payment Unspecified List will be launch
   */
  getListCount(): void {
    forkJoin([this.paidEventsCounts(), this.getCountsPaymentPendingUserList(), this.getCountsOverDueEventUserList(),
    this.getListCountsEventCancelBeforePaid(), this.getListCountsEventPaidButCancel(), this.getEventListCountsUnspecifiedPayment(),
    this.paidEventsList(this.eventReqAttributes.limits, this.eventReqAttributes.skips)]);
  }
  /**
   * This function is used search according to filter.
   */
  paymentManageSearch(): void {
    this.paymentSearch = 1;
    if (this.activeTabs === 1) {
      forkJoin([this.paidEventsList(this.paymentManageAttributes.limits, 0), this.paidEventsCountForSearch()]);
    } else if (this.activeTabs === 2) {
      forkJoin([this.getPendingPaymentUserList(this.paymentManageAttributes.limits, 0), this.getCountsPaymentPendingUserListSearch()]);
    } else if (this.activeTabs === 3) {
      forkJoin([this.getListEventCancelBeforePaid(this.paymentManageAttributes.limits, 0), this.getListCountsEventCancelBeforePaid()]);
    } else if (this.activeTabs === 7) {
      forkJoin([this.getListEventPaidButCancel(this.paymentManageAttributes.limits, 0), this.getListCountsEventPaidButCancel()]);
    } else if (this.activeTabs === 4) {
      forkJoin([this.getOverDueEventUserList(this.paymentManageAttributes.limits, 0), this.getCountsOverDueEventUserListSearch()]);
    } else if (this.activeTabs === 6) {
      forkJoin([this.getEventListForUnspecifiedPayment(this.paymentManageAttributes.limits, 0), this.getEventListCountsUnspecifiedPaymentSearchData()]);
    }
  }




  /**
   * This funcntion is used to add payment for payment unspecified users and then data will save
   */
  addPaymentFoEvent(): void {
    //  console.log(this.eventPaymentManageForm);
    //  console.log(this.userSingleItem,this.eventSingleItem);
    // return
    if (_.isUndefined(this.eventPaymentManageForm.value.exhibitorName)) {
      this.eventPaymentManageForm.controls['exhibitorName'].setValue(this.userSingleItem.username);
    }
    if (this.eventSingleItem.media && this.eventSingleItem.media.length) {
      // this.eventPaymentManageForm.controls['eventImg'].setValue(this.eventSingleItem.media[0]);
    }
    if (this.eventPaymentManageForm.valid) {
      var obj = this.eventPaymentManageForm.value;
      if (this.userSingleItem.id && this.userSingleItem.eventReqData) {

        obj.eventId = this.userSingleItem.eventReqData.localEventId;
        obj.reqId = this.userSingleItem.eventReqData.id;
        obj.memberId = this.userSingleItem.id;
        //console.log(this.eventPaymentManageForm.value);
        //email start
        let temEmail: any = {};
        temEmail.userId = this.eventPaymentManageForm.value.memberId;
        temEmail.slug = 'event-fill-amount-payment-template';

        temEmail.keys = {
          // Name: this.registerData.Name,
          // USERNAME:this.registerData.yourname,
          //  name: this.signUpForm.value.name,
          EVENT_PAYMENT: this.eventPaymentManageForm.value.eventPayment,
          EXHIBITOR_NAME: this.eventPaymentManageForm.value.exhibitorName,
          EVENT: this.eventPaymentManageForm.value.eventName,

          EVENT_DATE: this.datePipe.transform(this.eventSelectData.eventDate, 'MMM d, y'),
          EVENT_START_TIME: this.datePipe.transform(this.eventSelectData.startDateTime, 'h:mm a'),
          EVENT_ADDRESS: this.eventSelectData.eventAddress.location ? this.eventSelectData.eventAddress.location : this.eventSelectData.eventAddress.formatted_address,
          TIME_ZONE: this.eventSelectData && this.eventSelectData.timeZone && this.eventSelectData.timeZone.label ? this.eventSelectData.timeZone.label : 'NA'
        };

        this.emailSends(temEmail);
        this.setEventpaymentForExhib();


        if (this.eventPaymentManageForm.value.paymentStatus == 2) {
          const queryObj: any = { status: 2, paymentSpecified: 1 };
          this.eventService.updateEventReqById(this.userSingleItem.eventReqData.id, queryObj)
            .subscribe(data => {
              this.getPendingPaymentUserList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.getCountsPaymentPendingUserList() 
            }, (err) => {
              if (err && err.error && err.error.error && err.error.error.message) {
              }
            });
        }



        this.eventPaymentManageForm.reset();
      }
    } else {
      console.log(this.eventPaymentManageForm);
      this.notifier.notify('error', "Please fill form carefully.");
    }
  }
  /**
 * This funcntion is used to add payment for payment unspecified users and then data will save
 */
  addPaymentFoEventOverDue(): void {
    console.log(this.eventPaymentManageForm);
    console.log(this.userSingleItem, this.eventSingleItem);
    // return
    if (_.isUndefined(this.eventPaymentManageForm.value.exhibitorName)) {
      this.eventPaymentManageForm.controls['exhibitorName'].setValue(this.userSingleItem.username);
    }
    if (this.eventSingleItem.media && this.eventSingleItem.media.length) {
      // this.eventPaymentManageForm.controls['eventImg'].setValue(this.eventSingleItem.media[0]);
    }
    if (this.eventPaymentManageForm.valid) {
      var obj = this.eventPaymentManageForm.value;
      if (this.eventSingleItem._id) {

        obj.eventId = this.eventSingleItem.eventId;
        obj.reqId = this.eventSingleItem.reqId;
        obj.memberId = this.eventSingleItem.memberId;
        //console.log(this.eventPaymentManageForm.value);
        //email start
        let temEmail: any = {};
        temEmail.userId = this.eventSingleItem.memberId;
        temEmail.slug = 'event-fill-amount-payment-template';

        temEmail.keys = {
          // Name: this.registerData.Name,
          // USERNAME:this.registerData.yourname,
          //  name: this.signUpForm.value.name,
          EVENT_PAYMENT: this.eventPaymentManageForm.value.eventPayment,
          EXHIBITOR_NAME: this.eventPaymentManageForm.value.exhibitorName,
          EVENT: this.eventPaymentManageForm.value.eventName,

          EVENT_DATE: this.datePipe.transform(this.eventSingleItem.eventData[0].eventDate, 'MMM d, y'),
          EVENT_START_TIME: this.datePipe.transform(this.eventSingleItem.eventData[0].startDateTime, 'h:mm a'),
          EVENT_ADDRESS: this.eventSingleItem.eventData[0].eventAddress.location ? this.eventSingleItem.eventData[0].eventAddress.location : this.eventSingleItem.eventData[0].eventAddress.formatted_address,
          TIME_ZONE: this.eventSingleItem.eventData[0] && this.eventSingleItem.eventData[0].timeZone && this.eventSingleItem.eventData[0].timeZone.label ? this.eventSingleItem.eventData[0].timeZone.label : 'NA'
        };

        this.emailSends(temEmail);
        this.setEventpaymentForExhibOverDue();
        this.shoPaymentListIndx = -1;


        if (this.eventPaymentManageForm.value.paymentStatus == 2) {
          const queryObj: any = { status: 2, paymentSpecified: 1, eventType: 'paid' };
          this.eventService.updateEventReqById(this.eventSingleItem.reqId, queryObj)
            .subscribe(data => {
              //  this.notifier.notify('success', 'Done.');
            }, (err) => {
              if (err && err.error && err.error.error && err.error.error.message) {
                // this.notifier.notify('error', err.error.error.message);
              }
            });
        }
        const queryObj: any = { eventPayment: this.eventPaymentManageForm.value.eventPayment, };
        this.eventService.updatePaymentById(this.eventSingleItem._id, queryObj)
          .subscribe(data => {
            //  this.notifier.notify('success', 'Done.');
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              // this.notifier.notify('error', err.error.error.message);
            }
          });



        this.eventPaymentManageForm.reset();
      }
    } else {
      console.log(this.eventPaymentManageForm);
      this.notifier.notify('error', "Please fill form carefully.");
    }
  }
  addPaymentFoEventPending(): void {
    // console.log(this.eventPaymentManageForm);
    // console.log(this.userSingleItem, this.eventSingleItem);

    if (this.eventPaymentManageFormPending.valid) {
      var obj = this.eventPaymentManageFormPending.value;

      this.shoPaymentListIndx = -1;



      const queryObj: any = { eventPayment: this.eventPaymentManageFormPending.value.eventPayment, };
      this.eventService.updatePaymentById(this.eventSingleItem._id, queryObj)
        .subscribe(data => {

          this.showExhibListIndx = -1;
          this.notifier.notify('success', 'Updated Successful.');
          this.paymentPendingAttributes.pageItems[this.productIndex]['eventPaymentPendingData'][this.pendingChildIndex].eventPayment = obj.eventPayment;

        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            // this.notifier.notify('error', err.error.error.message);
          }
        });



      this.eventPaymentManageFormPending.reset();
    }
    else {
      console.log(this.eventPaymentManageFormPending);
      this.notifier.notify('error', "Please fill form carefully.");
    }
  }
  addPaymentFoEventPaid(): void {

    // console.log("aa");
    // console.log(this.eventPaymentManageFormPaid.value);
    // console.log(this.userSingleItem, this.eventSingleItem);
    // return

    if (this.eventPaymentManageFormPaid.valid) {
      var obj = this.eventPaymentManageFormPaid.value;
      if (this.eventSingleItem._id) {



        this.showExhibListIndx = -1;
        //productIndex
        const queryObj: any = {
          invoiceNumber: this.eventPaymentManageFormPaid.value.invoiceNumber,
          //  paymentAmount: this.eventPaymentManageFormPaid.value.paymentAmount,
          // prodList[0]:"a"
          //   indexes:this.productIndex
        };


        this.eventService.updatePaidPaymentById(this.eventSingleItem._id, queryObj)
          .subscribe(data => {
            this.notifier.notify('success', 'Succesfully updated.');
            this.paymentManageSearch();
            //  this.paymentManageAttributes.pageItems[this.showExhibListIndx].invoiceNumber=this.eventPaymentManageFormPaid.value.invoiceNumber;
            //  this.paymentManageAttributes.pageItems[this.showExhibListIndx].paymentAmount=this.eventPaymentManageFormPaid.value.paymentAmount;
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              // this.notifier.notify('error', err.error.error.message);
            }
          });



        this.eventPaymentManageFormPaid.reset();
      }
    } else {
      console.log(this.eventPaymentManageFormPaid);
      this.notifier.notify('error', "Please fill form carefully. All fields are required.");
    }
  }
  removeEventName(): void {
    this.paymentManageSearchForm.get('searchField').setValue("");
    this.paymentManageSearch();
  }
  removeEvent(): void {
    this.paymentManageSearchForm.get('eventName').setValue("");
    this.paymentManageSearch();
  }


  emailSends(sendObj: any) {
    this.eventService.sendEmail(sendObj)
      .subscribe(data => {
        // console.log('email send success');
        console.log(data);
      }, (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      });
  }


  /**
   * this function is used to get list of event for payment Unspecified
   * @param status number
   */
  getEventListForUnspecifiedPayment(limitNum: number, skipNum: number): void {
    const queryObj: any = { status: 1, paymentSpecified: 0, eventType: 'paid', limit: limitNum, skip: skipNum };
    if (this.paymentSearch == 0) {
      this.paymentManageSearchForm.reset();
    }
    // This statement for search

    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.eventListForPaymentUnspecified(queryObj)
      .subscribe(data => {

        this.eventReqAttributes.unspecifiedData = (data && data.length) ? data : [];
        console.log("I am in...by a", this.eventReqAttributes.unspecifiedData);
      }, (err) => { });
  }

  getEventListCountsUnspecifiedPayment(): void {
    const queryObj: any = { paymentSpecified: 0, eventType: 'paid', status: 1 };
    // This statement for search

    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }

    this.eventService.countsPaymentUnspecifiedListData(queryObj)
      .subscribe(data => {
        this.paymentUnspecifiedFixCount = (data && data.count) ? data.count : 0;
        this.eventReqAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }
  getEventListCountsUnspecifiedPaymentSearch(): void {
    const queryObj: any = { paymentSpecified: 0, eventType: 'paid', status: 1 };
    // This statement for search

    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.countsPaymentUnspecifiedListData(queryObj)
      .subscribe(data => {
        this.eventReqAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }

  getEventListCountsUnspecifiedPaymentSearchData(): void {
    const queryObj: any = { paymentSpecified: 0, eventType: 'paid', status: 1 };
    // This statement for search

    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.countsPaymentUnspecifiedListDataForSearch(queryObj)
      .subscribe(data => {
        this.eventReqAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }


  /**
   * this function is used to get list of event cancel before payment
   * @param status number
   */
  getListEventCancelBeforePaid(limitNum: number, skipNum: number): void {
    this.eventPaymentCheckOutList = [];
    const queryObj: any = { status: 2, paymentSpecified: 1, eventType: 'paid', limit: limitNum, skip: skipNum };
    //const queryObj: any = { status: 2, limit: limitNum, skip: skipNum };
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.eventPaymentCancelBeforePaid(queryObj)
      .subscribe(data => {
        this.eventCancelBeforePaidAttributes.pageItems = (data && data.length) ? data : [];
      }, (err) => { });
  }

  /**
   * this function is used to get list count of cancel Before Paid
   * @param status number
   */
  getListCountsEventCancelBeforePaid(): void {
    const queryObj: any = { paymentSpecified: 1, eventType: 'paid', status: 2 };
    //  const queryObj: any = { status: 2 };
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.countsCancelBeforePaid(queryObj)
      .subscribe(data => {

        this.eventCancelBeforePaidAttributes.count = (data && data.count) ? data.count : 0;

      }, (err) => { });
  }

  /**
   * this function is used to get list of event paid but cancel
   * @param status number
   */
  getListEventPaidButCancel(limitNum: number, skipNum: number): void {
    this.eventPaymentCheckOutList = [];
    const queryObj: any = { status: 3, paymentSpecified: 1, eventType: 'paid', limit: limitNum, skip: skipNum };
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.eventPaymentCancelBeforePaid(queryObj)
      .subscribe(data => {
        this.eventPaidButCancelAttributes.pageItems = (data && data.length) ? data : [];
      }, (err) => { });
  }

  /**
   * this function is used to get list count of cancel Before Paid
   * @param status number
   */
  getListCountsEventPaidButCancel(): void {
    const queryObj: any = { paymentSpecified: 1, eventType: 'paid', status: 3 };
    // This statement for search
    if (this.paymentSearch == 0) {
      this.paymentManageSearchForm.reset();
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.countsPaymentUnspecifiedListData(queryObj)
      .subscribe(data => {
        this.eventPaidButCancelAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }

  /**
   * getExhibitor List by eventSlug
   * @param eventSlug
   */
  getExhibitorList(idData: string, rowData: any): void {
    this.userListOfEventReq = [];
    this.eventSelectData = rowData;
    //eventSingleItem
    const queryObj: any = { filter: { where: { _id: idData } } };
    this.eventService.getEventExibitorList(queryObj)
      .subscribe(data => {
        this.userListOfEventReq = (data && data.length) ? data : [];
        let updateData = {
          exhibitorName: this.userSingleItem.username,
          eventName: this.eventSingleItem.eventName,
          corporateName: this.eventSingleItem.company,
          eventDate: this.eventSingleItem.eventDate,
        };
        this.eventPaymentManageForm.patchValue(updateData);

      }, (err) => { });
  }
  /**
 * getExhibitor List by eventSlug
 * @param eventSlug
 */
  getExhibitorListByLocalEvent(idData: string, rowData: any): void {
    this.userListOfEventReq = [];
    this.eventSelectData = rowData;
    //eventSingleItem
    const queryObj: any = { filter: { where: { _id: idData } } };
    this.eventService.getEventExibitorList(queryObj)
      .subscribe(data => {
        this.userListOfEventReq = (data && data.length) ? data : [];
        let updateData = {
          exhibitorName: this.userSingleItem.username,
          eventName: this.eventSingleItem.eventName,
          corporateName: this.eventSingleItem.company,
          eventDate: this.eventSingleItem.eventDate,
        };
        this.eventPaymentManageForm.patchValue(updateData);

      }, (err) => { });
  }



  /**
   * getExhibitor List by eventSlug
   * @param eventSlug
   */
  patchValuePaymentMangaementForm(): void {
    let updateData = {
      exhibitorName: this.userSingleItem.exhibitorName,
      eventName: this.eventSingleItem.eventName,
      corporateName: this.eventSingleItem.corporateName,
      eventDate: this.eventSingleItem.eventDate,
      eventPayment: this.eventSingleItem.eventPayment,
    };
    this.eventPaymentManageForm.patchValue(updateData);
  }

  patchValuePaymentMangaementFormPaid(idxP: number, amt: any): void {
    // console.log("I am in");
    // console.log(this.eventSingleItem,"aman...");
    this.productIndex = idxP;
    let updateData = {

      created: this.eventSingleItem.created,
      invoiceNumber: this.eventSingleItem.invoiceNumber
    };
    this.eventPaymentManageFormPaid.patchValue(updateData);
  }
  patchValuePaymentMangaementFormPending(idxP: number, childIndex: any): void {

    this.productIndex = idxP;
    this.pendingChildIndex = childIndex;
    let updateData = {
      paymentStatus: this.eventSingleItem.paymentStatus,
      eventDate: this.eventSingleItem.eventDate,
      eventPayment: this.eventSingleItem.eventPayment,

    };
    this.eventPaymentManageFormPending.patchValue(updateData);
  }

  /**
   * This is used to save payment for payment Unspecified exhibitors
   */

  setEventpaymentForExhib(): void {
    var queryObj = this.eventPaymentManageForm.value;
    this.eventService.addEventPaymentToManage(queryObj)
      .subscribe(data => {
        this.notifier.notify('success', 'Added Successful!');
        this.userListOfEventReq[this.showExhibListIndx].eventReqData.paymentSpecified = 1;

        if (this.userListOfEventReq && this.userListOfEventReq.length > 0) {
        } else {
          if (this.eventReqAttributes.pageItems && this.eventReqAttributes.pageItems.length > 0) {
            this.eventReqAttributes.pageItems.splice(this.showEventListIndx, 1);
          }
        }
        this.getPendingPaymentUserList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.getCountsPaymentPendingUserList()
        this.getEventListForUnspecifiedPayment(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.getEventListCountsUnspecifiedPayment()
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', 'someting went wrong');
        }
      });
  }
  /**
 * This is used to save payment for payment Unspecified exhibitors
 */

  setEventpaymentForExhibOverDue(): void {
    
    var queryObj = this.eventPaymentManageForm.value;
    this.eventService.addEventPaymentToManage(queryObj)
      .subscribe(data => {
        this.notifier.notify('success', 'Added Successful!');

      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', 'someting went wrong');
        }
      });
  }

  /**
   * on page change we use this function during pagiantion of list
   * @param event
   */
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    if (this.activeTabs === 1) {
      this.paidEventsList(this.paymentManageAttributes.limits, startItem);
    } else if (this.activeTabs === 2) {
      this.getPendingPaymentUserList(this.paymentPendingAttributes.limits, startItem);
    } else if (this.activeTabs === 3) {
      this.getListEventCancelBeforePaid(this.eventCancelBeforePaidAttributes.limits, startItem);
    } else if (this.activeTabs === 4) {
      this.getOverDueEventUserList(this.paymentOverDueAttributes.limits, startItem);
    } else if (this.activeTabs === 6) {
      this.getEventListForUnspecifiedPayment(this.eventReqAttributes.limits, startItem);
    }
  }

  setCountsForManagement(): void {
    var queryObj = { paymentStatus: 0, paymentSpecified: 0 };
    this.eventService.getCountsPaymentManagement(queryObj)
      .subscribe(data => {
        this.paymentManageCounts = data;
      }, (err) => { });
  }

  /**
   * payment counts for paid events
   */
  paidEventsCounts(): void {
    const queryObj: any = {
      status: 1
    };
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.userName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.paymentType) {
      queryObj.paymentType = this.paymentManageSearchForm.value.paymentType;
    }
    this.eventService.getCountPaymentInvoiceForAdmin(queryObj)
      .subscribe(data => {
        this.paidPaymentFixCount = (data && data.count) ? data.count : 0;
        this.paymentManageAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }

  /**
  * payment counts for paid events
  */
  paidEventsCountForSearch(): void {
    const queryObj: any = {
      status: 1
    };
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.userName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.paymentType) {
      queryObj.paymentType = this.paymentManageSearchForm.value.paymentType;
    }
    this.eventService.getCountPaymentInvoiceForAdmin(queryObj)
      .subscribe(data => {
        // this.paidPaymentFixCount  = (data && data.count) ? data.count : 0;
        this.paymentManageAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }
  /**
   * Event list for paid  events
   */
  paidEventsList(limitNum: number, skipNum: number): void {
    this.eventPaymentCheckOutList = [];
    const queryObj: any = {
      status: 1, limit: limitNum, skip: skipNum
    };
    if (this.paymentSearch == 0) {
      this.paymentManageSearchForm.reset();
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.userName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.paymentType) {
      queryObj.paymentType = this.paymentManageSearchForm.value.paymentType;
    }


    this.eventService.getPaymentInvoiceListForAdmin(queryObj)
      .subscribe(data => {
        this.paymentManageAttributes.pageItems = (data && data.length > 0) ? data : [];
      }, (err) => { });
  }

  /**
   * Event list for peayemnt Pending  events
   */
  getPendingPaymentUserList(limitNum: number, skipNum: number): void {
    this.eventPaymentCheckOutList = [];
    const queryObj: any = {
      paymentStatus: 0, limit: limitNum, skip: skipNum, status: 1
    };
    if (this.paymentSearch == 0) {
      this.paymentManageSearchForm.reset();
    }
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }

    this.eventService.getPaymentPendingUserList(queryObj)
      .subscribe(data => {
        this.paymentPendingAttributes.pageItems = (data && data.length > 0) ? data : [];
      }, (err) => { });
  }

  /**
   * Event list for overdue event user list
   */
  getOverDueEventUserList(limitNum: number, skipNum: number): void {
    this.eventPaymentCheckOutList = [];
    const queryObj: any = {
      limit: limitNum, skip: skipNum, paymentStatus: CommonStatus.PENDING, dateData: moment().subtract(this.systemConstants.TWENTY_DAYS, 'd').toDate()
    };
    if (this.paymentSearch == 0) {
      this.paymentManageSearchForm.reset();
    }
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.getPaymentOverDueUserList(queryObj)
      .subscribe(data => {
        this.paymentOverDueAttributes.pageItems = (data && data.length > 0) ? data : [];
      }, (err) => { });
  }

  /**
   * Event list for overdue event user list counts
   */
  getCountsOverDueEventUserList(): void {
    const queryObj: any = {
      paymentStatus: CommonStatus.PENDING, dateData: moment().subtract(SystemConstants.TWENTY_DAYS, 'd').toDate()
    };
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.getCountsPaymentOverDueUserList(queryObj)
      .subscribe(data => {
        this.overDueFixCount = (data && data.count) ? data.count : 0;
        this.paymentOverDueAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }

  /**
 * Event list for overdue event user list counts
 */
  getCountsOverDueEventUserListSearch(): void {
    const queryObj: any = {
      paymentStatus: CommonStatus.PENDING, dateData: moment().subtract(this.systemConstants.TWENTY_DAYS, 'd').toDate()
    };
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    this.eventService.getCountsPaymentOverDueUserList(queryObj)
      .subscribe(data => {
        this.paymentOverDueAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }
  /**
   * Event list for paid  events
   */
  getCountsPaymentPendingUserList(): void {
    const queryObj: any = {
      paymentStatus: 0, status: 1
    };
    if (this.paymentSearch == 0) {
      this.paymentManageSearchForm.reset();
    }
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }

    this.eventService.getCountsPaymentPendingUserList(queryObj)
      .subscribe(datas => {
        //console.log(datas.count,"abfs");
        this.pendingPaymentFixCount = (datas && datas.count) ? datas.count : 0;
        //console.log(this.pendingPaymentFixCount,"hhh");
        this.paymentPendingAttributes.count = (datas && datas.count) ? datas.count : 0;

      }, (err) => { });
  }

  getCountsPaymentPendingUserListSearch(): void {
    const queryObj: any = {
      paymentStatus: 0, status: 1
    };
    // This statement for search
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.searchField) {
      queryObj.exhibitorName = this.paymentManageSearchForm.value.searchField;
    }
    if (this.paymentManageSearchForm && this.paymentManageSearchForm.value && this.paymentManageSearchForm.value.eventName) {
      queryObj.eventName = this.paymentManageSearchForm.value.eventName;
    }
    if (this.paymentSearch == 0) {
      this.paymentManageSearchForm.reset();
    }
    this.eventService.getCountsPaymentPendingUserList(queryObj)
      .subscribe(data => {
        this.paymentPendingAttributes.count = (data && data.count) ? data.count : 0;

      }, (err) => { });
  }

  resumeInvitationForEvent(id: string): void {
    const queryObj: any = { status: 1 };
    this.eventService.updateEventReqById(id, queryObj)
      .subscribe(data => {
       this.getListEventPaidButCancel(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);
       this.getListCountsEventPaidButCancel()
       this.getListEventCancelBeforePaid(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP)
       this.getListCountsEventCancelBeforePaid()


        this.notifier.notify('success', 'Accepted Successful.');
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', err.error.error.message);
        }
      });
  }
  resumeInvitatioPaidButCancel(id: string, subIndex: number, mainIndexes: number): void {
    const queryObj: any = { status: 1 };
    this.eventService.updateEventReqById(id, queryObj)
      .subscribe(data => {
        this.eventPaidButCancelAttributes.pageItems[mainIndexes]['eventReqData'].splice(subIndex, 1);
        this.notifier.notify('success', 'Accepted Successful.');
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', err.error.error.message);
        }
      });
  }
  removePaidButCancel(id: string, subIndex: number, mainIndexes: number): void {
    this.eventService.deleteEventRequest(id)
      .subscribe(data => {

        //this.eventCancelBeforePaidAttributes.pageItems[mainIndexes]['eventReqData'][subIndex]
        this.eventPaidButCancelAttributes.pageItems[mainIndexes]['eventReqData'].splice(subIndex, 1);
        this.getListEventPaidButCancel(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);
        this.getListCountsEventPaidButCancel()
        this.notifier.notify('success', 'Removed Successful.');
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', err.error.error.message);
        }
      });
  }
  removeCancelBeforePaid(id: string, subIndex: number, mainIndexes: number): void {
    this.eventService.deleteEventRequest(id)
      .subscribe(data => {

        this.eventCancelBeforePaidAttributes.pageItems[mainIndexes]['eventReqData'].splice(subIndex, 1);

        this.getListEventCancelBeforePaid(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP)
        this.getListCountsEventCancelBeforePaid()
        this.notifier.notify('success', 'Removed Successful.');
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', err.error.error.message);
        }
      });
  }

  /**
   * Close Popup
   */

  closPopup(): void {
    const localModalRef = this.modalRef;
    localModalRef.hide();
  }
  openRequestPopUp(content: any, data: any): any {
    this.eventCancelBeforePaidAttributes.itemArray = data;
    this.modalRef = this.modalService.show(content, Object.assign({}, { class: 'modal-lg contact-to-exhibitor-pop' }));
    if (this.contactForm.controls['emailId']) {
      this.contactForm.controls['emailId'].setValue(this.userData.email);
    }
  }

  addMessage(): void {
    if (this.contactForm.valid) {
      const contactObj: any = this.contactForm.value;
      let mszObj: any = {};
      mszObj.receiverEmail = this.eventCancelBeforePaidAttributes.itemArray.email;
      mszObj.senderEmail = contactObj.emailId;
      let mszOf: any = {
        text: '<p>' + contactObj.eventName + '</P>' + '<pre>' + contactObj.message + '<pre>',
        email: contactObj.emailId
      };
      mszObj.mszSenderUpdateStatus = 0;
      mszObj.mszReceiverUpdteStatus = 1;
      mszObj.status = 1;
      mszObj.subject = contactObj.subject;
      mszObj.msz = [mszOf];
      mszObj.senderId = this.userData.id;
      mszObj.receiverId = this.eventCancelBeforePaidAttributes.itemArray.userid;
      if (this.eventCancelBeforePaidAttributes.itemArray) {
        this.sendMszToProfile(mszObj);
      }
    }
  }

  /**
   * This function is used to send message
   */
  sendMszToProfile(qObj: any) {
    this.profileService.addProfileMessage(qObj).subscribe(res => {
      this.notifier.notify('success', 'Message sent Successful.');
      this.closPopup();
      this.contactForm.reset();
    }, err => {
      if (err && err.error && err.error.error && err.error.error.message) {
        this.notifier.notify('error', err.error.error.message);
      }
    });
  }
  sendEmailForPending() {
    let someArray = [this.paymentPendingAttributes.pageItems];
    if (this.eventPaymentCheckOutList.length) {
      this.eventPaymentCheckOutList.forEach((item, idx) => {
        let pagingData = this.paymentPendingAttributes.pageItems[item];
        if (pagingData['userData']) {
          let temEmail: any = {};
          temEmail.userId = pagingData['userData'].id;
          let eventAllData = "";
          pagingData.eventPaymentPendingData.forEach((value) => {
            eventAllData += "<tr><td style='padding:5px;'  align='left'>" + value.eventName + "</td><td style='padding:5px;'  align='left'>" + value.eventData[0].company  + "</td><td style='padding:5px;'  align='left'>" + this.datePipe.transform(value.eventDate, 'MMM d, y') + "</td><td style = 'padding:5px;'  align='left'>$" + value.eventPayment + "</td></tr>";
          });
          temEmail.slug = 'payment-pending';
          temEmail.keys = {
            username: pagingData['userData'].username,
            EVENTDATA: eventAllData
          };
          this.emailSendss(temEmail);
        }
      });
      this.eventPaymentCheckOutList = [];
      this.notifier.notify('success', ' Email sent Successful.');
    } else {
      this.notifier.notify('error', 'Unable to sent email. Please select checbox carefully.');
    }
  }
  emailSendss(sendObj: any) {
    this.eventService.sendEmail(sendObj)
      .subscribe(data => {

        // console.log('email send success');
        console.log(data);
      }, (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      });
  }

  sendmailCancelBeforePaid() {
    if (this.eventPaymentCheckOutList.length) {
      this.eventPaymentCheckOutList.forEach((item, index) => {
        let pagingData = this.eventCancelBeforePaidAttributes.pageItems[item];
        if (pagingData['eventReqData']) {

          let eventAllData = "";
          eventAllData += "<tr><td style='padding:5px;' align='left'>" + pagingData.eventName + "</td><td  align='left' style='padding:5px;'>" + this.datePipe.transform(pagingData.eventDate, 'MMM d, y') + "</td></tr>";
          pagingData['eventReqData'].forEach((value) => {
            let temEmail: any = {};
            temEmail.userId = value.userid;
            temEmail.slug = 'cancel-before-paid-admin';
            temEmail.keys = {
              username: value.firstName,
              EVENTDATA: eventAllData
            };
            this.emailSendss(temEmail);
          });
        }
      });
      this.eventPaymentCheckOutList = [];
      this.notifier.notify('success', ' Email sent Successful.');

    } else {
      this.notifier.notify('error', 'Unable to sent email. Please select checbox carefully.');
    }
  }
  sendmailPaidButCancel() {
    if (this.eventPaymentCheckOutList.length) {
      this.eventPaymentCheckOutList.forEach((item, index) => {
        let pagingData = this.eventCancelBeforePaidAttributes.pageItems[item];
        if (pagingData['eventReqData']) {

          let eventAllData = "";
          eventAllData += "<tr><td style='padding:5px;' align='left'>" + pagingData.eventName + "</td><td  align='left' style='padding:5px;'>" + this.datePipe.transform(pagingData.eventDate, 'MMM d, y') + "</td></tr>";
          pagingData['eventReqData'].forEach((value) => {
            let temEmail: any = {};
            temEmail.userId = value.userid;
            temEmail.slug = 'paid-but-cancel-admin';
            temEmail.keys = {
              username: value.firstName,
              EVENTDATA: eventAllData
            };
            this.emailSendss(temEmail);
          });
        }
      });
      this.eventPaymentCheckOutList = [];
      this.notifier.notify('success', ' Email sent Successful.');

    } else {
      this.notifier.notify('error', 'Unable to sent email. Please select checbox carefully.');
    }
  }
  sendEmail(sendObj: any) {
    this.eventService.sendEmail(sendObj)
      .subscribe(data => {
        this.notifier.notify('success', ' Email sent Successful.');
        // console.log('email send success');
        console.log(data);
      }, (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      });
  }
  sendReminder(indexes: number) {
    //  let overDueDataIndex = this.eventPaymentCheckOutList[indexes];
    let paymentOverdue = this.paymentOverDueAttributes.pageItems[indexes];
    if (paymentOverdue['eventPaymentPendingData']) {
      let eventAllData = "";
      let temEmail: any = {};
      temEmail.userId = paymentOverdue.userData.id;
      temEmail.slug = 'payment-overdue';

      paymentOverdue['eventPaymentPendingData'].forEach((value) => {
        eventAllData += "<tr><td style='padding:5px;'  align='left'>" + value.eventName + "</td><td style='padding:5px;'  align='left'>" + value.eventData[0].company  + "</td><td style='padding:5px;'  align='left'>" + this.datePipe.transform(value.eventDate, 'MMM d, y') + "</td><td style = 'padding:5px;'  align='left'>$" + value.eventPayment + "</td></tr>";
      });
      temEmail.keys = {
        username: paymentOverdue.userData.username,
        EVENTDATA: eventAllData
      };
      this.emailSendss(temEmail);
    }
  }
  sendReminderPerEvent(mainIndex: number, indexes: number) {
    //  let overDueDataIndex = this.eventPaymentCheckOutList[indexes];
    let paymentOverdue = this.paymentOverDueAttributes.pageItems[mainIndex];
    if (paymentOverdue['eventPaymentPendingData'][indexes]) {
      let eventAllData = "";
      let temEmail: any = {};
      temEmail.userId = paymentOverdue.userData.id;
      temEmail.slug = 'payment-overdue';

      let value = paymentOverdue['eventPaymentPendingData'][indexes];
      eventAllData = "<tr><td style='padding:5px;'  align='left'>" + value.eventName + "</td><td style='padding:5px;'  align='left'>" + value.eventData[0].company  + "</td><td style='padding:5px;'  align='left'>" + this.datePipe.transform(value.eventDate, 'MMM d, y') + "</td><td style = 'padding:5px;'  align='left'>$" + value.eventPayment + "</td></tr>";
console.log("eventAllDataeventAllDataeventAllDataeventAllData",eventAllData)
      temEmail.keys = {
        username: paymentOverdue.userData.username,
        EVENTDATA: eventAllData
      };
      this.emailSendss(temEmail);
    }
  }
  markAsPaid(indexes: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paymentOverdue = this.paymentOverDueAttributes.pageItems[indexes];


    if (paymentOverdue['eventPaymentPendingData']) {

      paymentOverdue['eventPaymentPendingData'].forEach((value) => {

        const queryObj: any = {
          "userId": value.memberId,
          "prodList": [
            {
              "eventId": value.eventData[0]._id,
              "eventName": value.eventData[0].eventName,
              "eventPrice": value.eventPayment,
              "recordId": value._id
            }
          ],
          "transResponseCode": 1,
          "paymentAmount": value.eventPayment,
          "address": {
            "address": "",
            "city": "",
            "state": "",
            "zip": "",
            "country": ""
          },
          "status": 1,
          "paymentType": 1,
          "eventIds": [
            value.eventData[0]._id
          ]
        };
        this.eventService.markPaidByAdmin(queryObj)
          .subscribe(data => {
            this.paymentOverDueAttributes.pageItems.splice(indexes, 1);
            this.paidEventsList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.paidEventsCounts()
            this.getOverDueEventUserList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.getCountsOverDueEventUserList()
            this.notifier.notify('success', 'Successful Updated.');
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              this.notifier.notify('error', err.error.error.message);
            }
          });
      });

      //markPaidByAdmin
    }

  }
  markAsDelete(indexes: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paymentOverdue = this.paymentOverDueAttributes.pageItems[indexes];


    if (paymentOverdue['eventPaymentPendingData']) {

      paymentOverdue['eventPaymentPendingData'].forEach((value) => {

        const queryObj: any = { status: 3, paymentStatus: 3 };

        this.eventService.updatePaymentById(value._id, queryObj)
          .subscribe(data => {
            this.paymentOverDueAttributes.pageItems.splice(indexes, 1);
            //  this.notifier.notify('success', 'Done.');
            this.getOverDueEventUserList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.getCountsOverDueEventUserList()

            this.notifier.notify('success', 'Successful Removed.');
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              // this.notifier.notify('error', err.error.error.message);
            }
          });

      });


      //markPaidByAdmin
    }

  }
  markAsDeletePerUser(mainIndex: number, indexes: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paymentOverdue = this.paymentOverDueAttributes.pageItems[mainIndex];


    if (paymentOverdue['eventPaymentPendingData']) {
      let value = paymentOverdue['eventPaymentPendingData'][indexes];
      // paymentOverdue['eventPaymentPendingData'].forEach((value) => {

      const queryObj: any = { status: 3, paymentStatus: 3 };

      this.eventService.updatePaymentById(value._id, queryObj)
        .subscribe(data => {
          paymentOverdue['eventPaymentPendingData'].splice(indexes, 1);
          this.notifier.notify('success', 'Successful Removed.');
        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            // this.notifier.notify('error', err.error.error.message);
          }
        });

      //});

      //markPaidByAdmin
    }

  }
  // pending
  markAsDeletePending(indexes: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paymentOverdue = this.paymentPendingAttributes.pageItems[indexes];


    if (paymentOverdue['eventPaymentPendingData']) {

      paymentOverdue['eventPaymentPendingData'].forEach((value) => {

        const queryObj: any = { status: 3, paymentStatus: 3 };

        this.eventService.updatePaymentById(value._id, queryObj)
          .subscribe(data => {
            this.paymentPendingAttributes.pageItems.splice(indexes, 1);
            //  this.notifier.notify('success', 'Done.');
            this.getPendingPaymentUserList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.getCountsPaymentPendingUserList()
            this.notifier.notify('success', 'Successful Removed.');
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              // this.notifier.notify('error', err.error.error.message);
            }
          });

      });


      //markPaidByAdmin
    }

  }
  markAsDeletePerUserPending(mainIndex: number, indexes: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paymentOverdue = this.paymentPendingAttributes.pageItems[mainIndex];


    if (paymentOverdue['eventPaymentPendingData']) {
      let value = paymentOverdue['eventPaymentPendingData'][indexes];
      // paymentOverdue['eventPaymentPendingData'].forEach((value) => {

      const queryObj: any = { status: 3, paymentStatus: 3 };

      this.eventService.updatePaymentById(value._id, queryObj)
        .subscribe(data => {
          paymentOverdue['eventPaymentPendingData'].splice(indexes, 1);
          this.notifier.notify('success', 'Successful Removed.');
        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            // this.notifier.notify('error', err.error.error.message);
          }
        });

      //});

      //markPaidByAdmin
    }

  }

  markAsDeletePerUserPaid(mainIndex: number, indexes: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paidPayment = this.paymentManageAttributes.pageItems[mainIndex];


    if (typeof paidPayment != "undefined") {
      let value = paidPayment;
      // paymentOverdue['eventPaymentPendingData'].forEach((value) => {


      const queryObjs: any = { status: 2 };
      this.eventService.updatePaidPaymentById(value._id, queryObjs)
        .subscribe(data => {
          this.paymentManageAttributes.pageItems.splice(mainIndex, 1);
         this.paidEventsList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.paidEventsCounts()
          this.notifier.notify('success', 'Successful Removed.');
          // this.shoPaymentListIndx = -1;
          // this.showExhibListIndx = -1;

          let paymentIndex = value.prodList[indexes];
          if (paymentIndex && paymentIndex.recordId != "") {

            const queryObj: any = { status: 3, paymentStatus: 3 };

            this.eventService.updatePaymentById(paymentIndex.recordId, queryObj)
              .subscribe(data => {

              }, (err) => {
                if (err && err.error && err.error.error && err.error.error.message) {
                  // this.notifier.notify('error', err.error.error.message);
                }
              });



          }


        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            // this.notifier.notify('error', err.error.error.message);
          }
        });

      /*  const queryObj: any = { status: 3, paymentStatus: 3 };

        this.eventService.updatePaymentById(value._id, queryObj)
          .subscribe(data => {

         
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              // this.notifier.notify('error', err.error.error.message);
            }
          });*/

      //});

      //markPaidByAdmin
    }

  }
  markAsDeletePerUserPaidRegister(mainIndex: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paidPayment = this.paymentManageAttributes.pageItems[mainIndex];


    if (typeof paidPayment != "undefined") {
      let value = paidPayment;
      // paymentOverdue['eventPaymentPendingData'].forEach((value) => {


      const queryObjs: any = { status: 2 };
      this.eventService.updatePaidPaymentById(value._id, queryObjs)
        .subscribe(data => {
          this.paymentManageAttributes.pageItems.splice(mainIndex, 1);
          this.paidEventsList(this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP);this.paidEventsCounts()
          this.notifier.notify('success', 'Successful Removed.');

          this.shoPaymentListIndx = -1;
          this.showExhibListIndx = -1;


        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            // this.notifier.notify('error', err.error.error.message);
          }
        });

      /*  const queryObj: any = { status: 3, paymentStatus: 3 };

        this.eventService.updatePaymentById(value._id, queryObj)
          .subscribe(data => {

         
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              // this.notifier.notify('error', err.error.error.message);
            }
          });*/

      //});

      //markPaidByAdmin
    }

  }
  markAsDeleteGroupUserPaid(mainIndex: number) {
    //let overDueDataIndex = this.eventPaymentCheckOutList[indexes];

    //markBySuperAdmin

    let paidPayment = this.paymentManageAttributes.pageItems[mainIndex];


    if (typeof paidPayment != "undefined") {
      let value = paidPayment;
      // paymentOverdue['eventPaymentPendingData'].forEach((value) => {


      const queryObjs: any = { status: 2 };
      this.eventService.updatePaidPaymentById(value._id, queryObjs)
        .subscribe(data => {
          this.paymentManageAttributes.pageItems.splice(mainIndex, 1);
          this.notifier.notify('success', 'Successful Removed.');
          this.shoPaymentListIndx = -1;

          let paymentIndex = value.prodList;
          if (paymentIndex && paymentIndex.recordId != "") {

            const queryObj: any = { status: 3, paymentStatus: 3 };

            this.eventService.updatePaymentById(paymentIndex.recordId, queryObj)
              .subscribe(data => {

              }, (err) => {
                if (err && err.error && err.error.error && err.error.error.message) {
                  // this.notifier.notify('error', err.error.error.message);
                }
              });



          }


        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            // this.notifier.notify('error', err.error.error.message);
          }
        });

      /*  const queryObj: any = { status: 3, paymentStatus: 3 };

        this.eventService.updatePaymentById(value._id, queryObj)
          .subscribe(data => {

         
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              // this.notifier.notify('error', err.error.error.message);
            }
          });*/

      //});

      //markPaidByAdmin
    }

  }



  mail() {
    if (this.eventPaymentCheckOutList.length) {
      this.eventPaymentCheckOutList.forEach((item, idx) => {

        let paymentOverdue = this.paymentOverDueAttributes.pageItems[item];


        if (paymentOverdue['eventPaymentPendingData']) {

          let eventAllData = "";
          let temEmail: any = {};
          temEmail.userId = paymentOverdue.userData.id;
          temEmail.slug = 'payment-overdue';

          paymentOverdue['eventPaymentPendingData'].forEach((value) => {
            eventAllData += "<tr><td style='padding:5px;'  align='left'>" + value.eventName +"</td><td style='padding:5px;'  align='left'>" + value.eventData[0].company + "</td><td style='padding:5px;'  align='left'>" + this.datePipe.transform(value.eventDate, 'MMM d, y') + "</td><td style = 'padding:5px;'  align='left'>$" + value.eventPayment + "</td></tr>";
          });
          temEmail.keys = {
            username: paymentOverdue.userData.username,
            EVENTDATA: eventAllData
          };
          this.emailSendss(temEmail);

        }

      });
    } else {
      this.notifier.notify('error', 'Unable to sent email. Please select checbox carefully.');
    }

  }
  Email(sendObj: any) {
    this.eventService.sendEmail(sendObj)
      .subscribe(data => {
        this.notifier.notify('success', ' Email sent Successful.');
        // console.log('email send success');
        console.log(data);
      }, (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      });
  }

  changecheckbox(values: any, paymentPendingAttributes: any, index: number) {
    // console.log(idx);
    if (values.currentTarget.checked == true) {
      this.eventPaymentCheckOutList.push(index);
    }
    else if (values.currentTarget.checked == false) {
      this.removeAllElements(this.eventPaymentCheckOutList, index);
    }
    console.log(this.eventPaymentCheckOutList);

  }

  changebox(values: any, paymentPendingAttributes: any, index: number) {
    if (values.currentTarget.checked == true) {
      this.eventPaymentCheckOutList.push(index);
    }
    else if (values.currentTarget.checked == false) {
      this.removeAllElements(this.eventPaymentCheckOutList, index);
    }

  }

  removeAllElements(array, elem) {
    var index = array.indexOf(elem);
    while (index > -1) {
      array.splice(index, 1);
      index = array.indexOf(elem);
    }
  }

  changedcheckbox(values: any, paymentPendingAttributes: any, index: number) {
    // console.log(idx);
    if (values.currentTarget.checked == true) {
      //   console.log("844848",values.currentTarget)
      this.eventPaymentCheckOutList.push(index);
      // console.log("helloo", this.eventPaymentCheckOutList)
    }
    else if (values.currentTarget.checked == false) {
      var arr = this.eventPaymentCheckOutList;
      this.removeAllElements(this.eventPaymentCheckOutList, index);
    }

  }

  getAlleventDetails(eventIds: any): void {
    console.log(eventIds);
    const ids = _.map(eventIds);
    console.log(ids);
    const queryObj = {
      filter: {
        where: {
          id: { inq: ids },
        }
      }
    };

    this.eventService.getLocalEventListDataByParam(queryObj)
      .subscribe(data => {
        this.eventAllDataValue = (data && data.length) ? data : [];
      }, (err) => { });

  }


}