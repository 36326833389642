<!-- ##TODO: Do not remove this comments it is fold logic -->
<!-- <div class="benefits-of-exhibitor-section two-row-section" style="background-image:url(assets/images/benefits-of-exhibitor-img.jpg);">
    <div class="bg-overly"></div>
    <div class="container poi-r-z">
      <h1>{{foldData[0].heading}}</h1>
      <p [innerHTML]="foldData[0].subHeading"></p>
      <div class="row clearfix">
        <div class="hfp-col-6">
          <div class="img-video-box">
            <img *ngIf="foldData[0].type === foldConstants.IMAGE"  [src]="foldData && foldData.length>0 ? (imageCdn + 'w_540,h_319/' + ((imageBaseUrl + '/fold/' + foldData[0].file[0]) | encodeUriComponent )):'' "  alt="loading .."/>
          <video *ngIf="foldData[0].type === foldConstants.VIDEO" style="width: 100%; height: 100%; position: relative; z-index: 1;"  controls  #videoPlayer>
              <source [src]="foldData && foldData.length>0 ? (imageCdn + 'w_540,h_319/' + ((imageBaseUrl + '/fold/' + foldData[0].file[0]) )):'' " type="video/mp4" />
            </video>
          </div>
        </div>
        <div class="hfp-col-6">
          <div class="txt-ul">
            <div [innerHTML]="foldData[0].description"></div>
            
            <div class="signup-btn"><a  [routerLink]= "['signup', 'attend']"><i><img src="assets/images/signup-btn-icon.png" alt="" /></i> Sign Up</a> </div>

            
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- ##TODO: Do not remove this comments it is fold logic -->
  <div class="benefits-of-exhibitor-section two-row-section" style="background-image:url(assets/images/benefits-of-exhibitor-img.jpg);">
    <div class="bg-overly"></div>
    <div class="container poi-r-z">
      <h1>Virtual Employee Challenges</h1>
      <p class="hd-desc">At Health Fairs Plus, our employee challenges are interactive, engaging, customizable and continually evolving. <br /> 
        So create the right challenge for you!</p>
      <div class="row clearfix">
        <div class="hfp-col-6 hide-desktop"> 
          <img src="assets/images/virtual-employee-challenges-img.jpg" width="100%" alt="Virtual Employee Challenges" />
        </div>
        <div class="hfp-col-6 hide-mobile">
          <div class="img-video-box">
             <img src="assets/images/virtual-employee-challenges-img.jpg" alt="Virtual Employee Challenges" />
          </div>
        </div>
        <div class="hfp-col-6">
          <div class="txt-ul">
            <ul>
              <li>Team and/or individual challenge</li>
              <li>Social engagement tools including maps, badges, chat walls, pictures and more</li>
              <li>Categories include fitness, nutrition, stress, relaxation, social, financial and more</li>
              <li>Download the EWH app to participate on the go</li>
             </ul>
            
            <div class="signup-btn"><a [href]="systemConstants.CHALLENGES_FOLD_LINK" target="_blank">Learn More</a> </div>

            
          </div>
        </div>
      </div>
    </div>
  </div>

