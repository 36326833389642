
const SystemConstantData = {
    HFP_LIVE_FOLD_LINK : "https://www.hfplive.com",
    CHALLENGES_FOLD_LINK: "https://www.employeewellnesshub.com/",
    USER_IMAGE_FOLDER_UPLOADER_URL: "/containers/upload?folderName=users&fileType=image",
    DOCUMENT_FOLDER_UPLOADER_URL: "/containers/upload?folderName=exhibitorsEvent&fileType=document",
    ERROR_TEXT: "error",
    NO: "no",
    EMAIL_PATTERN: /^(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){255,})(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){65,}@)(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22))(?:\.(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-z0-9]+(?:-[a-z0-9]+)*\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)|(?:(?:xn--)[a-z0-9]+))(?:-[a-z0-9]+)*)|(?:\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})|(?:(?!(?:.*[a-f0-9][:\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\]))$/i,
    EVENT_IMAGE_FOLDER_UPLOADER_URL: "/containers/upload?folderName=event&fileType=image",
    DEFAULT_COUNTRY: "US",
    NUMERIC_PATTERN: "^[0-9]*$",
    TEXT_PATTERN: /^[^-\s][a-zA-Z_\s-]+$/, // no white space at beginning + allow space in the middle
    URL_PATTERN: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%_\+.~#?&\\=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\\=]*)?/gi,
    PASSWORD_PATTERN: /^.*(?=.{6,})(?=.*[a-zA-Z])(?=.*\d).*$/,
    DEFAULT_LANGUAGE: "en",
    LANGUAGES: ['en'],  // example: ['en', 'hi']
    BROWSER_LANGUAGES_PATTERN: /en/, // example: /en|hi/
    AUTH_TOKEN: "auth_token",
    IS_REMEMBERED: "isRemember",
    REMEMBER_ME: "RememberMe",
    VIRTUAL:"Virtual",
    FileMimeType: {
        PDF: 'application/pdf'
    },
    ONSITE:"onsite",
    DEFAULT_ZERO:0,
    DEFAULT_CURRENT_PAGE:1,
    STRING:"string",
    AVATAR_IMG:"avatar-2.png",
    AVATAR:"avatar",
    TIME_HOUR_AM:"HH a",
    HOUR_MIN_SEC_AM:"h:mm a",
    UNREGISTER_USER:"unregister-user",
    MMM_D_Y:"MMM d, y",
    ERROR:"error",
    SUCCESS:"success",
    DEFAULT_LIMIT:10,
    DEFAULT_SKIP:0,
    DEFAULT_CLICK_EVENT:2,
    EVENT_CAN_NOT_BE_CREATED_IN_PAST_DATE:"Event can't be created in past date",
    TWENTY_DAYS:20,
    TIME_HOUR_MIN_SEC:"THH:mm",
    YEAR_MONTH_DAY : "YYYY-MM-DD",
    EDIT :"edit",
    EVENT_REQUEST:"event-request",
    PLEASE_ENTER_REQUIRED_DETAILS_IN_FORM:'Please enter required details in form',
    STEP1:'step1',
    INVALID:'INVALID',
    VALID:'VALID',
    SUBMIT:'submit',
    COUNTRY:'country'
    

   
};

export const SystemConstants = SystemConstantData as Readonly<typeof SystemConstantData>

