<div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
          <div class="main-user-img">
              <div class="main-user-img-upload">
                <i class="fa fa-camera" aria-hidden="true"></i></div>
                <img *ngIf="userData.image && userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
                <img *ngIf="userData.image &&  userData.image.type != 'avatar'" src="/assets/images/users/{{userData.image.name}}" />
              </div>
          <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Industries</h1>
      </div>

    </div>
  </div>
</div>

<!-- Industries Search  Start Here-->
<div class="main-admin-search industry-search">
  <div class="container clearfix main__filter__row">
   <div class="filtersearch">
     <form [formGroup]="industriesSearchForm" (ngSubmit)="industriesSearch();">
       <div class="pagefilter__section__in">
         <div class="pagefilter-search-box">
           <input type="text" value="" placeholder='{{"SEARCH_INDUSTRIES"| translate}}' formControlName="name" />
         </div>
         <div class="pagefilter-search-button">
           <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> {{"SEARCH" | translate}}</button>
         </div>
       </div>
     </form>
   </div>
   <div class="pagefilter__addbtn">
     <div class="main-admin-add">
       <a class="outline-btn" href="javascript:void(0);" (click)="toggleForm()"><i class="fa fa-plus" aria-hidden="true"></i> {{"ADD_INDUSTRIES" | translate}}</a>
     </div>
   </div>


  </div>
</div>
<!-- Industries Search  End Here-->

<!-- Define form template here-->
<div *ngIf="iAttributes.isShow; then industriesFormTemp"></div>

<!-- Industries Form template content Start here-->
<ng-template #industriesFormTemp>
  <div class="add-new-section add-new-screenings">
    <div class="container">
      <div class="add-new-container">
        <div class="form-close">
          <button id="close-1" (click)="toggleForm()"><img src="/assets/images/add-form-close.png" alt=""></button>
        </div>
        <h2> {{"ADD_NEW_INDUSTRIES" | translate}}</h2>
        <form [formGroup]="industriesForm" (ngSubmit)="addIndustries()">
          <div class="fold-form">
            <div class="hfp-input-div">
              <label>{{"INDUSTRIES_NAME" | translate}}</label>
              <input class="" type="text" placeholder="Industries Name" formControlName="name">
              <div *ngIf="iForm.name.invalid &&  iAttributes.submitted"
                class="error_message">
                <div *ngIf="iForm.name.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <!-- <div *ngIf="iForm.name.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}
                </div>
                <div *ngIf="iForm.name.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
              | translate}}</div> -->
              </div>
            </div>
            <div class="hfp-input-div">
              <label>{{"PARENT_NAME" | translate}}</label>
              <select class="hfp-selectbox" formControlName="parentId">
                <option *ngFor="let indusItem of industriesParentList" value="{{indusItem.id}}">
                  {{indusItem.name}}
                </option>
              </select>
            </div>
            <div class="hfp-input-div">
              <label>{{"STATUS" | translate}}</label>
              <div class="switch-field Status-tab">
                <div *ngFor="let status of globals.statusType; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status"
                    value="{{status.value}}" [checked]="iAttributes.statusSelected==status.value" />
                  <label for="switch_left{{idx}}">{{status.name}}</label>
                </div>
              </div>
              <div *ngIf="iForm.status.invalid && iAttributes.submitted"
                class="error_message">
                <div *ngIf="iForm.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
              </div>
            </div>
            <div class="buttons">
              <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
              <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- Industries Form template content End here-->


<!-- Industries List Start here-->
<div class="screenings-industry-pg clearfix">
  <div class="container">
    <div class="locale-hierarchy">
    <div class="screenings-list" [style.width]="iAttributes.isActiveIdx >=1 ? (280*(iAttributes.isActiveIdx+2))+'px':'100%'">
      <ul *ngFor="let data of iAttributes.pageItems;let pidx=index">
        <li *ngFor="let ind of data; let idx = index" (click)="nextlevelChild(pidx,idx,ind.chid)" [ngClass]="{active:iAttributes.isActiveIdx==pidx && iAttributes.childIndex==idx}">
          <div class="sub-child">
            <h3 class="locale-name "> {{ind.name | truncate:[43, '...'] | capitalize}}
              <a href="javascript:void(0)" data-toggle="modal" data-target="#screenings-pop" (click)="open(content,ind,true);"><i aria-hidden="true" class="fa fa-pencil"></i></a>
              <a href="javascript:void(0)" (click)="deleteIndustryData(pidx,idx);"><i aria-hidden="true" class="fa fa-trash"></i></a>
            </h3>
            <span>Active</span>
            <a href="javascript:void(0)" [hidden]="!ind.child" class="arrow"></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  </div>
</div>
<!-- Industries List End here-->

<!--Add and Edit Industires Pop Up Start Here -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="screenings-pop-content">
    <button type="button" class="popclose" daria-label="Close" (click)="modalRef.hide()"><img
        src="/assets/images/pop-close.png" alt=""></button>
    <h1> {{(iAttributes.itemArray.id ? 'EDIT_NDUSTRIES' : 'ADD_NDUSTRIES') | translate}}</h1>
    <form [formGroup]="industriesForm" (ngSubmit)="updateIndustries(iAttributes.itemArray.id)">
      <div class="fold-form">
        <div class="hfp-input-div">
          <label>{{"INDUSTRIES_NAME" | translate}}</label>
          <input class="" type="text" placeholder="Industries Name" formControlName="name">
          <div *ngIf="iForm.name.invalid &&  iAttributes.submitted" class="alert alert-danger">
            <div *ngIf="iForm.name.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
            <!-- <div *ngIf="iForm.name.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}
            </div>
            <div *ngIf="iForm.name.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
        | translate}}</div> -->
          </div>
        </div>
        <div class="hfp-input-div">
          <label hidden>{{"PARENT_NAME" | translate}}</label>
          <select class="hfp-selectbox" formControlName="parentId" hidden>
            <option *ngFor="let indusItem of industriesParentList" value="{{indusItem.id}}"> {{indusItem.name}}
            </option>
          </select>
        </div>
        <div class="hfp-input-div">
          <label>{{"STATUS" | translate}}</label>
          <div class="switch-field Status-tab">
            <div *ngFor="let status of globals.statusType; let idx = index;">
              <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status"
                value="{{status.value}}" [checked]="iAttributes.statusSelected==status.value" />
              <label for="switch_left{{idx}}">{{status.name}}</label>
            </div>
          </div>
          <div *ngIf="iForm.status.invalid &&iAttributes.submitted"
            class="alert alert-danger">
            <div *ngIf="iForm.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
          </div>
        </div>
        <div class="buttons">
          <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!--Add and Edit Industires Pop Up End Here -->
