import { Component, Injector, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
//import { passwordValidator } from '../shared/validation.directive';
import { LoginService } from '../login/login.service';
import { color } from 'highcharts';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  private notifier: NotifierService;

  allowToReset = false;
  submitted = false;
  showPwd: boolean = false;
  type: string = 'password';
  token: string;
  resetPasswordForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private jwtHelper: JwtHelperService,
    private notifireService: NotifierService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private injector: Injector,
    private appComponent: AppComponent
    // private params: Params
  ) {
    this.notifier = notifireService;

  }

  ngOnInit() {
    const ResponseURL = this.router.url;
    this.token = ResponseURL.split('/').pop();
    this.initResetPassword();
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   const ResponseURL = this.router.url;
    //   this.token = ResponseURL.split('/').pop();
    //   this.initResetPassword();
    // } else {
    //   this.router.navigateByUrl("/");
    // }


  }

  initResetPassword() {
    if (this.token && this.token != "") {
      const tokenData = this.jwtHelper.decodeToken(this.token);

      if (tokenData && tokenData.exp && (tokenData.exp * 1000) >= new Date().getTime()) {
        this.initResetPasswordForm();
        this.allowToReset = true;
      } else {
        this.notifier.notify('warning', 'Reset token expired!');
      }
    } else {
      this.notifier.notify('danger', 'Unauthorized Access!');
      this.router.navigateByUrl('/');
    }
  }

  initResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      // password: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6),
        // Validators.pattern(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$'))
      ]],
      cPassword: ['', Validators.required]
    }, {
      //  validators: passwordValidator
    }
    );
  }
  get sf() {
    return this.resetPasswordForm.controls;
  }

  resetPasswordSubmit() {
    if (this.resetPasswordForm.valid) {
      const dataObj = Object.assign({}, this.resetPasswordForm.value);
      // delete dataObj.cPassword;
      if (dataObj.cPassword != dataObj.password) {
        // alert("Password do not match")
        this.notifier.notify("error", "password not match");
        return
      }
      dataObj.token = this.token;

      this.loginService.resetPassword(dataObj).subscribe(res => {
        this.notifier.notify('success', 'Successful updated pasword!');

        this.router.navigateByUrl('/login');
      }, err => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', err.error.error.message);
        }
      });
    } else {
      this.submitted = true;
    }
  }
  // to show and hide password
  toggleShow() {
    this.showPwd = !this.showPwd;
    if (this.showPwd) {
      this.type = 'text';
    } else { this.type = 'password'; }
  }


}
