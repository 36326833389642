import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Pipe } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { CommonModule, DatePipe } from "@angular/common";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  AppRoutingModule,
  routingComponents,
  routingEntryComponents,
} from "./app-routing.module";

import { AppComponent } from "./app.component";

import { serviceModule } from "./service";

import { PIPEMODULE } from "./pipe";

import { FileUploadModule } from "ng2-file-upload";
import { DndModule } from "ng2-dnd";
import { NgSelectModule } from "@ng-select/ng-select";

import { ControlFactoryDirective } from "./directive/control-factory.directive";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { NotifierModule, NotifierOptions } from "angular-notifier";

import { ScreeningComponent } from "./screening/screening.component";

import { environment } from "../environments/environment";
import { AuthService } from "./services/auth.service";
import { SharedService } from "./shared.service";

import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LeftImageComponent } from "./left-image/left-image.component";
import { SliderComponent } from "./slider/slider.component";
import { RightImageComponent } from "./right-image/right-image.component";
import { FrontFactoryDirective } from "./directive/front-factory.directive";
import { AboutComponent } from "./about/about.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { CorporateUserComponent } from "./corporate-user/corporate-user.component";
// import { GoogleMapsAPIWrapper } from "@agm/core/services";
import { AgmCoreModule } from "@agm/core";
import { ProfileComponent } from "./profile/profile.component";
import { EventDetailComponent } from "./event-detail/event-detail.component";
import { ViewExhibitorsComponent } from "./view-exhibitors/view-exhibitors.component";
import { ExhibitorInvitationComponent } from "./exhibitor-invitation/exhibitor-invitation.component";
import { CorporateReviewEventComponent } from "./corporate-review-event/corporate-review-event.component";
import { FindExhibitorsComponent } from "./find-exhibitors/find-exhibitors.component";
import { ReviewEventsComponent } from "./review-events/review-events.component";
import { EventInquiriesComponent } from "./event-inquiries/event-inquiries.component";
import { PaymentManagementComponent } from "./payment-management/payment-management.component";
import { ExhibitorEventPaymentComponent } from "./exhibitor-event-payment/exhibitor-event-payment.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { UnregisteredExhibitorsComponent } from "./unregistered-exhibitors/unregistered-exhibitors.component";
import { UnregisterEventRequestComponent } from "./unregister-event-request/unregister-event-request.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { ScreeningListComponent } from "./screening-list/screening-list.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ExhibitorTermsAndConditionsComponent } from "./exhibitor-terms-and-conditions/exhibitor-terms-and-conditions.component";
import { WhyRegisterComponent } from "./why-register/why-register.component";
import { CorporateTermsAndConditionsComponent } from "./corporate-terms-and-conditions/corporate-terms-and-conditions.component";
import { IndustriesListComponent } from "./industries-list/industries-list.component";
import { RefundPolicyComponent } from "./refund-policy/refund-policy.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { TooltipModule, TooltipConfig } from "ngx-bootstrap/tooltip";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { AlertModule } from "ngx-bootstrap/alert";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { SortableModule } from "ngx-bootstrap/sortable";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { AdminEventReviewComponent } from "./admin-event-review/admin-event-review.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { LocationModule } from "./shared/modules/location/location.module";
import { PhoneNumberModule } from "./shared/modules/phone-number/phone-number.module";
import { UnregisterEventRequestFormModule } from "./shared/modules/unregister-event-request-form/unregister-event-request-form.module";
import { AgmDirectionModule } from 'agm-direction';
import { RequestInvitationComponent } from './request-invitation/request-invitation.component';
const authUrl: string = environment.authApiUrl;
export function jwtOptionsFactory(AuthService) {
  return {
    tokenGetter: () => {
      return AuthService.getToken();
    },
    allowedDomains: [authUrl],
  };
}


// AoT requires an exported function for factories and this for language
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "right",
    },
    vertical: {
      position: "top",
      distance: 80,
    },
  },
  behaviour: {
    autoHide: 4500,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ScreeningComponent,
    routingComponents,
    HeaderComponent,
    FooterComponent,
    ControlFactoryDirective,
    LeftImageComponent,
    SliderComponent,
    AdminEventReviewComponent,
    RightImageComponent,
    FrontFactoryDirective,
    AboutComponent,
    ForgotPasswordComponent,
    CorporateUserComponent,
    ProfileComponent,
    EventDetailComponent,
    ViewExhibitorsComponent,
    ExhibitorInvitationComponent,
    CorporateReviewEventComponent,
    FindExhibitorsComponent,
    ReviewEventsComponent,
    EventInquiriesComponent,
    PaymentManagementComponent,
    ExhibitorEventPaymentComponent,
    ResetPasswordComponent,
    UnregisteredExhibitorsComponent,
    UnregisterEventRequestComponent,
    AboutUsComponent,
    PrivacyComponent,
    ContactUsComponent,
    ExhibitorTermsAndConditionsComponent,
    WhyRegisterComponent,
    CorporateTermsAndConditionsComponent,
    ScreeningListComponent,
    IndustriesListComponent,
    PIPEMODULE,
    RefundPolicyComponent,
    ConfirmationDialogComponent,
   RequestInvitationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    SortableModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    RatingModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgxCaptchaModule,
    HttpClientModule,
    CreditCardDirectivesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FileUploadModule,
    DndModule.forRoot(),
    LoadingBarHttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD4p1bR-gKmpgFDoSsusigd9oX-SPW8WeA",
      libraries: ["places"],
    }),
    AgmDirectionModule,
    LocationModule,
    PhoneNumberModule,
    UnregisterEventRequestFormModule,
    PageNotFoundModule
  ],
  providers: [serviceModule, SharedService, DatePipe, TooltipConfig],
  entryComponents: [routingEntryComponents],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
