<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i></div>
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Manage Menu</h1>
      </div>
    </div>
  </div>
</div>
<div class="main-admin-search menupage-search">
  <div class="container clearfix main__filter__row">
    <div class="filtersearch">
      <form [formGroup]="searchMenuForm" (ngSubmit)="searchMenu();">
       <div class="pagefilter__section__in">
        <div class="pagefilter-search-box">
          <input type="text" value="" formControlName="searchMenu" placeholder="{{'SEARCH_MENU' | translate}}" (change)="searchMenu()"/>
        </div>
        <div class="pagefilter-search-button">
          <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i>{{"SEARCH" | translate}}</button>
        </div>
       </div>
      </form>
    </div>
    <div class="pagefilter__addbtn">
      <div class="main-admin-add">
        <a class="outline-btn" href="javascript:void(0)" (click)="toggleForm()"><i class="fa fa-plus" aria-hidden="true"></i> Add Menu</a>
      </div>
    </div>
  </div>
</div>
<!-- Define form template here-->
<div *ngIf="menuAttributes.isShow; then menuFormTemp"></div>
<!-- Menu Form template content Start here-->
<ng-template #menuFormTemp>
  <div [ngClass]="{'edit_form_type': !menuAttributes.isShow}">
    <div class="container">
      <div class="add-new-container">
        <div class="form-close"><button (click)="toggleForm()"><img src="/assets/images/add-form-close.png" alt="" /></button></div>
        <h2>{{ (menuAttributes.itemArray.id ? "EDIT_MENU" : "ADD_MENU") | translate}}</h2>
        <form [formGroup]="menuForm" (ngSubmit)="clearValidationOnMenu(f.menuName.value);(menuAttributes.itemArray.id ? updateMenu(menuAttributes.itemArray.id) : addMenu())">

          <div class="fold-form">

            <div class="hfp-input-div">
              <label>Menu Name</label>
              <input class="" type="text" placeholder="Menu Name" formControlName="menuName"
              (keyup)="clearValidationOnMenu($event.target.value)" >
              <div *ngIf="f.menuName.invalid && menuAttributes.submitted"
                class="alert alert-danger">
                <div *ngIf="f.menuName.errors.required">{{"MENU_NAME_IS_REQUIRED" | translate}}</div>
                <!-- <div *ngIf="f.menuName.errors.minlength">{{"MENU_NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                <div *ngIf="f.menuName.errors.maxlength">{{"MENU_NAME_SHOULD_NOT_MORE_120_CHARACTERS" | translate}}</div> -->
              </div>
            </div>

            <div class="hfp-input-div">
              <label> Menu Type List</label>
              <select class="hfp-selectbox" formControlName="menuType" required>
                <option value="" selected>Select Menu Type</option>
                <option *ngFor="let menu of menuType" [value]="menu.label"
                 >
                  {{menu.label}}</option>
              </select>
              <div *ngIf="f.menuType.invalid &&  menuAttributes.submitted " class="alert alert-danger">
                <div *ngIf="f.menuType.errors.required">{{"MENU_TYPE_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div" >
              <label>{{"ACCESS_LEVEL" | translate}}</label>
              <select class="hfp-selectbox" formControlName="accessLevel">
                <option *ngFor="let accessLevels of globals.acl" 
                 [selected]="menuAttributes.itemArray.accessLevel == accessLevels.value" [value]="accessLevels.value">{{accessLevels.name}}
                 </option>
              </select>
              <div *ngIf="f.accessLevel.invalid &&  menuAttributes.submitted" class="alert alert-danger">
                <div *ngIf="f.accessLevel.errors.required">{{"ACCESS_LEVEL_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div" >
              <label>{{"AUTH" | translate}}</label>
              <select class="hfp-selectbox" formControlName="auth">
                <option *ngFor="let authType of globals.auth" 
                [selected]="menuAttributes.itemArray.auth == authType.value" [value]="authType.value">
                {{authType.name}}
              </option>
              </select>
              <div *ngIf="f.auth.invalid && menuAttributes.submitted" class="alert alert-danger">
                <div *ngIf="f.auth.errors.required">{{"AUTH_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div">
              <label>{{"STATUS" | translate}}</label>
              <div class="switch-field Status-tab">
                <div class="radio-round" *ngFor="let status of globals.statusType; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status" [value]="status.value" 
                  [checked]="menuAttributes.statusSelected === status.value" />
                  <label for="switch_left{{idx}}">{{status.name}}</label>
                </div>
              </div>
              <div *ngIf="f.status.invalid &&  menuAttributes.submitted" class="alert alert-danger">
                <div *ngIf="f.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div" formGroupName="linkTo">
                <label> Link To </label>
                <select class="hfp-selectbox" formControlName="type"  (change)="onLinkChange($event.target.value)" required>
                  <option value="" selected>Select Link To</option>
                  <option  *ngFor="let link of linkTo" [value]="link.value" name ="linkTo">{{link.label}}</option>
                </select>
                <div *ngIf="linkToType?.invalid &&  menuAttributes.submitted" class="alert alert-danger">
                  <div *ngIf="linkToType?.errors.required">{{"LINK_TO_IS_REQUIRED" | translate}}</div>
                </div>
              </div>

            <div class="hfp-input-div" formGroupName="linkTo" *ngIf="menuLinkType===menuFormConstant.MENU_LINK_FLAG">
                <label> URL </label>
                <input class="" type="text" formControlName="url" [value]="" name="url" required/>
                <div *ngIf="f.linkTo.get('type')?.valid && linkToFormGroup.get('url')?.invalid &&  menuAttributes.submitted" class="alert alert-danger">
                  <div *ngIf="linkToFormGroup.get('url')?.errors.required">{{"URL_IS_REQUIRED" | translate}}</div>
                </div>
              </div>

            <div class="hfp-input-div" formGroupName="linkTo"  *ngIf="menuLinkType==menuFormConstant.MENU_PAGE_FLAG">
                <label> Page </label>
                 <select class="hfp-selectbox" formControlName="page"  required>
                  <option value="" selected>Select Page</option>
                  <option  *ngFor="let link of allPageData" value="{{link.id}}" name ="linkTo">{{link.pageName}}</option>
                </select>
                <div *ngIf="f.linkTo.get('type')?.valid && linkToFormGroup.get('page')?.invalid &&  menuAttributes.submitted" class="alert alert-danger">
                  <div *ngIf="linkToFormGroup.get('page')?.errors.required">{{"PAGE_IS_REQUIRED" | translate}}</div>
                </div>
               </div>

            <div class="buttons">
              <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
              <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
            </div>

          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- menu  List Section content Start here-->
<div class="add-box-list-section">
  <div class="container">
    <div *ngIf="!menuAttributes.pageItems || !menuAttributes.pageItems.length" class="search-not-found">
      {{translatorJson.NO_RECORD_FOUND | translate}} </div>
    <div class="hfp-row clearfix">
      <div *ngFor="let data of menuAttributes.pageItems let idx = index">
        <div class="hfp-col-3" [ngClass]="{active:menuAttributes.isActiveIdx==idx}">
          <div class="a-b-l-s-div">
           <!-- <div class="manage-page-img" style="background-image:url(/assets/images/admin-pg-img-1.jpg);"></div>
            <div class="img-overly"></div>-->
            <div class="box_content_center">
              <div class="box_content_center_in">
                <h3>{{ data.menuName | truncate:[43, '...'] | capitalize }}</h3>
                <div class="a-d-edit">
                  <ul>
                    <li><a (click)="getMenuForEdit(idx);" href="javascript:void(0)"><i class="fa fa-pencil" aria-hidden="true"></i></a></li>
                    <li><a href="javascript:void(0)" (click)="openConfirmationDialog({idx:idx, data:data})"><i class="fa fa-trash-o" aria-hidden="true"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hfp-row clearfix ad-form-edit" *ngIf="menuAttributes.isIndex==idx;">
          <div class="hfp-col-12">
            <div *ngIf="menuAttributes.isIndex==idx; then menuFormTemp"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="menuAttributes.count > 12">
      <pagination [totalItems]="menuAttributes.count" [rotate]="menuAttributes.rotate" 
      [maxSize]="menuAttributes.maxSize" [itemsPerPage]="menuAttributes.limits" (pageChanged)="pageChanged($event);"></pagination>
    </div>
      </div>
</div>
<!-- menu List Section content End here-->
