import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "capitalize" })
export class CapitalizePipe implements PipeTransform {
  /**
   * Return string with first character uppercase and remaning lowercase
   * @param value:any
   */
  transform(value: string) {
    if (typeof value !== "string") return value;
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
  }
}
