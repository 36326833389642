import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import widget type interface.
import { IWidget } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  // Define the class property.
  private url: string = environment.apiUrl + '/widgets';
  constructor(private http: HttpClient) { }

  /**
   * this service add widget.
   */
  addWidget(widgetObj: IWidget): Observable<IWidget> {
    return this.http.post<IWidget>(this.url, widgetObj);
  }

  /**
   * this service return total count of widget .
   */
  findWidgetCount(widgetObj: object): Observable<any> {
    return this.http.get<any>(this.url + '/count?' + qs.stringify(widgetObj));
  }

  /**
   * this service return widget  list according to parms.
   */
  findWidget(widget: object): Observable<[]> {
    return this.http.get<[]>(this.url + '?' + qs.stringify(widget));
  }

  deleteWidget(id: string): Observable<IWidget> {
    return this.http.delete<IWidget>(this.url + '/' + id, {});
  }

  /**
   * this service update widget using id.
   */
  updateWidget(id: string, widgetTypeObj: IWidget): Observable<IWidget> {
    return this.http.patch<IWidget>(this.url + '/' + id, widgetTypeObj);
  }
  /**
   * this service get widget type using id.
   */
  getWidgetById(id: string): Observable<IWidget> {
    return this.http.get<IWidget>(this.url + '/' + id);
  }

}
