<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i></div>
          <img src="/assets/images/main-user-img.jpg"  alt="" /></div>
        <h2>Van Arnold</h2>

      </div>

      <div class="main-heading-right">
        <h1>Manage Widget</h1>
      </div>

    </div>

  </div>
</div>
<div class="main-admin-search pg-search-bar">
  <div class="container clearfix">
    <form [formGroup]="widgetSearchForm" (ngSubmit)="widgetSearch();">
      <div class="main-admin-search-box">
        <input type="text" value="" placeholder='{{"SEARCH_WIDGET"| translate}}' formControlName="widgetName" />

      </div>
      <div class="main-admin-search-button">
        <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> {{"SEARCH" | translate}}</button>
      </div>
    </form>
    <div class="main-admin-add" *ngIf="globals.developmentKey">
      <a id="show-add-section" class="outline-btn" href="javascript:void(0)" (click)="toggleForm()"><i
          class="fa fa-plus" aria-hidden="true"></i> {{"ADD_WIDGET" | translate}}</a>
    </div>
  </div>
</div>
<!-- Define form template here-->
<div *ngIf="wAttributes.isShow; then widgetFormTemp"></div>

<!-- widget List Section content Start here-->
<div class="add-box-list-section">
  <div class="container">
    <div class="hfp-row clearfix">
      <div *ngFor="let data of wAttributes.pageItems let idx = index">
        <div class="hfp-col-3" [ngClass]="{active:wAttributes.isActiveIdx==idx}">
          <div class="a-b-l-s-div manage-page-box">
            <div class="manage-page-img" style="background-image:url(/assets/images/admin-pg-img-1.jpg);"></div>
            <div class="img-overly"></div>
           <div class="box_content_center">
             <div class="box_content_center_in">
            <h3>{{data.widgetName | truncate:[43, '...'] | capitalize}}</h3>
            <div class="a-d-edit">
              <ul>
                <li><a id="edit-click-1" (click)="getWidgetForEdit(idx);" href="javascript:void(0)"><i
                      class="fa fa-pencil" aria-hidden="true"></i></a>
                </li>
                <li *ngIf="globals.developmentKey"><a href="javascript:void(0)" (click)="deleteWidget(idx)"><i
                      class="fa fa-trash-o" aria-hidden="true"></i></a>
                </li>
              </ul>
            </div>
           </div> </div>
          </div>
        </div>
        <div class="hfp-row clearfix ad-form-edit" *ngIf="wAttributes.isIndex==idx;">
          <div class="hfp-col-12">
            <div *ngIf="wAttributes.isIndex==idx; then widgetFormTemp"></div>
          </div>
        </div>
      </div>
    </div>
    <pagination [totalItems]="wAttributes.count" [rotate]="wAttributes.rotate" [maxSize]="wAttributes.maxSize"
      [itemsPerPage]="wAttributes.limits" (pageChanged)="pageChanged($event);">
    </pagination>
  </div>
</div>
<!-- Widget List Section content End here-->

<!-- Widget Form template content Start here-->
<ng-template #widgetFormTemp>
  <div foldTypeFormTempclass="add-new-section" [ngClass]="{'edit_form_type': !wAttributes.isShow}">
    <div class="container">
      <div class="add-new-container">
        <div class="form-close"><button (click)="toggleForm()"><img src="/assets/images/add-form-close.png"
              alt="" /></button></div>
        <h2> {{(wAttributes.itemArray.id ? 'EDIT_WIDGET' : 'ADD_WIDGET') | translate}}</h2>
        <form [formGroup]="widgetForm"
          (ngSubmit)="wAttributes.itemArray.id ? updateWidget(wAttributes.itemArray.id) : addWidget()">
          <div class="fold-form">
            <div class="hfp-input-div">
              <label>{{"WIDGET_NAME" | translate}}</label>
              <input class="" type="text" placeholder="Widget Name" formControlName="widgetName" />
              <div *ngIf="wf.widgetName.invalid && (wf.widgetName.dirty || wAttributes.submitted )"
                class="alert alert-danger">
                <div *ngIf="wf.widgetName.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="wf.widgetName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" |
                translate}}</div>
                <div *ngIf="wf.widgetName.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
                | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div" *ngIf="globals.developmentKey">
              <label>{{"WIDGET_TYPE_LIST" | translate}}</label>
              <select class="hfp-selectbox" formControlName="widgetTypeId">
                <option value="">{{"SELECT_WIDGET_TYPE_NAME" | translate}}</option>
                <option *ngFor="let widgetType of widgetTypePageItems"
                  [selected]="wAttributes.itemArray.widgetTypeId == widgetType.id" value="{{widgetType.id}}">
                  {{widgetType.widgetTypeName}}</option>
              </select>
              <div *ngIf="wf.widgetTypeId.invalid && (wf.widgetTypeId.dirty || wAttributes.submitted )"
                class="alert alert-danger">
                <div *ngIf="wf.widgetTypeId.errors.required">{{"WIDGET_TYPE_NAME_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div" *ngIf="globals.developmentKey">
              <label>{{"AUTH" | translate}}</label>
              <select class="hfp-selectbox" formControlName="auth">
                <option value="">{{"SELECT_AUTH_TYPE" | translate}}</option>
                <option *ngFor="let authType of globals.auth" value="{{authType.value}}">{{authType.name}}</option>
              </select>
              <div *ngIf="wf.auth.invalid && (wf.auth.dirty || wAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="wf.auth.errors.required">{{"AUTH_IS_REQUIRED" | translate}}</div>
              </div>
            </div>
            <div class="hfp-input-div">
              <label>{{"STATUS" | translate}}</label>
              <div class="switch-field Status-tab">
                <div class="input-radio-design" *ngFor="let status of globals.statusType; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status"
                    value="{{status.value}}" [checked]="wAttributes.statusSelected==status.value" />
                  <label for="switch_left{{idx}}">{{status.name}}</label>
                </div>
              </div>
              <div *ngIf="wf.status.invalid && (wf.status.dirty || wAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="wf.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="buttons">
              <button class="btn btn-primary">{{wAttributes.itemArray.id ? ('UPDATE' | translate) : ('SUBMIT' |
                translate)}}</button>
              <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!-- Widget Type Form content End here-->
