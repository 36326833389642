<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img" *ngIf="userData && userData.image && userData.image.type">
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)"
               src="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))"
               alt="" />
        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Event Evaluation</h1>
      </div>
    </div>
  </div>
</div>
<div class="corporate-review-event-section">
  <div class="container">

    <!-- ng template call for corporate Reviews   -->
   <div  *ngIf="eventData && eventData.id && (!submitSuccess); then corpoUserReviewFormTempalte"></div>
    <!-- ng template for Successfull review submit  -->
   <div  *ngIf="submitSuccess; then tempAfterSubmit"></div>

  </div>
</div>
<ng-template #corpoUserReviewFormTempalte>
    <div class="corporate-review-event-step-top">
        <div class="steps-form">
          <div class="steps-row setup-panel">
            <div class="steps-step">
              <a href="JavaScript:Void(0);" class="btn-circle" [ngClass]="{'active': submits.currentStep == 'step1' ||submits.currentStep == 'step2' || submits.currentStep == 'step3' }">1</a>
              <p>Step 1</p>
            </div>
            <div class="steps-step">
              <a href="JavaScript:Void(0);" class="btn-circle" [ngClass]="{'active': submits.currentStep == 'step2' || submits.currentStep == 'step3'}">2</a>
              <p>Step 2</p>
            </div>
            <div class="steps-step">
              <a href="JavaScript:Void(0);" class="btn-circle" [ngClass]="{'active': submits.currentStep == 'step3'}">3</a>
              <p>Step 3</p>
            </div>
          </div>
        </div>

      </div>
  <div class="corporate-review-event-step-btm">
    <form (ngSubmit)="addEventReview()" [formGroup]="eventReviewForm">
  <!---------------------------Step 1 Start-------------------------->
  <div class="corporate-review-event-step-1" [hidden]="submits.currentStep != 'step1'">
    <p>Thank you for letting us help you with your event! We hope your employees learned important information about their health and had lots of fun, too!<br><br>
      We value your input and look forward to continuing to improve our services. To help us maintain a high level of quality, please provide us with feedback by completing this evaluation. </p>
    <div class="corporate-review-event-step-form">
      <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Company</label>
            <input type="text" value="" formControlName="companyName" readonly placeholder="Company" />
          </div>
        </div>
      <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Event Name</label>
            <input type="text" [value]="eventData.eventName" readonly placeholder="Event Name" />
          </div>
        </div>
      <div class="corporate-review-input">
          <div class="clearfix hfp-row">
            <div class="hfp-col-6">
              <div class="hfp-input-div">
                <label>Event Location/City</label>
                <input class="" [value]="eventData.eventAddress.location" readonly type="text" placeholder="Event Location/City">
              </div>
            </div>
            <div class="hfp-col-6">
              <div class="hfp-input-div">
                <label>Event Date  </label>
                <input class="" [value]=" eventData.eventDate | date " type="text" readonly placeholder="Event Date [mm/dd/yy] ">
              </div>
            </div>
          </div>
        </div>
      <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Contact Name</label>
            <input type="text" value="" formControlName="contactName" placeholder="Contact Name" />
          </div>
        </div>
      <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Contact Email </label>
            <input type="text"  value="" formControlName="contactEmail" readonly placeholder="Contact Email " />
          </div>
        </div>
      <div formGroupName="exhibReview">
          <h3>Exhibitor Review</h3>
          <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Do you feel the exhibitors arrived on time and were properly set up before the start of the event? </label>
            <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.yesMostThemSomeThemReview; let idx = index;">
                  <input type="radio" id="option_one{{idx}}" name="exhibArrivedOnTime" required formControlName="exhibArrivedOnTime"
                  value={{optionItem.value}}>
                  <label for="option_one{{idx}}">{{optionItem.name}}</label>
              </div>
            </div>
          </div>
        </div>
          <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Do you feel the exhibitors provided useful information in regard to the health and wellness of your employees?</label>
            <div class="switch-field corporate-review-tab">
                <div *ngFor="let optionItem of globals.yesMostThemSomeThemReview; let idx = index;">
                    <input type="radio" id="option_two{{idx}}" name="exhibProvideUsefulInfo" required formControlName="exhibProvideUsefulInfo"
                    value={{optionItem.value}}>
                    <label for="option_two{{idx}}">{{optionItem.name}}</label>
                </div>
            </div>
          </div>
        </div>
          <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Do you feel the exhibitors conducted themselves in a professional manner while at the event?</label>
            <div class="switch-field corporate-review-tab">
                <div *ngFor="let optionItem of globals.yesMostThemSomeThemReview; let idx = index;">
                    <input type="radio" id="option_three{{idx}}" name="exhibProfessionalManner" required formControlName="exhibProfessionalManner"
                    value={{optionItem.value}}>
                    <label for="option_three{{idx}}">{{optionItem.name}}</label>
                </div>
            </div>
          </div>
        </div>
          <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Do you feel the exhibitors interacted with employees in a fun and productive manner?</label>
            <div class="switch-field corporate-review-tab">
                <div *ngFor="let optionItem of globals.yesMostThemSomeThemReview; let idx = index;">
                    <input type="radio" id="option_four{{idx}}" name="exhibInteractedEmp" required formControlName="exhibInteractedEmp"
                    value={{optionItem.value}}>
                    <label for="option_four{{idx}}">{{optionItem.name}}</label>
                </div>
            </div>
          </div>
        </div>
          <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Did any exhibitor practice hard sales tactics which made employees uncomfortable?</label>
            <div class="switch-field corporate-review-tab">
                <div *ngFor="let optionItem of globals.reviewYesSomeWhatOption; let idx = index;">
                    <input type="radio" id="option_five{{idx}}" name="exhibPracticeHard" required formControlName="exhibPracticeHard"
                    value={{optionItem.value}}>
                    <label for="option_five{{idx}}">{{optionItem.name}}</label>
                </div>
            </div>
          </div>
        </div>
          <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>If yes, please tell us who/why: </label>
            <input type="text" placeholder="" name="aboutExhibForUncomfort" formControlName="aboutExhibForUncomfort" />
          </div>
        </div>
          <div class="corporate-review-input">
          <div class="hfp-input-div">
            <label>Please provide additional Comments and Feedback regarding the Exhibitors: </label>
            <textarea name="additionalFeedback" formControlName="additionalFeedback"></textarea>
          </div>
        </div>
        </div>
      <div class="event-buttons clearfix">
          <a href="javascript:void(0)" (click)="next('step1',( exhibReviews.controls['exhibArrivedOnTime'].valid
          && exhibReviews.controls['exhibProvideUsefulInfo'].valid && exhibReviews.controls['exhibProfessionalManner'].valid
          && exhibReviews.controls['exhibInteractedEmp'].valid && exhibReviews.controls['exhibPracticeHard'].valid
          ), 'step2')" id="stap-1" class="btn btn-default next-btn">Next <i><img src="/assets/images/next-icon.png" alt=""></i></a>
        </div>
    </div>
  </div>
  <!---------------------------Step 1 End-------------------------->
  <!---------------------------Step 2 Start-------------------------->
  <div class="corporate-review-event-step-2" [hidden]="submits.currentStep != 'step2'">

    <div class="corporate-review-event-step-form">
      <h3>Health Fairs Plus Review</h3>
      <div formGroupName="hfpReview">
        <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Were you happy with the selection of categories from which you could choose exhibitors?</label>
          <div class="switch-field corporate-review-tab">

            <div *ngFor="let optionItem of globals.reviewYesSomeWhatOption; let idx = index;">
                <input type="radio" id="option_six{{idx}}" name="happyWithSelection" required formControlName="happyWithSelection"
                value={{optionItem.value}}>
                <label for="option_six{{idx}}">{{optionItem.name}}</label>
            </div>

          </div>
        </div>
      </div>
        <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Do you feel that the exhibitors properly represented the categories, topics, and/or services in which they were
            invited to represent?</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.reviewYesSomeWhatOption; let idx = index;">
                  <input type="radio" id="option_seven{{idx}}" name="exhibProperlyRespond" required formControlName="exhibProperlyRespond"
                  value={{optionItem.value}}>
                  <label for="option_seven{{idx}}">{{optionItem.name}}</label>
              </div>
          </div>
        </div>
      </div>
        <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Do you feel that Health Fairs Plus kept you updated on our progress while planning your event?</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.reviewYesSomeWhatOption; let idx = index;">
                  <input type="radio" id="option_eight{{idx}}" name="hfpKeptUpdate" required formControlName="hfpKeptUpdate"
                  value={{optionItem.value}}>
                  <label for="option_eight{{idx}}">{{optionItem.name}}</label>
              </div>
          </div>
        </div>
      </div>
        <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Did Health Fairs Plus provide all the services in which we committed to provide? (Including any items you
            requested such as exhibitor lists, biometric screenings, food, games, special items, etc.)</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.reviewYesSomeWhatOption; let idx = index;">
                  <input type="radio" id="option_nine{{idx}}" name="hfpProvideAllService" required formControlName="hfpProvideAllService"
                  value={{optionItem.value}}>
                  <label for="option_nine{{idx}}">{{optionItem.name}}</label>
              </div>
          </div>
        </div>
      </div>
        <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>If not, what did we miss? </label>
          <textarea name="anyMissingHfpService" formControlName="anyMissingHfpService"></textarea>
        </div>
      </div>
    </div>
      <h3>Onsite Staff Review (If an event manager was at your event)</h3>
      <div formGroupName="onSiteReview">
      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>If Health Fairs Plus staff was assigned to your event, did our staff arrive prepared and at least one hour prior
            to your event?</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.reYesSomeWhatStaffNotAsinOpt; let idx = index;">
                  <input type="radio" id="option_ten{{idx}}" name="hfpStaffAssigned"  formControlName="hfpStaffAssigned"
                  value={{optionItem.value}}>
                  <label for="option_ten{{idx}}">{{optionItem.name}}</label>
              </div>
          </div>
        </div>
      </div>

      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>IF STAFF WAS ASSIGNED TO ATTEND, did our staff greet exhibitors and help them find their tables?</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.reviewYesSomeWhatNAOption; let idx = index;">
                  <input type="radio" id="option_one_one{{idx}}" name="staffGreetAndHelp"  formControlName="staffGreetAndHelp"
                  value={{optionItem.value}}>
                  <label for="option_one_one{{idx}}">{{optionItem.name}}</label>
              </div>
          </div>
        </div>
      </div>


      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Did our staff members conduct themselves in a professional manner at all times?</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.reviewYesSomeWhatNAOption; let idx = index;">
                  <input type="radio" id="option_one_two{{idx}}" name="staffMemberProfessional"  formControlName="staffMemberProfessional"
                  value={{optionItem.value}}>
                  <label for="option_one_two{{idx}}">{{optionItem.name}}</label>
              </div>
          </div>
        </div>
      </div>

      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>If not, what did we miss?</label>
          <textarea name="anyUnprofessionalFeed"  formControlName="anyUnprofessionalFeed"></textarea>
        </div>
      </div>

      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Did our staff members follow all the rules set forth by your company including rules regarding parking,
            check-in, and security?</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.reYesSomeWhatStaffNotAsinOpt; let idx = index;">
                  <input type="radio" id="option_one_three{{idx}}" name="staffFollowRules"  formControlName="staffFollowRules"
                  value={{optionItem.value}}>
                  <label for="option_one_three{{idx}}">{{optionItem.name}}</label>
              </div>

          </div>
        </div>
      </div>

      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>If no, please explain</label>
          <textarea name="staffNotFollowRules"  formControlName="staffNotFollowRules"></textarea>
        </div>
      </div>


      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Please provide additional Comments and Feedback regarding Health Fairs Plus and Staff:</label>
          <textarea name="additionalFeedback"  formControlName="additionalFeedback"></textarea>
        </div>
      </div>
    </div>

      <div class="event-buttons clearfix">
        <a href="javascript:void(0)" (click)="previous('step1')"  class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> Previous <i></i></a>

        <a href="javascript:void(0)" (click)="next('step2',(hfpReviewsGroup.controls['happyWithSelection'].valid &&
        hfpReviewsGroup.controls['exhibProperlyRespond'].valid && hfpReviewsGroup.controls['hfpKeptUpdate'].valid
        && hfpReviewsGroup.controls['hfpProvideAllService'].valid && onSiteReviewGroup.controls['hfpStaffAssigned'].valid
        && onSiteReviewGroup.controls['staffGreetAndHelp'].valid  && onSiteReviewGroup.controls['staffMemberProfessional'].valid
        && onSiteReviewGroup.controls['staffFollowRules'].valid), 'step3')" id="stap-2"
        class="btn btn-default next-btn">Next <i><img src="/assets/images/next-icon.png" alt=""></i></a>

      </div>


    </div>
  </div>
  <!---------------------------Step 2 End-------------------------->
  <!---------------------------Step 3 Start-------------------------->
  <div class="corporate-review-event-step-3" [hidden]="submits.currentStep != 'step3'">

    <div class="corporate-review-event-step-form">

      <h3>Overall Summary Evaluation</h3>
      <div formGroupName="overallSummary">
      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>How would you evaluate the outcome of your event?</label>
          <div class="switch-field corporate-review-tab">
              <div *ngFor="let optionItem of globals.extremelyGoodPoorReview; let idx = index;">
                  <input type="radio" id="option_one_four{{idx}}" name="evaluationOutcome"  formControlName="evaluationOutcome"
                  value={{optionItem.value}}>
                  <label for="option_one_four{{idx}}">{{optionItem.name}}</label>
              </div>
          </div>
        </div>
      </div>

      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>If not, what did we miss?</label>
          <input type="text" value="" placeholder=""  name="evaluationOutcomeMiss"  formControlName="evaluationOutcomeMiss"/>
        </div>
      </div>


      <div class="corporate-review-input">
        <div class="hfp-input-div" formGroupName="hfpEvalService">
          <label>How would you evaluate the services of Health Fairs Plus?</label>
         <div  *ngFor="let optionItem of globals.hfpServiceEvaluation; let idx = index;">
            <div class="form-check">
                <input type="checkbox" name="checkboxG{{idx}}" id="checkboxG{{idx}}"  (change)="onCheckboxChange(optionItem,$event)" class="css-checkbox">
                <label for="checkboxG{{idx}}" class="css-label"> {{optionItem.name}}</label>
              </div>
         </div>
        </div>
      </div>

      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Would you use our services again?</label>
          <div  *ngFor="let optionItem of globals.hfpServiceAgainReview; let idx = index;">
              <div class="form-check">
                  <input type="checkbox" name="checkboxG1{{idx}}" id="checkboxG1{{idx}}"  (change)="onCheckboxChangeService(optionItem,$event)" class="css-checkbox">
                  <label for="checkboxG1{{idx}}" class="css-label"> {{optionItem.name}}</label>
                </div>
           </div>
        </div>
      </div>

      <div class="corporate-review-input">
        <div class="hfp-input-div">
          <label>Please provide additional Comments and Feedback regarding your overall evaluation of this event. Everything
            you say is important to us...and we listen!</label>
          <textarea name="additionalFeedback"  formControlName="additionalFeedback"></textarea>
        </div>
      </div>

    </div>

      <div class="corporate-review-input">
        <p>Thank you for your highly valuable feedback! We will use your answers to help improve our services. If you wish
          to contact us directly, please email Van Arnold, Marketing Director varnold@HealthFairsPlus.com </p>
      </div>
      <div class="event-buttons clearfix">
        <a href="javascript:void(0)" (click)="previous('step2')" class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> Previous <i></i></a>
        <a href="javascript:void(0)" (click)="next('step3',(overallSummaryGroup.controls['evaluationOutcome'].valid ), 'submit')" class="btn btn-default next-btn">Submit </a>
      </div>

    </div>


  </div>
  <!---------------------------Step 3 End-------------------------->
</form>
</div>
</ng-template>
<ng-template #tempAfterSubmit>
    <div class="corporate-review-event-step-btm" *ngIf="submitSuccess" [hidden]="submits.currentStep != 'submit'">
      <p>Thank you for your valuable feedback. We continue to seek ways in which we can continue to improve our Health Fairs Plus Events.</p>
      <p>Your comments will be closely reviewed and used to improve services </p>
      <p>Thank You!</p>
    </div>
</ng-template>
