
<!-- ##TODO: Do not remove this comments it is fold logic -->

<!-- <div class="home-slider-section">
  <carousel  [activeSlide]="activeSlideIndex"


          [noWrap]="!noWrap"
          [interval]="false"

          (slideRangeChange)="onSlideRangeChange($event)"
  >

    <slide *ngFor="let fold of foldData;" [id]="fold.image">
      <div class="homeslider-itm">

<img  [src]="fold && fold.image ? (imageCdn + 'w_1400,h_481/' + ((imageBaseUrl + '/fold/' + fold.image) | encodeUriComponent)):'' "
        class="wow bounceInRight animated" alt="second slide" style="display: block; width: 100%;" />
        <div class="slider-overly"></div>
        <div class="slider-txt-content">
          <div class="">
            <h1 class="wow bounceInRight animated">{{fold.title}} </h1>
            <div class="wow bounceInRight animated" [innerHTML]="fold.description"></div>
          </div>
        </div>
      </div>
    </slide>
  </carousel>


  <div class="slider-nav">
      <div  class="slick-slide" [ngClass]="(activeSlideIndex ==idx)?'active':''"  *ngFor="let fold of foldData; let idx=index;" >

          <a (click)="activeSlideIndex = idx">
            <img [src]="fold && fold.image ? (imageCdn + 'w_50,h_50/' + ((imageBaseUrl + '/fold/' + fold.image) | encodeUriComponent)):'' "
            alt="" width="100%"></a>
      </div>
  </div> -->

<!-- ##TODO: Do not remove this comments it is fold logic -->



  <div class="home-slider-section" [ngClass]="{'padding-home-slider' : isUserLoggedIn}">
    <carousel  [activeSlide]="activeSlideIndex"
  
  
            [noWrap]="!noWrap"
            [interval]="false"
  
            (slideRangeChange)="onSlideRangeChange($event)"
    >
  
      <slide *ngFor="let slide of sideData;" [id]="slide.id">
        <div class="homeslider-itm">
          
  
  <img [src]="'assets/images/'+slide.img" />
          <div class="slider-overly"></div>
          <div class="slider-txt-content">
            <div class="">
              <h1 class="wow bounceInRight animated">{{slide.title}} </h1>
              <div class="wow bounceInRight animated"><p>{{slide.description}}</p></div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  
  
    <div class="slider-nav">
        <div  class="slick-slide" [ngClass]="(activeSlideIndex ==idx)?'active':''"  *ngFor="let slide of sideData; let idx=index;" >
  
            <a (click)="activeSlideIndex = idx">
              <img [src]="'assets/images/'+slide.thumb" />
            </a>
        </div>
    </div>
