

<div class="page-not-found">
  <div class="container">
    <div class="page-not-found-icon">
      <img src="../../assets/images/page-not-found-icon.svg" width="100%" alt="Page Not Found" />
    </div>
    <h1>Oops! Page not Found</h1>
    <div class="go-to-back-btn"><a href="" routerLink="/">Get to home</a></div>
  </div>
</div>
