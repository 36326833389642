import { Directive, EventEmitter, HostListener, NgZone, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { InputLocationPlace } from 'src/app/interface/input-location/input-location-place';

declare let google: { maps: { places: { Autocomplete: new (arg0: HTMLInputElement, arg1: { types: string[]; }) => any; }; }; };

@Directive({
  selector: 'input[type=googleInputLocation]',
})

export class GoogleInputLocationDirective implements ControlValueAccessor {
  @Output() locFormGroupDataChanged: EventEmitter<{ location: number[] }> = new EventEmitter();
  placeData: string;
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private formGroupDirective: FormGroupDirective,
    private ngZone: NgZone
  ) { }
  writeValue(event: Event): void {
    this.searchGoogleAutocompleteAddress(event);
  }
  registerOnChange(): void { }
  registerOnTouched(): void { }

  @HostListener('keydown', ['$event'])
  ngOnInit = (event: Event): void => {
    this.writeValue(event);
  };

  @HostListener('change', ['$event'])
  onChange(event: Event): void {
    this.writeValue(event);
  }

  get address(): FormGroup {
    return this.formGroupDirective.form.get('address') as FormGroup;
  }

  get parentForm(): FormGroup {
    return this.formGroupDirective.form.get('address') as FormGroup;
  }


  searchGoogleAutocompleteAddress(event: Event): void {
    const srcElement = event.target as HTMLInputElement;
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(srcElement, { types: ['address'] });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          this.placeData = place.formatted_address;
          this.address.reset();
          this.setAddressFieldsWithGoogleAddress(place);
        });
        this.setValidAddressCustomValidator();
      });
    }).catch((error) => {
      throw new Error(error);
    });
    this.setValidAddressCustomValidator();
  }

  setAddressFieldsWithGoogleAddress(place: InputLocationPlace): void {
    if (place.address_components && place.address_components.length) {
      if (place.formatted_address) {
        this.formGroupDirective.form.patchValue({
          address: { addressOne: place.formatted_address }
        })
      }
      let placeData;
      for (placeData of place.address_components) {
        this.formGroupDirective.form.patchValue({
          address: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          },
        });

        this.locFormGroupDataChanged.emit({
          location: [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ],
        })
        const addressType = placeData.types[0];
        if (addressType === 'locality') {
          this.formGroupDirective.form.patchValue({
            address: { city: placeData.long_name }
          });
        }
        if (addressType === 'administrative_area_level_1') {
          this.formGroupDirective.form.patchValue({
            address: { state: placeData.long_name },
          });
        }
        if (addressType === 'postal_code') {
          this.formGroupDirective.form.patchValue({
            address: { zip_code: placeData.long_name },
          });
        }
      }
    }
  }

  isValidAddressFromList(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      if (this.placeData) {
        if (control.value === this.placeData) {
          return null;
        } else { this.placeData = null; }
      }
      return this.placeData ? null : { isValidAddressFromList: true };
    };
  }

  setValidAddressCustomValidator(): void {
    this.address.get('addressOne').setValidators([Validators.required.bind(Validators), this.isValidAddressFromList()])
    this.address.get('addressOne').updateValueAndValidity();
  }
}
