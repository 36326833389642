import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import widget type interface.
import { IFoldType } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FoldTypeService {
  // Define the class property.
  private url: string = environment.apiUrl + '/fold-types';
  constructor(private http: HttpClient) { }

  /**
   * This service add fold type
   */
  addFoldType(foldTypeObj: IFoldType): Observable<IFoldType> {
    return this.http.post<IFoldType>(this.url, foldTypeObj);
  }

  /**
   * This service return total cound of fold type
   */
  getFoldTypeCount(foldTypeObj: object): Observable<any> {
    return this.http.get<any>(this.url + '/count?' + qs.stringify(foldTypeObj));
  }

  /**
   * This service return total cound of fold type
   */
  getFoldTypeList(foldTypeObj: object): Observable<[]> {
    return this.http.get <[]>(this.url + '?' + qs.stringify(foldTypeObj));
  }

  /**
   * this service update fold type.
   */
  updateFoldType(id: string, foldTypeObj: IFoldType): Observable<IFoldType> {
    return this.http.patch<IFoldType>(this.url + '/' + id, foldTypeObj);
  }

  /**
   * this service get fold type using id.
   */
  getFoldTypeById(id: string): Observable<IFoldType> {
    return this.http.get<IFoldType>(this.url + '/' + id);
  }
}
