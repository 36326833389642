<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
          <div class="main-user-img">
              <div class="main-user-img-upload" hidden>
                <i class="fa fa-camera" aria-hidden="true"></i></div>
            <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
            <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

          </div>
          <h2>{{userData.username}}</h2>

      </div>

      <div class="main-heading-right">
        <h1>Manage Page</h1>
      </div>

    </div>

  </div>
</div>
 
<div class="main-admin-search pg-search-bar">
  <div class="container clearfix">
    <form [formGroup]="searchFForm" (ngSubmit)=" searchFold()">
    <div class="main-admin-search-box">
      <input type="text" value="" placeholder="Search Page" formControlName="searchFold" />
    </div>
    <!-- <div class="main-admin-search-date">
      <input class="date-icon" type="text" value="" placeholder="Date Filter" />
    </div> -->
    <div class="main-admin-search-button">
      <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
    </div>
  </form>
    <div class="main-admin-add"> <a id="show-add-section" class="outline-btn" href="javascript:void(0)"
        (click)="toggleForm()"><i class="fa fa-plus" aria-hidden="true"></i> Add Page</a> </div>
  </div>
</div>

<!-- Define form template here-->
<div *ngIf="pageAttributes.isShow; then pageFormTemp"></div>

<!-- Page Form template content Start here-->
<ng-template #pageFormTemp>
  <div [ngClass]="{'edit_form_type': !pageAttributes.isShow}">
    <div class="container">
      <div class="add-new-container">
        <div class="form-close"><button (click)="toggleForm()"><img src="/assets/images/add-form-close.png"
              alt="" /></button></div>
        <h2>{{ (pageAttributes.itemArray.id ? "EDIT_PAGE" : "ADD_PAGE") | translate}}</h2>
        <form [formGroup]="pageForm"
          (ngSubmit)="pageAttributes.itemArray.id ? updatePageData(pageAttributes.itemArray.id) : addPage()">
          <div class="fold-form">
            <div class="hfp-input-div">
              <label>Page Name</label>
              <input class="" type="text" placeholder="Page Name" formControlName="pageName" (input)="onInputPageName($event.target.value)">
              <div *ngIf="f.pageName.invalid && (f.pageName.dirty || pageAttributes.submitted )"
                class="alert alert-danger">
                <div *ngIf="f.pageName.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="f.pageName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                <div *ngIf="f.pageName.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
                    | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div">
              <label>Page Permalink</label>
              <input class="" type="text" placeholder="Page Permalink" formControlName="permalink">
              <div *ngIf="f.permalink.invalid && (f.permalink.dirty || pageAttributes.submitted )"
                class="alert alert-danger">
                <div *ngIf="f.permalink.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="f.permalink.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                <div *ngIf="f.permalink.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
                    | translate}}</div>
              </div>
            </div>



            <div class="hfp-input-div">
              <label>{{"ACCESS_LEVEL" | translate}}</label>
              <select class="hfp-selectbox" formControlName="accessLevel">
                <option *ngFor="let accessLevel of globals.acl"
                  [selected]="pageAttributes.itemArray.auth == accessLevel.value" [value]="accessLevel.value">
                  {{accessLevel.name}}</option>
              </select>
              <div *ngIf="f.accessLevel.invalid && (f.accessLevel.dirty || pageAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.accessLevel.errors.required">{{"ACCESS_LEVEL_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div" >
              <label>{{"AUTH" | translate}}</label>
              <select class="hfp-selectbox" formControlName="auth">
                <option *ngFor="let auth of globals.auth" 
                [selected]="pageAttributes.itemArray.auth == auth.value" [value]="auth.value">
                {{auth.name}}
              </option>
              </select>
              <div *ngIf="f.auth.invalid && (f.auth.dirty || pageAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.auth.errors.required">{{"AUTH_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div">
              <label> Page Type </label>
              <select class="hfp-selectbox" formControlName ="transactional">
                <option *ngFor="let page of pageType; let idx = index;" [value]="page.value">{{page.name}}</option>
              </select>
            </div>

            <div class="hfp-input-div">
              <label>{{"STATUS" | translate}}</label>
              <div class="switch-field Status-tab">
                <div class="radio-round" *ngFor="let status of globals.statusType; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status"
                    [value]="status.value" [checked]="pageAttributes.statusSelected==status.value" />
                  <label for="switch_left{{idx}}">{{status.name}}</label>
                </div>
              </div>
              <div *ngIf="f.status.invalid && (f.status.dirty || pageAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div dnd-sortable-container [sortableData]="addedFoldData">
              <div class="existing-fold-add" *ngFor="let fold of addedFoldData; let idx = index;" dnd-sortable
                [sortableIndex]="idx">
                <div class="existing-fold-row clearfix">
                  <div class="existing-fold-left">
                    <h4>{{fold.foldName}}</h4>
                  </div>
                  <div class="existing-fold-right">
                    <ul>
                      <li><a href="javascript:void(0);" (click)="deleteFold(idx);isExistFold=false;">Delete</a></li>
                    </ul>
                  </div>
                  <div class="foldhandle"><i class="fa fa-arrows" aria-hidden="true"></i></div>
                </div>
                <div class="add-fold-page" *ngIf="isEditFoldIdx==idx; then foldForm"></div>
              </div>
            </div>

            <!--For Add Section-->
            <div class="existing-fold-add" *ngIf="isExistFold">
              <div class="existing-fold-row clearfix">
                <div class="existing-fold-left">
                  <h4>{{pageForm.value.foldName}}</h4>
                  <div id="foldadd1-1-1" class="existing-fold-select">
                    <input type="text" formControlName="foldName" class="form-control"  [typeaheadOptionField]="'foldName'"  [typeahead]="foldArr"
                    [typeaheadItemTemplate]="rt" (typeaheadOnSelect)="onSelectFoldItems($event.item)" />
                    <ng-template #rt let-model="item" let-index="index">
                     {{model.foldName | json}}
                    </ng-template>
                  </div>
                </div>
                <div class="existing-fold-right">
                  <ul>
                    <li *ngIf="isExistFold"><a id="foldadd1" href="javascript:void(0)" (click)="addFoldData();">Add</a></li>
                    <li><a href="javascript:void(0);" (click)="isExistFold=false;">Delete</a></li>
                  </ul>
                </div>
                <div class="foldhandle"><i class="fa fa-arrows" aria-hidden="true"></i></div>
              </div>

            </div>



            <div class="add-fold-link">
              <a href="javascript:void(0)" (click)="isExistFold=true">Add existing Fold</a>
            </div>

            <div class="buttons">
              <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
              <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!-- Page  List Section content Start here-->
<div class="add-box-list-section">
  <div class="container">
    <div *ngIf="!pageAttributes.pageItems || !pageAttributes.pageItems.length" class="search-not-found">
      {{translatorJson.NO_RECORD_FOUND | translate}} </div>
    <div class="hfp-row clearfix">
      <div *ngFor="let data of pageAttributes.pageItems let idx = index">
        <div class="hfp-col-3" [ngClass]="{active:pageAttributes.isActiveIdx==idx}">
          <div class="a-b-l-s-div manage-page-box">
            <div class="manage-page-img" style="background-image:url(/assets/images/admin-pg-img-1.jpg);"></div>
            <div class="img-overly"></div>
            <div class="box_content_center">
              <div class="box_content_center_in">
                <h3>{{ data.pageName | truncate:[43, '...'] | capitalize }}</h3>
                <div class="a-d-edit">
                  <ul>
                    <li><a (click)="getPageForEdit(idx);" href="javascript:void(0)"><i class="fa fa-pencil"
                          aria-hidden="true"></i></a>
                    </li>
                    <li><a href="javascript:void(0)" (click)="deletePage(idx)"><i class="fa fa-trash-o"
                          aria-hidden="true"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hfp-row clearfix ad-form-edit" *ngIf="pageAttributes.isIndex==idx;">
          <div class="hfp-col-12">
            <div *ngIf="pageAttributes.isIndex==idx; then pageFormTemp"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination__box">
    <pagination [totalItems]="pageAttributes.count" [rotate]="pageAttributes.rotate" [maxSize]="pageAttributes.maxSize"
      [itemsPerPage]="pageAttributes.limits" (pageChanged)="pageChanged($event);">
    </pagination>
  </div>
  </div>
</div>
<!-- Page List Section content End here-->
