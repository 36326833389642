import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { SystemConstants } from '../constants/system.constants';
import { FoldConstants } from '../fold/fold-template/constants/fold.constants';
import { ManageImage } from '../interface/manage-image/manage-image';

@Component({
  selector: 'app-right-image',
  templateUrl: './right-image.component.html',
  styleUrls: ['./right-image.component.scss']
})
export class RightImageComponent implements OnInit {

  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  systemConstants = SystemConstants;
  imageServer: any; // Image server object
  siteUrl: string; // Site URL object
  foldData: ManageImage[] = [];
  foldConstants = FoldConstants;

  constructor() {
    this.imageServer = environment.imageCdn.getHost;  // Image By Image server
  }

  ngOnInit() {
  }


}
