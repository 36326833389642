<!----------------------Hero Slider Section Start--------------------->

<div class="home-slider-section">
  <carousel>

    <slide>
      <div class="homeslider-itm">
        <img src="/assets/images/slider-img-1.jpg" class="wow bounceInRight animated" alt="second slide" style="display: block; width: 100%;">
        <div class="slider-overly"></div>
        <div class="slider-txt-content">
          <div class="">
            <h1 class="wow bounceInRight animated">HealthFairsPlus.com </h1>
            <p class="wow bounceInRight animated">is an innovative Virtual corporate wellness resource that gives employers access to a variety of local exhibitors, biometric screenings, and other onsite employee health fair tools and Virtual wellness tools.</p>
          </div>
        </div>
      </div>
    </slide>

    <slide>
      <div class="homeslider-itm">
        <img src="/assets/images/slider-img-2.jpg" class="wow bounceInRight animated" alt="second slide" style="display: block; width: 100%;">
        <div class="slider-overly"></div>
        <div class="slider-txt-content">
          <div class="">
            <h1 class="wow bounceInRight animated">HealthFairsPlus.com </h1>
            <p class="wow bounceInRight animated">is an innovative Virtual corporate wellness resource that gives employers access to a variety of local exhibitors, biometric screenings, and other onsite employee health fair tools and Virtual wellness tools.</p>
          </div>
        </div>
      </div>
    </slide>

  </carousel>

  <div class="slider-nav">
    <div class="slick-slide"><a><img src="/assets/images/slider-img-2.jpg" width="100%" alt="" /></a></div>
    <div class="slick-slide"><a><img src="/assets/images/slider-img-2.jpg" width="100%" alt="" /></a></div>
    <div class="slick-slide"><a><img src="/assets/images/slider-img-2.jpg" width="100%" alt="" /></a></div>
  </div>
</div>

<!----------------------Hero Slider Section End--------------------->



<!----------------------Home Event Info Section Start--------------------->

<div class="home-event-info">
  <div class="container">
    <div class="row clearfix">
      <div class="hfp-col-3">
        <div class="home-evnt-box active">
          <a href="#" id="event1">   <strong>120</strong>Upcoming  Events</a>
          <div class="active-arrow"></div>
        </div>
      </div>

      <div class="hfp-col-3">
        <div class="home-evnt-box">
          <a href="#" id="event2">  <strong>85</strong>Running  Events</a>
          <div class="active-arrow"></div>
        </div>
      </div>

      <div class="hfp-col-3">
        <div class="home-evnt-box">
          <a href="#" id="event3">   <strong>2000+</strong>Corporate Users</a>
          <div class="active-arrow"></div>
        </div>
      </div>

      <div class="hfp-col-3">
        <div  class="home-evnt-box">
          <a href="#" id="event4"> <strong>2568+</strong>Exhibitor Users</a>
          <div class="active-arrow"></div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="event-map-section">
  <img src="assets/images/home-event-map.jpg" width="100%" alt="" />
</div>


<!----------------------Home Event Info Section End--------------------->
<!--third-->
<div class="event-map-section">
  <img src="assets/images/home-event-map.jpg" width="100%" alt="" />
</div>

<!-------------------------Home About Section Start---------------------------->

<div class="home-about-section">
  <div class="container">
    <div class="clearfix row">
      <div class="hfp-col-6">
        <div class="home-about-txt">
          <h1>About Us</h1>
          <p><b>HealthFairsPlus.com</b> finds Virtual wellness tools and information across the web and brings them together in one place for your convenience.<br><br>
            Much like an onsite health fair or trade show, the information provided on this site comes from a variety of health and wellness professionals.<br><br>

            Here you will find information from local doctors, nutritionists, personal trainers, non-profit groups, government agencies, and other health organizations world wide!<br><br>

            HealthFairsPlus.com strives to provide the most interactive experience possible.  Many of our pages contain free health assessments, tools, widgets and/or links to pages with fun and interactive activities.  We are constantly growing and changing.  If you are aware of a unique Virtual health tool or activity, we would love to hear about it.</p>
        </div>
      </div>
      <div class="hfp-col-6">
        <div class="home-about-img"><img src="assets/images/home-about-img.jpg" alt="" /></div>
      </div>
    </div>
  </div>
</div>

<!-------------------------Home About Section End---------------------------->

<!-------------------------Home Benefits Of Corporate Section Start---------------------------->

<div class="benefits-of-corporate-section" style="background-image:url(assets/images/benefits-of-orporate-img.jpg);">
  <div class="bg-overly"></div>
  <div class="container poi-r-z">
    <h1>Benefits of Corporate Account</h1>
    <p>Need help coordinating an employee wellness event?  HealthFairsPlus.com provides FREE Virtual tools that can help you plan, track, and execute your corporate wellness events.  Or take advantage of our full event management services. </p>

    <div class="row clearfix">
      <div class="hfp-col-6 pull-right">
        <div class="img-video-box">
          <img src="assets/images/home-video.jpg" alt="" />
        </div>
      </div>
      <div class="hfp-col-6 pull-left">
        <div class="txt-ul">
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Nam molestie eros ac fringilla commodo.</li>
            <li>Morbi velit massa, iaculis a efficitur quis, maximus in neque.</li>
            <li>Curabitur id nunc ligula. Sed tellus velit, </li>
          </ul>
          <div class="signup-btn"><a href="signup.html"><i><img src="assets/images/signup-btn-icon.png" alt="" /></i> Sign Up</a> </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-------------------------Home Benefits Of Corporate Section End---------------------------->

<!-------------------------Home Benefits Of Exhibitor Section Start---------------------------->

<div class="benefits-of-exhibitor-section" style="background-image:url(assets/images/benefits-of-exhibitor-img.jpg);">
  <div class="bg-overly"></div>
  <div class="container poi-r-z">
    <h1>Benefits of Exhibitor Account</h1>
    <p>Need help coordinating an employee wellness event?  HealthFairsPlus.com provides FREE Virtual tools that can help you plan, track, and execute your corporate wellness events.  Or take advantage of our full event management services. </p>
    <div class="row clearfix">
      <div class="hfp-col-6">
        <div class="img-video-box">
          <img src="assets/images/home-img.jpg" alt="" />
        </div>
      </div>
      <div class="hfp-col-6">
        <div class="txt-ul">
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Nam molestie eros ac fringilla commodo.</li>
            <li>Morbi velit massa, iaculis a efficitur quis, maximus in neque.</li>
            <li>Curabitur id nunc ligula. Sed tellus velit, </li>
          </ul>
          <div class="signup-btn"><a href="signup.html"><i><img src="assets/images/signup-btn-icon.png" alt="" /></i> Sign Up</a> </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-------------------------Home Benefits Of Exhibitor Section End---------------------------->

<!-------------------------Home News Slider Section Start---------------------------->

<div class="home-event-slider">
  <div class="container">
    <h1>LATEST NEWS</h1>
    <div class="home-event-slider-box">
      <div class="home-event-row clearfix">
        <carousel>
          <slide>
            <div class="hfp-col-6 wow bounceInRight animated"><div class="home-event-row-left"><strong>FREE EMPLOYEE HEALTH FAIRS</strong> <p>April 3rd, 2013 by HFP Admin    14 Comments</p></div></div>
            <div class="hfp-col-6 wow bounceInRight animated"><div class="home-event-row-right"><p>Health Fairs Plus offers free employee health fair tools and materials for corporations across America.  If you are hosting an employee health fair then check out our useful health fairs tools which can help you coordinate your event. <a href="#">Read More</a> </p></div></div>
          </slide>

          <slide>
            <div class="hfp-col-6 wow bounceInRight animated"><div class="home-event-row-left"><strong>FREE EMPLOYEE HEALTH FAIRS</strong> <p>April 3rd, 2013 by HFP Admin    14 Comments</p></div></div>
            <div class="hfp-col-6 wow bounceInRight animated"><div class="home-event-row-right"><p>Health Fairs Plus offers free employee health fair tools and materials for corporations across America.  If you are hosting an employee health fair then check out our useful health fairs tools which can help you coordinate your event. <a href="#">Read More</a> </p></div></div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</div>

<!-------------------------Home News Slider Section End------------------------------>
