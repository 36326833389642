import { Injectable, Output, Input, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  currentUser: any = null;
  @Output() authChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() foldTempData: EventEmitter<boolean> = new EventEmitter();

  foldData = new Subject<string[]>();
  foldData$ = this.foldData.asObservable();
  constructor() { }
  sendFoldData(data) {
    this.foldData.next(data);
  }

  sendTempData(data) {
    this.foldTempData.emit(data);
  }
}
