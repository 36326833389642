import { Injectable } from '@angular/core';
@Injectable()
export class Utility {
/**
 * this method is uesed to get Index for proper pagination and take two arguments.
 */
  getIndex(index: number, length: number): number {
    index = (Math.ceil((index + 1) / 4) * 4) - 1;
    if (length < 4) {
      index = length - 1;
    }
    if (index > length) {
      index = length - 1;
    }
    return index;
  }
}
