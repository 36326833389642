<header class="" [ngClass]="{'hd-z': isScrolled, 'nologinheader' : !isUserLoggedIn}">
  <div class="container-fluid clearfix">
    <div class="hdr-top">
      <div class="logo" *ngIf="!isUserLoggedIn"><a routerLink="/"><img src="/assets/images/new-logo-SVG.png" /></a>
      </div>
      <div class="logo" *ngIf="isUserLoggedIn"><a><img src="/assets/images/new-logo-SVG.png" /></a></div>
      <div class="mobile-menu-top hide-desktop"><a (click)="mobRes($event)" id="menu"><i><img
              src="/assets/images/nav-icon.png" alt="icon" /></i></a></div>
      <div class="main-menu clearfix hide-mobile" [ngClass]="{'login':!isUserLoggedIn}">
        <div hidden class="hdr-search " id="updiv">
          <input type="text" value="" placeholder="Search here ..." class="bigsearch">
          <input type="submit" value="">
        </div>
        <nav>
          <ul  class="hide-mobile newmenu" *ngIf="!isUserLoggedIn" >
            <li *ngFor="let menu of staticMenu; let idx = index;" class="{{menu.menuName}}-nav-lcons">
              <a *ngIf="(menu.menuType === 'Header Menu' && menu.status === 1) && ((menu.auth === 3 && menu.accessLevel === 21) ||
              ( menu.accessLevel === 21 && menu.auth == 2))"
              (click)="mobRes($event);getRouterLinkFromPageItems({linkTo:menu.linkTo, menuName: menu.menuName, auth: menu.auth})"
              routerLinkActive="active">
                {{menu.menuName}}
              </a>

            </li>
          </ul>
          <ul class="hide-mobile">
            <li *ngIf="!hideLogoutButton">
              <a (click)="backToTheSuperAdminPage()">
                Back to Home</a>
            </li>

            <li *ngIf="!isUserLoggedIn"><a href="" routerLink="/login"><i aria-hidden="true" class="fa fa-user"></i>
                Login</a></li>

            <li class="signupdrop" *ngIf="!isUserLoggedIn">
              <a><i aria-hidden="true" class="fa fa-file-text"></i> Sign Up <i class="fa fa-caret-down"
                  aria-hidden="true"></i></a>
              <div class="signup-dropdown">
                <div class="signup-dropdown-in">
                  <a href="" [routerLink]="['signup', 'host']" (click)="signTabToggle('hostEvent');">Host an Event</a>
                  <a href="" [routerLink]="['signup' , 'attend']" (click)="signTabToggle('attendEvent');">Attend an
                    Event</a>
                </div>
              </div>
            </li>
            <li class="user-login" *ngIf="isUserLoggedIn && loginUser">
              <a routerLink="/profile">
                <span>
                  <div *ngIf="userRole == 10 || userRole == 20" class="hdr-message_notification">{{totalMszCounts}}
                  </div>
                  <img *ngIf="loginUser && loginUser.image && loginUser.image.type == 'avatar'"
                    src="/assets/images/avatars/{{loginUser.image.name}}" />
                  <img *ngIf="loginUser && loginUser.image && loginUser.image.type !== 'avatar'"
                    src="/assets/images/users/{{loginUser.image.name}}" />
                </span> {{'WELCOME' | translate}}   {{((loginUser.role === 20 ?  loginUser.username || loginUser.email : loginUser.username) ||loginUser.email | slice:0:7)}} <i
                  class="fa fa-caret-down" aria-hidden="true"></i></a>
              <div class="nav-dropdown-menu">
                <a routerLink="/profile" routerLinkActive="active">{{'MY_ACCOUNT' | translate}}</a>
                <a (click)="userLogout();" *ngIf="hideLogoutButton">{{'LOGOUT' | translate}} </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div class="clear"></div>
    </div>
    <div class="submenu hide-desktop no-login-user" id="clickmenu" *ngIf="!isUserLoggedIn"
      [ngClass]="{'menu-slide': myVar}">
      <div class="container-fluid clearfix">
        <ul>
          <li *ngFor="let menu of staticMenu; let idx = index;" class="{{menu.menuName}}-nav-lcons">
            <a *ngIf="(menu.menuType === 'Header Menu' && menu.status === 1) && ((menu.auth === 3 && menu.accessLevel === 21) ||
            ( menu.accessLevel === 21 && menu.auth == 2))"
             (click)="getRouterLinkFromPageItems({linkTo:menu.linkTo, menuName: menu.menuName, auth: menu.auth})" 
             [ngClass] = "{'active': menu.linkTo.page === activePagePermalink  }">
              {{menu.menuName}}
            </a>
          </li>
        </ul>

        <ul>
          <li hidden class="hide-desktop">
            <div class="hdr-search">
              <input id="smsearch" type="text" value="" placeholder="Search here ..." class="bigsearch">
              <input id="clicksearch" type="submit" value="">
            </div>
          </li>
          <li><a (click)="mobRes($event)" href="" routerLink="/login"><i aria-hidden="true" class="fa fa-user"></i>
              Login</a></li>

          <li class="signupdrop">
            <a><i aria-hidden="true" class="fa fa-file-text"></i> Sign Up <i class="fa fa-caret-down"
                aria-hidden="true"></i></a>
            <div class="signup-dropdown">
              <div class="signup-dropdown-in">
                <a (click)="mobRes($event)" href="" [routerLink]="['signup', 'host']">Host an Event</a>
                <a (click)="mobRes($event)" href="" [routerLink]="['signup' , 'attend']">Attend an Event</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="submenu" id="clickmenu" *ngIf="isUserLoggedIn"
      [ngClass]="{'menu-slide': myVar, 'c-e-l-bg': loginUser && loginUser.role == '10', 'e-e-l-bg': loginUser && loginUser.role == '20'}">
      <div class="container-fluid clearfix">

        <ul>
          <li hidden class="hide-desktop">
            <div class="hdr-search">
              <input id="smsearch" type="text" value="" placeholder="Search here ..." class="bigsearch" />
              <input id="clicksearch" type="submit" value="" />
            </div>
          </li>
          <li class="user-login hide-desktop" *ngIf="isUserLoggedIn && loginUser">
            <a (click)="isShow=!isShow">
              <span>
                <img *ngIf="loginUser && loginUser.image && loginUser.image.type == 'avatar'"
                  src="/assets/images/avatars/{{loginUser.image.name}}" />
                <img *ngIf="loginUser && loginUser.image && loginUser.image.type !== 'avatar'"
                  src="/assets/images/users/{{loginUser.image.name}}" />
              </span> {{'WELCOME' | translate}} {{(loginUser.username || loginUser.email | slice:0:7)}} <i
                class="fa fa-caret-down" aria-hidden="true"></i></a>
            <div class="nav-dropdown-menu" *ngIf="isShow">
              <a (click)="mobRes($event)" routerLink="/profile" routerLinkActive="active">{{'MY_ACCOUNT' | translate}}</a>
              <a (click)="userLogout();mobRes($event)" *ngIf="hideLogoutButton">{{'LOGOUT' | translate}} </a>
            </div>
          </li>

          <!---
            #TODO: ## refactor this menu list
          -->
          <li *ngFor="let menu of staticMenu; let idx = index;" class="{{menu.menuName | convertIntoDash}}-nav-lcons">
            <div *ngIf="menu && (menu.menuType.toLowerCase() === 'header menu' && menu.status === 1)">
              <div *ngIf="( (menu.accessLevel === 21 && menu.auth === 1) || (menu.auth === 1 && loginUser?.role === menu.accessLevel) || menu.auth === 3)">
                <div *ngIf="(menu.linkTo.type === 'L' && (menu.linkTo.url === '' || menu.linkTo.url === null) )&& loginUser?.role === menu.accessLevel">
                 <a routerLinkActive="active"> {{menu.menuName | capitalize}}
                   <i class="fa fa-caret-down"  aria-hidden="true"></i></a>
                  <div class="nav-dropdown-menu" id="submenu-11"  >
                    <div class="dropdown-menu-in">
                      <a (click)="mobRes($event); selectedRouterLinkIndex = idx" [routerLink]="subMenu.url"
                        *ngFor="let subMenu of menu.subMenu"
                        [ngClass] = "{'active': subMenu.url === activePagePermalink}"
                        routerLinkActive="active">
                        {{subMenu.subMenuName | capitalize}}
                      </a>
                    </div>
                  </div>
                </div>
                <div *ngIf="!(menu.linkTo.type === 'L' && (menu.linkTo.url === '' || menu.linkTo.url === null) )
                   && loginUser?.role === menu.accessLevel || (menu.accessLevel === 21 && menu.auth === 1) || menu.auth === 3">
                   <a routerLinkActive="active"
                  (click)="mobRes($event); getRouterLinkFromPageItems({linkTo:menu.linkTo, menuName: menu.menuName, auth: menu.auth, index: idx});"[routerLink]="menu.linkTo.page"
                  [ngClass] = "{'active': activePagePermalink === menu.linkTo.page  }"  >
                    {{menu.menuName | capitalize}}
                  </a>
                </div>
              </div>
            </div>
          </li>
          <!-- <li class="post-nav-lcons">
            <a (click)="mobRes($event)" *ngIf="loginUser && loginUser.role == acl[1].value " routerLink="find-exhibitors">Find
              Exhibitors</a>
          </li>
          <li *ngIf="loginUser && loginUser.role == '20'" class="Events-nav-lcons"><a (click)="mobRes($event)"
              href="javascript.void(0)" routerLink="event"> View Events </a></li>
          <li *ngIf="loginUser && loginUser.role == '20'" class="submenu-icon9"><a href="javascript.void(0)"
              routerLink="exhibitor-invitation"> Invitation Request Status </a></li>
          <li *ngIf="loginUser && loginUser.role == '20'" class="submenu-icon10"><a (click)="mobRes($event)"
              href="javascript.void(0)" routerLink="review-events"> Review Events </a></li> -->
        </ul>
      </div>
    </div>
  </div>
</header>
