import { Component, Injector, OnInit } from '@angular/core';
import { WidgetService } from './widget.service';
import { WidgetTypeService } from '../widget-type/widget-type.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { forkJoin } from 'rxjs';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {

  widgetForm: FormGroup;
  widgetSearchForm: FormGroup;
  wAttributes: any = {};
  widgetTypePageItems: any = [];
  constructor(
    private widgetService: WidgetService,
    private widgetTypeService: WidgetTypeService,
    public globals: Globals,
    private injector: Injector,
    private fb: FormBuilder,
    private appComponent: AppComponent,
    private router: Router,
    private utility: Utility) {
  }

  ngOnInit() {

    this.widgetForm = this.fb.group({
      widgetName: ['', [Validators.required]],
      widgetTypeId: ['', [Validators.required]],
      auth: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });

    // This  is widget  seach form
    this.widgetSearchForm = this.fb.group({
      widgetName: ['', []]
    });
    // Copy object
    this.wAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // Load widget data
    this.getWidgetListCount();
    
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.widgetForm = this.fb.group({
    //     widgetName: ['', [Validators.required]],
    //     widgetTypeId: ['', [Validators.required]],
    //     auth: ['', [Validators.required]],
    //     status: ['', [Validators.required]]
    //   });

    //   // This  is widget  seach form
    //   this.widgetSearchForm = this.fb.group({
    //     widgetName: ['', []]
    //   });
    //   // Copy object
    //   this.wAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    //   // Load widget data
    //   this.getWidgetListCount();
    // } else {
    //   this.router.navigateByUrl("/");
    // }
  }

  // convenience getter for easy access to form fields
  get wf() {
    return this.widgetForm.controls;
  }

  // This method is used for search widget
  widgetSearch() {
    if (this.widgetSearchForm.valid) {
      this.getWidgetListCount();
    }
  }

  // Add Widget
  addWidget(): void {
    // Check Widget validation and save data in db
    if (this.widgetForm.valid) {
      this.widgetForm.value.status = Number(this.widgetForm.value.status);
      this.widgetForm.value.auth = Number(this.widgetForm.value.auth);
      this.widgetService.addWidget(this.widgetForm.value)
        .subscribe(data => {
          this.toggleForm();
          this.widgetForm.reset();
          // Push new object on zero index
          this.wAttributes.pageItems.splice(0, 0, data);
          this.wAttributes.count += 1;
        }, (err) => {

        });
    } else {
      this.wAttributes.submitted = true;
    }
  }

  // Manage show/hide Widget  form
  toggleForm(): void {
    if (!(this.wAttributes.isIndex >= 0)) {
      this.wAttributes.isShow = !this.wAttributes.isShow;
    }
    this.wAttributes.submitted = false;
    this.widgetForm.reset();
    this.wAttributes.isIndex = -1;
    this.wAttributes.isActiveIdx = -1;
    this.wAttributes.itemArray = [];
    this.wAttributes.statusSelected = 1;
    // Set default  radio button value
    this.widgetForm.get('status').setValue(this.wAttributes.statusSelected);
  }

  // Get single Widget  according to index
  getWidgetForEdit(index: number): void {
    this.toggleForm();
    setTimeout(() => {
      this.wAttributes.isActiveIdx = index;
      this.wAttributes.isIndex = this.utility.getIndex(index, this.wAttributes.pageItems.length);
      this.wAttributes.isShow = false;
      this.wAttributes.itemArray = this.wAttributes.pageItems[index];
      this.widgetForm.patchValue(this.wAttributes.itemArray);
      this.wAttributes.statusSelected = this.wAttributes.itemArray.status;
    }, 0);
  }

  // Update Widget
  updateWidget(id: string): void {
    if (this.widgetForm.valid) {
      this.widgetForm.value.status = Number(this.widgetForm.value.status);
      this.widgetForm.value.auth = Number(this.widgetForm.value.auth);
      this.widgetService.updateWidget(id, this.widgetForm.value)
        .subscribe(data => {
          this.getWidget(id);
          this.wAttributes.isIndex = -1;
          this.wAttributes.isActiveIdx = -1;
          this.widgetForm.reset();
        }, (err) => {

        });
    } else {
      this.wAttributes.submitted = true;
    }
  }

  // Update Widget
  deleteWidget(index: number): void {
    if (index >= 0) {
      this.widgetForm.patchValue(this.wAttributes.pageItems[index]);
      this.widgetForm.value.status = Number(2);
      this.widgetService.updateWidget(this.wAttributes.pageItems[index].id, this.widgetForm.value)
        .subscribe(data => {
          // remove item from array
          this.wAttributes.pageItems.splice(index, 1);
          this.wAttributes.count -= 1;
        }, (err) => {

        });
    }
  }

  // Get single Widget
  getWidget(id: string): any {
    this.widgetService.getWidgetById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.wAttributes.pageItems.findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          this.wAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      }, (err) => {

      });
  }

  // Get Widget total count
  getWidgetCount(): void {
    const queryObj: any = {
      where: {
        status: { inq: [1, 0] }
      }
    };

    // This statement for serch widget  list
    if (this.widgetSearchForm && this.widgetSearchForm.value && this.widgetSearchForm.value.widgetName) {
      queryObj.where.widgetName = {
        regexp: 'm.*' + this.widgetSearchForm.value.widgetName + '/mi'
      };
    }
    this.widgetService.findWidgetCount(queryObj)
      .subscribe(data => {
        this.wAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => {

      });
  }

  // Get Widget  total count
  getWidgetList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        limit: limitNum,
        skip: skipNum,
      }
    };

    // This statement for serch widget type list
    if (this.widgetSearchForm && this.widgetSearchForm.value && this.widgetSearchForm.value.widgetName) {
      queryObj.filter.where.widgetName = {
        regexp: 'm.*' + this.widgetSearchForm.value.widgetName + '/mi'
      };
    }
    this.widgetService.findWidget(queryObj)
      .subscribe(data => {
        this.wAttributes.pageItems = (data && data.length) ? data : [];
      }, (err) => {

      });
  }

  // Get Widget Types Items for listing
  findWigetTypesItems() {
    const queryObj: object = {
      filter: {
        where: {
          status: 1,
        }
      }
    };
    this.widgetTypeService.findWidgetType(queryObj)
      .subscribe(data => {
        this.widgetTypePageItems = data;
      }, (err) => {

      });
  }

  // Get told  list/count
  getWidgetListCount(): void {
    forkJoin([this.getWidgetCount(), this.getWidgetList(this.wAttributes.limits, this.wAttributes.skips), this.findWigetTypesItems()]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getWidgetList(this.wAttributes.limits, startItem);
  }
}
