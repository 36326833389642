import { DatePipe } from "@angular/common";
import { SystemConstants } from "src/app/constants/system.constants";
import { EventUserDetails } from "./event-user-details";
import { ExhibitorEventInvite } from "./exhibitor-event-invite";
import { EventDetail } from "src/app/interface/event-details/event_detail.interface";

export interface IUserTemplatekeys{
  USER?:string[]
  EVENT?:string
  EVENTDATE?:string
  EVENTTIME?:string
  EVENADDRESS?:string
  URL?:string;
  AMOUNTDATA?:string;
  USERNAME?: string;
  INQUIRY_URL?:string;
  COMPANY_NAME?:string
  CORPORATE_CONTACT_NAME?:string
  TIME_ZONE?:string
}
export class EventUnregisterUserTemplateData {
  userId?:string;
  slug?:string;
  keys?:IUserTemplatekeys;

  constructor(createdExhibitorInvitation:ExhibitorEventInvite,
    datePipe?:DatePipe,eventDetails?:EventDetail) { 
    this.userId = '';
    this.slug = SystemConstants.UNREGISTER_USER;
    this.keys={
      USER: createdExhibitorInvitation.email,
      EVENT:createdExhibitorInvitation.eventName,
      EVENTDATE:datePipe.transform(createdExhibitorInvitation.eventDate,SystemConstants.MMM_D_Y),
      EVENTTIME:createdExhibitorInvitation.eventEndTime,
      EVENADDRESS: eventDetails && eventDetails.eventAddress && eventDetails.eventAddress.fullAddess ? eventDetails && eventDetails.eventAddress && eventDetails.eventAddress.fullAddess :createdExhibitorInvitation.eventAddress, 
      URL:"",
      AMOUNTDATA: createdExhibitorInvitation.amount.toString(),
      COMPANY_NAME:eventDetails.data?.company_name ? eventDetails.data?.company_name : eventDetails.company,
      TIME_ZONE: eventDetails && eventDetails.timeZone && eventDetails.timeZone.label ? eventDetails.timeZone.label : 'NA'
    }
  }
}

