<!-- ##TODO: Do not remove this comments it is fold logic -->

<!-- <div class="home-about-section">
  <div class="container">
    <div class="clearfix row">
      <div class="hfp-col-6">
        <div class="home-about-txt">
          <h1>{{foldData[0].heading}}</h1>
          <p [innerHTML]="foldData[0].description"></p>
        </div>
      </div>
      <div class="hfp-col-6">
        <div class="home-about-img"><img  [src]="foldData && foldData.length>0 ? (imageCdn + 'w_540,h_319/' + ((imageBaseUrl + '/fold/' + foldData[0].file[0]) | encodeUriComponent)):'' "  alt="loading .."/></div>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="benefits-of-corporate-section two-row-section" style="background-image:url(assets/images/benefits-of-orporate-img.jpg);">
    <div class="bg-overly"></div>
    <div class="container poi-r-z">
      <h1>{{foldData[0].heading}}</h1>
      <p [innerHTML]="foldData[0].subHeading"></p>
      <div class="row clearfix">
        <div class="hfp-col-6 hide-desktop">
        </div>
        <div class="hfp-col-6">
          <div class="txt-ul">
            <div [innerHTML]="foldData[0].description"></div>
            <div class="signup-btn"><a   [routerLink]= "['signup', 'host']"><i><img src="assets/images/signup-btn-icon.png" alt="" /></i> Sign Up</a> </div>

          </div>
        </div>
        <div class="hfp-col-6 hide-mobile">
            {{foldData[0].type}}
          <div class="img-video-box">
             <img *ngIf="foldData[0].type === foldConstants.IMAGE"  [src]="foldData && foldData.length>0 ? (imageCdn + 'w_540,h_319/' + ((imageBaseUrl + '/fold/' + foldData[0].file[0]) | encodeUriComponent )):'' "  alt="loading .."/>
          <video *ngIf="foldData[0].type === foldConstants.VIDEO" style="width: 100%; height: 100%; position: relative; z-index: 1;"  controls  #videoPlayer>
              <source [src]="foldData && foldData.length>0 ? (imageCdn + 'w_540,h_319/' + ((imageBaseUrl + '/fold/' + foldData[0].file[0]) )):'' " type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div> -->

<!-- ##TODO: Do not remove this comments it is fold logic -->

  <div class="benefits-of-corporate-section two-row-section" style="background-image:url(assets/images/benefits-of-orporate-img.jpg);">
    <div class="bg-overly"></div>
    <div class="container poi-r-z">
      <h1>Onsite Wellness Services</h1>
      <p class="hd-desc">Need help coordinating onsite wellness services?  <br>
        Health Fairs Plus can provide in-person wellness professionals for your event</p>
      <div class="row clearfix">
        <div class="hfp-col-6 hide-desktop"> <img src="assets/images/onsite-wellness-services.jpg" width="100%" alt="Onsite Wellness Services" />
        </div>
        <div class="hfp-col-6">
          <div class="txt-ul">
            <ul>
              <li>Services include massage therapy, seminars, cooking demos, fitness and 
                relaxation, health fairs screenings and more</li>
              <li>A dedicated event manager will help from start to end</li>
              <li>Marketing and promotional materials are available</li>
              
             </ul>
             <div class="fold-desc">
              <p>For more information, please reach out at info@healthfairsplus.com or by 
                submitting your request here:</p>
             </div>
             
             <div class="signup-btn"><a href="/contact-us">Contact Us</a> </div>
           </div>
        </div>
        <div class="hfp-col-6 hide-mobile">
           
          <div class="img-video-box">
           
            <img src="assets/images/onsite-wellness-services.jpg" alt="Onsite Wellness Services" />
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="benefits-of-exhibitor-section two-row-section font-300" style="background-image:url(assets/images/webinars-workshops-fold-bg.jpg);">
    <div class="bg-overly"></div>
    <div class="container poi-r-z">
      <h1>Webinars, Workshops, Fitness Classes</h1>
      <p class="hd-desc">Health Fairs Plus works with a wide range of health and wellness professionals to provide these types <br>of presentations both virtually and onsite for your workforce:</p>
      <div class="row clearfix">
        <div class="hfp-col-6 hide-desktop"> 
          <img src="assets/images/webinars-workshops-fold-img.jpg" width="100%" alt="Virtual Employee Challenges" />
        </div>
        <div class="hfp-col-6 hide-mobile">
          <div class="img-video-box">
             <img src="assets/images/webinars-workshops-fold-img.jpg" alt="Virtual Employee Challenges" />
          </div>
        </div>
        <div class="hfp-col-6">
          <div class="txt-ul">
            <ul>
              <li>Virtual webinars and onsite seminars</li>
              <li>Cooking demos</li>
              <li>Stress management and relaxation sessions</li>
              <li>Interactive wellness workshops</li>
              <li>Fitness classes</li>
             </ul>
             <div class="signup-btn"><a href="/contact-us">Request our catalog</a> </div>
           
            
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="benefits-of-corporate-section two-row-section" style="background-image:url(assets/images/benefits-of-orporate-img.jpg);">
  <div class="bg-overly"></div>
  <div class="container poi-r-z">
    <h1>About Us</h1>
    <p class="hd-desc">Health Fairs Plus has been in operation since 2002 and is based in Haymarket VA just outside of Washington, DC.<br> Our programs allow us to provide efficient and effective services anywhere you have employees. </p>
    <div class="row clearfix">
      <div class="hfp-col-6 hide-desktop"> 
        <img src="assets/images/home-about-section-img.jpg" width="100%" alt="About" />
      </div>
      <div class="hfp-col-6">
        <div class="fold-desc"><p >
          We help coordinate more than 500 employee wellness programs each year and we continue to lead the industry in innovation and inspiration
<br><br>
Our goal is to make use of the most interactive corporate wellness options available to keep employee health up and corporate healthcare costs down. Our Virtual tools and resources allow us to provide programs nationwide wide while our database of local health and wellness professionals make it possible to keep a local feel to your programs. From virtual wellness to onsite programs, Health Fairs Plus has become the single go-to resource for your employee wellness programs
        </p></div>
      </div>
      <div class="hfp-col-6 hide-mobile">
         
        <div class="img-video-box">
         
          <img src="assets/images/home-about-section-img.jpg" alt="About" />
        </div>
      </div>
    </div>
  </div>
</div>



