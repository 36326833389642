import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import IEmailTemplate type interface.
import { IEmailTemplate } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  // Define the class property.
  private urlEmailTempalate: string = environment.apiUrl + '/email-templates';
  private urlEmailTempalateType: string = environment.apiUrl + '/email-template-types';
  constructor(private http: HttpClient) { }

  /**
   * this service add emailTempalte.
   */
  addEmailTempalte(widgetObj: IEmailTemplate): Observable<IEmailTemplate> {
    // console.log(widgetObj);
    // // return;
    return this.http.post<IEmailTemplate>(this.urlEmailTempalate, widgetObj);
  }

  /**
   * this service return total count of emailTempalte .
   */
  findEmailTempalteCount(widgetObj: object): Observable<any> {
    return this.http.get<any>(this.urlEmailTempalate + '/count?' + qs.stringify(widgetObj));
  }

  /**
   * this service return emailTempalte list according to parms.
   */
  findEmailTempalte(widget: object): Observable<[]> {
    return this.http.get<[]>(this.urlEmailTempalate + '?' + qs.stringify(widget));
  }
  /**
   * this service delete emailTempalte .
   */
  deleteEmailTempalte(id: string): Observable<IEmailTemplate> {
    return this.http.delete<IEmailTemplate>(this.urlEmailTempalate + '/' + id, {});
  }

  /**
   * this service update email template using id.
   */
  updateEmailTemplate(id: string, emailTempObj: IEmailTemplate): Observable<IEmailTemplate> {
    return this.http.patch<IEmailTemplate>(this.urlEmailTempalate + '/' + id, emailTempObj);
  }

  /**
   * this service get email template using id.
   */
  getEmailTemplateById(id: string): Observable<IEmailTemplate> {
    return this.http.get<IEmailTemplate>(this.urlEmailTempalate + '/' + id);
  }

  /**
   * this service return emailTempalteType list according to parms.
   */
  findEmailTempalteType(widget: object): Observable<[]> {
    return this.http.get<[]>(this.urlEmailTempalateType + '?' + qs.stringify(widget));
  }

}
