import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import widget type interface.
import { ITaxonomyType } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxomomyTypeService {
  // Define the class property.
  private url: string = environment.apiUrl + '/taxonomy-types';
  constructor(private http: HttpClient) { }

  /**
   * This service add taxonomy type
   */

  addTaxonomyType(taxonomyTypeObj: ITaxonomyType): Observable<ITaxonomyType> {
    return this.http.post<ITaxonomyType>(this.url, taxonomyTypeObj);
  }

  /**
   * This service return total cound of taxonomy type
   */
  getTaxonomyTypeList(taxonomyTypeObj: object): Observable<[]> {
    return this.http.get <[]>(this.url + '?' + qs.stringify(taxonomyTypeObj));
  }

  /**
   * This service return total count of taxonomy type
   */
  getTaxonomyTypeCount(taxonomyTypeObj: object): Observable<any> {
    return this.http.get<any>(this.url + '/count?' + qs.stringify(taxonomyTypeObj));
  }

  /**
   * this service update Taxonomy type.
   */
  updateTaxonomyType(id: string, taxonomyTypeObj: ITaxonomyType): Observable<ITaxonomyType> {
    return this.http.patch<ITaxonomyType>(this.url + '/' + id, taxonomyTypeObj);
  }
 /**
  * this service get Taxonomy type using id.
  */
  getTaxonomyTypeById(id: string): Observable<ITaxonomyType> {
    return this.http.get<ITaxonomyType>(this.url + '/' + id);
  }


}
