import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.sass"],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    public bsModalService: BsModalService
  ) {}

  ngOnInit() {}
}
