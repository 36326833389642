<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-heading-right">
        <h1>Manage Fold Type</h1>
      </div>
    </div>
  </div>
</div>
<div class="main-admin-search pg-search-bar">
  <div class="container clearfix">
    <form [formGroup]="searchFTForm" (ngSubmit)="searchFoldType();">
      <div class="main-admin-search-box">
        <input type="text" value="" formControlName="searchFoldType" placeholder="{{'SEARCH_FOLD_TYPE' | translate}}" />
      </div>
      <div class="main-admin-search-button">
        <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i>{{"SEARCH" | translate}}</button>
      </div>
    </form>
    <div class="main-admin-add" >
      <a id="show-add-section" class="outline-btn" href="javascript:void(0)" (click)="toggleForm()"><i class="fa fa-plus" aria-hidden="true"></i> {{"ADD_FOLD_TYPE" | translate}}</a>
    </div>
  </div>
</div>

<!-- Define form template here-->

<div *ngIf="fTAttributes.isShow; then foldTypeFormTemp"></div>

<!-- Fold Type List Section content Start here-->

<div class="add-box-list-section">
  <div class="container">
    <div class="hfp-row clearfix">
      <div *ngFor="let data of fTAttributes.pageItems let idx = index">
          <div class="hfp-col-3" [ngClass]="{active:fTAttributes.isActiveIdx==idx}">
          <div class="a-b-l-s-div manage-page-box">
            <div class="manage-page-img" style="background-image:url(/assets/images/admin-pg-img-1.jpg);"></div>
            <div class="img-overly"></div>
            <div class="box_content_center">
              <div class="box_content_center_in">
                <h3>{{ data.foldTypeName | truncate:[43, '...'] | capitalize }}</h3>
                <div class="a-d-edit">
              <ul>
                <li><a (click)="getfoldTypeForEdit(idx);" href="javascript:void(0)"><i class="fa fa-pencil" aria-hidden="true"></i></a></li>
                <li *ngIf="globals.developmentKey"><a href="javascript:void(0)" (click)="deleteFoldType(idx)"><i class="fa fa-trash-o" aria-hidden="true"></i></a></li>
              </ul>
            </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hfp-row clearfix ad-form-edit" *ngIf="fTAttributes.isIndex==idx;">
          <div class="hfp-col-12">
            <div *ngIf="fTAttributes.isIndex==idx; then foldTypeFormTemp"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination__box">
    <pagination [totalItems]="fTAttributes.count" [rotate]="fTAttributes.rotate" [maxSize]="fTAttributes.maxSize" [itemsPerPage]="fTAttributes.limits" (pageChanged)="pageChanged($event);"></pagination>
    </div>
  </div>
</div>

<!-- Fold Type List Section content End here-->

<!-- Fold Type Form template content Start here-->

<ng-template #foldTypeFormTemp>
  <div foldTypeFormTempclass="add-new-section" [ngClass]="{'edit_form_type': !fTAttributes.isShow}">
    <div class="container">
      <div class="add-new-container">
        <div class="form-close"><button (click)="toggleForm()"><img src="/assets/images/add-form-close.png" alt="" /></button></div>
        <h2>{{ (fTAttributes.itemArray.id ? "EDIT_FOLD_TYPE" : "ADD_FOLD_TYPE")  | translate}}</h2>
        <form [formGroup]="foldTypeForm" (ngSubmit)="fTAttributes.itemArray.id ? updateFoldType(fTAttributes.itemArray.id) : addFoldType()">
          <div class="fold-form">
            <div class="hfp-input-div">
              <label>{{"ENTER_FOLD_TYPE_NAME" | translate}}</label>
              <input class="" type="text" placeholder="{{'ENTER_FOLD_TYPE_NAME' | translate}}" formControlName="foldTypeName">
              <div *ngIf="ft.foldTypeName.invalid && (ft.foldTypeName.dirty || fTAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="ft.foldTypeName.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="ft.foldTypeName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                <div *ngIf="ft.foldTypeName.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
                  | translate}}</div>
              </div>
            </div>
            <div class="hfp-input-div" *ngIf="globals.developmentKey">
              <label>{{"ENTER_COMPONENT_NAME" | translate}}</label>
              <input class="" type="text" placeholder="{{'ENTER_COMPONENT_NAME' | translate}}" formControlName="dataComponent">
              <div *ngIf="ft.dataComponent.invalid && (ft.dataComponent.dirty || fTAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="ft.dataComponent.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="ft.dataComponent.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                <div *ngIf="ft.dataComponent.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS" | translate}}</div>
              </div>
            </div>
              <div class="hfp-input-div" *ngIf="globals.developmentKey">
              <label>{{"ENTER_FRONT_COMPONENT_NAME" | translate}}</label>
              <input class="" type="text" placeholder="{{'ENTER_FRONT_COMPONENT_NAME' | translate}}" formControlName="frontComponent">
              <div *ngIf="ft.frontComponent.invalid && (ft.frontComponent.dirty || fTAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="ft.frontComponent.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="ft.frontComponent.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                <div *ngIf="ft.frontComponent.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS" | translate}}</div>
              </div>
            </div>
            <div class="hfp-input-div">
              <label>{{"STATUS" | translate}}</label>
              <div class="switch-field Status-tab">
                <div *ngFor="let status of globals.statusType; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status" [value]="status.value" [checked]="fTAttributes.statusSelected==status.value" />
                  <label for="switch_left{{idx}}">{{status.name}}</label>
                </div>
              </div>
              <div *ngIf="ft.status.invalid && (ft.status.dirty || fTAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="ft.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
              </div>
            </div>
            <div class="buttons">
              <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
              <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!-- Fold Type Form content End here-->
