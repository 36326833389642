import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { TaxomomyTypeService } from './taxomomy-type.service';
import { forkJoin } from 'rxjs';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-taxonomy-type',
  templateUrl: './taxonomy-type.component.html',
  styleUrls: ['./taxonomy-type.component.scss']
})
export class TaxonomyTypeComponent implements OnInit {

  taxonomyTypeForm: FormGroup;
  searchTaxoTypeForm: FormGroup;
  tTAttributes: any = {};
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private taxomomyTypeService: TaxomomyTypeService,
    private injector: Injector,
    private appComponent: AppComponent,
    private router: Router,
    private utility: Utility) {
  }

  ngOnInit() {
    this.taxonomyTypeForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      status: ['', Validators.required]
    });

    // Copy object
    this.tTAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // Load data
    this.getTaxonomyTypeListCount();
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.taxonomyTypeForm = this.fb.group({
    //     name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
    //     status: ['', Validators.required]
    //   });

    //   // Copy object
    //   this.tTAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    //   // Load data
    //   this.getTaxonomyTypeListCount();
    // } else {
    //   this.router.navigateByUrl("/");
    // }
    // Taxonomy type add form

  }

  get tt() {
    return this.taxonomyTypeForm.controls;
  }

  // Add Taxonomy type
  addTaxonomyType(): void {
    // Check Taxonomy validation and save data in db
    if (this.taxonomyTypeForm.valid) {
      this.taxonomyTypeForm.value.status = Number(this.taxonomyTypeForm.value.status);
      this.taxomomyTypeService.addTaxonomyType(this.taxonomyTypeForm.value)
        .subscribe(data => {
          // Push new object on zero index
          this.tTAttributes.pageItems.splice(0, 0, data);
          this.tTAttributes.count += 1;
          this.taxonomyTypeForm.reset();
        }, (err) => {

        });
    } else {
      this.tTAttributes.submitted = true;
    }
  }

  // Manage show/hide Taxonomy type form
  toggleForm(): void {
    if (!(this.tTAttributes.isIndex >= 0)) {
      this.tTAttributes.isShow = !this.tTAttributes.isShow;
    }
    this.tTAttributes.submitted = false;
    this.taxonomyTypeForm.reset();
    this.tTAttributes.isIndex = -1;
    this.tTAttributes.isActiveIdx = -1;
    this.tTAttributes.itemArray = [];
    this.tTAttributes.statusSelected = 1;
    // Set default  radio button value
    this.taxonomyTypeForm.get('status').setValue(this.tTAttributes.statusSelected);
  }

  // This Function Update status 2 for delete taxonomy type
  deleteTaxonomyType(index: number): void {
    if (index >= 0) {
      this.taxonomyTypeForm.patchValue(this.tTAttributes.pageItems[index]);
      this.taxonomyTypeForm.value.status = Number(2);
      this.taxomomyTypeService.updateTaxonomyType(this.tTAttributes.pageItems[index].id, this.taxonomyTypeForm.value)
        .subscribe(data => {
          // remove item from array
          this.tTAttributes.pageItems.splice(index, 1);
          this.tTAttributes.count -= 1;
        }, (err) => {

        });
    }
  }
  // Get single Taxonomy type according to index
  getTaxonomyTypeForEdit(index: number): void {
    this.toggleForm();
    setTimeout(() => {
      this.tTAttributes.isIndex = this.utility.getIndex(index, this.tTAttributes.pageItems.length);
      this.tTAttributes.isShow = false;
      this.tTAttributes.isActiveIdx = index;
      this.tTAttributes.itemArray = this.tTAttributes.pageItems[index];
      this.taxonomyTypeForm.patchValue(this.tTAttributes.itemArray);
      this.tTAttributes.statusSelected = this.tTAttributes.itemArray.status;
    }, 0);
  }

  // Update Taxonomy type
  updateTaxonomyType(id: string): void {
    if (this.taxonomyTypeForm.valid) {
      this.taxonomyTypeForm.value.status = Number(this.taxonomyTypeForm.value.status);
      this.taxomomyTypeService.updateTaxonomyType(id, this.taxonomyTypeForm.value)
        .subscribe(data => {
          this.getTaxonomyType(id);
          this.tTAttributes.isIndex = -1;
          this.tTAttributes.isActiveIdx = -1;
          this.taxonomyTypeForm.reset();
        }, (err) => {

        });
    } else {
      this.tTAttributes.submitted = true;
    }
  }

  // Get single Taxonomy type
  getTaxonomyType(id: string): any {
    this.taxomomyTypeService.getTaxonomyTypeById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.tTAttributes.pageItems.findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          this.tTAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      }, (err) => {

      });
  }

  // Get taxonomy type  count
  getTaxonomyTypeCount(): void {
    const queryObj: any = {
      where: {
        status: { inq: [1, 0] }
      }
    };
    this.taxomomyTypeService.getTaxonomyTypeCount(queryObj)
      .subscribe(data => {
        this.tTAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => {

      });
  }

  // Get Taxonomy type total list
  getTaxonomyTypeList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        limit: limitNum,
        skip: skipNum,
      }
    };
    this.taxomomyTypeService.getTaxonomyTypeList(queryObj)
      .subscribe(data => {
        this.tTAttributes.pageItems = (data && data.length) ? data : [];
      }, (err) => {

      });
  }

  // Get told type list/count
  getTaxonomyTypeListCount(): void {
    forkJoin([this.getTaxonomyTypeCount(), this.getTaxonomyTypeList(this.tTAttributes.limits, this.tTAttributes.skips)]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getTaxonomyTypeList(this.tTAttributes.limits, startItem);
  }

}
