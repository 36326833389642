import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
// Import IEmailTemplate type interface.
import { IEmailTemplate } from '../client-schema';
import * as qs from 'qs';
@Injectable({
  providedIn: 'root'
})
export class UnregisteredExhibitorsService {

  private invitationBaseUrl: string = environment.apiUrl + '/exhibitor-invitations';
  private userBaseUrl: string = environment.apiUrl + '/users';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }


  
  findUnregisteredCount(queryObj: object): Observable<any> {
    return this.http.get<any>(this.invitationBaseUrl + '/count?' + qs.stringify(queryObj));
  }

  findUnregisteredList(queryObj: object): Observable<[]> {
    return this.http.get<any>(this.invitationBaseUrl + '?' + qs.stringify(queryObj));
  }

  updateUser(id: string, userObj: object): Observable<any> {
    return this.http.patch<any>(this.userBaseUrl + '/' + id, userObj);
  }


  registerUser(id: any, userObject: any): Observable<any> {
    userObject.role = 20;
    userObject.status = 1;
    userObject.eventStatus = 0;
    userObject.image = {};

    userObject.address = {};
    userObject.industry = [];
    userObject.region = [];
    userObject.data = {};
    return this.http.post<any>(this.invitationBaseUrl + '/createUser', userObject);
  }


  /**
   * this service get email template using id.
   */
  getEmailTemplateById(id: string): Observable<IEmailTemplate> {
    return this.http.get<IEmailTemplate>(this.invitationBaseUrl + '/' + id);
  }



  /**
   * this service send email.
   */
  sendEmail(queryObj: object): Observable<any> {
    return this.http.post<any>(this.invitationBaseUrl + '/emailForUnregiter', queryObj);
  }

  /**
   * this service update Unregister using id.
   */
  updateUnregister(id: string, paramObj: object): Observable<any> {
    return this.http.patch<any>(this.invitationBaseUrl + '/' + id, paramObj);
  }
}
