/// <reference types="@types/googlemaps" />

import { Component, OnInit, ElementRef, ViewChild, Injector } from "@angular/core";
import { AuthService } from "../services/auth.service";

import { ProfileService } from "../profile/profile.service";
import { ExhibitorService } from "./exhibitor.service";
import { AppComponent } from "../app.component";

import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { isEmpty, map, sum, forEach } from "lodash";
// Import utility method class
import { CreditCardValidators } from "angular-cc-library";
// Handle the global property
import { NotifierService } from "angular-notifier";
//import { Globals } from '../globals';
import { Router } from "@angular/router";

// import {} from "googlemaps";
import { MapsAPILoader } from "@agm/core";
import { MouseEvent } from "@agm/core";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { AboutComponent } from "../about/about.component";
import * as translateJson from '../../assets/i18n/en.json'
@Component({
  selector: "app-exhibitor-event-payment",
  templateUrl: "./exhibitor-event-payment.component.html",
  styleUrls: ["./exhibitor-event-payment.component.scss"],
})
export class ExhibitorEventPaymentComponent implements OnInit {
  userData: any;
  // notifier: NotifierService;
  eventPaymentCheckOutList: any = [];
  eventPaymentCheckOutListForPrint: any = [];
  tabName: string;
  total = 0;

  //authorize form start variable
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  miles: number = 20;
  private geoCoder;
  @ViewChild("search")
  public searchElementRef: ElementRef;

  authorizeForm: FormGroup;
  userInfoForm: FormGroup;
  step3form: FormGroup;
  region: FormArray;
  isSubmitted = false;
  userBillingSubmitted = false;
  finalformgSubmitted = false;
  public authorizeStep = "event-step-b-1";
  paymentSubmitted = false;
  savedMember: any;
  userInfoObj: any;
  finalFormObj: any;
   isFormSubmitted:boolean;
  prodList: Array<string | any>;
  //authorize form end variable

  private readonly notifier: NotifierService;
  translateJson: typeof translateJson = Object.assign({}, (translateJson as any).default);

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private exhibitorService: ExhibitorService,
    private formBuilder: FormBuilder,
    notifierService: NotifierService,
    private authService: AuthService,
    private appComponent: AppComponent,
    private injector: Injector
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.tabName = "payment";
    this.eventPaymentCheckOutList = this.profileService.getOption();
    this.eventPaymentCheckOutListForPrint = this.profileService.getOption();
    console.log("=>>>>>>");
    console.log(this.eventPaymentCheckOutList);
    console.log("---------<<<");

    if (isEmpty(this.eventPaymentCheckOutList)) {
      this.router.navigateByUrl("/profile");
    } else {
      this.prodList = map(this.eventPaymentCheckOutList, function (x) {
        return {
          eventId: x.eventId,
          eventName: x.eventName,
          eventPrice: x.eventPayment,
          recordId: x.id,
        };
      });
      if (
        this.eventPaymentCheckOutList &&
        this.eventPaymentCheckOutList.length > 0
      ) {
        const payment = map(this.eventPaymentCheckOutList, "eventPayment");
        this.total = sum(payment);
      }
    }

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.tabName = "cardInfo";
    //   this.firstFormInit();
    // } else {
    //   this.router.navigateByUrl("/");
    // }


    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.appComponent.userData;
          this.tabName = "cardInfo";
          this.firstFormInit();
          // console.log(this.userData);
        } else {
          this.router.navigateByUrl("/");
        }
      },
      () => {
        //console.log(err);
        this.router.navigateByUrl("/login");
      }
    );
  }
  proceedToPay() {
    this.tabName = "cardInfo";
    this.firstFormInit();
  }

  get regionArray() {
    return this.userInfoForm.get("region") as FormArray;
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    // this.getAddress(this.latitude, this.longitude);
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          var regionsObj = this.fb.group({
            name: [results[0].formatted_address],
            lat: [results[0].geometry.location.lat()],
            long: [results[0].geometry.location.lng()],
            miles: [this.miles],
            meters: [this.miles * 1609.34],
          }) as FormGroup;
          this.regionArray.push(regionsObj);
        }
      }
    );
  }
  /*
   * Remove circle
   */
  remvoeCircle(index: number): any {
    this.regionArray.removeAt(index);
  }
  firstFormInit() {
    this.authorizeForm = this.formBuilder.group({
      card: this.formBuilder.group({
        cardNumber: [
          "",
          [Validators.required, CreditCardValidators.validateCCNumber],
        ],
        expireAt: [
          "",
          [
            Validators.required,
            CreditCardValidators.validateExpDate,
            Validators.maxLength(7),
          ],
        ],
        cvv: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
          ],
        ],
        fullName: ["", [Validators.required, Validators.minLength(2)]],
        amount: [this.total, [Validators.required, Validators.min(1)]],
      }),
    });
    //this.googleAddress('autocomplete1');
  }
  ngAfterViewInit() { }

  thirdFormInit() {
    this.step3form = this.formBuilder.group({
      orderDesc: this.formBuilder.group({
        description: [""],
      }),
    });
  }

  backInintFirstForm() {
    this.authorizeStep = "event-step-b-1";
    this.firstFormInit();
    this.authorizeForm.patchValue(this.savedMember);
    setTimeout(() => {
      const cardNumberInput = document.querySelector('.card-nmr') as HTMLElement;
      if (cardNumberInput) {
        const event = new Event('input');
        cardNumberInput.dispatchEvent(event); 
      }
    })
  }

  //second form
  initBillingInfo() {
    this.userInfoForm = this.formBuilder.group({
      users: this.formBuilder.group({
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        company: "",
        address: ["", Validators.required],
        city: ["", Validators.required],

        state: ["", Validators.required],
        zipcode: ["", Validators.required],
        country: ["", Validators.required],

        phone: [
          "",
          [
            Validators.required,
          ],
        ],
        email: ["", [Validators.required, Validators.email]],
      }),
    });
    setTimeout(() => {
      this.googleAddress("autocomplete1");
    }, 2000);
  }
  backInintSecondForm() {
    this.initBillingInfo();
    this.authorizeStep = "event-step-b-2";
    this.userInfoForm.setValue({ users: this.userData });
    this.userInfoForm.setValue(this.userInfoObj);
  }
  //end second form

  // get power() { return this.userInfoForm.controls; }
  paymentSend() {
    if (this.authorizeForm.valid) {
      // console.log("I m in..22");
      const authorizeObj: any = Object.assign({}, this.authorizeForm.value);
      this.savedMember = authorizeObj;
      this.authorizeStep = "event-step-b-2";
      this.initBillingInfo();

      if (this.userInfoObj) {
        this.userInfoForm.patchValue(this.userInfoObj);
      } else if (this.userData) {
        this.userInfoForm.patchValue({
          users: {
            phone: this.userData.data.phone_number
              ? this.userData.data.phone_number
              : "",
            email: this.userData.email ? this.userData.email : "",
            company: this.userData.data.company_name
              ? this.userData.data.company_name
              : "",
          },
        });
        // console.log(this.savedMember);
        // console.log(this.userInfoObj);
      }
    } else {
      this.paymentSubmitted = true;
    }
  }
  finalPaymentSubmitFormWithUserInfo(){
      this.userInfoSubmitForm()
  }
  //get formControls() { return this.authorizeForm.controls; }
  userInfoSubmitForm() {
    if(this.phone.value){
      this.phone.setErrors(null);
      this.phone.updateValueAndValidity()
    }
    if (this.userInfoForm.valid) {
      const infObj: any = Object.assign({}, this.userInfoForm.value);
      this.userInfoObj = infObj;
      this.authorizeStep = "event-step-b-2";
      //  this.initBillingInfo();
      this.thirdFormInit();
      if (this.userInfoObj) {
        this.userInfoForm.patchValue(this.userInfoObj);
        //this.finalFormObj = finalObj;
      }
      this.finalSubmitForm()
    } else {
      this.userBillingSubmitted = true;
    }
  }
  finalSubmitForm() {
    if (this.step3form.valid) {
      const finalObj: any = Object.assign({}, this.step3form.value);
      if (finalObj) {
        this.finalFormObj = finalObj;
        const firstFormVal: any = Object.assign({}, this.savedMember.card);
        const secondFormVal: any = Object.assign({}, this.userInfoObj.users);
        const finalFormVal: any = Object.assign(
          {},
          this.finalFormObj.orderDesc
        );
        const sendObj: any = {
          userName: this.userData.username,
          userId: this.userData.id,
          cardNumber: firstFormVal.cardNumber.replace(/ /g, ""),
          cardExpiry: firstFormVal.expireAt
            .replace(/\//g, "")
            .replace(/ /g, ""),
          //  cardExpiry: "0922",
          cardCvv: firstFormVal.cvv.replace(/ /g, ""),
          prodDesc: finalFormVal.description,
          firstName: secondFormVal.firstName,
          lastName: secondFormVal.lastName,
          totalPayment: firstFormVal.amount,
          address: {
            address: secondFormVal.address,
            city: secondFormVal.city,
            state: secondFormVal.state,
            zip: secondFormVal.zipcode,
            country: secondFormVal.country,
          },
          prodList: this.prodList,
          phone: secondFormVal.phone,
          fullName: firstFormVal.fullName,
          orderDescription: finalFormVal.description,
        };

        this.exhibitorService.addPayment(sendObj).subscribe(
          (data) => {
            if (data.status == 400) {
              this.notifier.notify("error", data.desc);
              this. isFormSubmitted = false;
            } else if (data.id != "") {
              console.log(data);
              this.step3form.reset();
              this.userInfoForm.reset();
              this.authorizeForm.reset();
              //email start
              let temEmail: any = {};
              temEmail.userId = this.userData.id;
              temEmail.slug = "event-payment-template";
              //  temEmail.subject = 'Your Event Approved';
              var eventList = "";
              var amt = 0;
              forEach(this.prodList, function (value) {
                eventList +=
                  "<tr><td style='padding:5px;'>" +
                  value.eventName +
                  "</td><td style = 'padding:5px;'>$" +
                  value.eventPrice +
                  "</td></tr>";
                amt += value.eventPrice;
              });

              eventList +=
                "<tr><td style='padding:5px; font-size:15px;'><strong>Total</strong></td><td style='padding:5px;font-size:15px;'><strong>$" +
                amt +
                "</strong></td></tr>";

              temEmail.keys = {
                USERNAME: this.userData.username,
                transactionId: data.transId,
                invoiceNumber: data.invoiceNumber,
                eventData: eventList,
                companyName:this.userData && this.userData.data.company_name
              };
              this.emailSends(temEmail);

              //email end

              this.notifier.notify("success", "Your Payment is Successful.");
              this.router.navigateByUrl("/profile");
            }
            // this.finalformgSubmitted = false;
            // Push new object on zero index
            // this.wAttributes.pageItems.splice(0, 0, data);
            // this.wAttributes.count += 1;
          },
          (err) => {
            this.notifier.notify("error", err.error.error.message);
            console.log(err);
          }
        );
        /**/
        console.log(this.savedMember);
        console.log(this.userInfoObj);
        console.log(this.finalFormObj);
      }
    } else {
      this.finalformgSubmitted = true;
    }
  }

  emailSends(sendObj: any) {
    this.exhibitorService.sendEmail(sendObj).subscribe(
      (data) => {
        console.log("email send success");
        console.log(data);
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify("error", err.error.error.message);
        }
      }
    );
  }

  /*
   * Google Address
   */
  googleAddress(id: string) {
    console.log("aya");

    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>document.getElementById(id),
      {
        types: ["geocode"],
      }
    );
    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      const place = autocompleteFrom.getPlace();
      if (place.address_components && place.address_components.length) {
        console.log(place);
        if (place.formatted_address) {
          this.userInfoForm.patchValue({
            users: { address: place.formatted_address },
          });
        }
        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          if (addressType === "administrative_area_level_2") {
            this.userInfoForm.patchValue({
              users: { city: place.address_components[i].long_name },
            });
          }

          if (addressType === "administrative_area_level_1") {
            this.userInfoForm.patchValue({
              users: { state: place.address_components[i].long_name },
            });
          }
          if (addressType === "country") {
            this.userInfoForm.patchValue({
              users: { country: place.address_components[i].long_name },
            });
          }
          if (addressType === "postal_code") {
            this.userInfoForm.patchValue({
              users: { zipcode: place.address_components[i].long_name },
            });
          }
        }
      }
    });
  }
  removeEvent(index: number, amount: number) {
    this.eventPaymentCheckOutList.splice(index, 1);
    this.total -= amount;
    // this.profileService.removeOption(index);

    if (this.eventPaymentCheckOutList.length < 1) {
      this.router.navigateByUrl("/profile");
    }
  }
  
  get userInfoFormGroup(){
    return this.userInfoForm.get('users') as FormGroup
  }
  get data() {
    return this.userInfoFormGroup.controls;
  }
  get phone(){
    return this.data.phone
  }

}
