<a class="request-invitation-btn" href="javascript:void(0)" *ngIf=" !selectedEventTemplateDetails.eventReqExist && selectedExhibitorUser?.role === userRoleStatus.EXHIBITOR
&& selectedEventTemplateDetails.openCloseStatus === commonStatus.ACTIVE"
  (click)="openAndEditRequestPopUp(requestForm,selectedEventTemplateDetails);getScreeningTaxo();"><i></i> {{translateJson.REQUEST_INVITATION |
  translate}}</a>
<a href="javascript:void(0)" *ngIf="selectedEventTemplateDetails.eventReqExist"
  (click)="openAndEditRequestPopUp(requestForm,selectedEventTemplateDetails);getScreeningTaxo();">{{translateJson.EDIT_REQUEST | translate}}
</a>


<ng-template #requestForm let-c="close" let-d="dismiss">
  <button type="button" class="popclose" data-dismiss="modal" (click)="closePopup()" aria-label="Close"><img
      src="/assets/images/pop-close.png" alt=""></button>
  <div class="form-pop-txt">
   
    <div class="exhibitor-in-pop clearfix">

      <div class="hfp-row clearfix">

        <div class="hfp-col-4 pull-right">
          <div class="exhibitors-edit-right">
            <i><img src="/assets/images/exhibitor-pop-icon.png" alt=""></i>
            <h5>{{selectedEventTemplateDetails.eventName}}</h5>
            <p [innerHTML]="selectedEventTemplateDetails.eventDescription | truncate:[300, '...']"></p>
          </div>
        </div>
        <div class="hfp-col-8 pull-left">
          <div class="exhibitors-edit-left">
            <h3> {{translateJson.SEND_INVITATION_REQUEST | translate}} </h3>
            <app-unregister-event-request-form [eventRequestFormData]="selectedEventTemplateDetails?.eventReqData"
              [industriesList]="industriesList" [screeningParentList]="screeningParentList" [eventReqItem]="selectedEventTemplateDetails"
              [userData]="selectedExhibitorUser" (eventSubmitted)="onSubmitRequest($event)"></app-unregister-event-request-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>