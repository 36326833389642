import { EventDetail,EventInviteDetails } from "src/app/interface/event-details/event_detail.interface";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { SystemConstants } from "src/app/constants/system.constants";


export class ExhibitorEventInvite {
    constructor(eventInvite:EventInviteDetails,event:EventDetail,
        datePipe?:DatePipe
        ) {
            this.eventName = event.eventName || event.permalink;
            this.eventDate = event.eventDate || new Date();
            this.startDateTime = event.startDateTime ||  new Date();
            this.endDateTime = event.endDateTime || new Date();
            this.startTime = event.startDateTime || new Date();
            this.eventStartTimeFormatted = moment(this.startTime).format(SystemConstants.TIME_HOUR_AM);
            this.endTime = event.endDateTime || new Date();
            this.eventEndTimeFormatted = moment(this.endTime).format(SystemConstants.TIME_HOUR_AM);
            this.eventEndTime =
               datePipe.transform(this.startDateTime, SystemConstants.HOUR_MIN_SEC_AM) +
                " - " +
              datePipe.transform(this.endDateTime, SystemConstants.HOUR_MIN_SEC_AM);
            this.eventAddress = event.eventAddress.formatted_address || event.eventAddress.location;
                this.email = eventInvite.email,
                this.userId = event.memberId,
                this.eventId = event.id,
                this.eventName = event.eventName
                    ? event.eventName
                    : event.permalink,
                this.eventDate = this.eventDate,
                this.startTime = this.startDateTime,
                this.endTime = this.endDateTime,
                this.status = 0,
                this.amount = eventInvite.amount
    }
    eventName: string;
    eventDate: string | Date;
    startDateTime: Date;
    endDateTime: Date | string;
    startTime: string |Date;
    eventStartTimeFormatted: string;
    endTime: string | Date;
    eventEndTimeFormatted: string;
    eventEndTime: string;
    eventAddress: string;
    email: string[];
    userId: string;
    eventId: string;
    status: Number;
    amount: number
    id:string;
    
}