// Import Custome Services.
import { WidgetTypeService } from './widget-type/widget-type.service';
import { WidgetService } from './widget/widget.service';
import { FoldTypeService } from './fold-type/fold-type.service';
import { FoldService } from './fold/fold.service';
import { PageService } from './page/page.service';
import { MenuService } from './menu/menu.service';
import { TaxonomyService } from './taxonomy/taxonomy.service';
import { IndustriesService } from './industries/industries.service';
import { EventService } from './event/event.service';
import { TaxomomyTypeService } from './taxonomy-type/taxomomy-type.service';

// Import utility method class
import { Utility } from './utility';
// Import globals file
import { Globals } from './globals';

// Import all Services in array const.
export const serviceModule = [WidgetTypeService, WidgetService, FoldTypeService, FoldService, PageService, MenuService,
  MenuService, TaxonomyService, IndustriesService, EventService, Globals, Utility, TaxomomyTypeService];
