<div class="sub-tab-hd" *ngIf="templateData && templateData.length">
  <div class="hfpbox-row-fold" *ngFor="let data of templateData; let idx = index">
    <div class="hfpbox-row-fold-top clearfix">
      <div class="hfpbox-row-fold-hd">
        <h1>{{data.title ? data.title : 'Container' + (idx + 1)}}</h1>
        <span></span></div>
      <div class="hfpbox-row-fold-btn">
        <ul>
          <li><a href="javascript:void(0)" id="edit1" title="Edit">Edit</a></li>
          <li><a href="javascript:void(0)" title="Trash">Delete</a></li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>

  </div>
</div>
<div class="add-sub-form">

  <div class="hfp-input-div" style="width: 100%;">
    <label>Description</label>
  </div>

  <div class="buttons">
    <button class="btn btn-primary" type="submit">Save</button>
    <button class="btn btn-default">Cancel</button>
  </div>
</div>
<div class="add-fold-link"> <a href="javascript:void(0)"  class="text_link ng-binding" translate="ADD_MORE">Add More</a> </div>
