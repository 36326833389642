import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "encodeUriComponent",
})
export class EncodeUriComponentPipe implements PipeTransform {
  transform(url: string, args?: any): string {
    return url ? encodeURIComponent(url) : "";
  }
}
