
<div class="top-padding">
  <div class="contact-hd-hd" style="background-image:url(/assets/images/contact-bg.jpg);">
    <div class="img-overly"></div>
    <div class="container">
      <h1 translate="GET_IN_TOUCH"></h1>
    </div>
  </div>
</div>

<div class="contact-pg">
  <div class="container">
    <form [formGroup]="signUpForm" (ngSubmit)="signUpSubmit()">
      <div class="hfp-input-div">
          <label class="field-label">Your Name&nbsp;<span class="span_star">*</span></label>
          <input type="text" class="input-field" formControlName="name" name="yourname"
            placeholder="Your Name" required>
           <div *ngIf="sf.name.invalid && (sf.name.dirty || fAttributes.submitted)" class="custom_error">
          <div *ngIf="sf.name.errors.required">!! Your Name is required !! </div>
          <div *ngIf="sf.name.errors.minlength">Your Name should have two Characters
          </div>
          <div *ngIf="sf.name.errors.maxlength">Your Name is too Large !!!</div>
        </div>
        </div>

        <div class="hfp-input-div">
          <label class="field-label">Your Email&nbsp;<span class="span_star">*</span></label>
          <input type="text" class="input-field" placeholder="Your Email" formControlName="email"
            name="email" required>
          <div *ngIf="sf.email.invalid && (sf.email.dirty || fAttributes.submitted)" class="custom_error">
            <div *ngIf="sf.email.errors.required">!! Email is required !! </div>
            <div *ngIf="sf.email.errors.pattern">Email must be valid Pattern </div>
          </div>
        </div>

        <div class="hfp-input-div" formGroupName="data">
          <label class="field-label">Subject &nbsp;<span class="span_star">*</span></label>
          <input type="text" class="input-field" formControlName="subject" name="subject"
            placeholder="Subject" required>
          <div *ngIf="dataForm.subject.invalid && (dataForm.subject.dirty || fAttributes.submitted)"
               class="custom_error">
            <div *ngIf="dataForm.subject.errors.required"> !!Subject Is required !!
            </div>
          </div>
        </div>

      <div class="hfp-input-div" formGroupName="data">
        <label class="field-label">Message &nbsp;<span class="span_star">*</span></label>
        <textarea type="text" class="input-field" formControlName="message" name="message"
          placeholder="Message" required> </textarea>
        <div *ngIf="dataForm.message.invalid && (dataForm.message.dirty || fAttributes.submitted)"
             class="custom_error">

        </div>
      </div>

      <div class="clearfix"><br /></div>
        <div class="hfp-input-div"><div class="recaptcha-box">
          <ngx-recaptcha2 #captchaElem [siteKey]="'6LcokbcUAAAAAE81_fR5WYyVAYUZueDwIPfuTcE9'" (reset)="handleReset()"
            (expire)="handleExpire()" [useGlobalDomain]="false"
         formControlName="recaptcha"  >
          </ngx-recaptcha2>
          <!-- <div *ngIf="sf.recaptcha.invalid && (sf.recaptcha.dirty || fAttributes.submitted)" class="custom_error">
            <div *ngIf="sf.recaptcha.errors.required">!! Recaptcha is required !! </div>
          </div> -->
         </div></div>
      <div class="buttons">
          <button class="btn btn-primary">{{"SUBMIT" | translate }}</button>
          <!-- <button class="btn btn-dark">{{"Reset" | translate }}</button> -->
        </div>



    </form>
  </div>
</div>
<!--New -->
