<div class="bggray">
  <div class="container">
    <div class="sign-up-form">
      <div *ngIf="authorizeStep === 'event-step-b-0'">
        <div class="sign-up-form-top">
          <div class="signup-tab-btn" [ngClass]="{'active': signupFormShow == 1}">
            <button routerLink="/signup/host" (click)="corporateUserInit()">
              <i><img src="/assets/images/host-an-event-icon.png" alt=""></i><strong>Host an <b>Event</b></strong>
            </button>
          </div>
          <div class="signup-tab-btn" [ngClass]="{'active': signupFormShow == 2}">
            <button routerLink="/signup/attend" (click)="exhibitorUserInit()">
              <i><img src="/assets/images/attend-an-event-icon.png" alt=""></i><strong>Attend an <b>Event</b></strong>
            </button>
          </div>
        </div>
        <div class="sign-up-form-btm">
          <div class="host-event" *ngIf="signupFormShow == 1">
            <div class="signup-page clearfix">
              <div class="tab-content-left-bg Corporate-signup-left">
                <h2 class="sign-up-heading">Corporate Registration:</h2>
                <div class="p-txt">
                  <p>Thank you for registering at HealthFairsPlus.com. Our goal is to make use of the most innovative
                    corporate wellness options available to keep employee health up and corporate healthcare costs down.
                    Our Virtual tools and resources allow us to provide programs nation wide while our database of
                    local health and wellness professionals makes it possible to keep a local feel to your programs.<br>
                    <br> Please be sure to read the terms and conditions before proceeding. They can be accessed here:
                    <a href="/corporate-terms-and-conditions" target="_blank">Terms and Conditions.</a>
                    <br><br> <strong>Contact Us</strong><br> Please fill out this simple registration form. If you have
                    any questions regarding the sign up process, please contact us at <a
                      href="mailto:events@healthfairsplus.com">events@healthfairsplus.com</a>. We look forward to
                    working with your organization to provide the most interactive and engaging events possible!
                  </p>
                </div>
              </div>
              <form [formGroup]="signUpForm" (ngSubmit)="signUpSubmit()" autocomplete="off">
                <div class="tab-content-right-bg">

                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Company Name&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" formControlName="company_name" name="company_name"
                      placeholder="Company Name">
                  <div *ngIf="dataForm.company_name.invalid && fAttributes.submitted"
                    class="custom_error">
                    {{
                      'REQUIRED_MSG_WITH_PARAMS' |
                      translate: {'name': translatorJson.COMPANY_NAME | translate}
                      }} 
                          <!-- {{
                            getValidationErrors({
                              formControl: dataForm.company_name, formControlName: translatorJson.COMPANY_NAME,
                              minLength: 2, maxLength: 200,
                              isTextPattern: true
                              })
                          }} -->
                  </div>
                  </div>


                  <div class="sign-up-fields">
                    <label class="field-label">User Name&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" autocomplete="off" formControlName="username" name="username"
                      placeholder="User Name" required>
                  <div *ngIf="sf.username.invalid &&  fAttributes.submitted" class="custom_error">
                          <!-- {{
                          getValidationErrors({
                            formControl: sf.username, formControlName: translatorJson.USER_NAME,
                            minLength: 2, maxLength: 60
                            })
                          }} -->
                          {{
                            'REQUIRED_MSG_WITH_PARAMS' |
                            translate: {'name': translatorJson.USER_NAME | translate}
                          }} 
                      

                  </div>
                  </div>
                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Number of Employees&nbsp;<span class="span_star">*</span></label>
                    <input type="number" class="input-field" formControlName="number_employee" name="number_employee"
                      placeholder="No. of Employee" required>
                      <div
                        *ngIf="dataForm.number_employee.invalid && fAttributes.submitted"
                        class="custom_error">
                        {{
                          'REQUIRED_MSG_WITH_PARAMS' |
                          translate: {'name': translatorJson.NUMBER_OF_EMPLOYEES | translate}
                          }} 
                        <!-- {{
                        getValidationErrors({
                          formControl: dataForm.number_employee,
                          formControlName: translatorJson.NUMBER_OF_EMPLOYEES,
                          isNumericPattern: true
                          })
                        }} -->
                      </div>
                  </div>

                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Contact Name&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" formControlName="contact" name="contact"
                      placeholder="Contact Name" required>
                      <div
                        *ngIf="dataForm.contact.invalid && fAttributes.submitted"
                        class="custom_error">
                        {{
                          'REQUIRED_MSG_WITH_PARAMS' |
                          translate: {'name': translatorJson.CONTACT_NAME | translate}
                          }} 
                          <!-- {{
                          getValidationErrors({
                            formControl: dataForm.contact, formControlName: translatorJson.CONTACT_NAME,
                            minLength: 2, maxLength: 100,
                            isTextPattern: true
                            })
                          }} -->
                      </div>
                  </div>

                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Job/Title&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter your Job Title" formControlName="title"
                      name="title" required>
                    <div *ngIf="dataForm.title.invalid && fAttributes.submitted"
                      class="custom_error">
                      {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translatorJson.JOB_TITLE | translate}
                        }}
                      <!-- {{
                        getValidationErrors({
                        formControl: dataForm.title, formControlName: translatorJson.JOB_TITLE,
                        minLength: 2, maxLength: 100,
                        isTextPattern: true
                        })
                      }} -->
                    </div>
                  </div>

                  <div class="sign-up-fields">
                    <label class="field-label">Email&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Please Provide Email" formControlName="email"
                      name="email" required autocomplete="off">
                  <div *ngIf="sf.email.invalid &&fAttributes.submitted" class="custom_error">
                    {{
                      getValidationErrors({
                      formControl: sf.email, formControlName: translatorJson.EMAIL,
                      isEmailPattern: true
                      })
                    }}
                  </div>
                  </div>

                  <div class="sign-up-fields">
                    <label class="field-label">Password&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field numeric-password" placeholder="Password"
                      formControlName="password" name="password" required autocomplete="off">
                      <div *ngIf="sf.password.invalid && fAttributes.submitted" class="custom_error">
                    
                        {{
                          'REQUIRED_MSG_WITH_PARAMS' |
                          translate: {'name': translatorJson.PASSWORD | translate}
                          }} 
                        <!-- {{
                          getValidationErrors({
                          formControl: sf.password, formControlName: translatorJson.PASSWORD,
                          minLength: 6, maxLength: 25,
                          isPasswordPattern: true
                          })
                        }} -->
                      </div>
                  </div>
                  
                  <!-- <div class="sign-up-fields upload-img event-edit-form">
                    <label class="field-label">Profile Image&nbsp;<span class="span_star">*</span></label>
                    <div class="switch-field Status-tab" formGroupName="image">
                      <div class="radio-round">
                        <input type="radio" id="avatar" value="avatar" formControlName="type" [checked]="true"
                          (click)="isAvatar = true; selectedAvtar = 'avatar-1.png';" name="type" required>
                        <label for="avatar">Select Avatar</label>
                      </div>

                      <div class="radio-round">
                        <input type="radio" id="image" value="image" formControlName="type"
                          (click)="isAvatar = false; selectedAvtar ='';" name="type" required>
                        <label for="image">Upload Image</label>
                      </div>
                    </div>

                    <div class="select-avatar-icon avatar_scroll" *ngIf="isAvatar == true">
                      <ul class="clearfix">
                        <li *ngFor="let avatar of avatars;"><a [ngClass]="{'active':avatar===selectedAvtar}"
                            href="javascript:void(0)"><img src="assets/images/avatars/{{avatar}}"
                              (click)="selectedAvtar = avatar" alt="" /></a> </li>
                      </ul>
                    </div>
                    <div class="event-input" *ngIf="isAvatar == false">
                      <div class="">
                        <div class="hfp-input-div">
                          <label>Profile Image</label>
                          <div class="image__upload__input">
                            <div class="event-upload-img" [ngClass]="{'uploadedimg': imgUrl && imgUrl !=''}">
                              <input type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)">
                              <div class="upload-img-editicon" *ngIf="imgUrl && imgUrl !=''"><i class="fa fa-pencil"
                                  aria-hidden="true"></i></div>
                              <div class="event-upload-icon">
                                <i><img src="/assets/images/upload-img-icon.png" alt=""></i>
                                <span>Add Profile Image</span>
                              </div>
                              <div class="signupprofileimgshow" formArrayName="image" *ngIf="imgUrl && imgUrl !=''">
                                <img class="imgPlaceholder" src="/assets/images/users/{{imgUrl}}" alt="img"
                                  height="100%" width="100%" />
                              </div>
                            </div>

                          </div>
                          <div *ngIf="isavtarimage " class="custom_error">
                            <div> Image Is required </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Phone Number&nbsp;<span class="span_star">*</span></label>
                      <app-phone-number
                        [phoneNumberFormControlName]="'phone_number'"
                        [phoneCodePanelClass]="'input-field'" 
                        [phoneNumberFormGroup]="dataForm.phone_number.parent"
                      ></app-phone-number>
                      <div *ngIf="dataForm.phone_number.invalid && fAttributes.submitted"
                        class="custom_error">
                        <div *ngIf="dataForm.phone_number.errors.required"> {{
                          'REQUIRED_MSG_WITH_PARAMS' |
                          translate: {'name': 'Phone Number' | translate}
                          }} </div>
                        <div *ngIf="dataForm.phone_number.errors.invalidNumber">{{translatorJson.NOT_A_VALID_PHONE |
                          translate}}</div>
                      </div>
                  </div>
                      
                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">{{translatorJson.ADDRESS1 | translate}}&nbsp;<span class="span_star">*</span></label>
                    <input
                      formControlName="addressOne" name="addressOne"
                      autocorrect="off" autocapitalize="off" spellcheck="off"
                      (locFormGroupDataChanged)='patchDataForLocFormGroup($event)'
                      class="input-field" placeholder="{{translatorJson.ADDRESS1 | translate}}"
                      type="googleInputLocation" required>
                      <div 
                  *ngIf="addressForm.addressOne.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.addressOne.errors.required"> {{translatorJson.ADDRESS1 | translate}} Is
                      required.  </div>
                      <div
                        *ngIf="addressForm.addressOne.errors.isValidAddressFromList && !addressForm.addressOne.errors.required">
                        {{translatorJson.INVALID_ADDRESS_ERROR | translate}}
                        </div>
                  </div>
                  </div>
                  
                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">{{'ADDRESS2' | translate}}</label>
                    <input formControlName="formatted_address" name="formatted_address" class="input-field"
                      placeholder="{{'ADDRESS2' | translate}}" type="text">
                      <div
                    *ngIf="addressForm.formatted_address.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.formatted_address.errors.required"> {{'ADDRESS2' | translate}} Is
                      required.  </div>
                    <!-- <div *ngIf="addressForm.formatted_address.errors.minlength"> {{'ADDRESS2' | translate}} must
                      be of two letters </div>
                    <div *ngIf="addressForm.formatted_address.errors.maxlength">{{'ADDRESS2' | translate}} is too
                      Large </div> -->

                  </div>
                  </div>
                  
                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">City&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter City" formControlName="city" name="city"
                      required>
                      <div *ngIf="addressForm.city.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.city.errors.required"> City Name Is required. 
                    </div>
                    <!-- <div *ngIf="addressForm.city.errors.minlength"> City Name must be of two
                      letters </div>
                    <div *ngIf="addressForm.city.errors.maxlength"> City Name is too Large
                    </div> -->

                  </div>
                  </div>
                  
                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">State&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter state" formControlName="state"
                      name="state" required>
                      <div *ngIf="addressForm.state.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.state.errors.required"> State Name Is required 
                    </div>
                    <!-- <div *ngIf="addressForm.state.errors.minlength"> State Name must be of two
                      letters </div>
                    <div *ngIf="addressForm.state.errors.maxlength"> State Name is too Large
                    </div> -->

                  </div>
                  </div>
                  
                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">ZIP Code&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter Zip Code" formControlName="zip_code" name="zip_code" required>
                    <div *ngIf="addressForm.zip_code.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.zip_code.errors.required">ZIP code Is required.</div>
                    <!-- <div *ngIf="addressForm.zip_code.errors.minlength"> ZIP code must be of four
                      digits </div>
                    <div *ngIf="addressForm.zip_code.errors.pattern"> ZIP code must satisfy
                      pattern </div> -->
                  </div>
                  </div>
                  
                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Website&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter Website" formControlName="website"
                      name="website" required>
                  <div *ngIf="dataForm.website.invalid && fAttributes.submitted"
                    class="custom_error">
                    
                    {{
                      'REQUIRED_MSG_WITH_PARAMS' |
                      translate: {'name': translatorJson.WEBSITE | translate}
                      }} 
                      <!-- {{
                      getValidationErrors({
                      formControl: dataForm.website, formControlName: translatorJson.WEBSITE,
                      isUrlPattern: true
                      })
                    }} -->
                  </div>
                  </div>
                  <div class="form-check no_left_padding" formGroupName="data">
                    <input type="checkbox" name="checkboxG1" id="checkboxG1" class="css-checkbox"
                      formControlName="checkbox" name="checkbox" required>
                    <label for="checkboxG1" class="css-label">I have read and accepted the <a style="color: #02a6e3;"
                        href="" target="_blank" routerLink="/corporate-terms-and-conditions"> Terms and
                        Conditions</a></label>
                        <div *ngIf="dataForm.checkbox.invalid &&  fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="dataForm.checkbox.errors.required"> checkbox Is required
                    </div>
                  </div>
                  </div>
                  
                  <div class="clearfix"><br /></div>
                  <div class="recaptcha-box">
                    <ngx-recaptcha2 #captchaElem [siteKey]="'6LcokbcUAAAAAE81_fR5WYyVAYUZueDwIPfuTcE9'"
                      (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
                      (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size" [hl]="lang"
                      [theme]="theme" [type]="type" formControlName="recaptcha">
                    </ngx-recaptcha2>
                    <div *ngIf="sf.recaptcha.invalid &&  fAttributes.submitted"
                      class="custom_error">
                      <div *ngIf="sf.recaptcha.errors.required">Recaptcha is required. </div>
                    </div>
                  </div>
                  <div class="buttons">
                    <button class="btn btn-primary">{{"SUBMIT" | translate }}</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
          <div class="attend-event" *ngIf="signupFormShow == 2">
            <div class="signup-page clearfix">
              <div class="tab-content-left-bg attend-event-bg">
                <h2 class="sign-up-heading">Exhibitor Registration:</h2>
                <div class="p-txt">
                  <p>
                    To view upcoming health fairs in your area, you can register as a member by filling out the
                    registration form below. Please read about the advantages of becoming a member of our site by
                    clicking
                    here: <a href="" target="_blank" routerLink="/why-register">Membership Information</a>.
                    <br><br>
                    When registering, please only list the industries in which you can represent at events. PROFILES
                    MUST
                    BE APPROVED by Health Fairs Plus before they are posted. We will view your website to verify the
                    services you have selected. Industries may be removed if we cannot verify that you will offer them
                    at
                    events. If you do not have a website, we will contact you to verify your services. Exhibitors must
                    agree to the <a routerLink="/exhibitor-terms-and-conditions" href="" target="_blank">Terms and
                      Conditions</a> before their registrations are approved. Please view
                    the <a routerLink="/exhibitor-terms-and-conditions" href="" target="_blank">Terms and
                      Conditions</a>.
                  </p>

                </div>
              </div>
              <form [formGroup]="signUpForm" (ngSubmit)="signUpSubmitForExi()" autocomplete="off">
                <div class="tab-content-right-bg">

                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Your Business Name (if you don't have one, please use your name)&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" formControlName="company_name" name="company_name"
                      placeholder="Your Business Name" required>
                    <div
                      *ngIf="dataForm.company_name.invalid &&  fAttributes.submitted"
                      class="custom_error">
                      Business Name Is required
                      <!-- {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translatorJson.COMPANY_NAME | translate}
                        }}  -->
                      <!-- {{
                      getValidationErrors({
                      formControl: dataForm.company_name, formControlName: translatorJson.COMPANY_NAME,
                      minLength: 2, maxLength: 200,
                      isTextPattern: true
                      })
                      }} -->
                    </div>
                  </div>



                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Contact Name&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" formControlName="contact" name="contact"
                      placeholder="Contact Name" required>
                    <div
                      *ngIf="dataForm.contact.invalid && fAttributes.submitted"
                      class="custom_error">
                      {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translatorJson.CONTACT_NAME | translate}
                        }} 
                      <!-- {{
                      getValidationErrors({
                      formControl: dataForm.contact, formControlName: translatorJson.CONTACT_NAME,
                      minLength: 2, maxLength: 100,
                      isTextPattern: true
                      })
                      }} -->
                    </div>
                  </div>


                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Phone Number&nbsp;<span class="span_star">*</span></label>
                    <app-phone-number [phoneNumberFormControlName]="'phone_number'"
                      [phoneNumberFormGroup]="dataForm.phone_number.parent"></app-phone-number>
                      <div
                    *ngIf="dataForm.phone_number.invalid &&  fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="dataForm.phone_number.errors.required"> {{
                      'REQUIRED_MSG_WITH_PARAMS' |
                      translate: {'name': 'Phone Number' | translate}
                      }} </div>
                    <div *ngIf="dataForm.phone_number.errors.invalidNumber">{{translatorJson.NOT_A_VALID_PHONE |
                      translate}}</div>
                  </div>
                  </div>
                  
                  <div class="sign-up-fields">
                    <label class="field-label">Email&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Please Provide Email" formControlName="email"
                      name="email" required>
                    <div *ngIf="sf.email.invalid && fAttributes.submitted"
                      class="custom_error">
                      {{
                        getValidationErrors({
                        formControl: sf.email, formControlName: translatorJson.EMAIL,
                        isEmailPattern: true
                        })
                      }}
                    </div>
                  </div>

                  <div class="sign-up-fields" formGroupName="data">
                    <label class="field-label">Website&nbsp;<span class="span_star"></span></label>
                    <input type="text" class="input-field" placeholder="Enter Website" formControlName="website"
                      name="website">
                    <!-- <div
                      *ngIf="dataForm.website.invalid &&  fAttributes.submitted"
                      class="custom_error">
                      {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translatorJson.WEBSITE | translate}
                        }}  -->
                      <!-- {{
                        getValidationErrors({
                        formControl: dataForm.website, formControlName: translatorJson.WEBSITE,
                        isUrlPattern: true
                        })
                      }} -->
                    <!-- </div> -->
                  </div>

                  <div class="sign-up-fields">
                    <label class="field-label">User Name&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" formControlName="username" name="username"
                      placeholder="user Name" required>
                    <div
                      *ngIf="sf.username.invalid && fAttributes.submitted"
                      class="custom_error">
                      {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translatorJson.USER_NAME | translate}
                        }} 
                      <!-- {{
                        getValidationErrors({
                        formControl: sf.username, formControlName: translatorJson.USER_NAME,
                        minLength: 2, maxLength: 60
                        })
                      }} -->
                    </div>
                  </div>


                  <div class="sign-up-fields">
                    <label class="field-label">Password&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field numeric-password" placeholder="Please Provide Password"
                      formControlName="password" name="password" required>
                    <div
                      *ngIf="sf.password.invalid &&  fAttributes.submitted"
                      class="custom_error">
                      {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translatorJson.PASSWORD | translate}
                        }} 
                      <!-- {{
                      getValidationErrors({
                      formControl: sf.password, formControlName: translatorJson.PASSWORD,
                      minLength: 6, maxLength: 25,
                      isPasswordPattern: true
                      })
                      }} -->
                    </div>
                  </div>

                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">{{translatorJson.ADDRESS1 | translate}}&nbsp;<span class="span_star">*</span></label>
                      <input
                       formControlName="addressOne" name="addressOne"
                       autocorrect="off" autocapitalize="off" spellcheck="off"
                       (locFormGroupDataChanged)='patchDataForLocFormGroup($event)'
                       class="input-field" placeholder="{{translatorJson.ADDRESS1 | translate}}"
                       type="googleInputLocation" required>
                       <div
                    *ngIf="addressForm.addressOne.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.addressOne.errors.required">{{translatorJson.ADDRESS1 | translate}} Is
                      required. </div>
                      <div
                        *ngIf="addressForm.addressOne.errors.isValidAddressFromList && !addressForm.addressOne.errors.required">
                        {{translatorJson.INVALID_ADDRESS_ERROR | translate}}
                        </div>
                  </div>
                  </div>
                  

                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">{{'ADDRESS2' | translate}}</label>
                    <input formControlName="formatted_address" name="formatted_address" class="input-field"
                      placeholder="{{'ADDRESS2' | translate}}" type="text">
                      <div
                    *ngIf="addressForm.formatted_address.invalid &&  fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.formatted_address.errors.required"> {{'ADDRESS2' | translate}} Is
                      required.  </div>
                    <!-- <div *ngIf="addressForm.formatted_address.errors.minlength"> {{'ADDRESS2' | translate}} must
                      be of two letters </div>
                    <div *ngIf="addressForm.formatted_address.errors.maxlength">{{'ADDRESS2' | translate}} is too
                      Large </div> -->

                  </div>
                  </div>
                  

                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">City&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter City" formControlName="city" name="city"
                      required>
                      <div *ngIf="addressForm.city.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.city.errors.required"> City Name Is required.
                    </div>
                    <!-- <div *ngIf="addressForm.city.errors.minlength"> City Name must be of two
                      letters </div>
                    <div *ngIf="addressForm.city.errors.maxlength"> City Name is too Large
                    </div> -->

                  </div>
                  </div>
                  

                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">State&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter state" formControlName="state"
                      name="state" required>
                      <div *ngIf="addressForm.state.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.state.errors.required"> State Name Is required.
                    </div>
                    <!-- <div *ngIf="addressForm.state.errors.minlength"> State Name must be of two
                      letters </div>
                    <div *ngIf="addressForm.state.errors.maxlength"> State Name is too Large
                    </div> -->

                  </div>
                  </div>
                  

                  <div class="sign-up-fields" formGroupName="address">
                    <label class="field-label">ZIP Code&nbsp;<span class="span_star">*</span></label>
                    <input type="text" class="input-field" placeholder="Enter Zip Code" formControlName="zip_code"
                      name="zip_code" required minlength="4" maxlength="12">
                      <div *ngIf="addressForm.zip_code.invalid && fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="addressForm.zip_code.errors.required"> ZIP code Is required. 
                    </div>
                    <!-- <div *ngIf="addressForm.zip_code.errors.minlength"> ZIP code must be of four
                      digits </div>
                    <div *ngIf="addressForm.zip_code.errors.pattern"> ZIP code must satisfy
                      pattern </div> -->

                  </div>
                  </div>
                  

                  <!-- TODO:- Hiding the are you medical exibitior and insurance -->

                  <!-- <div class="sign-up-fields">
                    <label class="field-label">{{'ARE_YOU_MEDICAL_EXHIBITOR' | translate }}</label>
                    <div class="switch-field">
                      <div class="check-btntype" *ngFor="let item of medicalExhibitor; let idx = index;">
                        <input type="radio" id="switch_left{{idx}}" name="status" value="{{item.value}}"
                          (change)="onRadioBoxChange(item,$event);" checked />
                        <label for="switch_left{{idx}}">{{item.name}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="sign-up-fields" *ngIf="isExibitor && isMedical" formGroupName="data">
                    <label class="field-label">{{'INSURANCE_PLANS' | translate}}&nbsp;
                    </label>
                    <input type="text" class="input-field" placeholder="Which insurance plans are you in-network with?" formControlName="insurance"
                      name="insurance">
                  </div> -->


                  <div class="sign-up-fields reminders">
                    <label class="field-label">{{'DEFAULT_REMINDERS' | translate}}</label>
                    <div class="form-check" *ngFor="let list of defaultValue">
                      <input type="checkbox" class="css-checkbox" name="reminder" [checked]="true" id="{{list.id}}"
                        (change)="onCheckboxChange(list,$event)">
                      <label for="{{list.id}}" class="css-label">
                        {{list.value}}
                      </label>
                    </div>
                  </div>
                   
                  <!-- TODO :- hide the insdustry data as per we are not using this time -->
                  <!-- <div class="sign-up-fields" *ngIf="isExibitor">
                    <label class="field-label">Industry&nbsp;<span class="span_star">*</span></label> -->
                    <!-- <input type="text" class="input-field"> -->
                    <!-- <ng-select [closeOnSelect]="false" [items]="industriesList" [searchFn]="searchTaxo" bindLabel="name"
                      bindValue="id" [multiple]="true" placeholder="Select multiple industries"
                      formControlName="industry">
                    </ng-select>
                    <div *ngIf="sf.industry.invalid && (sf.industry.dirty || fAttributes.submitted)"
                      class="custom_error">
                      <div *ngIf="sf.industry.errors.required"> Industry is required  </div>
                    </div>
                  </div> -->

                  <div class="sign-up-fields screening-events-list-signup" *ngIf="isExibitor">

                    <div class="screening-events-list-signup-btm">
                      <label class="field-label">{{'SERVICES_OFFERED' | translate}}&nbsp;<span class="span_star">*</span></label>
                      <ng-select [closeOnSelect]="false" [items]="screeningList" bindLabel="formattedName" bindValue="id"
                        [multiple]="true" [searchFn]="searchTaxo" placeholder="{{'SELECT_SERVICES' | translate}}"
                        formControlName="screening">
                      </ng-select>
                      <div *ngIf="sf.screening.invalid && (sf.screening.dirty || fAttributes.submitted)"
                      class="custom_error">
                      <div *ngIf="sf.screening.errors.required"> Services is required </div>
                    </div>

                    </div>
                    <div class="screening-events-list-signup-top">
                      <div class="form-check">
                        <input type="checkbox" class="css-checkbox" name="screenRequired" id="checka"
                          formControlName="screenRequired">
                        <label for="checka" class="css-label">
                          Only show events that request my service(s)
                        </label>

                      </div>
                    </div>
                  </div>


                  <!-- <div class="sign-up-fields upload-img event-edit-form ">
                <label class="field-label">Profile Image&nbsp;<span class="span_star">*</span></label>
                <div class="switch-field Status-tab">
                  <div class="radio-round" *ngFor="let item of imageType; let idx = index;">
                    <input type="radio" id="{{item.type}}{{idx}}" name="avatar" value="{{item.type}}"
                      (click)="onImageTypeChange(item)" [checked]="item.type=='avatar'"  />
                    <label for="{{item.type}}{{idx}}">{{item.value}}</label>
                  </div>
                </div>

                <div class="select-avatar-icon avatar_scroll" *ngIf="isAvatar == true">
                  <ul class="clearfix">
                    <li *ngFor="let avatar of avatars;">
                      <a [ngClass]="{'active':avatar===selectedAvtar}"  href="javascript:void(0)">
                          <img src="assets/images/avatars/{{avatar}}" (click)="selectedAvtar = avatar" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>

                  <div class="event-input " *ngIf="isAvatar == false">
                    <div class="">
                      <div class="hfp-input-div">
                        <label>Profile Image</label>
                        <div class="image__upload__input">
                          <div class="event-upload-img">
                            <input type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)">
                            <div class="event-upload-icon">
                              <i><img src="/assets/images/upload-img-icon.png" alt=""></i>
                              <span>Add Profile Image</span>
                            </div>
                          </div>
                          <div class="browse-image-show" formArrayName="image" *ngIf="imgUrl && imgUrl !=''">
                            <img class="imgPlaceholder" src="/assets/images/users/{{imgUrl}}"
                            alt="img" height="100%" width="100%" />
                          </div>
                        </div>
                        <div *ngIf="isavtarimage " class="custom_error">
                          <div > Image Is required </div>

                        </div>
                      </div>
                    </div>
                  </div>
              </div> -->
                  <div class="sign-up-fields upload-img event-edit-form ">
                    <label class="field-label">Profile Image&nbsp;<span class="span_star">*</span></label>
                    <div class="switch-field Status-tab" formGroupName="image" (change)="toggleImageChange()">
                      <div class="radio-round">
                        <input type="radio" id="avatar" value="avatar" checked formControlName="type"
                          (click)="resetSelectAvatar()" name="type" required>
                        <label for="avatar">Select Avatar</label>
                      </div>

                      <div class="radio-round">
                        <input type="radio" id="image" value="image" formControlName="type"
                          (click)="resetSelectAvatar()" name="type" required>
                        <label for="image">Upload Image</label>
                      </div>
                    </div>

                    <div class="select-avatar-icon avatar_scroll" *ngIf="isAvatar == true">
                      <ul class="clearfix">
                        <li *ngFor="let avatar of avatars;"><a [ngClass]="{'active':avatar===selectedAvatar}"
                            href="javascript:void(0)"><img src="assets/images/avatars/{{avatar}}"
                              (click)="selectAvatar(avatar)" alt="" /></a> </li>
                      </ul>
                    </div>
                    <div class="event-input" *ngIf="isAvatar == false">
                      <div class="">
                        <div class="hfp-input-div">
                          <label>Profile Image</label>
                          <div class="image__upload__input">
                            <div class="event-upload-img" [ngClass]="{'uploadedimg': imgUrl && imgUrl !=''}">
                              <input type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)">
                              <div class="upload-img-editicon" *ngIf="imgUrl && imgUrl !=''"><i class="fa fa-pencil"
                                  aria-hidden="true"></i></div>

                              <div class="event-upload-icon">
                                <i><img src="/assets/images/upload-img-icon.png" alt=""></i>
                                <span>Add Profile Image</span>
                              </div>


                              <div class="signupprofileimgshow" formArrayName="image" *ngIf="imgUrl && imgUrl !=''">
                                <img class="imgPlaceholder" [src]="(imageCdn + 'w_228,h_228/' + ((imageBaseUrl + '/users/' + imgUrl) | encodeUriComponent))" alt="img"
                                  height="100%" width="100%" />
                              </div>
                            </div>

                          </div>
                          <div *ngIf="signupFormImageName.invalid && fAttributes.submitted " class="custom_error">
                            {{
                              'REQUIRED_MSG_WITH_PARAMS' |
                              translate: {'name': translatorJson.IMAGE | translate}
                              }} 
                            <!-- {{
                              getValidationErrors({
                              formControl: signupFormImageName, formControlName: translatorJson.IMAGE
                              })
                            }} -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- todo: we are not using them at this time but we can use in future -->
                  <!-- <div class="sign-up-fields" *ngIf="isExibitor" formGroupName="data">
                    <label class="field-label">Where do you want to attend events</label>
                    <textarea cols="5" rows="5" class="input-field" formControlName="attendevent"
                      name="attendevent"></textarea>
                    <div
                      *ngIf="dataForm.attendevent.invalid && (dataForm.attendevent.dirty || dataForm.attendevent.touched || fAttributes.submitted)"
                      class="custom_error">
                      {{
                        getValidationErrors({
                        formControl: dataForm.attendevent, formControlName: translatorJson.ATTEND_AN,
                        minLength: 2, maxLength: 400
                        })
                      }}
                    </div>
                  </div>

                  <div class="sign-up-fields" *ngIf="isExibitor" formGroupName="data">
                    <label class="field-label">Info/Activities offered at Health Fairs</label>
                    <textarea cols="5" rows="5" class="input-field" formControlName="infoFair"
                      name="infoFair"></textarea>
                    <div
                      *ngIf="dataForm.infoFair.invalid && (dataForm.infoFair.dirty || dataForm.infoFair.touched || fAttributes.submitted)"
                      class="custom_error">
                      {{
                        getValidationErrors({
                        formControl: dataForm.infoFair, formControlName: translatorJson.INFO_AT_HEALTH_FAIRS,
                        minLength: 2, maxLength: 600
                        })
                      }}
                    </div>
                  </div>

                  <div class="sign-up-fields" *ngIf="isExibitor" formGroupName="data">
                    <label class="field-label">Seminar, Workshop, and Corporate Programs Offered</label>
                    <textarea cols="5" rows="5" class="input-field" formControlName="offered" name="offered"></textarea>
                    <div
                      *ngIf="dataForm.offered.invalid && (dataForm.offered.dirty || dataForm.offered.touched || fAttributes.submitted)"
                      class="custom_error">
                      {{
                        getValidationErrors({
                        formControl: dataForm.offered, formControlName: translatorJson.SEMINAR_OFFERED,
                        minLength: 2, maxLength: 1000
                        })
                      }}
                    </div>
                  </div> -->

                  <div class="sign-up-fields">
                    <label class="field-label">List the main city/county where you want to attend events &nbsp;<span class="span_star">*</span></label>
                    <input class="input-field map-icon" id="eventMap" name="regions"
                      (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off"
                      autocapitalize="off" spellcheck="off" type="text" #search>
                    <div *ngIf="sf.region.invalid && fAttributes.submitted"
                      class="custom_error">
                      {{
                        getValidationErrors({
                        formControl: sf.region, formControlName: translatorJson.WATCH_LIST
                        })
                      }}
                    </div>
                    <div class="small_txt_info" *ngIf="!(regionArray.controls && regionArray.controls.length)">
                      Once you have selected a city/county, you can expand the circle below to encompass all areas that you want to receive event invitations.
                    </div>
                  </div>
                 
                  <div class="signup-map-box fullview-map-box">
                    <agm-map style="height : 200px" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                      <agm-marker *ngFor="let region of  regionArray.controls;let idx=index;"
                        [latitude]="region.value.lat" [longitude]="region.value.long" [markerDraggable]="false"
                        (dragEnd)="markerDragEnd($event)"></agm-marker>

                      <agm-circle *ngFor="let region of  regionArray.controls;let idx=index;"
                        [latitude]="region.value.lat" [longitude]="region.value.long"
                        [radius]="region.value.centerRadius?region.value.centerRadius:centerRadius" [fillColor]="'blue'"
                        [circleDraggable]="false" (radiusChange)="centerChangeds($event,idx)" [editable]="true">
                      </agm-circle>

                    </agm-map>
                   
                  </div>
                  <div class="small_txt_info_a" *ngIf="regionArray.controls && regionArray.controls.length">
                    Drag the circle select the radius in which you want to see the event
                  </div>
                  {{regionArray.controls.value}}
                   <div class="map-dropdown-list">
                    <ul>
                      <li *ngFor="let region of  regionArray.controls;let idx=index;">
                        <span><a href="javascript:void(0)">Region: {{region.value.name}}, {{region.radius}} mile
                            radius</a> </span>
                        <!-- <span>Miles - {{region.value.miles}}{{region.centerRadius*0.00062137   | number : '1.2-2'}} miles</span> -->
                        <span>Miles - {{region.value.centerRadius*0.000621371 | number : '1.2-2'}} miles</span>
                        <div class="map-row-btn">
                          <a href="javascript:void(0)" (click)="remvoeCircle(idx)"><i class="map-delete-icon"></i>
                            Delete
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <br>
                  <div class="form-check no_left_padding" formGroupName="data">
                    <input type="checkbox" name="checkboxG2" id="checkboxG2" class="css-checkbox"
                      formControlName="checkbox" name="checkbox" required>
                    <label for="checkboxG2" class="css-label">I have read and accepted the
                      <a style="color: #f89243;" href="" target="_blank" routerLink="/exhibitor-terms-and-conditions">
                        Terms and Conditions</a></label>
                  </div>
                  <div *ngIf="dataForm.checkbox.invalid &&  fAttributes.submitted"
                    class="custom_error">
                    <div *ngIf="dataForm.checkbox.errors.required"> checkbox Is required. 
                    </div>
                  </div>
                  <br>
                  <div class="recaptcha-box">
                    <ngx-recaptcha2 *ngIf="isExibitor" #captchaElem
                      [siteKey]="'6LcokbcUAAAAAE81_fR5WYyVAYUZueDwIPfuTcE9'" (reset)="handleReset()"
                      (expire)="handleExpire()" (load)="handleLoad()" (success)="handleSuccess($event)"
                      [useGlobalDomain]="false" [size]="size" [hl]="lang" [theme]="theme" [type]="type"
                      formControlName="recaptcha">
                    </ngx-recaptcha2>
                    <div *ngIf="isExibitor && sf.recaptcha.invalid &&  fAttributes.submitted"
                      class="custom_error">
                      <div *ngIf="sf.recaptcha.errors.required"> Recaptcha is required.  </div>
                    </div>
                  </div>

                  <div class="buttons">
                    <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
                  </div>

                </div>
              </form>

            </div>

          </div>
        </div>
      </div>
      <div class="my-account-right registration-payment-page">
        <!-- {{authorizeStep}} -->
        <div class="event-pay-page">
          <div id="event-step-b-1" *ngIf="authorizeStep === 'event-step-b-1'">

            <div class="clearfix">
              <div class="hfp-col-4">
                <div class="event-pay-left">
                  <div class="pay-event-step">
                    <ul>
                      <li><a class="active" href="#">1</a></li>
                      <li><a class="" href="#">2</a></li>
                    </ul>
                  </div>
                  <i><img src="assets/images/credit-card-information-icon.png" alt=""></i>
                  <h5>Credit card Information</h5>
                </div>
              </div>
              <div class="hfp-col-8 bg__ww">
                <div class="event-pay-right">
                  <h2>Credit Card Information</h2>
                  <p>Aceepted payment method visa, mastercard, american express, discover</p>

                  <div class="payment-for-event">
                    <form [formGroup]="authorizeForm" class="authorize-container" (ngSubmit)="paymentSend()">

                      <div formGroupName="card">
                        <div class="hfp-input-div">
                          <label>Card Number <span class="span_star">*</span></label>
                          <input type="text" formControlName="cardNumber" cc-type="cardType"
                            placeholder="Credit Card Number" ccNumber class="card-nmr">
                          <div
                            *ngIf="authorizeForm.controls['card'].controls['cardNumber'].invalid &&  paymentSubmitted"
                            class="custom_error">
                            <div *ngIf="authorizeForm.controls['card'].controls['cardNumber'].errors.required">
                              Card Number is required. 
                            </div>
                            <div
                              *ngIf="!authorizeForm.controls['card'].controls['cardNumber'].errors.required && authorizeForm.controls['card'].controls['cardNumber'].errors.ccNumber">
                              Invalid Card Number. </div>
                          </div>
                        </div>


                        <div class="hfp-input-div">
                          <label>Card Holder’s Name <span class="span_star">*</span></label>
                          <input type="text" value="Jhon Doe" placeholder="Card Holder’s Name"
                            formControlName="fullName">
                          <div
                            *ngIf="authorizeForm.controls['card'].controls['fullName'].invalid &&  paymentSubmitted"
                            class="custom_error">
                            <div *ngIf="authorizeForm.controls['card'].controls['fullName'].errors.required">Card
                              Holder’s
                              is required. </div>
                            <!-- <div
                              *ngIf="!authorizeForm.controls['card'].controls['fullName'].errors.required && authorizeForm.controls['card'].controls['fullName'].errors.minlength">
                              Min length be 2. </div> -->

                          </div>
                        </div>



                        <div class="clearfix row exhibitors-row-2">
                          <div class="col-sm-6">
                            <div class="hfp-input-div">
                              <label>Expiry Date<span class="span_star">*</span></label>
                              <input type="text" formControlName="expireAt" placeholder="MM/YY" ccExp>
                              <div
                                *ngIf="authorizeForm.controls['card'].controls['expireAt'].invalid && paymentSubmitted"
                                class="custom_error">
                                <div *ngIf="authorizeForm.controls['card'].controls['expireAt'].errors.required">Expiry
                                  date is required. </div>
                                <div
                                  *ngIf="!authorizeForm.controls['card'].controls['expireAt'].errors.required && authorizeForm.controls['card'].controls['expireAt'].errors.expDate">
                                  Invalid expiry date. </div>
                                <div *ngIf="!authorizeForm.controls['card'].controls['expireAt'].errors.required
                                      && !authorizeForm.controls['card'].controls['expireAt'].errors.minlength
                                      && authorizeForm.controls['card'].controls['expireAt'].errors.maxlength">
                                  Valid format is YY. </div>
                              </div>
                            </div>

                          </div>
                          <div class="col-sm-3">
                            <div class="hfp-input-div">
                              <label>CVV<span class="span_star">*</span></label>

                              <input type="text" formControlName="cvv" placeholder="CVV" ccCVC>
                              <div
                                *ngIf="authorizeForm.controls['card'].controls['cvv'].invalid && paymentSubmitted"
                                class="custom_error">
                                <div *ngIf="authorizeForm.controls['card'].controls['cvv'].errors.required">CVV number
                                  is required. </div>
                                <div
                                  *ngIf="!authorizeForm.controls['card'].controls['cvv'].errors.required && authorizeForm.controls['card'].controls['cvv'].errors.minlength">
                                  Min length should be 3. </div>
                                <div
                                  *ngIf="!authorizeForm.controls['card'].controls['cvv'].errors.required && !authorizeForm.controls['card'].controls['cvv'].errors.minlength && authorizeForm.controls['card'].controls['cvv'].errors.maxlength">
                                  Max length should be 4. </div>
                              </div>
                            </div>


                          </div>
                          <div class="col-sm-3">
                            <div class="hfp-input-div">
                              <label>Amount&nbsp;($)</label>
                              <input type="text" value="{{registerPaymentAmount}}" readonly placeholder="Amount"
                                formControlName="amount">
                              <div
                                *ngIf="authorizeForm.controls['card'].controls['amount'].invalid &&  paymentSubmitted"
                                class="custom_error">
                                <div *ngIf="authorizeForm.controls['card'].controls['amount'].errors.required">Amount is
                                  required. </div>
                                <div
                                  *ngIf="!authorizeForm.controls['card'].controls['amount'].errors.required && authorizeForm.controls['card'].controls['amount'].errors.min">
                                  Minimum number should be 1. </div>
                              </div>
                            </div>


                          </div>

                        </div>




                        <div class="event-buttons clearfix">
                          <a href="javascript:void(0)" id="next-b-1" class="btn btn-default next-btn margin-rightskip"
                            (click)="skipRegistration()">Skip </a>
                          <a class="refund-policy-link" routerLink="/refund-policy" href="javascript:void(0)"
                            target="_blank">Refund Policy</a>
                          <button type="submit" class="next-btn button-css">Next <i></i><i><img
                                src="assets/images/next-icon.png" alt=""></i></button>

                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <!--second form-->
          <div id="event-step-b-2" *ngIf="authorizeStep === 'event-step-b-2'">
            <div class="clearfix">
              <div class="hfp-col-4">
                <div class="event-pay-left">
                  <div class="pay-event-step">
                    <ul>
                      <li><a class="step-done" href="#">1</a></li>
                      <li><a class="active" href="#">2</a></li>

                    </ul>
                  </div>
                  <i><img src="assets/images/customer-billing-information-icon.png" alt=""></i>
                  <h5>Customer Billing Information</h5>
                </div>
              </div>
              <div class="hfp-col-8">
                <div class="event-pay-right">
                  <h2>CUSTOMER BILLING INFORMATION</h2>

                  <div class="payment-for-event">
                    <form [formGroup]="userInfoForm" class="authorize-container" (ngSubmit)="finalPaymentSubmitFormWithUserInfo()">
                      <div formGroupName="users">

                        <div class="hfp-input-div">
                          <label>First Name<span class="span_star">*</span></label>
                          <input type="text" formControlName="firstName" placeholder="First Name">
                          <div
                          *ngIf="userInfoForm.controls['users'].controls['firstName'].invalid
                                    &&  userBillingSubmitted"
                          class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['firstName'].errors.required">First Name
                            is required. </div>
                        </div>
                        </div>


                        <div class="hfp-input-div">
                          <label>Last Name<span class="span_star">*</span></label>
                          <input type="text" formControlName="lastName" placeholder="Last Name">
                          <div *ngIf="userInfoForm.controls['users'].controls['lastName'].invalid
                                                            && userBillingSubmitted" class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['lastName'].errors.required">Last Name
                            is required. </div>
                        </div>
                        </div>

                        

                        <div class="hfp-input-div">
                          <label>Company<span class="span_star">*</span></label>
                          <input type="text" formControlName="company" placeholder="Company">
                        </div>

                        <div class="hfp-input-div">
                          <label>Address<span class="span_star">*</span></label>
                          <!--  <textarea class="address-txt" placeholder="Address" formControlName="address"
                                      myNoSpaces #search> </textarea>-->
                          <input id="autocomplete2" placeholder="Address" formControlName="address" name="address"
                            myNoSpaces #search class="input-field" type="text" required>

                          <div *ngIf="userInfoForm.controls['users'].controls['address'].invalid
                                                            &&  userBillingSubmitted"
                          class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['address'].errors.required">Address
                            is required. </div>
                        </div>

                        </div>
                        


                        <div class="hfp-input-div">
                          <label>City<span class="span_star">*</span></label>
                          <input class="" type="text" name="city" placeholder="City" formControlName="city">
                          <div
                          *ngIf="userInfoForm.controls['users'].controls['city'].invalid && userBillingSubmitted"
                          class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['city'].errors.required">City
                            is required. </div>
                        </div>
                        </div>

                        

                        <div class="clearfix row exhibitors-row-2">
                          <div class="col-sm-6">
                            <div class="hfp-input-div">
                              <label>State/Province<span class="span_star">*</span></label>
                              <input name="state" formControlName="state" placeholder="State/Province"
                                class="input-field" type="text" required>
                              <!-- <select class="hfp-selectbox" formControlName="state">
                                            <option>VA</option>
                                          </select> -->
                                          <div *ngIf="userInfoForm.controls['users'].controls['state'].invalid
                                                                                      &&  userBillingSubmitted"
                              class="custom_error">
                              <div *ngIf="userInfoForm.controls['users'].controls['state'].errors.required">State
                                is required. </div>
                            </div>
                            </div>

                            


                          </div>
                          <div class="col-sm-6 col-sm-p15">
                            <div class="hfp-input-div">
                              <label>Zip Code<span class="span_star">*</span></label>
                              <input type="text" placeholder="Zip Code" name="zipcode" formControlName="zipcode">
                              <div *ngIf="userInfoForm.controls['users'].controls['zipcode'].invalid && userBillingSubmitted"
                              class="custom_error">
                              <div *ngIf="userInfoForm.controls['users'].controls['zipcode'].errors.required">Zip Code
                                is required. </div>
                            </div>
                            </div>
                            


                          </div>
                        </div>
                        <div class="hfp-input-div">
                          <label>Country<span class="span_star">*</span></label>
                          <input class="" type="text" placeholder="Country" name="country" formControlName="country">
                          <div
                          *ngIf="userInfoForm.controls['users'].controls['country'].invalid && userBillingSubmitted"
                          class="custom_error">
                          <div *ngIf="userInfoForm.controls['users'].controls['country'].errors.required">Country
                            is required. </div>
                        </div>
                        </div>
                        

                        <div class="clearfix row exhibitors-row-2">
                          <div class="col-sm-6">
                          <div class="sign-up-fields hfp-input-div">
                              <label class="field-label">Phone&nbsp;<span class="span_star">*</span></label>
                              <app-phone-number 
                                [phoneNumberFormControlName]="'phone'"
                                [phoneNumberFormGroup]="phone.parent"
                              ></app-phone-number>
                            </div>
                            <div
                                *ngIf="phone.invalid && userBillingSubmitted"
                                class="custom_error">
                                {{
                                getValidationErrors({
                                formControl: phone,
                                formControlName:translatorJson.PHONE_NUMBER
                                })
                                }}
                            </div>

                            
                          </div>
                          <div class="col-sm-6 col-sm-p15">
                            <div class="hfp-input-div">
                              <label>Email<span class="span_star">*</span></label>
                              <input class="" type="email" placeholder="Email" formControlName="email">
                              <div *ngIf="userInfoForm.controls['users'].controls['email'].invalid &&  userBillingSubmitted"
                              class="custom_error">
                              <div *ngIf="userInfoForm.controls['users'].controls['email'].errors.required">Email
                                is required. </div>
                              <div *ngIf="userInfoForm.controls['users'].controls['email'].errors.email">
                                Email must be a valid email address
                              </div>
                            </div>
                            </div>
                            
                          </div>
                        </div>

                        <div class="event-buttons clearfix">
                          <a href="javascript:void(0)" id="pre-b-2" (click)="backInintFirstForm()"
                            class="btn btn-default pre-btn"><img src="assets/images/prv-icon.png" alt=""> Previous
                            <i></i></a>
                            <button type="submit" class="btn btn-default next-btn"> Submit </button>
                          <!-- <button type="submit" class="btn btn-default next-btn">Next <i><img
                                src="assets/images/next-icon.png" alt=""></i></button> -->
                          <!-- <a href="javascript:void(0)" id="next-b-2" class="btn btn-default next-btn">Next <i><img
                                            src="assets/images/next-icon.png" alt=""></i></a> -->

                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>




          </div>
          <!--Third form-->
          <!-- <div id="event-step-b-3" *ngIf="authorizeStep === 'event-step-b-3'">
            <div class="clearfix">
              <div class="hfp-col-4">
                <div class="event-pay-left">
                  <div class="pay-event-step">
                    <ul>
                      <li><a class="step-done" href="#">1</a></li>
                      <li><a class="step-done" href="#">2</a></li>
                      <li><a class="active" href="#">3</a></li>

                    </ul>
                  </div>
                  <i><img src="assets/images/order-information-icon.png" alt=""></i>
                  <h5>Order Information</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ullamcorper eros id felis
                    porta, a volutpat purus bibendum.</p>
                </div>
              </div>
              <div class="hfp-col-8">
                <div class="event-pay-right">
                  <h2>ORDER INFORMATION</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam metus leo.</p>

                  <div class="payment-for-event">
                    <form [formGroup]="step3form" class="authorize-container" (ngSubmit)="finalSubmitForm()">
                      <div formGroupName="orderDesc">
                        <div class="hfp-input-div">
                          <label>Description</label>
                          <textarea placeholder="Description" formControlName="description"></textarea>
                        </div>
                        <div class="event-buttons clearfix">
                          <a href="javascript:void(0)" id="pre-b-2" (click)="backInintSecondForm()"
                            class="btn btn-default pre-btn">
                            <img src="assets/images/prv-icon.png" alt="">
                            Previous <i></i></a>
                          <button type="submit" class="btn btn-default next-btn">Submit </button>


                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>