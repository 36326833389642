// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isSSL: true,
  Host: "0.0.0.0",
  imageHost: "www.healthfairsplus.com",
  Port: "4000",
  dirImg: "/assets/images",
  imageCdn: {
    ssl: true,
    host: "media-api.lmdconsulting.com",
    port: 443,
    appName: "lmd",
    fileType: "image",
    dirPath: "download/",
    get getHost() {
      return 'http' + (this.ssl ? 's' : '') + '://' + this.host +
        ((this.port === 80 || this.port === 443) ? '' : ':' + this.port) + '/' +
        this.appName + '/' + this.fileType + '/' + this.dirPath;
    },
  },
  fileTypeAllow: {
    image: [".jpg", ".jpeg", ".png", ".gif"],
    video: [".mp4", ".webm", ".ogg"],
    audio: [".ogg", ".mpeg", ".wav"],
  },
  fileAllowSize: 2 * 1024 * 1024,
  // apiUrl: "http://localhost:3001",
  apiUrl: "https://api.healthfairsplus.com",
  // authApiUrl: "localhost:3001",
  authApiUrl: "api.healthfairsplus.com",
  get ImageBase() {
    return 'http' + (this.isSSL ? 's' : '') + '://' + this.imageHost +
      '/' + this.dirImg;
  },

  get sentEmailHost() {
    return 'http' + (this.isSSL ? 's' : '') + '://' + this.imageHost;
  },

  get homeUrl() {
    return 'http' + (this.isSSL ? 's' : '') + '://' + this.Host +
      ((this.Port === 80 || this.Port === 443) ? '' : ':' + this.Port);
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
