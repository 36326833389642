import { Component, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../environments/environment";
import { Globals } from "../globals";
import { AuthService } from "../services/auth.service";
import { UnregisteredExhibitorsService } from "./unregistered-exhibitors.service";
import { forkJoin } from "rxjs";
import { AppComponent } from "../app.component";
import { NotifierService } from "angular-notifier";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { Router } from "@angular/router";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { CommonStatus } from "../constants/common-status.constants";
import { SystemConstants } from "../constants/system.constants";

@Component({
  selector: "app-unregistered-exhibitors",
  templateUrl: "./unregistered-exhibitors.component.html",
  styleUrls: ["./unregistered-exhibitors.component.scss"],
})
export class UnregisteredExhibitorsComponent implements OnInit {
  userData: any;
  userssAttributes: any = {};
  registerForm: FormGroup;
  userSearchForm: FormGroup;
  loginUserRole: any = null;
  disableTime = true;
  endTime = false;
  unregisteredCount: any;
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  unregisteredList: any;
  pending: any;
  approved: any;
  memberStatus = 1;
  decline: any;
  userActiveTab = 1;
  exhibitorSearch: number = -1;
  totalUnregisterUsers = 0;
  maxDateCorpSearch = new Date();
  currentPage = SystemConstants.DEFAULT_CURRENT_PAGE
  private notifier: NotifierService;
  constructor(
    public globals: Globals,
    private authService: AuthService,
    private appComponent: AppComponent,
    private unregisteredExhibitorsService: UnregisteredExhibitorsService,
    private fb: FormBuilder,
    private injector: Injector,
    private router: Router,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    this.maxDateCorpSearch.setDate(this.maxDateCorpSearch.getDate());
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls["password"].value ===
      frm.controls["confirmPassword"].value
      ? null
      : { mismatch: true };
  }
  ngOnInit() {
    this.unregisteredList = Object.assign(
      {},
      this.globals.adminPaginationAttrs
    );

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   this.registeredFormInit();
    //   if (this.loginUserRole === 0) {
    //     // This  is user  seach form
    //     this.userSearchForm = this.fb.group({
    //       username: ["", []],
    //       createdDate: ["", []],
    //     });
    //     forkJoin([
    //       this.unregisteredExhibitorsService.findUnregisteredCount({
    //         where: { status: 0 },
    //       }),
    //       this.unregisteredExhibitorsService.findUnregisteredCount({
    //         where: { status: 1 },
    //       }),
    //       this.unregisteredExhibitorsService.findUnregisteredCount({
    //         where: { status: 2 },
    //       }),
    //     ]).subscribe(
    //       (results) => {
    //         this.pending =
    //           results[0] && results[0].count ? results[0].count : 0;
    //         this.approved =
    //           results[1] && results[1].count ? results[1].count : 0;
    //         this.decline =
    //           results[2] && results[2].count ? results[2].count : 0;
    //         this.totalUnregisterUsers =
    //           Number(this.pending) +
    //           Number(this.approved) +
    //           Number(this.decline);
    //         if (this.pending.length == 0) {
    //           this.pending = 0;
    //         }
    //         if (this.approved.length == 0) {
    //           this.approved = 0;
    //         }
    //         if (this.decline.length == 0) {
    //           this.decline = 0;
    //         }
    //         this.unregisteredList.count =
    //           this.userActiveTab === 1
    //             ? this.approved
    //             : this.userActiveTab === 0
    //               ? this.pending
    //               : this.decline;
    //         //  this.unregisteredList.count = this.totalUnregisterUsers;
    //       },
    //       (err) => { }
    //     );

    //     this.getUnRegisteredCounts(this.userActiveTab);
    //     // this.onChangeFomValue();
    //     //this.getUnRegisteredList();
    //     this.getUnRegisteredList(
    //       this.userActiveTab,
    //       this.unregisteredList.limits,
    //       this.unregisteredList.skips
    //     );
    //   } else if (this.loginUserRole === 10) {
    //   } else if (this.loginUserRole === 20) {
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.appComponent.userData;
          this.loginUserRole = this.userData.role;
          this.registeredFormInit();
          if (this.loginUserRole === 0) {
            // This  is user  seach form
            this.userSearchForm = this.fb.group({
              username: ["", []],
              createdDate: ["", []],
            });
            forkJoin([
              this.unregisteredExhibitorsService.findUnregisteredCount({
                where: { status: 0 },
              }),
              this.unregisteredExhibitorsService.findUnregisteredCount({
                where: { status: 1 },
              }),
              this.unregisteredExhibitorsService.findUnregisteredCount({
                where: { status: 2 },
              }),
            ]).subscribe(
              (results) => {
                this.pending =
                  results[0] && results[0].count ? results[0].count : 0;
                this.approved =
                  results[1] && results[1].count ? results[1].count : 0;
                this.decline =
                  results[2] && results[2].count ? results[2].count : 0;
                this.totalUnregisterUsers =
                  Number(this.pending) +
                  Number(this.approved) +
                  Number(this.decline);
                if (this.pending.length == 0) {
                  this.pending = 0;
                }
                if (this.approved.length == 0) {
                  this.approved = 0;
                }
                if (this.decline.length == 0) {
                  this.decline = 0;
                }
                this.unregisteredList.count =
                  this.userActiveTab === 1
                    ? this.approved
                    : this.userActiveTab === 0
                    ? this.pending
                    : this.decline;
                //  this.unregisteredList.count = this.totalUnregisterUsers;
              },
              (err) => {}
            );

            this.getUnRegisteredCounts(this.userActiveTab);
            // this.onChangeFomValue();
            //this.getUnRegisteredList();
            this.getUnRegisteredList(
              this.userActiveTab,
              this.unregisteredList.limits,
              this.unregisteredList.skips
            );
          } else{
            this.router.navigateByUrl("/home");
          }
        } else {
          this.router.navigateByUrl("/");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getUnRegisteredCounts(status) {
    const queryObj: any = {
      where: {
        status: status,
      },
    };
    // This statement for user serach
    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.username
    ) {
      queryObj.where.or =[
        { email :{
            regexp: "m.*" + this.userSearchForm.value.username + "/mi",
          }
        },
        { eventName :{
          regexp: "m.*" + this.userSearchForm.value.username + "/mi",
        }
      }
      ]
    }
    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.createdDate &&
      this.userSearchForm.value.createdDate[0]
    ) {
      var start = new Date(this.userSearchForm.value.createdDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.userSearchForm.value.createdDate[1]);
      end.setHours(23, 59, 59, 999);
      queryObj.where.and = [
        { eventDate: { gte: start } },
        { eventDate: { lte: end } },
      ];
    }

    this.unregisteredExhibitorsService
      .findUnregisteredCount(queryObj)
      .subscribe(
        (cuData) => {
          this.unregisteredCount = cuData;
          this.unregisteredList.count =
            cuData && cuData.count ? cuData.count : 0;

          // this.totalCountCalculation();
        },
        (err) => {
          this.unregisteredCount = 0;
        }
      );
  }

  registeredFormInit(): void {
    this.registerForm = this.fb.group({
      username: ["", [Validators.required]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required]],
      website: ["", []],
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]],
      emailCheckBox: [false, []],
    }, { validator: this.passwordMatchValidator });
  }

  getUnRegisteredLists() {
    const queryObj: any = {
      filter: {
        where: {
          status: 0,
        },
      },
    };
    this.unregisteredExhibitorsService.findUnregisteredList(queryObj).subscribe(
      (invitationList) => {
        // console.log(invitationList);
        this.unregisteredList = invitationList;
        // this.totalCountCalculation();
      },
      (err) => { }
    );
  }

  getUnRegisteredList(status: Number, limitNum: Number, skipNum: Number): void {
    this.currentPage = SystemConstants.DEFAULT_CURRENT_PAGE
    this.toggleForm()
    this.memberStatus = Number(status);
    const queryObj: any = {
      filter: {
        where: {
          status: status,
        },
        limit: limitNum,
        skip: skipNum,
      },
    };

    //   console.log(this.userSearchForm);
    // This statement for user serach
    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.username
    ) {
      queryObj.filter.where.email = this.userSearchForm.value.username.trim();
    }
    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.createdDate &&
      this.userSearchForm.value.createdDate[0]
    ) {
      var start = new Date(this.userSearchForm.value.createdDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.userSearchForm.value.createdDate[1]);
      end.setHours(23, 59, 59, 999);
      queryObj.filter.where.and = [
        { eventDate: { gte: start } },
        { eventDate: { lte: end } },
      ];
    }

    this.unregisteredExhibitorsService.findUnregisteredList(queryObj).subscribe(
      (invitationList) => {
        //  this.unregisteredList = invitationList;
        this.unregisteredList.pageItems =
          invitationList && invitationList.length ? invitationList : [];
        // console.log(this.unregisteredList.pageItems);
        // this.unregisteredList.count = 0;
        //  this.unregisteredList.count = this.userActiveTab === 1 ? this.approved : (this.userActiveTab === 0 ? this.pending : this.decline);
      },
      (err) => { }
    );
  }

  userSearch(): void {
    this.exhibitorSearch = 1;
    forkJoin([
      this.getUnRegisteredCounts(this.userActiveTab),
      this.getUnRegisteredList(
        this.userActiveTab,
        this.unregisteredList.limits,
        this.unregisteredList.skips
      ),
    ]);
  }

  removeEventName(): void {
    this.userSearchForm.get("username").setValue("");
    this.userSearch();
  }
  removeEventDate(): void {
    this.userSearchForm.get("createdDate").setValue("");
    this.userSearch();
  }

  resetSearchForm(st: number): void {
    this.exhibitorSearch = 0;
    this.userSearchForm.reset();
    this.getUnRegisteredCounts(this.userActiveTab);
    this.getUnRegisteredList(
      st,
      this.unregisteredList.limits,
      this.unregisteredList.skips
    );
  }
  /**
   * This function is used for pagination
   */
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getUnRegisteredList(
      this.userActiveTab,
      this.unregisteredList.limits,
      startItem
    );
  }

  toggleForm(): void {
    if (!(this.unregisteredList.isIndex >= 0)) {
      this.unregisteredList.isShow = !this.unregisteredList.isShow;
    }
    this.userssAttributes.submitted = false;
    this.registerForm.reset();
    this.unregisteredList.isIndex = -1;
    this.unregisteredList.isActiveIdx = -1;
    this.unregisteredList.itemArray = [];
    this.unregisteredList.statusSelected = 1;
  }

  // Get single Widget  according to index
  getEmailTempForEdit(index: number): void {
    this.toggleForm();
    setTimeout(() => {
      this.unregisteredList.isActiveIdx = index;
      this.unregisteredList.isIndex = index; //this.utility.getIndex(index, this.unregisteredList.pageItems.length);
      this.unregisteredList.isShow = false;
      this.unregisteredList.itemArray = this.unregisteredList.pageItems[index];
      this.registerForm.patchValue(this.unregisteredList.itemArray);
      this.unregisteredList.statusSelected = this.unregisteredList.itemArray.status;
    }, 0);
  }
  // convenience getter for easy access to form fields
  get registerTempf() {
    return this.registerForm.controls;
  }

  get registerTempRefError(){
    return this.registerForm.errors;
  }
  // register User
  registerUser(id: string): void {
    if (this.registerForm.valid) {
      this.registerForm.value.status = 1;
      this.registerForm.value.email.trim();
      const loginObj = Object.assign({}, this.registerForm.value);
      this.unregisteredExhibitorsService
        .updateUser(id, this.registerForm.value)
        .subscribe(
          (data) => {
            let temEmail: any = {};
            temEmail.userId = id;
            temEmail.slug = "unregister-exhibitor-template";
            temEmail.keys = {
              eventName: this.unregisteredList.itemArray.eventName,
              userName: loginObj.username,
              password: loginObj.password,
              email: loginObj.email,
            };
            this.emailSends(temEmail);
            const queryObj: any = { status: 1 };
            this.unregisteredExhibitorsService
              .updateUnregister(this.unregisteredList.itemArray._id, queryObj)
              .subscribe((data) => {
                this.notifier.notify("success", "Registration Successfully!");
                this.unregisteredList.isIndex = -1;
                this.unregisteredList.pageItems.splice(
                  this.unregisteredList.isActiveIdx,
                  1
                );
                this.unregisteredList.isActiveIdx = -1;
                this.registerForm.reset();
              });
          },
          (err) => {
            if (
              err &&
              err.error &&
              err.error.error &&
              err.error.error.message
            ) {
              this.notifier.notify("error", err.error.error.message.message);
            }
          }
        );
    } else {
      this.userssAttributes.submitted = true;
    }
  }

  passwordMatch(){
    console.log(this.registerForm.errors.mismatch)
    if(this.registerForm.errors.mismatch){
      return this.registerForm.errors.mismatch
    }
  }

  emailSends(sendObj: any) {
    this.unregisteredExhibitorsService.sendEmail(sendObj).subscribe(
      (data) => {
        console.log("email send success");
      },
      (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
        }
      }
    );
  }
deleteUnregisterExhibitor(unregisterUserId:string){
  const queryObj: any = { status: CommonStatus.DELETE };
  this.unregisteredExhibitorsService
    .updateUnregister(unregisterUserId, queryObj)
    .subscribe((data) => {
      this.notifier.notify('success', 'Deleted Successful.');
      this.getUnRegisteredList(
        this.userActiveTab,
        this.unregisteredList.limits,
        this.unregisteredList.skips
      );
      this.getUserCountAccordingToStatus();
    })
}


getUserCountAccordingToStatus(){
  forkJoin([
    this.unregisteredExhibitorsService.findUnregisteredCount({
      where: { status: 0 },
    }),
    this.unregisteredExhibitorsService.findUnregisteredCount({
      where: { status: 1 },
    }),
    this.unregisteredExhibitorsService.findUnregisteredCount({
      where: { status: 2 },
    }),
  ]).subscribe(
    (results) => {
      this.pending =
        results[0] && results[0].count ? results[0].count : 0;
      this.approved =
        results[1] && results[1].count ? results[1].count : 0;
      this.decline =
        results[2] && results[2].count ? results[2].count : 0;
      this.totalUnregisterUsers =
        Number(this.pending) +
        Number(this.approved) +
        Number(this.decline);
      if (this.pending.length == 0) {
        this.pending = 0;
      }
      if (this.approved.length == 0) {
        this.approved = 0;
      }
      if (this.decline.length == 0) {
        this.decline = 0;
      }
      this.unregisteredList.count =
        this.userActiveTab === 1
          ? this.approved
          : this.userActiveTab === 0
          ? this.pending
          : this.decline;
      //  this.unregisteredList.count = this.totalUnregisterUsers;
    },
    (err) => {}
  );

}
  // Get single Email tempalte
  getEmailTeplate(id: string): any {
    this.unregisteredExhibitorsService.getEmailTemplateById(id).subscribe(
      (cuData) => { },
      (err) => { }
    );
  }
}
