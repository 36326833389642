import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import widget type interface.
import { IWidgetType } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WidgetTypeService {
  // Define the class property.
  private url: string = environment.apiUrl + '/widget-types';
  constructor(private http: HttpClient) { }

  /**
   * this service add widget type.
   */
  addWidgetType(widgetTypeObj: IWidgetType): Observable<IWidgetType> {
    return this.http.post<IWidgetType>(this.url, widgetTypeObj);
  }

  /**
   * this service update widget type.
   */
  updateWidgetType(id: string, widgetTypeObj: IWidgetType): Observable<IWidgetType> {
    return this.http.patch<IWidgetType>(this.url + '/' + id, widgetTypeObj);
  }

  /**
   * this service delete widget type using id.
   */
  deleteWidgetType(id: string): Observable<IWidgetType> {
    return this.http.delete<IWidgetType>(this.url + '/' + id, {});
  }

  /**
   * this service return total count of widget type.
   */
  findWidgetCount(widgetTypeObj: object): Observable<any> {
    return this.http.get<any>(this.url + '/count?' + qs.stringify(widgetTypeObj));
  }

  /**
   * this service return widget type list according to parms.
   */
  findWidgetType(widgetType: object): Observable<[]> {
    return this.http.get<[]>(this.url + '?' + qs.stringify(widgetType));
  }
  /**
   * this service get widget type using id.
   */
  getWidgetTypeById(id: string): Observable<IWidgetType> {
    return this.http.get<IWidgetType>(this.url + '/' + id);
  }
}
