import { Injectable } from '@angular/core';
import * as qs from 'qs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

// Import IIndusties type interface.
import { Credential } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  // Define the class property.
 private messageUrl: string = environment.apiUrl + '/messages';
 private eventPaymentManageUrl: string =  environment.apiUrl + '/payment-managements';
 private dataEventPaymentCheckout: any = [];
  constructor(
    private http: HttpClient
  ) { }

  setOption( value) {
    this.dataEventPaymentCheckout = value;
  }
  getOption() {
    return this.dataEventPaymentCheckout;
  }

  /**
   * this service add message to message collection and return  data.
   */

  addProfileMessage(mszObj: any): Observable<any> {
    return this.http.post<any>(this.messageUrl, mszObj);
  }

  /**
   * This service return event list from message collection
   */
  getProfileMessageList(mszObj: object): Observable<[]> {
    return this.http.get<[]>(this.messageUrl + '?' + qs.stringify(mszObj));
  }

  /**
   * this service to get message List via Post.
   */

  getProfileMsz(mszObj: any): Observable<any> {
    return this.http.post<any>(this.messageUrl + '/getMessageForUser', mszObj);
  }

  /**
   * this service update profile Message using id.
   */
  updateMessage(id: string, eventObj: object): Observable<any> {
    return this.http.patch<any>(this.messageUrl + '/' + id, eventObj);
  }

  /**
   * this service to get message counts data.
   */

  getProfileMessageCounts(mszObj: any): Observable<any> {
    return this.http.post<any>(this.messageUrl + '/countMessage', mszObj);
  }

  /**
   * This service return event list of exhibtor payment
   */
  getExhibitorPaymentList(queryObjs: object): Observable<[]> {
    return this.http.post<[]>(this.eventPaymentManageUrl + '/getExibitorEventPaymentList', queryObjs);
  }
/**
 * This service return payment count
 */
  getPaymentEventListCount(queryObj: object): Observable<any> {
    return this.http.post<any>(this.eventPaymentManageUrl + '/countPaymentListCount', queryObj);
   // return this.http.get<[]>(this.eventPaymentManageUrl + '/count?' + qs.stringify(queryObj));
  }
}
