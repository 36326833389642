<!-- Do not remove this comment, it could be implement in fold logic -->
<!-- <div class="sub-tab-hd" *ngIf="templateData && templateData.length">
  <div class="hfpbox-row-fold" *ngFor="let data of templateData; let idx = index">
    <div class="hfpbox-row-fold-top clearfix">

      <div class="hfpbox-row-fold-hd">
        <h1>{{data.heading ? data.heading : 'Container' + (idx + 1)}}</h1>
        <span></span>
      </div>      <div class="hfpbox-row-fold-btn">
        <ul>
          <li><a href="javascript:void(0)" id="edit1" title="Edit" (click)="editTempData(idx);isIndex=idx">Edit</a></li>
          <li><a href="javascript:void(0)" title="Trash" (click)="deleteTempData(idx);">Delete</a></li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
    <div *ngIf="isIndex==idx; then foldTypeTemplate"></div>
  </div>
  </div> -->
<div *ngIf="isShowTemp; then foldTypeTemplate"></div>

<ng-template #foldTypeTemplate>
<div class="add-sub-form">
    <form [formGroup]="templateForm" (ngSubmit)="isIndex!= -1 ? updateData(isIndex) : addData()">
  <div class="hfp-input-div">
    <label>Heading</label>
    <input type="text" name="title" placeholder="Home page heading fold" formControlName="heading" />
  </div>

  <div class="hfp-input-div">
    <label>Sub Heading</label>
    <input type="text" name="title" placeholder="Home page sub heading fold" formControlName="subHeading" />
  </div>

  <div class="hfp-input-div" style="width: 100%;">
    <label>Description</label>
    <div [froalaEditor] formControlName ="description"></div>
  </div>
  <div class="hfp-input-div">
    <label>File Type</label>
    <select class="hfp-selectbox" formControlName="type" (change)="getImageDataType()">
      <option *ngFor="let fileDataTypeItem of fileDataType" value="{{fileDataTypeItem.value}}">
        {{fileDataTypeItem.name}}
      </option>
    </select>
  </div>

  <div class="hfp-input-div">
    <label *ngIf="fileTypesValue === foldConstants.IMAGE">Image</label>
    <label *ngIf="fileTypesValue === foldConstants.VIDEO">video</label>
    <div class="browse-image-box">
      <div *ngIf="fileTypesValue === foldConstants.IMAGE && fileType">
        <input type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)" accept="image/*">
      </div>   <div *ngIf="fileTypesValue === foldConstants.VIDEO && fileType">
        <input  type="file" ng2FileSelect [uploader]="uploaderVedio" (change)="onImageSelect($event)" accept="video/mp4" >
      </div>
      <div class="add-logo-icon"><i class="fa fa-image" aria-hidden="true"></i></div>
      <span *ngIf="fileTypesValue === foldConstants.IMAGE">Browse Image</span>
      <span *ngIf="fileTypesValue === foldConstants.VIDEO">Browse Video</span>
       </div>
     <div *ngIf="fileTypesValue === foldConstants.IMAGE" class="browse-image-show">
         <img class="imgPlaceholder" 
           [src]="imageSrc ? imageSrc:(getFileUrl(foldConstants.IMAGE))" alt="img" height="100%" width="100%"
       />
    </div>
    <div *ngIf="fileTypesValue === foldConstants.VIDEO" class="browse-image-show">
      <video style="width: 100%; height: 100%;"  controls  #videoPlayer>
          <source  class="imgPlaceholder"
           [src]="imageSrc ? imageSrc:(getFileUrl(foldConstants.VIDEO))" type="video/mp4" >
          <source *ngIf="((data.file && data.file.length) && !imageSrc)"  src="/assets/images/fold/{{data.file}}" type="video/mp4" />
          Browser not supported
      </video>
   </div>

  </div>

  <div class="buttons">
    <button class="btn btn-primary" type="submit">Save</button>
  </div>
</form>
</div>
</ng-template>
<!--used for add multiple data-->
<!-- <div class="add-fold-link">
  <a href="javascript:void(0)" (click)="addMore()" class="text_link ng-binding" translate="ADD_MORE">Add More</a>
</div> -->
