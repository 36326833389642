import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { WidgetTypeService } from './widget-type.service';
import { forkJoin } from 'rxjs';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { GetRouterLinkService } from '../services/getrouter-link.service';

@Component({
  selector: 'app-widget-type',
  templateUrl: './widget-type.component.html',
  styleUrls: ['./widget-type.component.scss']
})
export class WidgetTypeComponent implements OnInit {
  // Define the calss property
  widgetTypeForm: FormGroup;
  widgetTypeSearchForm: FormGroup;
  wTattributes: any = {};
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private widgetTypeService: WidgetTypeService,
    private utility: Utility,
    private injector: Injector,
    private appComponent: AppComponent,
    private router: Router) {
  }

  ngOnInit() {
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.widgetTypeForm = this.fb.group({
    //     widgetTypeName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
    //     component: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
    //     frontComponent: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
    //     status: ['', [Validators.required]]
    //   });

    //   // This  is widget Type seach form
    //   this.widgetTypeSearchForm = this.fb.group({
    //     widgetTypeName: ['', []]
    //   });

    //   // Copy object
    //   this.wTattributes = Object.assign({}, this.globals.adminPaginationAttrs);

    //   // Load widget type data
    //   this.getWidgetTypeListCount();
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.widgetTypeForm = this.fb.group({
      widgetTypeName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      component: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      frontComponent: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      status: ['', [Validators.required]]
    });

    // This  is widget Type seach form
    this.widgetTypeSearchForm = this.fb.group({
      widgetTypeName: ['', []]
    });

    // Copy object
    this.wTattributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // Load widget type data
    this.getWidgetTypeListCount();

  }

  // convenience getter for easy access to form fields
  get wt() {
    return this.widgetTypeForm.controls;
  }

  // This method is used for search widget type
  widgetTypeSearch() {
    if (this.widgetTypeSearchForm.valid) {
      this.getWidgetTypeListCount();
    }
  }

  // Add Widget type
  addWidgetType(): void {
    // Check Widget validation and save data in db
    if (this.widgetTypeForm.valid) {
      this.widgetTypeForm.value.status = Number(this.widgetTypeForm.value.status);
      this.widgetTypeService.addWidgetType(this.widgetTypeForm.value)
        .subscribe(data => {
          this.toggleForm();
          this.widgetTypeForm.reset();
          // Push new object on zero index
          this.wTattributes.pageItems.splice(0, 0, data);
          this.wTattributes.count += 1;
        }, (err) => {
        });
    } else {
      this.wTattributes.submitted = true;
    }
  }

  // Manage show/hide Widget type form
  toggleForm(): void {
    if (!(this.wTattributes.isIndex >= 0)) {
      this.wTattributes.isShow = !this.wTattributes.isShow;
    }
    this.wTattributes.submitted = false;
    this.widgetTypeForm.reset();
    this.wTattributes.isIndex = -1;
    this.wTattributes.isActiveIdx = -1;
    this.wTattributes.itemArray = [];
    this.wTattributes.statusSelected = 1;
    // Set default  radio button value
    this.widgetTypeForm.get('status').setValue(this.wTattributes.statusSelected);
  }

  // Get single Widget type according to index
  getWidgetTypeForEdit(index: number): void {
    this.toggleForm();
    setTimeout(() => {
      this.wTattributes.isActiveIdx = index;
      this.wTattributes.isIndex = this.utility.getIndex(index, this.wTattributes.pageItems.length);
      this.wTattributes.isShow = false;
      this.wTattributes.itemArray = this.wTattributes.pageItems[index];
      this.widgetTypeForm.patchValue(this.wTattributes.itemArray);
      this.wTattributes.statusSelected = this.wTattributes.itemArray.status;
    }, 0);
  }

  // Update Widget type
  updateWidgetType(id: string): void {
    if (this.widgetTypeForm.valid) {
      this.widgetTypeForm.value.status = Number(this.widgetTypeForm.value.status);
      this.widgetTypeService.updateWidgetType(id, this.widgetTypeForm.value)
        .subscribe(data => {
          this.getWidgetType(id);
          this.wTattributes.isIndex = -1;
          this.wTattributes.isActiveIdx = -1;
          this.widgetTypeForm.reset();
        }, (err) => {

        });
    } else {
      this.wTattributes.submitted = true;
    }
  }

  // Update Widget type
  deleteWidgetType(index: number): void {
    if (index >= 0) {
      this.widgetTypeForm.patchValue(this.wTattributes.pageItems[index]);
      this.widgetTypeForm.value.status = Number(2);
      this.widgetTypeService.updateWidgetType(this.wTattributes.pageItems[index].id, this.widgetTypeForm.value)
        .subscribe(data => {
          // remove item from array
          this.wTattributes.pageItems.splice(index, 1);
          this.wTattributes.count -= 1;
        }, (err) => {

        });
    }
  }

  // Get single Widget type
  getWidgetType(id: string): any {
    this.widgetTypeService.getWidgetTypeById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.wTattributes.pageItems.findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          this.wTattributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      }, (err) => {

      });
  }

  // Get Widget type total count
  getWidgetTypeCount(): void {
    const queryObj: any = {
      where: {
        status: { inq: [1, 0] }
      }
    };

    // This statement for serch widget type list
    if (this.widgetTypeSearchForm && this.widgetTypeSearchForm.value && this.widgetTypeSearchForm.value.widgetTypeName) {
      queryObj.where.widgetTypeName = {
        regexp: 'm.*' + this.widgetTypeSearchForm.value.widgetTypeName + '/mi'
      };
    }
    this.widgetTypeService.findWidgetCount(queryObj)
      .subscribe(data => {
        this.wTattributes.count = (data && data.count) ? data.count : 0;
      }, (err) => {

      });
  }

  // Get Widget type total count
  getWidgetTypeList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        limit: limitNum,
        skip: skipNum,
      }
    };

    // This statement for serch widget type list
    if (this.widgetTypeSearchForm && this.widgetTypeSearchForm.value && this.widgetTypeSearchForm.value.widgetTypeName) {
      queryObj.filter.where.widgetTypeName = {
        regexp: 'm.*' + this.widgetTypeSearchForm.value.widgetTypeName + '/mi'
      };
    }
    this.widgetTypeService.findWidgetType(queryObj)
      .subscribe(data => {
        this.wTattributes.pageItems = (data && data.length) ? data : [];
      }, (err) => {

      });
  }

  // Get told type list/count
  getWidgetTypeListCount(): void {
    forkJoin([this.getWidgetTypeCount(), this.getWidgetTypeList(this.wTattributes.limits, this.wTattributes.skips)]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getWidgetTypeList(this.wTattributes.limits, startItem);
  }

}
