import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { TranslationParams } from '../interface/translate/translate.interface';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  constructor(private translate: TranslateService) { }

  getTranslation(key: string, options?: TranslationParams): string {
    let dataTranslated: string;
    if (options && !isEmpty(options.params)) {
      if (options.areParamsToBeLocalized) {
        this.translate.get(key, options.params).subscribe((res) => {
          dataTranslated = this.translate.instant(res)
        })
        return dataTranslated;
      }
    } else {
      this.translate.get(key).subscribe((res) => {
        dataTranslated = this.translate.instant(res)
      })
      return dataTranslated;
    }
  }
}