import { Type } from "@angular/core";
import { AboutUsComponent } from "../about-us/about-us.component";
import { AboutComponent } from "../about/about.component";
import { AdminEventReviewComponent } from "../admin-event-review/admin-event-review.component";
import { ContactUsComponent } from "../contact-us/contact-us.component";
import { CorporateReviewEventComponent } from "../corporate-review-event/corporate-review-event.component";
import { CorporateTermsAndConditionsComponent } from "../corporate-terms-and-conditions/corporate-terms-and-conditions.component";
import { CorporateUserComponent } from "../corporate-user/corporate-user.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { EmailTemplateComponent } from "../email-template/email-template.component";
import { EventDetailComponent } from "../event-detail/event-detail.component";
import { EventInquiriesComponent } from "../event-inquiries/event-inquiries.component";
import { EventComponent } from "../event/event.component";
import { ExhibitorEventPaymentComponent } from "../exhibitor-event-payment/exhibitor-event-payment.component";
import { ExhibitorInvitationComponent } from "../exhibitor-invitation/exhibitor-invitation.component";
import { ExhibitorTermsAndConditionsComponent } from "../exhibitor-terms-and-conditions/exhibitor-terms-and-conditions.component";
import { FindExhibitorsComponent } from "../find-exhibitors/find-exhibitors.component";
import { FoldTypeComponent } from "../fold-type/fold-type.component";
import { ManageArticleComponent } from "../fold/fold-template/manage-article/manage-article.component";
import { ManageHeroComponent } from "../fold/fold-template/manage-hero/manage-hero.component";
import { ManageHtmlComponent } from "../fold/fold-template/manage-html/manage-html.component";
import { ManageImageComponent } from "../fold/fold-template/manage-image/manage-image.component";
import { ManageSliderComponent } from "../fold/fold-template/manage-slider/manage-slider.component";
import { ManageVideoComponent } from "../fold/fold-template/manage-video/manage-video.component";
import { FoldComponent } from "../fold/fold.component";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { HomeComponent } from "../home/home.component";
import { IndustriesListComponent } from "../industries-list/industries-list.component";
import { IndustriesComponent } from "../industries/industries.component";
import { LeftImageComponent } from "../left-image/left-image.component";
import { LoginComponent } from "../login/login.component";
import { MenuComponent } from "../menu/menu.component";
import { PageComponent } from "../page/page.component";
import { PaymentManagementComponent } from "../payment-management/payment-management.component";
import { PrivacyComponent } from "../privacy/privacy.component";
import { ProfileComponent } from "../profile/profile.component";
import { RefundPolicyComponent } from "../refund-policy/refund-policy.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { ReviewEventsComponent } from "../review-events/review-events.component";
import { RightImageComponent } from "../right-image/right-image.component";
import { ScreeningListComponent } from "../screening-list/screening-list.component";
import { ScreeningComponent } from "../screening/screening.component";
import { SignupComponent } from "../signup/signup.component";
import { SliderComponent } from "../slider/slider.component";
import { TaxonomyTypeComponent } from "../taxonomy-type/taxonomy-type.component";
import { TaxonomyComponent } from "../taxonomy/taxonomy.component";
import { UnregisterEventRequestComponent } from "../unregister-event-request/unregister-event-request.component";
import { UnregisteredExhibitorsComponent } from "../unregistered-exhibitors/unregistered-exhibitors.component";
import { ViewExhibitorsComponent } from "../view-exhibitors/view-exhibitors.component";
import { WhyRegisterComponent } from "../why-register/why-register.component";
import { WidgetTypeComponent } from "../widget-type/widget-type.component";
import { WidgetComponent } from "../widget/widget.component";

export const COMPONENT_LIST: IComponentList[] = [
  {
    componentClass: AboutComponent,
  },
  {
    componentClass: RightImageComponent,
  },
  {
    componentClass: LeftImageComponent,
  },
  {
    componentClass: SliderComponent,
  },
  {
    componentClass: WidgetTypeComponent,
  },
  {
    componentClass: WidgetComponent,
  },
  {
    componentClass: FoldTypeComponent,
  },
  {
    componentClass: FoldComponent,
  },
  {
    componentClass: MenuComponent,
  },
  {
    componentClass: PageComponent,
  },
  {
    componentClass: EventComponent,
  },
  {
    componentClass: TaxonomyComponent,
  },
  {
    componentClass: TaxonomyTypeComponent,
  },
  {
    componentClass: IndustriesComponent,
  },
  {
    componentClass: DashboardComponent,
  },
  {
    componentClass: ScreeningComponent,
  },
  {
    componentClass: ManageSliderComponent,
  },
  {
    componentClass: ManageArticleComponent,
  },
  {
    componentClass: ManageHtmlComponent,
  },
  {
    componentClass: ManageVideoComponent,
  },
  {
    componentClass: ManageImageComponent,
  },
  {
    componentClass: ManageHeroComponent,
  },
  {
    componentClass: HomeComponent,
  },
  {
    componentClass: LoginComponent,
  },
  {
    componentClass: SignupComponent,
  },
  {
    componentClass: EmailTemplateComponent,
  },
  {
    componentClass: CorporateUserComponent,
  },
  {
    componentClass: ProfileComponent,
  },
  {
    componentClass: ForgotPasswordComponent,
  },
  {
    componentClass: EventDetailComponent,
  },
  {
    componentClass: ViewExhibitorsComponent,
  },
  {
    componentClass: ExhibitorInvitationComponent,
  },
  {
    componentClass: CorporateReviewEventComponent,
  },
  {
    componentClass: FindExhibitorsComponent,
  },
  {
    componentClass: ReviewEventsComponent,
  },

  {
    componentClass: AdminEventReviewComponent,
  },
  {
    componentClass: EventInquiriesComponent,
  },
  {
    componentClass: PaymentManagementComponent,
  },
  {
    componentClass: ExhibitorEventPaymentComponent,
  },
  {
    componentClass: ResetPasswordComponent,
  },
  {
    componentClass: UnregisteredExhibitorsComponent,
  },
  {
    componentClass: UnregisterEventRequestComponent,
  },
  {
    componentClass: AboutUsComponent,
  },
  {
    componentClass: PrivacyComponent,
  },
  {
    componentClass: ScreeningListComponent,
  },
  {
    componentClass: ContactUsComponent,
  },
  {
    componentClass: ExhibitorTermsAndConditionsComponent,
  },
  {
    componentClass: WhyRegisterComponent,
  },
  {
    componentClass: CorporateTermsAndConditionsComponent,
  },
  {
    componentClass: IndustriesListComponent,
  },

  {
    componentClass: RefundPolicyComponent,
  },
];

interface IComponentList {
  componentClass: Type<any>;
}

