import { Injectable, Injector } from "@angular/core";
import { CookieBrowser } from "src/app/shared/modules/storage/cookie.browser";
import { StorageBrowser } from "src/app/shared/modules/storage/storage.browser";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject } from "rxjs";
import { NotifierService } from "angular-notifier";
import { SystemConstants } from "../constants/system.constants";
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { LocalizationService } from "./localization.service";
import { CommonStatus } from "../constants/common-status.constants";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  eventStatus: any;
  jwtHelper = new JwtHelperService();
  private loggedInSource = new BehaviorSubject(this.isAuthenticated());
  isLoggedIn = this.loggedInSource.asObservable();
  selectedExhibitorListStatus:any
  selectedEventId:string

  public loggedOutSource = new BehaviorSubject(
    this.isAuthenticatedMasqueradeUser()
  );
  isLoggedOut = this.loggedOutSource.asObservable();

  public masqueradeUserRoleSubject = new BehaviorSubject<number>(null);
  public masqueradeUserRoleObservable = this.masqueradeUserRoleSubject.asObservable();
  private _translatorJson: typeof TranslatorJson = Object.assign({}, (TranslatorJson as any).default);
  constructor
    (private _localizationService: LocalizationService,
    private _notifierService: NotifierService) { }

  public setUser(tokenData: any) {
    this.clear();

    if (tokenData && tokenData.token) {
      if (tokenData.isRemember && tokenData.isRemember == true) {
        const storage = tokenData.isRemember
          ? new StorageBrowser()
          : new CookieBrowser();

        localStorage.setItem(SystemConstants.IS_REMEMBERED, tokenData.isRemember);
        storage.set(SystemConstants.AUTH_TOKEN, tokenData.token);
      } else {
        const storage = tokenData.isRemember
          ? new StorageBrowser()
          : new CookieBrowser();
        storage.set(SystemConstants.AUTH_TOKEN, tokenData.token);
      }
      this.loggedInSource.next(this.isAuthenticated());
    }
  }

  public getToken(): string {
    const isRemember = localStorage.getItem(SystemConstants.IS_REMEMBERED);
    const storage = isRemember ? new StorageBrowser() : new CookieBrowser();
    const isTokenExpired = this.jwtHelper.isTokenExpired(storage.get(SystemConstants.AUTH_TOKEN));
    if (isRemember && isTokenExpired) {
      this._logOutOnSessionOut();
      return null;
    }
    return storage.get(SystemConstants.AUTH_TOKEN);
  }

  public getUserId(): string {
    const token = this.getToken();
    const tokenData = this.jwtHelper.decodeToken(token);
    return (tokenData && tokenData.id) || 21;
  }
  public clear() {
    this._clearStorage();
    this.loggedInSource.next(this.isAuthenticated());
  }
  public setEventStatus(status: number) {
    this.eventStatus = status;
  }
  getEventStatus() {
    let temp = (this.eventStatus || this.eventStatus === 0 ) ? this.eventStatus : CommonStatus.ACTIVE ;
    this.clearData();
    return temp;
  }
  clearData() {
    this.eventStatus = "";
  }
  public setExhibitorListStatus(status: number) {
    this.selectedExhibitorListStatus = status;
  }
  public setEventID(eventId:string) {
    this.selectedEventId = eventId;
  }
  public getSelectedEventId() {
    return this.selectedEventId;
  }
  
  getExhibitorListStatus() {
    let temp
    if(this.selectedExhibitorListStatus || this.selectedExhibitorListStatus === 0 ){
      temp = this.selectedExhibitorListStatus 
    }
    this.selectedExhibitorListStatus = '';
    return temp;
  }
  
  public isAuthenticated(): boolean {
    const authToken = this.getToken();
    return !this.jwtHelper.isTokenExpired(authToken);
  }

  public isAuthenticatedMasqueradeUser(): boolean {
    return localStorage.getItem(SystemConstants.REMEMBER_ME) ? true : false;
  }

  private _logOutOnSessionOut() {
    this._clearStorage();
    localStorage.clear();
    this.loggedInSource.next(false);
    this._notifierService.notify(SystemConstants.ERROR_TEXT, this._localizationService.getTranslation(this._translatorJson.SESSION_EXPIRED_MSG));
  }

  private _clearStorage(): void {
    try {
      const sStorage = new StorageBrowser();
      const cStorage = new CookieBrowser();
      sStorage.remove(SystemConstants.IS_REMEMBERED);
      sStorage.remove(SystemConstants.AUTH_TOKEN);
      cStorage.remove(SystemConstants.IS_REMEMBERED);
      cStorage.remove(SystemConstants.AUTH_TOKEN);
    } catch (e) {
      console.error(e);
    }
  }
}
