<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <div class="main-user-img-upload" hidden>
            <input type="file" />
            <i class="fa fa-camera" aria-hidden="true"></i>
          </div>
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Email Template</h1>
      </div>
    </div>
  </div>
</div>

<div class="main-admin-search email-logs-search">

  <div class="container clearfix main__filter__row">
    <div class="filtersearch">
      <form [formGroup]="emailTempSearchForm" (ngSubmit)="emailTemaplateSearch();">
        <div class="pagefilter__section__in">
          <div class="pagefilter-search-box">
            <input type="text" placeholder="Search Email Template"  formControlName="emailTemplateName"  />
          </div>
          <div class="pagefilter-search-button">
            <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
          </div>
        </div>



      </form>
    </div>
    <div class="pagefilter__addbtn">
      <div class="main-admin-add">
      <a href="javascript:void(0)" (click)="toggleForm()" class="outline-btn" ><i class="fa fa-plus" aria-hidden="true"></i> Add Email Template</a>
    </div> </div>
  </div>

</div>
<!-- Define form template here-->
<div *ngIf="emailTempAttributes.isShow; then emailTemplateFromtemp"></div>

<div class="email-logs-pg">
  <div class="container">
    <div *ngIf="!emailTempAttributes.pageItems || !emailTempAttributes.pageItems.length" class="search-not-found">
      {{translatorJson.NO_RECORD_FOUND | translate}} </div>
    <div class="email-logs-list">
      <div class="email-logs-row" *ngFor="let email of emailTempAttributes.pageItems; let idx = index">
        <div id="email-logs-top" class="email-logs-top clearfix">
            <div class="box-email">{{email.emailTemplateName | slice :0:50}}</div>
            <div class="box-sub">{{email.subject}}</div>
            <div class="box-condition">{{email.status == 1 ? 'Active' : 'Inactive' }}</div>
            <div class="box-action">
            <div class="exhibitors-dropdown" dropdown>
              <div class="action dropdown"> <a href="#" dropdownToggle aria-controls="dropdown-support-menu" (click)="false" class="dropdown-toggle" >Action </a>
                <ul class="dropdown-menu" *dropdownMenu id="dropdown-support-menu">
                  <li class="event-edit-icon"><a  href="javascript:void(0)" (click)="getEmailTempForEdit(idx);">Edit</a></li>
                  <li class="event-declined-icon"><a href="javascript:void(0)" (click)="deleteEmailTemplate(idx);">Delete</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="emailTempAttributes.isIndex==idx; then emailTemplateFromtemp"></div>
      </div>
    </div>
    <div class="pagination__box">
       <pagination [totalItems]="emailTempAttributes.count" [rotate]="emailTempAttributes.rotate" [maxSize]="emailTempAttributes.maxSize" [itemsPerPage]="emailTempAttributes.limits" (pageChanged)="pageChanged($event);"></pagination>
    </div>
  </div>
</div>

<ng-template #emailTemplateFromtemp>
    <div [ngClass]="{'add-email-template-outer':!emailTempAttributes.itemArray.id}">
      <div [ngClass]="{'container':!emailTempAttributes.itemArray.id}">
        <div [ngClass]="{'hfp-row clearfix':!emailTempAttributes.itemArray.id}">
          <div class="">
            <div class="add-email-template-inner">
              <div class="form-close"><button id="close-1" (click)="toggleForm()"><img src="/assets/images/add-form-close.png" alt="" /></button></div>
              <div class="email-template-edit">
              <h1>{{(emailTempAttributes.itemArray.id ? 'EDIT_EMAIL_TEMPLATE' : 'ADD_EMAIL_TEMPLATE') | translate}}</h1>
              <form [formGroup]="emailTempForm"  (ngSubmit)="emailTempAttributes.itemArray.id ? updateEmailTemplate(emailTempAttributes.itemArray.id) : addEmailTemplate()" >
                <div class="">
                  <div class="hfp-input-div">
                    <label>Email Template Name</label>
                    <input class="" type="text" placeholder="Email Template Name" name="emailTemplateName" formControlName="emailTemplateName"  required>
                    <div *ngIf="emailTempf.emailTemplateName.invalid &&  emailTempAttributes.submitted"
                      class="alert alert-danger">
                      <div *ngIf="emailTempf.emailTemplateName.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                      <!-- <div *ngIf="emailTempf.emailTemplateName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                      <div *ngIf="emailTempf.emailTemplateName.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS" | translate}}</div> -->
                    </div>
                  </div>
                  <div class="hfp-input-div">
                    <label>Subject</label>
                    <input class="" type="text" placeholder="Subject" formControlName="subject" name="subject" required >

                   <div *ngIf="emailTempf.subject.invalid && emailTempAttributes.submitted" class="alert alert-danger">
                      <div *ngIf="emailTempf.subject.errors.required"> Subject is required.</div>
                   </div>

                  </div>
                  <div class="hfp-textediter-div">
                    <label>Message</label>
                    <div  [froalaEditor]='globals.floraOptions' formControlName="message" required> </div>
                    <div *ngIf="emailTempf.message.invalid &&  emailTempAttributes.submitted "
                      class="alert alert-danger">
                        <div *ngIf="emailTempf.message.errors.required"> Message is required.</div>
                    </div>
                  </div>
                  <div class="hfp-input-div">
                      <label>{{"STATUS" | translate}}</label>
                      <div class="switch-field Status-tab">
                        <div class="input-radio-design" *ngFor="let status of globals.statusType; let idx = index;">
                          <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status" [value]="status.value" [checked]="emailTempAttributes.statusSelected==status.value" />
                          <label for="switch_left{{idx}}">{{status.name}}</label>
                        </div>
                      </div>
                      <div *ngIf="emailTempf.status.invalid &&  emailTempAttributes.submitted" class="alert alert-danger">
                        <div *ngIf="emailTempf.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
                      </div>
                    </div>
                  <div class="buttons">
                    <button class="btn btn-primary" >Submit</button>
                    <button class="btn btn-default" (click)="toggleForm()">Cancel</button>
                  </div>
                </div>
              </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
