import { Component, Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PageService } from "../app/page/page.service";
import { FoldService } from "../app/fold/fold.service";
import { SharedService } from "./shared.service";
import { Observable } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { MenuService } from "./menu/menu.service";
import { AboutComponent } from "./about/about.component";
import { Location } from '@angular/common';
import { SystemConstants } from "./constants/system.constants";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  myVarTest = false;
  fold: any = [];
  foldData: any = [];
  currentDate = new Date();
  userData: any = {};
  role: number = 21;
  pageItems = [];
  pageData;
  eventNavigationEnd;
  isPageNotFound:boolean= false;
  isFoldNotFound:boolean = false;
  constructor(
    public translate: TranslateService,
    private router: Router,
    private injector: Injector,
    private menuService: MenuService,
    private location: Location
  ) {
    this.setLanguage();
    this.navigateToPreviousPage();
  }

  ngOnInit() {

    const pageService = this.injector.get(PageService);
    const sharedService = this.injector.get(SharedService);
    this.userData = [];
    const queryObj: any = {
      filter: {
        fields: { permalink: true, pageName: true, id: true, foldId: true },
        where: { status: 1 },
      },
    };

    pageService.getPageList(queryObj).subscribe(
      (data) => {
        this.pageItems = data;
        const res = data && data.length ? data : [];
        if (res && res.length) {
          sharedService.getPageList(res);
        } else {
          sharedService.getPageList(null);
        }
      },
      () => { }
    );

    //#TODO: ## give it to the proper name.
    this.router.resetConfig([
      {
        path: "**", component: AboutComponent
      }
    ])
    this.router.events.subscribe((event) => {
      // example: NavigationStart, RoutesRecognized, NavigationEnd

      if (event instanceof NavigationEnd) {
        let path = event.url.replace("/", "");
        if (!path) {
          path = "home";
        }

        sharedService.session$.subscribe((data) => {
          if (data) {
            this.userData = data;
            this.role = this.userData.role
            if (path == "home" && this.userData.role == 0) {
              this.router.navigateByUrl("/dashboard");
            }
          } else {
            this.role = 21;
            this.userData = [];
          }
        });

        /*
          if(this.userData && this.userData.role == 0){

            if(path == "home" &&  this.userData.role == 0){
              this.router.navigateByUrl('/dashboard');
            }

          }*/
          this.isPageNotFound = false;
          this.isFoldNotFound = false;
        this.getPage(path).subscribe(
          (res) => {
            if (res && res.length > 0) {
              this.pageData = res[0];
              const singlePageItem = res[0];
              if (
                singlePageItem &&
                singlePageItem.foldId &&
                singlePageItem.foldId.length > 0
              ) {
                this.getFolds(singlePageItem.foldId).subscribe(
                  (foldRes) => {
                    singlePageItem.folds = foldRes;
                    this.fold = foldRes;
                    const newFoldsData = [];
                    for (let index = 0; index < this.fold.length; index++) {
                      this.fold[index].data = JSON.stringify(this.fold[index].data);
                    }
                    for (let index = 0; index < singlePageItem.foldId.length; index++) {
                      let item = this.fold.find(function (o) {
                        return o.id === singlePageItem.foldId[index];
                      });
                      if (item) {
                        newFoldsData.push(item);
                      }
                    }
                    if (newFoldsData && newFoldsData.length > 0) {
                      this.fold = newFoldsData;
                    }else{
                      this.isFoldNotFound = true;
                    }

                  },
                  (err1) => console.log(err1));
              }
            }else{
              this.isPageNotFound = true;
            }
          },
          (err) =>{  console.log(err)}
        );
      }
    }, (error) => {
      console.log('errors')
    });
  }

  // to get pages and their fold and foldtypes
  getPage(path: any): any {
    const pageService = this.injector.get(PageService);
    return new Observable((observer) => {
      var pathArr = [];
      if (path) {
        pathArr = path.split("/");
      }
      var wheres = {
        status: 1,
        auth: { gte: 0 },
        or: [],
      };
      if (pathArr && pathArr.length) {
        pathArr.forEach(function (path) {
          if (path) {
            wheres.or.push({ permalink: path.toLowerCase() });
          }
        });
      }

      let where = {
        filter: {
          fields: {
            permalink: true,
            pageName: true,
            auth: true,
            foldId: true,
            accessLevel: true,
            status: true
          },
          where: wheres,
        },
      };
      pageService.getPageList(where).subscribe(
        (res: any) => {
          observer.next(res);
        },
        (err) => observer.next(err)
      );
    });
  }

  getFolds(ids): any {
    const foldService = this.injector.get(FoldService);
    return new Observable((observer) => {
      let where = {
        filter: {
          fields: {
            foldName: true,
            foldTypeId: true,
            data: true,
            id: true,
          },
          where: {
            status: 1,
            id: { inq: ids },
            auth: { gte: 0 },
          },
        },
      };
      foldService.getFoldList(where).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => observer.next(err)
      );
    });
  }

  private setLanguage(): void {
    this.translate.addLangs(SystemConstants.LANGUAGES);
    this.translate.setDefaultLang(SystemConstants.DEFAULT_LANGUAGE);
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(SystemConstants.BROWSER_LANGUAGES_PATTERN) ? browserLang : SystemConstants.DEFAULT_LANGUAGE);
  }

  private navigateToPreviousPage(): void {
    this.location.subscribe((loc) => {
      if (loc.pop) {
        this.router.navigate([loc.url])
      }
    })
  }

}
