import {
  Component,
  NgZone,
  TemplateRef,
  OnInit,
  Injector,
} from "@angular/core";
import { SharedService } from "../shared.service";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../login/login.service";
import { AppComponent } from "../app.component";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { forkJoin } from "rxjs";
import { TaxonomyService } from "../taxonomy/taxonomy.service";
import * as _ from "lodash";
// Import utility method class
import { Utility } from "../utility";
// Handle the global property
import { NotifierService } from "angular-notifier";
import { Globals } from "../globals";
import {
  Router,
  RouterModule,
  NavigationStart,
  ActivatedRoute,
  RouterEvent,
  NavigationEnd,
} from "@angular/router";
// import { } from 'googlemaps';
import { MapsAPILoader, AgmMap } from "@agm/core";
import { GoogleMapsAPIWrapper } from "@agm/core/services";
import { MouseEvent } from "@agm/core";
import { FileUploader } from "ng2-file-upload";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as CryptoJS from "crypto-js";
import { CredentialByAdmin } from "../client-schema";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { AboutComponent } from "../about/about.component";
import { CommonStatus } from "../constants/common-status.constants";
import * as translateJson from '../../assets/i18n/en.json'
import { UserRole } from "../constants/user-role.constants";
import { SystemConstants } from "../constants/system.constants";
@Component({
  selector: "app-corporate-user",
  templateUrl: "./corporate-user.component.html",
  styleUrls: ["./corporate-user.component.scss"],
})
export class CorporateUserComponent implements OnInit {
  userData: any;
  userSlug: string = "";
  userRole: any = null;
  loginUserRole: any = null;
  pending: any;
  approved: any;
  decline: any;
  pendingSearch: any;
  approvedSearch: any;
  declinesearch: any;
  totalCorporateUsers = 0;
  memberStatus: any = 1;
  uAttributes: any = {};
  dropIndex = -1;
  showInd = 0;
  dropOpen = false;
  editIndex: number = -1;
  signUpForm: FormGroup;
  exhibitorSearch: number = -1;
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  latitude: number;
  longitude: number;
  zoom: number;
  showScreeingList: boolean = false;
  address: string;
  private geoCoder;
  miles: number = 10;
  private notifier: NotifierService;
  userActiveTab = 1;
  exhibListAttributes: any = {};
  screeningParentList: any = [];
  translateJson: typeof translateJson

  // TODO:- hidning Industries we are not using this time 

  // industriesList: any = [];
  imgUrl: string;
  startItem:number = 0;
  defaultValue = [
    {
      id: 1,
      value: "Day before of the event",
    },
    {
      id: 7,
      value: "One week before the event",
    },
    {
      id: 14,
      value: "Two week before the event",
    },
    {
      id: 28,
      value: "Four week before the event",
    },
  ];

    // TODO:- hidning medical exhibitor

  // medicalExhibitor: any = [
  //   { name: "Yes", value: "1" },
  //   { name: "No", value: "0" },
  // ];

  imageType: any = [
    { type: "avatar", value: "Select Avatar" },
    { type: "image", value: "Upload Image" },
  ];
  fAttributes: any = {};
  public uploader: FileUploader = new FileUploader({
    url:
      environment.apiUrl + "/containers/upload?folderName=users&fileType=image",
  });
  eventStatus: any;
  minStartDate = new Date();
  maxDateCorpSearch = new Date();

  modalRef: BsModalRef;

  userSearchForm: FormGroup;
  currentDate = new Date();

  changePasswordUserName: string;
  changePasswordUserId: any;
  passwordForm: FormGroup;
  submitted = false;
  currentPage = SystemConstants.DEFAULT_CURRENT_PAGE
  // isMedical: boolean;
  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public globals: Globals,
    private loginService: LoginService,
    private utility: Utility,
    private ngZone: NgZone,
    private injector: Injector,
    private taxonomyService: TaxonomyService,
    private modalService: BsModalService,
    private mapsAPILoader: MapsAPILoader,

  ) {
    this.notifier = notifierService;
    this.route.params.subscribe((params) => {
      if (params["slug"]) {
        this.userSlug =
          this.router.url.split("/").length == 4
            ? this.router.url.split("/")[2]
            : "";
        this.userSlug = params["slug"];
      } else {
        this.userSlug = this.router.url.substr(
          this.router.url.lastIndexOf("/") + 1
        );
      }
    });
    if (this.userSlug === "corporate-user") {
      this.userRole = 10;
    }
    if (this.userSlug === "exhibitors") {
      this.userRole = 20;
    }
    //select Date
    {
      this.minStartDate.setDate(this.minStartDate.getDate() - 1);
      this.currentDate.setDate(this.currentDate.getDate() + 1);
      this.maxDateCorpSearch.setDate(this.currentDate.getDate() - 1);

      // Copy object
      this.exhibListAttributes = Object.assign(
        {},
        this.globals.adminPaginationAttrs
      );
    }

    this.passwordForm = this.fb.group({
      nPassword: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.pattern(
            new RegExp(
              "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
            )
          ),
        ],
      ],
      cPassword: ["", Validators.required],
    });
    this.translateJson = Object.assign({}, (translateJson as any).default);
  }

  ngOnInit() {
    
    // member form to get data in template
    this.signUpForm = this.fb.group({
      username: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],
      image: this.fb.group({
        type: ["avatar", Validators.required],
        name: ["", Validators.required],
      }),
      //industry: ['', [Validators.required]],
      region: this.fb.array([]),
      data: this.fb.group({
        number_employee: [0, [Validators.pattern(new RegExp("^[0-9]*$"))]],
        company_name: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(250),
          ],
        ],
        contact: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(250),
          ],
        ],
        // phone_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14),
        // Validators.pattern(new RegExp('^([0-9\(\)\/\+ \-]*)$'))]],
        phone_number: [
          "",
          [
            Validators.required
          ],
        ],
        title: ["User", [Validators.minLength(2), Validators.maxLength(250)]],
        website: [""],
      }),
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            new RegExp("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}")
          ),
        ],
      ],
      address: this.fb.group({
        formatted_address: [""],
        state: ["", Validators.required],
        city: ["", Validators.required],
        //zip_code: ['',Validators.required, []],
        zip_code: [
          "",
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(12),
            Validators.pattern(/^[0-9a-zA-Z]+$/),
          ],
        ],
        lat: [, []],
        lng: [, []],
        addressOne: ["", Validators.required],
      }),
    });

    if (this.userSlug === "exhibitors") {
      this.addMoreFieldsInExibitiorForm();
      // this.getScreeningTaxo()
    }

    // This  is user  seach form
    this.userSearchForm = this.fb.group({
      username: ["", []],
      createdDate: ["", []],
    });

    // Copy object
    this.uAttributes = Object.assign({}, this.globals.adminPaginationAttrs);


    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.eventStatus = this.authService.getEventStatus();
    //   if (this.eventStatus && this.eventStatus >= 0) {
    //     this.userActiveTab = this.eventStatus;
    //   }
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   this.getMemberCountAndTotalParticipents();
    //   this.getUserList(
    //     this.userActiveTab,
    //     this.exhibListAttributes.limits,
    //     this.exhibListAttributes.skips
    //   );
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    // to check app session still alive or not
    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.eventStatus = this.authService.getEventStatus();
          if (this.eventStatus >= 0) {
            this.userActiveTab = this.eventStatus;
          }
          this.userData = this.appComponent.userData;
          this.loginUserRole = this.userData.role;
          if(this.loginUserRole === UserRole.SUPER_ADMIN){
            this.getMemberCountAndTotalParticipents();
            this.getUserList(
              this.userActiveTab,
              this.exhibListAttributes.limits,
              this.exhibListAttributes.skips
            );
            if (this.uAttributes.itemArray.region && this.uAttributes.itemArray.region.length) {
              this.latitude = this.uAttributes.itemArray.region[0].lat ? this.uAttributes.itemArray.region[0].lat : 47.751076;
              this.longitude = this.uAttributes.itemArray.region[0].long ? this.uAttributes.itemArray.region[0].long : -120.740135;
  
            } else {
              this.latitude = 47.751076;
              this.longitude = -120.740135;
            }
  
            this.zoom = 7;
            this.geoCoder = new google.maps.Geocoder;
    
          }else{
            this.router.navigateByUrl("/home");
          }         
        } else {
          this.router.navigateByUrl("/");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    // file upload steps
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      const data = JSON.parse(response);

      if (
        status === 200 &&
        data &&
        data.files &&
        data.files[0] &&
        data.files[0].filename
      ) {
        this.imgUrl = data.files[0].filename;
        //console.log(this.imgUrl);
        //  this.eventMediaArray.push(new FormControl(data.files[0].filename));
      } else {
        if (data && data.error && data.error.message) {
          //this.notifier.notify('error', data.error.message);
        } else {
          //this.notifier.notify('error', 'Something went wrong!');
        }
      }
    };
  }
  onImageSelect(event) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageSrc = event.target.result;
        this.uploader.uploadAll();
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  userSearch(): void {
    this.exhibitorSearch = 1;

    forkJoin([
      this.getMemberTotalCount(),
      this.getUserList(
        this.userActiveTab,
        this.exhibListAttributes.limits,
        this.exhibListAttributes.skips
      ),
    ]);
    // forkJoin([
    //   this.getUserList(
    //     this.userActiveTab,
    //     this.exhibListAttributes.limits,
    //     this.exhibListAttributes.skips
    //   ),
    // ]);
  }

  // Get Industry array from form Group
  get getAddress() {
    return this.signUpForm.get("address") as FormGroup;
  }

  get addRegionGroup() {
    return this.fb.group({
      name: [],
      lat: [],
      long: [],
      miles: [],
      meters: [],
    }) as FormGroup;
  }

  // Get Region array from Form Group
  get regionArray() {
    return this.signUpForm.get("region") as FormArray;
  }

  get addIndustryGroup() {
    return this.fb.group({ objectId1: [], objectId2: [] }) as FormGroup;
  }

  // Get Industry array from form Group

  // TODO:- hidning Industries we are not using this time 

  // get industryArray() {
  //   return this.signUpForm.get("industry") as FormArray;
  // }

  // to get data group from main form
  get getDataFromSigupForm() {
    return this.signUpForm.get("data") as FormGroup;
  }

  get getRemainderArray() {
    return this.getDataFromSigupForm.get("reminder") as FormArray;
  }

  getScreeningTaxo(): void {
    forkJoin([
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId,
          },
        },
      }),

      // TODO:- hidning Industries we are not using this time 

      // this.taxonomyService.getTaxonomyList({
      //   filter: {
      //     where: {
      //       status: 1,
      //       taxonomyTypeId: this.globals.taxonomyTypesConst.industryId,
      //     },
      //   },
      // }),
    ]).subscribe(
      (results) => {
        this.screeningParentList = results[0] ? results[0] : [];
        this.screeningParentList.forEach(item => {
          item.formattedName = this.buildFormattedName(item, this.screeningParentList);
      });
      this.screeningParentList.sort((a, b) => {
        return a.formattedName.localeCompare(b.formattedName);
    });

        // TODO:- hidning Industries we are not using this time 

        // this.industriesList = results[1] ? results[1] : [];

        this.showInd = 1;
      },
      (err) => { }
    );
  }

  buildFormattedName(item, allItems) {
    if (!item.parentId) {
        return item.name;
    }
    const parent = allItems.find(parentItem => parentItem.id === item.parentId);
    if (!parent) {
        return item.name;
    }
    const parentName = this.buildFormattedName(parent, allItems);
    return `${parentName} - ${item.name}`;
}

  // add more fileds to exibitor form
  addMoreFieldsInExibitiorForm(): void {

    // TODO:- Hiding the medical and insurance formcontrol

    // this.getDataFromSigupForm.addControl("medical", new FormControl("0"));
    // this.getDataFromSigupForm.addControl(
    //   "insurance",
    //   new FormControl("")
    // );
    // this.getDataFromSigupForm.addControl('attendevent', new FormControl('', Validators.required));
    // this.getDataFromSigupForm.addControl(
    //   "attendevent",
    //   new FormControl("", [])
    // );
    // this.getDataFromSigupForm.addControl("infoFair", new FormControl("", []));
    // this.getDataFromSigupForm.addControl('offered', new FormControl('', Validators.required));
    // this.getDataFromSigupForm.addControl("offered", new FormControl("", []));
    this.getDataFromSigupForm.addControl(
      "reminder",
      this.fb.array([1, 7, 14, 28])
    );
  }

  corporateUserUpdate(id: string): void {
    if (this.imgUrl && this.imgUrl != "") {
      this.signUpForm.patchValue({
        image: { name: this.imgUrl, type: "image" },
      });
    } else {
      this.signUpForm.value.image = "";
    }
    if(this.phone.value){
      this.phone.setErrors(null);
      this.phone.updateValueAndValidity()
      }
    if (this.signUpForm.valid) {
      const queryObj: any = this.signUpForm.value;
      console.log(id);
      this.loginService.updateUser(id, queryObj).subscribe(
        (data) => {
          this.notifier.notify("success", "Update Successful!");
          this.getUserById(id);
          this.uAttributes.isIndex = -1;
          this.uAttributes.isActiveIdx = -1;
          this.editIndex = -1;
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    } else {
      //console.log(this.signUpForm);
      this.uAttributes.submitted = true;
    }
  }

  exibitorUserUpdate(id: string): void {
    if(this.phone.value){
    this.phone.setErrors(null);
    this.phone.updateValueAndValidity()
    }
    if (this.signUpForm.valid) {
      let exibObj = this.signUpForm.value;
      exibObj.region = this.uAttributes.itemArray.region;
      try {
        delete exibObj.data.number_employee;
      } catch (error) { }

      this.loginService.updateUser(id, exibObj).subscribe(
        (data) => {
          this.notifier.notify("success", "Update Successful!");
          this.getUserById(id);
          this.uAttributes.isIndex = -1;
          this.uAttributes.isActiveIdx = -1;
          this.editIndex = -1;
          this.getUserList(this.userActiveTab,this.exhibListAttributes.limits,this.startItem);        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    } else {
      this.uAttributes.submitted = true;
    }
  }
  searchTaxo(term: string, item: any) {
    // console.log(term,item);
    // let strSearchLow = term.toLowerCase();
    // let objectLowerCase = item.name.toLowerCase();
    // var n = objectLowerCase.startsWith(strSearchLow);
    // if (n && n == true) {
    //   return item;
    // }
    let strSearchLow = term.toLowerCase();
    let nameLowerCase = item.name.toLowerCase();
    let formattedNameLowerCase = item.formattedName.toLowerCase();
    
    // Check if either the item's name or its formatted name contains the search term
    if (nameLowerCase.includes(strSearchLow) || formattedNameLowerCase.includes(strSearchLow)) {
        return true;
    } else {
        return false;
    }
  }

  // Get single user data
  getUserById(id: string): any {
    this.loginService.getUserById(id).subscribe(
      (cuData) => {
        // Return object index
        const getIndex: number = this.uAttributes.pageItems.findIndex(
          (x) => String(x.id) === String(id)
        );
        if (getIndex >= 0) {
          this.uAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      },
      (err) => { }
    );
  }

  getMemberTotalCount(): void {
    const queryObj: any = {
      where: {
        status: Number(this.userActiveTab),
        role: Number(this.userRole),
      },
    };

    // This statement for user serach
    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.username
    ) {
      queryObj.where.$or = [
        {
          username:  { $regex:this.userSearchForm.value.username, $options: "i" }
        },
        {
          email:{ $regex:this.userSearchForm.value.username, $options: "i" },
        },
        { 
          "data.company_name": 
          { $regex:this.userSearchForm.value.username, $options: "i" }
        },
        {
          "data.contact":
          { $regex:this.userSearchForm.value.username, $options: "i" }
        },
      ];
    }
    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.createdDate &&
      this.userSearchForm.value.createdDate[0]
    ) {
      var start = new Date(this.userSearchForm.value.createdDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.userSearchForm.value.createdDate[1]);
      end.setHours(23, 59, 59, 999);
      queryObj.where.and = [
        { created: { gte: start } },
        { created: { lte: end } },
      ];
    } else if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.createdDate
    ) {
      var start = new Date(this.userSearchForm.value.createdDate);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.userSearchForm.value.createdDate);
      end.setHours(23, 59, 59, 999);
      queryObj.where.and = [
        { created: { gte: start } },
        { created: { lte: end } },
      ];
    }

    this.loginService.findUserCount(queryObj).subscribe(
      (data) => {
        this.uAttributes.count = data && data.count ? data.count : 0;

        if (this.userActiveTab == 0) {
          this.pendingSearch = this.uAttributes.count;
        } else if (this.userActiveTab == 1) {
          this.approvedSearch = this.uAttributes.count;
        } else if (this.userActiveTab == 1) {
          this.declinesearch = this.uAttributes.count;
        }
      },
      (err) => { }
    );
  }

  getUserList(status: number, limitNum: number, skipNum: number): void {
    //  console.log(limitNum);
    this.currentPage = SystemConstants.DEFAULT_CURRENT_PAGE;
    this.editIndex = -1;
    this.uAttributes.isIndex = -1;
    this.memberStatus = Number(status);
    const queryObj: any = {
      filter: {
        where: {
          status: status,
          role: this.userRole,
        },
        limit: limitNum,
        skip: skipNum,
        order: "created DESC",
      },
    };

    // This statement for serch user list
    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.username
    ) {
      queryObj.filter.where.$or = [
        {
          username: {
            $regex: this.userSearchForm.value.username, $options: "i"
          },
        },
        {
          email: {
            $regex:this.userSearchForm.value.username, $options: "i" 
          },
        },
        { 
          "data.company_name": 
          { $regex:this.userSearchForm.value.username, $options: "i" }
        },
        {
          "data.contact":
          { $regex:this.userSearchForm.value.username, $options: "i" }
        },
      ];
    }

    if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.createdDate &&
      this.userSearchForm.value.createdDate[0]
    ) {
      var start = new Date(this.userSearchForm.value.createdDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.userSearchForm.value.createdDate[1]);
      end.setHours(23, 59, 59, 999);
      queryObj.filter.where.and = [
        { created: { gte: start } },
        { created: { lte: end } },
      ];
    } else if (
      this.userSearchForm &&
      this.userSearchForm.value &&
      this.userSearchForm.value.createdDate
    ) {
      var start = new Date(this.userSearchForm.value.createdDate);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.userSearchForm.value.createdDate);
      end.setHours(23, 59, 59, 999);
      queryObj.filter.where.and = [
        { created: { gte: start } },
        { created: { lte: end } },
      ];
    }

    this.loginService.findUserListByAdmin(queryObj).subscribe(
      (data) => {
        this.uAttributes.pageItems = data && data.length ? data : [];
      },
      (err) => { }
    );
  }

  /**
   *
   * @param event masquerade View any user without login password BySuperAdmin
   */

  public masqueradeViewBySuperAdmin(userEmail) {
    // console.log(userEmail);
    let key = "key 4 admin1234";

    let queryObj: CredentialByAdmin = {
      email: CryptoJS.AES.encrypt(userEmail, key).toString(),
      rememberMe: true,
    };
    // this.authService.clear();
    localStorage.removeItem("isRemember");
    // localStorage.removeItem("auth_token");
    this.authService.masqueradeUserRoleSubject.next(null);
    this.userRole = -1;
    this.loginService.userLoginByAdmin(queryObj).subscribe(
      (res) => {
        let userD: any = (res as any).userData;
        localStorage.setItem("RememberMe", "true");
        this.authService.loggedOutSource.next(true);
        this.authService.masqueradeUserRoleSubject.next(userD.role);
        if (userD.role == 20 && (userD.region.length == 0 || userD.region[0].loc[0] == 0)) {
          this.router.navigateByUrl("/profile");
        } else if (userD.role == 20 || userD.role == 10) {
          this.router.navigateByUrl("/event");
        }
      },
      (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify("error", err.error.error.message);
        }
      }
    );
  }

  /**
   * This function is used for pagination
   */
  pageChanged(event: PageChangedEvent): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.getUserList(this.userActiveTab, this.uAttributes.limits, this.startItem);
  }

  // Get told  list/count
  getMemberCountAndTotalParticipents(): void {
    forkJoin([
      this.loginService.findUserCount({
        where: { status: 0, role: this.userRole },
      }),
      this.loginService.findUserCount({
        where: { status: 1, role: this.userRole },
      }),
      this.loginService.findUserCount({
        where: { status: 2, role: this.userRole },
      }),
    ]).subscribe(
      (results) => {
        this.pending = results[0].count ? results[0].count : 0;
        this.approved = results[1].count ? results[1].count : 0;
        this.decline = results[2].count ? results[2].count : 0;
        this.totalCorporateUsers =
          Number(this.pending) + Number(this.approved) + Number(this.decline);

        if (this.pending.length == 0) {
          this.pending = 0;
        }
        if (this.approved.length == 0) {
          this.approved = 0;
        }
        if (this.decline.length == 0) {
          this.decline = 0;
        }

        this.uAttributes.count =
          this.userActiveTab === 1
            ? this.approved
            : this.userActiveTab === 0
              ? this.pending
              : this.decline;
      },
      (err) => { }
    );
  }

  removeEventName(): void {
    this.userSearchForm.get("username").setValue("");
    this.userSearch();
  }
  removeEventDate(): void {
    this.userSearchForm.get("createdDate").setValue("");
    this.userSearch();
  }

  resetSearchForm(st: number): void {
    this.exhibitorSearch = 0;
    this.userSearchForm.reset();
    this.getMemberCountAndTotalParticipents();
    this.getUserList(
      st,
      this.exhibListAttributes.limits,
      this.exhibListAttributes.skips
    );
  }
  /*
   * add medical value to data
   */
  // onRadioBoxChange(option, event) {
  //   if (event.target.checked) {
  //     this.signUpForm.patchValue({
  //       data: { medical: option.value },
  //     });
  //   }
  //   this.isMedical = !this.isMedical;
  // }

  /*
   * Push Default reminder value in array
   */
  onCheckboxChange(option, event) {
    if (this.getRemainderArray) {
      if (event.target.checked) {
        this.getRemainderArray.push(new FormControl(option.id));
      } else {
        for (var i = 0; i < this.getRemainderArray.length; i++) {
          if (
            this.getRemainderArray &&
            this.getRemainderArray.at(i).value == option.id
          ) {
            this.getRemainderArray.removeAt(i);
          }
        }
      }
    }
  }

  /*Add or Remove
   *class for open
   *drop down menu*/
  dropDown(index: number): void {
    if (this.dropIndex == index) {
      this.dropOpen = this.dropOpen ? false : true;
    } else {
      this.dropIndex = index;
      this.dropOpen = true;
    }
  }

  getMember(index: number): void {
    setTimeout(() => {
      // console.log("i am in");
      // this.uAttributes.isActiveIdx = index;
      // this.uAttributes.isIndex = this.utility.getIndex(index, this.uAttributes.pageItems.length);
      this.uAttributes.itemArray = this.uAttributes.pageItems[index];

      //this.widgetForm.patchValue(this.uAttributes.itemArray);
    }, 0);
  }

  get screeningArray() {
    return this.signUpForm.get("screening") as FormArray;
  }
  getMemberDetail(index: number): void {
    if (this.editIndex == index) {
    } else {
      // this.isMedical = false;
      this.uAttributes.itemArray = this.uAttributes.pageItems[index];
      this.signUpForm.patchValue(this.uAttributes.itemArray);
      // this.showInsuranceInputBox()
      if (this.uAttributes.itemArray.role == 20) {
        this.getScreeningTaxo();

        // TODO:- hidning Industries we are not using this time 

        // this.signUpForm.addControl(
        //   "industry",
        //   new FormControl("", Validators.required)
        // );
        this.signUpForm.addControl("screening", new FormControl([],Validators.required));
        this.signUpForm.addControl(
          "screenRequired",
          new FormControl(this.uAttributes.itemArray.screenRequired)
        );
        this.signUpForm.patchValue(this.uAttributes.itemArray);
      }

      setTimeout(() => {
        // this.uAttributes.isActiveIdx = index;
        //this.editIndex  = this.utility.getIndex(index, this.uAttributes.pageItems.length);
        //   this.getScreeningTaxo();

        //  console.log("I am innnnnnnnnnnnnn", this.uAttributes.itemArray);

        if (
          this.uAttributes.itemArray.role == 20 &&
          this.uAttributes.itemArray.screening &&
          this.uAttributes.itemArray.screening.length > 0
        ) {
          this.showScreeingList = true;
          this.latitude = this.uAttributes.itemArray.region[0].lat
            ? this.uAttributes.itemArray.region[0].lat
            : 47.751076;
          this.longitude = this.uAttributes.itemArray.region[0].long
            ? this.uAttributes.itemArray.region[0].long
            : -120.740135;
          //this.signUpForm.value.screenRequired = this.uAttributes.itemArray.screenRequired ? this.uAttributes.itemArray.screenRequired :false;
        }
        /**/
      }, 0);
    }
  }
  screeningCheckboxChange(values: any) {
    //  console.log(values.currentTarget.checked);
    if (values.currentTarget.checked == true) {
      this.showScreeingList = true;
    } else if (values.currentTarget.checked == false) {
      this.showScreeingList = false;
      //  console.log(values.currentTarget.checked);
    }
  }

  /*
   * Decline User
   */
  userOperation(
    index: number,
    roleStatus: number,
    userNames: string,
    userId: any
  ): any {
    this.uAttributes.itemArray = this.uAttributes.pageItems[index];
    //const getIndex: number = this.uAttributes.pageItems.findIndex((x) => String(x.id) === String(index));
    const queryObj: any = { status: roleStatus };
    this.loginService
      .updateUser(this.uAttributes.itemArray.id, queryObj)
      .subscribe(
        (data) => {
          this.uAttributes.pageItems.splice(index, 1);
          this.getUserList(this.userActiveTab,this.exhibListAttributes.limits,this.startItem);
          this.getMemberCountAndTotalParticipents();
          if(roleStatus === CommonStatus.DELETE){
            this.notifier.notify(this.translateJson.SUCCESS, this.translateJson.USER_DELETED)
          }

          const sts: number = roleStatus;
          if (sts === 1) {
            let temEmail: any = {};
            temEmail.userId = userId;
            temEmail.slug = "account-has-been-accepted-template";
            //  temEmail.subject = 'Your Event Approved';
            temEmail.keys = { userName: userNames,
            companyName:this.uAttributes.itemArray && this.uAttributes.itemArray.data && this.uAttributes.itemArray.data.company_name 
          };
            this.emailSends(temEmail);
            this.notifier.notify("success", "This user has been approved.");
            // this.closPopup();
          } else if (sts === 2) {
            //  this.allEventData[getIndex].eventType = this.selectedEvenType;
            let temEmail: any = {};
            temEmail.userId = userId;
            temEmail.slug = "account-has-been-declined-template";
            // temEmail.subject = 'Your Event Declined';
            temEmail.keys = { userName: userNames,
              companyName:this.uAttributes.itemArray && this.uAttributes.itemArray.data && this.uAttributes.itemArray.data.company_name 
             };
            this.emailSends(temEmail);
            this.notifier.notify("success", "This user has been declined.");
            // this.closPopup();
          }
        },
        (err) => { }
      );
  }
  //email send
  emailSends(sendObj: any) {
    this.loginService.sendEmail(sendObj).subscribe(
      (data) => {
        console.log("email send success");
        console.log(data);
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      }
    );
  }
  /*
   * Reset value after close
   */
  resetFormData(idx: number) {
    this.uAttributes.itemArray = this.uAttributes.pageItems[idx];
    this.editIndex = -1;
    this.dropIndex = -1;
  }
  resetForm(id: string) {
    this.signUpForm.patchValue(this.uAttributes.itemArray);
    //console.log("abc", this.uAttributes)
  }

  // Google autocomplete address for address field
  onAddreessChange(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>event.srcElement,
      {
        types: ["geocode"],
      }
    );
    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      const place = autocompleteFrom.getPlace();
      if (place.address_components && place.address_components.length) {
        if (place.formatted_address) {
          this.signUpForm.patchValue({
            address: { addressOne: place.formatted_address },
          });
        }
        for (let i = 0; i < place.address_components.length; i++) {
          this.signUpForm.patchValue({
            address: { lat: place.geometry.location.lat() },
          });

          this.signUpForm.patchValue({
            address: { lng: place.geometry.location.lng() },
          });

          let addressType = place.address_components[i].types[0];
          if (addressType === "administrative_area_level_2") {
            this.signUpForm.patchValue({
              address: { city: place.address_components[i].long_name },
            });
          }
          if (addressType === "administrative_area_level_1") {
            this.signUpForm.patchValue({
              address: { state: place.address_components[i].long_name },
            });
          }
          if (addressType === "postal_code") {
            this.signUpForm.patchValue({
              address: { zip_code: place.address_components[i].long_name },
            });
          }
        }
      }
    });
  }

  getAddressAccordingLatLng(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        //    console.log("john doe",results);
        var regionsObj = {
          name: results[0].formatted_address,
          lat: latitude,
          long: longitude,
          loc: [longitude, latitude],
          miles: this.miles,
          meters: this.miles * 1609.34,
          centerRadius: 16094
        };

        if (this.uAttributes.itemArray && this.uAttributes.itemArray.region) {
          this.uAttributes.itemArray.region.push(regionsObj);
        }
      }
    });
  }
  /*
   * Remove circle
   */
  remvoeCircle(index: number): any {
    this.uAttributes.itemArray.region.pop(index);
  }

  // serach address for region

  // Google autocomplete address for address field
  onSearchAddressForRegion(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>event.srcElement,
      {
        types: ["geocode"],
      }
    );

    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      this.ngZone.run(() => {
        // to get the place result
        let place: google.maps.places.PlaceResult = autocompleteFrom.getPlace();
        // to verify result from map
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // to set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.getAddressAccordingLatLng(this.latitude, this.longitude);
        this.zoom = 7;
      });
    });
  }

  openChangePasswordModal(
    passwordTemplate: TemplateRef<any>,
    userName: string,
    userId: any
  ) {
    this.changePasswordUserName = userName;
    this.changePasswordUserId = userId;
    this.modalRef = this.modalService.show(passwordTemplate);
  }

  changePasswordByAdmin() {
    let passwordObj = {
      nPassword: new String(),
      cPassword: new String(),
    };

    passwordObj = this.passwordForm.value;

    if (passwordObj.cPassword !== passwordObj.nPassword) {
      // alert(.nPassword do not match")
      this.notifier.notify("error", "password not match");
      this.submitted = true;
      return;
    } else if (
      passwordObj.cPassword.length == 0 ||
      passwordObj.nPassword.length == 0
    ) {
      this.notifier.notify("error", "Fields are required!");
      this.submitted = true;
      return;
    } else {
      this.submitted = false;
      const queryObj: any = { password: passwordObj.cPassword };
      this.loginService
        .changePasswordByAdmin(this.changePasswordUserId, queryObj)
        .subscribe(
          (res) => {
            this.passwordForm.reset();
            let temEmail: any = {};
            (temEmail.userId = this.changePasswordUserId),
              (temEmail.slug = "change-password-template");

            temEmail.keys = {
              username: this.changePasswordUserName,
              newpwd: passwordObj.cPassword,
            };
            // this.sendEmailForChangePassword(temEmail);
            // console.log(temEmail);

            this.notifier.notify("success", "Successful changed pasword!");
            this.modalRef.hide();
          },
          (err) => {
            if (
              err &&
              err.error &&
              err.error.error &&
              err.error.error.message
            ) {
              this.notifier.notify("error", err.error.error.message);
            }
          }
        );
    }
  }

  sendEmailForChangePassword(sendObj: any) {
    this.loginService.sendEmailRegister(sendObj).subscribe(
      (data) => {
        console.log("email send success");
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      }
    );
  }

  openConfirmationDialog(object: { [key: string]: any }) {
    this.modalRef = this.modalService.show(ConfirmationDialogComponent);
    this.modalRef.onHidden.subscribe((flag: any) => {
      if (flag && !flag.id) {
        this.modalRef.hide();
        this.remvoeCircle(object.index)
      }
    });
  }

  // get insuranceData(): AbstractControl {
  //   return this.getDataFromSigupForm.get("insurance");
  // }

  // private showInsuranceInputBox() {
    // if (this.uAttributes.itemArray && this.uAttributes.itemArray.data && this.uAttributes.itemArray.data.insurance !== "") {
    //   this.isMedical = true;
    // }
  // }
  get userSignUpFormGroup(){
    return this.signUpForm.get('data') as FormGroup
  }
  get data() {
    return this.userSignUpFormGroup.controls;
  }
  get phone(){
    return this.data.phone_number
  }
  getServices(serviceId:string):string{
    let selectedService:string
     this.screeningParentList.forEach((value) => {
      if (value._id == serviceId) {
        selectedService =  value.name;
      }
    });
    if(selectedService)   return selectedService 
  }
  centerChanged($event: any, indexed: number) {
    this.uAttributes.itemArray.region[indexed]["centerRadius"] = $event;
    this.uAttributes.itemArray.region[indexed]["miles"] = $event * 0.00062137;
  }
}
