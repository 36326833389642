<div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <!-- <div class="main-user-img-upload">
                <input type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect2($event)" />
                <i class="fa fa-camera" aria-hidden="true"></i></div> -->
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)"
            [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))"
            alt="" />
          <!-- <img *ngIf="userData.image.type != 'avatar'" src="/assets/images/users/{{userData.image.name}}" /> -->
        </div>
        <h2>{{userData.username}}</h2>
      </div>

      <div class="main-heading-right">
        <h1>Events <span *ngIf="loginUserRole===0">: {{adminApprovedSearchCount + adminApprovedCount + adminPendingCount
            + adminDeclinedCount}}</span></h1>
      </div>

      <div class="heading_page_type" *ngIf="loginUserRole===0">
        <ul>
          <li>
            <a (click)="getEventListAndCount(commonStatus.ACTIVE)"
              [ngClass]="{'active': adminActiveTab == commonStatus.ACTIVE }"> <i><img src="/assets/images/sm-paid-icon.png" alt="" /></i>
              <div class="paymet-status-right"> <span>{{adminApprovedCount}}</span> Approved </div>
            </a>
          </li>
          <li>
            <a (click)="getEventListAndCount(commonStatus.PENDING)"
              [ngClass]="{'active': adminActiveTab == commonStatus.PENDING }"> <i><img src="/assets/images/sm-peding-icon.png"
                  alt="" /></i>
              <div class="paymet-status-right"> <span>{{adminPendingCount}}</span> Pending </div>
            </a>
          </li>
          <li>
            <a (click)="getEventListAndCount(commonStatus.DECLINE)"
              [ngClass]="{'active': adminActiveTab == commonStatus.DECLINE }"> <i><img src="/assets/images/sm-declined-icon.png"
                  alt="" /></i>
              <div class="paymet-status-right"> <span>{{adminDeclinedCount}}</span> Declined </div>
            </a>
          </li>
          <li>
            <!--;-->
            <a (click)="getPastEventListAndCount(commonStatus.ACTIVE)"
              [ngClass]="{'active': adminActiveTab == commonStatus.ACTIVE_IN_PAST}"> <i><img src="/assets/images/sm-paid-icon.png" alt="" /></i>
              <div class="paymet-status-right"> <span>{{adminApprovedSearchCount}}</span> Past Events</div>
            </a>
          </li>
        </ul>
      </div>
      <div class="top-event-link" *ngIf="loginUserRole===10">
        <ul>
          <li><a href="javascript:void(0)"
              (click)="exhbitorEventActiveTab=1;exhibitorSearch=0;getCorporateEventListAndCounts();"
              [ngClass]="{'active': exhbitorEventActiveTab == 1 }"><span>{{activeEventCount}}</span> Active Events </a>
          </li>
          <li><a href="javascript:void(0)"
              (click)="exhbitorEventActiveTab=0;exhibitorSearch=0;getCorporateEventListAndCounts();"
              [ngClass]="{'active': exhbitorEventActiveTab == 0 }"><span>{{pastEventCount}}</span> Past Events </a>
          </li>

        </ul>
      </div>
      <div class="top-event-link" *ngIf="loginUserRole===20">
        <ul>
          <li><a href="javascript:void(0)"
              (click)="exhbitorEventActiveTab=1;exhibitorSearch=0;getExhibitorEventListAndCounts(1,10,0);"
              [ngClass]="{'active': exhbitorEventActiveTab == 1 }"><span>{{activeEventCount}}</span> Active Events </a>
          </li>
          <li><a href="javascript:void(0)"
              (click)="exhbitorEventActiveTab=0;exhibitorSearch=0;getExhibitorEventListAndCounts(0,10,0);"
              [ngClass]="{'active': exhbitorEventActiveTab == 0 }"><span>{{pastEventCount}}</span> Past Events </a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>



<div class="main-admin-search event__admin__search"
  [ngClass]="{'event__corporate__search': userData?.role == usersRoleStatus.CORPORATE, 'event__exhibitor__search': userData?.role == usersRoleStatus.EXHIBITOR}">
  <div class="container clearfix main__filter__row">
    <div class="main__filter__tag">
      <div class="active-tag-box" *ngIf="adminActiveTab === EventTabStatus.APPROVED_PAST && loginUserRole===usersRoleStatus.ADMIN">
        <i><img src="/assets/images/sm-paid-icon.png" alt=""></i> App.past <span><a href="javascript:void(0)"><img
              src="/assets/images/tag-close-icon.png" alt=""></a> </span>
      </div>
      <div class="active-tag-box" *ngIf="adminActiveTab === EventTabStatus.APPROVED && loginUserRole===usersRoleStatus.ADMIN">
        <i><img src="/assets/images/sm-paid-icon.png" alt=""></i> Approved <span><a href="javascript:void(0)"><img
              src="/assets/images/tag-close-icon.png" alt=""></a> </span>
      </div>
      <div class="active-tag-box" *ngIf="adminActiveTab === EventTabStatus.PENDING && loginUserRole===usersRoleStatus.ADMIN">
        <i><img src="/assets/images/sm-peding-icon.png" alt=""></i> Pending <span><a href="javascript:void(0)"><img
              src="/assets/images/tag-close-icon.png" alt=""></a> </span>
      </div>
      <div class="active-tag-box" *ngIf="adminActiveTab === EventTabStatus.DECLINED && loginUserRole===usersRoleStatus.ADMIN">
        <i><img src="/assets/images/sm-declined-icon.png" alt=""></i> Declined <span><a href="javascript:void(0)"><img
              src="/assets/images/tag-close-icon.png" alt=""></a> </span>
      </div>
    </div>
    <div class="pagefilter__section">
      <form [formGroup]="eventSearchForm" (ngSubmit)="eventSearch();">
        <div class="pagefilter__section__in">
          <div class="pagefilter-search-box">
            <input type="text" value="" placeholder="{{translateJson.SEARCH_EVENT | translate}}" formControlName="eventName">
          </div>
          <div class="pagefilter-search-paymet" *ngIf="userData?.role == 20000">
            <input class="regions-icon" type="text" value="" placeholder="{{translateJson.SHOW_ALL_REGIONS| translate}}">
          </div>
          <!-- {{minStartDate}} -->


          <div class="pagefilter-search-date">
            <input class="date-icon" type="text" *ngIf="userData?.role == 20 || userData?.role == 10" bsDatepicker
              placeholder="mm-dd-yyyy" [minDate]="minStartDate" formControlName="eventDate"
              value="{{ eventSearchForm.value.eventDate | date }}" [maxDate]="maxStartDate">

            <input class="date-icon" type="text" *ngIf="userData?.role == 0" bsDaterangepicker placeholder="mm-dd-yyyy"
              formControlName="eventDate" value="{{ eventSearchForm.value.eventDate}}">
          </div>
          <div class="search-by-region-input">
            <input type="text" id="eventMap" name="addressOne"
            (keydown.enter)="$event.preventDefault();" (click)="onSearchAddressForRegion($event)" placeholder="{{translateJson.SEARCH_BY_REGION | translate}}" autocorrect="off"
            autocapitalize="off"  spellcheck="off" type="text" formControlName="searchByRegion" #search>
          </div>
          <div class="pagefilter-search-button">
            <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
          </div>
        </div>
      </form>
    </div>
    <div class="pagefilter__addbtn" *ngIf="userData?.role == 0 || userData?.role == 10">
      <!-- <div class="main-admin-add">
        <a class="outline-btn" (click)="isAddEvent='add';getScreeningTaxo();toggle();"><i class="fa fa-plus"
            aria-hidden="true"></i> Add Event</a>
        
      </div> -->
      <div class="btn-group" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="outline-btn dropdown-toggle"
          aria-controls="dropdown-basic">
          {{translateJson.ADD_EVENT | translate}} <span class="caret"></span>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngIf="!modalOpen">
            <a class="dropdown-item"
              (click)="setOnsiteEvent(confirmTemplate); modalOpen = true;">{{translateJson.ONSITE_EVENT | translate}}</a>
          </li>
          <li role="menuitem" *ngIf="!modalOpen"><a class="dropdown-item"
              (click)=" setVirtualEvent(confirmTemplate); modalOpen = true;">{{translateJson.VIRTUAL_EVENT | translate}}</a></li>


          <li role="menuitem"  *ngIf="modalOpen">
            <a class="dropdown-item"
              (click)="openConfrimBox(confirmTemplate,translateJson.ONSITE_EVENT);">{{translateJson.ONSITE_EVENT | translate}}</a>
          </li>
          <li role="menuitem"  *ngIf="modalOpen"><a class="dropdown-item"
              (click)=" openConfrimBox(confirmTemplate,translateJson.VIRTUAL_EVENT);">{{translateJson.VIRTUAL_EVENT | translate}}</a></li>


        </ul>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAddEvent=='add'; then addEventContent"></div>
<div *ngIf="isAddEvent=='edit'&& isEditModeEnable; then addEventContent"></div>

<div class="show_search_filter_data" *ngIf="userData?.role == 0 && exhibitorSearch == 1">
  <div class="container">
    <!-- <div class="s_r">Search Result : <strong>
        <span *ngIf="adminActiveTab == 1">{{adminApprovedCountSearch}}</span>
        <span *ngIf="adminActiveTab == 0">{{adminPendingCountSearch}}</span>
        <span *ngIf="adminActiveTab == 2">{{adminDeclinedCountSearch}}</span>
        <span *ngIf="adminActiveTab == 3">{{adminApprovedPastCountSearch}}</span>
      </strong></div> -->
    <div class="s_r_t">

      <ul>
        <li *ngIf="eventSearchForm.value.eventDate "><span>{{eventSearchForm.value.eventDate[0] | date}} -
            {{eventSearchForm.value.eventDate[1] | date}}</span>

          <a href="javascript:void(0)" (click)="removeEventDate()"></a>
        </li>
        <li *ngIf="eventSearchForm.value.eventName "><span>{{eventSearchForm.value.eventName}}</span>
          <a href="javascript:void(0)" (click)="removeEventName()"></a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Event Listing start here-->
<div class="admin-event-section">
  <div *ngIf="!eventsAttributes.pageItems || !eventsAttributes.pageItems.length" class="search-not-found">
      {{translateJson.NO_RECORD_FOUND | translate}}
    </div>
  <div class="container" *ngIf="eventsAttributes.pageItems.length > 0">
    <div class="admin-event-row" *ngFor="let localData of eventsAttributesPageItems(); let idx = index;">
      <div class="admin-event-row-top" *ngIf="editIndex !==idx || isAddEvent==''">
        <div class="event-box clearfix">
          <div class="hfp-col-8 no-pd-l-r">
            <div class="event-list-left">
              <div class="virtual-onsite-tag">
                 <span *ngIf="localData.eventOptionType == userSystemConstants.VIRTUAL"><i><img src="/assets/images/svg/virtual-tag-icon.svg" alt="virtual" /></i>Virtual</span>
                 <span *ngIf="localData.eventOptionType  == userSystemConstants.ONSITE"><i><img src="/assets/images/svg/onsite-tag-icon.svg" alt="onsite" /></i>On-Site</span>
              </div>
              <div class="event-list-img" *ngIf="localData.media && localData.media.length">
                <img width="100%"
                  [src]="(imageCdn + 'w_750,h_220/' + ((imageBaseUrl + '/event/' + 'defaultEventImages/' + localData.media[0]) | encodeUriComponent))"
                  alt="" />
                <!-- <img width="100%"
                  [src]="(imageBaseUrl + '/event/' + 'defaultEventImages/' + localData.media[0])"
                  alt="" /> -->
              </div>
              <!-- <div *ngIf="localData.media && !localData.media.length" class="event-list-img"> <img
                  src="/assets/images/event/no-img-event-list.jpg" alt="" /> </div> -->
              <div class="event-list-txt">
                <div class="event-list-txt-top clearfix" [ngClass]="{'noactiondropdown': (hasEventExpired(localData.eventDate) == false
                && !localData.eventReqExist && userData?.role == 20
                && localData.openCloseStatus === 1) || localData.eventReqStatus == 2}">
                  <h2 class="eventhd">
                    <i *ngIf="localData.corpData">
                      {{localData.corpData[0].data?.company_name}} -
                    </i>
                    {{localData.eventName}} <span>{{localData.company}}</span>
                  </h2>

                  <!--<span *ngIf="localData.corpData">{{localData.corpData[0].data.company_name}}</span>-->

                  <div *ngIf="userData?.role == 0 || userData?.role == 10">
                    <div *ngIf="localData.status == 0" class="event-list-stus"><i><img width="20px"
                          src="/assets/images/sm-peding-icon.png" alt=""></i>Pending</div>
                    <div *ngIf="localData.status == 1" class="event-list-stus"><i><img width="20px"
                          src="/assets/images/sm-paid-icon.png" alt=""></i> Approved</div>
                    <div *ngIf="localData.status == 3" class="event-list-stus"><i><img width="20px"
                          src="/assets/images/sm-draft-icon.png" alt=""></i> Draft</div>
                    <div *ngIf="localData.status == 2" class="event-list-stus"><i><img width="20px"
                          src="/assets/images/declined-icon.png" alt=""></i> Declined</div>
                  </div>
                  <div *ngIf="userData?.role == 20 && localData.eventReqExist">
                    <div *ngIf="localData.eventReqStatus == 0" class="event-list-stus"><i><img width="20px"
                          src="/assets/images/sm-peding-icon.png" alt=""></i>Pending</div>
                    <div *ngIf="localData.eventReqStatus == 1" class="event-list-stus"><i><img width="20px"
                          src="/assets/images/sm-paid-icon.png" alt=""></i> Approved</div>
                    <!-- <div *ngIf="localData.eventReqStatus == 2" class="event-list-stus"><i><img width="20px"
                          src="/assets/images/declined-icon.png" alt=""></i> Declined</div> -->

                    <div *ngIf="localData.eventReqStatus == 3"
                      class="event-list-stus"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>
                      Canceled</div>
                    <div *ngIf="localData.eventReqStatus == 2 && localData.eventReqData.approveStatus == 0"
                      class="event-list-stus"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>
                      Declined</div>
                  </div>
                  <div class="event-right-dropdown" [ngClass]="{'hiddenDiv': (hasEventExpired(localData.eventDate) == false
                  && !localData.eventReqExist && userData?.role == 20
                  && localData.openCloseStatus === 1) || localData.eventReqStatus == 2}">
                    <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                        aria-controls="dropdown-menu" (click)="false">Action </a>
                      <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">

                        <li class="event-edit-icon" *ngIf="userData.role == 0  || userData.role == 10">
                          <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal" (click)="isEdit=!isEdit;getEventDetail(idx);editIndex =idx;
                            getScreeningTaxo();isAddEvent='edit';modalOpen = true;">
                            Edit</a>
                        </li>
                        <li class="event-approve-icon"
                          *ngIf="((localData.isApproveButtonVisible && localData.status == commonStatus.PENDING  ) || ( localData.isApproveButtonVisible
                            && localData.status == commonStatus.DECLINE )|| localData.status == commonStatus.ACTIVE) && userData?.role == userRoleStatus.SUPER_ADMIN && (adminActiveTab == commonStatus.PENDING || adminActiveTab == commonStatus.DECLINE)">
                          <a href="javascript:void(0)"
                            (click)="selectedEventId=localData.id;openEventApprovalPopUp(eventApprovalPopup,localData.id,true)">Approve</a>
                        </li>
                        <li class="event-updatepayment-icon"
                          *ngIf="(localData.status == 0 || localData.status == 2 || localData.status == 1) && userData?.role == 0 && adminActiveTab == 1">
                          <a href="javascript:void(0)" *ngIf="localData.disable"
                            (click)="selectedEventId=localData.id;openEventApprovalPopUp(eventApprovalPopup,localData.id,true)">Update
                            Payment Type</a>
                        </li>
                        <li *ngIf="userData?.role == 0 && (localData.status == 0 || localData.status == 1)"
                          class="event-declined-icon"><a href="javascript:void(0)"
                            (click)="approveEvent(localData.id,2)">Decline</a></li>
                          <li *ngIf="userData?.role == 0 && (localData.status == 0 || localData.status == 1)"
                           class="event-declined-icon"><a href="javascript:void(0)"
                          (click)="approveEvent(localData.id,4)">Delete</a></li>
                        <li
                          *ngIf="(localData.status == 1 || localData.status == 2) && (userData?.role == 0 || userData.role == 10)"
                          class="event-clone-icon"><a href="javascript:void(0)"
                            (click)="eventClone(idx);openAndCloseEvent(localData.id,1)">
                            Clone
                          </a>
                        </li>
                        <li *ngIf="userData?.role == 10 && localData.status == 1" class="event-rejected-icon"><a
                            href="javascript:void(0)"
                            (click)="getExhibitorList(localData._id);isAddEvent='';editIndex=-1;">Download List

                          </a></li>
                        <li *ngIf="userData?.role == 10 && localData.status == 1 && localData.openCloseStatus"
                          class="send-invite-icon"><a href="javascript:void(0)" data-toggle="modal"
                            *ngIf="localData.disable" (click)="inviteOpen='open';showInviteForm(idx);inviteIndex =idx; onItemChange('no')"
                            data-target="#sendinvite-pop">Send Invite</a></li>
                        <li
                          *ngIf="localData.status == 1 && localData.status !==3 && localData.openCloseStatus == 1 && (userData?.role == 0 || userData?.role == 10)"
                          class="re-market-icon"><a href="javascript:void(0)" *ngIf="localData.disable"
                            (click)="openAndCloseEvent(localData.id,0)">Close Event</a></li>
                        <li
                          *ngIf="localData.status == 1 && localData.openCloseStatus == 0 && (userData?.role == 0 || userData?.role == 10)"
                          class="event-approve-icon"><a href="javascript:void(0)"
                            (click)="openAndCloseEvent(localData.id,1)">Open Event </a></li>
                        <li *ngIf="localData.status == 3 && (userData?.role == 0 || userData?.role == 10)"
                          class="event-clone-icon"><a href="javascript:void(0)"
                            (click)="userData?.role == 10 ?approveEvent(localData.id,0):approveEvent(localData.id,1)">Publish</a>
                        </li>
                        <li
                          *ngIf="userData?.role == userRoleStatus.COMPANY_ADMIN && localData.status == commonStatus.ACTIVE && hasEventExpired(localData.eventDate) == true"
                          class="event-review-icon"><a (click)="viewCorporateReviewEvent(localData.permalink)"
                            href="javascript:void(0)">Review Event</a></li>

                        <li class="event-edit-icon" *ngIf="userData?.role == userRoleStatus.EXHIBITOR"  >    
                           <div *ngIf="userData?.role == userRoleStatus.EXHIBITOR then editRequestForm"></div>
                        </li>
                        <li *ngIf="userData?.role == userRoleStatus.EXHIBITOR && !(localData.eventReqStatus == 3)"
                          (click)="openEventApprovalPopUp(eventCancelPopup,localData,idx)" class="event-declined-icon">
                          <a href="javascript:void(0)">Cancel Request</a></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="event-date-box-list clearfix">
                  <span>{{localData.eventDate | date: 'MMM d' }}, {{localData.eventDate | date: 'yyyy' }}</span>
                  <span>{{localData.startDateTime | date:'shortTime'}} &nbsp; - &nbsp;
                    {{localData.endDateTime | date:'shortTime'}}</span>
                    <span>{{localData && localData.timeZone && localData.timeZone.label}}</span>
                  <div class="event-close-box" *ngIf="localData.openCloseStatus === 0">
                    <img src="/assets/images/event-close-pin.png" alt="">Closed
                  </div>
                </div>

                <div class="event-list-txt-btm clearfix">
                  <p [innerHTML]="localData.eventDescription | truncate:[300, '...']"></p>
                  <div class="event-btm-link">
                    <a class="event-view-detail-btn" (click)="viewDetail(localData.permalink)"
                      href="javascript:void(0)"><i></i>View Details</a>
                    <a *ngIf="localData.status == 1 && (userData?.role == 10 ||  userData?.role == 0)" (click)="viewExibitor({permalink:localData.permalink, eventId:localData._id})"
                      class="request-exhibitors-btn" href="javascript:void(0)"><i></i> View Exhibitors</a>

                    <a class="pay-now-btn"
                      *ngIf="localData.payButtonShow == 1 && localData.paymentData && userData?.role == 20"
                      id="view-ex-detail" href="javascript:void(0)"
                      (click)="setEventCheckout(localData.paymentData,localData)"><i></i>Pay For Event</a>
                      
                      <a  *ngIf="hasEventExpired(localData.eventDate) === false
                      && !localData.eventReqExist && userData?.role == userRoleStatus.EXHIBITOR
                      && localData.openCloseStatus === commonStatus.ACTIVE then editRequestForm">
                  </a>

                  </div>
                  <ng-template #editRequestForm>
                    <app-request-invitation 
                    [isEditRequestFormEnabled]="false"
                      [selectedEventTemplateDetails]="localData" 
                      [selectedExhibitorUser]="userData"
                      (triggerExhibitorEventList)="getEventListForExibitor(exhbitorEventActiveTab,eventsAttributes.limits,eventsAttributes.skips)"
                      >
                    </app-request-invitation>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="hfp-col-4 no-pd-l-r">
            <div class="event-right-map">
              <div class="event-tag-icon" *ngIf="localData && localData.eventType">
                <img *ngIf="localData.eventType == 'free' && loginUserRole != 10" width="100%"
                  src="/assets/images/event-tag-icon-2.png" alt="" />
                <img *ngIf="localData.eventType == 'hfp' && loginUserRole != 10" width="100%"
                  src="/assets/images/event-tag-icon-1.png" alt="" />
                <img *ngIf="localData.eventType == 'paid' && loginUserRole != 10" width="100%"
                  src="/assets/images/event-tag-icon-4.png" alt="" />
              </div>

              <div class="event-map"
                *ngIf="localData.eventAddress?.lat != '' && localData.eventAddress?.lng !='';else templateName">
                <img width="100%" src="https://maps.googleapis.com/maps/api/staticmap?center={{localData.eventAddress.location}}&zoom=20&size=600x355&maptype=roadmap
              &markers=color:red%7Clabel:G%7C{{localData.eventAddress?.lat}},
              {{localData.eventAddress?.lng}}&key=AIzaSyDtnx3pwtsdjwMYdA8WKao5On80x946POM" alt="" />
              </div>
              <!--    <ng-template #templateName>
                <div class="event-map"
                  *ngIf="localData.eventAddress.lat != '' && localData.eventAddress.lng !='';else templateName">
                  <img width="100%" src="https://maps.googleapis.com/maps/api/staticmap?center={{localData.eventAddress.location}}
                                &zoom=13&size=600x350&maptype=roadmap
                              &markers=color:green%7Clabel:G%7C&key=AIzaSyDtnx3pwtsdjwMYdA8WKao5On80x946POM" alt="" />
                </div>
              </ng-template>     -->
              <ng-template #templateName>
                <div class="event-map">
                  <img width="100%" src="/assets/images/event-map-img.jpg" alt="" />
                </div>
              </ng-template>
              <!-- -->

              <div class="event-address">
                <i><img width="100%" height="20px" src="/assets/images/event-map-icon.png" alt="" /></i>
                <span>{{localData.eventAddress?.location}}</span>
              </div>
              <div *ngIf=" (userData?.role == 0 || userData?.role == 10); ">
                <div class="event-stus-ul cursor_css">
                  <ul>
                    <li (click)="viewExibitor({permalink:localData.permalink, eventId:localData._id},1)"><strong>{{localData.eventRequestDataShow && localData.eventRequestDataShow[1] &&
                        localData.eventRequestDataShow[1].count ? localData.eventRequestDataShow[1].count : 0}}</strong>
                      Confirmed</li>
                    <li (click)="viewExibitor({permalink:localData.permalink, eventId:localData._id},0)"><strong>{{localData.eventRequestDataShow && localData.eventRequestDataShow[0] &&
                        localData.eventRequestDataShow[0].count ? localData.eventRequestDataShow[0].count : 0}}</strong>
                      Waiting</li>
                    <li (click)="viewExibitor({permalink:localData.permalink, eventId:localData._id},2)"><strong>{{localData.eventRequestDataShow && localData.eventRequestDataShow[2] &&
                        localData.eventRequestDataShow[2].count ? localData.eventRequestDataShow[2].count : 0}}</strong>
                        {{'DECLINED' | translate}}</li>
                  </ul>
                </div>
                <div class="totalrequests" *ngIf="localData.eventRequestDataShow">
                  {{localData.eventRequestDataShow[1].count + localData.eventRequestDataShow[0].count +
                  localData.eventRequestDataShow[2].count}} Total Requests</div>
              </div>
              <!-- -->
            </div>
          </div>




        </div>
        <div *ngIf="inviteIndex ==idx">
          <div *ngIf="inviteOpen=='open'; then sendinvite"></div>
        </div>

      </div>


      <div class="edit__event__section" *ngIf="editIndex ==idx">
        <div *ngIf="editIndex ==idx &&  (userData?.role == 0 || userData?.role == 10); then addEventContent"></div>
      </div>
    </div>

    <!-- {{eventsAttributes | json}} -->





  </div>
  <div class="pagination__box" *ngIf="eventsAttributes.count > eventsAttributes.limits" >
    <pagination [totalItems]="eventsAttributes.count"[rotate]="eventsAttributes.rotate"
      [maxSize]="eventsAttributes.maxSize" [itemsPerPage]="eventsAttributes.limits" [(ngModel)]="currentPage"
      (pageChanged)="pageChanged($event);">
    </pagination>

  </div>
</div>


<!--Event list end here -->

<!--Add Event Template Start Here -->
<ng-template #addEventContent>
  <div class="admin-event-section" #targetTop>
    <div class="event-form-outer">
      <div class="container">
        <div class="clearfix event-form-in">
          <div class="form-close"><button (click)="isAddEvent='';modalOpen = false ;editIndex=-1;openURL();fAttributes.submitted = false;" id="paymtclose-1">
              <img src="/assets/images/add-form-close.png" alt=""></button>
          </div>

          <form [formGroup]="eventForm" (ngSubmit)="isAddEvent=='edit' ? editEventData() :addEventData()">
            <div class="clearfix" id="event-step-1"  [hidden]="submits.currentStep != 'step1'">
              <div class="lmd-sm-4 pull-right">
                <div class="events-edit-right">
                  <div class="event-page-step"
                    [ngClass]="{'corporate-eventtab-details': loginUserRole===10, 'exhibitor-eventtab-details': loginUserRole===20}">

                    <!-- TODO :- Navigate to any page in edit mode -->

                      <ul>
                        <li><a class="active" href="javascript:void(0)">1</a></li>
                        <li><a href="javascript:void(0)" (click)=" (isAddEvent === userSystemConstants.EDIT) ? next('step2') : '' ">2</a></li>
                        <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step3'): '' ">3</a></li>
                        <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step4'): '' ">4</a></li>

                        <!-- TODO:- Hiding step 4 -->

                        <!-- <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step5'): '' ">5</a></li> -->
                      </ul>
                  </div>
                  <i *ngIf="loginUserRole===0"><img src="/assets/images/new-event-icon.png" alt=""></i>
                  <i *ngIf="loginUserRole===10"><img src="/assets/images/new-c-event-icon.png" alt=""></i>
                  <i *ngIf="loginUserRole===20"><img src="/assets/images/new-event-icon.png" alt=""></i>
                  <h5>Event Details</h5>
                  <p>Please be sure to double check your event information! This information is sent to all exhibitors
                    during our initial invite. It is also included in all reminder emails sent to those exhibitors
                    approved to attend your event. If we are providing paid services for your event, cancellation fees
                    and coordination fees may apply if we are required to re-book exhibitors due to incorrect
                    information.</p>
                </div>
              </div>
              <div class="lmd-sm-8 pull-left">
                <div class="events-edit-left">



 

                  <div class="event-name-edit-box clearfix" *ngIf="isAddEvent=='edit'">
                    <!-- <div class="event-name-edit-box-img"
                      *ngIf="eventMediaArray && eventMediaArray.value && eventMediaArray.value.length">
                      <img class="imgPlaceholder" src="assets/images/event/{{eventImageName}}/{{eventMediaArray.value[0]}}"  alt="img"
                      height="100px" width="100px" />
                    </div> -->
                    <div class="event-name-edit-box-text">
                      <h3>{{eventForm.value.eventName}}
                        <!--<a href="javascript:void(0)"><i class="fa fa-pencil" aria-hidden="true"></i></a>-->
                      </h3>
                    </div>
                  </div>

                  <h3 *ngIf="isAddEvent=='add'">Create
                    <span *ngIf="addEventType == 'onsite'">Onsite</span>
                    <span *ngIf="addEventType == 'Virtual'">Virtual</span>
                    Event
                  </h3>
                  <div class="event-edit-form" >
                    <div class="box-bordr-btm event-input">
                      <div class="hfp-input-div" *ngIf="loginUserRole == userRoleStatus.SUPER_ADMIN">
                        <label>{{translateJson.SELECT_COMPANY_ADMIN | translate}}&nbsp;<span class="span_star">*</span></label>
                        <ng-select [items]="corporateUserList" bindLabel="username" bindValue="id"
                          placeholder= "{{translateJson.SELECT_COMPANY_ADMIN | translate}}" (close)="findUserByAdmin()" formControlName="memberId"
                          [searchFn]="searchFilterCorporateUserList">
                        </ng-select>
                      </div>
                      <div class="hfp-input-div" *ngIf="loginUserRole == 10">
                        <input type="text" hidden formControlName="memberId" value="userData?.id">
                      </div>
                      <div *ngIf="eventFormMemberId.invalid && fAttributes.submitted"
                      class="custom_error">
                      {{
                      getValidationErrors({
                      formControl: eventFormMemberId, formControlName:translateJson.SELECT_COMPANY_ADMIN
                      })
                      }}
                    </div>
                    </div>


                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.EVENT_NAME | translate}}&nbsp;<span class="span_star">*</span></label>
                        <input type="text" placeholder={{translateJson.EVENT_NAME}} value="" formControlName="eventName"
                          (input)="onInputEventName($event.target.value)">
                      </div>
                      <div *ngIf="eventFormName.invalid && fAttributes.submitted"
                      class="custom_error">
                      {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translateJson.EVENT_NAME | translate}
                        }}
                      <!-- {{
                      getValidationErrors({
                      formControl: eventFormName, formControlName:translateJson.EVENT_NAME,
                      minLength: 2, maxLength: 100,
                      isTextPattern: true
                      })
                      }} -->
                    </div>
                    </div>


                    <div class="event-input row">
                      <div class="hfp-col-6">
                        <div class="hfp-input-div">
                          <label>Event Date&nbsp;<span class="span_star">*</span></label>
                          <input type="text" *ngIf="loginUserRole === userRoleStatus.COMPANY_ADMIN || loginUserRole === userRoleStatus.SUPER_ADMIN" bsDatepicker placeholder="mm-dd-yyyy"
                            [minDate]="minStartDate" formControlName="eventDate" value="{{date3}}">




                            
                        </div>
                        <div *ngIf="eventForm.controls['eventDate'].invalid &&  fAttributes.submitted" class="custom_error">
                          <div *ngIf="eventForm.controls['eventDate'].errors.required">!! Event Date is required !!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="event-input row">
                      <div class="hfp-col-6">
                        <div class="hfp-input-div">
                          <label>Event Start Time&nbsp;<span class="span_star">*</span></label>
                          <timepicker formControlName="startDateTime" required></timepicker>
                        </div>
                        <!-- <pre *ngIf="this.eventForm.get('endDateTime').value.getTime() < this.eventForm.get('startDateTime').value.getTime()"
                          class="alert alert-danger">Invalid time format</pre> -->
                        <div *ngIf="eventForm.controls['startDateTime'].invalid && fAttributes.submitted" class="custom_error">
                          <div *ngIf="eventForm.controls['startDateTime'].errors.required">!! Event start time is
                            required !! </div>
                        </div>
                      </div>


                      <div class="hfp-col-6">
                        <div class="hfp-input-div">
                          <label>Event End Time&nbsp;<span class="span_star">*</span></label>
                          <timepicker formControlName="endDateTime" (ngModelChange)="changed()" required></timepicker>
                        </div>
                        <pre
                          *ngIf="this.eventForm.get('endDateTime').value < this.eventForm.get('startDateTime').value && fAttributes.submitted"
                          class="alert alert-danger">Invalid time format</pre>
                        <div
                          *ngIf="eventForm.controls['endDateTime'].invalid &&  fAttributes.submitted"
                          class="custom_error">

                          <div *ngIf="eventForm.controls['endDateTime'].errors.required">!! Event end time is required
                            !! </div>
                        </div>
                      </div>
                    </div>
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.TIME_ZONE | translate}}&nbsp;<span class="span_star">*</span></label>
                        <ng-select
                          [items]="timezones"
                          bindLabel="label"
                          [compareWith]="compareTimezones"
                          formControlName="timeZone"
                          (change)="onTimezoneChange($event)"
                          placeholder="{{translateJson.SELECT_TIME_ZONE | translate}}"
                        >
                        </ng-select>
                      </div>
                            <div *ngIf="eventForm.controls['timeZone'].invalid && (eventForm.controls['timeZone'].dirty
                      || fAttributes.submitted)" class="custom_error">
                        <div *ngIf="eventForm.controls['timeZone'].errors.required">!! Time zone is required !! </div>
                      </div>
                    </div>
                   


                    <div class="event-input row">
                      <div class="hfp-col-12 sign-up-fields upload-img event-edit-form">
                        <label class="field-label">Event Image&nbsp;<span class="span_star">*</span></label>
                        <div class="switch-field Status-tab">
                        </div>

                        <div class="select-avatar-icon avatar_scroll">
                          <ul class="clearfix">
                            <li *ngFor="let eventImage of eventImages;"><a
                                [ngClass]="{'active':eventImage===selectedEventImage}" href="javascript:void(0)"><img
                                  src="assets/images/event/{{eventImageName}}/{{eventImage}}"
                                  (click)="addMediaValue(eventImage)" alt="" /></a> </li>
                          </ul>
                        </div>

                        <div *ngIf="eventMediaArray.invalid &&  fAttributes.submitted"
                          class="custom_error">
                          <div *ngIf="eventMediaArray.errors.required">
                            !! Event image is required
                            !! </div>
                        </div>
                      </div>
                    </div>

                    <!--hideing reffle items  -->
                    <!-- <div class="event-input">
                      <div class="hfp-input-div">
                        <label>Request Raffle Items from Exhibitors?</label>
                        <div class="switch-field event-status-tab">
                          <div class="check-btntype" *ngFor="let item of raffelType; let idx = index;">
                            {{item.value}}
                            {{requestRaffleValue}}
                            <input (change)="onRadioBoxChange(item,$event)" type="radio" id="switch_left{{idx}}"
                              name="requestReffleItem" [value]="item.value" formControlName="requestReffleItem"
                              [checked]="item.value==requestRaffleValue" />
                            <label for="switch_left{{idx}}">{{item.name}}</label>
                          </div>

                        </div>

                      </div>
                    </div> -->

                    <div class="event-input" *ngIf="!(addEventType == 'Virtual')">
                      <div class="hfp-input-div">
                        <!-- todo changed name "Maximum number of exhibitors" to "Requested number of exhibitors" -->
                        <label>{{'REQUESTED_NUMBER_OF_EXHIBITORS' | translate}}&nbsp;<span class="span_star">*</span></label>
                        <input type="text" placeholder="Number of exhibitors" value="" formControlName="maxExhibitor">
                      </div>
                      <div
                        *ngIf="eventForm.controls['maxExhibitor'].invalid && fAttributes.submitted"
                        class="custom_error">
                        <div *ngIf="eventForm.controls['maxExhibitor'].errors.required">!! Exhibitors is required !!
                        </div>
                        <div *ngIf="eventForm.controls['maxExhibitor'].errors.pattern">Please fill number.
                        </div>
                      </div>
                    </div>


                    <!-- hiding industry -->
                    <!-- <div class="event-input">
                      <div class="hfp-input-div">

                        <label>{{'INDUSTRIES' | translate}}&nbsp;
                          <span class="span_star">*</span>
                        </label>
                        <ng-select [clearable]="true" [items]="industriesList" bindLabel="name" bindValue="id"
                          [addTag]="addCustomUser" [multiple]="true" [closeOnSelect]="false" [searchFn]="searchTaxo"
                          placeholder="Select Industry" formControlName="exhibitorReq" searchable="enable">
                        </ng-select>
                      </div>
                      <div *ngIf="eventForm.controls['exhibitorReq'].invalid && (eventForm.controls['exhibitorReq'].dirty
                                                                                            || fAttributes.submitted)"
                        class="custom_error">
                        <div *ngIf="eventForm.controls['exhibitorReq'].errors.required">
                          {{"INDUSTRY_REQUIRED" | translate}}
                        </div>
                      </div>
                    </div> -->

                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{'SERVICES' | translate}}&nbsp;
                          <!-- <span class="span_star">*</span> -->
                        </label>
                        <ng-select [closeOnSelect]="false" [items]="screeningParentList" bindLabel="formattedName" bindValue="id"
                          [multiple]="true" [searchFn]="searchTaxo" placeholder="Select Services tag"
                          formControlName="screenReq">
                        </ng-select>
                      </div>
                      <!-- <div *ngIf="eventForm.controls['screenReq'].invalid && (eventForm.controls['screenReq'].dirty
                      || fAttributes.submitted)" class="custom_error">
                        <div *ngIf="eventForm.controls['screenReq'].errors.required">!! Screening Requests is required !! </div>
                      </div> -->
                    </div>
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.INVITE_YOUR_OWN_EXHIBITOR | translate}}&nbsp;
                        </label>
                        <input type="text" placeholder="{{translateJson.INVITE_YOUR_OWN_EXHIBITOR | translate}}" value="" formControlName="otherExhibitor">
                      </div>
                     <div *ngIf="eventFormOtherExhibitor.invalid &&  fAttributes.submitted"
                      class="custom_error">
                      {{
                      getValidationErrors({
                      formControl: eventFormOtherExhibitor, formControlName:translateJson.EMAIL,
                      isEmailPattern: true
                      })
                      }}
                    </div>
                    </div>

                    <div class="event-input ">
                      <div class="event-buttons clearfix">
                        <a *ngIf="!eventDynamicUrl" href="javascript:void()" (click)="next('step1',( eventFormMemberId.valid && eventForm.controls['eventName'].valid &&
                    eventForm.controls['eventDate'].valid && eventForm.controls['startDateTime'].valid &&
                    eventForm.controls['endDateTime'].valid && eventMediaArray.valid && timeZone.valid)
                    , 'step2'); scroll(targetTop)" id="next1" class="btn btn-default next-btn">Next <i><img
                              src="/assets/images/next-icon.png" alt=""></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void()" (click)="next('step1',( eventFormMemberId.valid &&eventForm.controls['eventName'].valid &&
                              eventForm.controls['eventDate'].valid && eventForm.controls['startDateTime'].valid &&
                              eventForm.controls['endDateTime'].valid && eventMediaArray.valid && eventFormOtherExhibitor.valid && timeZone.valid), 'step2'); scroll(targetTop)" id="next1"
                          class="btn btn-default next-btn">Next <i><img src="/assets/images/next-icon.png"
                              alt=""></i></a>
                             
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>


            <div class="clearfix" [hidden]="submits.currentStep != 'step2'" id="event-step-2">

              <div class="lmd-sm-4 pull-right">
                <div class="events-edit-right">
                  <div class="event-page-step" [ngClass]="{'corporate-eventtab-details': loginUserRole===10,
                    'exhibitor-eventtab-details': loginUserRole===20}">

                    <!-- TODO :- Navigate to any page in edit mode -->


                        <ul>
                        <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step1'): previous('step1')" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">1</a></li>
                        <li><a href="javascript:void(0)" class="active" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step2'): ''">2</a></li>
                        <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step3') : ''">3</a></li>
                        <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step4'): ''">4</a></li>
                        <!-- <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step5'): ''">5</a></li> -->
                      </ul> 
                  </div>
                  <i *ngIf="loginUserRole===0"><img src="/assets/images/new-event-step-2.png" alt=""></i>
                  <i *ngIf="loginUserRole===10"><img src="/assets/images/event-step-2.png" alt=""></i>
                  <i *ngIf="loginUserRole===20"><img src="/assets/images/new-event-step-2.png" alt=""></i>

                  <!-- TODO:- Renameing Hostcompany to Event Details -->

                  <h5 id="ggg">{{'EVENT_DETAILS' | translate}}</h5>
                  <p>Please be sure to double check your event information! This information is sent to all exhibitors
                    during our initial invite. It is also included in all reminder emails sent to those exhibitors
                    approved to attend your event. If we are providing paid services for your event, cancellation fees
                    and coordination fees may apply if we are required to re-book exhibitors due to incorrect
                    information.</p>

                </div>

              </div>
              <div class="lmd-sm-8 pull-left">
                <div class="events-edit-left">

                  <h3>

                  <!-- TODO:- Renameing Hostcompany to Event Details -->

                  {{'EVENT_DETAILS' | translate}}
                  </h3>


                  <div class="event-edit-form">
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.COMPANY_NAME | translate}}&nbsp;<span class="span_star">*</span></label>
                        <input type="text" name="company" placeholder="{{translateJson.COMPANY_NAME | translate}}"
                          formControlName="company">
                      </div>
                  
                      <div
                        *ngIf="eventFormCompany.invalid && fAttributes.submitted"
                        class="custom_error">
                        {{
                          'REQUIRED_MSG_WITH_PARAMS' |
                          translate: {'name': translateJson.COMPANY_NAME | translate}
                          }}
                        <!-- {{
                        getValidationErrors({
                        formControl: eventFormCompany, formControlName:translateJson.COMPANY_NAME, -->
                        <!-- minLength: 2, maxLength: 60,
                        isTextPattern: true -->
                        <!-- })
                        }} -->
                      </div>
                    </div>


                    <div class="event-input" *ngIf="!(addEventType == 'Virtual')">
                      <div class="hfp-input-div">
                        <label>Onsite Employees&nbsp;<span class="span_star">*</span></label>
                        <input type="number" placeholder="Onsite Employees" formControlName="onsiteEmp" required>
                      </div>

                      <div *ngIf="eventForm.controls['onsiteEmp'].invalid && fAttributes.submitted" class="custom_error">
                        <div *ngIf="eventForm.controls['onsiteEmp'].errors.required">!! Onsite Employees is required !!
                        </div>
                        <div *ngIf="eventForm.controls['onsiteEmp'].errors.pattern">{{'ONLY_NUMBER' | translate}}

                        </div>
                      </div>

                    </div>

                    <!-- TODO:- Hiding Attendance Fee -->

                    <!-- <div class="event-input">
                      <div class="hfp-input-div">
                        <label>Attendance Fee</label>
                        <ng-select [items]="attendanceList" bindLabel="name" bindValue="id"
                          placeholder="Select Attendance Fee" formControlName="attendanceFee">
                        </ng-select>
                      </div> -->
                      <!-- <div *ngIf="eventForm.controls['screenReq'].invalid && (eventForm.controls['screenReq'].dirty
                      || fAttributes.submitted)" class="custom_error">
                        <div *ngIf="eventForm.controls['screenReq'].errors.required">!! Screening Requests is required !! </div>
                      </div> -->
                    <!-- </div> -->
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.HOSTED_BY | translate}}
                          &nbsp;
                          <!--<span class="span_star">*</span>  -->
                        </label>
                        <input type="text" placeholder='{{translateJson.HOSTED_BY | translate}}' formControlName="hostedBy">
                      </div>
                      <!-- <div
                        *ngIf="eventFormHostedBy.invalid && fAttributes.submitted"
                        class="custom_error">
                        {{
                        getValidationErrors({
                        formControl: eventFormHostedBy, formControlName:translateJson.HOSTED_BY,
                        minLength: 2, maxLength: 100,
                        isTextPattern: true
                        })
                        }}
                      </div> -->
                    </div>


                    <div class="event-input">
                      <div class="hfp-input-div" formGroupName="eventAddress">
                        <label class="field-label" *ngIf="(addEventType == userSystemConstants.ONSITE)">
                          Event Address&nbsp;<span class="span_star">*</span></label>
                        <label class="field-label" *ngIf="(addEventType == userSystemConstants.VIRTUAL)">
                          Virtual Event&nbsp;<span class="span_star">*</span></label>
                        <input id="autocomplete" type="text" (input)="onAddreessChange($event)"
                          placeholder="{{'ADDRESS1' | translate}}" formControlName="location" name="location" myNoSpaces
                          #search (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off"
                          spellcheck="off" />

                          <div
                          *ngIf="eventFormLocation.invalid && (eventFormLocation.dirty ||eventFormLocation.touched || fAttributes.submitted)"
                          class="custom_error">
                          <!-- {{
                            'REQUIRED_MSG_WITH_PARAMS' |
                            translate: {'name': translateJson.EVENT_ADDRESS | translate}
                            }} -->
                          {{
                          getValidationErrors({
                          formControl: eventFormLocation, formControlName:translateJson.EVENT_ADDRESS,
                          minLength: 2, maxLength: 100,
                          isTextPattern: true
                          })
                          }}
                        </div>

                      </div>

                    </div>
                    <div class="event-input" *ngIf="(addEventType == userSystemConstants.ONSITE)">
                      <div class="hfp-input-div">
                        <label class="field-label">
                          Address2</label>
                        <input id="autocomplete" type="text"
                          placeholder="Address 2" formControlName="address2" name="address2" myNoSpaces
                          autocorrect="off" autocapitalize="off"
                          spellcheck="off" />
                        </div>
                    </div>
                    <!-- <div class="event-input">
                      <div class="hfp-input-div" formGroupName="eventAddress">
                        <label>Suite, building or facility number</label>
                        <input name="address1" formControlName="additionLocation" class="input-field" type="text">
                      </div> -->
                      
                      <!-- <div *ngIf="eventForm.get('eventAddress').controls['additionLocation'].invalid
                                                      && (eventForm.get('eventAddress').controls['additionLocation'].dirty
                                                      || fAttributes.submitted)" class="custom_error">
                        <div *ngIf="eventForm.get('eventAddress').controls['additionLocation'].errors.required">
                          !! Event address1 is required !! </div>


                      </div> -->

                    <!-- </div> -->

                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.WEBSITE | translate}}&nbsp;<span class="span_star">*</span></label>
                        <input type="text" placeholder="Website" formControlName="website">
                      </div>
                      <div *ngIf=" eventFormWebsite.invalid &&  fAttributes.submitted"
                       class="custom_error">
                       {{
                        'REQUIRED_MSG_WITH_PARAMS' |
                        translate: {'name': translateJson.WEBSITE | translate}
                        }}
                      <!-- {{
                      getValidationErrors({
                      formControl:  eventFormWebsite, formControlName:translateJson.WEBSITE,
                      isUrlPattern: true
                      })
                    }} -->
                  </div>
                    </div>
                    <div class="event-input ">
                      <div class="event-buttons clearfix">
                        <a *ngIf="!eventDynamicUrl" href="javascript:void()" (click)="previous('step1'); scroll(targetTop)" id="pre2"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt="">{{translateJson.PREVIOUS | translate}}
                          <i></i></a>
                        <a *ngIf="!eventDynamicUrl" href="javascript:void()" (click)="next('step2',isValidEvent(),'step3'); scroll(targetTop)" id="next3" class="btn btn-default next-btn">{{translateJson.NEXT | translate}} <i><img
                              src="/assets/images/next-icon.png" alt=""></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void()" (click)="previous('step1'); scroll(targetTop)" id="pre2"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt="">{{translateJson.PREVIOUS | translate}}
                          <i></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void()" (click)="next('step2',isValidEvent(),'step3'); scroll(targetTop)" id="next3" class="btn btn-default next-btn">{{translateJson.NEXT | translate}}
                          <i><img src="/assets/images/next-icon.png" alt=""></i></a>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>


            <div class="clearfix" [hidden]="submits.currentStep != 'step3'" id="event-step-3">
              <div class="lmd-sm-4 pull-right">
                <div class="events-edit-right">
                  <div class="event-page-step"
                    [ngClass]="{'corporate-eventtab-details': loginUserRole===10, 'exhibitor-eventtab-details': loginUserRole===20}">

                    <!-- TODO :- Navigate to any page in edit mode -->

                    <ul>
                      <li><a  href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step1') : previous('step1')" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">1</a></li>
                      <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step2') : previous('step2')" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">2</a></li>
                      <li><a href="javascript:void(0)" class="active" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step4') : ''">3</a></li>
                      <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step4') : ''" >4</a></li>
                      <!-- <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step5') : ''" >5</a></li> -->
                  </ul>
                    
                  </div>
                  <i *ngIf="loginUserRole===0"><img src="/assets/images/new-event-step-4.png" alt=""></i>
                  <i *ngIf="loginUserRole===10"><img src="/assets/images/event-step-4.png" alt=""></i>
                  <i *ngIf="loginUserRole===20"><img src="/assets/images/new-event-step-4.png" alt=""></i>
                  <h5>Event Description</h5>
                  <p>Please be sure to double check your event information! This information is sent to all exhibitors
                    during our initial invite. It is also included in all reminder emails sent to those exhibitors
                    approved to attend your event. If we are providing paid services for your event, cancellation fees
                    and coordination fees may apply if we are required to re-book exhibitors due to incorrect
                    information.</p>
                  <h5>Parking</h5>
                  <p>Please be sure to mention any parking fees that may be required. If there is a preferred parking
                    area, please specify that here. </p>
                </div>

              </div>
              <div class="lmd-sm-8 pull-left">
                <div class="events-edit-left">

                  <h3>Event Description</h3>


                  <div class="event-edit-form">

                    <div class="event-input" *ngIf="addEventType == userSystemConstants.ONSITE">
                      <div class="hfp-input-div">
                        <label>Parking Instructions&nbsp;<span class="span_star">*</span></label>
                        <textarea name="parkingInstructions" formControlName="parkingInstructions" required ></textarea>
                      </div>
                      <div
                        *ngIf="eventForm.controls['parkingInstructions'].invalid && fAttributes.submitted"
                        class="custom_error">
                        <div *ngIf="eventForm.controls['parkingInstructions'].errors.required">!! Parking Instructions
                          is required
                          !! </div>
                        <!-- <div *ngIf="eventForm.controls['parkingInstructions'].errors.minlength">Please fill at least 2
                          characters.
                        </div>
                        <div *ngIf="eventForm.controls['parkingInstructions'].errors.maxlength">Parking Instructions is
                          too
                          Large.
                        </div> -->
                      </div>
                    </div>


                    <div class="event-input" *ngIf="addEventType == userSystemConstants.ONSITE">
                      <div class="hfp-input-div">
                        <label>Check in/Security Instructions(PLEASE BRING YOUR ID)&nbsp;<span
                            class="span_star">*</span></label>
                        <textarea name="checkIn" formControlName="checkIn" required ></textarea>
                      </div>
                      <div
                        *ngIf="eventForm.controls['checkIn'].invalid && fAttributes.submitted"
                        class="custom_error">
                        <div *ngIf="eventForm.controls['checkIn'].errors.required">!! Check In / Security Instructions
                          is required
                          !! </div>
                        <!-- <div *ngIf="eventForm.controls['checkIn'].errors.minlength">Please fill at least 2
                          characters.
                        </div>
                        <div *ngIf="eventForm.controls['checkIn'].errors.maxlength">Check In / Security Instructions is
                          too
                          Large.
                        </div> -->
                      </div>
                    </div>
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.ONSITE_CONTACT | translate}}&nbsp;<span class="span_star">*</span></label>
                        <input type="text" placeholder="{{translateJson.ONSITE_CONTACT | translate}}" name="onsiteContact"
                          formControlName="onsiteContact" required>
                      </div>
                      <div
                        *ngIf="eventFormOnsiteContact.invalid && fAttributes.submitted"
                        class="custom_error">
                        {{
                          'REQUIRED_MSG_WITH_PARAMS' |
                          translate: {'name': translateJson.ONSITE_CONTACT | translate}
                          }}
                        <!-- {{
                        getValidationErrors({
                        formControl: eventFormOnsiteContact, formControlName:translateJson.ONSITE_CONTACT,
                        minLength: 2, maxLength: 100,
                        isTextPattern: true
                        })
                        }} -->
                      </div>
                    </div>
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>Day of Event Cell Phone Number&nbsp;<span class="span_star">*</span></label>
                        <app-phone-number [phoneNumberFormControlName]="'phone'"
                        [phoneNumberFormGroup]="eventFormPhone.parent"></app-phone-number>
                      </div>
                      <div
                        *ngIf="eventFormPhone.invalid &&  fAttributes.submitted"
                        class="custom_error">
                        {{
                        getValidationErrors({
                        formControl: eventFormPhone, formControlName:translateJson.PHONE_NUMBER
                        })
                        }}
                      </div>
                    </div>
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>{{translateJson.ADDITIONAL_EVENT_DETAILS | translate}}
                        </label>
                        <div [froalaEditor]='globals.floraOptions' formControlName="eventDescription"></div>
                      </div>

                      <div
                        *ngIf="eventFormEventDescription.invalid &&  fAttributes.submitted"
                        class="custom_error">
                        {{
                        getValidationErrors({
                        formControl: eventFormEventDescription, formControlName:translateJson.ADDITIONAL_EVENT_DETAILS
                        })
                        }}
                      </div>
                    </div>


                    <div class="event-input ">

                      <div class="event-buttons clearfix">

                        <a *ngIf="!eventDynamicUrl" href="javascript:void()" id="pre3" (click)="previous('step2'); scroll(targetTop)"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> {{translateJson.PREVIOUS | translate}}
                          <i></i></a>
                        <a *ngIf="!eventDynamicUrl" href="javascript:void()" (click)="next('step3',eventFormParkingInstructions.valid
                          && eventFormCheckIn.valid && eventFormOnsiteContact.valid && eventFormPhone.valid && eventFormEventDescription.valid
                          ,'step4'); scroll(targetTop)" id="next3" class="btn btn-default next-btn">{{translateJson.NEXT | translate}}
                          <i><img src="/assets/images/next-icon.png" alt=""></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void()" id="pre3" (click)="previous('step2'); scroll(targetTop)"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> {{translateJson.PREVIOUS | translate}}
                          <i></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void()" (click)="next('step3',eventFormParkingInstructions.valid
                              &&  eventFormCheckIn.valid && eventFormOnsiteContact.valid && eventFormPhone.valid && eventFormEventDescription.valid
                              ,'step4'); scroll(targetTop)" id="next3" class="btn btn-default next-btn">{{translateJson.NEXT | translate}}
                          <i><img src="/assets/images/next-icon.png" alt=""></i></a>
                      </div>
                    </div>





                  </div>

                </div>
              </div>

            </div>

            <!-- <div class="clearfix" #target3 [hidden]="submits.currentStep != 'step4'" id="event-step-4">
              <div class="lmd-sm-4 pull-right">
                <div class="events-edit-right">
                  <div class="event-page-step"
                    [ngClass]="{'corporate-eventtab-details': loginUserRole===10, 'exhibitor-eventtab-details': loginUserRole===20}"> -->

                    <!-- TODO :- Navigate to any page in edit mode -->


                    <!-- <ul>
                      <li><a (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step1') : previous('step1')" href="javascript:void(0)" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">1</a></li>
                      <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step2') : previous('step2')" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">2</a></li>
                      <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step3') : previous('step3')" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">3</a></li>
                      <li><a href="javascript:void(0)" class="active" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step4') : ''">4</a></li>
                      <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step5') : ''">5</a></li>
                  </ul>
                  </div>
                  <i *ngIf="loginUserRole === 0"><img src="/assets/images/new-event-step-3.png" alt=""></i>
                  <i *ngIf="loginUserRole === 10"><img src="/assets/images/event-step-3.png" alt=""></i>
                  <i *ngIf="loginUserRole === 20"><img src="/assets/images/new-event-step-3.png" alt=""></i>
                  <h5>Insurance Carriers</h5>
                  <p>When applicable, Health Fairs Plus strives to provide exhibitors that are in-network with your
                    current medical, dental and/or vision carriers. We do NOT contact your benefit providers. This
                    information is shared with the exhibitors so that they know if they are an in-network provider.
                    Exhibitors will also list the insurances in which they are in-network on their profiles.</p>

                </div>

              </div>
              <div class="lmd-sm-8 pull-left">
                <div class="events-edit-left">

                  <h3>Insurance Carriers</h3>


                  <div class="event-edit-form">

                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>Medical Carriers</label>
                        <textarea name="medicalCarriers" formControlName="medicalCarriers"></textarea>
                      </div>
                    </div>


                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>Vision Carriers</label>
                        <textarea name="visionCarries" formControlName="visionCarries"></textarea>
                      </div>
                    </div>


                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>Dental Carriers</label>
                        <textarea name="dentalCarries" formControlName="dentalCarries"></textarea>
                      </div>
                    </div>




                    <div class="event-input ">

                      <div class="event-buttons clearfix">

                        <a *ngIf="!eventDynamicUrl" href="javascript:void()" (click)="previous('step3'); scroll(targetTop)" id="pre4"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> Previous
                          <i></i></a>
                        <a *ngIf="!eventDynamicUrl" href="javascript:void()" (click)="next('step4',(eventForm.controls['medicalCarriers'].valid &&
                      eventForm.controls['visionCarries'].valid && eventForm.controls['dentalCarries'].valid),'step5'); scroll(targetTop)"
                          id="next4" class="btn btn-default next-btn">Next <i><img src="/assets/images/next-icon.png"
                              alt=""></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void(0)" (click)="previous('step3'); scroll(targetTop)" id="pre4"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> Previous
                          <i></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void(0)"
                          (click)="next('step4',(eventForm.controls['medicalCarriers'].valid &&
                          eventForm.controls['visionCarries'].valid && eventForm.controls['dentalCarries'].valid),'step5'); scroll(targetTop)" id="next4"
                          class="btn btn-default next-btn">Next <i><img src="/assets/images/next-icon.png"
                              alt=""></i></a>

                      </div>
                    </div>



                  </div>

                </div>
              </div> -->

            <!-- </div> -->

              <!-- TODO:- hiding page/step 4  -->

            <!-- <div class="clearfix" #target4 [hidden]="submits.currentStep != 'step5'" id="event-step-5"> -->
              <div class="clearfix" #target3 [hidden]="submits.currentStep != 'step4'" id="event-step-4">
              <div class="lmd-sm-4 pull-right">
                <div class="events-edit-right">
                  <div class="event-page-step"
                    [ngClass]="{'corporate-eventtab-details': loginUserRole===10, 'exhibitor-eventtab-details': loginUserRole===20}">

                    <!-- TODO :- Navigate to any page in edit mode -->

                    <ul>
                      <li><a (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step1') : previous('step1')" href="javascript:void(0)" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">1</a></li>
                      <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step2') : previous('step2')" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">2</a></li>
                      <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? previous('step3') : previous('step3')" [ngClass]="{'step-done-blk':!(isAddEvent === userSystemConstants.EDIT)}">3</a></li>
                      <li><a href="javascript:void(0)" class="active" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step4') : ''">4</a></li>
                      <!-- <li><a href="javascript:void(0)" (click)="(isAddEvent === userSystemConstants.EDIT) ? next('step5') : ''">5</a></li> -->
                  </ul>
                  </div>
                  <i *ngIf="loginUserRole===0"><img src="/assets/images/new-event-icon-2.png" alt=""></i>
                  <i *ngIf="loginUserRole===10"><img src="/assets/images/new-event-icon-2-2.png" alt=""></i>
                  <i *ngIf="loginUserRole===20"><img src="/assets/images/new-event-icon-2.png" alt=""></i>
                  <h5>Select exhibitor invitation radius</h5>
                  <p>Type in your address and then move/expand the circle that appears on the map to include all areas
                    from which you want to recruit exhibitors. A larger radius will likely provide you with a larger
                    response from exhibitors and give you more options to choose from. A limited radius could result in
                    few or no responses.<br><br>
                    Exhibitors will only respond to events that they want to attend. In addition, exhibitors who request
                    an invite are only able to attend if you accept them. Therefore, there is usually no harm is
                    selecting a wider radius.
                  </p>

                </div>

              </div>
              <div class="lmd-sm-8 pull-left">
                <div class="events-edit-left">

                  <h3>Select exhibitor invitation address</h3>


                  <div class="event-edit-form">
                    <div class="event-input">
                      <div class="hfp-input-div">
                        <label>Select exhibitor invitation address&nbsp;<span class="span_star">*</span></label>
                        <input class="map-icon" type="text" (keydown.enter)="$event.preventDefault()" value="{{eventFormLocation.value}}" autocorrect="off"
                          (input)="onSearchAddressForRegion($event)" autocapitalize="off" spellcheck="off">
                      </div>
                    </div>

                    <div class="event-input" *ngIf="!mapRegion.length">
                      <div class="event-list-map">

                        <agm-map style="height : 200px" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                          <agm-marker *ngFor="let region of  mapRegion;let idx=index;" [latitude]="region.lat"
                            [longitude]="region.lng" [markerDraggable]="false"></agm-marker>

                          <agm-circle *ngFor="let region of  mapRegion ;let idx=index;" [latitude]="region.lat"
                            [longitude]="region.lng" [radius]="region.centerRadius?region.centerRadius:centerRadius"
                            [fillColor]="'blue'" [circleDraggable]="false" (radiusChange)="centerChanged($event,idx)"
                            [editable]="true">
                          </agm-circle>
                        </agm-map>

                      </div>
                    </div>
                    <div class="event-input" *ngIf="mapRegion.length === 1">
                      <div class="event-list-map">

                        <agm-map style="height : 200px" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                          <agm-marker *ngFor="let region of  mapRegion;let idx=index;" [latitude]="region.lat"
                            [longitude]="region.lng" [markerDraggable]="false"></agm-marker>

                          <agm-circle *ngFor="let region of  mapRegion ;let idx=index;" [latitude]="region.lat"
                            [longitude]="region.lng" [radius]="region.centerRadius?region.centerRadius:centerRadius"
                            [fillColor]="'blue'" [circleDraggable]="false" (radiusChange)="centerChanged($event,idx)"
                            [editable]="true">
                          </agm-circle>
                        </agm-map>

                      </div>
                    </div>

                    <div class="event-input" *ngIf="mapRegion.length > 1">
                      <div class="event-list-map">

                        <agm-map style="height : 200px" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                          <agm-marker *ngFor="let region of  mapRegion.slice(1);let idx=index;" [latitude]="region.lat"
                            [longitude]="region.lng" [markerDraggable]="false"></agm-marker>

                          <agm-circle *ngFor="let region of  mapRegion.slice(1)  ;let idx=index;" [latitude]="region.lat"
                            [longitude]="region.lng" [radius]="region.centerRadius?region.centerRadius:centerRadius"
                            [fillColor]="'blue'" [circleDraggable]="false" (radiusChange)="centerChanged($event,idx)"
                            [editable]="true">
                          </agm-circle>
                        </agm-map>

                      </div>
                    </div>
                    <div class="map-dropdown-list" *ngIf="mapRegion.length === 1">
                      <ul>
                        <li *ngFor="let region of mapRegion;let idx=index;">
                          <span><a href="javascript:void(0)">Region: {{region.name}}, {{region.radius}} mile radius</a>
                          </span><span>Miles - {{region.centerRadius*0.000621371 | number : '1.2-2'}} miles</span>
                          <div class="map-row-btn">
                            <a href="javascript:void(0)" (click)="remvoeCircle(idx)"><i class="map-delete-icon"></i> Delete
                            </a>
                          </div>
                        </li>
                      </ul>

                    </div>
                    <div class="map-dropdown-list"  *ngIf="mapRegion.length > 1">
                      <ul>
                        <li *ngFor="let region of mapRegion.slice(1);let idx=index;">
                          <span><a href="javascript:void(0)">Region: {{region.name}}, {{region.radius}} mile radius</a>
                          </span><span>Miles - {{region.centerRadius*0.000621371 | number : '1.2-2'}} miles</span>
                          <div class="map-row-btn">
                            <a href="javascript:void(0)" (click)="remvoeCircle(idx)"><i class="map-delete-icon"></i> Delete
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="event-input ">

                      <div class="event-buttons clearfix">

                        <a *ngIf="!eventDynamicUrl" href="javascript:void(0)" (click)="previous('step3'); scroll(targetTop)" id="pre5"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> Previous
                          <i></i></a>
                        <a *ngIf="eventDynamicUrl" href="javascript:void(0)" (click)="previous('step3'); scroll(targetTop)" id="pre5"
                          class="btn btn-default pre-btn"><img src="/assets/images/prv-icon.png" alt=""> Previous
                          <i></i></a>
                        <a href="javascript:void(0)" *ngIf="isAddEvent=='add' || eventDynamicUrl "
                          (click)="next('step4',(mapRegion.length>0), 'preview'); scroll(targetTop)"
                          class="btn btn-default next-btn">Preview </a>

                        <span *ngIf="!eventDynamicUrl">
                          <a href="javascript:void(0)" *ngIf="isAddEvent=='edit'"
                            (click)="next('step4',(mapRegion.length > 0), 'submit'); scroll(targetTop)"
                            class="btn btn-default next-btn">Submit </a>
                        </span>

                      </div>
                    </div>



                  </div>

                </div>
              </div>

            </div>






          </form>

        </div>

      </div>

    </div>
  </div>
</ng-template>

<ng-template #sendinvite>
  <div class="admin-event-sendinvite">
    <div class="event-form-outer">
      <div class="container">
        <div class="clearfix event-form-in">
          <div class="form-close">
            <button (click)="isAddEvent='';editIndex=-1;inviteIndex=-1" id="paymtclose-1">
              <img src="/assets/images/add-form-close.png" alt=""></button>
          </div>
          <div class="sendinvite-section">
            <form [formGroup]="inviteForm" (ngSubmit)="sendInvite(indexData)">
              <div class="">
                <h1>Invitation for {{eventName}}</h1>
                <div class="sendinvite_pop_btm">
                  <div class="hfp-input-div">
                    <label>From </label>
                    <input type="email" value="" placeholder="xyz@yourdomain.com" readonly
                      formControlName="fromEmailAddress">

                    <div class="alert alert-danger" *ngIf="submitInvite && f.fromEmailAddress.errors"
                      [ngClass]="{ 'is-invalid': isSubmitted && f.fromEmailAddress.errors }">
                      <div *ngIf="f.fromEmailAddress.errors.required">From Email address is
                        required</div>
                    </div>
                  </div>

                  <div class="hfp-input-div">
                    <label>{{translateJson.EMAIL | translate}} </label>
                    <input type="email" value="" placeholder="{{translateJson.EMAIL_ADDRESS | translate}}"formControlName="email">

                    <div *ngIf="f.email.invalid &&  isSubmitted"
                      class="custom_error alert alert-danger">
                      {{
                      getValidationErrors({
                      formControl: f.email, formControlName:translateJson.EMAIL_ADDRESS,
                      isEmailPattern: true
                      })
                      }}
                    </div>
                  </div>

                  <div class="hfp-input-div">
                    <label>Is there a fee to attend? <span class="span_star">*</span></label>
                    <div class="switch-field event-status-tab">
                      <input type="radio" id="switch_left10" name="fee" formControlName="fee" value="yes"
                        (change)="onItemChange('yes')">
                      <label for="switch_left10">Yes</label>
                      <input type="radio" id="switch_right11" name="fee" value="no" formControlName="fee" [checked]
                        (change)="onItemChange('no')">
                      <label for="switch_right11">No</label>
                    </div>

                    <div class="alert alert-danger" *ngIf=" isSubmitted && f.fee.errors"
                      [ngClass]="{ 'is-invalid':  isSubmitted && f.fee.errors }">
                      <div *ngIf="f.fee.errors.required">Fee is required</div>
                    </div>
                  </div>
                  <div class="hfp-input-div" *ngIf="showAmountField == 1">
                    <label>{{translateJson.AMOUNT | translate}}<span class="span_star">*</span> </label>
                    <input type="text" value="" placeholder="{{translateJson.AMOUNT | translate}}" formControlName="amount" name="amount">
                    <div *ngIf="f.amount.invalid &&  isSubmitted"
                      class="custom_error alert alert-danger">
                      {{
                      getValidationErrors({
                      formControl: f.amount, formControlName:translateJson.AMOUNT,
                      isNumericPattern: true
                      })
                      }}
                    </div>
                  </div>

                  <div class="hfp-input-div">
                    <textarea placeholder="" formControlName="emailFormat" value="{{emailTemplate}}"></textarea>
                  </div>
                  <div class="buttons">
                    <button class="btn btn-primary">Create Invite</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!--Pop up used before event approval to set event type by admin Start -->
<ng-template #eventApprovalPopup let-c="close" let-d="dismiss">
  <div class="screenings-pop-content">
    <button type="button" class="popclose" daria-label="Close" (click)="modalRef.hide()"><img
        src="/assets/images/pop-close.png" alt=""></button>
    <h1>Event Approval</h1>
    <div class="fold-form">
      <div class="hfp-input-div">
        <label>Select Event Type</label>
        <select class="hfp-selectbox" (change)="setEventOption($event.target.value)">
          <option *ngFor="let item of eventApprovalType" value="{{item.id}}"> {{item.name}}
          </option>
        </select>
      </div>

      <div class="buttons">
        <button class="btn btn-primary" *ngIf="adminActiveTab == 0 || adminActiveTab == 2"
          (click)="approveEvent(selectedEventId,1)">Approval</button>
        <button class="btn btn-primary" *ngIf="adminActiveTab == 1"
          (click)="approveEvent(selectedEventId,1)">Update</button>
      </div>
    </div>
  </div>
</ng-template>
<!--Pop up used before event approval to set event type by admin end -->


<!--Pop up used before event approval to set event type by admin Start -->
<ng-template #eventCancelPopup let-c="close" let-d="dismiss">
  <div class="screenings-pop-content">
    <button type="button" class="popclose" daria-label="Close" (click)="modalRef.hide()"><img
        src="/assets/images/pop-close.png" alt=""></button>
    <h1>Cancel Request</h1>
    <div class="fold-form">
      <form [formGroup]="eventRequestCancelForm" (ngSubmit)="declineInvitationOfEvent()">

        <div class="hfp-input-div">
          <label>Reason to cancel event.</label>

          <textarea formControlName="reason" name="reason" class="form-control"> </textarea>

          <div *ngIf="submitted && fg.reason.errors" class="invalid-feedback">
            <div *ngIf="fg.reason.errors.required">Reason is required</div>
          </div>
        </div>

        <div class="buttons">
          <button class="btn btn-primary" [disabled]="!eventRequestCancelForm.valid">Submit</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #confirmTemplate>
  <div class="modal-body text-center">
    <p>Your unsaved data will be lost.</p>
    <p>Do you want to leave this form?</p>
    <button type="button" class="btn btn-default" (click)="confirmEventForm()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="declineEventForm()">No</button>
  </div>
</ng-template>
<!--Pop up used before event approval to set event type by admin end -->