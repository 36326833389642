import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import widget interface.
import { IFold } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FoldService {
  // Define the class property.
  private url: string = environment.apiUrl + '/folds';
  constructor(private http: HttpClient) { }

  /**
   * This service add fold
   */
  addFold(foldObj: IFold): Observable<IFold> {
    return this.http.post<IFold>(this.url, foldObj);
  }

  /**
   * This service return total cound of fold
   */
  getFoldCount(foldObj: object): Observable<any> {
    return this.http.get<any>(this.url + '/count?' + qs.stringify(foldObj));
  }

  /**
   * This service return total cound of fold
   */
  getFoldList(foldObj: object): Observable<[]> {
    return this.http.get<[]>(this.url + '?' + qs.stringify(foldObj));
  }

  /**
   * this service update fold
   */
  updateFold(id: string, foldObj: IFold): Observable<IFold> {
    return this.http.patch<IFold>(this.url + '/' + id, foldObj);
  }

  /**
   * this service get fold using id.
   */
  getFoldById(id: string): Observable<IFold> {
    return this.http.get<IFold>(this.url + '/' + id);
  }
}
