<div class="login-width">
  <div class="login-in clearfix">
    <div class="login-left">
      <div class="login-img"><img src="/assets/images/login-left.jpg" /></div>

    </div>
    <div class="login-right"> <h2>Forgot Password </h2>

        <div class="login-input">
          <div class="fa fa-envelope"></div> <input type="text" placeholder="Email" class="login-field">
        </div>
        <div class="login-submit"><button>Send</button></div>
    </div>
  </div>
</div>
