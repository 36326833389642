<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i></div>
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)"
               [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))"
               alt="" />

        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Services</h1>
      </div>
    </div>
  </div>
</div>

<div class="main-admin-search screenings-industry-search">
  <div class="container clearfix">
    <form [formGroup]="screeningSearchForm" (ngSubmit)="screeingSearch();">
      <div class="main-admin-search-user-box">
        <input type="text" value="" placeholder="Search Services" formControlName="name">
      </div>

      <div class="main-admin-search-button">
        <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> {{"SEARCH" | translate}}</button>
      </div>
    </form>
    <div class="main-admin-add">
      <a class="outline-btn" href="javascript:void(0);" (click)="toggleForm(true)"><i class="fa fa-plus"
          aria-hidden="true"></i> {{"ADD_SCREENING" | translate}}</a>
    </div>
  </div>
</div>
<!-- Define form template here-->
<div *ngIf="sAttributes.isShow; then screeningFormTemp"></div>

<!-- Screening Form template content Start here-->
<ng-template #screeningFormTemp>
  <div class="add-new-section add-new-screenings">
    <div class="container">
      <div class="add-new-container">
        <div class="form-close"><button id="close-1" (click)="toggleForm()"><img src="/assets/images/add-form-close.png"
              alt=""></button></div>
        <h2> {{"ADD_NEW_SCREENING" | translate}}</h2>
        <form [formGroup]="screeningForm" (ngSubmit)="addScreening()">
          <div class="fold-form">
            <div class="hfp-input-div">
              <label>{{"SCREENING_NAME" | translate}}</label>
              <input class="" type="text" placeholder='{{"SCREENING_NAME" | translate}}' formControlName="name">
              <div *ngIf="iForm.name.invalid && (iForm.name.dirty || sAttributes.submitted )"
                class="alert alert-danger">
                <div *ngIf="iForm.name.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="iForm.name.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}
                </div>
                <div *ngIf="iForm.name.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
              | translate}}</div>
              </div>
            </div>
            <div class="hfp-input-div">
              <label>{{"PARENT_NAME" | translate}}</label>
              <select class="hfp-selectbox" formControlName="parentId">
                <option *ngFor="let screenItem of screeningParentList" value="{{screenItem.id}}">
                  {{screenItem.name}}
                </option>
              </select>
            </div>
            <div class="hfp-input-div">
              <label>{{"STATUS" | translate}}</label>
              <div class="switch-field Status-tab">
                <div *ngFor="let status of globals.statusType; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status"
                    [value]="status.value" [checked]="sAttributes.statusSelected==status.value" />
                  <label for="switch_left{{idx}}">{{status.name}}</label>
                </div>
              </div>
              <div *ngIf="iForm.status.invalid && (iForm.status.dirty || sAttributes.submitted )"
                class="alert alert-danger">
                <div *ngIf="iForm.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
              </div>
            </div>
            <div class="buttons">
              <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
              <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- Screening Form template content End here-->


<!-- Screening List Start here-->
<div class="screenings-industry-pg clearfix">
  <div class="container">
    <div *ngIf="!sAttributes.pageItems || !sAttributes.pageItems.length" class="search-not-found">
      {{translatorJson.NO_RECORD_FOUND| translate}} </div>
    <div class="locale-hierarchy">
      <div class="screenings-list"
        [style.width]="sAttributes.isActiveIdx >=1 ? (280*(sAttributes.isActiveIdx+2))+'px':'100%'">
        <ul *ngFor="let data of sAttributes.pageItems;let pidx=index">
          <li *ngFor="let ind of data; let idx = index" (click)="nextlevelChild(pidx,idx,ind.chid)"
            [ngClass]="{active:sAttributes.isActiveIdx==pidx && sAttributes.childIndex==idx}">
            <div class="sub-child">
              <h3 class="locale-name "> {{ind.name}}
                <a href="javascript:void(0)" data-toggle="modal" data-target="#screenings-pop"
                  (click)="open(content,ind,true);"><i aria-hidden="true" class="fa fa-pencil"></i></a>
                <a href="javascript:void(0)" (click)="deleteScreeningData(pidx,idx);"><i aria-hidden="true"
                    class="fa fa-trash"></i></a>
              </h3>
              <span *ngIf = "ind.status==1">Active</span>
              <span *ngIf = "ind.status==0">InActive</span>
              <a href="javascript:void(0)" [hidden]="!ind.child" class="arrow"></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Screening List End here-->

<!--Add and Edit Screening Pop Up Start Here -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="screenings-pop-content">
    <button type="button" class="popclose" daria-label="Close" (click)="modalRef.hide()"><img
        src="/assets/images/pop-close.png" alt=""></button>
    <h1> {{(sAttributes.itemArray.id ? 'EDIT_SCREENING' : 'ADD_SCREENING') | translate}}</h1>
    <form [formGroup]="screeningForm" (ngSubmit)="updateScreening(sAttributes.itemArray.id)">
      <div class="fold-form">
        <div class="hfp-input-div">
          <label>{{"SCREENING_NAME" | translate}}</label>
          <input class="" type="text" placeholder='{{"SCREENING_NAME"| translate}}' formControlName="name">
          <div *ngIf="iForm.name.invalid && (iForm.name.dirty || sAttributes.submitted )" class="alert alert-danger">
            <div *ngIf="iForm.name.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
            <div *ngIf="iForm.name.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}
            </div>
            <div *ngIf="iForm.name.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
        | translate}}</div>
          </div>
        </div>
        <div class="hfp-input-div">
          <label hidden>{{"PARENT_NAME" | translate}}</label>
          <!-- <select class="hfp-selectbox" formControlName="parentId" hidden>
            <option *ngFor="let indusItem of screeningParentList" value="{{indusItem.id}}"> {{indusItem.name}}
            </option>
          </select> -->
        </div>
        <div class="hfp-input-div">
          <label>{{"STATUS" | translate}}</label>
          <div class="switch-field Status-tab">
            <div *ngFor="let status of globals.statusType; let idx = index;">
              <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status"
                [value]="status.value" [checked]="sAttributes.statusSelected==status.value" />
              <label for="switch_left{{idx}}">{{status.name}}</label>
            </div>
          </div>
          <div *ngIf="iForm.status.invalid && (iForm.status.dirty || sAttributes.submitted )"
            class="alert alert-danger">
            <div *ngIf="iForm.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
          </div>
        </div>
        <div class="buttons">
          <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!--Add and Edit Screening Pop Up End Here -->
