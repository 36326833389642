import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import * as qs from "qs";

// Import widget interface.
import { IMenu } from "../client-schema";
// Import environment config file.
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private menuItemsSubject = new Subject<any>();
  menuItemsObservable = this.menuItemsSubject.asObservable();

  public adminMenuSubject = new Subject<any>();
  public adminMenuObservable = this.adminMenuSubject.asObservable();

  private url: string = environment.apiUrl + "/menus";
  constructor(private http: HttpClient) { }

  /**
   * This service add menu
   */
  addMenu(menuObj: any): Observable<IMenu> {
    return this.http.post<IMenu>(this.url, menuObj);
  }

  /**
   * This service return total cound of menu
   */
  getMenuCount(menuObj: object): Observable<any> {
    return this.http.get<any>(this.url + "/count?" + qs.stringify(menuObj));
  }

  /**
   * This service return total cound of menu
   */
  getMenuList(menuObj: object): Observable<[]> {
    return this.http.get<[]>(this.url + "?" + qs.stringify(menuObj));
  }

  /**
   * this service update menu
   */
  updateMenu(id: string, menuObj: any): Observable<IMenu> {
    // this.reloadMenuItems(true);
    return this.http.patch<IMenu>(this.url + "/" + id, menuObj);
  }

  /**
   * this service get menu using id.
   */
  getMenuById(id: string): Observable<IMenu> {
    return this.http.get<IMenu>(this.url + "/" + id);
  }

  reloadMenuItems(options: { flag?: IMenu, id?: string }) {
    this.menuItemsSubject.next(options);
  }
  setUrlToNavigateLinkToNextPage(url: string) {
    window.open('http' + (environment.isSSL ? 's' : '') + '://' + url, '_blank');
  }
}
