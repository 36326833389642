import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'usNumberMask'
})
export class usNumberMaskPipe implements PipeTransform {

    transform(input: any): any {
        if(input) {
         let x = input.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
         return input = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        }
    }

}
