import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { DataService } from '../../../data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-manage-article',
  templateUrl: './manage-article.component.html',
  styleUrls: ['./manage-article.component.scss']
})

export class ManageArticleComponent implements OnInit {
  @Input() foldData;
  data: any = {};
  isShowTemp = true;
  tempFoldData: any = [];
  isIndex = -1;
  templateData: any = [];
  filePreviewPath: string;
  holdImgdata: any = [];
  localUrl: any[];
  filePreviewUrl: any[];
  templateForm: FormGroup;
  constructor(private fb: FormBuilder, private dataservice: DataService) {
    this.data = { title: '', description: '', Image: [] }
  }

  ngOnInit() {

    // Fold add form
    this.templateForm = this.fb.group({
      title: ['', []],
      description: ['', []]
    });
  }

  /**
   * Add fold template data to fold collection
   */
  addData(): void {
    if (this.templateForm.valid) {
      this.data = this.templateForm.value;
      if (this.templateData && this.templateData.length === 1) {
        this.templateData = [this.data];
      } else if (this.templateData && this.templateData.length > 1) {
        this.templateData[this.templateData.length - 1] = this.data;
      }
      this.dataservice.sendTempData(this.templateData);
      this.isShowTemp = false;


    }
  }

  /**
   * Edit Fold Template Data to fold collection
   * @param index
   */
  editTempData(index: number): void {
    this.data = this.templateData[index];



    this.templateForm.patchValue(this.data);
  }

  /**
   * Add More Fold Template Data In An Array
   */
  addMore(): void {
    this.templateForm.reset();
    this.filePreviewPath = '';
    this.data = { 'Image': [] };
    this.isShowTemp = true;
    this.templateData.push(this.data);
  }

  /**
   * Delete fold template Data by id from an array
   * @param index
   */
  deleteTempData(index: number): void {
    this.templateData.splice(index, 1);
    this.dataservice.sendTempData(this.templateData);
  }

  /**
   * Send Data to fold component by sendTempData servie
   * @param index
   * @param data
   */
  updateData(index: number): any {
    this.templateData[index] = this.data;
    this.isIndex = -1;
    this.dataservice.sendTempData(this.templateData);
  }
  showPreviewImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

}
