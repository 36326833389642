<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
          <div class="main-user-img">
              <div class="main-user-img-upload" hidden>
                <i class="fa fa-camera" aria-hidden="true"></i></div>
            <img *ngIf="userData.image?.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
            <img  *ngIf="userData.image?.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

          </div>
          <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Manage Fold</h1>
      </div>

    </div>

  </div>
</div>

<div class="main-admin-search pg-search-bar">
  <div class="container clearfix">
    <form [formGroup]="searchFForm" (ngSubmit)="searchFold();">
      <div class="main-admin-search-box">
        <input type="text" value="" formControlName="searchFold" placeholder="{{'SEARCH_FOLD' | translate}}" />
      </div>
      <div class="main-admin-search-button">
        <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i>{{"SEARCH" | translate}}</button>
      </div>
    </form>
    <div class="main-admin-add" *ngIf="globals.developmentKey">
      <a id="show-add-section" class="outline-btn" href="javascript:void(0)" (click)="toggleForm()"><i class="fa fa-plus"
          aria-hidden="true"></i> {{"ADD_FOLD" | translate}}</a>
    </div>
  </div>
</div>
<!-- Define form template here-->
<div *ngIf="fAttributes.isShow; then foldFormTemp"></div>

<!-- Fold  List Section content Start here-->
<div class="add-box-list-section">
  <div class="container">
    <div *ngIf="!fAttributes.pageItems || !fAttributes.pageItems.length" class="search-not-found">
      {{translatorJson.NO_RECORD_FOUND | translate}} </div>
    <div class="hfp-row fixed_min_height clearfix">
      <div *ngFor="let data of fAttributes.pageItems let idx = index">
        <div class="hfp-col-3" [ngClass]="{active:fAttributes.isActiveIdx==idx}">
          <div class="a-b-l-s-div manage-page-box">
            <div class="manage-page-img" style="background-image:url(/assets/images/admin-pg-img-1.jpg);"></div>
            <div class="img-overly"></div>
            <div class="box_content_center">
              <div class="box_content_center_in">
            <h3>{{ data.foldName | truncate:[43, '...'] | capitalize }}</h3>
            <div class="a-d-edit">
              <ul>
                <li><a (click)="getfoldForEdit(idx);" href="javascript:void(0)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                </li>
                <li *ngIf="globals.developmentKey"><a href="javascript:void(0)" (click)="deleteFold(idx)"><i class="fa fa-trash-o"
                      aria-hidden="true"></i></a>
                </li>
              </ul>
            </div>
              </div></div>
          </div>
        </div>
        <div class="hfp-row clearfix ad-form-edit" *ngIf="fAttributes.isIndex==idx;">
          <div class="hfp-col-12">
            <div *ngIf="fAttributes.isIndex==idx; then foldFormTemp"></div>
          </div>
        </div>
      </div>
    </div>
 <div class="pagination__box">
    <pagination [totalItems]="fAttributes.count" [rotate]="fAttributes.rotate" [maxSize]="fAttributes.maxSize"
      [itemsPerPage]="fAttributes.limits" (pageChanged)="pageChanged($event);">
    </pagination>
 </div>
  </div>
</div>
<!-- Fold List Section content End here-->

<!-- Fold Form template content Start here-->
<ng-template #foldFormTemp>
  <div [ngClass]="{'edit_form_type': !fAttributes.isShow}">
    <div class="container">
      <div class="add-new-container">
        <div class="form-close"><button (click)="toggleForm()"><img src="/assets/images/add-form-close.png" alt="" /></button></div>
        <h2>{{ (fAttributes.itemArray.id ? "EDIT_FOLD" : "ADD_FOLD") | translate}}</h2>
        <form [formGroup]="foldForm" (ngSubmit)="fAttributes.itemArray.id ? updateFold(fAttributes.itemArray.id) : addFold()">
          <div class="fold-form">
            <div class="hfp-input-div">
              <label>{{"ENTER_FOLD_NAME" | translate}}</label>
              <input class="" type="text" placeholder="{{'ENTER_FOLD_NAME' | translate}}" formControlName="foldName">
              <div *ngIf="f.foldName.invalid && (f.foldName.dirty || fAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.foldName.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                <div *ngIf="f.foldName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
                <div *ngIf="f.foldName.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
                  | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div">
              <label> Select Fold Type </label>
              <select class="hfp-selectbox" formControlName="foldTypeId" (change)="getFoldTypeTemplate(addFolds.foldTypeId)" required>
                <option value="" selected>Select FoldType</option>
                <option *ngFor="let foldType of foldTypeData" [selected]="fAttributes.itemArray.foldTypeId == foldType.id"  [value]="foldType.id">{{foldType.foldTypeName}}</option>
              </select>
              <div *ngIf="f.foldTypeId.invalid && (f.foldTypeId.dirty || fAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.foldTypeId.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
              </div>
            </div>
            <div *ngIf="addFolds.foldTypeId" appControlFactory comp="{{foldType}}" data="{{templateData}}" tempStatus="{{isShow}}" foldData="{{addFolds}}"></div>
              
            <div class="hfp-input-div" *ngIf="globals.developmentKey">
              <label>{{"ACCESS_LEVEL" | translate}}</label>
              <select class="hfp-selectbox" formControlName="accessLevel">
                <option *ngFor="let accessLevel of globals.acl" [selected]="fAttributes.itemArray.auth == accessLevel.value" [value]="accessLevel.value">
                  {{accessLevel.name}}
                </option>
              </select>
              <div *ngIf="f.accessLevel.invalid && (f.accessLevel.dirty || fAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.accessLevel.errors.required">{{"ACCESS_LEVEL_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div" *ngIf="globals.developmentKey">
              <label>{{"AUTH" | translate}}</label>
              <select class="hfp-selectbox" formControlName="auth">
                <option *ngFor="let auth of globals.auth" [selected]="fAttributes.itemArray.auth == auth.value" [value]="auth.value">{{auth.name}}</option>
              </select>
              <div *ngIf="f.auth.invalid && (f.auth.dirty || fAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.auth.errors.required">{{"AUTH_IS_REQUIRED" | translate}}</div>
              </div>
            </div>

            <div class="hfp-input-div">
              <label>{{"STATUS" | translate}}</label>
              <div class="switch-field Status-tab">
                <div class="radio-round" *ngFor="let status of globals.statusType; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status" [value]="status.value"
                    [checked]="fAttributes.statusSelected==status.value" />
                  <label for="switch_left{{idx}}">{{status.name}}</label>
                </div>
              </div>
              <div *ngIf="f.status.invalid && (f.status.dirty || fAttributes.submitted )" class="alert alert-danger">
                <div *ngIf="f.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
              </div>
            </div>
            <app-manage-image
             *ngIf="isManageImageComponent"
             [isManageImageComponent]="isManageImageComponent"
             (foldDataSaved)="getFoldData($event)"
             [foldFormData]="foldFormData">
             </app-manage-image>
            <div class="buttons">
              <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
              <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- Fold Form content End here-->
