import { Component, Injector, OnInit } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { environment } from "../../environments/environment";
import { forkJoin } from "rxjs";
// Import utility method class
import { Utility } from "../utility";
// Handle the global property
import { Globals } from "../globals";
import { MenuService } from "./menu.service";
import { AuthService } from "../services/auth.service";
import { AppComponent } from "../app.component";
import { PageService } from "../page/page.service";
import { Router } from "@angular/router";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NotifierService } from "angular-notifier";
import { MenuFormConstant, SORT_NO_MENU_LIST } from "./MENU_LIST";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import * as TranslatorJson from '../../assets/i18n/en.json'
import { UserRole } from "../constants/user-role.constants";
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  bsModalRef: BsModalRef;
  menuForm: FormGroup;
  searchMenuForm: FormGroup;
  menuAttributes: any = {};
  menuLinkType = "";
  linkTypeValue = "";
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  userData: any;
  loginUserRole: any = null;
  allPageData: any = [];

  essentialMenus = ["menus"];
  nullURLMenus = ['layout', 'settings', 'members'];

  menuType: any = [
    { label: "Footer Menu", value: "footer-menu" },
    { label: "Header Menu", value: "header-menu" },
    { label: "Top Header Menu", value: "top-header-menu" },
  ];
  linkTo = [
    { label: "Page", value: MenuFormConstant.MENU_PAGE_FLAG },
    { label: "Article", value: MenuFormConstant.MENU_ARTICLE_FLAG },
    { label: "Link", value: MenuFormConstant.MENU_LINK_FLAG },
  ];
  menuFormConstant: typeof MenuFormConstant;
  translatorJson: typeof TranslatorJson;
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private menuService: MenuService,
    private authService: AuthService,
    private pageService: PageService,
    private router: Router,
    private comp: AppComponent,
    private utility: Utility,
    private injector: Injector,
    private notifier: NotifierService
  ) {this.initializeProperties(); 
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  ngOnInit() {
    // Menu add form
    this.menuForm = this.fb.group({
      menuName: [
        "",
        [
          Validators.required,
        ],
      ],
      menuType: ['', Validators.required],
      menuSort: [0, []],
      linkTo: this.fb.group({
        type: ["", []],
        url: ["", []],
        page: ["", []],
      }),
      // linkTo: this.fb.group({}),
      containers: this.fb.array([]),
      memberId: ["", []],
      auth: ["", [Validators.required]],
      accessLevel: ["", Validators.required],
      status: ["", Validators.required],
    });

    this.menuAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    this.searchMenuForm = this.fb.group({
      searchMenu: ["", []],
    });

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.comp)) {
    //   this.userData = this.comp.userData;
    //   this.loginUserRole = this.userData.role;
    //   // if (this.loginUserRole === 0) {
    //   //   const userId = this.authService.getUserId();
    //   //   this.menuForm.patchValue({
    //   //     memberId: userId,
    //   //   });
    //   //   this.getMenuListCount();
    //   // }
    //   const userId = this.authService.getUserId();
    //   this.menuForm.patchValue({
    //     memberId: userId,
    //   });
    //   this.getMenuListCount();
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.comp.userData;
          this.loginUserRole = this.userData.role;
          if (this.loginUserRole === UserRole.SUPER_ADMIN) {
          //   const userId = this.authService.getUserId();
          //   this.menuForm.patchValue({
          //     memberId: userId,
          //   });
          //   this.getMenuListCount();
          // }
          const userId = this.authService.getUserId();
          this.menuForm.patchValue({
            memberId: userId,
          });
          this.getMenuListCount();
        }else{
          this.router.navigateByUrl("/home");
        }
        } else {
          this.router.navigateByUrl("/");
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.setMemberID();
  }

  searchMenu(): void {
    if (this.searchMenuForm.valid) {
      this.getMenuListCount();
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.menuForm.controls;
  }

  // set member id to form
  setMemberID(): void {
    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          const userId = this.authService.getUserId();
          this.menuForm.patchValue({
            memberId: userId,
          });
        }
      },
      (err) => { }
    );
  }

  // Manage show/hide menu form
  toggleForm(): void {
    // this.linkToFormGroup.addControl("type", new FormControl("", Validators.required));
    if (!(this.menuAttributes.isIndex >= 0)) {
      this.menuAttributes.isShow = !this.menuAttributes.isShow;
    }
    this.menuAttributes.submitted = false;
    this.menuForm.reset();
    this.menuAttributes.isIndex = -1;
    this.menuAttributes.isActiveIdx = -1;
    this.menuAttributes.itemArray = [];
    this.menuAttributes.statusSelected = 1;
    // Set default  radio button value
    this.menuForm.get("status").setValue(this.menuAttributes.statusSelected);
    this.setMemberID();
  }

  get linkToFormGroup() {
    return this.menuForm.get("linkTo") as FormGroup;
  }

  onLinkChange(eventValue: string) {
    if (eventValue) {
      this.linkToFormGroup.patchValue({
        type: eventValue,
      });
      this.menuLinkType = eventValue;
      this.setLinkParams({ eventValue: eventValue });
    }
  }


  setLinkParams(options: { eventValue: string; menuName?: string; }): void {
    if (options.eventValue === MenuFormConstant.MENU_PAGE_FLAG) {
      this.linkToFormGroup.addControl("page", new FormControl("", Validators.required));
      this.linkToFormGroup.removeControl("url");
    } else if (options.eventValue === MenuFormConstant.MENU_LINK_FLAG) {
      if (this.linkToUrl && options.menuName && !this.nullURLMenus.includes(options.menuName.toLowerCase())) {
        this.linkToUrl.setValidators(Validators.required)
        this.linkToUrl.updateValueAndValidity();
      } else {
        this.linkToFormGroup.addControl("url", new FormControl("", Validators.required));
        this.linkToFormGroup.removeControl("page");
      }
    }
  }

  initializeProperties() {
    this.menuFormConstant = MenuFormConstant;
  }

  addMenu(): void {
    if (this.menuForm.invalid) {
      this.menuAttributes.submitted = true;
    } else {
      this.convertIntoNumber();
      this.menuForm.value.menuName = this.menuForm.value.menuName.toLowerCase();
      this.menuService.addMenu(this.menuForm.value).subscribe(
        (data) => {
          this.menuService.reloadMenuItems({ flag: data });
          this.toggleForm();
          this.menuAttributes.pageItems.splice(0, 0, data);
          this.menuAttributes.count += 1;
          this.menuForm.reset();
        },
        (err) => {
          if (err.error.error.statusCode === 422) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    }
  }

  clearValidationOnMenu(menuName: string): void {
    if (menuName) {
      if (this.linkToType && this.linkToType.value) {
        let layoutMenuName: string;
        if (this.nullURLMenus.includes(menuName.toLowerCase())) {
          layoutMenuName = menuName.toLowerCase();
        }
        if (this.nullURLMenus.includes(this.menuName.value.toLowerCase()) && this.nullURLMenus.includes(layoutMenuName.toLowerCase())) {
          this.linkToUrl.clearValidators();
          this.linkToUrl.updateValueAndValidity();
          this.linkToFormGroup.removeControl("page");
        } else {
          this.setLinkParams({
            eventValue: this.linkToType.value,
            menuName: menuName.toLowerCase()
          })
        }
      }

    }
  }

  updateMenu(id: string): void {
    if (this.menuForm.valid) {
      this.convertIntoNumber();
      this.menuForm.value.menuName = this.menuForm.value.menuName.toLowerCase();
      this.menuService.updateMenu(id, this.menuForm.value).subscribe(
        (data) => {
          this.menuService.reloadMenuItems({ flag: this.menuForm.value, id: id });
          this.getMenu(id);
          this.menuAttributes.isIndex = -1;
          this.menuAttributes.isActiveIdx = -1;
          this.menuForm.reset();
          // if (this.menuForm.value.auth === this.loginUserRole) {
          //   this.menuService.reloadMenuItems(true);
          //   this.getMenu(id);
          //   this.menuAttributes.isIndex = -1;
          //   this.menuAttributes.isActiveIdx = -1;
          //   this.menuForm.reset();
          // }
          // else {

          //   /**
          //    * alternate method for Switch-Case
          //    */
          //   //#TODO: ##refactor this object literal
          //   const userRoles = {
          //     0: () => {
          //       this.router.navigate(['/dashboard'])
          //     },
          //     10: () => {
          //       this.router.navigate(['/event'])
          //     },
          //     20: () => {
          //       this.router.navigate(['/event']);
          //     },
          //   }
          //   userRoles[this.loginUserRole]();
          //   this.menuService.reloadMenuItems(true);
          // }

        },
        (err) => { }
      );
    } else {
      this.menuAttributes.submitted = true;
    }
  }

  convertIntoNumber() {
    this.menuForm.value.status = Number(this.menuForm.value.status);
    this.menuForm.value.auth = Number(this.menuForm.value.auth);
    this.menuForm.value.accessLevel = Number(this.menuForm.value.accessLevel);
    this.menuForm.value.menuSort = Number(SORT_NO_MENU_LIST.filter((menu) => {
      return menu.menuName.toLocaleLowerCase() === this.menuForm.value.menuName.toLowerCase()
    })[0]?.sortNumber || 0);
  }

  // Get single menu item
  getMenu(id: string): any {
    this.menuService.getMenuById(id).subscribe(
      (cuData) => {
        // Return object index
        const getIndex: number = this.menuAttributes.pageItems.findIndex(
          (x) => String(x.id) === String(id)
        );
        if (getIndex >= 0) {
          this.menuAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      },
      (err) => { }
    );
  }

  // Update menu
  deleteMenu(index: number): void {
    if (index >= 0) {
      this.menuForm.patchValue(this.menuAttributes.pageItems[index]);
      this.menuForm.value.status = Number(2);
      this.menuForm.value.auth = Number(this.menuForm.value.auth);
      this.menuService
        .updateMenu(
          this.menuAttributes.pageItems[index].id,
          this.menuForm.value
        )
        .subscribe(
          (data) => {
            this.menuService.reloadMenuItems({ id: this.menuAttributes.pageItems[index].id, flag: this.menuForm.value });
            this.menuAttributes.pageItems.splice(index, 1);
            this.menuAttributes.count -= 1;
          },
          (err) => { }
        );
    }
  }

  // Get Menu count
  getMenuCount(): void {
    const queryObj: any = {
      where: {
        status: { inq: [1, 0] },
      },
    };
    // if (this.searchMenuForm && this.searchMenuForm.value && this.searchMenuForm.value.searchMenu
    // ) {
    //   queryObj.where.menuName = {
    //     regexp: "m.*" + this.searchMenuForm.value.searchMenu + "/mi",
    //   };
    // }
    queryObj.where.menuName = this.getSearchFormValue();
    this.menuService.getMenuCount(queryObj).subscribe(
      (data) => {
        this.menuAttributes.count = data && data.count ? data.count : 0;
      },
      (err) => { }
    );
  }

  // Get menu total list
  getMenuList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        limit: limitNum,
        skip: skipNum,
      },
    };
    // if (this.searchMenuForm && this.searchMenuForm.value && this.searchMenuForm.value.searchMenu) {
    //   queryObj.filter.where.menuName = {
    //     regexp: "m.*" + this.searchMenuForm.value.searchMenu + "/mi",
    //   };
    // }
    queryObj.filter.where.menuName = this.getSearchFormValue();
    this.menuService.getMenuList(queryObj).subscribe(
      (data) => {
        this.menuAttributes.pageItems = data && data.length ? data : [];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getSearchFormValue() {
    if (this.searchMenuForm && this.searchMenuForm.value && this.searchMenuForm.value.searchMenu) {
      return {
        regexp: "m.*" + this.searchMenuForm.value.searchMenu + "/mi",
      };
    } else {
      return
    }
  }

  // Get single menu according to index
  getMenuForEdit(index: number): void {
    this.menuAttributes.isIndex = this.utility.getIndex(
      index,
      this.menuAttributes.pageItems.length
    );
    this.menuAttributes.isShow = false;
    this.menuAttributes.isActiveIdx = index;
    this.menuAttributes.itemArray = this.menuAttributes.pageItems[index];
    this.menuForm.patchValue(this.menuAttributes.itemArray);
    if (this.menuAttributes.itemArray && this.menuAttributes.itemArray.linkTo) {

      this.linkToFormGroup.addControl("type", new FormControl("", Validators.required));
      this.menuLinkType = this.menuAttributes.itemArray.linkTo.type;
      this.setLinkParams({ eventValue: this.menuLinkType });
    }
    this.menuAttributes.statusSelected = this.menuAttributes.itemArray.status;
  }

  // Get total list/count
  getMenuListCount(): void {
    forkJoin([
      this.getMenuCount(),
      this.getMenuList(this.menuAttributes.limits, this.menuAttributes.skips),
      this.getPageList(),
    ]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.menuAttributes.isIndex = -1
    this.menuAttributes.isActiveIdx = -1
    this.getMenuList(this.menuAttributes.limits, startItem);
  }

  // Get page total list
  getPageList(): void {
    const queryObj: any = {
      filter: {
        where: {
          status: 1,
        },
      },
    };
    this.pageService.getPageList(queryObj).subscribe(
      (data) => {
        this.allPageData = data && data.length ? data : [];
      },
      (err) => { }
    );
  }

  openConfirmationDialog(object: any) {
    let modalService = this.injector.get(BsModalService);
    let notifierService = this.injector.get(NotifierService);
    this.bsModalRef = modalService.show(ConfirmationDialogComponent);
    this.bsModalRef.onHidden.subscribe((flag: any) => {
      if (flag && !flag.id) {
        this.deleteMenu(object.idx);
        this.bsModalRef.hide();
        // if (!this.essentialMenus.includes(object.data.menuName.toLowerCase())) {
        //   this.deleteMenu(object.idx);
        // } else {
        //   notifierService.notify("error", "You can not delete this menu");
        // }
      }
    });
  }

  get linkToType(): AbstractControl {
    return this.f.linkTo.get('type');
  }

  get linkToUrl(): AbstractControl {
    return this.f.linkTo.get('url');
  }

  get menuName(): AbstractControl {
    return this.menuForm.get("menuName");
  }
}
