<div class="event-detail-hd exhibitors-list-top no-user-details-hd" [ngStyle]="{'background-image':'url(assets/images/event/defaultEventImages/'+tempEventData.media[0]+')'}">
        <div class="event-bg-overly"></div>
        <div class="event-bg-overly-2"></div>
        <div class="container" *ngIf="tempEventData">
            <h1>Invitation Request</h1>
            <h2>{{tempEventData.eventName}}</h2>
            <ul class="flex-ul justify-center-content">
                <li><i><img src="/assets/images/event-date-icon.png" alt=""></i>
                    <span>{{tempEventData.eventDate | date: 'MMMM d, yyyy' }} <br> {{tempEventData.startDateTime | date:'shortTime'}} - {{tempEventData.endDateTime | date:'shortTime'}}</span>
                </li>
                <li><i><img src="assets/images/event-location-icon.png" alt="" /></i><span>{{tempEventData.eventAddress.location}} <br *ngIf="tempEventData.address2">{{tempEventData.address2}}</span></li>

                
            </ul>
        </div>

    </div>
<div class="main-admin-search exhibitors-searchbar">
        <div class="container clearfix">
            <form [formGroup]="exhibSearchForm" (ngSubmit)="getEventExhibitorListAndCounts();">
                <div class="main-admin-search-box">
                    <input type="text" value="" placeholder="Search Exhibitor" formControlName="searchField" />
                </div>

                <div class="main-admin-search-button">
                    <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                </div>
            </form>
            <div class="list-download-btn" *ngIf="customers.length > 0"><a href="javascript:void(0)" (click)="getExhibitorListForDownLoad(eventSlug,1,tempEventData.eventOptionType)" class="outline-btn"><i class="icon-fa-download"></i> Download List <span>{{customers.length}}</span></a></div>
        </div>
    </div>
<div class="admin-exhibitors-section exhibitors-list-pg">
        <div class="container">

            <div class="exhibitors-row-list" *ngFor="let user of exhibListAttributes.pageItems;  let idx = index">
                <div id="exhibitors-row-list-top" class="exhibitors-row-list-top clearfix" *ngIf="exhibListAttributes.isIndex !=idx">
                    <div class="admin-exhibitors-img">
                        <a  href="javascript:void(0)">
                                <img *ngIf="user.image && user.image.type == 'avatar'" src="/assets/images/avatars/{{user.image.name}}" />
                                <img *ngIf="user.image && user.image.type != 'avatar'" src="/assets/images/users/{{user.image.name}}" />
                        </a>
                    </div>
                    <div class="admin-exhibitors-txt">
                        <h3><a href="javascript:void(0)">{{user.data?.company_name}} </a>
                             <!-- <span><img src="/assets/images/review-star.png" alt=""></span> -->
                            </h3>
                        <div class="exhibitors-tags">
              
                            <!-- <ul *ngIf="user.taxoLists && user.taxoLists.length">
                                <li *ngFor="let taxos of user.taxoLists">{{taxos.name}}</li>
                            </ul> -->
                        </div>
                        <div class="admin-exhibitors-review">{{user.email}}</div>
                        <div class="exhibitors-detail-link"><a id="edit-exhibitors" (click)="exhibListAttributes.isIndex =idx;exhibListAttributes.itemArray=user;getTaxonomyData(user.eventReqData.exhibitorReq,user.eventReqData.screenReq);" href="javascript:void(0)">View Detail</a> </div>
                    </div>
                    <div class="user-screening-name">
                        <div class="user-screening-name-item" *ngFor="let userScreenName of user.eventReqData.screening  | slice: 0:3"> <span tooltip="{{userScreenName.name.length > 21 ? userScreenName.name : ''}}">{{userScreenName.name}}</span>
                        </div>
                        <div class="see-more-screen-name" *ngIf="user.eventReqData.screening.length > 3">
                            <button class="see-more-btn" (click)="seeMoreScreening(idx)">See more</button>
                            <div class="see-more-screen-name-drop-down" *ngIf="seeMoreScreeningIndex == idx">
                                <div class="see-more-screen-name-drop-down-in">
                                    <button class="see-more-close-btn" (click)="seeMoreScreeningIndex = -1">✕</button>
                                    <ul>
                                        <li *ngFor="let userScreenName of user.eventReqData.screening" >{{userScreenName.name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                   <div class="exhibitors-company" >{{user.data?.company_name}}</div>
                    <div class="exhibitors-created"><span>{{user.eventReqData?.created | dateAgo}}</span></div>
                    <!-- {{user | json}} -->
                    <div *ngIf="user.eventReqStatus == 0" class="status-txt"><i><img width="20px" src="/assets/images/sm-peding-icon.png" alt=""></i>Pending</div>
                    <div *ngIf="user.eventReqStatus == 1" class="status-txt"><i><img width="20px" src="/assets/images/sm-paid-icon.png" alt=""></i>  Approved</div>
                    <div *ngIf="user.eventReqStatus == 3 && user.eventReqData.approveStatus == 1" class="status-txt"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>  Canceled</div>
                    <div *ngIf="user.eventReqStatus == 2 && user.eventReqData.approveStatus == 0" class="status-txt"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>  Declined</div>

                        <div class="corporate-users-list-action">

                            <div class="exhibitors-dropdown">
                                <div class="action dropdown " dropdown>
                                  <a class="dropdown-toggle" dropdownToggle aria-controls="dropdown-menu" (click)="false">Action </a>
                                    <ul class="dropdown-menu"  *dropdownMenu id="dropdown-menu">
                                        <li class="event-approve-icon"><a *ngIf="user.eventReqStatus == 0 || user.eventReqStatus == 2" href="javascript:void(0)" (click)="acceptInvitationOfEvent(user.eventReqData,user);">Accept </a></li>
                                        <li class="event-declined-icon"><a *ngIf="user.eventReqStatus == 1 || user.eventReqStatus == 0 " href="javascript:void(0)" (click)="declineInvitationOfEvent(user.eventReqData.id,user,user.eventReqStatus);">Decline </a></li>
                                        <!--<li class="event-clone-icon"><a href="#">Approve</a></li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>

                </div>

              <div class="" *ngIf="exhibListAttributes.isIndex ==idx">
                <div *ngIf="exhibListAttributes.isIndex ==idx; then exhibitorDetail"></div>
              </div>

            </div>
            <div style="align:center" *ngIf="exhibListAttributes.pageItems.length == 0">
                No Data Found !!
            </div>
<div class="pagination__box">
            <pagination *ngIf="exhibListAttributes.count >exhibListAttributes.limits " [totalItems]="exhibListAttributes.count" [rotate]="exhibListAttributes.rotate" [maxSize]="exhibListAttributes.maxSize"
      [itemsPerPage]="exhibListAttributes.limits" (pageChanged)="pageChanged($event);">
    </pagination></div>


        </div>
    </div>

<ng-template  #exhibitorDetail>
        <div class="view-exhibitors-edit clearfix for-corporate-exhibitor" id="pg-edit-exhibitors">
                <div class="form-close">
                    <button id="paymtclose-1" (click)="exhibListAttributes.isIndex =-1;" ><img src="/assets/images/inquiries-close.png" alt=""></button>
                </div>
                <div class="view-exhibitor-box">
                    <div class="view-exhibitor-box-top">
                        <div class="admin-exhibitors-img"><a href="javascript:void(0)">
                                <img *ngIf="exhibListAttributes.itemArray.image.type == 'avatar'" src="/assets/images/avatars/{{exhibListAttributes.itemArray.image.name}}" />
                                <img *ngIf="exhibListAttributes.itemArray.image.type != 'avatar'" src="/assets/images/users/{{exhibListAttributes.itemArray.image.name}}" />
                        </a> </div>
                        <div class="admin-exhibitors-txt">
                            <h3><a href="javascript:void(0)">{{exhibListAttributes.itemArray.username}} </a> 
                                <!-- <span><img src="/assets/images/review-star.png" alt=""></span> -->
                            </h3>
                            <div class="exhibitors-tags"> </div>
                            <div class="admin-exhibitors-review">{{exhibListAttributes.itemArray.email}}</div>
                            <div class="exhibitors-detail-link"><a id="hide-exhibitors" (click)="exhibListAttributes.isIndex =-1;" href="javascript:void(0)">Hide Detail</a> </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <h2 >{{exhibListAttributes.itemArray.data?.company_name}} 
                        <a *ngIf="exhibListAttributes.itemArray.address?.lat !=0 || exhibListAttributes.itemArray.address?.lat != ''" href="javascript:void(0)"
                          (click)="openRequestPopUp(exhibContatct,exhibListAttributes.itemArray);" 
                          data-toggle="modal" data-target="#contact-to-exhibitor-pop">Contact </a> </h2>
                    <div class="view-exhibitor-box-list">
                        <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-2.png" alt=""></i>
                                <strong>Exhibitor Name</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Exhibitor Name</h3>
                                <p>{{exhibListAttributes.itemArray.eventReqData.firstName}} {{exhibListAttributes.itemArray.eventReqData.lastName}}</p>
                            </div>
                        </div>


                        <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-12.png" alt=""></i>
                                <strong>Email</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Email Address
                                </h3>
                                <p>{{exhibListAttributes.itemArray.eventReqData.email}}</p>
                            </div>
                        </div>


                        <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-11.png" alt=""></i>
                                <strong>Phone</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Phone
                                </h3>
                                <p>{{exhibListAttributes.itemArray.eventReqData.contactNumber}}</p>
                            </div>
                        </div>


                        <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-2.png" alt=""></i>
                                <strong>Contact
                                    Name
                                    For the day
                                    of Event</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Contact
                                    Name
                                    For the day
                                    of Event</h3>
                                <p>{{exhibListAttributes.itemArray.eventReqData.contactName}}</p>
                            </div>
                        </div>

                        <!-- <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-17.png" alt=""></i>
                                <strong>Industry</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                             
                                <h3>Industry</h3>
                                <ul *ngIf="taxoData && taxoData.length > 0">
                                    <li *ngFor="let taxo  of taxoData">{{taxo.name}}</li>                                  
                                </ul>
                            </div>
                        </div> -->
                        <div class="view-exhibitor-box-row clearfix" *ngIf="exhibListAttributes.itemArray.address?.lat !=0 || exhibListAttributes.itemArray.address?.lat != ''" >
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-17.png" alt=""></i>
                                <strong>Services Offered</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                             
                                <h3>Services Offered</h3>
                                <ul *ngIf="screeningSelectedData && screeningSelectedData.length > 0">
                                    <li *ngFor="let taxo  of screeningSelectedData">{{taxo.name}}</li>                                  
                                </ul>
                            </div>
                        </div>
                        <div class="view-exhibitor-box-row clearfix" *ngIf="exhibListAttributes.itemArray.eventReqData.numberOfTable">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-18.png" alt=""></i>
                                <strong>No. of Tables Required</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>No. of Tables Required</h3>
                                <p>{{exhibListAttributes.itemArray.eventReqData.numberOfTable}}</p>
                            </div>
                        </div>
                        <div class="view-exhibitor-box-row clearfix"  *ngIf="exhibListAttributes.itemArray.eventReqData.numberOfChairs">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-19.png" alt=""></i>
                                <strong>No. of Chairs Required</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>No. of Chairs Required</h3>

                                <p>{{exhibListAttributes.itemArray.eventReqData.numberOfChairs}}</p>
                            </div>
                        </div>
                        <div class="view-exhibitor-box-row clearfix" *ngIf="exhibListAttributes.itemArray.eventReqData.numberOfChairs">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-20.png" alt=""></i>
                                <strong>Do you need electricity?
                                </strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Do you need electricity?
                                </h3>
                                <p *ngIf="exhibListAttributes.itemArray.eventReqData.electricity == true">Yes</p>
                                <p *ngIf="exhibListAttributes.itemArray.eventReqData.electricity == false">No</p>
                            </div>
                        </div>
                        <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-1.png" alt=""></i>
                                <strong>Names of those attending</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Names of those attending</h3>



                                <p *ngFor="let attendeesShow of exhibListAttributes.itemArray.eventReqData.attendees">
                                    {{attendeesShow.name}} : {{attendeesShow.email}}</p>

                            </div>
                        </div>
                        <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-21.png" alt=""></i>
                                <strong>Additional needs</strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Additional needs (Internet access, additional space, etc.) 	</h3>
                                <ul>
                                    <li>{{exhibListAttributes.itemArray.eventReqData.additionalNeeds}}</li>

                                </ul>
                            </div>
                        </div>



                        <!-- <div class="view-exhibitor-box-row clearfix" *ngIf="exhibListAttributes.itemArray.eventReqData.raffleItem">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-1.png" alt=""></i>
                                <strong>Raffel Item </strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Raffel Item</h3>
                                <ul>
                                    <li>{{exhibListAttributes.itemArray.eventReqData.raffleItem}}</li>
                                </ul>
                            </div>
                        </div> -->


                        <div class="view-exhibitor-box-row clearfix">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-22.png" alt=""></i>
                                <strong>Request On </strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Asked On</h3>
                                <p>{{exhibListAttributes.itemArray.eventReqData.created | date: 'MMMM d, yyyy'}} </p>
                            </div>
                        </div>
                        <div class="view-exhibitor-box-row clearfix" *ngIf="exhibListAttributes.itemArray.eventReqData.virtualEventLink">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-25.png" alt=""></i>
                                <strong>Virtual Event Link
                                </strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <h3>Virtual Event Link</h3>
                                <p>{{exhibListAttributes.itemArray.eventReqData.virtualEventLink }} </p>
                            </div>
                        </div>
                        <div class="view-exhibitor-box-row clearfix" *ngIf="exhibListAttributes.itemArray.eventReqData.exhibitorEventDocument">
                            <div class="view-exhibitor-list-l">
                                <i><img src="/assets/images/corporate-list-icon-26.png" alt=""></i>
                                <strong>Uploaded Document
                                </strong>
                            </div>
                            <div class="view-exhibitor-list-r">
                                <div class="upload-document-row">
                                    <div class="upload-document-row-content"> 
                                        <h3>Uploaded Document</h3>
                                        <p>{{exhibListAttributes.itemArray.eventReqData.exhibitorEventDocument}} </p>
                                    </div>
                                    <div class="upload-document-download">
                                    <button (click)="downloadPfd(exhibListAttributes.itemArray.eventReqData.exhibitorEventDocument)">Download</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
</ng-template>
<!--Exhibitor contact form tempalte  start here-->
<ng-template #exhibContatct>

    <div class="form-pop-txt">
        <button type="button" (click)="closPopup();" class="popclose"
            data-dismiss="modal" aria-label="Close"><img src="/assets/images/pop-close.png" alt=""></button>
        <div class="contact-to-exhibitor-in clearfix">
            <div class="contact-to-exhibitor-right pull-right">
                <h2 *ngIf="exhibListAttributes.itemArray && exhibListAttributes.itemArray.data !=''"> <span>Connect to</span>
                    {{exhibListAttributes.itemArray.data?.company_name}}</h2>
                <div class="contact-to-exhibitor-txt"> <i><img src="/assets/images/connect-exhibitors-pop-icon.png"
                            alt=""></i>

                </div>
            </div>
            <div class="contact-to-exhibitor-left pull-left">
                <form (ngSubmit)="addMessage()" [formGroup]="contactForm">
                    <div class="contact-to-exhibitor-input">
                        <div class="hfp-input-div">
                            <label>Event Name</label>
                            <input class="" value="" type="text" placeholder="Event Name" formControlName="eventName"
                                name="eventName" required minlength="2" maxlength="200">
                        </div>
                    </div>
                    <div class="contact-to-exhibitor-input">
                        <div class="hfp-input-div">
                            <label>Your Email </label>
                            <input class="" value="" type="text" placeholder="Email" formControlName="emailId"
                                name="emailId" required>
                        </div>
                    </div>
                    <div class="contact-to-exhibitor-input">
                        <div class="hfp-input-div">
                            <label>Subject </label>
                            <input class="" value="" type="text" placeholder="Email Subject" formControlName="subject"
                                name="subject" required minlength="2" maxlength="200">
                        </div>
                    </div>
                    <div class="contact-to-exhibitor-input">
                        <div class="hfp-input-div">
                            <label>Message </label>
                            <textarea formControlName="message" required></textarea>
                        </div>
                    </div>
                    <div class="buttons">
                        <button class="btn btn-primary">Contact</button>
                        <button class="btn btn-default" (click)="closPopup();">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</ng-template>
<!--Exhibitor contact form tempalte end here-->
