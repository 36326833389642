import { Component, OnInit, TemplateRef, ElementRef, Injector } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { IndustriesService } from './industries.service';
import { environment } from '../../environments/environment';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { forkJoin } from 'rxjs';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { AppComponent } from '../app.component';
//import { Router } from '@angular/router';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { SystemConstants } from '../constants/system.constants';
@Component({
  selector: 'app-industries-list',
  templateUrl: './industries-list.component.html',
  styleUrls: ['./industries-list.component.scss']
})
export class IndustriesListComponent implements OnInit {
  industrieData: any[];
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  modalRef: BsModalRef;
  industriesForm: FormGroup;
  industriesSearchForm: FormGroup;
  iAttributes: any = {};
  industriesParentList: any = [];
  nestedChildId = [];
  userData: any;
  userSlug: string = '';
  userRole: any = null;
  translatorJson: typeof TranslatorJson;
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private industriesService: IndustriesService,
    private taxonomyService: TaxonomyService,
    private modalService: BsModalService,
    private elementRef: ElementRef,
    private appComponent: AppComponent,
    private authService: AuthService,
    private loginService: LoginService,
    private router: Router,
    private injector: Injector,
    private utility: Utility) {
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {
    // filter Data

    const queryObj: any = {
      filter: {
        where: {
          taxonomyTypeId: ("5b7ba50c5ee376051498b78a")
        }
      }
    };

    this.industriesService.getIndustiesList(queryObj).subscribe(user => {

      // console.log(user);
      this.industrieData = user;

    }, err => {
      //console.log(err);
    });

    // industries type add form
    this.industriesForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      parentId: '',
      status: ['', Validators.required]
    });

    // This  is industries  seach form
    this.industriesSearchForm = this.fb.group({
      name: ['', []]
    });

    // Copy object
    this.iAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    console.log("gggg", this.iAttributes)

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   if (this.appComponent.role === 0) {
    //     // Load data        
    //     this.getIndustriesListCount();
    //   } else {
    //     this.router.navigateByUrl("/");
    //   }
    // }
    // to check auth for login user
     this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
       if (isLogin) {
         this.userData = this.appComponent.userData;
         if (this.userData.role === 0) {
           // Load data
           this.getIndustriesListCount();
         } else {
           this.router.navigateByUrl('/');
         }
       }
     }, err => {
       this.router.navigateByUrl('/');
     });

  }

  get iForm() {
    return this.industriesForm.controls;
  }

  // This method is used for search industries
  industriesSearch() {
    if (this.industriesSearchForm.valid) {
      this.iAttributes.pageItems = [];
      this.getIndustriesListCount();
    }
  }

  // Manage show/hide Industries form
  toggleForm(): void {
    if (!(this.iAttributes.isIndex >= 0) && (this.iAttributes.isActiveIdx == -1)) {
      this.iAttributes.isShow = !this.iAttributes.isShow;
    }
    this.iAttributes.submitted = false;
    this.industriesForm.reset();
    this.iAttributes.isIndex = -1;
    this.iAttributes.isActiveIdx = -1;
    this.iAttributes.childIndex = -1;
    this.iAttributes.itemArray = [];
    this.iAttributes.statusSelected = 1;
    // Set default  radio button value
    this.industriesForm.addControl('parentId', new FormControl());
    this.industriesForm.get('status').setValue(this.iAttributes.statusSelected);
  }

  // Add Industries
  addIndustries(): void {
    // Check Industries validation and save data in db
    if (this.industriesForm.valid) {
      this.industriesForm.value.status = Number(this.industriesForm.value.status); //
      let parentId = null;
      if (this.industriesForm.value.parentId == null) {
        this.industriesForm.removeControl('parentId');
      } else {
        parentId = this.industriesForm.value.parentId;
      }
      this.industriesForm.value.taxonomyTypeId = this.globals.taxonomyTypesConst.industryId;
      this.taxonomyService.addTaxonomy(this.industriesForm.value)
        .subscribe(data => {
          const industryData = data ? data : {};
          this.industriesParentList.push(industryData);
          this.iAttributes.pageItems = [];
          this.setListItems();
          this.toggleForm();
        }, (err) => {
        });
    } else {
      this.iAttributes.submitted = true;
    }
  }

  // Update Industries From Taxonomy Collection
  updateIndustries(id: string): void {
    if (this.industriesForm.valid) {
      if (this.industriesForm.value.parentId == null) {
        this.industriesForm.removeControl('parentId');
      }
      this.industriesForm.value.status = Number(this.industriesForm.value.status);
      this.industriesForm.value.taxonomyTypeId = this.globals.taxonomyTypesConst.industryId;
      this.taxonomyService.updateTaxonomy(id, this.industriesForm.value)
        .subscribe(data => {
          this.getSingleIndustrie(id);
          this.industriesForm.reset();
          this.modalRef.hide();
        }, (err) => {

        });
    } else {
      this.iAttributes.submitted = true;
    }
  }

  // Delete Industries From Taxonomy Collection
  deleteIndustryData(pId: number, cId: number): void {
    const singleItem = this.iAttributes.pageItems[pId][cId];
    if (singleItem) {
      this.nestedChildId.push(singleItem.id);
      this.getNestedAllChild(this.industriesParentList, singleItem.id);
    }
    const queryObj: any = {
      where: { id: { inq: this.nestedChildId } }
    };
    const reqBody: any = {
      status: 2
    };
    if (cId >= 0 && pId >= 0) {
      this.taxonomyService.updateAllTaxonomy(queryObj, reqBody)
        .subscribe(data => {
          this.iAttributes.pageItems[pId].splice(cId, 1);
          this.iAttributes.pageItems.splice(pId + 1, this.iAttributes.pageItems.length);
        }, (err) => {

        });
    }

  }

  //  Get all Child IDs of given parents
  getNestedAllChild(data: any, itemId: string) {
    itemId = itemId || null;
    data.forEach((v: any, i: number) => {
      if (String(v.parentId) === String(itemId)) {
        this.nestedChildId.push(v.id);
        this.getNestedAllChild(data, v.id);
      }
    });
  }
  // Get single Industrie
  getSingleIndustrie(id: string): any {
    this.taxonomyService.getTaxonomyById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.iAttributes.pageItems[this.iAttributes.isActiveIdx].findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          this.iAttributes.pageItems[this.iAttributes.isActiveIdx][getIndex] = Object.assign({}, cuData);
        }
        this.industriesForm.addControl('parentId', new FormControl());
      }, (err) => {

      });
  }
  // Get Industries list
  getIndustriesList(): void {
    const queryObj: any = {
      filter: {
        where: {
          status: 1,
          taxonomyTypeId: this.globals.taxonomyTypesConst.industryId
        }
      }
    };

    // This statement for serch industries list
    if (this.industriesSearchForm && this.industriesSearchForm.value && this.industriesSearchForm.value.name) {
      //  queryObj.filter.where.name = {
      //    regexp: 'm.*' + this.industriesSearchForm.value.name + '/mi'
      //  };
      queryObj.filter.where.name = this.industriesSearchForm.value.name;

    }
    this.taxonomyService.getTaxonomyList(queryObj)
      .subscribe(data => {
        this.industriesParentList = (data && data.length) ? data : [];
        this.setListItems();
      }, (err) => {

      });
  }

  // This is used to set industries list
  setListItems() {
    const industriesData = this.industriesParentList || [];
    this.iAttributes.pageItems = [];
    const parentId = null;
    let getNestedIndustriesData=this.getNested(industriesData, parentId)
    if(getNestedIndustriesData.length == SystemConstants.DEFAULT_ZERO){
      this.iAttributes.pageItems
    }else{
    this.iAttributes.pageItems.push(getNestedIndustriesData);
    }
  }
  //  Get Child Industries From Main Array
  getNested(data: any, parentId: string): any {
    parentId = parentId || null;
    const localeArr = [];
    data.forEach((v, i) => {
      if (String(v.parentId) === String(parentId)) {
        const hasChildData = this.getNested(data, v.id);
        if (hasChildData.length) { data[i].child = hasChildData; }
        localeArr.push(data[i]);
      }
    });
    // console.log(localeArr);
    return localeArr;
  }

  // Set Next level for Industries
  nextlevelChild(parent: number, index: number, haveChild: boolean) {
    this.iAttributes.pageItems.splice(parent + 1, this.iAttributes.pageItems.length);
    if (this.iAttributes.pageItems[parent][index].child) {
      this.iAttributes.pageItems.push(this.iAttributes.pageItems[parent][index].child);
    }
    if (!haveChild) {
      this.iAttributes.pageItems.push([]);
    }
    this.iAttributes.isActiveIdx = parent;
    this.iAttributes.childIndex = index;
  }
  // Open model box of add/edit industry
  open(content: any, data: any, isEdit: boolean): any {
    if (isEdit) {
      try {
        delete data.child;
      } catch (e) { }
      this.iAttributes.itemArray = data;
      this.industriesForm.patchValue(this.iAttributes.itemArray);
      this.iAttributes.statusSelected = this.iAttributes.itemArray.status;
    } else {
      this.industriesForm.reset();
      this.industriesForm.get('parentId').setValue(data.id);
      this.iAttributes.itemArray.parentId = data.id;
    }
    this.modalRef = this.modalService.show(content);
  }

  // Get told type list/count
  getIndustriesListCount(): void {
    forkJoin([this.getIndustriesList()]);
  }
}
