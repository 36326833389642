import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private session = new Subject<string[]>();
  private menuList = new Subject<string[]>();
  session$ = this.session.asObservable();
  pageList$ = this.menuList.asObservable();

  // message start 
  private messageDataSource = new Subject<string[]>();
  messageTemplateData$ = this.messageDataSource.asObservable();
  //message end
  pageData: any = []

  constructor() { }

  //start msg
  setMsg(msg) {
 
  this.messageDataSource.next(msg);
  }

  // getMsg(pageData) {
  //   this.messageTemplateData.next(pageData);
  //  }

  //end msg


  setSession(userData) {
  this.session.next(userData);
  }

  getPageList(pageData) {
    this.menuList.next(pageData);
   }

   generateSlug(data : any,name:string): string {
    name = name.replace(/[^A-Z0-9]/gi, '-').toLowerCase();
    var i = 0, j = 1, len = data.length;
    while (i < len) {
        if (data[i].permalink === name) {
            name = name.replace(/\d+$/, '') + j;
            i = -1;
            j++;
        }
        i++;
    }
    return name;
}

}
