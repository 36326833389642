import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { SystemConstants } from '../constants/system.constants';
import { FoldConstants } from '../fold/fold-template/constants/fold.constants';
import { ManageImage } from '../interface/manage-image/manage-image';


@Component({
  selector: 'app-left-image',
  templateUrl: './left-image.component.html',
  styleUrls: ['./left-image.component.scss']
})
export class LeftImageComponent implements OnInit {

  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  foldData: ManageImage[] = [];
  foldConstants: typeof FoldConstants = FoldConstants;
  systemConstants = SystemConstants;
  constructor() {
  }
  ngOnInit() {
  }

}
