<div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img" *ngIf="userData && userData.image && userData.image.type">
          <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i>
          </div>
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img *ngIf="userData.image.type != 'avatar'" src="/assets/images/users/{{userData.image.name}}" />
        </div>
        <h2>{{userData.username}}</h2>
      </div>

      <div class="main-heading-right">
        <h1>Event Invitation Request Status</h1>
      </div>


    </div>
  </div>
</div>



<div class="main-admin-search admin-event-list-search exhibitor-invitation-page-filter">
  <div class="container clearfix">
    <form [formGroup]="exhibitorInvitationSearchForm"
      (ngSubmit)="getLocalEventsCounts(eventsAttributes.limits,eventsAttributes.skips);exhibitorInvitationSearch();">
      <div class="main-admin-search-box">
        <input type="text" value="" placeholder="Search Event" formControlName="eventName">
      </div>
      <div class="main-admin-search-button">
        <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
      </div>
    </form>
  </div>
</div>

<!-- Event Listing start here-->
<div class="admin-event-section">
  <div class="container">
    <div *ngIf="!eventsAttributes.pageItems || !eventsAttributes.pageItems.length" class="search-not-found">
      {{ translatorJson.NO_RECORD_FOUND | translate}} </div>
    <div class="admin-event-row" *ngIf="isAddEvent==''">
      <div class="admin-event-row-top">
        <div class="event-box clearfix" *ngFor="let localData of allEventData; let idx = index;">
          <div class="hfp-col-8 no-pd-l-r">
            <div class="event-list-left">

              <div class="event-list-img" *ngIf="localData.media && localData.media.length">
                <img width="100%"
                  [src]="(imageCdn + 'w_750,h_220/' + ((imageBaseUrl + '/event/' + localData.media[0]) | encodeUriComponent))"
                  alt="" />
              </div>
              <div *ngIf="localData.media && !localData.media.length" class="event-list-img"> <img
                  src="/assets/images/event/no-img-event-list.jpg" alt="" /> </div>
              <div class="event-list-txt">
                <div class="event-list-txt-top clearfix"
                  [ngClass]="{'noaction': localData.disable == localData.disable}">

                  <h2 class="eventhd">{{localData.eventName}}</h2>
                  <div *ngIf="isEventDateValid(localData)" class="event-list-stus"><i><img width="20px"
                        src="/assets/images/sm-peding-icon.png" alt=""></i>Pending</div>
                  <div *ngIf="localData.eventReqStatus == 1" class="event-list-stus"><i><img width="20px"
                        src="/assets/images/sm-paid-icon.png" alt=""></i> Approved</div>
                  <div *ngIf="localData.eventReqStatus == 3" class="event-list-stus"><i><img width="20px"
                        src="/assets/images/sm-draft-icon.png" alt=""></i> Draft</div>
                  <div *ngIf="isEventDecline(localData)" class="event-list-stus"><i><img width="20px"
                        src="/assets/images/declined-icon.png" alt=""></i> Declined</div>
                  <div class="event-right-dropdown" [ngClass]="{'hiddenDiv': isEventDecline(localData)}" >
                    <div class="action dropdown" dropdown >
                      <a href="#" class="dropdown-toggle" dropdownToggle aria-controls="dropdown-menu" (click)="false">
                        Action
                      </a>
                      <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">
                        <li *ngIf="userData.role == 20" class="event-edit-icon"><a href="javascript:void(0)"
                            (click)="openRequestPopUp(requestForm,localData,true);getScreeningTaxo();">Edit Request </a>
                        </li>
                        <li *ngIf="userData.role == 20" class="event-declined-icon"><a href="javascript:void(0)"
                            (click)="openEventApprovalPopUp(eventApprovalPopup,localData,true,idx)">Cancel Request</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="event-date-box-list clearfix">
                  <span>{{localData.eventDate | date: 'd MMM' }}, {{localData.eventDate | date: 'yyyy' }}</span>
                  <span>{{localData.startDateTime | date:'shortTime'}} &nbsp; - &nbsp;
                    {{localData.endDateTime | date:'shortTime'}}</span>
                    <span>{{localData && localData.timeZone && localData.timeZone.label}}</span>
                </div>

                <div class="event-list-txt-btm clearfix">
                  <p [innerHTML]="localData.eventDescription | truncate:[300, '...']"></p>
                  <div class="event-btm-link">
                    <a class="event-view-detail-btn" (click)="viewDetail(localData.permalink)"
                      href="javascript:void(0)"><i></i> View Detail</a>

                    <!--  <a *ngIf="isExpirationExpired(localData.eventDate) == false
                    &&  userData?.role == 20
                    && localData.eventReqStatus == 2" class="request-invitation-btn" href="javascript:void(0)"
                      (click)="openRequestPopUp(requestForm,localData,true,idx);getScreeningTaxo();againRequest = 1"><i></i> Request
                      Invitation Again</a> -->

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hfp-col-4 no-pd-l-r">
            <div class="event-right-map">
              <div class="event-tag-icon" *ngIf="localData && localData.eventType">
                <img *ngIf="localData.eventType == 'free' && loginUserRole != 10" width="100%"
                  src="/assets/images/event-tag-icon-2.png" alt="" />
                <img *ngIf="localData.eventType == 'hfp' && loginUserRole != 10" width="100%"
                  src="/assets/images/event-tag-icon-1.png" alt="" />
                <img *ngIf="localData.eventType == 'paid' && loginUserRole != 10" width="100%"
                  src="/assets/images/event-tag-icon-3.png" alt="" />
              </div>
              <div class="event-map"
                *ngIf="localData.eventAddress.lat != '' && localData.eventAddress.lng !='';else templateName">
                <img width="100%" src="https://maps.googleapis.com/maps/api/staticmap?center={{localData.eventAddress.location}}&zoom=13&size=600x350&maptype=roadmap
                            &markers=color:green%7Clabel:G%7C{{localData.eventAddress.lat}},
                            {{localData.eventAddress.lng}}&key=AIzaSyD4p1bR-gKmpgFDoSsusigd9oX-SPW8WeA" alt="" />
              </div>
              <div class="event-address">
                <i><img width="100%" src="/assets/images/event-map-icon.png" alt="" /></i>
                <span>{{localData.eventAddress.location}}</span>
              </div>
            </div>
          </div>

          <div class="corporate-users-list-b" *ngIf="editIndex ==idx">
            <div *ngIf="editIndex ==idx &&  (userData.role == 0 || userData.role == 10); then addEventContent"></div>
          </div>
        </div>


        <div class="pagination__box" *ngIf="eventsAttributes.count > eventsAttributes.limits">
          <pagination [totalItems]="eventsAttributes.count" [rotate]="eventsAttributes.rotate"
            [maxSize]="eventsAttributes.maxSize" [itemsPerPage]="eventsAttributes.limits"
            (pageChanged)="pageChanged($event);">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Event list end here -->


<!--Request Event Form-->
<ng-template #requestForm let-c="close" let-d="dismiss">
  <button type="button" class="popclose" data-dismiss="modal" (click)="closPopup()" aria-label="Close"><img
      src="/assets/images/pop-close.png" alt=""></button>

  <div class="form-pop-txt">
    <div class="exhibitor-in-pop clearfix">

      <div class="hfp-row clearfix">

        <div class="hfp-col-4 pull-right">
          <div class="exhibitors-edit-right">
            <i><img src="/assets/images/exhibitor-pop-icon.png" alt=""></i>
            <h5>{{eventReqItem.eventName}}</h5>
            <p [innerHTML]="eventReqItem.eventDescription | truncate:[300, '...']"></p>
          </div>
        </div>
        <div class="hfp-col-8 pull-left">
          <div class="exhibitors-edit-left">
            <h3> {{'SEND_INVITATION_REQUEST' | translate}} </h3>
            <p> </p>
            <form (ngSubmit)="updateRequest()" [formGroup]="eventRequestForm" autocomplete="off">
              <div class="exhibitors-edit-form">

                <div class="clearfix row exhibitors-row-2">
                  <div class="col-sm-6">
                    <div class="hfp-input-div">
                      <label>{{'FIRST_NAME' | translate}}</label>
                      <input class="" value="" type="text" placeholder="{{'FIRST_NAME' | translate}}"
                        formControlName="firstName" name="firstName" required minlength="2" maxlength="200">
                    </div>
                    <div *ngIf="reqForm.firstName.invalid && (reqForm.firstName.dirty || eventsAttributes.submitted )"
                      class="alert alert-danger">
                      <div *ngIf="reqForm.firstName.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
                      <div *ngIf="reqForm.firstName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" |
                        translate}}</div>
                      <div *ngIf="reqForm.firstName.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
                        | translate}}</div>
                    </div>

                  </div>
                  <div class="col-sm-6">
                    <div class="hfp-input-div">
                      <label>{{'LAST_NAME' | translate}}</label>
                      <input class="" value="" type="text" placeholder="{{'LAST_NAME' | translate}}" name="lastName"
                        formControlName="lastName" required minlength="2" maxlength="200">
                    </div>
                    <div *ngIf="reqForm.lastName.invalid && (reqForm.lastName.dirty || eventsAttributes.submitted )"
                      class="alert alert-danger">
                      <div *ngIf="reqForm.lastName.errors.required"> Last Name is required ! </div>
                      <div *ngIf="reqForm.lastName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" |
                        translate}}</div>
                      <div *ngIf="reqForm.lastName.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
                        | translate}}</div>
                    </div>
                  </div>

                </div>

                <div class="hfp-input-div">
                  <label>{{'YOUR_EMAIL' | translate}}</label>
                  <input class="" value="" type="email" placeholder="{{'YOUR_EMAIL' | translate}}" name="email"
                    formControlName="email" required pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$">
                  <div *ngIf="reqForm.email.invalid && (reqForm.email.dirty || eventsAttributes.submitted )"
                    class="alert alert-danger">
                    <div *ngIf="reqForm.email.errors.required"> Email is required ! </div>
                    <div *ngIf="reqForm.email.errors.pattern">Email must be valid Pattern !</div>
                  </div>
                </div>
                <div class="clearfix row exhibitors-row-2">
                  <div class="col-sm-6">
                    <div class="hfp-input-div">
                      <label>{{'PHONE' | translate}}</label>
                      <input class="" value="" type="text" placeholder="{{'PHONE' | translate}}" name="phone"
                        formControlName="phone" minlength="10" maxlength="14" required>
                    </div>
                    <div *ngIf="reqForm.phone.invalid && (reqForm.phone.dirty || eventsAttributes.submitted )"
                      class="alert alert-danger">
                      <div *ngIf="reqForm.phone.errors.required"> {{"PLEASE_ENTER_PHONE" |
                        translate}} </div>
                      <div *ngIf="reqForm.phone.errors.pattern">{{"NOT_A_VALID_PHONE" |
                        translate}}</div>
                      <div *ngIf="reqForm.phone.errors.minlength">
                        {{'NUMBER_LENGTH10' | translate}}
                        letters </div>
                      <div *ngIf="reqForm.phone.errors.maxlength">
                        {{'MAX_LENGTH514' | translate}} </div>
                    </div>

                  </div>
                  <div class="col-sm-6">
                    <div class="hfp-input-div">
                      <label>{{'CONTACT_NAME_DAY_EVENT' | translate}}</label>
                      <input class="" value="" type="text" placeholder="{{'CONTACT_NAME_DAY_EVENT' | translate}}"
                        name="contactName" formControlName="contactName" required minlength="2" maxlength="60">
                    </div>
                    <div
                      *ngIf="reqForm.contactName.invalid && (reqForm.contactName.dirty || eventsAttributes.submitted )"
                      class="alert alert-danger">
                      <div *ngIf="reqForm.contactName.errors.required"> ContactName required ! </div>
                      <div *ngIf="reqForm.contactName.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" |
                        translate}}</div>
                      <div *ngIf="reqForm.contactName.errors.maxlength">ContactName should not be 60 Characters </div>
                    </div>
                  </div>

                </div>

                <div class="clearfix row exhibitors-row-2">
                  <div class="col-sm-6">
                    <div class="hfp-input-div">
                      <label>{{'CONTACT_NUMBER_DAY_EVENT' | translate}}</label>
                      <input class="" value="" type="text" placeholder="{{'CONTACT_NUMBER_DAY_EVENT' | translate}}"
                        name="contactNumber" formControlName="contactNumber" minlength="10" maxlength="14" required>
                    </div>
                    <div
                      *ngIf="reqForm.contactNumber.invalid && (reqForm.contactNumber.dirty || eventsAttributes.submitted )"
                      class="alert alert-danger">
                      <div *ngIf="reqForm.contactNumber.errors.required"> {{"PLEASE_ENTER_PHONE" | translate}} </div>
                      <div *ngIf="reqForm.contactNumber.errors.pattern"> {{"NOT_A_VALID_PHONE" | translate}}</div>
                      <div *ngIf="reqForm.contactNumber.errors.minlength"> {{'NUMBER_LENGTH10' | translate}}</div>
                      <div *ngIf="reqForm.contactNumber.errors.maxlength"> {{'MAX_LENGTH514' | translate}} </div>
                    </div>

                  </div>
                </div>

                <!-- <div class="hfp-input-div">
                  <label>{{'INDUSTRIES' | translate}}&nbsp;<span class="span_star">*</span></label>
                  <ng-select [closeOnSelect]="false" [items]="industriesList" bindLabel="name" bindValue="id"
                    [addTag]="addCustomUser" [multiple]="true" [searchFn]="searchTaxo"
                    placeholder="Select user or add custom tag" formControlName="exhibitorReq">
                  </ng-select>
                  <div
                    *ngIf="reqForm.exhibitorReq.invalid && (reqForm.exhibitorReq.dirty || eventsAttributes.submitted )"
                    class="alert alert-danger">
                    <div *ngIf="reqForm.exhibitorReq.errors.required"> {{"INDUSTRY_REQUIRED" | translate}}
                    </div>

                  </div>

                </div> -->
                <div class="hfp-input-div">
                  <label>{{'SCREENINGS' | translate}}&nbsp;<span class="span_star">*</span></label>
                  <ng-select [items]="screeningParentList" bindLabel="name" bindValue="id" [addTag]="addCustomUser"
                    [multiple]="true" [searchFn]="searchTaxo" [closeOnSelect]="false"
                    placeholder="Select multiple screening" [clearable]="true" formControlName="screenReq">
                  </ng-select>
                  <div *ngIf="reqForm.screenReq.invalid && (reqForm.screenReq.dirty || eventsAttributes.submitted )"
                    class="alert alert-danger">
                    <div *ngIf="reqForm.screenReq.errors.required"> {{"SCREENING_REQUIRED" | translate}}
                    </div>

                  </div>

                </div>

                <div class="clearfix row exhibitors-row-2">
                  <div class="col-sm-6">
                    <div class="hfp-input-div">
                      <label>{{'NO_OF_TABLES' | translate}}</label>
                      <input class="" value="" type="number" placeholder="{{'NO_OF_TABLES' | translate}}"
                        name="numberOfTable" formControlName="numberOfTable" required pattern="^(0|[1-9]\d*)$">
                    </div>
                    <div
                      *ngIf="reqForm.numberOfTable.invalid && (reqForm.numberOfTable.dirty || eventsAttributes.submitted )"
                      class="alert alert-danger">
                      <div *ngIf="reqForm.numberOfTable.errors.required"> {{'REQUIRED' | translate}} </div>
                      <div *ngIf="reqForm.numberOfTable.errors.pattern"> {{'ONLY_NUMBER' | translate}} </div>
                    </div>
                  </div>


                  <div class="col-sm-6">
                    <div class="hfp-input-div">
                      <label>{{'NO_OF_CHAIRS' | translate}}</label>
                      <input class="" value="" type="number" placeholder="{{'NO_OF_CHAIRS' | translate}}"
                        name="numberOfChairs" formControlName="numberOfChairs" required pattern="^(0|[1-9]\d*)$">
                    </div>
                    <div
                      *ngIf="reqForm.numberOfChairs.invalid && (reqForm.numberOfChairs.dirty || eventsAttributes.submitted )"
                      class="alert alert-danger">
                      <div *ngIf="reqForm.numberOfChairs.errors.required"> {{'REQUIRED' | translate}} </div>
                      <div *ngIf="reqForm.numberOfChairs.errors.pattern"> {{'ONLY_NUMBER' | translate}} </div>
                    </div>
                  </div>
                </div>

                <div class="hfp-input-div">
                  <label> {{'NEED_ELECTRICITY' | translate}}</label>
                  <div class="switch-field event-status-tab">
                    <div class="check-btntype" *ngFor="let item of electricityNeed; let idx = index;">
                      <input type="radio" id="switch_left0{{idx}}" formControlName="electricity" name="electricity"
                        value="{{item.value}}" [checked]="eventReqItem.eventReqData.electricity == item.value" />
                      <label for="switch_left0{{idx}}">{{item.name}}</label>
                    </div>
                  </div>
                </div>


                <div class="clearfix label-txt exhibitors-row-2">
                  <div class="row">
                    <div class="col-sm-12"> <label>{{'ATTENDEES' | translate}}</label></div>
                  </div>
                  <div formArrayName="attendees"
                    *ngFor="let item of eventRequestForm.controls['attendees'].controls; let i = index">
                    <div class="row" [formGroupName]="i">
                      <div class="col-sm-6">
                        <div class="hfp-input-div">
                          <input class="" value="" formControlName="name" type="text" placeholder="Full Name">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="hfp-input-div">
                          <input class="" value="" formControlName="email" type="email" placeholder="Email">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hfp-input-div"*ngIf="!(eventRequestForm && eventRequestForm.controls && eventRequestForm.controls['attendees'] && eventRequestForm.controls['attendees'].length >= 10)">
                  <div class="add-fold-link">
                    <a href="javascript:void(0)" (click)="addMoreAttendee()">Add More</a>
                  </div>
                </div>


                <div class="hfp-input-div">
                  <label>{{'ADDITIONAL_NEEDS' | translate}}</label>
                  <textarea style="height: 80px" placeholder="{{'ADDITIONAL_NEEDS' | translate}} "
                    name="additionalNeeds" formControlName="additionalNeeds" minlength="2" maxlength="500"></textarea>

                </div>



                <!-- <div class="hfp-input-div">
                  <label>{{'RAFFLE_ITEM' | translate}}</label>
                  <input class="" type="text" placeholder="{{'RAFFLE_ITEM' | translate}}" name="raffleItem"
                    formControlName="raffleItem" required minlength="2" maxlength="500">

                </div> -->

                <div class="hfp-input-div">
                  <label>{{'INTERACTIVE_COMPONENT' | translate}}</label>
                  <input class="" type="text" placeholder="{{'INTERACTIVE_COMPONENT' | translate}}"
                    name="interactiveComponent" formControlName="interactiveComponent" required minlength="2"
                    maxlength="500">

                </div>


                <div class="hfp-input-div reminders">
                  <label class="field-label">{{'DEFAULT_REMINDERS' | translate}}</label>
                  <div class="form-check" *ngFor="let list of defaultValue">
                    <input type="checkbox" class="css-checkbox" name="reminder" id="{{list.id}}" [checked]='true'
                      (change)="onCheckboxChange(list,$event)">
                    <label for="{{list.id}}" class="css-label">
                      {{list.value}}
                    </label>
                  </div>

                </div>
                <div style="padding-left: 0px;" class="form-check">
                  <input type="checkbox" (change)="conditionAccepted = !conditionAccepted" name="checkboxG1"
                    id="checkboxG1" class="css-checkbox" required />
                  <label for="checkboxG1" class="css-label">{{'EVENT_REQUEST_INVITATION' | translate}}</label>
                </div>

                <div class="buttons">
                  <input type="submit" value="Submit" [disabled]=!conditionAccepted class="btn btn-primary">
                  <a href="javascript:void(0)" data-dismiss="modal" aria-label="Close" (click)="closPopup()"
                    class="btn btn-default">Cancel</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!--Pop up used before event approval to set event type by admin Start -->
<ng-template #eventApprovalPopup let-c="close" let-d="dismiss">
  <div class="screenings-pop-content">
    <button type="button" class="popclose" daria-label="Close" (click)="modalRef.hide()"><img
        src="/assets/images/pop-close.png" alt=""></button>
    <h1>Cancel Request</h1>
    <div class="fold-form">
      <form [formGroup]="eventRequestCancelForm" (ngSubmit)="declineInvitationOfEvent()">

        <div class="hfp-input-div">
          <label>Reason to cancel event.</label>

          <textarea formControlName="reason" name="reason" class="form-control"> </textarea>


          <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
            <div *ngIf="f.reason.errors.required">Reason is required</div>
          </div>
        </div>

        <div class="buttons">
          <button class="btn btn-primary" [disabled]="!eventRequestCancelForm.valid">Submit</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!--Pop up used before event approval to set event type by admin end -->