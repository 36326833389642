<div class="corporate-profile-hd" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <h1> Profile</h1>
  </div>
  <div class="container" *ngIf="tabName =='payment';">
    <div class="paymet-status pro-payment-history">
      <ul>
        <li><a href="javascript:void(0)" (click)="getPaymentList(10,0,1);tabactive=false" [ngClass]="{'active': !tabactive }" >
           <i><img src="/assets/images/sm-paid-icon.png" alt=""></i>
            <div class="paymet-status-right"> <span>{{countsForPaymet.paidCount}}</span> Paid </div>
          </a> </li>

        <li><a href="javascript:void(0)" (click)="getPaymentList(10,0,2);tabactive=true" [ngClass]="{'active': tabactive }"> <i><img src="/assets/images/sm-peding-icon.png" alt=""></i>
            <div class="paymet-status-right"> <span>{{countsForPaymet.unPaidCount}}</span> Pending </div>
          </a> </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="tabName =='payment';" class="exhibitor-payment-history-pg">
  <div class="main-admin-search ">
    <div class="container clearfix">
      <form [formGroup]="eventSearchForm" (ngSubmit)="eventSearch();">
        <div class="main-admin-search-box">
          <input type="text" value="" placeholder="Search Event" formControlName="eventName">
        </div>
        <!-- <div class="main-admin-search-date">
          <input class="date-icon" type="text" value="" placeholder="Date Filter">
        </div> -->
        <div class="main-admin-search-button">
          <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
        </div>
      </form>


    </div>
  </div>
</div>
<div class="my-account-pg">
  <div class="container clearfix">
    <div class="my-account-in clearfix">

      <div class="my-account-left">
        <div class="my-account-user-img">
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_228,h_228/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

          <div class="my-account-user-img-edit">
            <input type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)" />
          <span><i class="fa fa-pencil"
                aria-hidden="true"></i> Change Profile Picture</span></div>
        </div>

        <div class="my-account-user-info">
          <h3>{{userData.username}}</h3>
          <!-- <span>{{userData.email}}</span> -->
        </div>

        <div class="account-link">
          <ul>
            <li class="pro-update-profile-icon"><a (click)="tabName ='profile'"
                [ngClass]="{'active': tabName =='profile'}" href="javascript:void(0)"><i></i> Update Profile</a> </li>
            <li class="pro-change-password-icon"><a (click)="tabName ='password'" href="javascript:void(0)"
                [ngClass]="{'active': tabName =='password'}"><i></i> Change Password</a> </li>
            <li class="pro-message-icon"><a (click)="tabName ='message';getMeszForProfile();"
                [ngClass]="{'active': tabName =='message'}" href="javascript:void(0)"><i></i> Messages <span>{{totalMszCounts}}</span></a> </li>
            <li hidden class="pro-email-tep-icon" *ngIf="loginUserRole ==0 || loginUserRole ==10"><a (click)="tabName ='email'"
                [ngClass]="{'active': tabName =='email'}" href="javascript:void(0)"><i></i> Email Templates</a> </li>
            <li class="pro-payment-history-icon" *ngIf="loginUserRole ==20"><a (click)="tabName ='payment';getPaymentList(10,0,2);getPaymentListCount(0)"
                [ngClass]="{'active': tabName =='payment'}" href="javascript:void(0)"><i></i> Payment History</a> </li>
            <li class="registration-payment-history-icon" hidden *ngIf="loginUserRole ==20"><a (click)="tabName ='rpayment';getPaymentList(10,0,2);getPaymentListCount(0)"
                                                                               [ngClass]="{'active': tabName =='rpayment'}" href="javascript:void(0)"><i></i> Registration Payment</a> </li>
            <li class="pro-logout-icon"><a (click)="logout()" href="javascript:void(0)"><i></i> Logout</a> </li>

          </ul>
        </div>
      </div>


      <!----------------------Update Profile Section Start ---------------------->
      <div *ngIf="tabName =='profile' && loginUserRole ==10; then corporateUserUpdateTemp"></div>
      <div *ngIf="tabName =='profile' && loginUserRole ==20; then exhibitorEdit"></div>
      <div *ngIf="tabName =='profile' && loginUserRole== 0; then corporateUserUpdateTemp"></div>
      <!----------------------Update Profile Section End ---------------------->

      <!----------------------Change Password Section Start ---------------------->
      <div *ngIf="tabName =='password'; then changePassTemp"></div>
      <!----------------------Update Profile Section End ---------------------->


      <!----------------------Corporate Profile Messages Section Start ---------------------->
      <div *ngIf="tabName =='message'; then messageSectionTemp"></div>
      <!----------------------Corporate Profile Messages Section End ---------------------->

      <!----------------------Email Templates Section Start ---------------------->
      <div *ngIf="tabName =='email'; then emailTemp"></div>
      <!----------------------Email Templates Section End ---------------------->


      <!----------------------Payment History Section Start ---------------------->
      <div *ngIf="tabName =='payment'; then paymentSectionTemp"></div>
      <!----------------------Payment History Section End ---------------------->


      <!----------------------Registration Payment Section Start ---------------------->
      <div *ngIf="tabName =='rpayment'; then rgpaymentTemp"></div>
      <!----------------------Registration Payment Section End ---------------------->

    </div>
  </div>
</div>

<!----------------------Update Profile Section Start ---------------------->
<ng-template #updateProfileTemp>
  <div class="my-account-right">
    <div class="update-profile-box">
      <h3>PROFILE DETAILS</h3>
      <div class="my-account-form">

        <div class="hfp-input-div">
          <label>Username</label>
          <input class="" type="text" value="Health Fairs Plus" placeholder="Username" />
        </div>

        <div class="hfp-input-div">
          <label>Company Name</label>
          <input class="" type="text" value="Health Fairs Plus" placeholder="Company Name" />
        </div>

        <div class="hfp-input-div">
          <label>Name</label>
          <input class="" type="text" value="D C Water" placeholder="Name" />
        </div>

        <div class="hfp-input-div">
          <label>Title</label>
          <input class="" type="text" value="Marketing Director" placeholder="Title" />
        </div>

        <div class="hfp-row clearfix">
          <div class="hfp-col-6">
            <div class="hfp-input-div">
              <label>No Of Employees</label>
              <input class="" type="text" value="10" placeholder="No Of Employees" />
            </div>
          </div>

          <div class="hfp-col-6">
            <div class="hfp-input-div">
              <label>Phone Number</label>
              <input class="" type="text" value="7037432735" placeholder="Phone Number" />
            </div>
          </div>
        </div>
        <div class="hfp-input-div">
          <label>E-mail</label>
          <input class="" type="text" value="events@healthfairsplus.com" placeholder="E-mail" />
        </div>
        <div class="hfp-input-div">
          <label>Website</label>
          <input class="" type="text" value="http://www.healthfairsplus.com" placeholder="Website" />
        </div>

        <div class="hfp-input-div">
          <label>Address 1</label>
          <input class="" type="text" value="5501 Merchants View Square" placeholder="Address 1" />
        </div>

        <div class="hfp-input-div">
          <label>Address 2</label>
          <input class="" type="text" value="#735" placeholder="Address 2" />
        </div>

        <div class="hfp-input-div">
          <label>City</label>
          <input class="" type="text" value="Haymarket" placeholder="City" />
        </div>

        <div class="hfp-row clearfix">
          <div class="hfp-col-6">
            <div class="hfp-input-div">
              <label>State</label>
              <select class="hfp-selectbox">
                <option>State</option>
              </select>
            </div>
          </div>

          <div class="hfp-col-6">
            <div class="hfp-input-div">
              <label>ZIP Code</label>
              <input class="" type="text" value="20169" placeholder="ZIP Code" />
            </div>
          </div>

        </div>

        <div class="buttons">
          <button class="btn btn-primary">Submit</button>
          <button class="btn btn-default">Reset</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>
<!----------------------Update Profile Section End ---------------------->

<!----------------------Profile Message Section Start ---------------------->
<ng-template #messageSectionTemp>

  <div class="my-account-right">
    <div class="my-account-message-box clearfix" *ngIf="mszData && mszData.length > 0 else noMessageFound">
      <div class="my-account-message-box-l">
        <ul>
          <li *ngFor="let item of mszData; let idx = index;">
            <a [ngClass]="{'new__message': item.mszReceiverUpdteStatus === 1,'active': item.id == mszSingleItm.id}"
              href="javascript:void(0)" (click)="mszSingleItem(idx);onReplyStatus = false;">
              <h5 class="clearfix">{{item.senderEmail}}</h5>
              <span>{{item.created | date: 'd MMM ' }} {{item.created | date:'shortTime'}}</span>
              <p><i class="fa fa-envelope" aria-hidden="true"></i>{{item.subject}}</p>
            </a>
            <div class="message-remove-box">
              <div class="r-icon"><a  href="javascript:void(0)" (click)="mszRemove(idx)"><i class="fa fa-trash-o" aria-hidden="true"></i> Delete</a></div>
              <div class="v-icon"><a  href="javascript:void(0)" (click)="mszSingleItem(idx);onReplyStatus = false;"><i class="fa fa-eye" aria-hidden="true"></i> View</a></div>
            </div>

          </li>
        </ul>
      </div>
      <div class="my-account-message-box-r">
        <h4>{{mszSingleItm.subject}} <span> {{mszSingleItm.created | date: 'd MMM ' }}
            {{mszSingleItm.created | date:'shortTime'}}</span></h4>
        <div
          *ngFor="let item of (userData.id == mszSingleItm.receiverId ? mszSingleItm.msz.reverse():mszSingleItm.msz); let idx = index;">
          <span *ngIf="idx == 0">{{item.email}}</span>
          <span *ngIf="idx > 0">{{item.mszTime | date: 'd MMM ' }} {{item.mszTime | date:'shortTime'}} {{item.email}}
            wrote: -</span>
          <p [innerHTML]="item.text" class="admin-em-tem"> </p>
        </div>
        <div *ngIf="mszSingleItm && mszSingleItm.msz && mszSingleItm.msz.length > 0 else emptyList">
          <div class="reply_a" *ngIf="mszSingleItm.senderEmail != 'support@lmdconsulting.com'">
            <a href="javascript:void(0);" (click)="onReplyStatus = true;">Reply</a>
          </div>
        </div>

        <div class="message-reply-box" *ngIf="onReplyStatus">
          <form (ngSubmit)="addMessage();onReplyStatus = false" [formGroup]="replyForm">
            <textarea formControlName="message" required></textarea>
            <div class="buttons">
              <button class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>

    </div>

  </div>

</ng-template>
<!----------------------Profile Message Section End ---------------------->

<!----------------------Email Temp Section Start ---------------------->
<ng-template #emailTemp>
  <div class="my-account-right">
    <div class="update-profile-box">

      <div class="account-email-template">

        <div class="account-email-template-row">
          <div class="account-email-template-row-t clearfix">
            <div class="a-e-t-r-l">
              <h4>Event Payment Approved Email For Exhibitor</h4>
              <div class="exhibitors-detail-link"><a id="email-detail-view" href="javascript:void(0);">View</a> <a
                  id="email-edit" href="javascript:void(0)">Edit</a> </div>
            </div>
            <div class="a-e-t-r-m">Diane Taub LMT</div>
            <div class="a-e-t-r-r">2 days ago</div>

          </div>
          <div class="account-email-template-row-b">
            <div class="email-open-box" id="email-detail-view-box" style="display: none;">
              <div class="box-close"><a id="box-close" href="javascript:void(0);"><img
                    src="images/sm-close-box.png" /></a> </div>

              <div class="account-email-template-full">
                <h4>Event Payment Approved Email For Exhibitor</h4>
                <span class="date">AUGUST 29TH, 2016</span>
                <div class="account-email-txt">
                  <p>
                    Hello,<br /><br />
                    Event Name : VA Hospital Reflexology<br /><br />
                    Event Date Date : 06/07/2018<br /><br />
                    Event Time : 11:30 am - 2:30 pm<br /><br />
                    Event Address : 1701 N George Mason Dr., Arlington, VA<br /><br />
                    The event fee is $20 . Please log into your account and accept or decline the event.<br /><br />
                    If you cannot attend, please submit a cancellation Virtual.<br /><br />
                    Event Management<br /><br />
                    Health Fairs Plus<br /><br />

                    Regards<br />
                    Kevin W Harris LMT<br />
                    Health Fairs Plus<br />
                  </p>
                </div>

              </div>

            </div>

          </div>
        </div>


        <div class="account-email-template-row">
          <div class="account-email-template-row-t clearfix">
            <div class="a-e-t-r-l">
              <h4>Acceptance E-mail Template Sent to Exhibitor</h4>
              <div class="exhibitors-detail-link"><a id="email-detail-view-1" href="javascript:void(0);">View</a> <a
                  id="email-edit-1" href="javascript:void(0)">Edit</a> </div>
            </div>
            <div class="a-e-t-r-m">Diane Taub LMT</div>
            <div class="a-e-t-r-r">3 days ago</div>

          </div>
          <div class="account-email-template-row-b">
            <div class="email-open-box" id="email-detail-view-box-1" style="display: none;">
              <div class="box-close"><a id="box-close-1" href="javascript:void(0);"><img
                    src="images/sm-close-box.png" /></a> </div>

              <div class="account-email-template-full">
                <h4>Acceptance E-mail Template Sent to Exhibitor</h4>
                <span class="date">AUGUST 29TH, 2016</span>
                <div class="account-email-txt">
                  <p>
                    Hello,<br /><br />
                    Event Name : VA Hospital Reflexology<br /><br />
                    Event Date Date : 06/07/2018<br /><br />
                    Event Time : 11:30 am - 2:30 pm<br /><br />
                    Event Address : 1701 N George Mason Dr., Arlington, VA<br /><br />
                    The event fee is $20 . Please log into your account and accept or decline the event.<br /><br />
                    If you cannot attend, please submit a cancellation Virtual.<br /><br />
                    Event Management<br /><br />
                    Health Fairs Plus<br /><br />

                    Regards<br />
                    Kevin W Harris LMT<br />
                    Health Fairs Plus<br />
                  </p>
                </div>

              </div>

            </div>

          </div>
        </div>


        <div class="account-email-template-row">
          <div class="account-email-template-row-t clearfix">
            <div class="a-e-t-r-l">
              <h4>Cancellation E-mail Template Sent to Exhibitor</h4>
              <div class="exhibitors-detail-link"><a id="email-detail-view-2" href="javascript:void(0);">View</a> <a
                  id="email-edit-2" href="javascript:void(0)">Edit</a> </div>
            </div>
            <div class="a-e-t-r-m">Xuan Bui</div>
            <div class="a-e-t-r-r">5 days ago</div>

          </div>
          <div class="account-email-template-row-b">
            <div class="email-open-box" id="email-detail-view-box-2" style="display: none;">
              <div class="box-close"><a id="box-close-2" href="javascript:void(0);"><img
                    src="images/sm-close-box.png" /></a> </div>

              <div class="account-email-template-full">
                <h4>Cancellation E-mail Template Sent to Exhibitor</h4>
                <span class="date">AUGUST 29TH, 2016</span>
                <div class="account-email-txt">
                  <p>
                    Hello,<br /><br />
                    Event Name : VA Hospital Reflexology<br /><br />
                    Event Date Date : 06/07/2018<br /><br />
                    Event Time : 11:30 am - 2:30 pm<br /><br />
                    Event Address : 1701 N George Mason Dr., Arlington, VA<br /><br />
                    The event fee is $20 . Please log into your account and accept or decline the event.<br /><br />
                    If you cannot attend, please submit a cancellation Virtual.<br /><br />
                    Event Management<br /><br />
                    Health Fairs Plus<br /><br />

                    Regards<br />
                    Kevin W Harris LMT<br />
                    Health Fairs Plus<br />
                  </p>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!----------------------Email Temp Section End ---------------------->

<!----------------------Change Password Temp Section Start ---------------------->
<ng-template #changePassTemp>
  <div class="my-account-right">
    <div class="update-profile-box">
      <div class="my-account-form">
        <form [formGroup]="passwordForm" (ngSubmit)="passwordFormSubmit()">
          <div class="hfp-input-div">
            <label>Old Password&nbsp;<span class="span_star">*</span></label>
            <input class="" type="password" formControlName="oPassword" placeholder="Current Password" />
            <div
              *ngIf="passwordForm.controls['oPassword'].invalid && submitted"
              class="custom_error">
              <div *ngIf="passwordForm.controls['oPassword'].errors.required">Current Password is required. </div>
              <!-- <div *ngIf="passwordForm.controls['oPassword'].errors.minlength">Password must be at least 6 characters. -->
              <!-- </div> -->
            </div>
          </div>
          <div class="hfp-input-div">
            <label>New Password &nbsp;<span class="span_star">*</span></label>
            <input class="" type="password" formControlName="password" placeholder="New Password" />
            <!-- <div
              *ngIf="passwordForm.controls['password'].invalid && (passwordForm.controls['password'].dirty || submitted)"
              class="custom_error">
              <div *ngIf="passwordForm.controls['password'].errors.required">New Password is required. </div>
              <div *ngIf="passwordForm.controls['password'].errors.minlength">Password must be at least 6 characters.
              </div>
            </div> -->
            <div *ngIf="sf.password.invalid && (sf.password.dirty)" class="custom_error">
              <div *ngIf="sf.password.errors.required ">Password is required. </div>
              <!-- <div *ngIf="sf.password.errors.minlength">Password must be at least 6 characters. -->
              <!-- </div> -->
              <!-- <div *ngIf="!sf.password.errors.minlength && sf.password.errors.pattern">
                Paasword must contain at least one L_case,U_case,special Symbol, Or Number </div> -->
            </div>


</div>

          <div class="hfp-input-div">
            <label>Confirm Password &nbsp;<span class="span_star">*</span></label>
            <input class="" type="password" formControlName="cPassword" placeholder="Confirm New Password" />

            <div
              *ngIf="passwordForm.controls['cPassword'].invalid && submitted"
              class="custom_error">
              <div *ngIf="passwordForm.controls['cPassword'].errors.required">Confirm password is required. </div>
            </div>
            <div *ngIf="passwordForm.errors?.misMatch && (passwordForm.controls['cPassword'].dirty || submitted)"
              class="custom_error">
              <div *ngIf="passwordForm.errors?.misMatch">Password does not match. </div>
            </div>


          </div>
          <div class="hfp-input-div form-check">
            <div class="remember-me ">
              <input type="checkbox" name="checkbox1" id="checkboxG1" class="css-checkbox"formControlName="checkbox1" >
              <label for="checkboxG1" class="css-label">Email Notifications</label>

            </div>

          </div>
          <div class="buttons">
            <button class="btn btn-primary"  >Submit</button>
            <button class="btn btn-default" (click)="clearInputMethod()" >Reset</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!----------------------Change Password Tempalte Section End ---------------------->

<!----------------------Payment Tempalte Section Start ---------------------->
<ng-template #paymentSectionTemp>

  <div class="my-account-right">
    <div class="payment-history-box" *ngIf="paymentListData.pageItems.length > 0">
      <div hidden class="payment-history-box-top">
        <ul>
          <li>Event Description</li>
          <!-- <li>Invoice Number</li>-->
          <li>Price</li>
        </ul>
      </div>


      <div class="payment-history-list profilepay" >

        <div class="payment-history-row" *ngFor="let rowItem of paymentListData.pageItems; let i = index;">

          <div class="payment-history-row-top clearfix"  >
            <div class="payment-date">
              <strong>{{rowItem.eventDate | date: 'd MMM, yyyy' }}</strong>
            </div>
            <div class="payment-history-right">
              <div class="select-payment-check" *ngIf="tabactive">
                <input type="checkbox" name="checkboxG{{i}}" id="checkboxG{{i}}" class="css-checkbox" checked=""
                (change)="changePayment($event,rowItem.eventPayment,rowItem,i)" />
                <label for="checkboxG{{i}}" class="css-label"></label>
              </div>
              <div class="ex_payment_left"  >

                <div class="p-h-r-i"  *ngIf="rowItem.eventData.media && rowItem.eventData.media?.length" >
                 
                  <img width="100%"
                  [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + 'defaultEventImages/' + rowItem.eventData.media[0]) | encodeUriComponent))"
                  alt="" /> 
                </div>
              <div *ngIf="rowItem.eventData.media && !rowItem.eventData.media.length" class="p-h-r-i">
                      <img src="" alt=""
                      [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/no-img-event-list.jpg') | encodeUriComponent))"
                      alt="" />
                     <!-- <img
                    src="/assets/images/event/no-img-event-list.jpg" alt="" /> -->
                   </div>
                <div class="p-h-r-txt">
                  <h3>{{rowItem.eventName}}</h3>
                  <span>{{rowItem.corporateName}}</span>
                  <!--- *ngIf="eventPaymenIndex.indexOf(i)>-1" -->
                  <div [hidden]="!myVar" *ngIf="eventPaymenIndex.indexOf(i) == -1 && searchStatus == 0" class="exhibitors-detail-link">
                    <a class="btn btn-primary btn-sm active"
                     id="view-ex-detail" href="javascript:void(0)" (click)="addPaymentForEvent(rowItem,i)">Pay For
                      Event</a> </div>
                </div>
              </div>
              <div hidden class="p-h-i-n-left"></div>
              <div class="ex_payment_right" *ngIf="rowItem.eventPayment > 0"><strong>${{rowItem.eventPayment}}</strong> Amount</div>

              <div hidden class="p-h-p-stc" *ngIf="searchStatus == 0">
                <img src="/assets/images/sm-payment-unspecfied-icon.png" alt="" /> Unpaid
              </div>
              <div hidden class="p-h-p-stc" *ngIf="searchStatus == 1">
                <i><img src="/assets/images/sm-paid-icon.png" alt=""></i>Paid
              </div>

              <div hidden class="total-invoice">Total Invoice Amount : ${{rowItem.eventPayment}}</div>
            </div>

          </div>
        </div>


        <div class="payment-proceed-btn" *ngIf="tabactive"><a
            href="javascript:void(0)" (click)="setEventCheckout()">Proceed to Pay ${{total}}</a>
        </div>
        <br />
        <div class="pagination__box" [hidden]="!myVar">
          <!-- {{paymentListData.count}}
          <br />
          {{paymentListData.limits}}
          <br />

          {{paymentListData.maxSize}}
          <br />
          {{paymentListData.rotate}} -->
          <pagination [totalItems]="paymentListData.count" [rotate]="paymentListData.rotate"
            [maxSize]="paymentListData.maxSize" [itemsPerPage]="paymentListData.limits"
            (pageChanged)="pageChanged($event);">
          </pagination>
        </div>

      </div>
    </div>

  </div>

</ng-template>
<!----------------------Payment Tempalte Section End ---------------------->

<ng-template #emptyList>
  <div>
    <P> No message selected</P>
  </div>
</ng-template>

<ng-template #noMessageFound>
  <div class="my-account-message-box-l">
    <P>No Message</P>
  </div>
</ng-template>

<!--Corporate User Edit Tempalte Start Fronm here -->
<!--ng template form-->
<ng-template #corporateUserUpdateTemp>

  <div class="my-account-right">

    <div class="update-profile-box">

      <div class="my-account-form">
        <h3>{{'COMPANY_INFO' | translate}}</h3>
        <form [formGroup]="signUpForm" (ngSubmit)="corporateUserUpdate(uAttributes.itemArray.id)" autocomplete="off">
          <div class="hfp-input-div">
            <label>{{'USER' | translate}} {{'NAME' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" placeholder="User Name" formControlName="username" name="username" />
          </div>
          <div
            *ngIf="signUpForm.controls['username'].invalid && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.controls['username'].errors.required">!! User Name is required !! </div>
            <!-- <div *ngIf="signUpForm.controls['username'].errors.minlength">User Name should have two Characters
            </div>
            <div *ngIf="signUpForm.controls['username'].errors.maxlength">User Name is too Large !!!</div> -->
          </div>

          <div class="hfp-input-div" formGroupName="data">
            <label>{{'COMPANY_NAME' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" placeholder="{{'COMPANY_NAME' | translate}}" formControlName="company_name"
              name="company_name" />
          </div>
          <div
            *ngIf="signUpForm.get('data').controls['company_name'].invalid && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['company_name'].errors.required"> !! Company Name is
              required !!</div>
            <!-- <div *ngIf="signUpForm.get('data').controls['company_name'].errors.minlength"> Company Name must be of
              two letters </div>
            <div *ngIf="signUpForm.get('data').controls['company_name'].errors.maxlength"> Company Name is too
              Large </div> -->
          </div>


          <div class="hfp-input-div" formGroupName="data">
            <label>{{'NUMBER_OF_EMPLOYEES' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="number" placeholder="{{'NUMBER_OF_EMPLOYEES' | translate}}" formControlName="number_employee"
              name="number_employee"  required/>
          </div>
          <div
            *ngIf="signUpForm.get('data').controls['number_employee'].invalid && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['number_employee'].errors.required"> !!Employee No. Is
              required !! </div>
            <!-- <div *ngIf="
              .get('data').controls['number_employee'].errors.pattern"> !!Only Numbers is
              allowed !! </div> -->
          </div>


          <div class="hfp-input-div" formGroupName="address">
            <label>{{'ADDRESS1' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input id="autocomplete" type="text" (click)="onAddreessChange($event)" (keydown.enter)="$event.preventDefault()"
              placeholder="{{'ADDRESS1' | translate}}" formControlName="addressOne" name="addressOne" myNoSpaces
              #search />
          </div>
          <div
          *ngIf="signUpForm.get('address').controls['addressOne'].invalid && uAttributes.submitted"
          class="custom_error">
          <div *ngIf="signUpForm.get('address').controls['addressOne'].errors.required"> !! Address is
            required !!</div>
        </div>


          <div class="hfp-input-div" formGroupName="address">
            <label>{{'ADDRESS 2' | translate}}</label>
            <input type="text" formControlName="formatted_address" name="formatted_address"
              placeholder="{{'ADDRESS2' | translate}}" />
          </div>

          <div class="hfp-input-div" formGroupName="address">
            <label>{{'City' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" placeholder="{{'City' | translate}}" formControlName="city" name="city" />
          </div>
          <div
          *ngIf="signUpForm.get('address').controls['city'].invalid && uAttributes.submitted"
          class="custom_error">
          <div *ngIf="signUpForm.get('address').controls['city'].errors.required"> !! City is required !!</div>
        </div>

          <div class="hfp-row">
            <div class="hfp-col-6">
              <div class="hfp-input-div" formGroupName="address">
                <label>{{'STATE' | translate}}&nbsp;<span class="span_star">*</span></label>
                <input type="text" placeholder="Enter state" formControlName="state" name="state" required>
              </div>
              <div
          *ngIf="signUpForm.get('address').controls['state'].invalid && uAttributes.submitted"
          class="custom_error">
          <div *ngIf="signUpForm.get('address').controls['state'].errors.required"> !! State is
            required !!</div>
        </div>
            </div>


            <div class="hfp-col-6">
              <div class="hfp-input-div" formGroupName="address">
                <label>{{'ZIP_CODE' | translate}}&nbsp;<span class="span_star">*</span></label>
                <input type="text" formControlName="zip_code" name="zip_code" placeholder="{{'ZIP_CODE' | translate}}"required>
              </div>
              <div
              *ngIf="signUpForm.get('address').controls['zip_code'].invalid && uAttributes.submitted"
              class="custom_error">
              <div *ngIf="signUpForm.get('address').controls['zip_code'].errors.required"> !! ZIP_CODE is
                required !!</div>
            </div>
            </div>


          </div>


          <div class="hfp-input-div" formGroupName="data">
            <label>{{'CONTACT' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" formControlName="contact" name="contact" placeholder="{{'CONTACT' | translate}}" />
          </div>

          <div
            *ngIf="signUpForm.get('data').controls['contact'].invalid &&  uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['contact'].errors.required"> !!Contact Name Is required !!
            </div>
            <!-- <div *ngIf="signUpForm.get('data').controls['contact'].errors.minlength"> Contact Name must be of two
              letters </div>
            <div *ngIf="signUpForm.get('data').controls['contact'].errors.maxlength"> Contact Name is too Large
            </div> -->
          </div>

          <div class="hfp-input-div" formGroupName="data">
            <label>{{'JOB_TITLE' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" formControlName="title" name="title" placeholder="{{'JOB_TITLE' | translate}}" />
          </div>
          <div
            *ngIf="signUpForm.get('data').controls['title'].invalid
            && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['title'].errors.required"> !! Your Job Title Is required
              !! </div>
            <!-- <div *ngIf="signUpForm.get('data').controls['title'].errors.minlength"> Job Title must be of two
              letters </div>
            <div *ngIf="signUpForm.get('data').controls['title'].errors.maxlength"> Job title is too Large </div> -->
          </div>


          <div class="sign-up-fields" formGroupName="data">
            <label class="field-label">Phone Number&nbsp;<span class="span_star">*</span></label>
            <app-phone-number 
              [phoneNumberFormControlName]="'phone_number'"
              [phoneNumberFormGroup]="profileDataForm.phone_number.parent"
            ></app-phone-number>
          </div>
          <div
            *ngIf="profileDataForm.phone_number.invalid &&uAttributes.submitted "
            class="custom_error">
            <div *ngIf="profileDataForm.phone_number.errors.required"> {{
              'REQUIRED_MSG_WITH_PARAMS' |
              translate: {'name': 'Phone Number' | translate}
              }} </div>
            <div *ngIf="profileDataForm.phone_number.errors.invalidNumber">{{translatorJson.NOT_A_VALID_PHONE |
              translate}}</div>
          </div>

          <!-- <div class="hfp-input-div" formGroupName="data">
            <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" placeholder="Enter Phone Number" formControlName="phone_number"
              [value]="signUpForm.get('data').controls['phone_number'].value"
              placeholder="{{'PHONE_NUMBER' | translate}}" required />
          </div> -->
          <!-- <div
            *ngIf="signUpForm.get('data').controls['phone_number'].invalid && (signUpForm.get('data').controls['phone_number'].dirty || uAttributes.submitted)"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.required"> {{'REQUIRED' | translate}}
            </div> -->
            <!-- <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.minlength">
              {{'NUMBER_LENGTH10' | translate}}
              letters </div> -->
            <!-- <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.maxlength">
              {{'NUMBER_LENGTH10' | translate}} </div>
            <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.pattern"> {{'ONLY_NUMBER' | translate}}
            </div>
          </div> -->

          <div class="hfp-input-div">
            <label>{{'EMAIL' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" formControlName="email" name="email"  placeholder="{{'EMAIL' | translate}}" />
            <div
            *ngIf="signUpForm.controls['email'].invalid && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.controls['email'].errors.required">!! Email is required !! </div>
            <div *ngIf="signUpForm.controls['email'].errors.email">Email must be valid Pattern </div>

            <!-- <div *ngIf="signUpForm.controls['username'].errors.minlength">User Name should have two Characters
            </div>
            <div *ngIf="signUpForm.controls['username'].errors.maxlength">User Name is too Large !!!</div> -->
          </div>
            
          </div>


          <div class="hfp-input-div" formGroupName="data">
            <label>{{'WEBSITE' | translate}}</label>
            <input type="text" formControlName="website" name="website" placeholder="{{'WEBSITE' | translate}}" />
          </div>


            <!-- <div  class="buttons">
              <a href="javascript:void(0)"
              class="btn btn-primary" (click)="toggleCollapse()">{{'EDIT' | translate}}</a>
            </div> -->
          <div class="buttons" >
            <button class="btn btn-primary">{{"SUBMIT" | translate }}</button>
            <!-- <a href="javascript:void(0)" (click)="editIndex = -1; dropIndex = -1" id="reset1"
              class="btn btn-default">{{'CANCEL' | translate}}</a> -->
              <a href="javascript:void(0)" (click)="editIndex()"
              class="btn btn-default">{{'CANCEL' | translate}}</a>
          </div>

        </form>

      </div>


    </div>
    <div class="clear"></div>
  </div>
</ng-template>
<!--Corporate User Edit Temp end here -->

<!--Exibitor User Edit Temp Start from here -->
<ng-template #exhibitorEdit>



  <div class="my-account-right">


    <div class="update-profile-box">
      <h3>{{'COMPANY_INFO' | translate}}&nbsp;<span class="span_star">*</span></h3>
      <div class="my-account-form">
        <form [formGroup]="signUpForm" (ngSubmit)="exibitorUserUpdate(uAttributes.itemArray.id)" autocomplete="off">


          <div class="hfp-input-div" formGroupName="data">
            <label>Your Business Name (if you don't have one, please use your name)&nbsp;<span class="span_star">*</span></label>
            <input type="text" class="input-field" placeholder="Your Business Name"
              formControlName="company_name" name="company_name" required>
          </div>
          <div
            *ngIf="signUpForm.get('data').controls['company_name'].invalid && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['company_name'].errors.required">Business Name is
              required </div>
            <!-- <div *ngIf="signUpForm.get('data').controls['company_name'].errors.minlength"> Company Name must be of
              two letters </div>
            <div *ngIf="signUpForm.get('data').controls['company_name'].errors.maxlength"> Company Name is too
              Large </div> -->
          </div>

          <div class="hfp-input-div" formGroupName="address">
            <label class="field-label">{{'ADDRESS1' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input id="autocomplete" type="text" (click)="onAddreessChange($event)"
              placeholder="{{'ADDRESS1' | translate}}" formControlName="addressOne" name="addressOne" myNoSpaces #search
              (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" />

          </div>
          <div
          *ngIf="signUpForm.get('address').controls['addressOne'].invalid && uAttributes.submitted"
          class="custom_error">
          <div *ngIf="signUpForm.get('address').controls['addressOne'].errors.required"> !! Address 1 is
            required !!</div>
        </div>


<div class="hfp-input-div" formGroupName="address">
            <label class="field-label">{{'CUSTOM_ADDRESS' | translate}}</label>
            <input type="text" formControlName="formatted_address" name="formatted_address"
              placeholder="{{'ADDRESS2' | translate}}" />
          </div>


          <div class="hfp-input-div" formGroupName="address">
            <label class="field-label">{{'City' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" placeholder="{{'City' | translate}}" formControlName="city" name="city" />

          </div>
          <div
          *ngIf="signUpForm.get('address').controls['city'].invalid && uAttributes.submitted"
          class="custom_error">
          <div *ngIf="signUpForm.get('address').controls['city'].errors.required"> !! City is
            required !!</div>
        </div>


<div class="hfp-row clearfix">
            <div class="hfp-col-6">
              <div class="hfp-input-div" formGroupName="address">
                <label class="field-label">{{'STATE' | translate}}&nbsp;<span class="span_star">*</span></label>
                <input type="text" placeholder="Enter state" formControlName="state" name="state">
                <div
                *ngIf="signUpForm.get('address').controls['state'].invalid && uAttributes.submitted"
                class="custom_error">
                <div *ngIf="signUpForm.get('address').controls['state'].errors.required"> !! State is
                  required !!</div>
              </div>

              </div>
            </div>

            <div class="hfp-col-6">
              <div class="hfp-input-div" formGroupName="address">
                <label class="field-label">{{'ZIP_CODE' | translate}}&nbsp;<span class="span_star">*</span></label>
                <input type="text" formControlName="zip_code" name="zip_code" placeholder="{{'ZIP_CODE' | translate}}" required>
              </div>
              <div
          *ngIf="signUpForm.get('address').controls['zip_code'].invalid && uAttributes.submitted"
          class="custom_error">
          <div *ngIf="signUpForm.get('address').controls['zip_code'].errors.required"> !! zip_code is
            required !!</div>
        </div>
            </div>
          </div>


          <div class="hfp-input-div" formGroupName="data">
            <label>{{'CONTACT' | translate}} {{'NAME' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" formControlName="contact" name="contact" placeholder="{{'CONTACT' | translate}}" />
          </div>
          <div
            *ngIf="signUpForm.get('data').controls['contact'].invalid && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['contact'].errors.required"> !!Contact Name Is required !!
            </div>
            <!-- <div *ngIf="signUpForm.get('data').controls['contact'].errors.minlength"> Contact Name must be of two
              letters </div>
            <div *ngIf="signUpForm.get('data').controls['contact'].errors.maxlength"> Contact Name is too Large
            </div> -->
          </div>

          <!-- <div class="hfp-input-div" formGroupName="data">
            <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" placeholder="Enter Phone Number" formControlName="phone_number"
              [value]="signUpForm.get('data').controls['phone_number'].value | usNumberMask"
              placeholder="{{'PHONE_NUMBER' | translate}}" required />
          </div> -->

<div class="sign-up-fields" formGroupName="data">
            <label class="field-label">Phone Number&nbsp;<span class="span_star">*</span></label>
            <app-phone-number 
              [phoneNumberFormControlName]="'phone_number'"
              [phoneNumberFormGroup]="profileDataForm.phone_number.parent"
            ></app-phone-number>
          </div>
          <div
            *ngIf="profileDataForm.phone_number.invalid &&  uAttributes.submitted"
            class="custom_error">
            <div *ngIf="profileDataForm.phone_number.errors.required"> {{
              'REQUIRED_MSG_WITH_PARAMS' |
              translate: {'name': 'Phone Number' | translate}
              }} </div>
            <div *ngIf="profileDataForm.phone_number.errors.invalidNumber">{{translatorJson.NOT_A_VALID_PHONE |
              translate}}</div>
          </div>
          <!-- <div class="hfp-input-div" formGroupName="data">
            <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" placeholder="Enter Phone Number" formControlName="phone_number"
              [value]="signUpForm.get('data').controls['phone_number'].value"
              placeholder="{{'PHONE_NUMBER' | translate}}" required />
          </div>
          <div
            *ngIf="signUpForm.get('data').controls['phone_number'].invalid && (signUpForm.get('data').controls['phone_number'].dirty || uAttributes.submitted)"
            class="custom_error">
            <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.required"> {{'REQUIRED' | translate}}
            </div>
            <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.maxlength">
              {{'NUMBER_LENGTH10' | translate}} </div>
            <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.pattern"> {{'ONLY_NUMBER' | translate}}
            </div>
          </div> -->


          <!-- <div class="hfp-input-div">
            <label class="field-label">{{'INDUSTRY' | translate}}</label>
          </div> -->

          <!-- TODO :- Hiding the are you medical exhibitor -->

          <!-- <div class="hfp-input-div">
            <label class="field-label">{{'ARE_YOU_MEDICAL_EXHIBITOR' | translate }}</label>
            <div class="switch-field" formGroupName="data">
              <div class="check-btntype" *ngFor="let item of medicalExhibitor; let idx = index;">
                <input type="radio" id="switch_left{{idx}}" formControlName="medical" name="medical"
                  value="{{item.value}}" (change)="onRadioBoxChange(item,$event)" checked />
                <label for="switch_left{{idx}}">{{item.name}}</label>
              </div>
            </div>
          </div>

          <div class="hfp-input-div" formGroupName="data">
            <label class="field-label">{{'INSURANCE_PLANS' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" class="input-field" placeholder="Insurance Plans" formControlName="insurance"
              name="insurance" required>
          </div>
          <div
          *ngIf="signUpForm.get('data').controls['insurance'].invalid && (signUpForm.get('data').controls['insurance'].dirty || uAttributes.submitted)"
          class="custom_error">
          <div *ngIf="signUpForm.get('data').controls['insurance'].errors.required"> !! Insurance  is
            required !!</div>
        </div>

        <!-- TODO :- hiding the industry section -->

        <!-- <div class="event-input" *ngIf="industriesList.length > 0 && showInd ==1">

            <div class="hfp-input-div">
              <label>Industry&nbsp;<span class="span_star">*</span></label>

              <ng-select  [clearable]="true" [items]="industriesList" bindLabel="name" bindValue="id"
                [addTag]="addCustomUser" [multiple]="true"
                [searchFn]="searchTaxo"
                [closeOnSelect]="false"
                placeholder="Select Industry" formControlName="industry" searchable="enable">
              </ng-select>
            </div>

            <div *ngIf="signUpForm.controls['industry'].invalid && ( signUpForm.controls['industry'].dirty
                                                                                  || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.controls['industry'].errors.required">!! Industry is required !!
              </div>
            </div>
          </div> -->
          <div  class="event-input" >
            <!-- {{this.signUpForm.value | json}} -->


            <div class="hfp-input-div">
              <label class="field-label">{{'SERVICES_OFFERED' | translate}}&nbsp;<span class="span_star">*</span></label>
              <ng-select [closeOnSelect]="false" [items]="screeningParentList" bindLabel="formattedName" bindValue="id"
              [addTag]="addCustomUser" [multiple]="true"
              [searchFn]="searchTaxo"
                placeholder="Select multiple screening" formControlName="screening">
              </ng-select>     
              <div *ngIf="signUpForm.controls['screening'].invalid && ( signUpForm.controls['screening'].dirty
                            || uAttributes.submitted)" class="custom_error">
                <div *ngIf="signUpForm.controls['screening'].errors.required">Services is required
                </div>
              </div>
            </div>
              <div class="screening-events-list-signup-top">
                  <div class="form-check">
                    <input type="checkbox" class="css-checkbox" name="screenRequired" [checked]="signUpForm.value.screenRequired"
                     id="checka" formControlName="screenRequired">
                    <label for="checka" class="css-label">
                        Only show events that request my service(s)
                    </label>


                  </div>
                </div>
          </div>


          <!-- <div class="hfp-input-div" formGroupName="data">
            <label class="field-label">{{'WHERE_DO_YOU_WANT_TO_ATTEND_EVENTS' | translate}}</label>
            <textarea cols="5" rows="5" class="input-field" formControlName="attendevent" name="attendevent"></textarea>
          </div>

          <div class="hfp-input-div" formGroupName="data">
            <label class="field-label">{{'INFO_AT_HEALTH_FAIRS' | translate}}</label>
            <textarea cols="5" rows="5" class="input-field" formControlName="infoFair" name="infoFair"></textarea>
          </div>

          <div class="hfp-input-div" formGroupName="data">
            <label class="field-label">{{'SEMINAR_OFFERED' | translate}}</label>
            <textarea cols="5" rows="5" class="input-field" formControlName="offered" name="offered"></textarea>
          </div> -->

          <div class="hfp-input-div reminders">
            <label class="field-label">{{'DEFAULT_REMINDERS' | translate}}</label>
            <div class="form-check" *ngFor="let list of defaultValue">
              <input type="checkbox" class="css-checkbox" name="reminder" [checked]="true" id="{{list.id}}"
                (change)="onCheckboxChange(list,$event)">
              <label for="{{list.id}}" class="css-label">
                {{list.value}}
              </label>
            </div>
          </div>


          <div class="hfp-input-div">
            <label>{{'EMAIL' | translate}}&nbsp;<span class="span_star">*</span></label>
            <input type="text" formControlName="email" name="email" readonly placeholder="{{'EMAIL' | translate}}" />
          </div>
          <div
            *ngIf="signUpForm.controls['email'].invalid && uAttributes.submitted"
            class="custom_error">
            <div *ngIf="signUpForm.controls['email'].errors.required">!! Email is required !! </div>
            <div *ngIf="signUpForm.controls['email'].errors.email">Email must be valid Pattern </div>
          </div>

          <div class="hfp-input-div" formGroupName="data">
            <label class="field-label">{{'WEBSITE' | translate}}</label>
            <input type="text" formControlName="website" name="website" placeholder="{{'WEBSITE' | translate}}" />
          </div>


          <!-- Region Map-->
          <div class="hfp-input-div">
            <div class="sign-up-fields">
              <label class="field-label">{{'WATCH_LIST' | translate}}</label>
              <input class="input-field map-icon" id="region_id" name="regions" #search class="input-field" type="text"
                (keydown.enter)="$event.preventDefault()" autocorrect="off" (click)="onSearchAddressForRegion($event)"
                autocapitalize="off" spellcheck="off">
            </div>
            <!--todo validation-->
          </div>


          <div class="signup-map-box">
            <agm-map style="height : 200px" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
              <agm-marker  *ngFor="let region of  uAttributes.itemArray.region;let idx=index;" [latitude]="region.lat"
                [longitude]="region.long" [markerDraggable]="false"></agm-marker>
              <agm-circle *ngFor="let region of  uAttributes.itemArray.region;let idx=index;" [latitude]="region.lat"
                [longitude]="region.long" [radius]="region.centerRadius?region.centerRadius:centerRadius" [fillColor]="'blue'" [circleDraggable]="false"
                [editable]="true" (radiusChange)="centerChanged($event,idx)">
              </agm-circle>
            </agm-map>
          </div>

          <div class="event-input">
            <div class="map-dropdown-list">
              <ul>
                <li *ngFor="let region of uAttributes.itemArray.region;let idx=index;">
                  <span><a href="javascript:void(0)">Region: {{region.name}}, {{region.radius}} mile radius</a>
                  </span><span>Miles - {{region.centerRadius*0.00062137  | number : '1.2-2'}} miles</span>
                  <div class="map-row-btn">
                    <a href="javascript:void(0)" (click)="remvoeCircle()"><i class="map-delete-icon"></i> Delete </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="buttons">
            <input type="submit" value="{{'SUBMIT' | translate}}" class="btn btn-primary" [disabled]="flag">
            <button type="button" class="btn btn-default" (click)="resetForm()">Reset</button>
          </div>
        </form>
      </div>
    </div>


    <div class="clear"></div>
  </div>
</ng-template>

<!--Exibitor User Edit Temp end here -->


<ng-template #rgpaymentTemp>
  <div class="my-account-right">
    <div class="registration-payment-profile">
       <h2>Registration Payment</h2>
       <div class="registration-payment-profile-box">
         <div class="event-pay-page">
           <div class="clearfix">
             <div id="event-step-b-1" style="display: block;">
              <div class="row">
               <div class="col-lg-4 col-md-4 col-sm-4">
                 <div class="event-pay-left">
                   <div class="pay-event-step">
                     <ul>
                       <li><a class="active" href="#">1</a></li>
                       <li><a class="" href="#">2</a></li>

                     </ul>
                   </div>
                   <i><img src="images/credit-card-information-icon.png" alt=""></i>
                   <h5>Credit card Information</h5>
                 </div>
               </div>
               <div class="col-lg-8 col-md-8 col-sm-8">
                 <div class="event-pay-right">
                   <h2>Credit Card Information</h2>
                   <p>Aceepted payment method visa, mastercard, american express, discover</p>

                   <div class="payment-for-event">
                     <form>

                       <div class="hfp-input-div">
                         <label>Card Number</label>
                         <input class="card-nmr" type="text" value="5645-6554-5896-2586" placeholder="Card Number">
                       </div>

                       <div class="hfp-input-div">
                         <label>Card Holder’s Name</label>
                         <input type="text" value="Jhon Doe" placeholder="Card Holder’s Name">
                       </div>


                       <div class="clearfix row exhibitors-row-2">
                         <div class="col-sm-6">
                           <div class="hfp-input-div">
                             <label>Exp. Month</label>
                             <input type="text" value="546" placeholder="Exp. Month">
                           </div>
                         </div>
                         <div class="col-sm-3">
                           <div class="hfp-input-div">
                             <label>Exp. Year</label>
                             <input type="text" value="546" placeholder="Year">
                           </div>
                         </div>
                         <div class="col-sm-3">
                           <div class="hfp-input-div">
                             <label>CVV</label>
                             <input type="text" value="546" placeholder="CVV">
                           </div>
                         </div>
                       </div>




                       <div class="event-buttons clearfix">

                         <a href="javascript:void(0)" id="next-b-1" class="btn btn-default next-btn">Next <i><img src="images/next-icon.png" alt=""></i></a>

                       </div>

                     </form>
                   </div>

                 </div>
               </div>
              </div>
             </div>

             <div id="event-step-b-2" style="display: none;">
               <div class="row">
               <div class="col-lg-4 col-md-4 col-sm-4">
                 <div class="event-pay-left">
                   <div class="pay-event-step">
                     <ul>
                       <li><a class="step-done" href="#">1</a></li>
                       <li><a class="active" href="#">2</a></li>

                     </ul>
                   </div>
                   <i><img src="images/customer-billing-information-icon.png" alt=""></i>
                   <h5>Customer Billing Information</h5>
                 </div>
               </div>
               <div class="col-lg-8 col-md-8 col-sm-8">
                 <div class="event-pay-right">
                   <h2>CUSTOMER BILLING INFORMATION</h2>

                   <div class="payment-for-event">
                     <form>

                       <div class="hfp-input-div">
                         <label>First Name</label>
                         <input class="" type="text" value="Jhon" placeholder="First Name">
                       </div>

                       <div class="hfp-input-div">
                         <label>First Name</label>
                         <input class="" type="text" value="Doe" placeholder="First Name">
                       </div>

                       <div class="hfp-input-div">
                         <label>Company</label>
                         <input class="" type="text" value="Health Fairs Plus" placeholder="Company">
                       </div>

                       <div class="hfp-input-div">
                         <label>Address</label>
                         <textarea class="address-txt" placeholder="5501 Merchants View Square"></textarea>
                       </div>

                       <div class="hfp-input-div">
                         <label>City</label>
                         <input class="" type="text" value="Haymarket" placeholder="City">
                       </div>

                       <div class="clearfix row exhibitors-row-2">
                         <div class="col-sm-6">
                           <div class="hfp-input-div">
                             <label>State/Province</label>
                             <select class="hfp-selectbox"><option>VA</option></select>
                           </div>
                         </div>
                         <div class="col-sm-6">
                           <div class="hfp-input-div">
                             <label>Zip Code</label>
                             <input type="text" value="20169" placeholder="Zip Code">
                           </div>
                         </div>

                       </div>


                       <div class="hfp-input-div">
                         <label>Country</label>
                         <input class="" type="text" value="USA" placeholder="Country">
                       </div>


                       <div class="clearfix row exhibitors-row-2">
                         <div class="col-sm-6">
                           <div class="hfp-input-div">
                             <label>Phone</label>
                             <input type="text" value="707-855-1652" placeholder="Phone">
                           </div>
                         </div>
                         <div class="col-sm-6">
                           <div class="hfp-input-div">
                             <label>Fax</label>
                             <input type="text" value="707-855-1652" placeholder="Zip Code">
                           </div>
                         </div>

                       </div>

                       <div class="hfp-input-div">
                         <label>Email</label>
                         <input class="" type="text" value="healthfairsplus1@gmail.com" placeholder="Email">
                       </div>






                       <div class="event-buttons clearfix">
                         <a href="javascript:void(0)" id="pre-b-2" class="btn btn-default pre-btn"><img src="images/prv-icon.png" alt=""> Previous <i></i></a>
                         <a href="javascript:void(0)" id="next-b-3" class="btn btn-default next-btn">Submit </a>

                       </div>

                     </form>
                   </div>

                 </div>
               </div>
               </div>
             </div>

             <!-- <div id="event-step-b-3" style="display: none;">
               <div class="row">
               <div class="col-lg-4 col-md-4 col-sm-4">
                 <div class="event-pay-left">
                   <div class="pay-event-step">
                     <ul>
                       <li><a class="step-done" href="#">1</a></li>
                       <li><a class="step-done" href="#">2</a></li>
                       <li><a class="active" href="#">3</a></li>

                     </ul>
                   </div>
                   <i><img src="images/order-information-icon.png" alt=""></i>
                   <h5>Order Information</h5>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ullamcorper eros id felis porta, a volutpat purus bibendum.</p>
                 </div>
               </div>
               <div class="col-lg-8 col-md-8 col-sm-8">
                 <div class="event-pay-right">
                   <h2>ORDER INFORMATION</h2>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam metus leo.</p>

                   <div class="payment-for-event">
                     <form>

                       <div class="hfp-input-div">
                         <label>Description</label>
                         <textarea placeholder="Description"></textarea>
                       </div>




                       <div class="event-buttons clearfix">
                         <a href="javascript:void(0)" id="pre-b-3" class="btn btn-default pre-btn"><img src="images/prv-icon.png" alt=""> Previous <i></i></a>
                         <a href="javascript:void(0)" id="next-b-3" class="btn btn-default next-btn">Submit </a>

                       </div>

                     </form>
                   </div>

                 </div>
               </div>
               </div>
             </div> -->
           </div>
         </div>

       </div>
    </div>
  </div>

</ng-template>
