import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleInputLocationDirective } from './directives/google-input-location.directive';
import { FormGroupDirective } from '@angular/forms';



@NgModule({
  declarations: [GoogleInputLocationDirective],
  imports: [
    CommonModule
  ],
  exports: [GoogleInputLocationDirective],
  providers: [FormGroupDirective]
})
export class LocationModule { }
