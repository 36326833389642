import { Component, OnInit, AfterViewInit, ElementRef, Injector } from '@angular/core';
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { UnregisteredExhibitorsService } from '../unregistered-exhibitors/unregistered-exhibitors.service';
import { EventService } from '../event/event.service';
import { AppComponent } from '../app.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import * as _ from 'lodash';
import { Globals } from '../globals';
import { NotifierService } from 'angular-notifier';
import { ExportService } from '../event-detail/export.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileService } from '../profile/profile.service';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { SystemConstants } from '../constants/system.constants';
import { saveAs } from 'file-saver';
import { CommonStatus } from '../constants/common-status.constants';

@Component({
  selector: 'app-view-exhibitors',
  templateUrl: './view-exhibitors.component.html',
  styleUrls: ['./view-exhibitors.component.scss'],
  providers: [DatePipe]
})
export class ViewExhibitorsComponent implements OnInit {

  eventSlug = '';
  tempSlug = '';
  userData: any;
  loginUserRole: any = null;
  tempEventData: any;
  previusStatus: number = 0;
  localEvents: boolean = false;
  zoom = 4;
  screeningList: any = [];
  indusList: any = [];
  private notifier: NotifierService;
  isShowRoute = false;
  exhibListAttributes: any = {};
  exhibSearchForm: FormGroup;
  contactForm: FormGroup;
  customers: any = [];
  modalRef: BsModalRef;
  // taxoData: any = [];
  screeningSelectedData: any = [];

  datePipeString: string;
  exhibitorEventReqDocSrc: string;
  exhibitorEventDocument: any;
  selectedStatus:number
  seeMoreScreeningIndex: number = -1;
  selectedEventId:string
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taxonomyService: TaxonomyService,
    private appComponent: AppComponent,
    private eventService: EventService,
    private notifierService: NotifierService,
    private fb: FormBuilder,
    public globals: Globals,
    private exportService: ExportService,
    private modalService: BsModalService,
    private injector: Injector,
    private profileService: ProfileService,
    private datePipe: DatePipe,
    private unregisteredExhibitorsService: UnregisteredExhibitorsService,

  ) {
    this.notifier = notifierService;
    this.route.params.subscribe((params) => {
      if (params['slug']) {
        this.tempSlug = this.router.url.split("/").length == 4 ? this.router.url.split("/")[2] : '';
        this.eventSlug = params['slug'];

        this.eventSlug = decodeURIComponent(this.eventSlug);
        this.selectedEventId = this.authService.getSelectedEventId()
      } else {
        this.tempSlug = this.router.url.split("/").length == 4 ? this.router.url.split("/")[2] : '';
        this.eventSlug = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);
        this.eventSlug = decodeURIComponent(this.eventSlug);
        this.selectedEventId = this.authService.getSelectedEventId()
      }
    });
  }

  ngOnInit() {
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   if (this.eventSlug) {
    //     this.getLocalEventData(this.eventSlug);
    //   }
    //   this.getExhibitorListForDownLoad(this.eventSlug, 2);
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        this.selectedStatus = this.authService.getExhibitorListStatus();
        if(this.loginUserRole === 20){
          return this.router.navigateByUrl('/event');
        }
        if (this.eventSlug) {
          this.getLocalEventData(this.eventSlug);
        }
        this.getExhibitorListForDownLoad(this.eventSlug, 2);
      }else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });
    // This is exhibitor seach form
    this.exhibSearchForm = this.fb.group({
      searchField: ['', []]
    });

    // Copy object
    this.exhibListAttributes = Object.assign({}, this.globals.adminPaginationAttrs);


    this.contactForm = this.fb.group({
      eventName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
      subject: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
      emailId: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  getLocalEventData(slug: string): any {
    this.getLocalEventBySlug(slug).subscribe((res) => {
      if (res && res.length > 0) {
        this.tempEventData = res[0];
        this.getEventExhibitorListAndCounts();
      }
    }, err => console.log(err));
  }


  // Open model box to contact for exhibitor
  openRequestPopUp(content: any, data: any): any {
    this.exhibListAttributes.itemArray = data;
    this.modalRef = this.modalService.show(content, Object.assign({}, { class: 'modal-lg contact-to-exhibitor-pop' }));
    if (this.contactForm.controls['emailId']) {
      this.contactForm.controls['emailId'].setValue(this.userData.email);
    }
  }

  /**
   * This function used to submit the contact form.
   */
  addMessage(): void {
    if (this.contactForm.valid) {
      const contactObj: any = this.contactForm.value;
      let mszObj: any = {};
      mszObj.receiverEmail = this.exhibListAttributes.itemArray.email;
      mszObj.senderEmail = contactObj.emailId;
      let mszOf: any = {
        text: '<p>' + contactObj.eventName + '</P>' + '<pre>' + contactObj.message + '<pre>',
        email: contactObj.emailId
      };
      mszObj.mszSenderUpdateStatus = 0;
      mszObj.mszReceiverUpdteStatus = 1;
      mszObj.status = 1;
      mszObj.subject = contactObj.subject;
      mszObj.msz = [mszOf];
      mszObj.senderId = this.userData.id;
      mszObj.receiverId = this.exhibListAttributes.itemArray.id;
      if (this.exhibListAttributes.itemArray) {
        this.sendMszToProfiles(mszObj);
      }
    }
  }
  /**
 * This function is used to send message
 */
  sendMszToProfiles(qObj: any) {
    this.profileService.addProfileMessage(qObj).subscribe(res => {
      this.notifier.notify('success', 'Message send Successful.');
      this.closPopup();
      this.contactForm.reset();
    }, err => {
      if (err && err.error && err.error.error && err.error.error.message) {
        this.notifier.notify('error', err.error.error.message);
      }
    });
  }

  closPopup(): void {
    const localModalRef = this.modalRef;
    localModalRef.hide();
  }

  // to get event deatil for preview
  getLocalEventBySlug(slug: any): any {
    return new Observable((observer) => {
      let where = {
        filter: {
          where: {
            status: CommonStatus.ACTIVE,
            permalink: slug
          },
          order: 'created DESC'
        }
      };
      this.eventService.getLocalEventList(where).subscribe((res) => {
        observer.next(res);
      }, (err) => observer.next(err));
    });
  }



  // Get Exhibitor list for Event
  getExhibitorList(limitNum: number, skipNum: number): void {
    const queryObj: any =
    {
      filter:
      {
        where: { localEventId: this.selectedEventId },
        order: 'created DESC', limit: Number(limitNum), skip: Number(skipNum)
      }
    };

    // This statement for exhibitor list
    if (this.exhibSearchForm && this.exhibSearchForm.value && this.exhibSearchForm.value.searchField) {
      queryObj.filter.where.firstName = this.exhibSearchForm.value.searchField;
    }
    if(this.selectedStatus !== undefined) queryObj.filter.where.status = this.selectedStatus;

    this.eventService.getEventExibitorList(queryObj)
      .subscribe(data => {
        const res = (data && data.length) ? data : [];
        if (res && res.length > 0) {
          this.exhibListAttributes.pageItems = res;
        } else {
          this.exhibListAttributes.pageItems = [];
        }
      }, (err) => { });
  }
// Get Exhibitor list for Event
  getExhibitorListForDownLoad(eventSlugs: string, download: number,eventType?:string): void {
    const queryObj: any = { filter: { where: { eventSlug: eventSlugs , status:CommonStatus.ACTIVE } } };
    this.eventService.getEventExibitorList(queryObj)
      .subscribe(data => {
        const res = (data && data.length) ? data : [];
        if (res && res.length > 0) {
          // this.exhibListAttributes.pageItems = res;
          let arrays = res;
          this.customers = [];
          for (var value of arrays) {
            const firstName: string = value.eventReqData.hasOwnProperty('firstName') ? value.eventReqData.firstName : "";
            const lastName: string = value.eventReqData.hasOwnProperty('lastName') ? value.eventReqData.lastName : "";
            const name: string = firstName + " " +lastName;
            const phone: string = value.eventReqData.hasOwnProperty('phone') ? value.eventReqData.phone : "";
            const electricity: string = value.eventReqData.hasOwnProperty('electricity') && value.eventReqData.electricity ? "Yes" : "No";
            const numberOfChairs: string = value.eventReqData.hasOwnProperty('numberOfChairs') ? value.eventReqData.numberOfChairs : "0";
            const numberOfTable: string = value.eventReqData.hasOwnProperty('numberOfTable') ? value.eventReqData.numberOfTable : "0";
            // const raffle:string = value.eventReqData.hasOwnProperty("raffleItem")? value.eventReqData.raffle :"";
            const additionalNeeds:string = value.eventReqData.hasOwnProperty("additionalNeeds")?value.eventReqData.additionalNeeds :"";
            //Todo : commented because they are not required now to show in excel 
            // const address: string = value.hasOwnProperty('address.formatted_address') ? value.address.formatted_address : "";
            // const eventType: string = value.eventReqData.hasOwnProperty('eventType') ? value.eventReqData.eventType : "";
            // const email: string = value.eventReqData.hasOwnProperty('email') ? value.eventReqData.email : "";
            // const contactName: string = value.eventReqData.hasOwnProperty('contactName') ? value.eventReqData.contactName : "";
            // const paymentSpecified: string = value.eventReqData.hasOwnProperty('paymentSpecified') && value.eventReqData.paymentSpecified == 1 ? "Done" : "Not Done";
            var attendee = "";
            let eventStatus: string;
            if (value.eventReqData.attendees && value.eventReqData.attendees.length) {
              for (var i = 0; i < value.eventReqData.attendees.length; i++) {
                var valueAttendee = value.eventReqData.attendees[i];
                attendee += (i === 0 ? "Name: " : ", ") + valueAttendee.name;
              }
            }
            

            // todo : the services stored in array format it will fecth the services and show in services variable if there will no services it will show none
            let services="";
            if(value.eventReqData.screening && value.eventReqData.screening.length) {
              for (var i = 0; i < value.eventReqData.screening.length; i++) {
                var service = value.eventReqData.screening[i];
                services += service.name;
                if (i < value.eventReqData.screening.length - 1) {
                  services += ",";
                }
              }
            }else{
              services="none"
            }



            switch (value.eventReqStatus) {
              case 0:
                eventStatus = "Pending";
                break;
              case 1:
                eventStatus = "Approved";
                break;
              case 2:
                eventStatus = "Declined";
                break;
              default:
                eventStatus = "Pending";
            }

            
            //todo : when status will be approved then only it will push or show in excel file
            if(eventStatus=="Approved"  && eventType === SystemConstants.ONSITE){
              this.customers.push({
                'Exhibitor Name': name,
                'Exhibitor number': phone,
                'Attendee Names': attendee,
                'Service Offered': services,
                'Tables': numberOfTable,
                'Chairs': numberOfChairs,
                'Electricity': electricity,
                // "Raffle":raffle,
                "Additional Needs":additionalNeeds,
                //Todo : commented because they are not required to show in excel 
                // 'Address': address,
                // 'EventType': eventType,
                // 'Email': email,
                // 'ContactName': contactName,
                // "Payment Specified": paymentSpecified,
                // 'Request Status': eventStatus,
              });
            }else{
              this.customers.push({
                'Exhibitor Name': name,
                'Exhibitor number': phone,
                'Attendee Names': attendee,
                'Service Offered': services,
                "Additional Needs":additionalNeeds,
                //Todo : commented because they are not required to show in excel 
                // 'Address': address,
                // 'EventType': eventType,
                // 'Email': email,
                // 'ContactName': contactName,
                // "Payment Specified": paymentSpecified,
                // 'Request Status': eventStatus,
              });

            }
            

          }
          // console.log("aaaaaaaaaaaaaa")
          // console.log(this.customers);
          // console.log("bbbbbbbbbbbbbbbbbb");
          if (download == 1) {
            this.exportService.exportExcel(this.customers, 'customers');
          }

        }
      }, (err) => { });
  }

  acceptInvitationOfEvent(eventRequest:any, userDataVal: any): void {
    // console.log("accept",userDataVal);
    // return
      const queryObj: any = { status: 1, approveStatus: 1 };
      let timeZone = this.getTimeZone();
      queryObj.timeZone = timeZone
      queryObj.reminder =  eventRequest.reminder
      queryObj.localEventId = eventRequest.localEventId
      queryObj.attendees = eventRequest.attendees
      this.eventService.updateEventReqById(eventRequest.id, queryObj)
      .subscribe(data => {
        this.getEventReqById(eventRequest.id);
        //email code start

        let temEmail: any = {};
        temEmail.userId = userDataVal.id;
        //
        temEmail.slug = 'corporate-event-accept';
        let url = environment.sentEmailHost + "/event-detail/" + this.tempEventData.permalink;
        //  temEmail.subject = 'Your Event Approved';
        temEmail.keys = {
          EVENT: this.tempEventData.eventName, 
          USERNAME: userDataVal.username,
          CORPORATE_USER_NAME: this.userData.username,
          EVENT_DATE: this.datePipe.transform(this.tempEventData.eventDate, 'MMM d, y'),
          EVENT_ADDRESS: this.tempEventData.eventAddress.location ? this.tempEventData.eventAddress.location : this.tempEventData.eventAddress.formatted_address,
          EVENT_START_TIME: this.datePipe.transform(this.tempEventData.startDateTime, 'h:mm a'),
          EVENT_END_TIME: this.datePipe.transform(this.tempEventData.endDateTime, 'h:mm a'),
          CONTACT: this.tempEventData.onsiteContact,
          PARKING_INST: this.tempEventData.parkingInstructions,
          CHECK_IN: this.tempEventData.checkIn,
          EVENT_URL: `<a href=${url} >Click Here</a>`,
          COMPANY_NAME:this.tempEventData.company,
          EMAIL: this.userData.email,
          PHONE_NUMBER: this.tempEventData.phone,
          TIME_ZONE: this.tempEventData && this.tempEventData.timeZone && this.tempEventData.timeZone.label ? this.tempEventData.timeZone.label : 'NA'
        };
        // console.log("======================================>");
        // console.log(temEmail);
        this.emailSends(temEmail);
        ///email code ends
      }, (err) => { });
    /* */

  }
  declineInvitationOfEvent(id: string, userDataVal: any, oldStatus: number): void {
    this.previusStatus = oldStatus;
    const queryObjs: any = {
      filter: {
        where: {
          email: userDataVal.email
        }
      }
    };
    const queryObj: any = { status: 2 };
    this.eventService.updateEventReqById(id, queryObj)
      .subscribe(data => {

        this.getEventReqById(id);

        //email code start 
        let temEmail: any = {};
        temEmail.userId = userDataVal.id;
        //

        this.unregisteredExhibitorsService.findUnregisteredList(queryObjs)
          .subscribe(invitationList => {
            //corporate-event-declined-unregister-exhibitor
            // console.log(invitationList);

            if (oldStatus == 1) {
              temEmail.slug = 'corporate-cancelled-to-exhibitor';
            }
            else {
              if (invitationList && invitationList.length) {
                temEmail.slug = 'corporate-event-declined-unregister-exhibitor';
              } else {
                temEmail.slug = 'corporate-event-declined';
              }
            }



            //  temEmail.subject = 'Your Event Approved';
            temEmail.keys = {
              EVENT: this.tempEventData.eventName, USERNAME: userDataVal.username,
              CORPORATE_USER_NAME: this.userData.username,
              EVENT_DATE: this.datePipe.transform(this.tempEventData.eventDate, 'MMM d, y'),
              EVENT_ADDRESS: this.tempEventData.eventAddress.location ? this.tempEventData.eventAddress.location : this.tempEventData.eventAddress.formatted_address,
              EVENT_START_TIME: this.datePipe.transform(this.tempEventData.startDateTime, 'h:mm a'),
              EVENT_END_TIME: this.datePipe.transform(this.tempEventData.endDateTime, 'h:mm a'),
              COMPANY_NAME:this.tempEventData.company,
              TIME_ZONE: this.tempEventData && this.tempEventData.timeZone && this.tempEventData.timeZone.label ? this.tempEventData.timeZone.label : 'NA'
            };
            this.emailSends(temEmail);

            // this.totalCountCalculation();
          }, (err) => {
          });
        ///email code ends
      }, (err) => { });  /**/
  }

  emailSends(sendObj: any) {
    this.eventService.sendEmail(sendObj)
      .subscribe(data => {
        // console.log('email send success');
        console.log(data);
      }, (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      });
  }

  getEventReqById(id: string): void {
    this.eventService.getEventRequestById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.exhibListAttributes.pageItems.findIndex((x) => String(x.eventReqData.id) === String(id));
        if (getIndex >= 0) {
          this.exhibListAttributes.pageItems[getIndex].eventReqData = Object.assign({}, cuData);
          this.exhibListAttributes.pageItems[getIndex].eventReqStatus = cuData.status;
        }
      }, (err) => { });
  }

  // multi request for Corporate User only
  getEventExhibitorListAndCounts(): void {
    forkJoin([this.getExhibitorList(this.exhibListAttributes.limits, this.exhibListAttributes.skips),
    this.getExhibitorReqCounts()]);
  }

  // to count of events
  getExhibitorReqCounts(): void {
    const queryObj: any = {
      filter: {
        where: {
          eventSlug: this.eventSlug
        }
      }
    };

    // This statement for exhibitor list
    if (this.exhibSearchForm && this.exhibSearchForm.value && this.exhibSearchForm.value.searchField) {
      queryObj.filter.where.firstName = this.exhibSearchForm.value.searchField;
    }
    if(this.selectedStatus !== undefined) queryObj.filter.where.status = this.selectedStatus;
    this.eventService.getEventExibitorListCount(queryObj)
      .subscribe(data => {
        this.exhibListAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => { });
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getExhibitorList(this.exhibListAttributes.limits, startItem);
  }

  getTaxonomyData(taxoId: any, ScreenId: any) {
    //  console.log("I am in..", taxoId);
    forkJoin([
      this.taxonomyService.getTaxonomyList({ filter: { where: { status: 1, id: taxoId, } } }),
      this.taxonomyService.getTaxonomyList({ filter: { where: { status: 1, id: ScreenId, } } })
    ]).subscribe(results => {
      // this.taxoData = results[0] ? results[0] : [];
      this.screeningSelectedData = results[1] ? results[1] : [];

    }, (err) => { });

    // this.taxoData = this.taxonomyService.getTaxonomyList({ filter: { where: { status: 1, id: { inq: taxoId } } } });
  }

  downloadPfd(pdfName: string) {
    this.eventService.getPdf(pdfName).subscribe(
      (response) => {
        const base64String = this.arrayBufferToBase64(response.pdfData.data);
        const blob = this.base64ToBlob(base64String, 'application/pdf');
        saveAs(blob, response.pdfName);
      }
    );
  }
  arrayBufferToBase64(buffer: ArrayBuffer): string {
    const binary = Array.prototype.map.call(new Uint8Array(buffer), (byte: number) => String.fromCharCode(byte)).join('');
      return btoa(binary);
  }
  base64ToBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: mimeType });
  }
  getTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  }

  seeMoreScreening(index:number){
    this.seeMoreScreeningIndex = index;
  }
}
