<div id="mainContainer" [ngClass]="{'menu-slide': myVarTest}">
  <ngx-loading-bar color="#55d2ff" [includeSpinner]="false" height="3px"></ngx-loading-bar>
  <notifier-container class="notifier_box"></notifier-container>
  <app-header></app-header>
 <!-- <div class="mid-container">
    <router-outlet></router-outlet>
  </div> -->
  <div class="mid-container">
    <div *ngFor="let f of fold" >
      <div  appFrontFactory
            [component]="f.foldType.frontComponent | getcomponent "
            foldData="{{f.data}}"
            userData="{{userData}}">
      </div>

     </div>



     <app-page-not-found *ngIf="isPageNotFound"></app-page-not-found>


    </div>

  <app-footer ></app-footer>
</div>
