<div class="login-width">
  <div class="login-in">
    <div class="login-left">
      <div class="login-img"><img src="/assets/images/login-left.jpg" /></div>

    </div>
    <div class="login-right">
      <h2>Password Reset</h2>

      <div class="rset_password_in" *ngIf="allowToReset">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordSubmit()">

          <div class="login-input password-input">
            <div class="password-icon-login"></div>
            <div class="password-hide-icon" (click)="toggleShow()"><i class="fa fa-eye" aria-hidden="true"></i></div>
            <input type="{{type}}" placeholder=" {{'PASSWORD' | translate}}" value="" class="login-field"
              formControlName="password" name="password" required>   
            <!-- <div
              *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || submitted)"
              class="custom_error">
              <div *ngIf="resetPasswordForm.controls['password'].errors.required">Password is required. </div>
              <div *ngIf="resetPasswordForm.controls['password'].errors.minlength">Password must be at least 6
                characters.
              
              </div>
             
            </div> -->
            <div *ngIf="sf.password.invalid && (sf.password.dirty)" class="custom_error">
              <div *ngIf="sf.password.errors.required ">Password is required. </div>
              <div *ngIf="sf.password.errors.minlength">Password must be at least 6 characters.
              </div>
              <!-- <div *ngIf="!sf.password.errors.minlength && sf.password.errors.pattern">
                Paasword must contain at least one L_case,U_case,special Symbol, Or Number </div> -->
            </div>
          

          </div>
      


          <div class="login-input password-input">
            <div class="password-icon-login"></div>
            <input formControlName="cPassword" placeholder="Confirm password" type="password" class="login-field">
            <div
              *ngIf="resetPasswordForm.controls['cPassword'].invalid && (resetPasswordForm.controls['cPassword'].dirty || submitted)"
              class="custom_error">
              <div *ngIf="resetPasswordForm.controls['cPassword'].errors.required">Confirm password is required. </div>
            </div>
            <div
              *ngIf="resetPasswordForm.errors?.misMatch && (resetPasswordForm.controls['cPassword'].dirty || submitted)"
              class="custom_error">
              <div *ngIf="resetPasswordForm.errors?.misMatch">Password does not match. </div>
            </div>
          </div>


          <div class="login-submit"><button type="submit">Reset Password</button></div>
        </form>
      </div>
      <div class="rset_password_in" *ngIf="!allowToReset">
        <h3>Your reset token has been expired!</h3>
      </div>
    </div>
  </div>
</div>
