<div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i>
          </div>

          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img *ngIf="userData.image.type != 'avatar'" width="100%"
            [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))"
            alt="" />

        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>{{userSlug == 'corporate-user' ? 'Total Corporate' : 'Total Exhibitor' | translate}}
          {{totalCorporateUsers}}</h1>
      </div>
      <div class="heading_page_type">
        <ul>
          <li>
            <a (click)="resetSearchForm(1);userActiveTab=1;getUserList(1,10,0);"
              [ngClass]="{'active': memberStatus == 1 }"> <i><img src="/assets/images/sm-paid-icon.png" alt="" /></i>
              <div class="paymet-status-right"> <span>{{approved}}</span> {{'APPROVED'| translate }} </div>
            </a>
          </li>
          <li>
            <a (click)="resetSearchForm(0);userActiveTab=0; getUserList(0, 10,0);"
              [ngClass]="{'active': memberStatus == 0 }"> <i><img src="/assets/images/sm-peding-icon.png" alt="" /></i>
              <div class="paymet-status-right"><span>{{pending}}</span> {{'PENDING'| translate }}</div>
            </a>
          </li>
          <li>
            <a (click)="resetSearchForm(2);userActiveTab=2;getUserList(2,10,0);"
              [ngClass]="{'active': memberStatus == 2 }"> <i><img src="/assets/images/sm-declined-icon.png"
                  alt="" /></i>
              <div class="paymet-status-right"> <span>{{decline}}</span> {{'DECLINED'| translate }} </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!--  Search section start from here-->
<div class="main-admin-search corporate-admin-search">
  <div class="container clearfix main__filter__row">
    <div class="main__filter__tag">
      <div class="active-tag-box">
        <i class="active-tag-box-icon"><img
            src="/assets/images/{{memberStatus == 1 ? 'sm-paid-icon.png' : memberStatus == 0 ? 'sm-peding-icon.png' : 'sm-declined-icon.png'}}"
            alt="" /></i>
        <div class="active-tag-box-txt"> {{memberStatus == 1 ? 'approved' : memberStatus == 0 ? 'pending' : 'declined'}}
        </div>
      </div>
    </div>
    <div class="pagefilter__section">
      <form [formGroup]="userSearchForm" (ngSubmit)="userSearch();">
        <div class="pagefilter__section__in">

          <div class="pagefilter-search-box">
            <input type="text" value="" placeholder="{{userSlug == 'corporate-user' ? 'Search Corporate' : 'Search Exhibitor' | translate}}" formControlName="username" (keyup)="userSearch()" />
          </div>
          <!-- <div class="pagefilter-search-date">
        <input class="date-icon" type="text" value="" placeholder="Date Filter" />
      </div> -->
          <div class="pagefilter-search-date">

            <input class="date-icon" type="text" name="createdDate" displayMonths="1" [maxDate]="maxDateCorpSearch"
              *ngIf="loginUserRole == 0" bsDaterangepicker placeholder="mm-dd-yyyy" formControlName="createdDate">
            <input class="date-icon" type="text" name="createdDate" *ngIf="loginUserRole == 10 || loginUserRole == 20"
              bsDatepicker placeholder="mm-dd-yyyy" formControlName="createdDate">
            <!-- value="{{ userSearchForm.value.username | date }}" [minDate]="minStartDate" -->
          </div>
          <div class="pagefilter-search-button">
            <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
          </div>
        </div>
      </form>
    </div>


  </div>
</div>
<!--  Search section end from here-->

<div class="show_search_filter_data" *ngIf="loginUserRole == 0 && exhibitorSearch == 1">
  <div class="container">
    <div class="s_r">Search Result : <strong>
        <span *ngIf="userActiveTab == 1">{{approvedSearch}}</span>
        <span *ngIf="userActiveTab == 0">{{pendingSearch}}</span>
        <span *ngIf="userActiveTab == 2">{{declinesearch}}</span>
      </strong></div>
    <div class="s_r_t">

      <ul>
        <li *ngIf="userSearchForm.value.createdDate "><span>{{userSearchForm.value.createdDate[0] | date}} -
            {{userSearchForm.value.createdDate[1] | date}}</span>

          <a href="javascript:void(0)" (click)="removeEventDate()"></a>
        </li>
        <li *ngIf="userSearchForm.value.username "><span>{{this.userSearchForm.value.username}}</span>
          <a href="javascript:void(0)" (click)="removeEventName()"></a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!--User Listing section start from here -->

<div class="corporate-users-pg">
  <!--User corporate Listing section start from here -->
  <div class="container" *ngIf="userSlug == 'corporate-user'">
    <div *ngIf="!uAttributes.pageItems.length" class="search-not-found">{{'NO_RECORD_FOUND' | translate}}</div>
    <div class="corporate-users-list" *ngFor="let user of uAttributes.pageItems;  let idx = index">

      <div class="row_corporate_users" [hidden]="(editIndex == idx || uAttributes.isIndex == idx)">
        <div class="row-flex">
          <div class="corporate_users_left_info">
            <div class="corporate_users_img">


              <img *ngIf="user.image.type == 'avatar'" src="/assets/images/avatars/{{user.image.name}}" />
              <img *ngIf="user.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)"
                [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + user.image.name) | encodeUriComponent))"
                alt="" />


            </div>
            <div class="corporate_users_text">
              <h3>{{user.city}}</h3>
              <p>{{ user && user.data && user.data.company_name}}<br>{{user.email}}</p>
              <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                  (click)="getMember(idx);uAttributes.isIndex =idx;">{{'VIEW_DETAIL' | translate}}</a>
              </div>

            </div>
          </div>
          <div class="about_corporate_users">
            <ul>
              <li>
                <div class="super-admin-icon">
                  <a (click)="masqueradeViewBySuperAdmin(user.email)">
                    <img src="/assets/images/super-admin-icon.png" width="100%" alt="" />
                  </a>
                </div>
              </li>
              <li>
                <strong>{{user.data.number_employee}}</strong> {{'NUMBER_OF_EMPLOYEES' | translate}}
              </li>
              <li>
                <strong>{{user.eventCounts}}</strong> {{'EVENT_POST' | translate}}
              </li>
            </ul>

          </div>
          <div class="corporate_users_action">
            <div *ngIf="user.status == 1 " class="bold_txt">
              <i><img src="/assets/images/sm-paid-icon.png" alt=""></i>{{'APPROVED'| translate }}
              <span></span>
            </div>
            <div *ngIf="user.status == 0" class="bold_txt">
              <i><img src="/assets/images/sm-peding-icon.png" alt=""></i>Pending
              <span></span>
            </div>
            <div *ngIf="user.status == 2" class="bold_txt">
              <i><img src="/assets/images/sm-declined-icon.png" alt=""></i>Declined
              <span></span>
            </div>

            <div class="user_reg_date"> {{user.created | date}}</div>
            <div class="exhibitors-dropdown " >
              <div class="action dropdown" dropdown> <a class="dropdown-toggle" dropdownToggle
                  aria-controls="dropdown-support-menu">Action </a>
                <ul class="dropdown-menu" *dropdownMenu id="dropdown-support-menu">
                  <li class="event-edit-icon"><a
                      (click)="isEdit=!isEdit;getMemberDetail(idx);editIndex =idx; dropDown(idx)"
                      href="javascript:void(0)">{{'EDIT'| translate }}</a></li>
                  <li [hidden]="user.status == 2 " class="event-declined-icon"><a href="javascript:void(0)"
                      (click)="userOperation(idx,2,user.username,user.id);dropDown(idx);dropIndex=idx">{{'Decline'|
                      translate }}</a></li>
                  <li class="event-password-icon"><a href="javascript:void(0)"
                      (click)="openChangePasswordModal(passwordTemplate, user.username, user.id);dropDown(idx)">{{'Change
                      Password'|
                      translate }}</a></li>
                      <li  class="event-declined-icon"><a href="javascript:void(0)"
                        (click)="userOperation(idx,4,user.username,user.id);dropDown(idx);dropIndex=idx">{{'DELETE'| translate }}</a></li>
                  <li [hidden]="user.status == 1 " class="event-clone-icon"><a href="javascript:void(0)"
                      (click)="userOperation(idx,1,user.username,user.id);dropDown(idx);dropIndex=idx">Approve</a></li>
                  <!--<li class="event-clone-icon"><a href="#">Approve</a></li>-->
                </ul>
              </div>
            </div>

          </div>

        </div>
      </div>


      <!--ng template show-->
      <div class="corporate-users-list-b" *ngIf="editIndex ==idx">
        <div *ngIf="editIndex ==idx; then updateMember"></div>
      </div>

      <div class="corporate-users-list-b" *ngIf="uAttributes.isIndex ==idx">
        <div *ngIf="uAttributes.isIndex ==idx; then memberDetailForm"></div>
      </div>

    </div>

    <div class="pagination__box top-margin20" *ngIf="uAttributes.count > 10">

      <pagination [totalItems]="uAttributes.count" [rotate]="uAttributes.rotate" [maxSize]="uAttributes.maxSize"
        [itemsPerPage]="uAttributes.limits" (pageChanged)="pageChanged($event);" [(ngModel)]="currentPage">
      </pagination>
    </div>

  </div>

  <!--User corporate Listing section end from here -->

  <!-- {{uAttributes.pageItems | json}} -->
  <!--User Exibitor Listing section start from here  -->
  <div class="container vvvv" *ngIf="userSlug == 'exhibitors'">
    <div *ngIf="uAttributes.pageItems && !uAttributes.pageItems.length" class="search-not-found">
      {{'NO_RECORD_FOUND' | translate}}</div>
    <div class="exhibitors-row-list" *ngFor="let user of uAttributes.pageItems;  let idx = index">
      <div id="editexhibitorstop" [hidden]="(editIndex == idx || uAttributes.isIndex == idx)"
        class="row_corporate_users clearfix">
        <div class="row-flex">
          <div class="corporate_users_left_info">
            <div class="corporate_users_img">
              <img *ngIf="user.image && user.image.type == 'avatar' "
                src="/assets/images/avatars/{{user.image.name}}" />

              <img *ngIf="user.image && user.image.type != 'avatar' && user.image.name != ''" width="100%"
                (error)="handleImgError($event, item)"
                [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + user.image.name) | encodeUriComponent))"
                alt="" />

              <img *ngIf="user.image && user.image.type != 'avatar' && user.image.name == ''"
                src="/assets/images/avatars/{{user.image.name}}" />

            </div>
            <div class="corporate_users_text">
              <h3>{{user && user.data && user.data.company_name}}
                <!-- <span><img src="/assets/images/review-star.png" alt=""></span> -->
                <div class="for-payment">
                  <span *ngIf="user.unregister == 1">
                    Unregister Exhibitors
                  </span>

                </div>
              </h3>

              <div class="exhibitors-tags">
                <ul>
                  <li *ngFor="let industry of user.ind | slice:0:2; let i=index">{{industry.name}}</li>
                </ul>
              </div>
              <div class="admin-exhibitors-review">{{user.email}}</div>
              <div class="exhibitors-detail-link"><a id="view-ex-detail" href="javascript:void(0)"
                  (click)="getMember(idx);uAttributes.isIndex =idx;getScreeningTaxo()">{{'VIEW_DETAIL' | translate}}</a> </div>
            </div>
          </div>
          <div class="about_corporate_users">

            <div *ngIf="user.status == 1">
              <div class="admin-exhibitors-stue-ul">
                <ul>
                  <li>
                    <div class="super-admin-icon">
                      <a (click)="masqueradeViewBySuperAdmin(user.email)"
                      href="javascript:void(0)">
                        <img
                          src="/assets/images/super-admin-icon.png" width="100%" alt="">
                        </a>
                      </div>
                  </li>
                  <li><strong>{{user.accepted}}</strong>Accepted</li>
                  <li><strong>{{user.pending}}</strong>Pending</li>
                  <li><strong>{{user.declined}}</strong>Decline</li>
                </ul>
              </div>
              <div class="totalrequests">{{user.eventReqCounts}} Total Requests</div>
            </div>
          </div>
          <div class="corporate_users_action">
            <div *ngIf="user.status == 1 " class="bold_txt">
              <i><img src="/assets/images/sm-paid-icon.png" alt=""></i>{{'APPROVED'| translate }}
              <span></span>
            </div>
            <div *ngIf="user.status == 0" class="bold_txt">
              <i><img src="/assets/images/sm-peding-icon.png" alt=""></i>Pending
              <span></span>
            </div>
            <div *ngIf="user.status == 2" class="bold_txt">
              <i><img src="/assets/images/sm-declined-icon.png" alt=""></i>Decline
              <span></span>
            </div>

            <div class="user_reg_date"> {{user.created | date}}</div>

            <div class="exhibitors-dropdown">
              
                <div class="action dropdown" dropdown> <a class="dropdown-toggle" dropdownToggle
                    aria-controls="dropdown-support-menu">Action </a>
                  <ul class="dropdown-menu" *dropdownMenu id="dropdown-support-menu">
                    <li class="event-edit-icon"><a
                        (click)="isEdit=!isEdit;getMemberDetail(idx);editIndex =idx; dropDown(idx)"
                        href="javascript:void(0)">{{'EDIT'| translate }}</a></li>
                    <li [hidden]="user.status == 2 " class="event-declined-icon"><a href="javascript:void(0)"
                        (click)="userOperation(idx,2,user.username,user.id);dropDown(idx);dropIndex=idx">{{'Decline'|
                        translate }}</a></li>
                    <li  class="event-declined-icon"><a href="javascript:void(0)"
                          (click)="userOperation(idx,4,user.username,user.id);dropDown(idx);dropIndex=idx">{{'DELETE'| translate }}</a></li>
                    <li class="event-password-icon"><a href="javascript:void(0)"
                        (click)="openChangePasswordModal(passwordTemplate, user.username, user.id);dropDown(idx)">{{'Change
                        Password'|
                        translate }}</a></li>
                    <li [hidden]="user.status == 1 " class="event-clone-icon"><a href="javascript:void(0)"
                        (click)="userOperation(idx,1,user.username,user.id);dropDown(idx);dropIndex=idx">{{'APPROVE'|
                        translate }}</a></li>
                    <!-- <li class="event-clone-icon"><a href="#">Approve</a></li> -->

                  </ul>
                </div>
             
            </div>

          </div>
        </div>
      </div>
      <div class="corporate-users-list-b" *ngIf="editIndex ==idx">
        <div *ngIf="editIndex ==idx && userRole == 10; then updateMember"></div>
        <div *ngIf="editIndex ==idx && userRole == 20; then exhibitorEdit"></div>

      </div>

      <div class="corporate-users-list-b" *ngIf="uAttributes.isIndex ==idx">
        <div *ngIf="uAttributes.isIndex ==idx; then memberDetailForm"></div>
        <!--   <div *ngIf="isIndex ==idx && userRole == 20; then exhibitor" ></div>-->
      </div>
    </div>

    <div class="pagination__box top-margin20" *ngIf="uAttributes.count > 10" >
      <pagination [totalItems]="uAttributes.count" [rotate]="uAttributes.rotate" [maxSize]="uAttributes.maxSize"
        [itemsPerPage]="uAttributes.limits" (pageChanged)="pageChanged($event);"  [(ngModel)]="currentPage"></pagination>
    </div>
  </div>
  <!-- User Exibitor Listing section end from here-->
</div>
<!--User Listing section end from here -->

<!--User Detail template Start here -->
<ng-template #memberDetailForm>
  <div class="form-close"><button id="paymtclose-2" (click)="uAttributes.isIndex = -1;dropIndex = -1"><img
        src="/assets/images/add-form-close.png" alt=""></button></div>

  <div [ngClass]="{'exhibitors-list-row-detail': userRole==20,'corporate-users-list-a': userRole == 10 }"
    id="corporate-users-details-b-1">

    <!--<div class="form-close"><button id="paymtclose-3"><img src="/assets/images/add-form-close.png" alt=""></button></div>-->
    <div class="corporate-step-1">
      <div class="lmd-sm-4 pull-right">
        <div class="corporate-users-list-bg">

          <div class="users-list-bg-details" *ngIf="uAttributes.itemArray && uAttributes.itemArray.image">
            <i class="right-user-img"><img src="/assets/images/exhibitors-right-icon.png" alt="" />
            </i>
            <h5 *ngIf="userRole==20">Exhibitor Information</h5>
            <h5 *ngIf="userRole==10">Corporate Company Information</h5>
            <p *ngIf="userRole==10">Corporate Company Information here</p>
            <p *ngIf="userRole==20">Exhibitor information is provided by the exhibitors and not guaranteed by Health
              Fairs Plus. We highly recommend that you view the websites of all exhibitors and reach out to any
              exhibitor direct should you have any questions or concerns.</p>
          </div>
        </div>

      </div>
      <div class="lmd-sm-8 pull-left">
        <div class="corporate-users-step">
          <div class="corporate-users-s-l">
            <div class="c-u-l-img" *ngIf="uAttributes.itemArray && uAttributes.itemArray.image">
              <img *ngIf="uAttributes.itemArray.image.type == 'avatar'"
                src="/assets/images/avatars/{{uAttributes.itemArray.image.name}}" />
              <img *ngIf="uAttributes.itemArray.image.type != 'avatar'"
                src="/assets/images/users/{{uAttributes.itemArray.image.name}}" />
            </div>
            <div class="c-u-l-text">
              <h3>{{uAttributes.itemArray.city}}</h3>
              <p>{{uAttributes.itemArray.username}}<br>{{uAttributes.itemArray.email}}</p>
              <div class="exhibitors-detail-link aaa"><a id="hide-exhibitors" href="javascript:void(0)"
                  (click)="uAttributes.isIndex = -1;dropIndex = -1">{{'HIDE_DETAILS' | translate}}</a> </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="orporate-users-s-h">
            <!-- <h2>Atlantic Media Payment </h2> -->
          </div>

          <div class="corporate-step-form">
            <h3>{{'COMPANY_INFO' | translate}}</h3>

            <div class="view-exhibitor-box-list">
              <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole == 10 ? 'list-icon-1.png' : 'exhibitor-list-icon-1.png'}}"
                      alt=""></i> <strong class="">{{'LOCATION' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r" *ngIf="uAttributes.itemArray.address">
                  <h3>{{'LOCATION' | translate}}</h3>
                  <!-- {{uAttributes.itemArray | json}} -->
                  <p><span *ngIf="uAttributes.itemArray.address.addressOne">{{uAttributes.itemArray.address.addressOne}}</span>
                    <!-- <span *ngIf="uAttributes.itemArray.address.city">{{uAttributes.itemArray.address.city}}</span><span
                      *ngIf="uAttributes.itemArray.address.state">,{{uAttributes.itemArray.address.state}}</span><span
                      *ngIf="uAttributes.itemArray.address.zip_code">,{{uAttributes.itemArray.address.zip_code}}</span> -->
                  </p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-2.png' : 'exhibitor-list-icon-2.png'}}"
                      alt=""></i> <strong class="">{{'USER_NAME' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r">
                  <h3>{{'USER_NAME' | translate}}</h3>
                  <p>{{uAttributes.itemArray.username}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-2.png' : 'exhibitor-list-icon-2.png'}}"
                      alt=""></i> <strong class="">{{'NAME' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r">
                  <h3>{{'NAME' | translate}}</h3>
                  <p>{{uAttributes.itemArray.data.contact}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-3.png' : 'exhibitor-list-icon-11.png'}}"
                      alt=""></i> <strong class="">{{'PHONE' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r" *ngIf="uAttributes.itemArray.data">
                  <h3>{{'PHONE' | translate}}</h3>
                  <p>{{uAttributes.itemArray.data.phone_number}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-4.png' : 'exhibitor-list-icon-12.png'}}"
                      alt=""></i> <strong class="">{{'EMAIL' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r">
                  <h3>{{'EMAIL' | translate}}</h3>
                  <p>{{uAttributes.itemArray.email}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-5.png' : 'exhibitor-list-icon-13.png'}}"
                      alt=""></i> <strong class="">{{'WEBSITE' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r" *ngIf="uAttributes.itemArray.data">
                  <h3>{{'WEBSITE' | translate}}</h3>
                  <p>{{uAttributes.itemArray.data.website}}</p>
                </div>
              </div>
              <div *ngIf="userRole == 20">
                <!-- TODO:- hidning Industries we are not using this time -->

                <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                  <div class="view-exhibitor-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-17.png" alt=""></i>
                    <strong class="">{{'SERVICES_OFFERED' | translate}}</strong>
                  </div>
                  <div class="view-exhibitor-list-r">
                    <h3>{{'SERVICES_OFFERED' | translate}}
                    </h3>
                    <ul *ngIf="uAttributes && uAttributes.itemArray && uAttributes.itemArray.screening">
                      <li *ngFor="let service of uAttributes.itemArray.screening">{{ getServices(service) }}</li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                  <div class="view-exhibitor-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-15.png" alt=""></i>
                    <strong class="">Areas Exhibitor Attends Events</strong>
                  </div>
                  <div class="view-exhibitor-list-r" *ngIf="uAttributes.itemArray.data">
                    <h3>Areas Exhibitor Attends Events
                    </h3>
                    <p>{{uAttributes.itemArray.data.attendevent}}</p>
                  </div>
                </div> -->
                <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                  <div class="view-exhibitor-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-15.png" alt=""></i>
                    <strong>Watched Regions</strong>
                  </div>
                  <div class="view-exhibitor-list-r">
                    <h3>Watched Regions
                    </h3>
                    <ul>
                      <li *ngFor="let watch of uAttributes.itemArray.region">{{watch.name}}</li>
                    <!-- <li>Canada</li>
                    <li>Delaware</li>
                    <li>Georigia</li> -->

                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div class="clear"></div>
    </div>
  </div>

</ng-template>
<!--USer Detail template end here -->
<!--Corporate User Edit Tempalte Start Fronm here -->
<!--ng template form-->
<ng-template #updateMember>
  <div class="form-close"><button id="paymtclose-1" (click)="editIndex = -1; dropIndex = -1"><img
        src="/assets/images/add-form-close.png" alt=""></button></div>
  <div class="corporate-step-1" id="corporate-step-3">
    <div class="lmd-sm-4 pull-right">
      <div class="corporate-users-list-bg">
        <div class="users-list-bg-details">
          <i *ngIf="uAttributes.itemArray && uAttributes.itemArray.image"><img
              src="/assets/images/exhibitors-right-icon.png" alt="" />
          </i>
          <h5>Corporate Company Information</h5>
          <p>Corporate Company Information here</p>
        </div>
      </div>

    </div>
    <div class="lmd-sm-8 pull-left">
      <div class="corporate-users-step">
        <div class="corporate-users-s-l">
          <div class="c-u-l-img" *ngIf="uAttributes.itemArray && uAttributes.itemArray.image">
            <img src="/assets/images/avatars/{{uAttributes.itemArray.image.name}}" width="200" />
          </div>
          <div class="c-u-l-text">
            <h3>{{uAttributes.itemArray.city}}</h3>
            <p>{{uAttributes.itemArray.username}}<br>{{uAttributes.itemArray.email}}</p>
          </div>
          <div class="clear"></div>
        </div>
        <!-- <div class="orporate-users-s-h">
          <h2>{{'EDITING' | translate}}: Atlantic Media Payment <a href="#"><i class="fa fa-pencil" aria-hidden="true"></i></a></h2>
        </div> -->

        <div class="corporate-step-form">
          <h3>{{'COMPANY_INFO' | translate}}</h3>
          <form [formGroup]="signUpForm" (ngSubmit)="corporateUserUpdate(uAttributes.itemArray.id)" autocomplete="off">
            <div class="hfp-input-div">
              <label>{{'USER' | translate}} {{'NAME' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" placeholder="User Name" formControlName="username" name="username" />
            </div>
            <div
              *ngIf="signUpForm.controls['username'].invalid && (signUpForm.controls['username'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.controls['username'].errors.required">!! User Name is required !! </div>
              <div *ngIf="signUpForm.controls['username'].errors.minlength">User Name should have two Characters</div>
              <div *ngIf="signUpForm.controls['username'].errors.maxlength">User Name is too Large !!!</div>
            </div>

            <div class="hfp-input-div">
              <label>{{'ACCEPTED_TERMS' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" value="{{ uAttributes.itemArray.created | date }}" name="created" placeholder=""
                readonly />
            </div>

            <div class="hfp-input-div" formGroupName="data">
              <label>{{'COMPANY_NAME' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" placeholder="{{'COMPANY_NAME' | translate}}" formControlName="company_name"
                name="company_name" />
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['company_name'].invalid && (signUpForm.get('data').controls['company_name'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['company_name'].errors.required"> !! Company Name is required
                !!</div>
              <div *ngIf="signUpForm.get('data').controls['company_name'].errors.minlength"> Company Name must be of two
                letters </div>
              <div *ngIf="signUpForm.get('data').controls['company_name'].errors.maxlength"> Company Name is too Large
              </div>
            </div>

            <!-- <div class="hfp-input-div">
              <label>{{'COMPANY_PICTURE' | translate}}</label>
              <div class="editupload-picture" *ngIf="uAttributes.itemArray && uAttributes.itemArray.image">
                <img [src]="filePreviewUrl" *ngIf="filePreviewUrl" class="imgPlaceholder" width="200" />
                <img *ngIf="uAttributes.itemArray.image.type == 'avatar'" src="/assets/images/avatars/{{uAttributes.itemArray.image.name}}" width="200" />
                <img *ngIf="uAttributes.itemArray.image.type != 'avatar'" src="/assets/images/users/{{uAttributes.itemArray.image.name}}" width="200" />
                <div class="edit-upload-img"><input type="file" class="form-control" name="corporateUserImage">
                  <span>{{'EDIT_PICTURE' | translate}}</span></div>
              </div>
            </div> -->
            <div class="hfp-input-div">
              <label>{{'COMPANY_PICTURE' | translate}}&nbsp;<span class="span_star">*</span></label>
              <div class="editupload-picture" *ngIf="uAttributes.itemArray && uAttributes.itemArray.image">
                <img [src]="filePreviewUrl" *ngIf="filePreviewUrl" class="imgPlaceholder" width="200" />
                <img *ngIf="uAttributes.itemArray.image.type == 'avatar'"
                  src="/assets/images/avatars/{{uAttributes.itemArray.image.name}}" width="200" />
                <img *ngIf="uAttributes.itemArray.image.type != 'avatar'"
                  src="/assets/images/users/{{uAttributes.itemArray.image.name}}" width="200" />

                <div class="edit-upload-img" [ngClass]="{'uploadedimg': imgUrl && imgUrl !=''}">
                  <input type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)"
                    class="form-control" name="corporateUserImage">
                  <span>{{'EDIT_PICTURE' | translate}}</span>

                  <div class="editupload-picture" formArrayName="image" *ngIf="imgUrl && imgUrl !=''">
                    <img class="imgPlaceholder" src="/assets/images/users/{{imgUrl}}" alt="img" height="100%"
                      width="100%" />
                  </div>
                </div>

              </div>
              <!-- <div  class="editupload-picture" formArrayName="image" *ngIf="imgUrl && imgUrl !=''">
                <img class="imgPlaceholder" src="/assets/images/users/{{imgUrl}}" alt="img" height="100%" width="100%" />
              </div> -->
            </div>
            <div class="hfp-input-div" formGroupName="data">
              <label>{{'NUMBER_OF_EMPLOYEES' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" placeholder="{{'NUMBER_OF_EMPLOYEES' | translate}}" formControlName="number_employee"
                name="number_employee" required>
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['number_employee'].invalid && (signUpForm.get('data').controls['number_employee'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['number_employee'].errors.required"> !!Employee No. Is
                required !! </div>
              <div *ngIf="signUpForm.get('data').controls['number_employee'].errors.pattern"> !!Only Numbers is allowed
                !! </div>
            </div>


            <div class="hfp-input-div" formGroupName="address">
              <label>{{'ADDRESS1' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input id="autocomplete" type="text" (click)="onAddreessChange($event)"
                placeholder="{{'ADDRESS1' | translate}}" formControlName="addressOne" name="addressOne" myNoSpaces
                #search required />
            </div>

            <div
              *ngIf="signUpForm.get('address').controls['addressOne'].invalid && (signUpForm.get('address').controls['addressOne'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('address').controls['addressOne'].errors.required"> !!Address 1 Is required !!
              </div>

            </div>


            <div class="hfp-input-div" formGroupName="address">
              <label>{{'ADDRESS2' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="formatted_address" name="formatted_address"
                placeholder="{{'ADDRESS2' | translate}}" />
            </div>
            <div
              *ngIf="signUpForm.get('address').controls['formatted_address'].invalid && (signUpForm.get('address').controls['formatted_address'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('address').controls['formatted_address'].errors.required"> !!Address 2 Is
                required !! </div>

            </div>

            <div class="hfp-input-div" formGroupName="address">
              <label>{{'City' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" placeholder="{{'City' | translate}}" formControlName="city" name="city" required />
            </div>

            <div
              *ngIf="signUpForm.get('address').controls['city'].invalid && (signUpForm.get('address').controls['city'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('address').controls['city'].errors.required"> !!City Is required !! </div>

            </div>

            <div class="hfp-row">
              <div class="hfp-col-6">
                <div class="hfp-input-div" formGroupName="address">
                  <label>{{'STATE' | translate}}&nbsp;<span class="span_star">*</span></label>
                  <input type="text" placeholder="Enter state" formControlName="state" name="state" required>
                </div>
                <div
                  *ngIf="signUpForm.get('address').controls['state'].invalid && (signUpForm.get('address').controls['state'].dirty || uAttributes.submitted)"
                  class="custom_error">
                  <div *ngIf="signUpForm.get('address').controls['state'].errors.required"> !!state Is required !!
                  </div>

                </div>




              </div>


              <div class="hfp-col-6">
                <div class="hfp-input-div" formGroupName="address">
                  <label>{{'ZIP_CODE' | translate}}&nbsp;<span class="span_star">*</span></label>
                  <input type="text" formControlName="zip_code" name="zip_code" placeholder="{{'ZIP_CODE' | translate}}"
                    minlength="4" maxlength="12" required>
                </div>
                <div
                  *ngIf="signUpForm.get('address').controls['zip_code'].invalid && (signUpForm.get('address').controls['zip_code'].dirty || uAttributes.submitted)"
                  class="custom_error">
                  <div *ngIf="signUpForm.get('address').controls['zip_code'].errors.required"> !!ZIP_CODE Is required !!
                  </div>

                </div>

              </div>

            </div>


            <div class="hfp-input-div" formGroupName="data">
              <label>{{'CONTACT' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="contact" name="contact" placeholder="{{'CONTACT' | translate}}" />
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['contact'].invalid && (signUpForm.get('data').controls['contact'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['contact'].errors.required"> !!Contact Name Is required !!
              </div>
              <div *ngIf="signUpForm.get('data').controls['contact'].errors.minlength"> Contact Name must be of two
                letters </div>
              <div *ngIf="signUpForm.get('data').controls['contact'].errors.maxlength"> Contact Name is too Large</div>
            </div>

            <div class="hfp-input-div" formGroupName="data">
              <label>{{'JOB_TITLE' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="title" name="title" placeholder="{{'JOB_TITLE' | translate}}"
                required />
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['title'].invalid && (signUpForm.get('data').controls['title'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['title'].errors.required"> !! Your Job Title Is required
                !! </div>
              <div *ngIf="signUpForm.get('data').controls['title'].errors.minlength"> Job Title must be of two
                letters </div>
              <div *ngIf="signUpForm.get('data').controls['title'].errors.maxlength"> Job title is too Large </div>
            </div>
            <div class="hfp-input-div"  formGroupName="data">
              <div class="hfp-input-div">
                <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
                <app-phone-number [phoneNumberFormControlName]="'phone_number'"
                [phoneNumberFormGroup]="phone.parent"></app-phone-number>
              </div>
              <div *ngIf="phone.invalid && uAttributes.submitted" class="custom_error">
                <div *ngIf="phone.errors.required"> {{
                  'REQUIRED_MSG_WITH_PARAMS' |
                  translate: {'name': 'Phone Number' | translate}
                  }} </div>
                <div *ngIf="phone.errors.invalidNumber">{{translateJson.NOT_A_VALID_PHONE |
                  translate}}</div>
              </div>
              <!-- <div *ngIf="phone.invalid &&  uAttributes.submitted" class="custom_error">
                {{
                getValidationErrors({
                formControl: phone.phone_number,
                formControlName:translateJson.PHONE_NUMBER
                })
                }}
              </div> -->
              </div>

            <!-- <div class="hfp-input-div" formGroupName="data">
              <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" placeholder="Enter Phone Number" formControlName="phone_number"
                [value]="signUpForm.get('data').controls['phone_number'].value"
                placeholder="{{'PHONE_NUMBER' | translate}}" required />
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['phone_number'].invalid && (signUpForm.get('data').controls['phone_number'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.required"> {{'REQUIRED' | translate}}
              </div>
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.minlength">{{'NUMBER_LENGTH10' |
                translate}} letters </div>
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.maxlength">{{'NUMBER_LENGTH10' |
                translate}} </div>
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.pattern"> {{'ONLY_NUMBER' | translate}}
              </div>
            </div> -->

            <div class="hfp-input-div">
              <label>{{'EMAIL' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="email" name="email" placeholder="{{'EMAIL' | translate}}" />
            </div>
            <div
              *ngIf="signUpForm.controls['email'].invalid && (signUpForm.controls['email'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.controls['email'].errors.required">!! Email is required !! </div>
              <div *ngIf="signUpForm.controls['email'].errors.pattern">Email must be valid Pattern </div>
            </div>


            <div class="hfp-input-div" formGroupName="data">
              <label>{{'WEBSITE' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="website" name="website" placeholder="{{'WEBSITE' | translate}}" />
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['website'].invalid && (signUpForm.get('data').controls['website'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['website'].errors.required"> !!WEBSITE Is required !! </div>

            </div>


            <div class="buttons">
              <button class="btn btn-primary">{{"SUBMIT" | translate }} </button>

              <a href="javascript:void(0)" (click)="editIndex = -1; dropIndex = -1" id="reset1"
                class="btn btn-default">{{'CANCEL' | translate}}</a>
            </div>

          </form>

        </div>

      </div>

    </div>
    <div class="clear"></div>
  </div>
</ng-template>
<!--Corporate User Edit Temp end here -->


<!--Exibitor User Edit Temp Start from here -->
<ng-template #exhibitorEdit>
  <div class="form-close"><button (click)="resetFormData(editIndex); "><img src="/assets/images/add-form-close.png"
        alt=""></button></div>
  <div class="corporate-step-1">
    <div class="lmd-sm-4 pull-right">
      <div class="corporate-users-list-bg">
        <div class="users-list-bg-details" *ngIf="uAttributes.itemArray.image">
          <i>
            <!--<img *ngIf="uAttributes.itemArray.image.type == 'avatar'" src="/assets/images/avatars/{{uAttributes.itemArray.image.name}}" width="200" />
            <img *ngIf="uAttributes.itemArray.image.type != 'avatar'" src="/assets/images/users/{{uAttributes.itemArray.image.name}}" width="200" />-->
            <img src="/assets/images/exhibitors-right-icon.png" alt="" />
          </i>
          <h5>Exhibitor Information</h5>
          <p>Exhibitor information is provided by the exhibitors and not guaranteed by Health Fairs Plus. We highly
            recommend that you view the websites of all exhibitors and reach out to any exhibitor direct should you have
            any questions or concerns.</p>
        </div>
      </div>

    </div>
    <div class="lmd-sm-8 pull-left">
      <div class="corporate-users-step">
        <div class="corporate-users-s-l">
          <div class="c-u-l-img" *ngIf="uAttributes.itemArray.image">
            <img *ngIf="uAttributes.itemArray.image.type == 'avatar'"
              src="/assets/images/avatars/{{uAttributes.itemArray.image.name}}" width="200" />
            <img *ngIf="uAttributes.itemArray.image.type != 'avatar'"
              src="/assets/images/users/{{uAttributes.itemArray.image.name}}" width="200" />
          </div>
          <div class="c-u-l-text">
            <h3>{{uAttributes.itemArray.city}}</h3>
            <p>{{uAttributes.itemArray.username}}<br>{{uAttributes.itemArray.email}}</p>
          </div>
          <div class="clear"></div>
        </div>
        <!-- <div class="orporate-users-s-h">
          <h2>{{'EDITING' | translate}}: Atlantic Media Payment <a href="#"><i class="fa fa-pencil" aria-hidden="true"></i></a></h2>
        </div> -->

        <div class="corporate-step-form">
          <h3>{{'COMPANY_INFO' | translate}}</h3>
          <form [formGroup]="signUpForm" (ngSubmit)="exibitorUserUpdate(uAttributes.itemArray.id)" autocomplete="off">


            <div class="hfp-input-div" formGroupName="data">
              <label>Your Business Name (if you don't have one, please use your name)&nbsp;<span class="span_star">*</span></label>
              <input type="text" class="input-field" placeholder="Your Business Name"
                formControlName="company_name" name="company_name" required minlength="2" maxlength="200">
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['company_name'].invalid && (signUpForm.get('data').controls['company_name'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['company_name'].errors.required"> !! Business Name is required
                !!</div>
              <div *ngIf="signUpForm.get('data').controls['company_name'].errors.minlength"> Business Name must be of two
                letters </div>
              <div *ngIf="signUpForm.get('data').controls['company_name'].errors.maxlength"> Business Name is too Large
              </div>
            </div>

            <div class="hfp-input-div" formGroupName="address">
              <label class="field-label">{{'ADDRESS1' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input id="autocomplete" type="text" (click)="onAddreessChange($event)"
                placeholder="{{'ADDRESS1' | translate}}" formControlName="addressOne" name="addressOne" myNoSpaces
                #search (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off"
                spellcheck="off" />
            </div>
            <div
              *ngIf="signUpForm.get('address').controls['addressOne'].invalid && (signUpForm.get('address').controls['addressOne'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('address').controls['addressOne'].errors.required"> !! Address 1 is required !!
              </div>

            </div>

            <div class="hfp-input-div" formGroupName="address">
              <label class="field-label">{{'CUSTOM_ADDRESS' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="formatted_address" name="formatted_address"
                placeholder="{{'ADDRESS2' | translate}}" />
            </div>
            <div
              *ngIf="signUpForm.get('address').controls['formatted_address'].invalid && (signUpForm.get('address').controls['addressOne'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('address').controls['formatted_address'].errors.required"> !! Address 2 is
                required !!</div>

            </div>


            <div class="hfp-input-div" formGroupName="address">
              <label class="field-label">{{'CITY' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" placeholder="{{'CITY' | translate}}" formControlName="city" name="city" required
                minlength="2" />
            </div>
            <div
              *ngIf="signUpForm.get('address').controls['city'].invalid && (signUpForm.get('address').controls['city'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('address').controls['city'].errors.required"> !! City is required !!</div>


            </div>


            <div class="hfp-row clearfix">
              <div class="hfp-col-6">
                <div class="hfp-input-div" formGroupName="address">
                  <label class="field-label">{{'STATE' | translate}}&nbsp;<span class="span_star">*</span></label>
                  <input type="text" placeholder="Enter state" formControlName="state" name="state">
                </div>

                <div
                  *ngIf="signUpForm.get('address').controls['state'].invalid && (signUpForm.get('address').controls['state'].dirty || uAttributes.submitted)"
                  class="custom_error">
                  <div *ngIf="signUpForm.get('address').controls['state'].errors.required"> !! State is required !!
                  </div>
                </div>

              </div>


              <div class="hfp-col-6">
                <div class="hfp-input-div" formGroupName="address">
                  <label class="field-label">{{'ZIP_CODE' | translate}}&nbsp;<span class="span_star">*</span></label>
                  <input type="text" formControlName="zip_code" name="zip_code" placeholder="{{'ZIP_CODE' | translate}}"
                    required>
                </div>
                <div
                  *ngIf="signUpForm.get('address').controls['zip_code'].invalid && (signUpForm.get('address').controls['zip_code'].dirty || uAttributes.submitted)"
                  class="custom_error">
                  <div *ngIf="signUpForm.get('address').controls['zip_code'].errors.required"> !!ZIP_CODE Is required !!
                  </div>
                </div>
              </div>

            </div>


            <div class="hfp-input-div" formGroupName="data">
              <label>{{'CONTACT' | translate}} {{'NAME' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="contact" name="contact" placeholder="{{'CONTACT' | translate}}" />
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['contact'].invalid && (signUpForm.get('data').controls['contact'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['contact'].errors.required"> !!Contact Name Is required !!
              </div>
              <div *ngIf="signUpForm.get('data').controls['contact'].errors.minlength"> Contact Name must be of two
                letters </div>
              <div *ngIf="signUpForm.get('data').controls['contact'].errors.maxlength"> Contact Name is too Large</div>
            </div>

            <!-- <div class="hfp-input-div" formGroupName="data">
              <label  class="field-label">{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" class="input-field" placeholder="Enter Phone Number" formControlName="phone_number"
                [value]="signUpForm.get('data').controls['phone_number'].value"
                placeholder="{{'PHONE_NUMBER' | translate}}" required />
            </div>

            <div *ngIf="signUpForm.get('data').controls['phone_number'].invalid && (signUpForm.get('data').controls['phone_number'].dirty || uAttributes.submitted)" class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.required"> !!Phone  No. Is required !!</div>
              <div *ngIf="signUpForm.phone_number.errors.pattern"> Only Nos. is required </div>
            </div> -->

            <!-- <div class="hfp-input-div" formGroupName="data">
              <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" placeholder="Enter Phone Number" formControlName="phone_number"
                [value]="signUpForm.get('data').controls['phone_number'].value"
                placeholder="{{'PHONE_NUMBER' | translate}}" required />
            </div>
            <div
              *ngIf="signUpForm.get('data').controls['phone_number'].invalid && (signUpForm.get('data').controls['phone_number'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.required"> {{'REQUIRED' | translate}}
              </div>
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.minlength">{{'NUMBER_LENGTH10' |
                translate}} letters </div>
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.maxlength">{{'NUMBER_LENGTH10' |
                translate}} </div>
              <div *ngIf="signUpForm.get('data').controls['phone_number'].errors.pattern"> {{'ONLY_NUMBER' | translate}}
              </div>
            </div> -->
            <div class="hfp-input-div"  formGroupName="data">
              <div class="hfp-input-div">
                <label>{{'PHONE_NUMBER' | translate}}&nbsp;<span class="span_star">*</span></label>
                <app-phone-number [phoneNumberFormControlName]="'phone_number'"
                [phoneNumberFormGroup]="phone.parent"></app-phone-number>
              </div>
              <div *ngIf="phone.invalid && uAttributes.submitted" class="custom_error">
                <div *ngIf="phone.errors.required"> {{
                  'REQUIRED_MSG_WITH_PARAMS' |
                  translate: {'name': 'Phone Number' | translate}
                  }} </div>
                <div *ngIf="phone.errors.invalidNumber">{{translateJson.NOT_A_VALID_PHONE |
                  translate}}</div>
              </div>
              <!-- <div *ngIf="phone.invalid && uAttributes.submitted" class="custom_error">
                {{
                getValidationErrors({
                formControl: phone,
                formControlName : translateJson.PHONE_NUMBER 
                })
                }}
              </div> -->
            </div>


            <!-- <div class="hfp-input-div">
              <label class="field-label">{{'INDUSTRY' | translate}}</label>
            </div> -->

            <!-- TODO:- Hiding insurance -->

            <!-- <div class="hfp-input-div">
              <label class="field-label">{{'ARE_YOU_MEDICAL_EXHIBITOR' | translate }}</label>
              <div class="switch-field" formGroupName="data">
                <div class="check-btntype" *ngFor="let item of medicalExhibitor; let idx = index;">
                  <input type="radio" id="switch_left{{idx}}" formControlName="medical" name="medical"
                    value="{{item.value}}" (change)="onRadioBoxChange(item,$event)" checked />
                  <label for="switch_left{{idx}}">{{item.name}}</label>
                </div>
              </div>
            </div>
            <div class="hfp-input-div" *ngIf="isMedical" formGroupName="data">
              <label class="field-label">{{'INSURANCE_PLANS' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" class="input-field" placeholder="Insurance Plans" formControlName="insurance"
                name="insurance" required>
            </div>
            <div *ngIf="insuranceData.invalid && (insuranceData.dirty
             || uAttributes.submitted)" class="custom_error">
              <div *ngIf="insuranceData.errors.required"> !!Insurance Is required !!
              </div>

            </div> -->

            <!-- TODO:- hidning Industries we are not using this time -->

            <!-- <div class="event-input" *ngIf="showInd ==1">

              <div class="hfp-input-div">
                <label>Industry&nbsp;<span class="span_star">*</span></label>

                <ng-select [clearable]="true" [items]="industriesList" bindLabel="name" bindValue="id"
                  [addTag]="addCustomUser" [multiple]="true" [searchFn]="searchTaxo" [closeOnSelect]="false"
                  placeholder="Select Industry" formControlName="industry" searchable="enable">
                </ng-select>
              </div>

              <div *ngIf="signUpForm.controls['industry'].invalid && ( signUpForm.controls['industry'].dirty
                                                                                      || uAttributes.submitted)"
                class="custom_error">
                <div *ngIf="signUpForm.controls['industry'].errors.required">!! Industry is required !!
                </div>
              </div>
            </div> -->
            <div class="sign-up-fields screening-events-list-signup">
              <!-- {{this.signUpForm.value | json}} -->

              <div class="screening-events-list-signup-btm">
                <label>{{'SERVICES_OFFERED' | translate}}&nbsp;<span class="span_star">*</span></label>
                <ng-select [closeOnSelect]="false" [items]="screeningParentList" bindLabel="formattedName" bindValue="id"
                  [addTag]="addCustomUser" [multiple]="true" [searchFn]="searchTaxo"
                  placeholder="Select multiple screening" formControlName="screening">
                </ng-select>
                <div *ngIf="signUpForm.controls['screening'].invalid && ( signUpForm.controls['screening'].dirty
                  || uAttributes.submitted)"
                  class="custom_error">
                  <div *ngIf="signUpForm.controls['screening'].errors.required">Services is required
                </div>
</div>
              </div>

              <div class="screening-events-list-signup-top">
                <!-- {{uAttributes.itemArray | json}}  -->
                <div class="form-check">
                  <input type="checkbox" class="css-checkbox" name="screenRequired"
                    [checked]="uAttributes.itemArray.screenRequired" id="checka" formControlName="screenRequired">
                  <label for="checka" class="css-label">
                    Only show events that request my service(s)
                  </label>

                </div>
              </div>
            </div>




            <!-- <div class="hfp-input-div" formGroupName="data">
              <label class="field-label">{{'WHERE_DO_YOU_WANT_TO_ATTEND_EVENTS' | translate}}</label>
              <textarea cols="5" rows="5" class="input-field" formControlName="attendevent"
                name="attendevent"></textarea>
            </div> -->


            <!-- <div class="hfp-input-div" formGroupName="data">
              <label class="field-label">{{'INFO_AT_HEALTH_FAIRS' | translate}}</label>
              <textarea cols="5" rows="5" class="input-field" formControlName="infoFair" name="infoFair"></textarea>
            </div> -->

            <!-- <div class="hfp-input-div" formGroupName="data">
              <label class="field-label">{{'SEMINAR_OFFERED' | translate}}</label>
              <textarea cols="5" rows="5" class="input-field" formControlName="offered" name="offered"></textarea>
            </div> -->

            <div class="hfp-input-div reminders">
              <label class="field-label">{{'DEFAULT_REMINDERS' | translate}}</label>
              <div class="form-check" *ngFor="let list of defaultValue">
                <input type="checkbox" class="css-checkbox" name="reminder" [checked]="true" id="{{list.id}}"
                  (change)="onCheckboxChange(list,$event)">
                <label for="{{list.id}}" class="css-label">
                  {{list.value}}
                </label>
              </div>
            </div>


            <div class="hfp-input-div">
              <label>{{'EMAIL' | translate}}&nbsp;<span class="span_star">*</span></label>
              <input type="text" formControlName="email" name="email" placeholder="{{'EMAIL' | translate}}" />
            </div>
            <div
              *ngIf="signUpForm.controls['email'].invalid && (signUpForm.controls['email'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.controls['email'].errors.required">!! Email is required !! </div>
              <div *ngIf="signUpForm.controls['email'].errors.pattern">Email must be valid Pattern </div>
            </div>


            <div class="hfp-input-div" formGroupName="data">
              <label class="field-label">{{'WEBSITE' | translate}}</label>
              <input type="text" formControlName="website" name="website" placeholder="{{'WEBSITE' | translate}}"
                 />

            </div>
            <!-- <div
              *ngIf="signUpForm.get('data').controls['website'].invalid && (signUpForm.get('data').controls['website'].dirty || uAttributes.submitted)"
              class="custom_error">
              <div *ngIf="signUpForm.get('data').controls['website'].errors.required"> !!Website Is required !!</div>
            </div> -->

            <!-- Region Map-->
            <div class="hfp-input-div">
              <div class="sign-up-fields">
                <label class="field-label">{{'WATCH_LIST' | translate}}</label>
                <input class="input-field map-icon" id="region_id" name="regions" #search class="input-field" type="text"
                  (keydown.enter)="$event.preventDefault()" autocorrect="off" (click)="onSearchAddressForRegion($event)"
                  autocapitalize="off" spellcheck="off">
              </div>
              <!--todo validation-->
            </div>
  
  
            <div class="signup-map-box">
              <agm-map style="height : 200px" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                <agm-marker  *ngFor="let region of  uAttributes.itemArray.region;let idx=index;" [latitude]="region.lat"
                  [longitude]="region.long" [markerDraggable]="false"></agm-marker>
                <agm-circle *ngFor="let region of  uAttributes.itemArray.region;let idx=index;" [latitude]="region.lat"
                  [longitude]="region.long" [radius]="region.centerRadius?region.centerRadius:centerRadius" [fillColor]="'blue'" [circleDraggable]="false"
                  [editable]="true" (radiusChange)="centerChanged($event,idx)">
                </agm-circle>
              </agm-map>
            </div>
  
            <div class="event-input">
              <div class="map-dropdown-list">
                <ul>
                  <li *ngFor="let region of uAttributes.itemArray.region;let idx=index;">
                    <span><a href="javascript:void(0)">Region: {{region.name}}, {{region.radius}} mile radius</a>
                    </span><span>Miles - {{region.centerRadius*0.00062137  | number : '1.2-2'}} miles</span>
                    <div class="map-row-btn">
                      <a href="javascript:void(0)" (click)="remvoeCircle()"><i class="map-delete-icon"></i> Delete </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="buttons">
              <input type="submit" value="{{'SUBMIT' | translate}}" class="btn btn-primary" [disabled]="flag">
              <!-- <button type="button" class="btn btn-default" (click)="resetForm()">Reset</button> -->
              <a href="javascript:void(0)" (click)="editIndex = -1; dropIndex = -1" id="reset1"
                class="btn btn-default">{{'CANCEL' | translate}}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</ng-template>

<!--Exibitor User Edit Temp end here -->

<!---change password template-->
<ng-template #passwordTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Change Password</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)='changePasswordByAdmin()' [formGroup]='passwordForm'>
      <div class="row">
        <div class="hfp-input-div col-sm-12">
          <label>New Password &nbsp;<span class="span_star">*</span></label>
          <input class="" type="password" formControlName="nPassword" placeholder="New Password" autocomplete="on" />
          <div
            *ngIf="passwordForm.controls['nPassword'].invalid && (passwordForm.controls['nPassword'].dirty || submitted)"
            class="custom_error">
            <div *ngIf="passwordForm.controls['nPassword'].errors.required">New Password is required. </div>
            <div *ngIf="passwordForm.controls['nPassword'].errors.minlength">Password must be at least 6 characters.
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="hfp-input-div col-sm-12">
          <label>Confirm Password &nbsp;<span class="span_star">*</span></label>
          <input class="" type="password" formControlName="cPassword" placeholder="Confirm New Password"
            autocomplete="on" />
          <div
            *ngIf="passwordForm.controls['cPassword'].invalid && (passwordForm.controls['cPassword'].dirty || submitted)"
            class="custom_error">
            <div *ngIf="passwordForm.controls['cPassword'].errors.required">Confirm password is required. </div>
          </div>
          <div *ngIf="passwordForm.errors?.misMatch && (passwordForm.controls['cPassword'].dirty || submitted)"
            class="custom_error">
            <div *ngIf="passwordForm.errors?.misMatch">Password does not match. </div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>

    </form>
  </div>
</ng-template>