<div class="unregistered-event-details" *ngIf="eventReqItem && showFirstForm">
  <div  class="event-detail-hd no-top-hd event-step-preview no-user-details-hd"  [ngStyle]="{'background-image': 'url(assets/images/event/defaultEventImages/'+eventReqItem?.media[0]+')'}">
    <div class="event-bg-overly"></div>
    <div class="event-bg-overly-2"></div>
    <div class="container">
      <div class="main-heading-top clearfix">
        <div class="main-heading-right">
          <h1 *ngIf="eventReqItem.corpData">{{eventReqItem && eventReqItem.corpData && eventReqItem.corpData[0] && eventReqItem.corpData[0].data && eventReqItem.corpData[0].data.company_name}}</h1>
          <span>{{eventReqItem.eventName}}</span>

          <ul class="flex-ul justify-center-content">
            <li><i><img src="assets/images/event-date-icon.png" alt="" /></i>
              <span>{{eventReqItem.eventDate | date: 'MMMM d, yyyy'}} <br>  {{eventReqItem.startDateTime | date:'shortTime'}} - {{eventReqItem.endDateTime | date:'shortTime'}}</span>
            </li>
            <li><i><img src="assets/images/event-location-icon.png" alt="" /></i><span> {{eventReqItem.eventAddress.location}}<br *ngIf="eventReqItem.address2">{{eventReqItem.address2}}</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="event-detail-mid " *ngIf="eventReqItem">
    <div class="container">
      <div class="event-detail-mid-in corporate-detail-pg margin-top-m" [ngClass]="{'event-details-page-ex': userData.role == 20}">
        <div class="clearfix">
          <div class="hfp-col-8">
            <div class="event-d-mid-in-left">
              <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-1.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-1.png" alt="" />
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-1.png" alt="" />
                  </i>
                  <strong>Onsite Employees</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Onsite Employees</h3>
                  <p>{{eventReqItem.onsiteEmp}}</p>
                </div>
              </div>

              <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-2.png" alt=""></i>
                  <strong>Onsite Contact Name</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Contact Name</h3>
                  <p>{{eventReqItem && eventReqItem.corpData[0] && eventReqItem.corpData[0].data && eventReqItem.corpData[0].data.contact}}</p>
                </div>
              </div>


            <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-12.png" alt=""></i>
                    <strong>Email</strong>
                </div>
                <div class="event-detail-list-r">
                    <h3>Email Address
                    </h3>
                    <p>{{eventReqItem && eventReqItem.corpData[0] && eventReqItem.corpData[0].email}}</p>
                </div>
            </div>


              <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-2.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-2.png" alt="" />
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-2.png" alt="" />
                  </i>
                  <strong>Onsite Contact Name</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Onsite Contact Name</h3>
  
                  <p>{{eventReqItem.onsiteContact}}</p>
                </div>
              </div>
              <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-11.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-11.png" alt="" />
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-11.png" alt="" />
                  </i>
                  <strong>Phone Number</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Phone Number</h3>
                  <p>{{eventReqItem.phone}}</p>
                </div>
              </div>
              <!-- <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-2.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-2.png" alt="" />
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-2.png" alt="" />
                  </i>

                  <strong>Exhibitor Requests</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Category Request</h3>
                  <ul>

                    <li *ngFor="let industry of indusList">{{industry.name}}</li>
                  </ul>
                </div>
              </div> -->
              <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-3.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-3.png" alt="" />
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-3.png" alt="" />
                  </i>
                  <strong>Services Requested</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Services Requested</h3>
                  <!-- {{screeningParentList | json}} -->
                  <ul>
                    <li *ngFor="let screen of screeningParentList">  {{screen.name}}</li>
                  </ul>
                </div>
              </div>


              <!-- <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-6.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-6.png" alt="" />
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-6.png" alt="" />
                  </i>
                  <strong>Raffle Item</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Raffle Item</h3>
                  <p>{{eventReqItem.requestReffleItem}}</p>
                </div>
              </div> -->
              <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-7.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-7.png" alt="" />
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-7.png" alt="" />
                  </i>
                  <strong>Attendance Fee</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Attendance Fee</h3>
                  <p>No</p>
                </div>
              </div>
              <div class="event-detail-list clearfix" *ngIf="loginUserRole == 20">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-8.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-8.png" alt="" />
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-8.png" alt="" />
                  </i>
                  <strong>Invitation Request Status</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Invitation Request Status</h3>
                  <!-- {{eventRequestData | json}}
                  {{eventRequestData?.length | json}} -->
                  <p *ngIf="eventRequestData?.status == 0">Pending</p>
                  <p *ngIf="eventRequestData?.status == 1">Apporved</p>
                  <p *ngIf="!eventRequestData?.length">Not sent request</p>
                </div>
              </div>

              <!-- TODO:- Hiding the step4 details -->

              <!-- <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-9.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-9.png" alt="" />
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-9.png" alt="" />
                  </i>
                  <strong>Insurance Carriers</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Medical Insurance</h3>
                  <p>{{eventReqItem.medicalCarriers}}</p>

                  <h3>Dental Insurance</h3>
                  <p>{{eventReqItem.dentalCarries}}</p>

                  <h3>Vision Insurance</h3>
                  <p>{{eventReqItem.visionCarries}} </p>
                </div>
              </div> -->
              <div class="event-detail-list clearfix">
                <div class="event-detail-list-l">
                  <i>
                    <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-10.png" alt="" />
                    <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-10.png" alt="" />
                    <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-10.png" alt="" />
                  </i>
                  <strong>Event Details</strong>
                </div>
                <div class="event-detail-list-r">
                  <h3>Parking Instructions</h3>
                  <p>{{eventReqItem.parkingInstructions}}</p>

                  <h3>Check In/Security Instructions</h3>
                  <p>{{eventReqItem.checkIn}}</p>

                </div>

              </div>


            </div>
          </div>

          <!-- Exibitor List participate list start from here-->
          <div class="hfp-col-4" >
            <div class="corporate-event-mid-right">
              <div class="download-list-btn"><a (click)="showPaymentForm()" href="javascript:void(0)">Register</a></div>
               </div>
          </div>
          <!-- Exibitor List participate list end here-->
        </div>
      </div>

    </div>
  </div>


  <div class="event-map-section">
    <div class="event-map-section-top">
      <div class="container" *ngIf="eventReqItem && eventReqItem.eventAddress">
        <ul>
          <li><a href="javascript:void(0)" (click)="initMap();isShowRoute=true;"  id="open-direction-box">Driving Direction</a></li>
          <li class="li-map-icon">{{eventReqItem.eventAddress?.location}}</li>
        </ul>
      </div>
      <div class="direction-from-box" id="open-direction-box-1">
        <form>
          <div class="direction-input"><input placeholder="From Address" type="text" /></div>
          <div class="direction-btn"><button>Get Direction</button></div>
          <div class="direction-btn"><button>Get Route</button></div>
          <div class="direction-close"><button id="direction-close"><img src="assets/images/Direction-close.png" /></button></div>
        </form>
      </div>
    </div>

    <div class="event-map-section-btm">
        <div class="map-responsive" *ngIf="eventReqItem && eventReqItem.eventAddress">
        <agm-map style="height : 100%" [latitude]="eventReqItem.eventAddress?.lat" [longitude]="eventReqItem.eventAddress?.lng"
                 [zoom]="zoom">
          <agm-marker [latitude]="eventReqItem.eventAddress?.lat"
                      [longitude]="eventReqItem.eventAddress?.lng" [markerDraggable]="false"></agm-marker>
        </agm-map>
      </div>
    </div>
    <div class="direction-map-list">
      <div class="direction-map-add clearfix" *ngIf="isShowRoute==true">
        <div class="direction-map-add-txt"></div>
        <div class="direction-map-add-txt-close"><a href="#" (click)="isShowRoute=false;"> Close Route <i><img src="assets/images/close-icon.png" /></i></a></div>
      </div>
      <div class="direction-map-list-row">
        <div id="right-panel" *ngIf="isShowRoute==true" class="map-dic-list"></div>
      </div>
    </div>
  </div>



</div>

<div class="container unregistered-event-request-page" >
  <div class="clearfix row">
    <div class="hfp-col-12">
      <div class="form-pop-txt" >
      <!--<button type="button" class="popclose" data-dismiss="modal" aria-label="Close"><img src="images/pop-close.png" alt=""></button>
         -->
      <div class="exhibitor-in-pop clearfix">
        <div *ngIf="showPaymentFormForPublic == 1" class="hfp-row clearfix">
        <div class="my-account-right registration-payment-page">
          <!-- {{authorizeStep}} -->
            <div class="event-pay-page">
              <div id="event-step-b-1" *ngIf="authorizeStep === 'event-step-b-1'">

                <div class="clearfix">
                  <div class="hfp-col-4">
                    <div class="event-pay-left">
                      <div class="pay-event-step">
                        <ul>
                          <li><a class="active" href="#">1</a></li>
                          <li><a class="" href="#">2</a></li>
                          <li><a href="#">3</a></li>
                        </ul>
                      </div>
                      <i><img src="assets/images/credit-card-information-icon.png" alt=""></i>
                      <h5>Credit card Information</h5>
                    </div>
                  </div>
                  <div class="hfp-col-8 bg__ww">
                    <div class="event-pay-right">
                      <h2>Credit Card Information</h2>
                      <p>Aceepted payment method visa, mastercard, american express, discover</p>

                      <div class="payment-for-event">
                        <form [formGroup]="authorizeForm" class="authorize-container" (ngSubmit)="paymentSend()">

                          <div formGroupName="card">
                            <div class="hfp-input-div">
                              <label>Card Number <span class="span_star">*</span></label>
                              <input type="text" formControlName="cardNumber" cc-type="cardType"
                                placeholder="Credit Card Number" ccNumber class="card-nmr">
                              <div
                                *ngIf="authorizeForm.controls['card'].controls['cardNumber'].invalid && (authorizeForm.controls['card'].controls['cardNumber'].dirty || paymentSubmitted)"
                                class="custom_error">
                                <div *ngIf="authorizeForm.controls['card'].controls['cardNumber'].errors.required">Card Number
                                  is required. </div>
                                <div
                                  *ngIf="!authorizeForm.controls['card'].controls['cardNumber'].errors.required && authorizeForm.controls['card'].controls['cardNumber'].errors.ccNumber">
                                  Invalid Card Number. </div>
                              </div>
                            </div>


                            <div class="hfp-input-div">
                              <label>Card Holder’s Name <span class="span_star">*</span></label>
                              <input type="text" value="Jhon Doe" placeholder="Card Holder’s Name" formControlName="fullName">
                              <div
                                *ngIf="authorizeForm.controls['card'].controls['fullName'].invalid && (authorizeForm.controls['card'].controls['fullName'].dirty || paymentSubmitted)"
                                class="custom_error">
                                <div *ngIf="authorizeForm.controls['card'].controls['fullName'].errors.required">Card Holder’s
                                  is required. </div>
                                <div
                                  *ngIf="!authorizeForm.controls['card'].controls['fullName'].errors.required && authorizeForm.controls['card'].controls['fullName'].errors.minlength">
                                  Min length be 2. </div>

                              </div>
                            </div>



                            <div class="clearfix row exhibitors-row-2">
                              <div class="col-sm-6">
                                <div class="hfp-input-div">
                                  <label>Expiry Date<span class="span_star">*</span></label>
                                  <input type="text" formControlName="expireAt" placeholder="MM/YY" ccExp>
                                  <div *ngIf="authorizeForm.controls['card'].controls['expireAt'].invalid && (authorizeForm.controls['card'].controls['expireAt'].dirty || paymentSubmitted)"
                                       class="custom_error">
                                    <div *ngIf="authorizeForm.controls['card'].controls['expireAt'].errors.required">Expiry
                                      date is required. </div>
                                    <div
                                      *ngIf="!authorizeForm.controls['card'].controls['expireAt'].errors.required && authorizeForm.controls['card'].controls['expireAt'].errors.expDate">
                                      Invalid expiry date. </div>
                                    <div *ngIf="!authorizeForm.controls['card'].controls['expireAt'].errors.required
                                        && !authorizeForm.controls['card'].controls['expireAt'].errors.minlength
                                        && authorizeForm.controls['card'].controls['expireAt'].errors.maxlength">
                                      Valid format is YY. </div>
                                  </div>
                                </div>

                              </div>
                              <div class="col-sm-3">
                                <div class="hfp-input-div">
                                  <label>CVV<span class="span_star">*</span></label>

                                  <input type="text" formControlName="cvv" placeholder="CVV" ccCVC>
                                  <div *ngIf="authorizeForm.controls['card'].controls['cvv'].invalid && (authorizeForm.controls['card'].controls['cvv'].dirty || paymentSubmitted)" class="custom_error">
                                    <div *ngIf="authorizeForm.controls['card'].controls['cvv'].errors.required">CVV number is required. </div>
                                    <div *ngIf="!authorizeForm.controls['card'].controls['cvv'].errors.required && authorizeForm.controls['card'].controls['cvv'].errors.minlength">Min length should be 3. </div>
                                    <div *ngIf="!authorizeForm.controls['card'].controls['cvv'].errors.required && !authorizeForm.controls['card'].controls['cvv'].errors.minlength && authorizeForm.controls['card'].controls['cvv'].errors.maxlength">Max length should be 4. </div>
                                  </div>
                                </div>


                              </div>
                              <div class="col-sm-3">
                                <div class="hfp-input-div">
                                  <label>Amount&nbsp;($)</label>
                                  <input type="text" value="{{unregisterData.amount}}" readonly placeholder="Amount" formControlName="amount">
                                  <div *ngIf="authorizeForm.controls['card'].controls['amount'].invalid && (authorizeForm.controls['card'].controls['amount'].dirty || paymentSubmitted)" class="custom_error">
                                    <div *ngIf="authorizeForm.controls['card'].controls['amount'].errors.required">Amount is required. </div>
                                    <div *ngIf="!authorizeForm.controls['card'].controls['amount'].errors.required && authorizeForm.controls['card'].controls['amount'].errors.min">Minimum number should be 1. </div>
                                  </div>
                                </div>


                              </div>

                            </div>




                            <div class="event-buttons clearfix">
                              <!-- <a href="javascript:void(0)" id="next-b-1" class="btn btn-default next-btn margin-rightskip" (click)="skipRegistration()">Skip </a> -->
                              <a  class="refund-policy-link" routerLink="/refund-policy" href="javascript:void(0)" target="_blank">Refund Policy</a>
                              <button type="submit" class="next-btn button-css">Next <i></i><i><img src="assets/images/next-icon.png" alt=""></i></button>

                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
              <!--second form-->
              <div id="event-step-b-2" *ngIf="authorizeStep === 'event-step-b-2'">
                <div class="clearfix">
                <div class="hfp-col-4">
                  <div class="event-pay-left">
                    <div class="pay-event-step">
                      <ul>
                        <li><a class="step-done" href="#">1</a></li>
                        <li><a class="active" href="#">2</a></li>
                        <li><a class="active" href="#">3</a></li>

                      </ul>
                    </div>
                    <i><img src="assets/images/customer-billing-information-icon.png" alt=""></i>
                    <h5>Customer Billing Information</h5>
                  </div>
                </div>
                <div class="hfp-col-8">
                  <div class="event-pay-right">
                    <h2>CUSTOMER BILLING INFORMATION</h2>

                    <div class="payment-for-event">
                      <form [formGroup]="userInfoForm" class="authorize-container" (ngSubmit)="finalPaymentSubmitFormWithUserInfo()" autocomplete="off">
                        <div formGroupName="users">

                          <div class="hfp-input-div">
                            <label>First Name<span class="span_star">*</span></label>
                            <input type="text" formControlName="firstName" placeholder="First Name">
                          </div>

                          <div *ngIf="userInfoForm.controls['users'].controls['firstName'].invalid
                                      && (userInfoForm.controls['users'].controls['firstName'].dirty || userBillingSubmitted)"
                            class="custom_error">
                            <div *ngIf="userInfoForm.controls['users'].controls['firstName'].errors.required">First Name
                              is required. </div>
                          </div>


                          <div class="hfp-input-div">
                            <label>Last Name<span class="span_star">*</span></label>
                            <input type="text" formControlName="lastName" placeholder="Last Name">
                          </div>

                          <div *ngIf="userInfoForm.controls['users'].controls['lastName'].invalid
                                                              && (userInfoForm.controls['users'].controls['lastName'].dirty
                                                               || userBillingSubmitted)" class="custom_error">
                            <div *ngIf="userInfoForm.controls['users'].controls['lastName'].errors.required">Last Name
                              is required. </div>
                          </div>

                          <div class="hfp-input-div">
                            <label>Company<span class="span_star">*</span></label>
                            <input type="text" formControlName="company" placeholder="Company">
                          </div>

                          <div class="hfp-input-div">
                            <label>Address<span class="span_star">*</span></label>
                            <!--  <textarea class="address-txt" placeholder="Address" formControlName="address"
                                        myNoSpaces #search> </textarea>-->
                            <input id="autocomplete2"  (click)="onAddreessChange($event)" placeholder="Address" 
                            formControlName="address" name="address" myNoSpaces
                              #search class="input-field" type="text" required>



                          </div>
                          <div *ngIf="userInfoForm.controls['users'].controls['address'].invalid
                                                              && (userInfoForm.controls['users'].controls['address'].dirty
                                                                                               || userBillingSubmitted)"
                            class="custom_error">
                            <div *ngIf="userInfoForm.controls['users'].controls['address'].errors.required">Address
                              is required. </div>
                          </div>


                          <div class="hfp-input-div">
                            <label>City<span class="span_star">*</span></label>
                            <input class="" type="text" name="city" placeholder="City" formControlName="city">
                          </div>

                          <div
                            *ngIf="userInfoForm.controls['users'].controls['city'].invalid
                                                                                        && (userInfoForm.controls['users'].controls['city'].dirty
                                                                                                                         || userBillingSubmitted)"
                            class="custom_error">
                            <div *ngIf="userInfoForm.controls['users'].controls['city'].errors.required">City
                              is required. </div>
                          </div>

                          <div class="clearfix row exhibitors-row-2">
                            <div class="col-sm-6">
                              <div class="hfp-input-div">
                                <label>State/Province<span class="span_star">*</span></label>
                                <input name="state" formControlName="state" placeholder="State/Province" class="input-field"
                                  type="text" required>
                                <!-- <select class="hfp-selectbox" formControlName="state">
                                              <option>VA</option>
                                            </select> -->
                              </div>

                              <div *ngIf="userInfoForm.controls['users'].controls['state'].invalid
                                                                                        && (userInfoForm.controls['users'].controls['state'].dirty
                                                                                     || userBillingSubmitted)" class="custom_error">
                                <div *ngIf="userInfoForm.controls['users'].controls['state'].errors.required">State
                                  is required. </div>
                              </div>


                            </div>
                            <div class="col-sm-6">
                              <div class="hfp-input-div">
                                <label>Zip Code<span class="span_star">*</span></label>
                                <input type="text" placeholder="Zip Code" name="zipcode" formControlName="zipcode">
                              </div>
                              <div *ngIf="userInfoForm.controls['users'].controls['zipcode'].invalid
                                                                                                                    && (userInfoForm.controls['users'].controls['zipcode'].dirty
                                                                              || userBillingSubmitted)" class="custom_error">
                                <div *ngIf="userInfoForm.controls['users'].controls['zipcode'].errors.required">Zip Code
                                  is required. </div>
                              </div>


                            </div>
                          </div>
                          <div class="hfp-input-div">
                            <label>Country<span class="span_star">*</span></label>
                            <input class="" type="text" placeholder="Country" name="country" formControlName="country">
                          </div>
                          <div *ngIf="userInfoForm.controls['users'].controls['country'].invalid

                                                                  && (userInfoForm.controls['users'].controls['country'].dirty
                                                                                                        || userBillingSubmitted)"
                            class="custom_error">
                            <div *ngIf="userInfoForm.controls['users'].controls['country'].errors.required">Country
                              is required. </div>
                          </div>

                          <div class="clearfix row exhibitors-row-2">
                            <div class="col-sm-6">
                            <div class="sign-up-fields hfp-input-div">
                                <label class="field-label">Phone&nbsp;<span class="span_star">*</span></label>
                                <app-phone-number 
                                  [phoneNumberFormControlName]="'phone'"
                                  [phoneNumberFormGroup]="phone.parent"
                                ></app-phone-number>
                              </div>
                              <div *ngIf="phone.invalid && userBillingSubmitted" class="custom_error">
                                  {{
                                  getValidationErrors({
                                  formControl: phone,
                                  formControlName:translatorJson.PHONE_NUMBER
                                  })
                                  }}
                              </div>
  
                              
                            </div>

                          <!-- <div class="clearfix row exhibitors-row-2">
                            <div class="col-sm-6">
                              <div class="hfp-input-div">
                                <label>Phone<span class="span_star">*</span></label>
                                <input type="text" placeholder="Phone" formControlName="phone">
                              </div>

                              <div *ngIf="userInfoForm.controls['users'].controls['phone'].invalid
                                      && (userInfoForm.controls['users'].controls['phone'].dirty
                                                                             || userBillingSubmitted)" class="custom_error">
                                <div *ngIf="userInfoForm.controls['users'].controls['phone'].errors.required">Phone
                                  is required. </div>
                                <div *ngIf="!userInfoForm.controls['users'].controls['phone'].errors.required
                                              && userInfoForm.controls['users'].controls['phone'].errors.minlength">
                                  Phone number invalid. Shoud be 10. </div>
                                <div *ngIf="!userInfoForm.controls['users'].controls['phone'].errors.required
                                              && !userInfoForm.controls['users'].controls['phone'].errors.minlength
                                               && userInfoForm.controls['users'].controls['phone'].errors.maxlength">
                                  Phone number invalid. Shoud not be greater then 12 </div>
                              </div>
                            </div> -->
                            <div class="col-sm-6">
                              <div class="hfp-input-div">
                                <label>Email<span class="span_star">*</span></label>
                                <input class="" type="email" placeholder="Email" formControlName="email">
                              </div>
                              <div *ngIf="userInfoForm.controls['users'].controls['email'].invalid
                                                                             && (userInfoForm.controls['users'].controls['email'].dirty

                                                                             || userBillingSubmitted)" class="custom_error">
                                <div *ngIf="userInfoForm.controls['users'].controls['email'].errors.required">Email
                                  is required. </div>
                                <div *ngIf="userInfoForm.controls['users'].controls['email'].errors.email">
                                  Email must be a valid email address
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="event-buttons clearfix">
                            <a href="javascript:void(0)" id="pre-b-2" (click)="backInintFirstForm()"
                                          class="btn btn-default pre-btn"><img src="assets/images/prv-icon.png" alt=""> Previous
                                          <i></i></a>
                            <!-- <button type="submit" class="btn btn-default next-btn">Next <i><img src="assets/images/next-icon.png"
                                  alt=""></i></button> -->
                            <!-- <a href="javascript:void(0)" id="next-b-2" class="btn btn-default next-btn">Next <i><img
                                              src="assets/images/next-icon.png" alt=""></i></a> -->
                                              <button type="submit" class="btn btn-default next-btn">Submit </button>

                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
                </div>




              </div>
              <!--Third form-->
              <!-- <div id="event-step-b-3" *ngIf="authorizeStep === 'event-step-b-3'">
                <div class="clearfix">
                <div class="hfp-col-4">
                  <div class="event-pay-left">
                    <div class="pay-event-step">
                      <ul>
                        <li><a class="step-done" href="#">1</a></li>
                        <li><a class="step-done" href="#">2</a></li>
                        <li><a class="active" href="#">3</a></li>

                      </ul>
                    </div>
                    <i><img src="assets/images/order-information-icon.png" alt=""></i>
                    <h5>Order Information</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ullamcorper eros id felis
                      porta, a volutpat purus bibendum.</p>
                  </div>
                </div>
                <div class="hfp-col-8">
                  <div class="event-pay-right">
                    <h2>ORDER INFORMATION</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam metus leo.</p>

                    <div class="payment-for-event">
                      <form [formGroup]="step3form" class="authorize-container" (ngSubmit)="finalSubmitForm()" autocomplete="off">

                          <div class="hfp-input-div">
                            <label>Description</label>
                            <textarea placeholder="Description" formControlName="description" name="description"></textarea>
                          </div>

                          <div class="event-buttons clearfix">
                            <a href="javascript:void(0)" id="pre-b-2" (click)="backInintSecondForm()"
                             class="btn btn-default pre-btn">
                              <img src="assets/images/prv-icon.png" alt="">
                              Previous <i></i></a>
                            <button type="submit" class="btn btn-default next-btn">Submit </button>


                        </div>
                      </form>
                    </div>

                  </div>
                </div>
                </div>
              </div> -->
            </div>
          </div>        </div>
        <div class="hfp-row clearfix unregistered-requestfullpage" *ngIf="showRequest == 1">

          <div class="hfp-col-4 pull-right">
            <div class="exhibitors-edit-right">
              <i><img src="./assets/images/exhibitor-pop-icon.png" alt=""></i>
              <h5>{{eventReqItem.eventName}}</h5>
              <p [innerHTML]="eventReqItem.eventDescription | truncate:[300, '...']"></p>
            </div>
          </div>
          <div class="hfp-col-8 pull-left">
            <div class="exhibitors-edit-left">
              <h3> {{'SEND_INVITATION_REQUEST' | translate}} </h3>
              <app-unregister-event-request-form
                [industriesList]="industriesList"
                [screeningParentList]="screeningParentList"
                [eventReqItem]="eventReqItem"
                [userData]="userData"
                (eventSubmitted)="onSubmitRequest($event)"
              ></app-unregister-event-request-form>
             
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</div>
</div>
