import {
  Directive,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type,
} from "@angular/core";

@Directive({
  selector: "[appControlFactory]",
})
export class ControlFactoryDirective implements OnChanges {
  @Input() component: Type<any>;
  @Input() data: any;
  @Input() foldData: any;
  @Input() tempStatus: any;
  @Input() preComp: any;
  @Output() dateEmitter = new EventEmitter();
  compRef: any = {};
  message: string = "";
  constructor(
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnChanges() {
    const foldTempData = JSON.parse(this.data);
    if (Object.keys(this.compRef).length) {
      this.compRef.destroy();
    }
    if (this.component && foldTempData.data && foldTempData.data.length) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(
        this.component
      );
      this.compRef = this.vcRef.createComponent(factory);
      (<any>this.compRef.instance).foldData = foldTempData;
      (<any>this.compRef.instance).templateData = foldTempData.data;
      if (foldTempData && foldTempData.id) {
        (<any>this.compRef.instance).isShowTemp = false;
      } else {
        (<any>this.compRef.instance).isShowTemp = true;
      }
    }
  }
}
