import { Component, Injector, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { EventService } from "../event/event.service";
import { AppComponent } from "../app.component";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import * as moment from "moment";
// Import utility method class
import { FileUploader } from "ng2-file-upload";
// Handle the global property
import { NotifierService } from "angular-notifier";
import { Globals } from "../globals";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { TaxonomyService } from "../taxonomy/taxonomy.service";
import { Router } from "@angular/router";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import * as TranslatorJson from '../../assets/i18n/en.json'

@Component({
  selector: "app-exhibitor-invitation",
  templateUrl: "./exhibitor-invitation.component.html",
  styleUrls: ["./exhibitor-invitation.component.scss"],
})
export class ExhibitorInvitationComponent implements OnInit {
  modalRef: BsModalRef;
  userData: any;
  userSlug: string = "";
  userRole: any = null;
  loginUserRole: any = null;
  eventRequestForm: FormGroup;
  eventRequestCancelForm: FormGroup;
  isAddEvent = "";
  againRequest = 0;
  indexCurrent = -1;
  adminApprovedCount = 0;
  eventsAttributes: any = {};
  raffelType: any = [
    { name: "Yes", value: "true" },
    { name: "No", value: "false" },
  ];

  electricityNeed: any = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];
  users = [
    { id: "anjmao", name: "Anjmao" },
    { id: "varnas", name: "Tadeus Varnas" },
  ];
  minStartDate = new Date();
  hourStep = 1;
  minuteStep = 15;
  disableTime = true;
  endTime = false;
  submitted = false;
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  screeningParentList: any = [];
  industriesList: any = [];
  mapRegion: any = [];
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  updateIndex: number = -1;
  miles = 20;
  allEventData: any = [];
  selectedEventData: any = [];
  corporateUserList: any = [];
  editIndex: number = -1;
  showDropdownMenuList: boolean = false;
  eventReqItem: any;
  conditionAccepted = false;
  exhibitorInvitationSearchForm: FormGroup;
  private notifier: NotifierService;
  translatorJson: typeof TranslatorJson;
  currentDate = new Date()
  defaultValue = [
    {
      id: 1,
      value: "Day before of the event",
    },
    {
      id: 7,
      value: "One week before the event",
    },
    {
      id: 14,
      value: "Two week before the event",
    },
    {
      id: 28,
      value: "Four week before the event",
    },
  ];
  public uploader: FileUploader = new FileUploader({
    url:
      environment.apiUrl + "/containers/upload?folderName=event&fileType=image",
  });
  constructor(
    private fb: FormBuilder,
    notifierService: NotifierService,
    private appComponent: AppComponent,
    private taxonomyService: TaxonomyService,
    private router: Router,
    private authService: AuthService,
    private modalService: BsModalService,
    public globals: Globals,
    private eventService: EventService,
    private injector: Injector
  ) {
    this.notifier = notifierService;
    this.minStartDate.setDate(this.minStartDate.getDate() - 1);
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  formIniti() {
    this.eventRequestForm = this.fb.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],
      email: ["", [Validators.required]],
      phone: [
        "",
        [
          Validators.required,
          Validators.pattern(new RegExp("^([0-9()/+ -]*)$")),
        ],
      ],
      contactName: ["", [Validators.required]],
      contactNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(new RegExp("^([0-9()/+ -]*)$")),
        ],
      ],
      // exhibitorReq: new FormControl(null, Validators.required),
      screenReq: new FormControl(null, Validators.required),
      numberOfTable: ["", [Validators.required]],
      numberOfChairs: ["", [Validators.required]],
      attendees: this.fb.array([]),
      electricity: [false, [Validators.required]],
      interactiveComponent: ["", [Validators.required]],
      // raffleItem: ["", [Validators.required]],
      additionalNeeds: ["", [Validators.required]],
      reminder: this.fb.array([1, 28]),
      memberId: [this.userData.id, [Validators.required]],
      status: [0, [Validators.required]],
    });
  }

  ngOnInit() {
    this.latitude = 20.5937;
    this.longitude = 78.9629;
    this.zoom = 7;
    // Copy object
    this.eventsAttributes = Object.assign(
      {},
      this.globals.adminPaginationAttrs
    );

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.userData.role;
    //   this.formIniti();
    //   this.getLocalEventsCounts(
    //     this.eventsAttributes.limits,
    //     this.eventsAttributes.skips
    //   );
    //   this.exhibitorInvitationSearchForm = this.fb.group({
    //     eventName: ["", []],
    //   });

    //   if (this.loginUserRole == 0) {
    //     this.getUserAndEventList();
    //   } else if (this.loginUserRole == 10) {
    //     this.getEventList(10, 0);
    //   } else if (this.loginUserRole == 20) {
    //     this.getEventList(10, 0);
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.appComponent.userData;
          this.loginUserRole = this.userData.role;
          this.formIniti();
          this.getLocalEventsCounts(
            this.eventsAttributes.limits,
            this.eventsAttributes.skips
          );
          this.exhibitorInvitationSearchForm = this.fb.group({
            eventName: ["", []],
          });

          if (this.loginUserRole == 0) {
            this.getUserAndEventList();
          } else if (this.loginUserRole == 10) {
            this.getEventList(10, 0);
          } else if (this.loginUserRole == 20) {
            this.getEventList(10, 0);
          }
        } else {
          this.router.navigateByUrl("/");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // convenience getter for easy access to form fields
  get reqForm() {
    return this.eventRequestForm.controls;
  }

  get getRemainderArray() {
    return this.eventRequestForm.get("reminder") as FormArray;
  }

  createAttendeeItem() {
    return this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
    });
  }

  addMoreAttendee() {
    (this.eventRequestForm.controls["attendees"] as FormArray).push(
      this.createAttendeeItem()
    );
  }

  // Open model box of add/edit taxonomy
  openRequestPopUp(content: any, data: any, indexNumber: number): any {
    this.updateIndex = indexNumber;

    this.eventRequestForm.reset();
    this.formIniti();
    this.eventReqItem = data; //
    var attendeeData = new FormArray([]);
    if (
      this.eventReqItem.eventReqData &&
      this.eventReqItem.eventReqData.attendees &&
      this.eventReqItem.eventReqData.attendees.length > 0
    ) {
      _.forEach(this.eventReqItem.eventReqData.attendees, function (value) {
        attendeeData.push(
          new FormGroup({
            name: new FormControl(value.name, Validators.required),
            email: new FormControl(value.email, Validators.required),
          })
        );
      });

      if (attendeeData.length > 0) {
        this.eventRequestForm.setControl("attendees", attendeeData);
      }
    }

    this.eventRequestForm.patchValue(this.eventReqItem.eventReqData);

    this.modalRef = this.modalService.show(
      content,
      Object.assign({}, { class: "exhibitor-event-edit-pop" })
    );
  }

  // Get Event list for slug
  getEventList(limitNum: number, skipNum: number): void {
    // this.eventsAttributes.count = this.adminApprovedCount;
    const queryObj: any = {
      userid: this.userData.id,
      limit: limitNum,
      skip: skipNum,
    };
    // This statement for serch widget type list
    if (
      this.exhibitorInvitationSearchForm &&
      this.exhibitorInvitationSearchForm.value &&
      this.exhibitorInvitationSearchForm.value.eventName
    ) {
      // queryObj.eventSlug = {
      //   regexp: 'm.*' + this.exhibitorInvitationSearchForm.value.eventName + '/mi'
      // };
      const perma = this.exhibitorInvitationSearchForm.value.eventName
        .replace("/event/", "")
        .trim()
        .replace(/[^A-Z0-9-]/gi, "-")
        .replace(/-{2,}/g, "-")
        .toLowerCase();
      queryObj.eventSlug = perma;
    }

    //const queryObj: any = { userid: this.userData.id };

    this.eventService.getEventRequestForInvitation(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        this.eventsAttributes.pageItems = data && data.length ? data : [];

        if (res && res.length > 0) {
          res.forEach((v: any, k): any => {
            if (v.event) {
              let mapId = "map_" + k;
              this.loadListMap(mapId, v.eventAddress);
            }
          });

          this.allEventData = res;
          this.allEventData.forEach((element) => {
            let currentdate = new Date();
            let enddate = new Date(element.eventDate);
            element.disable = false;
            //  console.log("1",currentdate.getTime()>= enddate.getTime(),currentdate, enddate,element.endDateTime, )

            if (currentdate.getTime() >= enddate.getTime()) {
              element.disable = true;
            }
          });
        }else{
          this.allEventData = res;
        }
      },
      () => { }
    );
  }

  // This method is used for search widget
  exhibitorInvitationSearch() {
    if (this.exhibitorInvitationSearchForm.valid) {
      this.getEventList(
        this.eventsAttributes.limits,
        this.eventsAttributes.skips
      );
    }
  }

  // to count of events
  getLocalEventsCounts(limitNum: number, skipNum: number): void {
    /*
    const queryObj: any = {      
      where: {
        userid:this.userData.id
    }
    };


    if (this.exhibitorInvitationSearchForm && this.exhibitorInvitationSearchForm.value
      && this.exhibitorInvitationSearchForm.value.eventName) {
        const perma = this.exhibitorInvitationSearchForm.value.eventName .replace('/event/', '').trim().replace(/[^A-Z0-9-]/ig, '-').
        replace(/-{2,}/g, '-').toLowerCase();
       queryObj.eventSlug = perma;
      queryObj.eventSlug = {
        regexp: 'm.*' + perma + '/mi'
      };
     // queryObj.eventSlug = this.exhibitorInvitationSearchForm.value.eventName ;
    }
*/
    const queryObj: any = {
      userid: this.userData.id,
      limit: limitNum,
      skip: skipNum,
    };
    // This statement for serch widget type list
    if (
      this.exhibitorInvitationSearchForm &&
      this.exhibitorInvitationSearchForm.value &&
      this.exhibitorInvitationSearchForm.value.eventName
    ) {
      // queryObj.eventSlug = {
      //   regexp: 'm.*' + this.exhibitorInvitationSearchForm.value.eventName + '/mi'
      // };
      //queryObj.eventSlug = this.exhibitorInvitationSearchForm.value.eventName ;
      const perma = this.exhibitorInvitationSearchForm.value.eventName
        .replace("/event/", "")
        .trim()
        .replace(/[^A-Z0-9-]/gi, "-")
        .replace(/-{2,}/g, "-")
        .toLowerCase();
      queryObj.eventSlug = perma;
    }
    this.eventService.getEventRequestForInvitationCount(queryObj).subscribe(
      (data) => {
        this.eventsAttributes.count = data && data.count ? data.count : 0;
      },
      () => { }
    );
  }

  /**
   * This function is used for pagination
   */
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getEventList(this.eventsAttributes.limits, startItem);
  }

  isExpirationExpired(experationDate) {
    // your date logic here, recommend moment.js;
    let currentdate = new Date();

    return moment(experationDate).isBefore(moment(currentdate));
  }
  /**
   * Push Default reminder value in array
   */
  onCheckboxChange(option, event) {
    if (this.getRemainderArray) {
      if (event.target.checked) {
        this.getRemainderArray.push(new FormControl(option.id));
      } else {
        for (var i = 0; i < this.getRemainderArray.length; i++) {
          if (
            this.getRemainderArray &&
            this.getRemainderArray.at(i).value == option.id
          ) {
            this.getRemainderArray.removeAt(i);
          }
        }
      }
    }
  }

  /*
   * Redirect to Event Detai Page
   */
  viewDetail(slug) {
    console.log(this.router.navigate(["event-detail", slug]));
  }

  /**
   * Close Popup
   */

  closPopup(): void {
    const localModalRef = this.modalRef;
    localModalRef.hide();
  }

  // Get screening taxo list
  getScreeningTaxo(): void {
    forkJoin([
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId,
          },
        },
      }),
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.industryId,
          },
        },
      }),
    ]).subscribe(
      (results) => {
        this.screeningParentList = results[0] ? results[0] : [];
        this.industriesList = results[1] ? results[1] : [];

        if (this.userData.industry && this.userData.industry.length > 0) {
          let selectedIndustry: any = [];
          this.industriesList = selectedIndustry;
        }

        //screening
        if (this.eventReqItem.screenReq && this.eventReqItem.screenReq .length > 0) {
          let selectedScreening: any = [];
          this.screeningParentList.forEach(async (value) => {
            _.find(this.eventReqItem.screenReq, function (o) {
              if (String(o) == String(value.id)) {
                selectedScreening.push(value);
              }
            });
          });

          this.screeningParentList = selectedScreening;
        }
      },
      () => { }
    );
  }

  loadListMap(mapId: string, location: any) {
    setTimeout(() => {
      var map = new google.maps.Map(
        <HTMLInputElement>document.getElementById(mapId),
        {
          center: new google.maps.LatLng(location.lat, location.lng),
          zoom: 10,
        }
      );
      let contentString =
        '<div id="content">' +
        '<div id="bodyContent">' +
        "<p>" +
        location.location +
        "</p>" +
        "</div>" +
        "</div>";

      let infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      let marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map,
      });
      marker.addListener("click", function () {
        infowindow.open(map, marker);
      });
    }, 0);
  }

  approveEvent(id: string, status: number) {
    const queryObj: any = { status: status };
    this.eventService.updateEvent(id, queryObj).subscribe(
      () => {
        const getIndex: number = this.allEventData.findIndex(
          (x) => String(x.id) === String(id)
        );
        if (getIndex >= 0) {
          this.allEventData[getIndex].status = status;
        }
      },
      () => { }
    );
  }

  openEventApprovalPopUp(content: any, data: any, ind: number): any {
    this.selectedEventData = data;
    this.indexCurrent = ind;
    // this.selectedEvenType = 'free';
    this.eventRequestCancelForm = this.fb.group({
      reason: ["", Validators.required],
    });

    this.modalRef = this.modalService.show(
      content,
      Object.assign({}, { class: "pop-event-approval" })
    );
  }
  get f() {
    return this.eventRequestCancelForm.controls;
  }

  declineInvitationOfEvent(): void {
    this.submitted = true;

    if (this.eventRequestCancelForm.valid) {
      const queryObj: any = { status: 2 };
      let id = this.selectedEventData.eventReqData.id;
      this.eventService.updateEventReqById(id, queryObj).subscribe(
        () => {
          let temEmail: any = {};
          temEmail.userId = this.selectedEventData.corpItem.id;
          //

          temEmail.slug = "exhibitor-event-request-declined";

          //  temEmail.subject = 'Your Event Approved';
          temEmail.keys = {
            EXHIBITOR_NAME: this.userData.username,
            CORPORATE_NAME: this.selectedEventData.corpItem.username,
            REASON: this.eventRequestCancelForm.value.reason,
            companyName:this.selectedEventData.corpItem.data.company_name
          };
          this.emailSends(temEmail);
          this.allEventData[this.indexCurrent].eventReqStatus = 2;
          this.notifier.notify("success", "Request cancelled.");
          this.modalRef.hide();
          this.eventRequestCancelForm.reset();

          ///email code ends
        },
        () => { }
      );
    }
    /*  */
  }
  emailSends(sendObj: any) {
    this.eventService.sendEmail(sendObj).subscribe(
      (data) => {
        // console.log('email send success');
        console.log(data);
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      }
    );
  }

  // multi request for admin only
  getUserAndEventList(): void {
    forkJoin([this.getEventList(10, 0)]);
  }

  /**
   * This function is used to update event req
   */
  updateRequest() {
    if (this.eventRequestForm.valid) {
      const queryObj: any = this.eventRequestForm.value;
      if (this.againRequest == 1) {
        queryObj.eventReqStatus = 0;
      }
      const eventReqObj = Object.assign({}, queryObj);
      this.eventService
        .updateEventReqById(this.eventReqItem.eventReqData.id, eventReqObj)
        .subscribe(
          () => {
            //updateIndex
            console.log(
              "dsfsd",
              this.updateIndex,
              this.againRequest,
              this.allEventData[this.updateIndex]
            );
            if (this.updateIndex >= 0 && this.againRequest == 1) {
              this.allEventData[this.updateIndex].eventReqStatus = 0;
            }

            this.notifier.notify("success", "Update Successful!");
            this.conditionAccepted = false;
            this.modalRef.hide();
          },
          (err) => {
            if (
              err &&
              err.error &&
              err.error.error &&
              err.error.error.message
            ) {
              this.notifier.notify("error", err.error.error.message);
            }
          }
        );
    } else {
      this.eventsAttributes.submitted = true;
    }
  }
  searchTaxo(term: string, item: any) {
    // console.log(term,item);
    let strSearchLow = term.toLowerCase();
    let objectLowerCase = item.name.toLowerCase();
    var n = objectLowerCase.startsWith(strSearchLow);
    if (n && n == true) {
      return item;
    }
  }
  isEventDateValid(localData): boolean {
    return localData.eventReqStatus == 0 && new Date(localData.eventDate) > new Date();
  }
  isEventDecline(localData):boolean{
    return localData.eventReqStatus == 2  || (localData.eventReqStatus == 0 && new Date(localData.eventDate) < new Date())
  }
}
