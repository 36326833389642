export enum CommonTabStatus  {
    'PENDING' = 0,
    'APPROVED'= 1,
    'DECLINED' = 2,
    'APPROVED_PAST' = 3
} 
export enum UserRoleStatus  {
    'ADMIN' = 0,
    'CORPORATE'= 10,
    'EXHIBITOR' = 20,
} 

export enum EventType{
  Virtual="Virtual",
  ONSITE="onsite"
}