<!-- ##TODO: Do not remove this comments it is fold logic -->
<!-- <div class="benefits-of-corporate-section two-row-section" style="background-image:url(assets/images/benefits-of-orporate-img.jpg);">
    <div class="bg-overly"></div>
    <div class="container poi-r-z">
      <h1>{{foldData[0].heading}}</h1>
      <p [innerHTML]="foldData[0].subHeading"></p>
      <div class="row clearfix">
        <div class="hfp-col-6 hide-desktop">
        </div>
        <div class="hfp-col-6">
          <div class="txt-ul">
            <div [innerHTML]="foldData[0].description"></div>
            <div class="signup-btn"><a   [routerLink]= "['signup', 'host']"><i><img src="assets/images/signup-btn-icon.png" alt="" /></i> Sign Up</a> </div>

          </div>
        </div>
        <div class="hfp-col-6 hide-mobile">
            {{foldData[0].type}}
          <div class="img-video-box">
             <img *ngIf="foldData[0].type === foldConstants.IMAGE"  [src]="foldData && foldData.length>0 ? (imageCdn + 'w_540,h_319/' + ((imageBaseUrl + '/fold/' + foldData[0].file[0]) | encodeUriComponent )):'' "  alt="loading .."/>
          <video *ngIf="foldData[0].type === foldConstants.VIDEO" style="width: 100%; height: 100%; position: relative; z-index: 1;"  controls  #videoPlayer>
              <source [src]="foldData && foldData.length>0 ? (imageCdn + 'w_540,h_319/' + ((imageBaseUrl + '/fold/' + foldData[0].file[0]) )):'' " type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- ##TODO: Do not remove this comments it is fold logic -->

  <div class="benefits-of-corporate-section two-row-section" style="background-image:url(assets/images/benefits-of-orporate-img.jpg);">
    <div class="bg-overly"></div>
    <div class="container poi-r-z">
      <h1>Experience HFP Live!</h1>
      <p>A live stream service that’s inventive, adaptive, and proactive so you don’t have to be.</p>
      <div class="row clearfix">
        
        <div class="hfp-col-6 hide-desktop video-iframe"> 
          <iframe src="https://player.vimeo.com/video/685858082?h=48ea91e547&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen  title="HFPLive Promo"></iframe>
        </div>

        <div class="hfp-col-6">
          <div class="txt-ul">
            <ul>
              <li>Unlimited access to daily live-streamed webinars, workshops, and fitness classes</li>
              <li>Upcoming event calendars with registrations</li>
              <li>Video library, recordings, and health information section</li>
              <li>Engaging health coaches, doctors, dietician and fitness professionals</li>
              <li>A different theme every month!</li>
             </ul>
             <div class="signup-btn"><a [href]="systemConstants.HFP_LIVE_FOLD_LINK" target="_blank">Learn More</a> </div>
           </div>
        </div>
        <div class="hfp-col-6 hide-mobile">
          <div class="img-video-box video-iframe">

            <iframe src="https://player.vimeo.com/video/685858082?h=48ea91e547&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="HFPLive Promo"></iframe>
            
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </div>
  </div>

