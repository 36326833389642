<div class="phone-number-input-box">
  <ng-select
    [items]="regionsString"
    bindLabel="label"
    [(ngModel)]="defaultPhoneNumber"
    [ngClass]='phoneCodePanelClass'
    (ngModelChange)="selectValue($event)"
    (clear)="reset()">
  </ng-select>
  <form class="phone-number-input" [formGroup]="phoneNumberFormGroup">
    <input
    #phoneNumber
    type="text"
    [placeholder]="phoneNumberFormat"
    [formControlName]="phoneNumberFormControlName"
    autocomplete="off"
    name="phone"
    (input)="validatePhoneNumberInput(); getMaximumLengthValidationOnTypeValue($event.target);"
    maxlength="{{maxLength}}" 
>
  </form>
</div>