<div class="">
  <div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
    <div class="img-overly"></div>
    <div class="container">
      <div class="main-heading-top paymentpage-hd clearfix">
        <div class="main-user-info">
          <div class="main-user-img">
            <div class="main-user-img-upload" hidden>
              <input type="file" />
              <i class="fa fa-camera" aria-hidden="true"></i></div>
            <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
            <img *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)"
              [src]="(imageCdn + 'w_228,h_228/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))"
              alt="" />

          </div>
          <h2>{{userData.username}}</h2>
        </div>
        <div class="main-heading-right">
          <h1>Manage Payment</h1>
        </div>
        <div class="heading_page_type adminpayment">
          <ul>
            <li><a (click)="paymentSearch=0;activeTabs=1;paidEventsList(systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);paidEventsCounts();showExhibListIndx=-1"
                [ngClass]="{'active': activeTabs == 1 }"> <i><img src="/assets/images/sm-paid-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>{{paidPaymentFixCount}}</span> Paid </div>
              </a> </li>
            <li> <a
                (click)="paymentSearch=0;activeTabs=2;getPendingPaymentUserList(systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);getCountsPaymentPendingUserList();showExhibListIndx=-1"
                [ngClass]="{'active': activeTabs == 2 }">
                <i><img src="/assets/images/sm-peding-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>{{pendingPaymentFixCount}}</span>
                  Pending </div>
              </a> </li>
            <li> <a (click)="activeTabs=3;paymentSearch=0;getListEventCancelBeforePaid(systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);showExhibListIndx=-1"
                [ngClass]="{'active': activeTabs == 3 }">
                <i><img src="/assets/images/sm-cancel-paid-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>{{eventCancelBeforePaidAttributes.count}}</span> Cancel Before
                  Paid </div>
              </a> </li>
            <li> <a
                (click)="activeTabs=7;paymentSearch=0;getListEventPaidButCancel(systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);getListCountsEventPaidButCancel();showExhibListIndx=-1"
                [ngClass]="{'active': activeTabs == 7 }">
                <i><img src="/assets/images/sm-cancel-paid-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>{{eventPaidButCancelAttributes.count}}</span> Paid But Cancelled
                </div>
              </a> </li>
            <li> <a
                (click)="activeTabs=4;paymentSearch=0;getOverDueEventUserList(systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);getCountsOverDueEventUserList();showExhibListIndx=-1"
                [ngClass]="{'active': activeTabs == 4 }">
                <i><img src="/assets/images/sm-payment-overdue-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>{{overDueFixCount}}</span>Payment Overdue </div>
              </a> </li>
            <li hidden> <a (click)="activeTabs=5;paymentSearch=0;" [ngClass]="{'active': activeTabs == 5 }"> <i><img
                    src="/assets/images/sm-paid-but-cancel-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>0</span>Paid But Cancel </div>
              </a> </li>
            <li> <a
                (click)="activeTabs=6;paymentSearch=0;getEventListForUnspecifiedPayment(systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);getEventListCountsUnspecifiedPayment();showExhibListIndx=-1"
                [ngClass]="{'active': activeTabs == 6 }"> <i><img src="/assets/images/sm-payment-unspecfied-icon.png"
                    alt="" /></i>
                <div class="paymet-status-right"> <span>
                    {{paymentUnspecifiedFixCount}}</span>Payment Unspecfied</div>
              </a> </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Search section start-->
  <div class="main-admin-search super-payment-search">
    <div class="container clearfix main__filter__row">
      <div class="main__filter__tag" [ngClass]="{'paid-but-cancel-tag':activeTabs == 7}">
        <div class="active-tag-box">
          <div class="active-tag-box-in" *ngIf="activeTabs == 1">
            <i class="active-tag-box-icon"><img src="/assets/images/sm-paid-icon.png" alt="" /></i>
            <div class="active-tag-box-txt">Paid</div>
          </div>

          <div class="active-tag-box-in" *ngIf="activeTabs == 2">
            <i class="active-tag-box-icon"><img src="/assets/images/sm-peding-icon.png" alt="" /></i>
            <div class="active-tag-box-txt">Pending</div>
          </div>

          <div class="active-tag-box-in" *ngIf="activeTabs == 3">
            <i class="active-tag-box-icon"><img src="/assets/images/sm-cancel-paid-icon.png" alt="" /></i>
            <div class="active-tag-box-txt">Cancel
              <!-- Befoe Paid-->
            </div>
          </div>

          <div class="active-tag-box-in" *ngIf="activeTabs == 4">
            <i class="active-tag-box-icon"><img src="/assets/images/sm-payment-overdue-icon.png" alt="" /></i>
            <div class="active-tag-box-txt">
              <!--Payment -->Overdue</div>
          </div>

          <div class="active-tag-box-in" *ngIf="activeTabs == 5">
            <i class="active-tag-box-icon"><img src="/assets/images/sm-paid-but-cancel-icon.png" alt="" /></i>
            <div class="active-tag-box-txt">Paid Cancel</div>
          </div>

          <div class="active-tag-box-in" *ngIf="activeTabs == 6">
            <i class="active-tag-box-icon"><img src="/assets/images/sm-payment-unspecfied-icon.png" alt="" /></i>
            <div class="active-tag-box-txt">
              <!--Payment -->Unspecfied</div>
          </div>
          <div class="active-tag-box-in" *ngIf="activeTabs == 7">
            <i class="active-tag-box-icon"><img src="/assets/images/sm-paid-but-cancel-icon.png" alt="" /></i>
            <div class="active-tag-box-txt">Paid But Cancel</div>
          </div>

        </div>
      </div>
      <div class="pagefilter__section">
        <form [formGroup]="paymentManageSearchForm" (ngSubmit)="paymentManageSearch();">
          <div class="pagefilter__section__in">
            <div class="pagefilter-search-box">
              <input type="text" value="" placeholder="Search Exhibitor" formControlName="searchField" />
            </div>
            <div class="pagefilter-search-box">
              <input type="text" value="" placeholder="Search Event" formControlName="eventName" />
            </div>
            <div class="pagefilter-select-box" *ngIf="activeTabs == 1">
              <select class="hfp-selectbox" formControlName="paymentType" required>
                <option value="" selected="selected">Select Payment Type</option>
                <option *ngFor="let menu of paymentType" value="{{menu.value}}">{{menu.lable}}</option>
              </select>
            </div>
            <div class="pagefilter-search-button">
              <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Searching-->


  <div class="show_search_filter_data" *ngIf="loginUserRole == 0 && paymentSearch == 1">
    <div class="container">
      <div class="s_r">Search Result : <strong>
          <span *ngIf="activeTabs == 1">{{paymentManageAttributes.count}}</span>
          <span *ngIf="activeTabs == 2">{{paymentPendingAttributes.count}}</span>
          <span *ngIf="activeTabs == 3">{{eventCancelBeforePaidAttributes.count}}</span>
          <span *ngIf="activeTabs == 4">{{paymentOverDueAttributes.count}}</span>
          <span *ngIf="activeTabs == 5">0</span>
          <span *ngIf="activeTabs == 6 ">{{eventReqAttributes.count}}</span>
          <span *ngIf="activeTabs == 7 ">{{eventPaidButCancelAttributes.count}}</span>

        </strong>
      </div>
      <div class="s_r_t">
        <ul>
          <li *ngIf="paymentManageSearchForm.value.searchField ">
            <span>{{this.paymentManageSearchForm.value.searchField}}</span>
            <a href="javascript:void(0)" (click)="removeEventName()"></a></li>
          <li *ngIf="paymentManageSearchForm.value.eventName ">
            <span>{{this.paymentManageSearchForm.value.eventName}}</span>
            <a href="javascript:void(0)" (click)="removeEvent()"></a></li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Searching-->
  <!-- Search section end-->


  <div *ngIf="activeTabs == 6; then eventUnspecified"></div>
  <div *ngIf="activeTabs == 5; then eventUnspecified"></div>
  <div *ngIf="activeTabs == 4; then paymentOverDueTemp"></div>
  <div *ngIf="activeTabs == 3; then eventCancelBeforePaid"></div>
  <div *ngIf="activeTabs == 7; then eventPaidButCancel"></div>
  <div *ngIf="activeTabs == 2; then pendingPaymentEvents"></div>
  <div *ngIf="activeTabs == 1; then paidEvents"></div>

</div>


<ng-template #paymentOverDueTemp>
  <div class="admin-payment-section admin-payment-page paymentOverDueTemp">
    <div class="container">
      <div *ngIf="!paymentOverDueAttributes.pageItems || !paymentOverDueAttributes.pageItems.length" class="search-not-found">
        {{translatorJson.NO_RECORD_FOUND | translate}} </div>
      <div class="">
        <div class="a-p-row" *ngFor="let item of paymentOverDueAttributes.pageItems;  let idx = index">
          <div class="super-admin-payment-row-top" id="a-p-top-1" *ngIf="shoPaymentListIndx != idx;">
            <div class="s-a-p-img">
              <img *ngIf="item.userData && item.userData.image && item.userData.image.type == 'avatar'"
                src="/assets/images/avatars/{{item.userData.image.name}}" />
              <img *ngIf="item.userData && item.userData.image && item.userData.image.type != 'avatar'" width="100%"
                (error)="handleImgError($event, item)"
                [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item.userData.image.name) | encodeUriComponent))"
                alt="" />
            </div>
            <div class="s-a-p-left">
              <div class="s-a-p-txt">
                <h3 *ngIf="item && item.userData">{{item.userData.data.company_name}}</h3>
                <div class="payment__event__list">

                  <p class="bottom-margin-5">{{item.userData.email}}</p>
                  <!-- <p *ngFor="let eItem of item.eventPaymentPendingData;" class="bottom-margin-5">{{eItem.eventName}}</p> -->
                </div>
                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="eventSingleItem = item ;shoPaymentListIndx= idx;">View
                    Detail</a>
                </div>
              </div>
              <div class="s-a-p-right paymentpending-pos">
                <!--<div class="select-payment-check event-box">
                  <input  class="css-checkbox" type="checkbox" name="checkboxG0" id="checkboxG{{idx}}"
                  (change)="changebox($event, item.eventPaymentPendingData,idx)" >
                  <label  class="css-label" for="checkboxG{{idx}}" ></label>

                </div>-->

                <div class="s-a-p-right-in">
                  <div class="payment-txt" *ngIf="item && item.userBillingData"> {{item.userBillingData.city}} </div>
                  <div class="stus-type"> <i><img src="/assets/images/sm-payment-overdue-icon.png" alt="">
                    </i>Payment Overdue </div>
                </div>
                <!-- start code dropdown --->
                <div class="paymentaction-box">
                  <div class="payment-action">
                    <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                        aria-controls="dropdown-menu" (click)="false">Action </a>
                      <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">
                        <li class="event-review-icon" *ngIf="userData.role == 0">
                          <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                            (click)="sendReminder(idx)">
                            Send Reminder</a>
                        </li>
                        <li class="event-approve-icon" *ngIf="userData.role == 0  || userData.role == 10">
                          <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                            (click)="markAsPaid(idx)">
                            Mark as Paid</a>
                        </li>
                        <!-- <li class="event-edit-icon" *ngIf="userData.role == 0  || userData.role == 10"  >
                          <a  href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                              (click)="isEdit=!isEdit;getEventDetail(idx);editIndex =idx;getScreeningTaxo();isAddEvent='edit'">
                            Edit</a>
                        </li> -->
                        <li class="event-declined-icon" *ngIf="userData.role == 0  || userData.role == 10">
                          <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                            (click)="markAsDelete(idx)">
                            Delete</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- end code dropdown -->

              </div>
            </div>
          </div>
          <div *ngIf="shoPaymentListIndx == idx" class="a-p-btm clearfix">
            <div class="tab-close-btn"><button (click)="shoPaymentListIndx=-1;shoPaymentListIndx=-1;"><img
                  src="/assets/images/inquiries-close.png" alt=""></button></div>
            <div class="a-p-btm-top clearfix">
              <div class="a-p-img">
                <img *ngIf="item.userData && item.userData.image && item.userData.image.type == 'avatar'"
                  src="/assets/images/avatars/{{item.userData.image.name}}" />
                <img *ngIf="item.userData && item.userData.image && item.userData.image.type != 'avatar'" width="100%"
                  (error)="handleImgError($event, item)"
                  [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item.userData.image.name) | encodeUriComponent))"
                  alt="" />
              </div>
              <div class="a-p-txt">

                <h3 *ngIf="item && item.userData">{{item.userData.data.company_name}}</h3>
                <div class="payment__event__list">
                  <p class="bottom-margin-5">{{item.userData.email}}</p>
                  <!-- <p *ngFor="let eItem of item.eventPaymentPendingData;" class="bottom-margin-5">{{eItem.eventName}}</p> -->
                </div>


                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="shoPaymentListIndx=-1;shoPaymentListIndx=-1;">Hide Detail</a> </div>
              </div>
            </div>
            <div class="exhibitor-event-payment">

              <div class="exhibitor-event-payment-row"
                *ngIf="item && item.eventPaymentPendingData && item.eventPaymentPendingData.length>0">
                <div class="exhibitor-event-payment-row-in"
                  *ngFor="let pItem of item.eventPaymentPendingData;let pIdx = index">
                  <div class="img-43 no-imgevent" *ngIf="!pItem.eventData[0]?.media[0]">

                  </div>
                  <div class="img-43 no-imgevent" *ngIf="pItem.eventData[0]?.media[0]">
                    <img width="100%"
                      [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/event/' + pItem.eventData[0]?.media[0]) | encodeUriComponent))"
                      alt="" />

                  </div>
                  <div class="exhibitor-event-txt">
                    <h4> {{pItem.corporateName}} - {{pItem.eventName}}</h4>
                    <p>{{pItem.eventDate | date: 'MMM/d/yyyy' }}</p>
                  </div>
                  <div class="payment-donedate" *ngIf="pIdx==0">
                    Payment Date
                    <span>{{pItem.eventDate | date: 'MMM/d/yyyy' }}</span>
                  </div>
                  <!-- start code dropdown --->
                  <div class="paymentaction-box">
                    <div class="payment-action">
                      <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                          aria-controls="dropdown-menu" (click)="false">Action </a>
                        <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">
                          <li class="event-review-icon" *ngIf="userData.role == 0">
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                              (click)="sendReminderPerEvent(idx,pIdx)">
                              Send Reminder</a>
                          </li>
                          <li class="event-edit-icon" *ngIf="userData.role == 0 ">
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                              (click)="eventSingleItem = pItem;showExhibListIndx = pIdx;patchValuePaymentMangaementForm();">
                              Edit</a>
                          </li>
                          <li class="event-edit-icon" *ngIf="userData.role == 0">
                            <a href="javascript:void(0)" (click)="markAsDeletePerUser(idx,pIdx)">
                              Delete</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- end code dropdown -->
                  <div class="exhibitor-payment-info">
                    $ {{pItem.eventPayment}}
                  </div>

                  <div class="clear"></div>
                  <div *ngIf="showExhibListIndx == pIdx; then exhibTempalteOoverDue"></div>
                </div>


                <div class="admin-payment-total" hidden>
                  Total Invoice Amount : $ {{item.paymentAmount}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination__box" *ngIf="paymentOverDueAttributes.count > paymentOverDueAttributes .limits">

        <!-- <button *ngIf="paymentOverDueAttributes.count > 0" class="sendmailbtn outline-btn" (click)="mail()">Send Mail</button> -->
        <pagination  [totalItems]="paymentOverDueAttributes.count" [rotate]="paymentOverDueAttributes.rotate"
          [maxSize]="paymentOverDueAttributes.maxSize" [itemsPerPage]="paymentOverDueAttributes.limits"
          (pageChanged)="pageChanged($event);"></pagination>
      </div>
    </div>
  </div>
</ng-template>




<!--payment unsepecified payment list start -->
<ng-template #eventCancelBeforePaid>
  <div class="admin-payment-section admin-payment-page CancelBeforePaid">
    <div class="container">
      <div *ngIf="!eventCancelBeforePaidAttributes.pageItems || !eventCancelBeforePaidAttributes.pageItems.length" class="search-not-found">
        {{translatorJson.NO_RECORD_FOUND | translate}} </div>
      <!-- {{eventCancelBeforePaidAttributes.pageItems | json}} -->
      <div class="a-p-row" *ngFor="let item of eventCancelBeforePaidAttributes.pageItems;  let idx = index">
        <div class="super-admin-payment-row-top" *ngIf="showEventListIndx != idx;">
          <!-- <div class="s-a-p-img no-imgevent">
            <div *ngIf="item['eventReqData'][0].eventData[0].media && item['eventReqData'][0].eventData[0].media.length">
              <img width="100%"
                src="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + item['eventReqData'][0].eventData[0].media[0]) | encodeUriComponent))"
                alt="" />
            </div>
          </div> -->

          <div class="s-a-p-img no-avatarevent">
            <img *ngIf="item['eventReqData'][0]['exhibitorData'][0] && item['eventReqData'][0]['exhibitorData'][0].image
            && item['eventReqData'][0]['exhibitorData'][0].image.type == 'avatar'"
              src="/assets/images/avatars/{{item['eventReqData'][0]['exhibitorData'][0].image.name}}" />
            <img *ngIf="item['eventReqData'][0]['exhibitorData'][0] && item['eventReqData'][0]['exhibitorData'][0].image
            && item['eventReqData'][0]['exhibitorData'][0].image.type != 'avatar'" width="100%"
              (error)="handleImgError($event, item)"
              [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item['eventReqData'][0]['exhibitorData'][0].image.name) | encodeUriComponent))"
              alt="" />
          </div>

          <div class="s-a-p-left">
            <div class="s-a-p-txt">
              <h3>{{item.userData.data.company_name}}</h3>
              <!-- <p class="bottom-margin-5">{{item['eventReqData'][0].eventData[0].eventName}}</p> -->
              <p class="bottom-margin-5" *ngFor="let eventItem of item.eventReqData;">
                {{eventItem.eventData[0].eventName}} </p>
              <!--  -->

              <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                  (click)="getExhibitorList(item['eventReqData'][0]._id);eventSingleItem = item ;showEventListIndx= idx;">View
                  Detail</a> </div>
            </div>
            <div class="s-a-p-right paymentpending-pos">
              <div class="select-payment-check">
               <!--
                TODO : requirement not clear
                <input class="css-checkbox" type="checkbox" name="checkboxG0" id="checkboxG{{idx}}"
                  (change)="changecheckbox($event, item.eventPaymentPendingData,idx)">
                <label class="css-label" for="checkboxG{{idx}}"></label>-->
              </div>
              <div class="s-a-p-right-in">
                <div class="payment-txt" *ngIf="item && item.eventAddress"> {{item.eventAddress.city}} </div>
                <div class="stus-type"> <i><img src="/assets/images/sm-cancel-paid-icon.png" alt=""></i>Cancel Before
                  Paid </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showEventListIndx == idx">
          <!-- {{eventSingleItem | json}} -->
          <!-- Box start from here-->
          <div class="a-p-btm">
            <div class="tab-close-btn"><button (click)="showEventListIndx=-1;showExhibListIndx=-1;"><img
                  src="/assets/images/inquiries-close.png" alt=""></button></div>
            <div class="a-p-btm-top clearfix">
              <div class="a-p-img no-imgevent">
                <!-- <img width="100%"
                  src="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + eventSingleItem['eventReqData'][0]['exhibitorData'][0].media[0]) | encodeUriComponent))"
                  alt="" /> -->
                <img *ngIf="eventSingleItem['eventReqData'] && eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData'] && eventSingleItem['eventReqData'][0]['exhibitorData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData'][0].image
            && eventSingleItem['eventReqData'][0]['exhibitorData'][0].image.type == 'avatar'"
                  src="/assets/images/avatars/{{item['eventReqData'][0]['exhibitorData'][0].image.name}}" />
                <img *ngIf="eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData'] && item['eventReqData'][0]['exhibitorData'][0] && item['eventReqData'][0]['exhibitorData'][0].image
            && item['eventReqData'][0]['exhibitorData'][0].image.type != 'avatar'" width="100%"
                  (error)="handleImgError($event, eventSingleItem)"
                  [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/users/' + eventSingleItem['eventReqData'][0]['exhibitorData'][0].image.name) | encodeUriComponent))"
                  alt="" />
              </div>
              <div class="a-p-txt">
                <!-- dsdsf -->
                <!-- {{eventSingleItem | json}} -->
                <!-- <h3>{{eventSingleItem.eventName}}</h3>
                <p class="bottom-margin-5">{{eventSingleItem.company}}</p> -->
                <h3 *ngIf="eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData']">
                  {{eventSingleItem['eventReqData'][0]['exhibitorData'][0].username}}</h3>
                <p class="bottom-margin-5"
                  *ngIf="eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData']">
                  {{eventSingleItem['eventReqData'][0].eventData[0].eventName}}</p><!--  -->
                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="showEventListIndx=-1;showExhibListIndx=-1;">Hide Detail</a> </div>
              </div>
            </div>

            <div class="exhibitor-event-payment">
              <div class="exhibitor-event-payment-row" *ngFor="let reqItem of item.eventReqData;  let idxs = index">
                <div class="exhibitor-event-payment-row-in eventCancelBeforePaid"
                  *ngIf="reqItem && reqItem.paymentSpecified == 1">

                  <div class="img-43">
                    <img *ngIf="reqItem['exhibitorData'][0].image && reqItem['exhibitorData'][0].image.type == 'avatar'"
                      src="/assets/images/avatars/{{reqItem['exhibitorData'][0].image.name}}" />
                    <img
                      *ngIf="reqItem['exhibitorData'][0].image &&  reqItem['exhibitorData'][0].image.type != 'avatar'"
                      width="100%" (error)="handleImgError($event, item)"
                      [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/users/' + reqItem['exhibitorData'][0].image.name) | encodeUriComponent))"
                      alt="" />
                  </div>
                  <div class="exhibitor-event-txt">
                    <h4>{{reqItem['exhibitorData'][0].username}}</h4>
                    <p>{{reqItem.eventData[0].eventName}}</p>
                    <div class="exhibitors-detail-link" hidden>
                      <a id="paymentedit1" href="javascript:void(0)">
                        <span (click)="userSingleItem = user;showExhibListIndx = idxs" class="span-edit">Edit</span>
                        <span class="span-hide" *ngIf="showExhibListIndx == idxs"
                          (click)="showExhibListIndx = -1">hide</span>
                      </a>
                    </div>
                  </div>
                  <div class="payment-donedate" *ngIf="reqItem && reqItem.modified">
                    Event Request Cancel Date
                    <span>{{reqItem.modified | date: 'MMM/d/yyyy'}}</span>
                    <a class="con" href="javascript:void(0)"
                      (click)="openRequestPopUp(exhibContatct,reqItem);">Contact</a>
                  </div>
                  <div class="exhibitor-payment-info">
                    <div class="exhibitors-dropdown">
                      <div class="action dropdown " dropdown>
                        <a class="dropdown-toggle" dropdownToggle aria-controls="dropdown-menu" (click)="false">Action
                        </a>
                        <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">
                          <li class="event-approve-icon"><a *ngIf="reqItem.status == 2" href="javascript:void(0)"
                              (click)="resumeInvitationForEvent(reqItem._id);">Accept </a></li>
                          <!-- <li class="event-approve-icon">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                                    (click)="eventSingleItem = reqItem;showExhibListIndx = idxs;patchValuePaymentMangaementFormPaid(idxs,reqItem.eventPrice);">
                                    Edit</a>
                              </li> -->
                          <li class="event-approve-icon"><a *ngIf="reqItem.status == 2" href="javascript:void(0)"
                              (click)="removeCancelBeforePaid(reqItem._id,idxs,idx);">Delete </a></li>
                          <!--<li class="event-clone-icon"><a href="#">Approve</a></li>-->
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="clear"></div>

                </div>
              </div>
            </div>
          </div>


          <!-- Box end here-->
        </div>
      </div>
      <div class="pagination__box" *ngIf="eventCancelBeforePaidAttributes.count > eventCancelBeforePaidAttributes.limits">
        <button  *ngIf="eventCancelBeforePaidAttributes.count > 0" class="sendmailbtn outline-btn" (click)="sendmailCancelBeforePaid()">Send Mail</button>
        <pagination [totalItems]="eventCancelBeforePaidAttributes.count"
          [rotate]="eventCancelBeforePaidAttributes.rotate" [maxSize]="eventCancelBeforePaidAttributes.maxSize"
          [itemsPerPage]="eventCancelBeforePaidAttributes.limits" (pageChanged)="pageChanged($event);">
        </pagination>

      </div>
    </div>
  </div>
</ng-template>
<!--payment unsepecified payment list end -->
<!--payment unsepecified payment list start -->

<ng-template #eventPaidButCancel>

  <div class="admin-payment-section admin-payment-page CancelBeforePaid">
    <div class="container">
      <div *ngIf="!eventPaidButCancelAttributes.pageItems || !eventPaidButCancelAttributes.pageItems.length" class="search-not-found">
        {{translatorJson.NO_RECORD_FOUND | translate}} </div>

      <div class="a-p-row" *ngFor="let item of eventPaidButCancelAttributes.pageItems;  let idx = index">
        <div class="super-admin-payment-row-top" *ngIf="showEventListIndx != idx;">
          <!-- <div class="s-a-p-img no-imgevent">
              <div *ngIf="item['eventReqData'][0].eventData[0].media && item['eventReqData'][0].eventData[0].media.length">
                <img width="100%"
                  src="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + item['eventReqData'][0].eventData[0].media[0]) | encodeUriComponent))"
                  alt="" />
              </div>
            </div> -->

          <div class="s-a-p-img no-avatarevent">
            <img *ngIf="item['eventReqData'][0]['exhibitorData'][0] && item['eventReqData'][0]['exhibitorData'][0].image
              && item['eventReqData'][0]['exhibitorData'][0].image.type == 'avatar'"
              src="/assets/images/avatars/{{item['eventReqData'][0]['exhibitorData'][0].image.name}}" />
            <img *ngIf="item['eventReqData'][0]['exhibitorData'][0] && item['eventReqData'][0]['exhibitorData'][0].image
              && item['eventReqData'][0]['exhibitorData'][0].image.type != 'avatar'" width="100%"
              (error)="handleImgError($event, item)"
              [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item['eventReqData'][0]['exhibitorData'][0].image.name) | encodeUriComponent))"
              alt="" />
          </div>

          <div class="s-a-p-left">
            <div class="s-a-p-txt">
              <h3>{{item.userData.data.company_name}}</h3>
              <!-- <p class="bottom-margin-5">{{item['eventReqData'][0].eventData[0].eventName}}</p> -->
              <p class="bottom-margin-5" *ngFor="let eventItem of item.eventReqData;">
                {{eventItem.eventData[0].eventName}} </p>
              <!--  -->

              <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                  (click)="getExhibitorList(item['eventReqData'][0]._id);eventSingleItem = item ;showEventListIndx= idx;">View
                  Detail</a> </div>
            </div>
            <div class="s-a-p-right paymentpending-pos">
              <div class="select-payment-check">
               <!--
                 TODO : requirement not clear
                <input class="css-checkbox" type="checkbox" name="checkboxG0" id="checkboxG{{idx}}"
                  (change)="changecheckbox($event, item.eventPaymentPendingData,idx)">
                <label class="css-label" for="checkboxG{{idx}}"></label>-->
              </div>
              <div class="s-a-p-right-in">
                <div class="payment-txt" *ngIf="item && item.eventAddress"> {{item.eventAddress.city}} </div>
                <div class="stus-type"> <i><img src="/assets/images/sm-cancel-paid-icon.png" alt=""></i>Paid But Cancelled </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showEventListIndx == idx">
          <!-- {{eventSingleItem | json}} -->
          <!-- Box start from here-->
          <div class="a-p-btm">
            <div class="tab-close-btn"><button (click)="showEventListIndx=-1;showExhibListIndx=-1;"><img
                  src="/assets/images/inquiries-close.png" alt=""></button></div>
            <div class="a-p-btm-top clearfix">
              <div class="a-p-img no-imgevent">
                <!-- <img width="100%"
                    src="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + eventSingleItem['eventReqData'][0]['exhibitorData'][0].media[0]) | encodeUriComponent))"
                    alt="" /> -->
                <img *ngIf="eventSingleItem && eventSingleItem['eventReqData'] && eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData'] && eventSingleItem['eventReqData'][0]['exhibitorData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData'][0].image
              && eventSingleItem['eventReqData'][0]['exhibitorData'][0].image.type == 'avatar'"
                  src="/assets/images/avatars/{{item['eventReqData'][0]['exhibitorData'][0].image.name}}" />
                <img *ngIf="eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData'] && item['eventReqData'][0]['exhibitorData'][0] && item['eventReqData'][0]['exhibitorData'][0].image
              && item['eventReqData'][0]['exhibitorData'][0].image.type != 'avatar'" width="100%"
                  (error)="handleImgError($event, eventSingleItem)"
                 [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/users/' + eventSingleItem['eventReqData'][0]['exhibitorData'][0].image.name) | encodeUriComponent))"
                  alt="" />
              </div>
              <div class="a-p-txt">
                <!-- dsdsf -->
                <!-- {{eventSingleItem | json}} -->
                <!-- <h3>{{eventSingleItem.eventName}}</h3>
                  <p class="bottom-margin-5">{{eventSingleItem.company}}</p> -->
                <h3 *ngIf="eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData']">
                  {{eventSingleItem['eventReqData'][0]['exhibitorData'][0].username}}</h3>
                <p class="bottom-margin-5"
                  *ngIf="eventSingleItem['eventReqData'][0] && eventSingleItem['eventReqData'][0]['exhibitorData']">
                  {{eventSingleItem['eventReqData'][0].eventData[0].eventName}}</p><!--  -->
                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="showEventListIndx=-1;showExhibListIndx=-1;">Hide Detail</a> </div>
              </div>
            </div>

            <div class="exhibitor-event-payment">
              <div class="exhibitor-event-payment-row" *ngFor="let reqItem of item.eventReqData;  let idxs = index">
                <div class="exhibitor-event-payment-row-in eventCancelBeforePaid"
                  *ngIf="reqItem && reqItem.paymentSpecified == 1">

                  <div class="img-43">
                    <img *ngIf="reqItem['exhibitorData'][0].image && reqItem['exhibitorData'][0].image.type == 'avatar'"
                      src="/assets/images/avatars/{{reqItem['exhibitorData'][0].image.name}}" />
                    <img
                      *ngIf="reqItem['exhibitorData'][0].image &&  reqItem['exhibitorData'][0].image.type != 'avatar'"
                      width="100%" (error)="handleImgError($event, item)"
                      [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/users/' + reqItem['exhibitorData'][0].image.name) | encodeUriComponent))"
                      alt="" />
                  </div>
                  <div class="exhibitor-event-txt">
                    <h4>{{reqItem['exhibitorData'][0].username}}</h4>
                    <p>{{reqItem.eventData[0].eventName}}</p>
                    <div class="exhibitors-detail-link" hidden>
                      <a id="paymentedit1" href="javascript:void(0)">
                        <span (click)="userSingleItem = user;showExhibListIndx = idxs" class="span-edit">Edit</span>
                        <span class="span-hide" *ngIf="showExhibListIndx == idxs"
                          (click)="showExhibListIndx = -1">hide</span>
                      </a>
                    </div>
                  </div>
                  <div class="payment-donedate" *ngIf="reqItem && reqItem.modified">
                    Event Request Cancel Date
                    <span>{{reqItem.modified | date: 'MMM/d/yyyy'}}</span>
                    <a class="con" href="javascript:void(0)"
                      (click)="openRequestPopUp(exhibContatct,reqItem);">Contact</a>
                  </div>
                  <div class="exhibitor-payment-info">
                    <div class="exhibitors-dropdown">
                      <div class="action dropdown " dropdown>
                        <a class="dropdown-toggle" dropdownToggle aria-controls="dropdown-menu" (click)="false">Action
                        </a>
                        <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">
                          <li class="event-approve-icon"><a href="javascript:void(0)"
                              (click)="resumeInvitationForEvent(reqItem._id,idxs,idx);">Accept </a></li>
                          <!-- <li class="event-approve-icon">
                                  <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                                      (click)="eventSingleItem = reqItem;showExhibListIndx = idxs;patchValuePaymentMangaementFormPaid(idxs,reqItem.eventPrice);">
                                      Edit</a>
                                </li> -->
                          <li class="event-approve-icon"><a href="javascript:void(0)"
                              (click)="removePaidButCancel(reqItem._id,idxs,idx);">Delete </a></li>
                          <!--<li class="event-clone-icon"><a href="#">Approve</a></li>-->
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="clear"></div>

                </div>
              </div>
            </div>
          </div>


          <!-- Box end here-->
        </div>

      </div>
      <div class="pagination__box" *ngIf="eventPaidButCancelAttributes.count > this.eventPaidButCancelAttributes.limits">
        <button class="sendmailbtn outline-btn" (click)="sendmailPaidButCancel()">Send Mail</button>
        <!---->
        <pagination [totalItems]="eventPaidButCancelAttributes.count" [rotate]="eventPaidButCancelAttributes.rotate"
          [maxSize]="eventPaidButCancelAttributes.maxSize" [itemsPerPage]="eventPaidButCancelAttributes.limits"
          (pageChanged)="pageChanged($event);">
        </pagination>

      </div>
    </div>
  </div>
</ng-template>
<!--payment unsepecified payment list end -->




<ng-template #pendingPaymentEvents>
  <div class="admin-payment-section admin-payment-page pendingPaymentEvents">
    <div class="container">
      <div *ngIf="!paymentPendingAttributes.pageItems || !paymentPendingAttributes.pageItems.length" class="search-not-found">
        {{translatorJson.NO_RECORD_FOUND | translate}} </div>
      <div class="">
        <div class="a-p-row" *ngFor="let item of paymentPendingAttributes.pageItems;  let idx = index">
          <div class="super-admin-payment-row-top" *ngIf="shoPaymentListIndx != idx;">
            <div class="s-a-p-img no-avatarevent">
              <img *ngIf="item.userData && item.userData.image && item.userData.image.type == 'avatar'"
                src="/assets/images/avatars/{{item.userData.image.name}}" />
              <img *ngIf="item.userData && item.userData.image && item.userData.image.type != 'avatar'" width="100%"
                (error)="handleImgError($event, item)"
                [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item.userData.image.name) | encodeUriComponent))"
                alt="" />
            </div>

            <div class="s-a-p-left">
              <!-- <div class="select-payment-check">
                <input  class="css-checkbox" type="checkbox" name="checkboxG0" id="checkboxG{{idx}}"
                (change)="changecheckbox($event, item.eventPaymentPendingData,idx)" >
                <label  class="css-label" for="checkboxG{{idx}}" ></label>
              </div> -->
              <div class="s-a-p-txt">
                <!-- {{item.eventPaymentPendingData[0] | json}}` -->
                <h3 *ngIf="item && item.userData">{{item && item.eventPaymentPendingData && item.eventPaymentPendingData[0] && item.eventPaymentPendingData[0].eventData && item.eventPaymentPendingData[0].eventData[0] && item.eventPaymentPendingData[0].eventData[0].company }}</h3>
                <div class="payment__event__list">
                  <!-- <p *ngFor="let eItem of item.eventPaymentPendingData;" class="bottom-margin-5">{{eItem.eventName}}</p> -->
                  <p *ngIf="item.eventPaymentPendingData;" class="bottom-margin-5">
                    {{item.eventPaymentPendingData[0].eventName}}</p>
                </div>
                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="eventSingleItem = item ;shoPaymentListIndx= idx;">View
                    Detail</a>
                </div>
              </div>
              <div class="s-a-p-right">
                <div class="s-a-p-right-in paymentpending-pos">
                  <div class="select-payment-check">
                    <input class="css-checkbox" type="checkbox" name="checkboxG0" id="checkboxG{{idx}}"
                      (change)="changedcheckbox($event, item.eventPaymentPendingData,idx)">
                    <label class="css-label" for="checkboxG{{idx}}"></label>
                  </div>
                  <div class="payment-txt" *ngIf="item && item.userBillingData"> {{item.userBillingData.city}} </div>
                  <div class="stus-type"> <i><img src="/assets/images/sm-peding-icon.png" alt=""></i>Pending </div>
                </div>

                <div class="clear"></div><br />
                <!-- start code dropdown --->
                <div class="paymentaction-box">
                  <div class="payment-action">
                    <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                        aria-controls="dropdown-menu" (click)="false">Action </a>
                      <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">

                        <!-- <li class="event-edit-icon" *ngIf="userData.role == 0 ">
                              <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                                (click)="eventSingleItem = pItem;showExhibListIndx = pIdx;patchValuePaymentMangaementForm();">
                                Edit</a>
                            </li> -->
                        <li class="event-edit-icon" *ngIf="userData.role == 0">
                          <a href="javascript:void(0)" (click)="markAsDeletePending(idx)">
                            Delete</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- end code dropdown -->
              </div>
            </div>


          </div>
          <div *ngIf="shoPaymentListIndx == idx" class="a-p-btm clearfix">
            <div class="tab-close-btn"><button (click)="shoPaymentListIndx=-1;shoPaymentListIndx=-1;"><img
                  src="/assets/images/inquiries-close.png" alt=""></button></div>
            <div class="a-p-btm-top clearfix">
              <div class="a-p-img">
                <img *ngIf="item.userData && item.userData.image && item.userData.image.type == 'avatar'"
                  src="/assets/images/avatars/{{item.userData.image.name}}" />
                <img *ngIf="item.userData && item.userData.image && item.userData.image.type != 'avatar'" width="100%"
                  (error)="handleImgError($event, item)"
                 [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item.userData.image.name) | encodeUriComponent))"
                  alt="" />
              </div>
              <div class="a-p-txt">
                <h3 *ngIf="item && item.userData">{{item.userData.username}}</h3>
                <div class="payment__event__list">
                  <p *ngFor="let eItem of item.eventPaymentPendingData;" class="bottom-margin-5">{{eItem.eventName}}</p>

                </div>

                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="shoPaymentListIndx=-1;shoPaymentListIndx=-1;">Hide Detail</a> </div>
              </div>
            </div>
            <div class="exhibitor-event-payment">

              <div class="exhibitor-event-payment-row"
                *ngIf="item && item.eventPaymentPendingData && item.eventPaymentPendingData.length>0">
                <div class="exhibitor-event-payment-row-in"
                  *ngFor="let pItem of item.eventPaymentPendingData;let pIdx = index">

                  <div class="exhibitor-event-txt pendingpaymentadmin-left">
                    <div class="img-43 no-imgevent" *ngIf="pItem.eventData[0]?.media[0]">
                      <img width="100%"
                        src="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/event/' + pItem.eventData[0]?.media[0]) | encodeUriComponent))"
                        alt="" />
                    </div>
                    <div class="img-43 no-imgevent event-list-img"
                      *ngIf="pItem.eventData[0]?.media && !pItem.eventData[0]?.media.length"> <img
                        [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/event/no-img-event-list.jpg') | encodeUriComponent))"
                        alt="" /> </div>
                    <div class="p_a_p_r">
                      <h4>{{pItem.eventName}}</h4>
                      <p>{{pItem.eventDate | date: 'MMM/d/yyyy' }}</p>
                    </div>
                  </div>
                  <div class="payment-donedate" *ngIf="pIdx==0">
                    Payment Date
                    <span>{{pItem.eventDate | date: 'MMM/d/yyyy' }}</span>
                  </div>
                  <!-- start code dropdown --->
                  <div class="paymentaction-box">
                    <div class="payment-action">
                      <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                          aria-controls="dropdown-menu" (click)="false">Action </a>
                        <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">

                          <li class="event-edit-icon" *ngIf="userData.role == 0 ">
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                              (click)="eventSingleItem = pItem;showExhibListIndx = pIdx;patchValuePaymentMangaementFormPending(idx,pIdx);">
                              Edit</a>
                          </li>
                          <li class="event-edit-icon" *ngIf="userData.role == 0">
                            <a href="javascript:void(0)" (click)="markAsDeletePerUserPending(idx,pIdx)">
                              Delete</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- end code dropdown -->
                  <div class="exhibitor-payment-info">
                    $ {{pItem.eventPayment}}
                  </div>
                  <div class="clear"></div>
                  <div *ngIf="showExhibListIndx == pIdx; then exhibTempaltePending"></div>
                  <span class="span-hide" *ngIf="showExhibListIndx == idx" (click)="showExhibListIndx = -1">hide</span>
                </div>


                <div class="admin-payment-total" hidden>
                  Total Invoice Amount : $ {{item.paymentAmount}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination__box">
        <button *ngIf="paymentPendingAttributes.count > 0" class="sendmailbtn outline-btn"
          (click)="sendEmailForPending()">Send Email</button>
        <pagination *ngIf="paymentPendingAttributes.count > paymentPendingAttributes.limits" [totalItems]="paymentPendingAttributes.count" [rotate]="paymentPendingAttributes.rotate"
          [maxSize]="paymentPendingAttributes.maxSize" [itemsPerPage]="paymentPendingAttributes.limits"
          (pageChanged)="pageChanged($event);"></pagination>
        <!-- <button  (click)="add()">Send mail</button> -->

      </div>
    </div>
  </div>
</ng-template>



<ng-template #paidEvents>
  <div class="admin-payment-section admin-payment-page paidEvents">
    <div class="container">
      <div *ngIf="!paymentManageAttributes.pageItems || !paymentManageAttributes.pageItems.length" class="search-not-found">
        {{translatorJson.NO_RECORD_FOUND | translate}} </div>
      <div class="">
        <div class="a-p-row" *ngFor="let item of paymentManageAttributes.pageItems;  let idx = index">
          <!-- {{item | json}} -->
          <div class="super-admin-payment-row-top" *ngIf="shoPaymentListIndx != idx;">
            <div class="s-a-p-img no-avatarevent">
              <img *ngIf="item.userData && item.userData.image && item.userData.image.type == 'avatar'"
                src="/assets/images/avatars/{{item.userData.image.name}}" />
              <img *ngIf="item.userData && item.userData.image && item.userData.image.type != 'avatar'" width="100%"
                (error)="handleImgError($event, item)"
                [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item.userData.image.name) | encodeUriComponent))"
                alt="" />
            </div>
            <div class="s-a-p-left">
              <div class="s-a-p-txt">
                <h3 *ngIf="item && item.userData">{{item.userData.data.company_name}} <div class="for-payment">
                    <span *ngIf="item.paymentType == 2">
                      Register
                    </span>
                    <span *ngIf="item.paymentType == 1">
                      Event
                    </span>
                  </div>
                </h3>

                <div class="payment__event__list">
                  <p *ngIf="item.prodList;" class="bottom-margin-5">{{item.prodList[0].eventName}}</p>
                  <!-- <p *ngIf="item.eventPaymentPendingData;" class="bottom-margin-5">{{item.eventPaymentPendingData[0].eventName}}</p> -->

                </div>
                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="eventSingleItem = item ;shoPaymentListIndx= idx;getAlleventDetails(item.eventIds)">View
                    Detail</a>
                </div>
              </div>

              <div class="s-a-p-right  paymentpending-pos">
                <div class="s-a-p-right-in">
                  <div class="payment-txt payment-txt-paid" *ngIf="item && item.userBillingData">
                    {{item.userBillingData.city}} </div>
                  <div class="stus-type"> <i><img src="/assets/images/sm-paid-icon.png" alt=""></i>Paid </div>
                </div>

                <!-- start code dropdown --->
                <div class="paymentaction-box">
                  <div class="payment-action">
                    <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                        aria-controls="dropdown-menu" (click)="false">Action </a>
                      <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">

                        <!-- <li class="event-edit-icon" *ngIf="userData.role == 0 ">
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                              (click)="eventSingleItem = pItem;showExhibListIndx = pIdx;patchValuePaymentMangaementForm();">
                              Edit</a>
                          </li> -->
                          <li class="event-edit-icon" *ngIf="userData.role == 0 && item.paymentType == 1">
                            <a href="javascript:void(0)" (click)="markAsDeletePerUserPaid(idx)">
                              Delete</a>
                          </li>
                          <li class="event-edit-icon" *ngIf="userData.role == 0 && item.paymentType == 2">
                            <a href="javascript:void(0)" (click)="markAsDeletePerUserPaidRegister(idx)">
                              Delete</a>
                          </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- end code dropdown -->

              </div>

            </div>
          </div>
          <div *ngIf="shoPaymentListIndx == idx" class="a-p-btm clearfix">
            <div class="tab-close-btn"><button (click)="shoPaymentListIndx=-1;shoPaymentListIndx=-1;"><img
                  src="/assets/images/inquiries-close.png" alt=""></button></div>

            <div class="a-p-btm-top clearfix">
              <div class="a-p-img">
                <img *ngIf="item.userData && item.userData.image && item.userData.image.type == 'avatar'"
                  src="/assets/images/avatars/{{item.userData.image.name}}" />
                <img *ngIf="item.userData && item.userData.image && item.userData.image.type != 'avatar'" width="100%"
                  (error)="handleImgError($event, item)"
                  [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + item.userData.image.name) | encodeUriComponent))"
                  alt="" />
              </div>
              <div class="a-p-txt">
                <h3 *ngIf="item && item.userData">{{item.userData.username}}</h3>
                <div class="payment__event__list">
                  <p *ngFor="let eItem of item.prodList;" class="bottom-margin-5">{{eItem.eventName}}</p>
                </div>

                <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                    (click)="shoPaymentListIndx=-1;shoPaymentListIndx=-1;">Hide Detail</a> </div>
              </div>
            </div>

            <div class="exhibitor-event-payment">

              <div *ngIf="item.paymentType == 2">
                <div class="exhibitor-event-payment-row">
                  <div class="exhibitor-event-payment-row-in event-payment-sub">

                    <div class="img-43 no-imgevent">
                      <img *ngIf="item.userData && item.userData.image && item.userData.image.type == 'avatar'"
                        width="60px" height="60px" src="/assets/images/avatars/{{item.userData.image.name}}" />
                        <div   class="event-list-img">
                      <img *ngIf="item.userData && item.userData.image && item.userData.image.type != 'avatar'"
                        width="100%" (error)="handleImgError($event, item)"
                        [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/users/' + item.userData.image.name) | encodeUriComponent))"
                        alt="" />
                      </div>
                    </div>

                    <div class="payment-invoicenumber">
                      Invoice Number
                      <span>{{item.invoiceNumber}}</span>
                    </div>
                    <div class="payment-donedate">
                      Payment Date
                      <span>{{item.created | date: 'MMM/d/yyyy' }}</span>
                    </div>
                        <!-- start code dropdown --->
                  <div class="paymentaction-box">
                    <div class="payment-action">
                      <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                          aria-controls="dropdown-menu" (click)="false">Action </a>
                        <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">

                          <li class="event-edit-icon" *ngIf="userData.role == 0 ">
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                              (click)="eventSingleItem = item;showExhibListIndx = idx;patchValuePaymentMangaementFormPaid(idx,item.paymentAmount);">
                              Edit</a>
                          </li>
                          <li class="event-edit-icon" *ngIf="userData.role == 0">
                            <a href="javascript:void(0)" (click)="markAsDeletePerUserPaidRegister(idx)">
                              Delete</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- end code dropdown -->
                    <div class="exhibitor-payment-info">
                      $ {{item.paymentAmount}}

                    </div>


                    <div class="clear"></div>
                    <div class="clear"></div>
                    <div *ngIf="showExhibListIndx == idx; then exhibTempaltePaid"></div>
                    <span class="span-hide" *ngIf="showExhibListIndx == idx" (click)="showExhibListIndx = -1">hide</span>
                  </div>
                </div>
              </div>

              <div class="exhibitor-event-payment-row" *ngIf="item && item.prodList && item.prodList.length>0">
                <div class="exhibitor-event-payment-row-in event-payment-sub"
                  *ngFor="let pItem of item.prodList;let pIdx = index">

                  <div class="img-43 no-imgevent">
                    <img *ngIf="eventAllDataValue[pIdx]?.media && eventAllDataValue[pIdx]?.media.length" width="100%"
                      [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/event/' + eventAllDataValue[pIdx].media[0]) | encodeUriComponent))"
                      alt="" />
                    <div *ngIf="eventAllDataValue[pIdx]?.media && !eventAllDataValue[pIdx]?.media.length"
                      class="event-list-img"> <img
                        [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/event/no-img-event-list.jpg') | encodeUriComponent))"
                        alt="" /> </div>
                  </div>
                  <div class="exhibitor-event-txt">
                    <h4>{{pItem.eventName}}</h4>
                    <h4>  {{item.userData.email}}</h4>
                  </div>


                  <div class="payment-invoicenumber" *ngIf="pIdx==0">
                    Invoice Number
                    <span>{{item.invoiceNumber}}</span>
                  </div>
                  <div class="payment-donedate" *ngIf="pIdx==0">
                    Payment Date
                    <span>{{item.created | date: 'MMM/d/yyyy' }}</span>
                  </div>
                  <!-- start code dropdown --->
                  <div class="paymentaction-box">
                    <div class="payment-action">
                      <div class="action dropdown" dropdown> <a href="#" class="dropdown-toggle" dropdownToggle
                          aria-controls="dropdown-menu" (click)="false">Action </a>
                        <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">

                          <li class="event-edit-icon" *ngIf="userData.role == 0 ">
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal"
                              (click)="eventSingleItem = item;showExhibListIndx = pIdx;patchValuePaymentMangaementFormPaid(pIdx,pItem.eventPrice);">
                              Edit</a>
                          </li>
                          <li class="event-edit-icon" *ngIf="userData.role == 0">
                            <a href="javascript:void(0)" (click)="markAsDeletePerUserPaid(idx,pIdx)">
                              Delete</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- end code dropdown -->
                  <div class="exhibitor-payment-info">
                    $ {{pItem.eventPrice}}
                    <!-- {{pItem.eventPrice}} -->
                  </div>
                  <div class="clear"></div>
                  <div *ngIf="showExhibListIndx == pIdx; then exhibTempaltePaid"></div>
                  <span class="span-hide" *ngIf="showExhibListIndx == idx" (click)="showExhibListIndx = -1">hide</span>
                </div>


                <div class="admin-payment-total">
                  Total Invoice Amount : $ {{item.paymentAmount}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination__box" *ngIf="paymentManageAttributes.count > paymentManageAttributes.limits">
        <pagination [totalItems]="paymentManageAttributes.count" [rotate]="paymentManageAttributes.rotate"
          [maxSize]="paymentManageAttributes.maxSize" [itemsPerPage]="paymentManageAttributes.limits"
          (pageChanged)="pageChanged($event);"></pagination>
      </div>
    </div>
  </div>
</ng-template>



<!--payment unsepecified payment list start -->
<ng-template #eventUnspecified>
  <div *ngIf="!eventReqAttributes.unspecifiedData || !eventReqAttributes.unspecifiedData.length " class="search-not-found">
    {{translatorJson.NO_RECORD_FOUND | translate}} </div>
  <div *ngIf="eventReqAttributes.unspecifiedData && eventReqAttributes.unspecifiedData.length > 0"
    class="admin-payment-section admin-payment-page eventUnspecified-payment-page eventUnspecifiedEvent">

    <div class="container">
      <div class="a-p-row" *ngFor="let item of eventReqAttributes.unspecifiedData;  let idx = index">

        <div class="super-admin-payment-row-top clearfix" *ngIf="showEventListIndx != idx;">

          <div class="img-43 no-imgevent">
            <img *ngIf="item.eventData && item.eventData[0]?.media && item.eventData[0]?.media.length" width="100%"
              [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/event/' + item.eventData[0].media[0]) | encodeUriComponent))"
              alt="" />
            <div *ngIf="item.eventData[0]?.media && !item.eventData[0]?.media.length" class="event-list-img">
              <img
                [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/event/no-img-event-list.jpg') | encodeUriComponent))"
                alt="" /> </div>
          </div>

          <div class="s-a-p-left">

            <div class="s-a-p-txt">
              <div class="payment__event__list">
                <h3 *ngIf="item.eventData;" class="bottom-margin-5">{{item.eventData[0].eventName}}</h3>

              </div>
              <p *ngIf="item && item.exhibitorData[0]">{{item.exhibitorData[0].username}}</p>
              <p class="bottom-margin-5">{{item.eventData[0].company}}</p>
              <div class="exhibitors-detail-link"><a href="javascript:void(0)"
                  (click)="getExhibitorList(item._id,item.eventData[0]);eventSingleItem = item.eventData[0] ;showEventListIndx= idx;">View
                  Detail</a> </div>
            </div>
            <div class="s-a-p-right">
              <div class="s-a-p-right-in">
                <div class="payment-txt" *ngIf="item.eventData[0] && item.eventData[0].eventAddress">
                  {{item.eventData[0].eventAddress.city}} </div>
                <div class="stus-type">
                  <i><img src="/assets/images/sm-payment-unspecfied-icon.png" alt="">
                  </i>Payment Unspecfied </div>
              </div>
            </div>
          </div>


        </div>

        <div *ngIf="showEventListIndx == idx;then showBox"></div>
      </div>
      <div class="pagination__box" *ngIf="eventReqAttributes.count > eventReqAttributes.limits">
        <pagination [totalItems]="eventReqAttributes.count" [rotate]="eventReqAttributes.rotate"
          [maxSize]="eventReqAttributes.maxSize" [itemsPerPage]="eventReqAttributes.limits"
          (pageChanged)="pageChanged($event);">
        </pagination>
      </div>
    </div>
  </div>
</ng-template>
<!--payment unsepecified payment list end -->

<!-- template for row section -->
<ng-template #showBox>
  <div class="a-p-btm">
    <div class="tab-close-btn"><button (click)="showEventListIndx=-1;showExhibListIndx=-1;"><img
          src="/assets/images/inquiries-close.png" alt=""></button></div>
    <div class="a-p-btm-top clearfix">
      <div class="img-43 no-imgevent" *ngIf="!eventSingleItem.media[0]">

      </div>
      <div class="a-p-img no-imgevent">
        <img width="100%" *ngIf="eventSingleItem && eventSingleItem.media"
          [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + eventSingleItem.media[0]) | encodeUriComponent))"
          alt="" />
      </div>
      <div class="a-p-txt">
        <h3>{{eventSingleItem.eventName}}</h3>
        <p class="bottom-margin-5">{{eventSingleItem.company}}</p>
        <div class="exhibitors-detail-link"><a href="javascript:void(0)"
            (click)="showEventListIndx=-1;showExhibListIndx=-1;">Hide Detail</a> </div>
      </div>
    </div>
    <div class="exhibitor-event-payment">
      <div class="exhibitor-event-payment-row" *ngFor="let user of userListOfEventReq;  let idx = index">
        <div class="exhibitor-event-payment-row-in"
          *ngIf="user && user.eventReqData && user.eventReqData.paymentSpecified == 0">
          <div class="img-43">
            <img *ngIf="user.image && user.image.type == 'avatar'" src="/assets/images/avatars/{{user.image.name}}" />

            <img *ngIf="user.image &&  user.image.type != 'avatar'"
              [src]="(imageCdn + 'w_60,h_60/' + ((imageBaseUrl + '/users/' + user.image.name) | encodeUriComponent))" />
          </div>
          <div class="exhibitor-event-txt">
            <h4>{{user.username}}</h4>
            <p>{{user.email}}</p>
            <div class="exhibitors-detail-link">
              <a id="paymentedit1" href="javascript:void(0)">
                <span (click)="userSingleItem = user;showExhibListIndx = idx" class="span-edit">Edit</span>
                <span class="span-hide" *ngIf="showExhibListIndx == idx" (click)="showExhibListIndx = -1">hide</span>
              </a>
            </div>
          </div>
          <div class="payment-donedate" *ngIf="user && user.eventReqData">

            Event approval date
            <span>{{user.eventReqData.modified | date: 'MMM/d/yyyy'}}</span>
          </div>
          <div class="exhibitor-payment-info paymentUnspecfied">
            <i><img width="20px" src="/assets/images/sm-payment-unspecfied-icon.png" alt=""></i>Payment Unspecfied
          </div>
          <div class="clear"></div>
          <div *ngIf="showExhibListIndx == idx; then exhibTempalte"></div>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #exhibTempalte>



  <div class="paymentedit-form" style="display: block;">

    <form (ngSubmit)="addPaymentFoEvent()" [formGroup]="eventPaymentManageForm">
      <h2>Edit: {{eventSingleItem.eventName}}</h2>

      <div class="in-inquiries-detail-list ">

        <div class="hfp-input-div">
          <label>Exhibitor Name</label>
          <input class="" type="text" placeholder="Exhibitor Name" formControlName="exhibitorName"
            value="{{userSingleItem.username}}" required>

        </div>

        <div class="hfp-input-div">
          <label>Event name</label>
          <input class="" type="text" placeholder="Event Name" formControlName="eventName"
            value="{{eventSingleItem.eventName}}" required>
        </div>

        <div class="hfp-input-div">
          <label>Corporate Name</label>
          <input class="" type="text" placeholder="Corporate Name" formControlName="corporateName"
            value="{{eventSingleItem.company}}" required>
        </div>

        <div class="hfp-input-div">
          <label>Occured On</label>
          <input class="" type="text" placeholder="Occured On" readonly formControlName="eventDate"
            value="{{ eventSingleItem.eventDate | date }}" required>
        </div>

        <div class="hfp-input-div">
          <label> Payment Status </label>
          <select class="hfp-selectbox" formControlName="paymentStatus">
            <option *ngFor="let item of paymentSpecified" value="{{item.value}}"> {{item.name}}
            </option>
          </select>

        </div>

        <div class="hfp-input-div">
          <label> Payment Amount($) </label>
          <input class="" type="number" placeholder="00" formControlName="eventPayment" required>
        </div>

        <div class="buttons">
          <button class="btn btn-primary">Submit</button>

        </div>
      </div>
    </form>

  </div>

</ng-template>


<ng-template #exhibTempalteOoverDue>



  <div class="paymentedit-form" style="display: block;">

    <form (ngSubmit)="addPaymentFoEventOverDue()" [formGroup]="eventPaymentManageForm">
      <h2>Edit: {{eventSingleItem.eventName}}</h2>

      <div class="in-inquiries-detail-list ">

        <div class="hfp-input-div">
          <label>Exhibitor Name</label>
          <input class="" type="text" placeholder="Exhibitor Name" formControlName="exhibitorName"
            value="{{userSingleItem.username}}" required>

        </div>

        <div class="hfp-input-div">
          <label>Event name</label>
          <input class="" type="text" placeholder="Event Name" formControlName="eventName"
            value="{{eventSingleItem.eventName}}" required>
        </div>

        <div class="hfp-input-div">
          <label>Corporate Name</label>
          <input class="" type="text" placeholder="Corporate Name" formControlName="corporateName"
            value="{{eventSingleItem.company}}" required>
        </div>

        <div class="hfp-input-div">
          <label>Occured On</label>
          <input class="" type="text" placeholder="Occured On" readonly formControlName="eventDate"
            value="{{ eventSingleItem.eventDate | date }}" required>
        </div>

        <div class="hfp-input-div">
          <label> Payment Status </label>
          <select class="hfp-selectbox" formControlName="paymentStatus">
            <option *ngFor="let item of paymentSpecified" value="{{item.value}}"> {{item.name}}
            </option>
          </select>

        </div>

        <div class="hfp-input-div">
          <label> Payment Amount($) </label>
          <input class="" type="number" placeholder="00" formControlName="eventPayment" required>
        </div>

        <div class="buttons">
          <button class="btn btn-primary">Submit</button>

        </div>
      </div>
    </form>

  </div>


</ng-template>
<ng-template #exhibTempaltePending>



  <div class="paymentedit-form" style="display: block;">

    <form (ngSubmit)="addPaymentFoEventPending()" [formGroup]="eventPaymentManageFormPending">
      <h2>Edit: {{eventSingleItem.eventName}}</h2>
      <div class="in-inquiries-detail-list ">
        <div class="hfp-input-div">
          <label>Occured On</label>
          <input class="" type="text" placeholder="Occured On" readonly formControlName="eventDate"
            value="{{ eventSingleItem.eventDate | date }}" required>
        </div>

        <!-- <div class="hfp-input-div">
          <label> Payment Status </label>
          <select class="hfp-selectbox" formControlName="paymentStatus">
            <option *ngFor="let item of paymentSpecified" value="{{item.value}}"> {{item.name}}
            </option>
          </select>
        </div> -->
        <div class="hfp-input-div">
          <label> Payment Amount($) </label>
          <input class="" type="number" placeholder="00" value="{{ eventSingleItem.eventPayment}}" formControlName="eventPayment" required>
        </div>

        <div class="buttons">
          <button class="btn btn-primary">Submit</button>

        </div>
      </div>
    </form>

  </div>


</ng-template>
<ng-template #exhibTempaltePaid>

  <div class="paymentedit-form" style="display: block;">

    <form (ngSubmit)="addPaymentFoEventPaid()" [formGroup]="eventPaymentManageFormPaid">
      <h2>Edit: {{eventSingleItem.eventName}}</h2>

      <div class="in-inquiries-detail-list ">

        <div class="hfp-input-div">
          <label>Invoice Number</label>
          <input class="" type="text" placeholder="Invoice Number" formControlName="invoiceNumber"
            value="{{eventSingleItem.invoiceNumber}}" required>
        </div>


        <div class="hfp-input-div">
          <label>Occured On</label>
          <input class="" type="text" placeholder="Occured On" readonly formControlName="created"
            value="{{ eventSingleItem.created | date }}" required>
        </div>

        <!-- <div class="hfp-input-div">
          <label> Payment Amount($) </label>
          <input class="" type="number" placeholder="00"  formControlName="paymentAmount" required>
        </div> -->

        <div class="buttons">
          <button class="btn btn-primary">Submit</button>

        </div>
      </div>
    </form>

  </div>

</ng-template>


<!--Exhibitor contact form tempalte  start here-->
<ng-template #exhibContatct>
  <div class="form-pop-txt">
    <button type="button" (click)="closPopup();" class="popclose" data-dismiss="modal" aria-label="Close"><img
        src="/assets/images/pop-close.png" alt=""></button>
    <div class="contact-to-exhibitor-in clearfix">

      <div class="contact-to-exhibitor-left pull-left" style="width: 100%;">
        <form (ngSubmit)="addMessage()" [formGroup]="contactForm">
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label>Event Name</label>
              <input class="" value="" type="text" placeholder="Event Name" formControlName="eventName" name="eventName"
                required minlength="2" maxlength="200">
            </div>
          </div>
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label>Your Email </label>
              <input class="" value="" type="text" placeholder="Email" formControlName="emailId" name="emailId"
                required>
            </div>
          </div>
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label>Subject </label>
              <input class="" value="" type="text" placeholder="Email Subject" formControlName="subject" name="subject"
                required minlength="2" maxlength="200">
            </div>
          </div>
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label>Message </label>
              <textarea formControlName="message" required></textarea>
            </div>
          </div>
          <div class="buttons">
            <button class="btn btn-primary">Contact</button>
            <button class="btn btn-default" (click)="closPopup();">Close</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</ng-template>

<!--Exhibitor contact form tempalte end here-->
