import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertIntoDash',
    pure: true
})
export class ConvertIntoDash implements PipeTransform {

    transform(value: string, args?: any): any {
        if (value) {
            value = value.replace(/\s/g, '-');
        }
        return value;
    }
}