import { Component, Injector, Input, OnChanges, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { IMenu } from "../client-schema";
import { FoldService } from "../fold/fold.service";
import { LoginService } from "../login/login.service";
import { MenuService } from "../menu/menu.service";
import { staticMenu } from "../menu/MENU_LIST";
import { PageService } from "../page/page.service";
import { AuthService } from "../services/auth.service";
import { GetRouterLinkService } from "../services/getrouter-link.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnChanges {
  // @Input()
  // menuItems = [];

  // @Input()
  pageItems: any[];

  userRole: number = 21;
  isUserLoggedIn: boolean = false;

  selectedRouterLinkIndex: number = -1;

  menuItems = [
    {
      "id": "",
      "menuName": "home",
      "menuSort": 0,
      "menuType": "Footer Menu",
      "linkTo": {
        "type": "P",
        "page": "home"
      },
      "auth": 3,
      "accessLevel": 21,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "about us",
      "menuSort": 0,
      "menuType": "Footer Menu",
      "linkTo": {
        "type": "P",
        "page": "about-us"
      },
      "auth": 3,
      "accessLevel": 21,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "contact us",
      "menuSort": 0,
      "menuType": "Footer Menu",
      "linkTo": {
        "type": "P",
        "page": "contact-us"
      },
      "auth": 3,
      "accessLevel": 21,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "privacy policy",
      "menuSort": 0,
      "menuType": "Footer Menu",
      "linkTo": {
        "type": "P",
        "page": "privacy-policy"
      },
      "auth": 3,
      "accessLevel": 21,
      "status": 1,
    }
  ] as Partial<IMenu[]>;

  foldList;
  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private menuService: MenuService,
    private injector: Injector
  ) {
    this.refreshMenuItemsList();
  }

  get footerContext() {
    return {
      menuItems: this.menuItems,
    };
  }

  getFoldList() {
    let where = {
      filter: {
        fields: {
          foldName: true,
          foldTypeId: true,
          auth: true,
          data: true,
          id: true,
        },
        where: {
          status: 1,
        },
      },
    };
    this.injector.get(FoldService).getFoldList(where).subscribe((foldRes) => {
      this.foldList = foldRes;
    }, (err) => {
      console.error(err);
    })
  }

  ngOnInit() {
    // this.getFoldList()
    this.authLoginSubscriber();
    // this.refreshMenuItemsList();
    this.getUpdatedPageItems();
    this.getMenuListItems();
  }

  ngOnChanges() {
    // this.getFoldList()
    // this.authLoginSubscriber();
    // this.getMenuListItems();
  }

  authLoginSubscriber() {
    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.isUserLoggedIn = isLogin;
          this.getLoginUser();
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  refreshMenuItemsList() {
    this.menuService.menuItemsObservable.subscribe((options: { flag: IMenu, id: string }) => {
      this.getUpdatedPageItems();
      if (options.id) {
        this.updateMenuArray(options);
      } else {
        this.addNewMenu(options);
      }
    });
  }


  addNewMenu(options: { flag: IMenu }) {
    let index = this.menuItems.findIndex((entry) => {
      return entry.menuName === options.flag.menuName
    })
    if (index === -1) {
      this.menuItems.splice(0, 0, options.flag);
    }
  }

  updateMenuArray(options: { flag: IMenu, id: string }) {
    let index = this.menuItems.findIndex((entry) => {
      return entry.id === options.id
    })
    if (index >= 0) {
      const menuId = options.id
      this.menuItems[index] = { ...options.flag, ...{ id: menuId } };
    } 
  }


  getUpdatedPageItems() {
    const pageService = this.injector.get(PageService);
    let where = {
      filter: {
        fields: {
          permalink: true,
          pageName: true,
          auth: true,
          foldId: true,
          accessLevel: true,
          status: true,
          id: true
        },
        where: {
          status: 1
        }
      },
    };
    pageService.getPageList(where).subscribe(
      (pageData: any) => {
        if (pageData && pageData.length) {
          this.pageItems = pageData;
        }
      },
      (err) => console.error(err)
    );
  }

  getMenuListItems() {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        skip: 0,
      },
    };
    this.menuService.getMenuList(queryObj).subscribe(
      (data: any) => {
        this.menuItems.forEach((entry) => {
          data.push(entry)
        })
        this.menuItems = data.sort((firstEl: any, secondEl: any) => {
          return firstEl.menuSort - secondEl.menuSort;
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getRouterLinkFromPageItems(options: { [key: string]: any }) {
    this.selectedRouterLinkIndex = options.index
    if (options.linkTo.url && options.linkTo.type === 'L') {
      this.menuService.setUrlToNavigateLinkToNextPage(options.linkTo.url);
    } else {
      this.injector.get(GetRouterLinkService).getRouterLinkFromPageItemsService({
        linkTo: options.linkTo,
        pageItems: this.pageItems,
        // foldList: this.foldList,
      });
    }
  }

  getLoginUser() {
    this.loginService.getCurrentUser().subscribe((user: any) => {
      this.userRole = user.role;
    });
  }

  isAuthForLoggedIn_And_SpecificUser(auth: number, accessLevel: number, userRole: number) {
    return (auth === 1 && userRole === accessLevel)
  }

  isAuthForLoggedIn_And_PublicUser(auth: number, accessLevel: number) {
    return (auth === 1 && accessLevel === 21)
  }

  isAuthforBoth_And_PublicUser(auth: number, accessLevel: number) {
    return (auth === 3 && accessLevel === 21)
  }
}
