import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExhibitorService {

  public url: string = environment.apiUrl + '/payment-invoices';
  constructor(private http: HttpClient) { }

  /**
   * this service add widget.
   */
 public addPayment(Obj: any): Observable<any> {
   return this.http.post<any>(this.url, Obj);
  }
  public registerPayment(Obj: any): Observable<any> {
    return this.http.post<any>(this.url+"/user", Obj);
   }
   public unregisterPaymentEvent(Obj: any): Observable<any> {
    return this.http.post<any>(this.url+"/unregisterPaymentEvent", Obj);
   }

   
  /**
   * this service send email.
   */
  sendEmail(queryObj: object): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/emailForEventPaymentInvoice', queryObj);
  }
}
