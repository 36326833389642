import { Component, Injector, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { EventService } from "../event/event.service";
import { ReviewService } from "../review-events/review.service";
import { AppComponent } from "../app.component";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { forkJoin } from "rxjs";
import { TaxonomyService } from "../taxonomy/taxonomy.service";
import { Globals } from "../globals";
import { FormBuilder, FormGroup } from "@angular/forms";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { debounceTime } from "rxjs/operators";
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { UserRole } from "../constants/user-role.constants";
import { SystemConstants } from "../constants/system.constants";

@Component({
  selector: "app-admin-event-review",
  templateUrl: "./admin-event-review.component.html",
  styleUrls: ["./admin-event-review.component.scss"],
})
export class AdminEventReviewComponent implements OnInit {
  eventSlug = "";
  tempSlug = "";
  userData: any;
  loginUserRole: any = null;
  adminReviewAttributes: any = {};
  showExhibStatus = false;
  showHfpStatus = false;
  showSummarytatus = false;
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  serviceBaseUrl: string;
  eventReviewSearchForm: FormGroup;
  translatorJson: typeof TranslatorJson;
  currentPage = SystemConstants.DEFAULT_CURRENT_PAGE;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taxonomyService: TaxonomyService,
    private appComponent: AppComponent,
    private eventService: EventService,
    public reviewService: ReviewService,
    private fb: FormBuilder,
    public globals: Globals,
    private injector: Injector
  ) {
    this.route.params.subscribe((params) => {
      if (params["slug"]) {
        this.tempSlug = params["slug"];
      } else {
        this.tempSlug = this.router.url.substr(
          this.router.url.lastIndexOf("/") + 1
        );
      }
    });
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  ngOnInit() {
    // Copy object
    this.adminReviewAttributes = Object.assign(
      {},
      this.globals.adminPaginationAttrs
    );
    // This  is widget  seach form
    this.eventReviewSearchForm = this.fb.group({
      eventName: ["", []],
    });

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   // this.loginUserRole = this.userData.role;
    //   this.loginUserRole = this.appComponent.role;
    //   if (this.tempSlug === "corporate-review" || this.tempSlug === "exhibitors-review") {
    //     if (this.tempSlug === "exhibitors-review") {
    //       this.serviceBaseUrl = this.reviewService.baseUrlExhibReview;
    //       this.getExhibEventReviewListCount();
    //     }
    //     if (this.tempSlug === "corporate-review") {
    //       this.serviceBaseUrl = this.reviewService.baseUrlCorpoReview;
    //       this.getExhibEventReviewListCount();
    //     }
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }


    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.appComponent.userData;
          this.loginUserRole = this.userData.role;
          if(this.loginUserRole === UserRole.SUPER_ADMIN){
              // this.loginUserRole = this.appComponent.role;
              if (this.tempSlug === "corporate-review" || this.tempSlug === "exhibitors-review") {
                if (this.tempSlug === "exhibitors-review") {
                  this.serviceBaseUrl = this.reviewService.baseUrlExhibReview;
                  this.getExhibEventReviewListCount();
                }
                if (this.tempSlug === "corporate-review") {
                  this.serviceBaseUrl = this.reviewService.baseUrlCorpoReview;
                  this.getExhibEventReviewListCount();
                }
              }
          }else{
            this.router.navigateByUrl("/home");
          }
        } else {
          this.router.navigateByUrl("/");
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.eventReviewSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(data=>
      this.getExhibEventReviewListCount()
    )
  }

  showViewExhibReviewDetail(item: any) {
    this.adminReviewAttributes.itemArray = item;
  }

  closeViewExhibReviewDetail() {
    this.adminReviewAttributes.itemArray = {};
    this.showExhibStatus = false;
  }

  /**
   * this function is to get list for exhib event review list
   * @param limitNum number
   * @param skipNum number
   */
  getEventExhibReviewList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: 1,
        },
        limit: limitNum,
        skip: skipNum,
      },
    };

    if (
      this.eventReviewSearchForm &&
      this.eventReviewSearchForm.value &&
      this.eventReviewSearchForm.value.eventName
    ) {
      queryObj.filter.where.eventName = {
        regexp: "m.*" + this.eventReviewSearchForm.value.eventName + "/mi",
      };
    }
    this.reviewService
      .getEventExhibReviewList(queryObj, this.serviceBaseUrl)
      .subscribe(
        (data) => {
          this.adminReviewAttributes.pageItems =
            data && data.length ? data : [];
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
          }
        }
      );
  }

  // Get Exhib Event Review total count
  getExhibEventReviewCount(): void {
    const queryObj: any = { where: { status: 1 } };
    // This statement for serch exhib review count

    if (
      this.eventReviewSearchForm &&
      this.eventReviewSearchForm.value &&
      this.eventReviewSearchForm.value.eventName
    ) {
      queryObj.where.eventName = {
        regexp: "m.*" + this.eventReviewSearchForm.value.eventName + "/mi",
      };
    }
    this.reviewService
      .getEventExhibReviewCounts(queryObj, this.serviceBaseUrl)
      .subscribe(
        (data) => {
          this.adminReviewAttributes.count =
            data && data.count ? data.count : 0;
        },
        (err) => { }
      );
  }

  // Get told  list/count
  getExhibEventReviewListCount(): void {
    forkJoin([
      this.getExhibEventReviewCount(),
      this.getEventExhibReviewList(
        this.adminReviewAttributes.limits,
        this.adminReviewAttributes.skips
      ),
    ]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getEventExhibReviewList(this.adminReviewAttributes.limits, startItem);
  }
}
