import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../email-template/template.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { AppComponent } from '../app.component';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { forkJoin } from 'rxjs';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';
import { NotifierService } from 'angular-notifier';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { UserRole } from '../constants/user-role.constants';
@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {
  emailTempForm: FormGroup;
  emailTempSearchForm: FormGroup;
  emailTempAttributes: any = {};
  emailTempTypePageItems: any = [];
  emailTypeData: any = [];
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  userData: any;
  private notifier: NotifierService;
  translatorJson: typeof TranslatorJson;
  constructor(
    private templateService: TemplateService,
    private comp: AppComponent,
    private router: Router,
    private authService: AuthService,
    public globals: Globals,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private utility: Utility) {
      this.notifier = notifierService;
      this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
    }

  ngOnInit() {
    this.emailTempForm = this.fb.group({
      emailTemplateName: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      slug: ['', [Validators.required]],
      status: [1, [Validators.required]]
    });

    // This  is template  seach form
    this.emailTempSearchForm = this.fb.group({
      emailTemplateName: ['', []]
    });
    // Copy object
    this.emailTempAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // check seesion and userData before service call
    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.comp.userData;
        if(this.userData.role === UserRole.SUPER_ADMIN ){
          this.getEmailTempalteAndTempType();
        }else{
           this.router.navigateByUrl('home')
        }        
      }else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });
  }

  // Manage show/hide teplate  form
  toggleForm(): void {
    if (!(this.emailTempAttributes.isIndex >= 0)) {
      this.emailTempAttributes.isShow = !this.emailTempAttributes.isShow;
    }
    this.emailTempAttributes.submitted = false;
    this.emailTempForm.reset();
    this.emailTempAttributes.isIndex = -1;
    this.emailTempAttributes.isActiveIdx = -1;
    this.emailTempAttributes.itemArray = [];
    this.emailTempAttributes.statusSelected = 1;
    // Set default  radio button value
    this.emailTempForm.get('status').setValue(this.emailTempAttributes.statusSelected);
  }


  // Update Email Tempalte
  updateEmailTemplate(id: string): void {
    if (this.emailTempForm.valid) {
      this.emailTempForm.value.status = Number(this.emailTempForm.value.status);
      this.templateService.updateEmailTemplate(id, this.emailTempForm.value)
        .subscribe(data => {
          this.notifier.notify('success', 'Update Successful!');
          this.getEmailTeplate(id);
          this.emailTempAttributes.isIndex = -1;
          this.emailTempAttributes.isActiveIdx = -1;
          this.emailTempForm.reset();
        }, (err) => {

        });
    } else {
      this.emailTempAttributes.submitted = true;
    }
  }

  // Get single Email tempalte
  getEmailTeplate(id: string): any {
    this.templateService.getEmailTemplateById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.emailTempAttributes.pageItems.findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          this.emailTempAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      }, (err) => {

      });
  }

  // Add Email Template
  addEmailTemplate(): void {
    // Check Email Template validation and save data in db
    if (this.emailTempForm.value && this.emailTempForm.value.emailTemplateName) {
      this.onInputTemplateName(this.emailTempForm.value.emailTemplateName);
      if (this.emailTempForm.valid) {
        this.emailTempForm.value.status = Number(this.emailTempForm.value.status);
        this.templateService.addEmailTempalte(this.emailTempForm.value)
          .subscribe(data => {
            this.notifier.notify('success', 'Added Successful!');
            this.toggleForm();
            this.emailTempForm.reset();
            // Push new object on zero index
            this.emailTempAttributes.pageItems.splice(0, 0, data);
            this.emailTempAttributes.count += 1;
          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              this.notifier.notify('error', err.error.error.message);
            }
          });
      } else {
        this.emailTempAttributes.submitted = true;
      }
    }
  }

  /**
   * This function is used to set pre permalink for event .
   */

  onInputTemplateName(data: string) {
    if (data && data !== '') {
      const perma = data.replace('/email-template/', '').trim().replace(/[^A-Z0-9-]/ig, '-').
        replace(/-{2,}/g, '-').toLowerCase();
      this.emailTempForm.patchValue({
        slug: perma
      });
    }
  }

  // convenience getter for easy access to form fields
  get emailTempf() {
    return this.emailTempForm.controls;
  }

  // Get single Widget  according to index
  getEmailTempForEdit(index: number): void {
    this.toggleForm();
    setTimeout(() => {
      this.emailTempAttributes.isActiveIdx = index;
      this.emailTempAttributes.isIndex = index;  //this.utility.getIndex(index, this.emailTempAttributes.pageItems.length);
      this.emailTempAttributes.isShow = false;
      this.emailTempAttributes.itemArray = this.emailTempAttributes.pageItems[index];
      this.emailTempForm.patchValue(this.emailTempAttributes.itemArray);
      this.emailTempAttributes.statusSelected = this.emailTempAttributes.itemArray.status;
    }, 0);
  }


  // Get Email Teplate type
  getEmailTemplateTypeList(): void {
    const queryObj: any = {
      filter: {
        where: {
          status: 1,
        }
      }
    };
    this.templateService.findEmailTempalteType(queryObj)
      .subscribe(data => {
        this.emailTypeData = (data && data.length) ? data : [];
      }, (err) => {

      });
  }

  // Get Email Teplate type
  getEmailTemplateList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1,0] },
        },
        limit: limitNum,
        skip: skipNum,
      }
    };

    // This statement for serch widget type list
    if (this.emailTempSearchForm && this.emailTempSearchForm.value && this.emailTempSearchForm.value.emailTemplateName) {
      queryObj.filter.where.emailTemplateName = {
        regexp: 'm.*' + this.emailTempSearchForm.value.emailTemplateName + '/mi'
      };
    }

    this.templateService.findEmailTempalte(queryObj)
      .subscribe(data => {
        this.emailTempAttributes.pageItems = (data && data.length) ? data : [];
      }, (err) => {
      });
  }

  // Get Email Teplate count
  getEmailTemplateCounts(): void {
    const queryObj: any = {
        where: {
          status: { inq: [1] }
      }
    };
    // This statement for search counts
    if (this.emailTempSearchForm && this.emailTempSearchForm.value && this.emailTempSearchForm.value.emailTemplateName) {
      queryObj.where.emailTemplateName = {
        regexp: 'm.*' + this.emailTempSearchForm.value.emailTemplateName + '/mi'
      };
    }
    this.templateService.findEmailTempalteCount(queryObj)
      .subscribe(data => {
        this.emailTempAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => {
      });
  }

  /**
   * This function is used to delete email template by record Id
   * @param index number
   */
   deleteEmailTemplate(index: number): void {
    if (index >= 0) {
      this.emailTempForm.patchValue(this.emailTempAttributes.pageItems[index]);
      this.emailTempForm.value.status = Number(2);
      this.templateService.updateEmailTemplate(this.emailTempAttributes.pageItems[index].id, this.emailTempForm.value)
        .subscribe(data => {
          // remove item from array
          this.notifier.notify('success', 'Delete Successful!');
          this.emailTempAttributes.pageItems.splice(index, 1);
          this.emailTempAttributes.count -= 1;
        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify('error', err.error.error.message);
          }
        });
    }
  }

  // This method is used for search widget
  emailTemaplateSearch() {
    if (this.emailTempSearchForm.valid) {
      this.getEmailTempalteAndTempType();
    }
  }

  // Get  list amd temp type /count
  getEmailTempalteAndTempType(): void {
    forkJoin([this.getEmailTemplateTypeList(), this.getEmailTemplateCounts(),
    this.getEmailTemplateList(this.emailTempAttributes.limits, this.emailTempAttributes.skips)]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.emailTempAttributes.isIndex = -1;
    this.emailTempAttributes.isActiveIdx = -1;
    this.getEmailTemplateList(this.emailTempAttributes.limits, startItem);
  }

}
