import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnregisterEventRequestFormComponent } from './component/unregister-event-request-form/unregister-event-request-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneNumberModule } from '../phone-number/phone-number.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [
    UnregisterEventRequestFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PhoneNumberModule,
    TranslateModule,
    NgSelectModule,
    FileUploadModule,
    PdfViewerModule

  ],
  exports: [UnregisterEventRequestFormComponent]
})
export class UnregisterEventRequestFormModule { }
