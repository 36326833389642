<div class="content-txt-page">
  <div class="heading-top">
    <div class="container">
      <h1>About Us</h1>
    </div>
  </div>
  <div class="content-txt-page-btm">
    <div class="container">
     <p>HealthFairsPlus.com finds Virtual wellness tools and information across the web and brings them together in one place for your convenience. </p>
     <p>Much like an onsite health fair or trade show, the information provided on this site comes from a variety of health and wellness professionals. </p>
     <p>Here you will find information from local doctors, nutritionists, personal trainers, non-profit groups, government agencies, and other health organizations world wide!</p>
     <p>HealthFairsPlus.com strives to provide the most interactive experience possible.  Many of our pages contain free health assessments, tools, widgets and/or links to pages with fun and interactive activities.  We are constantly growing and changing.  If you are aware of a unique Virtual health tool or activity, we would love to hear about it.  Please let us know by contacting us here.</p>
    </div>
  </div>
</div>
