import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { SharedService } from '../shared.service';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { ReviewService } from '../review-events/review.service';
import { EventService } from '../event/event.service';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
// Import utility method class
import { Utility } from '../utility';
import { FileUploader } from 'ng2-file-upload';
// Handle the global property
import { NotifierService } from 'angular-notifier';
import { Globals } from '../globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import * as TranslatorJson from '../../assets/i18n/en.json'

@Component({
  selector: 'app-review-events',
  templateUrl: './review-events.component.html',
  styleUrls: ['./review-events.component.scss']
})
export class ReviewEventsComponent implements OnInit {
  userData: any;
  userSlug: string = '';
  userRole: any = null;
  loginUserRole: any = null;
  isAddEvent = '';
  minStartDate = new Date();
  hourStep = 1;
  minuteStep = 15;
  disableTime = true;
  endTime = false;
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  screeningParentList: any = [];
  industriesList: any = [];
  mapRegion: any = [];
  latitude: number;
  totalCountPagination: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  miles = 20;
  allEventData: any = [];
  corporateUserList: any = [];
  editIndex: number = -1;
  eventReqItem: any;
  showEventReviewForm = false;
  singleSelectedItem: any = {};
  eventReviewForm: FormGroup;
  eventsAttributes: any = {};
  reviewEventSearchForm: FormGroup;
  translatorJson: typeof TranslatorJson;
  private notifier: NotifierService;
  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private appComponent: AppComponent,
    private taxonomyService: TaxonomyService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: BsModalService,
    public globals: Globals,
    public reviewService: ReviewService,
    private loginService: LoginService,
    private eventService: EventService,
    private utility: Utility,
    private injector: Injector,
    private ngZone: NgZone
  ) {
    this.notifier = notifierService;
    this.minStartDate.setDate(this.minStartDate.getDate() - 1);
    this.route.params.subscribe((params) => {

      if (params['slug']) {
        this.userSlug = this.router.url.split("/").length == 4 ? this.router.url.split("/")[2] : '';
        this.userSlug = params['slug'];
      } else {
        this.userSlug = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);
      }
    });
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  formIniti() {
    this.eventReviewForm = this.fb.group({
      companyName: [this.userData.data.company_name, [Validators.required]],
      contactName: [this.userData.username, [Validators.required]],
      contactPhone: [this.userData.data.phone_number, [Validators.required]],
      contactEmail: [this.userData.email, [Validators.required]],
      eventSatisfiedRate: this.fb.group({
        parkingCheckinSetup: ['', Validators.required],
        availCorporateStaff: ['', Validators.required],
        availabeSpace: ['', Validators.required],
        overAllHfp: ['', []],
        levelOfEmpAttend: ['', []],
        overSatisfied: ['', []]
      }),
      status: [1, [Validators.required]],
      eventLikeStatus: [1, [Validators.required]],
      approxEmpVisited: [1, [Validators.required]],
      eventCommentsFeedback: ['', [Validators.required]],
    });
  }

  addEventReview(): void {
    if (this.eventReviewForm.valid && this.singleSelectedItem.id) {
      var obj = this.eventReviewForm.value;
      obj.eventLikeStatus = Number(obj.eventLikeStatus);

      obj.eventName = this.singleSelectedItem.eventName;
      obj.eventDate = this.singleSelectedItem.eventDate;
      obj.media = this.singleSelectedItem.media;
      obj.eventSlug = this.singleSelectedItem.permalink;
      obj.eventLocation = this.singleSelectedItem.eventAddress;
      obj.eventId = this.singleSelectedItem.id;
      obj.memberId = this.userData.id;
      this.reviewService.addReview(obj).subscribe(data => {
        this.showEventReviewForm = false;
        this.notifier.notify('success', 'Event Reviewed Successful!');
      }, (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', err.error.error.message);
        }
      });

    }
  }

  toggleReviewForm(): void {
    this.eventReviewForm.reset();
    setTimeout(() => {
      this.formIniti();
    }, 0);

  }

  checkEventReviewed() {
    var obj = { memberId: this.userData.id, eventId: this.singleSelectedItem.id, status: 1 };
    this.reviewService.getCounts(obj).subscribe(data => {
      var counts = data && data.count ? data.count : 0;
      if (counts > 0) {
        this.notifier.notify('success', 'You already reviewed this event!');
      } else {
        this.showEventReviewForm = true;
        this.toggleReviewForm();
      }
    }, (err) => {
    });
  }
  ngOnInit() {
    this.latitude = 20.5937;
    this.longitude = 78.9629;
    this.zoom = 7;
    this.geoCoder = new google.maps.Geocoder;

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   this.formIniti();
    //   if (this.loginUserRole === 20) {
    //     this.getEventList(10, 0);
    //   }
    //   this.eventsAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    //   this.reviewEventSearchForm = this.fb.group({
    //     eventName: ['', []]
    //   });
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        this.formIniti();
        if (this.loginUserRole === 20) {
          this.getEventList(10, 0);
        }
        this.eventsAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
        this.reviewEventSearchForm = this.fb.group({
          eventName: ['', []]
        });
      }
    }, err => {
      console.log(err);
    });
  }

  // This method is used for search widget
  eventSearch() {
    if (this.reviewEventSearchForm.valid) {
      this.getEventList(this.eventsAttributes.limits, this.eventsAttributes.skips)
    }
  }
  // Get Event list for slug
  getEventList(limitNum: number, skipNum: number): void {
    const queryObj: any = { userid: this.userData.id, status: 1, review: 1 };


    // This statement for serch widget type list
    if (this.reviewEventSearchForm && this.reviewEventSearchForm.value
      && this.reviewEventSearchForm.value.eventName) {
      const perma = this.reviewEventSearchForm.value.eventName.replace('/event/', '').trim().replace(/[^A-Z0-9-]/ig, '-').
        replace(/-{2,}/g, '-').toLowerCase();
      queryObj.eventSlug = perma;
      /*{
        regexp: 'm.*' + this.reviewEventSearchForm.value.eventName + '/mi'
      };*/
    }
    //eventDates


    queryObj.limit = limitNum;
    queryObj.skip = skipNum;


    this.eventService.getEventRequestForInvitation(queryObj)
      .subscribe(data => {
        const res = (data && data.length) ? data : [];
        if (res && res.length > 0) {

          res.forEach((v: any, k): any => {
            if (v.event) {
              let mapId = 'map_' + k;
              this.loadListMap(mapId, v.eventAddress);
            }
          });

          this.allEventData = res;
        } else {
          this.allEventData = [];
        }
      }, (err) => { });

    this.eventService.getEventRequestForInvitationCount(queryObj)
      .subscribe(data => {
        this.totalCountPagination = data.count;
      }, (err) => { });
  }



  /**
 * This function is used for pagination
 */
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getEventList(this.eventsAttributes.limits, startItem);
  }
  loadListMap(mapId: string, location: any) {
    setTimeout(() => {
      var map = new google.maps.Map(<HTMLInputElement>document.getElementById(mapId), {
        center: new google.maps.LatLng(location.lat, location.lng),
        zoom: 10
      });
      let contentString = '<div id="content">' +
        '<div id="bodyContent">' +
        '<p>' + location.location + '</p>' +
        '</div>' +
        '</div>';

      let infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      let marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map

      });
      marker.addListener('click', function () {
        infowindow.open(map, marker);
      });
    }, 0);
  }


  // multi request for admin only
  getUserAndEventList(): void {
    forkJoin([this.getEventList(10, 0)]);
  }

}

