<div class="bggray">
  <div class="login-width">
    <div class="login-in">
      <div class="login-left">
        <div class="login-img"><img src="/assets/images/login-left.jpg" /></div>

      </div>
      <div class="login-right" *ngIf="showTemplate == 'login'">
        <h2>{{'LOGIN' | translate}} </h2>

        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="login-input">
            <div class="email-icon-login"></div>
            <input type="text" placeholder="Username or Email" value="" class="login-field"
              formControlName="email" name="email" required>
          </div>

          <div class="login-input password-input" >
            <div class="password-icon-login"></div>
            <div class="password-hide-icon"  (click)="toggleShow()" [ngClass]="status ? 'password-show-icon' : 'danger'">
              <img class="hide-password-img" src="/assets/images/hide-password.png" alt="" />
              <img class="show-password-img" src="/assets/images/show-password.png" alt="" />
            </div>
            <input type="{{type}}" placeholder=" {{'PASSWORD' | translate}}" value="" class="login-field"
              formControlName="password" name="password" required>
          </div>

          <div class="clearfix remember form-check">
            <div class="remember-me">
              <input type="checkbox" name="rememberMe" formControlName="rememberMe" id="checkboxG1"
                class="css-checkbox" />
              <label for="checkboxG1" class="css-label">{{'REMEMBER_ME' | translate}}</label>
            </div>
            <a href="javascript:void(0)" (click)="showForgotPassword()">{{'FORGOT_PASSWORD' | translate}} </a>
          </div>
          <div class="login-submit"><button type="submit">{{'LOGIN' | translate}}</button></div>

        </form>

        <p>{{'IF_YOU_ARE_NOT_REGISTERED' | translate}} <a href="javascript:void(0)"
            (click)="isShow=!isShow">{{'SING_UP_HERE' | translate}}</a></p>

        <div class="clearfix for-signup" *ngIf="isShow">
          <!-- <a [routerLink]="'/signup'">{{'SING_UP_FOR_HOST' | translate}}</a>
          <a [routerLink]="'/signup'">{{'SING_UP_FOR_ATTEND' | translate}}</a> -->
          <a href="" [routerLink]= "['signup', 'host']"  >{{'SING_UP_FOR_HOST' | translate}}</a>
          <a href="" [routerLink]= "['signup' , 'attend']"  >{{'SING_UP_FOR_ATTEND' | translate}}</a>
        </div>
      </div>
      <div class="login-right" *ngIf="showTemplate == 'forgot-password'">
        <h2>Forgot Password </h2>
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword();">
          <div class="login-input">
            <div class="fa fa-envelope"></div>
            <input type="text" formControlName="email"
            placeholder="Email" class="login-field">
            <div *ngIf="forgotPasswordForm.controls['email'].invalid && (forgotPasswordForm.controls['email'].dirty
                    || forgotSubmitted)" class="custom_error">
              <div *ngIf="forgotPasswordForm.controls['email'].errors.required">E-mail is required. </div>
              <!-- <div *ngIf="forgotPasswordForm.controls['email'].errors.pattern">E-mail must be valid. </div> -->
            </div>
          </div>
          <div class="login-submit"  >
            <button type="submit">Submit</button></div>
            <p>{{'IF_YOU_ARE_NOT_LOGIN' | translate}} <a href="javascript:void(0)"
                (click)="loginCall()">{{'LOGIN_HERE' | translate}}</a></p>
        </form>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>
