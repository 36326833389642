
import { Component, OnInit, TemplateRef, ElementRef, Injector } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { ScreeningListService } from "../screening-list/screening-list.service";

import { forkJoin, from } from 'rxjs';
import { AppComponent } from '../app.component';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';
import { NotifierService } from 'angular-notifier';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { SystemConstants } from '../constants/system.constants';

@Component({
  selector: 'app-screening-list',
  templateUrl: './screening-list.component.html',
  styleUrls: ['./screening-list.component.scss']
})
export class ScreeningListComponent implements OnInit {

  modalRef: BsModalRef;
  screeningForm: FormGroup;
  screeningSearchForm: FormGroup;
  sAttributes: any = {};
  screeningParentList: any = [];
  nestedChildId = [];
  userData: any;
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  loginUserRole: any = null;
  translatorJson: typeof TranslatorJson;
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private taxonomyService: TaxonomyService,
    private modalService: BsModalService,
    private elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private injector: Injector,
    private appComponent: AppComponent,
    private utility: Utility) {
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {
    // Screening type add form
    this.screeningForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      parentId: '',
      status: ['', Validators.required]
    });

    // This  is screening  seach form
    this.screeningSearchForm = this.fb.group({
      name: ['', []]
    });
    // Copy object
    this.sAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // Load data

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   if (this.loginUserRole === 0) {
    //     const userId = this.authService.getUserId();
    //     this.getScreeningListCount();
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }


    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        if (this.loginUserRole === 0) {
          const userId = this.authService.getUserId();
          this.getScreeningListCount();
        }else {
          this.router.navigateByUrl('/home');
        }
      }else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });
  }

  get iForm() {
    return this.screeningForm.controls;
  }

  // This method is used for search screening
  screeingSearch() {
    if (this.screeningSearchForm.valid) {
      this.sAttributes.pageItems = [];
      this.getScreeningListCount();
    }
  }
  // Manage show/hide Screening form
  toggleForm(open?:boolean): void {
    if (!(this.sAttributes.isIndex >= 0) && (this.sAttributes.isActiveIdx == -1)) {
      this.sAttributes.isShow = !this.sAttributes.isShow;
    }
    if(open){
      this.sAttributes.isShow = true;
    }
    this.sAttributes.submitted = false;
    this.screeningForm.reset();
    this.sAttributes.isIndex = -1;
    this.sAttributes.isActiveIdx = -1;
    this.sAttributes.childIndex = -1;
    this.sAttributes.itemArray = [];
    this.sAttributes.statusSelected = 1;
    // Set default  radio button value
    this.screeningForm.addControl('parentId', new FormControl());
    this.screeningForm.get('status').setValue(this.sAttributes.statusSelected);
  }

  // Add Screening
  addScreening(): void {
    // Check Screening validation and save data in db
    if (this.screeningForm.valid) {
      this.screeningForm.value.status = Number(this.screeningForm.value.status); //
      let parentId = null;
      if (this.screeningForm.value.parentId == null) {
        this.screeningForm.removeControl('parentId');
      } else {
        parentId = this.screeningForm.value.parentId;
      }
      this.screeningForm.value.taxonomyTypeId = this.globals.taxonomyTypesConst.screeningId;
      this.taxonomyService.addTaxonomy(this.screeningForm.value)
        .subscribe(data => {
          const screeningData = data ? data : {};
          this.screeningParentList.push(screeningData);

          this.sAttributes.pageItems = [];
          this.setListItems();
          this.toggleForm();
        }, (err) => {
        });
    } else {
      this.sAttributes.submitted = true;
    }
  }

  // Update Screening From Taxonomy Collection
  updateScreening(id: string): void {
    if (this.screeningForm.valid) {
      if (this.screeningForm.value.parentId == null) {
        this.screeningForm.removeControl('parentId');
      }
      this.screeningForm.value.status = Number(this.screeningForm.value.status);
      this.screeningForm.value.taxonomyTypeId = this.globals.taxonomyTypesConst.screeningId;
      this.taxonomyService.updateTaxonomy(id, this.screeningForm.value)
        .subscribe(data => {

          this.getSingleScreening(id);
          this.screeningForm.reset();
          this.modalRef.hide();
        }, (err) => {

        });
    } else {
      this.sAttributes.submitted = true;
    }
  }

  // Delete Screening From Taxonomy Collection
  deleteScreeningData(pId: number, cId: number): void {
    const singleItem = this.sAttributes.pageItems[pId][cId];
    this.sAttributes.itemArray = singleItem;
    if (singleItem) {
      this.nestedChildId.push(singleItem.id);
      this.getNestedAllChild(this.screeningParentList, singleItem.id);
    }
    const queryObj: any = {
      where: { id: { inq: this.nestedChildId } }
    };
    const reqBody: any = {
      status: 2
    };
    this.sAttributes.statusSelected = this.sAttributes.itemArray.status;
    if (cId >= 0 && pId >= 0) {
      this.taxonomyService.updateAllTaxonomy(queryObj, reqBody)
        .subscribe(data => {
          this.sAttributes.pageItems[pId].splice(cId, 1);
          this.sAttributes.pageItems.splice(pId + 1, this.sAttributes.pageItems.length);
          this.screeningParentList = this.screeningParentList.filter(id => !this.nestedChildId.includes(id._id));
          this.screeningParentList = this.screeningParentList.filter(id => !this.nestedChildId.includes(id.id));
        }, (err) => {

        });
    }

  }

  //  Get all Child of given parents
  getNestedAllChild(data: any, itemId: string) {
    itemId = itemId || null;
    data.forEach((v: any, i: number) => {
      if (String(v.parentId) === String(itemId)) {
        this.nestedChildId.push(v.id);
        this.getNestedAllChild(data, v.id);
      }
    });
  }

  // Get single Screening
  getSingleScreening(id: string): any {
    this.taxonomyService.getTaxonomyById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.sAttributes.pageItems[this.sAttributes.isActiveIdx].findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          // console.log(getIndex);
          // console.log("dsf",this.sAttributes.isActiveIdx);
          this.screeningParentList[getIndex + 1] = Object.assign({}, cuData);
          this.sAttributes.pageItems[this.sAttributes.isActiveIdx][getIndex].name = cuData.name;
          this.sAttributes.pageItems[this.sAttributes.isActiveIdx][getIndex].status = cuData.status;
          // this.sAttributes.pageItems[this.sAttributes.isActiveIdx][getIndex] = Object.assign({}, cuData);

        }
        this.screeningForm.addControl('parentId', new FormControl());
        this.toggleForm();
      }, (err) => {

      });
  }
  // Get Screening list
  getScreeningList(): void {
    const queryObj: any = {
      filter: {
        where: {
          status: 1,
          taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId
        }
      }
    };
    // This statement for serch screening list
    if (this.screeningSearchForm && this.screeningSearchForm.value && this.screeningSearchForm.value.name) {
      queryObj.filter.where.name = this.screeningSearchForm.value.name;
    }
    this.taxonomyService.getTaxonomyList(queryObj)
      .subscribe(data => {
        this.screeningParentList = [];
        this.screeningParentList = (data && data.length) ? data : [];
        this.setListItems();
      }, (err) => {

      });
  }

  // This is used to set screening list
  setListItems() {
    const screeningData = this.screeningParentList || [];
    this.sAttributes.pageItems = [];
    const parentId = null;
     let getNestedScreeningData = this.getNested(screeningData, parentId)
     if(getNestedScreeningData.length == SystemConstants.DEFAULT_ZERO){
      this.sAttributes.pageItems
     }else{
     this.sAttributes.pageItems.push(getNestedScreeningData);
     }
  }

  //  Get Child Screening From Main Array
  getNested(data: any, parentId: string): any {
    parentId = parentId || null;
    const localeArr = [];
    data.forEach((v, i) => {
      if (String(v.parentId) === String(parentId)) {
        const hasChildData = this.getNested(data, v.id);
        if (hasChildData.length) { data[i].child = hasChildData; }
        localeArr.push(data[i]);
      }
    });
    return localeArr;
  }

  // Set Next level for Screening
  nextlevelChild(parent: number, index: number, haveChild: boolean) {
    this.sAttributes.pageItems.splice(parent + 1, this.sAttributes.pageItems.length);
    if (this.sAttributes.pageItems[parent][index].child) {
      this.sAttributes.pageItems.push(this.sAttributes.pageItems[parent][index].child);
    }
    if (!haveChild) {
      this.sAttributes.pageItems.push([]);
    }
    this.sAttributes.isActiveIdx = parent;
    this.sAttributes.childIndex = index;
  }
  // Open model box of add/edit screening
  open(content: any, data: any, isEdit: boolean): any {
    if (isEdit) {
      // try {
      //   delete data.child;
      // } catch (e) { }
      this.sAttributes.itemArray = data;
      this.screeningForm.patchValue(this.sAttributes.itemArray);
      this.sAttributes.statusSelected = this.sAttributes.itemArray.status;
    } else {
      this.screeningForm.reset();
      this.screeningForm.get('parentId').setValue(data.id);
      this.sAttributes.itemArray.parentId = data.id;
    }
    this.modalRef = this.modalService.show(content);
  }

  // Get told type list/count
  getScreeningListCount(): void {
    forkJoin([this.getScreeningList()]);
  }
}
