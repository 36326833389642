<div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img" *ngIf="userData && userData.image && userData.image.type">
          <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i></div>
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)"
               [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))"
               alt="" />
        </div>
        <h2>{{userData.username}}</h2>
      </div>

      <div class="main-heading-right">
        <h1>Review Event</h1>
      </div>


    </div>
  </div>
</div>



<div class="main-admin-search admin-event-list-search review-events-page-filter">
  <div class="container clearfix">

    <form [formGroup]="reviewEventSearchForm" (ngSubmit)="eventSearch();">
      <div class="main-admin-search-box">
        <input type="text" value="" placeholder="Search Event" formControlName="eventName">
      </div>

      <div class="main-admin-search-button">
        <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
      </div>
    </form>
  </div>
</div>

<!-- Event Listing start here-->
<div class="admin-event-section review-event-list">
  <div class="container">
    <div *ngIf="!allEventData ||  !allEventData.length" class="search-not-found">
       {{translatorJson.NO_RECORD_FOUND | translate }} </div>
    <div class="admin-event-row" *ngIf="isAddEvent==''">
      <div class="admin-event-row-top">
        <div *ngIf="showEventReviewForm">
          <div *ngIf="showEventReviewForm &&  (userData.role == 20); then exhibReviewForm"></div>
        </div>
        <div *ngIf="!showEventReviewForm">
          <div class="event-box clearfix" *ngFor="let localData of allEventData; let idx = index;">
            <div class="hfp-col-8 no-pd-l-r">
              <div class="event-list-left">

                <div class="event-list-img" *ngIf="localData.media && localData.media.length">
                  <img width="100%"
                    [src]="(imageCdn + 'w_750,h_220/' + ((imageBaseUrl + '/event/' + localData.media[0]) | encodeUriComponent))"
                    alt="" />
                </div>
                <div *ngIf="localData.media && !localData.media.length" class="event-list-img"> <img
                    src="/assets/images/event/no-img-event-list.jpg" alt="" /> </div>
                <div class="event-list-txt">
                  <div class="event-list-txt-top clearfix">
                    <h2 class="eventhd">{{localData.eventName}}<span></span></h2>

                  </div>
                  <div class="event-date-box-list clearfix">
                    <span>{{localData.eventDate | date: 'd MMM' }}, {{localData.eventDate | date: 'yyyy' }}</span>
                    <span>{{localData.startDateTime | date:'shortTime'}} &nbsp; - &nbsp;
                    {{localData.endDateTime | date:'shortTime'}}</span>
                  </div>
                  <div class="event-list-txt-btm clearfix">
                    <p [innerHTML]="localData.eventDescription"></p>
                    <div class="event-btm-link">
                      <a class="event-view-detail-btn" (click)="singleSelectedItem =localData; checkEventReviewed();"
                        href="javascript:void(0)"><i></i> Review Event</a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hfp-col-4 no-pd-l-r">
              <div class="event-right-map">
                <div class="event-map"
                  *ngIf="localData.eventAddress.lat != '' && localData.eventAddress.lng !='';else templateName">
                  <img width="100%" src="https://maps.googleapis.com/maps/api/staticmap?center={{localData.eventAddress.location}}&zoom=13&size=600x350&maptype=roadmap
                            &markers=color:green%7Clabel:G%7C{{localData.eventAddress.lat}},
                            {{localData.eventAddress.lng}}&key=AIzaSyD4p1bR-gKmpgFDoSsusigd9oX-SPW8WeA" alt="" />
                </div>
                <div class="event-address">
                  <i><img width="100%" src="/assets/images/event-map-icon.png" alt="" /></i>
                  <span>{{localData.eventAddress.location}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- {{totalCountPagination | json}} -->
          <div class="pagination__box" *ngIf="eventsAttributes.count > eventsAttributes.limits">
            <pagination [totalItems]="totalCountPagination" [rotate]="eventsAttributes.rotate"
              [maxSize]="eventsAttributes.maxSize" [itemsPerPage]="eventsAttributes.limits"
              (pageChanged)="pageChanged($event);">
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Event list end here -->
<ng-template #exhibReviewForm>
  <div class="exhibitor-event-review" *ngIf="singleSelectedItem && singleSelectedItem.id">
    <div class="container">
      <div class="exhibitor-event-review-in">
        <p>Thank you for participating in our recent event. We value your input and look forward to continuing to
          improve our services. To help us maintain a high level of quality, please provide us with feedback by
          completing this evaluation.</p>
        <div class="exhibitor-event-review-form">
          <form (ngSubmit)="addEventReview()" [formGroup]="eventReviewForm">
            <div class="exhibitor-review-input">
              <div class="hfp-input-div">
                <label>Name of the Event</label>
                <input class="" value="{{singleSelectedItem.eventName}}" readonly type="text"
                  placeholder="Name of the Event">
              </div>
            </div>

            <div class="exhibitor-review-input">
              <div class="clearfix hfp-row">
                <div class="hfp-col-6">
                  <div class="hfp-input-div">
                    <label>Event Location/City</label>
                    <input class="" value="{{singleSelectedItem.eventAddress.location}}" type="text" readonly
                      placeholder="Event Location/City">
                  </div>
                </div>
                <div class="hfp-col-6">
                  <div class="hfp-input-div">
                    <label>Event Date [mm/dd/yy] </label>
                    <input class="" value="{{singleSelectedItem.eventDate | date}}" readonly type="text"
                      placeholder="Event Date [mm/dd/yy] ">
                  </div>
                </div>
              </div>
            </div>


            <div class="exhibitor-review-input">
              <div class="hfp-input-div">
                <label>Exhibitor Company </label>
                <input class="" value="{{userData.data.company_name}}" formControlName="companyName" type="text"
                  placeholder="Exhibitor Company ">
              </div>
            </div>


            <div class="exhibitor-review-input">
              <div class="clearfix hfp-row">
                <div class="hfp-col-6">
                  <div class="hfp-input-div">
                    <label>Contact Name </label>
                    <input class="" value="{{userData.username}}" formControlName="contactName" type="text" required
                      placeholder="Contact Name ">
                  </div>
                </div>
                <div class="hfp-col-6">
                  <div class="hfp-input-div">
                    <label>Contact Telephone Number </label>
                    <input class="" value="{{userData.data.phone_number}}" formControlName="contactPhone" type="text"
                      required placeholder="Contact Telephone Number ">
                  </div>
                </div>
              </div>
            </div>


            <div class="exhibitor-review-input">
              <div class="hfp-input-div">
                <label>Contact Email</label>
                <input class="" value="{{userData.email}}" formControlName="contactEmail" type="email" required
                  placeholder="Contact Email">
              </div>
            </div>


            <div class="exhibitor-review-table" id="flip-scroll">
              <strong>How satisfied were you with each of the following:</strong>
              <table width="100%">
                <thead>
                  <tr>
                    <th width="45%"></th>
                    <th>Very Satisfied</th>
                    <th class="numeric">Satisfied</th>
                    <th class="numeric">Neutral</th>
                    <th class="numeric">Dissatisfied</th>
                    <th class="numeric">Very Dissatisfied</th>

                  </tr>
                </thead>
                <tbody formGroupName="eventSatisfiedRate">
                  <tr>
                    <td>Parking, Check-in, and Set-up</td>
                    <td *ngFor="let eventRate of globals.eventSatisficationRate; let idx = index;">
                      <div class="review-radio-btn"><input type="radio" id="switch_left{{idx}}"
                          name="parkingCheckinSetup" formControlName="parkingCheckinSetup" value="{{eventRate.value}}"
                          class="css-checkbox" />
                        <label for="switch_left{{idx}}" class="css-label radGroup1"></label></div>
                    </td>
                  </tr>

                  <tr>
                    <td>Friendliness and Availability of Corporate Staff </td>
                    <td *ngFor="let eventRate of globals.eventSatisficationRate; let idx = index;">
                      <div class="review-radio-btn"><input type="radio" id="switch_left_one{{idx}}"
                          name="availCorporateStaff" formControlName="availCorporateStaff" value="{{eventRate.value}}"
                          class="css-checkbox" />
                        <label for="switch_left_one{{idx}}" class="css-label radGroup1"></label></div>
                    </td>
                  </tr>

                  <tr>
                    <td>Positioning and Available Space for your Booth/Table </td>
                    <td *ngFor="let eventRate of globals.eventSatisficationRate; let idx = index;">
                      <div class="review-radio-btn"><input type="radio" id="switch_left_two{{idx}}" name="availabeSpace"
                          formControlName="availabeSpace" value="{{eventRate.value}}" class="css-checkbox" />
                        <label for="switch_left_two{{idx}}" class="css-label radGroup1"></label></div>
                    </td>
                  </tr>



                  <tr>
                    <td>Overall Organization of the Health Fair </td>
                    <td *ngFor="let eventRate of globals.eventSatisficationRate; let idx = index;">
                      <div class="review-radio-btn"><input type="radio" id="switch_left_three{{idx}}" name="overAllHfp"
                          formControlName="overAllHfp" value="{{eventRate.value}}" class="css-checkbox" />
                        <label for="switch_left_three{{idx}}" class="css-label radGroup1"></label></div>
                    </td>
                  </tr>

                  <tr>
                    <td>Level of Employee Attendance </td>
                    <td *ngFor="let eventRate of globals.eventSatisficationRate; let idx = index;">
                      <div class="review-radio-btn"><input type="radio" id="switch_left_four{{idx}}"
                          name="levelOfEmpAttend" formControlName="levelOfEmpAttend" value="{{eventRate.value}}"
                          class="css-checkbox" />
                        <label for="switch_left_four{{idx}}" class="css-label radGroup1"></label></div>
                    </td>
                  </tr>

                  <tr>
                    <td>Overall, how satisfied were you with this Event? </td>
                    <td *ngFor="let eventRate of globals.eventSatisficationRate; let idx = index;">
                      <div class="review-radio-btn"><input type="radio" id="switch_left_five{{idx}}"
                          name="overSatisfied" formControlName="overSatisfied" value="{{eventRate.value}}"
                          class="css-checkbox" />
                        <label for="switch_left_five{{idx}}" class="css-label radGroup1"></label></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="exhibitor-review-input">
              <div class="hfp-input-div">
                <label>How likely are you to attend this event again?
                </label>
                <div>
                  <div class="switch-field exhibitor-review-tab">
                    <div *ngFor="let likeRate of globals.eventLikeRate; let idx = index;">
                      <input type="radio" id="switch_like{{idx}}" name="eventLikeStatus" required
                        formControlName="eventLikeStatus" value={{likeRate.value}}>
                      <label for="switch_like{{idx}}">{{likeRate.name}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="exhibitor-review-input">
              <div class="hfp-input-div">
                <label>Approximately how many employees visited your booth?</label>
                <input class="" type="text"  placeholder="Approximately" formControlName="approxEmpVisited">
              </div>
            </div>


            <div class="exhibitor-review-input">
              <div class="hfp-input-div txt-height">
                <label>Please provide additional Comments and Feedback regarding your overall evaluation of this event.
                  Everything
                  you say is important to us...and we listen!</label>
                <textarea placeholder="Feedback" formControlName="eventCommentsFeedback" required></textarea>
              </div>
            </div>


            <div class="exhibitor-review-input">
              <div class="buttons">
                <button class="btn btn-primary">Submit</button>
                <button class="btn btn-primary"
                  (click)="toggleReviewForm();showEventReviewForm = false;">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</ng-template>
