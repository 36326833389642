<div class="">
  <div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
    <div class="img-overly"></div>
    <div class="container">
      <div class="main-heading-top clearfix">
        <div class="main-user-info">
          <div class="main-user-img">
            <div class="main-user-img-upload" hidden><input type="file" />
              <i class="fa fa-camera" aria-hidden="true"></i></div>
            <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
            <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

          </div>
          <h2>{{userData.username}}</h2>
        </div>

        <div class="main-heading-right">
          <h1>Event Inquiries : {{totalRequest}}</h1>
        </div>

        <div class="heading_page_type eventinquiriesstatus">
          <ul>
            <!-- <li>
              <a href="javascript:void(0)">
                <div class="paymet-status-right"> <span>{{totalRequest}}</span> All </div>
              </a>
            </li> -->

            <li>
    <a href="javascript:void(0)" (click)="getTotalEventInquiriesAndCount(commonStatus.ACTIVE, systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);;"
    [ngClass]="{'active': eventInqStatus === commonStatus.ACTIVE }" >
      <i><img src="/assets/images/sm-paid-icon.png" alt="" /></i>
      <div class="paymet-status-right"> <span>{{approvedFix}}</span> Accepted </div>
    </a>
            </li>
            <li>
              <a href="javascript:void(0)"
              (click)="getTotalEventInquiriesAndCount(commonStatus.DECLINE, systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);"
               [ngClass]="{'active': eventInqStatus === commonStatus.DECLINE }"> <i><img src="/assets/images/sm-declined-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>{{declineFix}}</span> Declined </div>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)"
              (click)="getTotalEventInquiriesAndCount(commonStatus.PENDING, systemConstants.DEFAULT_LIMIT,systemConstants.DEFAULT_SKIP);"
               [ngClass]="{'active': eventInqStatus === commonStatus.PENDING }"> <i><img src="/assets/images/sm-peding-icon.png" alt="" /></i>
                <div class="paymet-status-right"> <span>{{pendingFix}}</span>Pending</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!--This is search section start here -->
  <div class="main-admin-search event-inquiries-search">
    <div class="container clearfix main__filter__row">
      <div class="main__filter__tag">
        <div class="active-tag-box">
          <i class="active-tag-box-icon"><img src="/assets/images/{{eventInqStatus == 1 ? 'sm-paid-icon.png' : eventInqStatus == 0 ? 'sm-peding-icon.png' : 'sm-declined-icon.png'}}" alt="" /></i>
          <div class="active-tag-box-txt"> {{eventInqStatus == 1 ? 'approved' : eventInqStatus == 0 ? 'pending' : 'declined'}}</div>
        </div>
      </div>
      <div class="pagefilter__section">
       <form [formGroup]="eventInqurieSearchForm" (ngSubmit)="resetSearchForm=false;searchEventEnquiry(eventInqStatus,12,0);">
         <div class="pagefilter__section__in">
           <div class="pagefilter-search-box" *ngIf="eventInqurieSearchForm.value.filterCont != 'date'">
          <input type="text" value="" placeholder="Search" formControlName="fieldName" />
        </div>
           <div class="pagefilter-search-date"
            *ngIf="eventInqurieSearchForm.value.filterCont == 'date'">
             <input class="date-icon"type="text" bsDaterangepicker [minDate]="minStartDate"
              formControlName="eventDate" placeholder="Date Filter" />
           </div>
           <div class="pagefilter-selectbox">
             <select class="custom-selectbox" formControlName="filterCont">
                <option value="">Select Filter Type</option>
                <option *ngFor="let opt of filterOptions" value="{{opt.id}}">{{opt.name}}</option>
              </select>
           </div>
           <div class="pagefilter-search-button">
          <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
        </div>
         </div>
      </form>
      </div>
    </div>
  </div>
  <div class="show_search_filter_data" *ngIf="userData?.role == 0 && enquirySearch == 1">
    <div class="container">
      <div class="s_r">Search Result : <strong>
          <span *ngIf="eventInqStatus == 1">{{approved}}</span>
          <span *ngIf="eventInqStatus == 0">{{pending}}</span>
          <span *ngIf="eventInqStatus == 2">{{decline}}</span>
        </strong></div>
      <div class="s_r_t">

        <ul>
          <li *ngIf="eventInqurieSearchForm.value.eventDate && eventInqurieSearchForm.value.filterCont == 'date'"><span>

            {{eventInqurieSearchForm.value.eventDate[0] | date}}

            -
              {{eventInqurieSearchForm.value.eventDate[1] | date}}</span>

            <a href="javascript:void(0)" (click)="removeEventDate()"></a></li>
          <li *ngIf="eventInqurieSearchForm.value.fieldName && eventInqurieSearchForm.value.filterCont != 'date'"><span>{{eventInqurieSearchForm.value.fieldName}}</span>
            <a href="javascript:void(0)" (click)="removeEventName()"></a></li>
        </ul>
      </div>
    </div>
  </div>
  <!--This is search section end here -->

  <div class="inquiries-pg">
    <div class="container">
      <div *ngIf="!eventReqAttributes.pageItems || !eventReqAttributes.pageItems.length" class="search-not-found">
        {{translatorJson.NO_RECORD_FOUND | translate}} </div>
      <div class="inquiries-row" *ngFor="let item of eventReqAttributes.pageItems;  let idx = index">
        <div class="inquiries-box-top clearfix" *ngIf="showEventListIndx != idx;">
          <div class="inquiries-box-txt clearfix" >
            <div class="inquiries-box-img">
                <img width="100%" [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + item.media[0]) | encodeUriComponent))" alt="" />
              </div>
            <div class="inquiries-box-right">
              <h3>{{item.eventName}}</h3>
              <p>{{item.company}}</p>
              <div class="inquiries-date">{{item.eventDate | date: 'd MMM, yyyy'}}</div>
              <div class="exhibitors-detail-link"><a id="view-inquiries-detail"
                 (click)="getExhibitorList(item._id);eventSingleItem = item ;showEventListIndx= idx;"
                  href="javascript:void(0)">View Detail</a> </div>
            </div>
          </div>
          <div class="inquiries-numbr"><strong>{{item.eventReqCounts}}</strong>Inquries</div>
        </div>
        <div *ngIf="showEventListIndx == idx;then showBox"></div>
      </div>
      <div class="pagination__box" *ngIf="eventReqAttributes.count > eventReqAttributes.limits">
       <pagination [totalItems]="eventReqAttributes.count" [rotate]="eventReqAttributes.rotate" [maxSize]="eventReqAttributes.maxSize"
       [itemsPerPage]="eventReqAttributes.limits" (pageChanged)="pageChanged($event);"  [(ngModel)]="currentPage"></pagination>
      </div>
    </div>
  </div>
</div>


<!-- template for row section -->
<ng-template #showBox>
  <div class="inquiries-box-btm clearfix">
    <div class="inquiries-form-close"><button id="inquiriesclose-1" (click)="showEventListIndx=-1;showExhibListIndx=-1;"><img src="/assets/images/inquiries-close.png" alt=""></button></div>
    <div class="inquiries-box-txt clearfix">
        <div class="inquiries-box-img">
            <img width="100%" src="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + eventSingleItem.media[0]) | encodeUriComponent))" alt="" />
        </div>
        <div class="inquiries-box-right">
        <h3>{{eventSingleItem.eventName}}</h3>
        <p>{{eventSingleItem.company}}</p>
        <div class="inquiries-date">{{eventSingleItem.eventDate | date: 'MMMM d, yyyy'}}</div>
        <div class="exhibitors-detail-link"><a (click)="showEventListIndx=-1;showExhibListIndx=-1;" href="javascript:void(0)">Hide Detail</a> </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="inquiries-detail ">
      <h4 hidden><b>{{eventSingleItem.eventReqCounts}}</b> Inquries</h4>
      <div class="in-inquiries-row" *ngFor="let user of userListOfEventReq;  let idx = index">
        <div class="in-inquiries-box" [ngClass]="{'active':showExhibListIndx == idx}" id="ininquiriesbox5">

          <!-- user lsit for event req start -->

          <div class="in__inquiries__top">
            <div class="in__inquiries__img dsdsd" *ngIf="user && user.image">
              <img *ngIf="user && user.image && user.image.type == 'avatar'" src="/assets/images/avatars/{{user.image.name}}" />

              <img  *ngIf="user && user.image && user.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" src="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + user.image.name) | encodeUriComponent))" alt="" />

            </div>
            <div class="in__inquiries__hd">
              <h3>{{user.username}}</h3>
              <p>{{user.email}}</p>
            </div>
            <div class="in__inquiries__comny">{{user && user.data && user.data.company_name}}</div>
            <div class="in__inquiries__date">{{user.eventReqData.created | date: 'd MMM, yyyy'}}</div>
            <div class="in__inquiries__status">
              <span *ngIf="user.eventReqStatus == 0"><i><img width="20px" src="/assets/images/sm-peding-icon.png" alt=""></i>Pending</span>
              <span *ngIf="user.eventReqStatus == 1"><i><img width="20px" src="/assets/images/sm-paid-icon.png" alt=""></i>  Approved</span>
              <span *ngIf="user.eventReqStatus == 2"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>  Declined</span>
            </div>
            <div class="in__inquiries__dropdown">
              <div class="exhibitors-dropdown">
                <div class="action dropdown " dropdown>
                  <a class="dropdown-toggle" dropdownToggle aria-controls="dropdown-menu" (click)="false">Action </a>
                  <ul class="dropdown-menu"  *dropdownMenu id="dropdown-menu">
                    <li class="event-approve-icon"><a *ngIf="user.eventReqStatus == 0 || user.eventReqStatus == 2" href="javascript:void(0)" (click)="acceptInvitationOfEvent(user.eventReqData.id,idx,user.eventReqStatus);">Accept </a></li>
                    <li class="event-declined-icon"><a *ngIf="user.eventReqStatus == 1" href="javascript:void(0)" (click)="declineInvitationOfEvent(user.eventReqData.id,idx,user.eventReqStatus);">Decline </a></li>
                    <li class="event-declined-icon"><a  href="javascript:void(0)" (click)="deleteInvitationOfEvent(user.eventReqData.id,idx,user.eventReqStatus);"> Delete </a></li>

                    <!--<li class="event-clone-icon"><a href="#">Approve</a></li>-->
                  </ul>
                </div>
              </div>
            </div>
            <div class="in__inquiries__arrow">
              <div class="inquirie-arrow-box active__icon_hide" (click)="userSingleItem = user;showExhibListIndx = idx"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
              <div class="inquirie-arrow-box" *ngIf="showExhibListIndx == idx" (click)="showExhibListIndx = -1"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
            </div>
          </div>

          <!-- user lsit for event req end -->
          <div class="">
            <div *ngIf="showExhibListIndx == idx; then exhibTempalte"></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #exhibTempalte>
  <div class="in-inquiries-box-btm">
    <div class="in-inquiries-detail-list ">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-2.png" alt=""></i>
        <strong>Exhibitor Name</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Exhibitor Name</h3>
        <p>{{userSingleItem.username}}</p>
      </div>
      <div class="clear"></div>
    </div>
    <div class="in-inquiries-detail-list clearfix">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-12.png" alt=""></i>
        <strong>Email</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Email Address
        </h3>
        <p>{{userSingleItem.email}}</p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix" *ngIf="userSingleItem && userSingleItem.eventReqData">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-11.png" alt=""></i>
        <strong>Phone</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Phone
        </h3>
        <p>{{userSingleItem.eventReqData.phone}}</p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix" *ngIf="userSingleItem && userSingleItem.eventReqData">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-2.png" alt=""></i>
        <strong>Contact
          Name
          For the day
          of Event</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Contact
          Name
          For the day
          of Event</h3>
        <p>{{userSingleItem.eventReqData.contactName}}</p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-17.png" alt=""></i>
        <strong>Category</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Category</h3>
        <ul>
          <li>Aerobics</li>
          <li>Pilates</li>
          <li>Self Defense</li>
          <li>Aerobics</li>
          <li>Cancer Awareness</li>
          <li>Exercise and Fitness</li>
        </ul>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix" *ngIf="userSingleItem && userSingleItem.eventReqData">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-18.png" alt=""></i>
        <strong>No. of Tables Required</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>No. of Tables Required</h3>
        <p>{{userSingleItem.eventReqData.numberOfTable}}</p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix" *ngIf="userSingleItem && userSingleItem.eventReqData">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-19.png" alt=""></i>
        <strong>No. of Chairs Required</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>No. of Chairs Required</h3>
        <p>{{userSingleItem.eventReqData.numberOfChairs}}</p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-20.png" alt=""></i>
        <strong>Do you need electricity?
        </strong>
      </div>
      <div class="inquiries-detail-list-r" *ngIf="userSingleItem && userSingleItem.eventReqData">
        <h3>Do you need electricity?
        </h3>
        <p *ngIf="userSingleItem.eventReqData.electricity">
          YES
        </p>
        <p *ngIf="! userSingleItem.eventReqData.electricity">
            NO
          </p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-1.png" alt=""></i>
        <strong>Names of those attending</strong>
      </div>
      <div class="inquiries-detail-list-r" *ngIf="userSingleItem && userSingleItem.eventReqData && userSingleItem.eventReqData.attendees" >
        <h3>Names of those attending</h3>
        <p *ngFor="let attendeItem of userSingleItem.eventReqData.attendees;  let idx = index">
            {{attendeItem.name}} : {{attendeItem.email}}<br />
        </p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix" *ngIf="userSingleItem && userSingleItem.eventReqData">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-21.png" alt=""></i>
        <strong>Additional needs</strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Additional needs (Internet access, additional space, etc.) </h3>
        <ul>
          <li>{{userSingleItem.eventReqData.additionalNeeds}}</li>
        </ul>
      </div>
    </div>
    <!-- <div class="in-inquiries-detail-list clearfix" *ngIf="userSingleItem && userSingleItem.eventReqData">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-1.png" alt=""></i>
        <strong>Raffel Item </strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Names of those attending</h3>
        <ul>
          <li>{{userSingleItem.eventReqData.raffleItem}}</li>
        </ul>
      </div>
    </div> -->
    <div class="in-inquiries-detail-list clearfix" *ngIf="userSingleItem && userSingleItem.eventReqData">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-22.png" alt=""></i>
        <strong>Asked On </strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Asked On</h3>
        <p>{{userSingleItem.eventReqData.created | date: 'MMMM d, yyyy' }} </p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-23.png" alt=""></i>
        <strong>Event </strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Event</h3>
        <p>{{eventSingleItem.eventName}}</p>
      </div>
    </div>
    <div class="in-inquiries-detail-list clearfix">
      <div class="inquiries-detail-list-l">
        <i><img src="/assets/images/exhibitor-list-icon-24.png" alt=""></i>
        <strong>Host
          Company </strong>
      </div>
      <div class="inquiries-detail-list-r">
        <h3>Host
          Company</h3>
          <p>{{eventSingleItem.company}}</p>
      </div>
    </div>
  </div>
</ng-template>
