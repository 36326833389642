import { Component, Injector, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { AboutComponent } from '../about/about.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  userData: any;
  i = 0;
  constructor(private authService: AuthService, private appComponent: AppComponent,
    private router: Router,
    private injector: Injector) { }

  ngOnInit() {
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   if (this.appComponent.role === 0) {
    //     // Load data
    //     this.router.navigateByUrl('/dashboard');
    //   } else {
    //     this.router.navigateByUrl("/");
    //   }
    // }

    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        if (this.userData.role === 0) {
          // Load data
          this.router.navigateByUrl('/dashboard');
        } else {
          this.router.navigateByUrl('/');
        }
      } else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      // this.router.navigateByUrl('/');
    });
  }

}
