<form (ngSubmit)="onSubmit()" [formGroup]="eventRequestForm" autocomplete="off"  id="formId">
  <div class="exhibitors-edit-form">

    <div class="clearfix row exhibitors-row-2">
      <div class="col-sm-6">
        <div class="hfp-input-div">
          <label>{{translateJson.FIRST_NAME | translate}}&nbsp;<span class="span_star">*</span></label>
          <input class="" value="" type="text" placeholder="{{translateJson.FIRST_NAME | translate}}"
            formControlName="firstName" name="firstName">
          <div
            *ngIf="reqForm.firstName.invalid && isSubmitted"
            class="alert alert-danger">
            {{
              'REQUIRED_MSG_WITH_PARAMS' |
              translate: {'name': translateJson.FIRST_NAME | translate}
              }}
            <!-- {{
            getValidationErrors({
            formControl: reqForm.firstName, formControlName: translateJson.FIRST_NAME,
            isTextPattern: true, minLength: 2, maxLength: 60
            })
            }} -->
          </div>
        </div>

      </div>


      <div class="col-sm-6">
        <div class="hfp-input-div">
          <label>{{translateJson.LAST_NAME | translate}}&nbsp;<span class="span_star">*</span></label>
          <input class="" value="" type="text" placeholder="{{translateJson.LAST_NAME | translate}}" name="lastName"
            formControlName="lastName">
          <div *ngIf="reqForm.lastName.invalid && isSubmitted"
            class="alert alert-danger">
            {{
              'REQUIRED_MSG_WITH_PARAMS' |
              translate: {'name': translateJson.LAST_NAME | translate}
              }}
            <!-- {{
            getValidationErrors({
            formControl: reqForm.lastName, formControlName: translateJson.LAST_NAME,
            isTextPattern: true, minLength: 2, maxLength: 60
            })
            }} -->
          </div>
        </div>
      </div>

    </div>

    <div class="hfp-input-div">
      <label>{{'YOUR_EMAIL' | translate}}&nbsp;<span class="span_star">*</span></label>
      <input class="" value="" [readonly]="true" type="email" placeholder="{{'YOUR_EMAIL' | translate}}" name="email"
        formControlName="email" required pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$">

      <div *ngIf="reqForm.email.invalid && isSubmitted" class="alert alert-danger">
        <div *ngIf="reqForm.email.errors.required"> Email is required.</div>
        <div *ngIf="reqForm.email.errors.pattern">Email must be valid Pattern.</div>
      </div>

    </div>
    <div class="clearfix row exhibitors-row-2">
      <div class="col-sm-6">
        <div class="hfp-input-div">
          <label>{{'PHONE' | translate}}&nbsp;<span class="span_star">*</span></label>
          <app-phone-number [phoneNumberFormControlName]="'phone'" [phoneNumberFormGroup]="reqForm.phone.parent">
          </app-phone-number>
          <div *ngIf="reqForm.phone.invalid &&  isSubmitted"
            class="alert alert-danger">
            {{
              'REQUIRED_MSG_WITH_PARAMS' |
              translate: {'name': translateJson.PHONE | translate}
              }}
            <!-- {{
            getValidationErrors({
            formControl: reqForm.phone, formControlName: translateJson.PHONE
            })
            }} -->
          </div>
        </div>

      </div>
      <div class="col-sm-6">
        <div class="hfp-input-div">
          <label>{{translateJson.CONTACT_NAME_DAY_EVENT | translate}}&nbsp;<span class="span_star">*</span></label>
          <input class="" value="" type="text" placeholder="{{translateJson.CONTACT_NAME_DAY_EVENT | translate}}"
            name="contactName" formControlName="contactName">
          <div
            *ngIf="reqForm.contactName.invalid &&  isSubmitted"
            class="alert alert-danger">
            {{
              'REQUIRED_MSG_WITH_PARAMS' |
              translate: {'name': translateJson.CONTACT_NAME_DAY_EVENT | translate}
              }}
            <!-- {{
            getValidationErrors({
            formControl: reqForm.contactName, formControlName: translateJson.CONTACT_NAME_DAY_EVENT,
            isTextPattern: true, minLength: 2, maxLength: 60
            })
            }} -->
          </div>
        </div>

      </div>

    </div>

    <div class="clearfix row exhibitors-row-2">
      <div class="col-sm-6">
        <div class="hfp-input-div">
          <label>{{'CONTACT_NUMBER_DAY_EVENT' | translate}}&nbsp;<span class="span_star">*</span></label>
          <app-phone-number [phoneNumberFormControlName]="'contactNumber'"
            [phoneNumberFormGroup]="reqForm.contactNumber.parent"></app-phone-number>
          <div
            *ngIf="reqForm.contactNumber.invalid &&  isSubmitted"
            class="alert alert-danger">
            {{
              'REQUIRED_MSG_WITH_PARAMS' |
              translate: {'name': translateJson.CONTACT_NUMBER_DAY_EVENT | translate}
              }}
            <!-- {{
            getValidationErrors({
            formControl: reqForm.contactNumber, formControlName: translateJson.CONTACT_NUMBER_DAY_EVENT
            })
            }} -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="hfp-input-div">
      <label>{{'INDUSTRIES' | translate}}&nbsp;<span class="span_star">*</span></label>

      <ng-select [items]="industriesList" bindLabel="name" bindValue="id" [multiple]="true" [searchFn]="searchTaxo"
        [closeOnSelect]="false" placeholder="Select multiple industries" [clearable]="true"
        formControlName="exhibitorReq">
      </ng-select>
      <div *ngIf="reqForm.exhibitorReq.invalid && (reqForm.exhibitorReq.dirty || isSubmitted )"
        class="alert alert-danger">
        <div *ngIf="reqForm.exhibitorReq.errors.required"> {{"INDUSTRY_REQUIRED" | translate}}
        </div>

      </div>

    </div> -->
    <div class="hfp-input-div">
      <label>{{translateJson.SERVICES | translate}}&nbsp;<span class="span_star">*</span></label>
      <ng-select [items]="screeningParentList" bindLabel="name" bindValue="id" [multiple]="true" [searchFn]="searchTaxo"
        [closeOnSelect]="false" [placeholder]="translateJson.SELECT_SERVICES | translate" [clearable]="true"
        formControlName="screenReq">
      </ng-select>
      <div *ngIf="reqForm.screenReq.invalid &&  isSubmitted"
        class="alert alert-danger">
        {{
          'REQUIRED_MSG_WITH_PARAMS' |
          translate: {'name':translateJson.SERVICES | translate}
          }}
        <!-- {{
        getValidationErrors({
        formControl: reqForm.screenReq, formControlName: translateJson.SERVICES
        })
        }} -->
      </div>

    </div>

    <div class="clearfix row exhibitors-row-2">
      <div class="col-sm-6" *ngIf="eventReqItem.eventOptionType === systemConstants.ONSITE">
        <div class="hfp-input-div">
          <label>{{'NO_OF_TABLES' | translate}}&nbsp;<span class="span_star">*</span></label>
          <input class="" value="" type="number" placeholder="{{'NO_OF_TABLES' | translate}}" name="numberOfTable"
            formControlName="numberOfTable" required pattern="^(0|[1-9]\d*)$">
        </div>
        <div *ngIf="reqForm.numberOfTable.invalid && isSubmitted"
          class="alert alert-danger">
          <div *ngIf="reqForm.numberOfTable.errors.required"> {{'REQUIRED' | translate}} </div>
          <div *ngIf="reqForm.numberOfTable.errors.pattern"> {{'ONLY_NUMBER' | translate}} </div>
        </div>
      </div>

      <div class="col-sm-6" *ngIf="eventReqItem.eventOptionType === systemConstants.ONSITE">
        <div class="hfp-input-div">
          <label>{{'NO_OF_CHAIRS' | translate}}&nbsp;<span class="span_star">*</span></label>
          <input class="" value="" type="number" placeholder="{{'NO_OF_CHAIRS' | translate}}" name="numberOfChairs"
            formControlName="numberOfChairs" required pattern="^(0|[1-9]\d*)$">
        </div>
        <div *ngIf="reqForm.numberOfChairs.invalid && isSubmitted"
          class="alert alert-danger">
          <div *ngIf="reqForm.numberOfChairs.errors.required"> {{'REQUIRED' | translate}} </div>
          <div *ngIf="reqForm.numberOfChairs.errors.pattern"> {{'ONLY_NUMBER' | translate}} </div>
        </div>
      </div>
    </div>

    <div class="hfp-input-div" *ngIf="eventReqItem.eventOptionType === systemConstants.ONSITE">
      <label> {{'NEED_ELECTRICITY' | translate}}&nbsp;<span
              class="span_star">*</span></label>
      <div class="switch-field event-status-tab">
        <div class="check-btntype" *ngFor="let item of electricityNeed; let idx = index;">
          <input type="radio" [id]="item.name" formControlName="electricity" name="electricity"
            [value]="item.value" [checked]="item.checked" />
          <label [for]="item.name">{{item.name}}</label>
        </div>
      </div>
        <div *ngIf="reqForm.electricity.invalid &&  isSubmitted"
          class="alert alert-danger">
          {{
            'REQUIRED_MSG_WITH_PARAMS' |
            translate: {'name': translateJson.ELECTRICITY | translate}
            }}
          <!-- {{
            getValidationErrors({
            formControl: reqForm.electricity, formControlName: translateJson.ELECTRICITY
            })
          }} -->
        </div>
    </div>


    <div class="clearfix label-txt exhibitors-row-2">
      <div class="row">
        <div class="col-sm-12"> <label>{{translateJson.ATTENDEES | translate}}&nbsp;<span
              class="span_star">*</span></label></div>
      </div>
      <div formArrayName="attendees">
        <div class="row" *ngFor="let control of attendees.controls; let attendeeIndex = index"
          [formGroupName]="attendeeIndex">
          <div class="col-sm-6">
            <div class="hfp-input-div">
              <input class="" value="" formControlName="name" type="text" placeholder="Full Name">
              <div
                *ngIf="control.get('name').invalid &&  isSubmitted"
                class="alert alert-danger">
                {{
                  'REQUIRED_MSG_WITH_PARAMS' |
                  translate: {'name': translateJson.Name | translate}
                  }}
                <!-- {{
                getValidationErrors({
                formControl: control.get('name'), formControlName: translateJson.Name,
                minLength: 2, maxLength: 60, isTextPattern: true
                })
                }} -->
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="hfp-input-div">
              <input class="" value="" formControlName="email" type="email" placeholder="Email">
              <div
                *ngIf="control.get('email').invalid &&  isSubmitted"
                class="alert alert-danger">
                {{
                  'REQUIRED_MSG_WITH_PARAMS' |
                  translate: {'name': translateJson.EMAIL | translate}
                  }}
                <!-- {{
                getValidationErrors({
                formControl: control.get('email'), formControlName: translateJson.EMAIL,
                isEmailPattern: true
                })
                }} -->
              </div>
            </div>
          </div>
          <div class="hfp-input-div remove-attendees" *ngIf="attendeeIndex > 0">
            <button type="button" (click)="removeOptions(attendeeIndex)">Remove</button>
          </div>

        </div>
      </div>
    </div>
    <div class="hfp-input-div" *ngIf="!(attendees && attendees.controls && attendees.controls.length >= 10)">
      <div class="add-fold-link">
        <a href="javascript:void(0)" (click)="addMoreAttendee()">Add More</a>
      </div>
    </div>


    <div class="hfp-input-div">
      <label>{{translateJson.ADDITIONAL_NEEDS | translate}}</label>
      <textarea style="height: 80px" placeholder="{{translateJson.ADDITIONAL_NEEDS | translate}} "
        name="additionalNeeds" formControlName="additionalNeeds"></textarea>
      <!-- <div
        *ngIf="reqForm.additionalNeeds.invalid && isSubmitted"
        class="alert alert-danger">

        {{
          'REQUIRED_MSG_WITH_PARAMS' |
          translate: {'name': translateJson.ADDITIONAL_NEEDS | translate}
          }} -->
        <!-- {{
        getValidationErrors({
        formControl: reqForm.additionalNeeds, formControlName: translateJson.ADDITIONAL_NEEDS,
        minLength: 2, maxLength: 500
        })
        }} -->
      <!-- </div> -->
    </div>


    <!-- <div class="hfp-input-div" *ngIf="eventReqItem.eventOptionType === systemConstants.ONSITE">
      <label>{{translateJson.RAFFLE_ITEM | translate}}&nbsp;

      </label>
      <input class="" type="text" placeholder="{{translateJson.RAFFLE_ITEM | translate}}" name="raffleItem"
        formControlName="raffleItem"> -->
      <!-- <div
        *ngIf="reqForm.raffleItem.invalid &&  isSubmitted "
        class="alert alert-danger">
        {{
          'REQUIRED_MSG_WITH_PARAMS' |
          translate: {'name': translateJson.RAFFLE_ITEM | translate}
          }} -->
        <!-- {{
        getValidationErrors({
        formControl: reqForm.raffleItem, formControlName: translateJson.RAFFLE_ITEM,
        minLength: 2, maxLength: 500
        })
        }} -->
      <!-- </div> -->
    <!-- </div> -->

    <div class="hfp-input-div" *ngIf="eventReqItem.eventOptionType === systemConstants.VIRTUAL">
      <label>{{translateJson.COPY_AND_PASTE_MEETING | translate}}&nbsp;<span
              class="span_star">*</span></label>
      <textarea style="height: 97px; resize: none;" class="" type="text" placeholder="{{translateJson.COPY_AND_PASTE_MEETING | translate}}"
                 name="virtualEventLink" formControlName="virtualEventLink"></textarea>
        <div
        *ngIf="reqForm.virtualEventLink.invalid &&  isSubmitted"
        class="alert alert-danger">
        {{
          'REQUIRED_MSG_WITH_PARAMS' |
          translate: {'name': translateJson.COPY_AND_PASTE_MEETING | translate}
          }}
        <!-- {{
        getValidationErrors({
        formControl: reqForm.virtualEventLink, formControlName: translateJson.COPY_AND_PASTE_MEETING,
        isUrlPattern: true
        })
        }} -->
      </div>
    </div>

    <div class="hfp-input-div" *ngIf="eventReqItem.eventOptionType === systemConstants.ONSITE">
      <label>{{translateJson.INTERACTIVE_COMPONENT | translate}}</label>
      <input class="" type="text" placeholder="{{translateJson.INTERACTIVE_COMPONENT | translate}}"
        name="interactiveComponent" formControlName="interactiveComponent">
      <!-- <div
        *ngIf="reqForm.interactiveComponent.invalid && isSubmitted"
        class="alert alert-danger">
        {{
          'REQUIRED_MSG_WITH_PARAMS' |
          translate: {'name': translateJson.INTERACTIVE_COMPONENT | translate}
          }} -->
        <!-- {{
        getValidationErrors({
        formControl: reqForm.interactiveComponent, formControlName: translateJson.INTERACTIVE_COMPONENT,
        minLength: 2, maxLength: 500
        })
        }} -->
      <!-- </div> -->
    </div>
    <div class="hfp-input-div event-input" *ngIf="eventReqItem.eventOptionType === systemConstants.VIRTUAL">
      <div class="image__upload__input">
        <label>{{translateJson.UPLOAD_DOCUMENT | translate}} </label>
        <div class="event-upload-img fancyupload-input">
          <div class="file_uploader_box">
            <input #myInput type="file" ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)"
            accept="application/pdf">
            <button class="choose_file_btn">Choose file</button>
          </div>
        
            <div class="event-upload-img-close" *ngIf="exhibitorEventReqDocSrc">
              <button class="pdf-remove-btn" (click)="resetUploadedDocument(myInput)">
                 Remove
              </button>
            </div>
        </div>
        
        <!-- <div
          *ngIf="reqForm.exhibitorEventDocument.invalid && (reqForm.exhibitorEventDocument.dirty || reqForm.exhibitorEventDocument.touched || isSubmitted)"
          class="alert alert-danger">
          {{
            getValidationErrors({
            formControl: reqForm.exhibitorEventDocument, formControlName: translateJson.UPLOAD_DOCUMENT
            })
          }}
        </div> -->
      </div>
    </div>
    <div class="upload-img-editicon" *ngIf="eventReqItem.eventOptionType === systemConstants.VIRTUAL && fileType !== systemConstants.FileMimeType.PDF && exhibitorEventReqDocSrc">
      <img class="imgPlaceholder" [src]="exhibitorEventReqDocSrc" alt="img" />
    </div>
    <div class="pdf-preview-box" *ngIf="eventReqItem.eventOptionType === systemConstants.VIRTUAL && fileType === systemConstants.FileMimeType.PDF && exhibitorEventReqDocSrc">
      <pdf-viewer
        [src]="exhibitorEventReqDocSrc"
        [page]="1"
        [original-size]="false"
        [fit-to-page]="true">
      </pdf-viewer>
    </div>

    <div class="hfp-input-div reminders">
      <label class="field-label">{{'DEFAULT_REMINDERS' | translate}}</label>
      <div class="form-check" *ngFor="let list of defaultValue">
        <input type="checkbox" class="css-checkbox" name="reminder" [checked]="true" id="{{list.id}}"
          (change)="onCheckboxChange(list,$event)">
        <label for="{{list.id}}" class="css-label">
          {{list.value}}
        </label>
      </div>

    </div>
    <div style="padding-left: 0px;" class="form-check">
      <input type="checkbox"  name="checkboxG1" id="checkboxG1"
        class="css-checkbox" formControlName="isCheckBoxChecked" required  />
      <label for="checkboxG1" class="css-label">{{'EVENT_REQUEST_INVITATION' | translate}}</label>
      <div
        *ngIf="reqForm.isCheckBoxChecked.invalid &&  isSubmitted"
        class="alert alert-danger" >
       Check box is required
    </div>
    </div>

    <div class="buttons">
      <input type="submit" value="Submit"  class="btn btn-primary">
      <a href="javascript:void(0)" data-dismiss="modal" aria-label="Close" (click)="closPopup()"
        class="btn btn-default">Cancel</a>
    </div>

  </div>
</form>