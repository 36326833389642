<div class="content-txt-page">
  <div class="heading-top">
    <div class="container">
      <h1>Privacy Policy</h1>
    </div>
  </div>

  
  <div class="content-txt-page-btm">
    <div class="container">
      <p>HealthFairsPlus.com is owned by Health Fairs Plus and will occasionally update its privacy statement to reflect company and customer feedback. We encourage you to periodically review this page to be informed of how we are protecting your information. If you have questions or concerns about our policy, please contact us. Your privacy is important to us. This privacy policy is designed to inform you of what information HealthFairsPlus.com collects, why we collect such information and how we use the information we collect.</p>
      <h3>Collection and use of Information</h3>
      <p>Personally identifiable information means any information that may be used to identify an individual, including, but not limited to, a first and last name; home, billing or other physical address; an email address; phone number or other contact information. When you submit your personally identifiable information to us, you are giving us your consent to the collection, use and disclosure of your personally identifiable information as set forth in this privacy policy.</p>
      <p>As a policy, Health Fairs Plus does NOT sell, trade or rent your personally identifiable information to third-parties, unless you have granted us permission to do so.</p>
      <p>Any health and wellness professional or small business who registers for the database on this site (these business and professionals will be referred to as “exhibitor(s)“) and gets contacted through this site is strongly encouraged to conduct their own research of the group, company, person(s) and/or entity which contacted them.  Health Fairs Plus does its best to monitor all users of HealthFairsPlus.com.  Do NOT assume that all inquires through this site are legitimate invitations into events.  PLEASE MAKE SURE THAT YOU KNOW WHO YOU ARE RESPONDING TO.</p>
      <p>Exhibitors who register on this site do so with the understanding that their profile information (including name, email, phone, website, physical address and any other information the exhibitor provides on the profile registration) will be available for corporations and other persons to view.  CREDIT CARD information not a part of the exhibitor profile and is not shared with third parties.  Exhibitors understand that they may be contacted and/or solicited to attend events and speaking engagements by corporations and companies.  Exhibitors who wish to remove information from their profiles or exhibitors who wish to remove their profiles from this site may do so by submitting a request to Health Fairs Plus HERE </p>
      <p>Exhibitors who fill out an Virtual registration received via email for a single event (including benefit providers) are published on the exhibitor list for the event in which they registered ONLY.  They are not listed anywhere else on this site, nor is their information available to anyone other than the host company of the event in which the exhibitor registered for.  If an exhibitor would like to be listed in a searchable database so that other companies may find their services and such that the exhibitor will be made aware of other events in their area which the exhibitor may be interested in attending, the exhibitor must fill out an exhibitor registration on this site.</p>
      <p>Please note that we have the ability to retain any data that you provide on this website, even if you do not complete your registration or transaction by clicking “Register,” “Submit,” or any similar Action Link. Your contact information (for example, your email address) may be used to contact you a single time with an invitation or information about an upcoming event or to provide sales assistance. By using this website you are agreeing to these terms.</p>
      <h3>Communications</h3>
      <p>If you register at HealthFairsPlus.com with personally identifiable information, this information may be used to communicate to you information regarding our services and events.  Your personally identifiable information may also be used to improve and customize our Web Site’s content, layout and services. These uses improve and customize our interactions with you to better meet your needs.</p>
      <p>If you do not want to receive communications from HealthFairsPlus.com you may elect to opt-out of receiving such information by changing the regions in which you want to receiving event notifications or removing your email address from your profile.</p>
      <p>Internal Use: We may compare and review your personally identifiable information for errors, omissions and accuracy. We may also use your personally identifiable information internally to improve our marketing and promotional efforts, Web Site content and product offerings. Your personally identifiable information may also be used internally to conduct internal research on our users’ demographics, interests, behavior and generally to better understand and improve the usability, performance and effectiveness of our Web Site and our marketing programs.</p>
      <p>Compliance with Law and Fraud Protection: We may collect and disclose any information, including personally identifiable information, we deem necessary, in our sole discretion, to comply with any applicable law, regulation, legal process or governmental request. We may collect and possibly share personally identifiable information and any other information available to us in order to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any personal or as otherwise required by law. We may also exchange information, including personally identifiable information, with other companies and organizations for credit fraud protection and risk reduction.</p>
      <p>Service Providers: We may share your personally identifiable information with authorized third party clients, partners, agents and contractors. For example, if you register for an event through our site, your information on your profile will be shared with the host company of the event so that they may determine whether your business is a good fit for their event.  Examples of other third parties that we may share your personal information with include: webhosting companies, data analysis firms, affiliates, third party consulting or marketing firms and e-mail service providers. These third parties only receive personally identifiable information if such information is needed to perform their function(s), and they are not authorized to use any personally identifiable information for any other purpose(s) other than the purpose(s) set forth by HealthFairsPlus.com. We only provide third-parties with the minimum amount of personally identifiable information necessary to complete the requested service, product or transaction.</p>
      <p>Events: If you choose to participate in an event (Health Fair, seminar, workshop, etc.),  HealthFairsPlus.com may share your personally identifiable information with those organizations participating in the applicable event. Typically, these third parties do not use your information for any other purpose other than to manage the event. In some cases these third-party clients may send you promotional materials, instructions, or reminders about their events.</p>
      <p>IP Address: When you visit our Web Site, HealthFairsPlus.com collects your IP address to, among other things, help diagnose problems with its server, administer and tune the operation of its Web Site, analyze trends, track traffic patterns, gather demographic information for aggregate use, and track the date and duration of each session within our Web Site. Your IP address may also be used in combination with your personally identifiable information for credit fraud protection and risk reduction.</p>
      <p>Data Collection Devices, such as Cookies and Web Beacons: In some instances, HealthFairsPlus.com may collect non-personal data through cookies, web logs, web beacons (also know as pixel gifs or action tags) and other monitoring technologies. Where authorized by us, third parties may use cookies, HealthFairsPlus.com’s web log files, web beacons and other monitoring technologies to compile aggregate statistics on HealthFairsPlus.com’s behalf. The non-personal information that we collect is used to better understand and improve the usability, performance and effectiveness of our Web Site and marketing programs. We may share or transfer non-personally identifiable information with or to our affiliates, licensees and partners. “Cookies” are small pieces of information that are stored by your browser on your computer’s hard drive. We use cookies on certain pages of our Web Site to, among other things, analyze our web page flow, measure promotional effectiveness, deliver you a more customized shopping experience, track visits from our affiliates and partners and allow the use of our Save and Recently Viewed lists. Jewelry.com does not use cookies to store or collect any personally identifiable information. You are always free to decline our cookies if your browser permits; although, by declining the use of cookies you may not be able to use certain features on the Web Site. HealthFairsPlus.com web pages may also contain electronic images know as web beacons. These Web beacons allow HelalthFairsPlus.com to count users who have visited certain web pages.</p>
      <p>Additionally, HealthFairsPlus.com may use third-party advertising or content delivery companies to serve ads or images on our web Site or on other web sites on the World Wide Web. These companies may use web beacons to place a persistent cookie on your computer. Doing this allows the third party to recognize your computer each time you visit certain pages or emails and compiles anonymous information. Information collected through these third-party advertising companies is anonymous.</p>
      <h3>Security</h3>
      <p><a href="mailto:info@healthfairsplus.com">info@healthfairsplus.com</a> is committed to the protection of the personally identifiable information that you share with us. We utilize a combination of security technologies, procedures and organizational measures to help protect your personally identifiable information from unauthorized access, use or disclosure. For example, we store the personally identifiable information you provide on computer servers with limited access. Additionally, when we transfer sensitive personal information (for example, credit card information) over the Internet, we protect it using Secure Sockets Layer (SSL) encryption technology.</p>
      <p>Should you have any difficulty unsubscribing, have any questions, or need assistance, please contact us at info@healthfairsplus.com, 703,743-2735, or 5501 Merchants View Sq., #735 Haymarket VA 20169</p>
     <h3>Your Consent to HealthFairsPlus.com’s Privacy Policy</h3>
      <p>By using this Web Site, you agree to the above privacy policy. This is our entire and exclusive privacy policy and it supersedes any earlier version. We may occasionally update this privacy policy. We encourage you to periodically review this privacy policy to stay informed about how we are protecting your information. Your continued use of this web site constitutes your consent to this privacy policy and any updates.</p>
    </div>
  </div>

</div>
