import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { DataService } from '../../../data.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { FileUploader } from 'ng2-file-upload';
import * as _ from 'lodash';
import { FoldConstants } from '../constants/fold.constants';
import { ManageImage } from 'src/app/interface/manage-image/manage-image';
import { EncodeUriComponentPipe } from 'src/app/pipe/encode-uri-component.pipe';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-manage-image',
  templateUrl: './manage-image.component.html',
  styleUrls: ['./manage-image.component.scss']
})
export class ManageImageComponent implements OnInit {

  @Input() foldData;
  @Output() foldDataSaved: EventEmitter<ManageImage> = new EventEmitter();
  @Input() foldFormData: ManageImage;
  @Input() isManageImageComponent: boolean;
  data: any = {};
  isShowTemp = true;
  tempFoldData: any = [];
  isIndex = -1;
  templateData: any = [];
  filePreviewPath: string;
  holdImgdata: any = [];
  localUrl: any[];
  filePreviewUrl: any[];
  templateForm: FormGroup;
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  fileTypesValue = 'image';
  fileDataType: any = [
    { name: 'Video', value: FoldConstants.VIDEO },
    { name: 'Image', value: FoldConstants.IMAGE }
  ];
  uriPipe = new EncodeUriComponentPipe();
  foldConstants = FoldConstants;
  public uploader: FileUploader = new FileUploader({
    url: environment.apiUrl + '/containers/upload?folderName=fold&fileType=' + this.fileTypesValue
  });

  public uploaderVedio: FileUploader = new FileUploader({
    url: environment.apiUrl + '/containers/upload?folderName=fold&fileType=video'
  });

  constructor(private fb: FormBuilder, private dataservice: DataService, private loadingBarService: LoadingBarService) {
    this.data = { heading: '', subHeading: '', description: '', type: '', file: [] }
  }
  ngOnInit() {
    this.initTemplateForm();
    this.setTemplateValueOnFoldEditForm();
    this.setTemplateFormValueAfterImageUploaded();
    this.setTemplateFormValueAfterVideoUploaded();

  }

  private initTemplateForm(): void {
    this.templateForm = this.fb.group({
      heading: ['', Validators.required],
      subHeading: ['', Validators.required],
      description: ['', Validators.required],
      type: ['', Validators.required],
      file: [null, Validators.required]
    });
  }

  private setTemplateValueOnFoldEditForm(): void {
    if (this.isManageImageComponent) {
      this.templateForm.setValue(this.foldFormData);
    }
    this.fileTypesValue = this.foldFormData.type;
  }

  private setTemplateFormValueAfterImageUploaded(): void {
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const data = JSON.parse(response);

      if (status === 200 && data && data.files && data.files[0] && data.files[0].filename) {
        this.addFoldTemplateAtSubmit(data.files[0].filename);
      } else {
        if (data && data.error && data.error.message) {
          //this.notifier.notify('error', data.error.message);
        } else {
          //this.notifier.notify('error', 'Something went wrong!');
        }
      }
      this.templateForm.reset();
    };
  }

  private setTemplateFormValueAfterVideoUploaded(): void {
    this.uploaderVedio.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploaderVedio.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const data = JSON.parse(response);

      if (status === 200 && data && data.files && data.files[0] && data.files[0].filename) {
        this.addFoldTemplateAtSubmit(data.files[0].filename);
      } else {
        if (data && data.error && data.error.message) {
        } else {
        }
      }
      this.templateForm.reset();
    };
  }

  /**
   * Add fold template data to fold collection
   */
  addData(): void {
    if (this.templateForm.valid) {
      this.loadingBarService.useRef().start();
      this.fileTypesValue = this.templateForm.value.type;
      if (this.fileTypesValue == 'image') { this.uploader.uploadAll(); }
      if (this.fileTypesValue == 'video') { this.uploaderVedio.uploadAll(); }
      this.loadingBarService.useRef().stop();
      this.isShowTemp = false;
    }
  }

  addFoldTemplateAtSubmit(imgName: string): void {
    this.templateForm.value.file = [imgName];
    if (this.templateForm.valid) {
      this.data = this.templateForm.value;
      if (this.templateData && this.templateData.length === 1) {
        this.templateData = [this.data];
      } else if (this.templateData && this.templateData.length > 1) {
        this.templateData[this.templateData.length - 1] = this.data;
      } else {
        this.templateData = this.data;
      }
      this.foldDataSaved.emit(this.templateData);
      this.loadingBarService.useRef().stop();
      this.dataservice.sendTempData(this.templateData);
      this.isShowTemp = false;
    }
  }



  /**
   * Edit Fold Template Data to fold collection
   * @param index
   */
  editTempData(index: number): void {
    this.data = this.templateData[index];
    const tempData = Object.assign({}, this.data);
    this.imageSrc = '';
    if (tempData && tempData.file) {
      try {
        delete tempData.file;
      } catch (error) {
      }
    }
    this.templateForm.patchValue(tempData);
  }

  /**
   * Add More Fold Template Data In An Array
   */
  addMore(): void {
    this.templateForm.reset();
    this.filePreviewPath = '';
    this.data = { 'file': [] };
    this.isShowTemp = true;
    this.templateData.push(this.data);
  }

  /**
   * Delete fold template Data by id from an array
   * @param index
   */
  deleteTempData(index: number): void {
    this.templateData.splice(index, 1);
    this.dataservice.sendTempData(this.templateData);
  }

  /**
   * Send Data to fold component by sendTempData servie
   * @param index
   * @param data
   */
  updateData(index: number): any {
    this.templateData[index] = this.data;
    this.isIndex = -1;
    this.dataservice.sendTempData(this.templateData);
  }
  onImageSelect(event) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageSrc = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  getImageDataType(){
    this.fileTypesValue = this.templateForm.value.type;
  }

  get fileType() {
    return this.templateForm.get("type")
  }

  get fileValue() {
    return this.templateForm.get("file")

  }
  getFileUrl(fileTypeValue: string): string {
    const url = (this.imageCdn + 'w_300,h_300/' + this.uriPipe.transform(this.imageBaseUrl + '/fold/' + this.fileValue.value));
    return this.fileType.value.toLowerCase() == fileTypeValue ? url : '';
  }

}
