import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';
import { SystemConstants } from '../constants/system.constants';
import { CustomValidatorOptions, ErrorsValidatorOptions } from '../interface/common-validators/common-validators.interface';
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { LocalizationService } from './localization.service';
import { isArray } from "lodash";

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private translatorJson: typeof TranslatorJson = Object.assign({}, (TranslatorJson as any).default);
  constructor(private translate: LocalizationService) { }

  private noWhitespaceValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const noWhitespace = String((control.value || '')).trim().length !== 0;
      const isValid = noWhitespace;
      return isValid ? null : { noWhitespaceValidation: true };
    };
  }

  getErrorMessage(options: ErrorsValidatorOptions): string {
    return options.formControl.hasError('required') || options.formControl.hasError('noWhitespaceValidation') ? this.translate.getTranslation("REQUIRED_MSG_WITH_PARAMS", { params: { name: options.formControlName }, areParamsToBeLocalized: true }) :
      options.formControl.hasError('minlength') ? this.translate.getTranslation("MIN_LENGTH_MSG_WITH_PARAMS", { params: { label: options.formControlName, value: options.minLength }, areParamsToBeLocalized: true }) :
        options.formControl.hasError('maxlength') ? this.translate.getTranslation("MAX_LENGTH_MSG_WITH_PARAMS", { params: { label: options.formControlName, value: options.maxLength }, areParamsToBeLocalized: true }) :
          options.formControl.hasError('pattern') && options.isTextPattern ? this.translate.getTranslation(this.translatorJson.ONLY_CHARACTER) :
            options.formControl.hasError('pattern') && options.isEmailPattern ? this.translate.getTranslation(this.translatorJson.EMAIL_INVALID) :
              options.formControl.hasError('pattern') && options.isNumericPattern ? this.translate.getTranslation(this.translatorJson.ONLY_NUMBER) :
                options.formControl.hasError('pattern') && options.isPasswordPattern ? this.translate.getTranslation(this.translatorJson.PASSWORD_PATTERN_VALIDATION) :
                  options.formControl.hasError('pattern') && options.isUrlPattern ? this.translate.getTranslation(this.translatorJson.INVALID_URL) :
                    options.formControl.hasError('invalidNumber') ? this.translate.getTranslation(this.translatorJson.NOT_A_VALID_PHONE) :
                      options.formControl.hasError('maxlength') ? this.translate.getTranslation("MAX_LENGTH_MSG_WITH_PARAMS", { params: { label: options.formControlName, value: options.maxLength }, areParamsToBeLocalized: true }) :
                  options.formControl.hasError('isValidMultiEmail') ? options.formControl.errors.isValidMultiEmail:
                  '';
  }

  setCustomValidatorMethods(options?: Partial<CustomValidatorOptions>): (ValidatorFn | null | undefined)[] {
    if (!isEmpty(options)) {
    const commonValidationErrors = []
      if (!options.disableRequired) {
      commonValidationErrors.push(Validators.required);
    }
    if (options.isNoWhitespaceValidation)
      commonValidationErrors.push(this.noWhitespaceValidation())
    if (options.isNumericPattern) {
      commonValidationErrors.push(Validators.pattern(SystemConstants.NUMERIC_PATTERN));
    }
    if (options.minLength) {
      commonValidationErrors.push(Validators.minLength(options.minLength))
    }
    if (options.maxLength) {
      commonValidationErrors.push(Validators.maxLength(options.maxLength))
    }
    if (options.isEmailPattern) {
      commonValidationErrors.push(Validators.pattern(SystemConstants.EMAIL_PATTERN))
    }
    if (options.isUrlPattern) {
      commonValidationErrors.push(Validators.pattern(SystemConstants.URL_PATTERN))
    }
    if (options.isTextPattern) {
      commonValidationErrors.push(Validators.pattern(SystemConstants.TEXT_PATTERN))
    }
    if (options.isPasswordPattern) {
      commonValidationErrors.push(Validators.pattern(SystemConstants.PASSWORD_PATTERN))
    }
    if(options.isMultiEmailValidation){
      commonValidationErrors.push((control: AbstractControl): { isValidMultiEmail: string } | null => {
        let invaildEmails:string='';
        let eMailIndex =0;
        if(control.value){
          let emails:string[];
          if(typeof control.value === "string"){
            emails =control.value.trim().split(",");
          }else{
            emails =control.value.trim();
          }
        emails.forEach(emailId => {

          let isValidEmail = this.validateEmail(emailId.trim());
           if(!isValidEmail){
            if(eMailIndex>=1)invaildEmails += ','
            invaildEmails +=emailId
            eMailIndex++;
          }        
        });
       }
        
      return invaildEmails ? { isValidMultiEmail: this.translatorJson.EMAIL_IS_INVALID }  : null;
      })
    }
    return commonValidationErrors as (ValidatorFn | null | undefined)[];
    }
  }

  validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        SystemConstants.EMAIL_PATTERN
      ) ? true : false;
  };

  getTodayStartDate(){
      var todayStartDate = new Date();
     todayStartDate.setDate(todayStartDate.getDate() - 1);
     todayStartDate.setHours(0, 0, 0, 0);
      return todayStartDate;
  }
  getExpiryDate(){
     var end = new Date();
     end.setDate(end.getDate() + 999999);
     end.setHours(0, 0, 0, 0);
     return end;
 
}

  ConditionalValidator(predicate, validator) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    if(isArray(validator)) {
      validator = Validators.compose(validator)
    }
    if (predicate()) {
      return validator(formControl); 
    }
    return null;
  })
}
}
