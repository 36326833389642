<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)"
            [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))"
            alt="" />

        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Find A Professional</h1>

      </div>
    </div>
  </div>
</div>


<div class="find-exhibitors-page">
  <div class="container">
    <div class="findexhibitors-form">
      <div class="findexhibitors-form-top clearfix" id="findexhibitors-form-top" *ngIf="!showAdvance">
        <form [formGroup]="findExhibSearchForm" (ngSubmit)="exhibitorSearch();">
          <div class="search-exhibitor-input">
            <input type="text" value="" placeholder="Search Exhibitors" formControlName="username"
              (keyup)="exhibitorSearch()" />
          </div>
          <div class="main-admin-search-button">
            <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
          </div>
        </form>
        <div class="advance-search-button">
          <a href="javascript:void(0);" (click)="showAdvance = !showAdvance" id="exhibitors-advance-btn">Advance
            Search</a>
        </div>

      </div>

      <div class="find-exhibitors-form" id="exhibitors-advance-search-form" *ngIf="showAdvance">
        <form [formGroup]="findExhibAdvanceSearchForm"
          (ngSubmit)="exhibitorSearchAdvance(10, 0);exhibitorSearchAdvanceCount();">

          <div class="find-exhibitors-input hfp-row clearfix">
            <div class="hfp-col-6">
              <div class="hfp-input-div">
                <label>Industry</label>
                <ng-select [items]="industriesList" bindLabel="name" [closeOnSelect]="false" bindValue="id"
                  [addTag]="addCustomUser" [multiple]="false" placeholder="All Industries" (data)="refreshValue($event)"
                  (selected)="selected($event)" (removed)="removed($event)" (typed)="typed($event)"
                  formControlName="industry">
                </ng-select>
              </div>
            </div>
            <div class="hfp-col-6">
              <div class="hfp-input-div">
                <label>State</label>
                <ng-select [items]="stateList" bindLabel="name" bindValue="name" [addTag]="addCustomUser"
                  [multiple]="false" placeholder="State" formControlName="state">
                </ng-select>
              </div>
            </div>
          </div>

          <div class="find-exhibitors-input hfp-row clearfix">
            <div class="hfp-col-6">
              <div class="hfp-input-div">
                <label>City</label>
                <ng-select [items]="cityList" bindLabel="name" bindValue="name" [multiple]="false" placeholder="City"
                  formControlName="city">
                </ng-select>
              </div>
            </div>
            <div class="hfp-col-6">
              <div class="hfp-input-div">
                <label>{{translatorJson.COMPANY_NAME | translate}}</label>
                <input type="text" placeholder="{{translatorJson.COMPANY_NAME | translate}}" formControlName="company_name">
              </div>
              <div *ngIf="companyName.invalid &&  isSubmitted"
                class="custom_error">
                {{
                  'REQUIRED_MSG_WITH_PARAMS' |
                  translate: {'name': translatorJson.COMPANY_NAME | translate}
                  }}
                <!-- {{
                getValidationErrors({
                formControl: companyName, formControlName:translatorJson.COMPANY_NAME,
                minLength: 2, maxLength: 60,
                isTextPattern: true
                })
                }} -->
              </div>
            </div>
          </div>

          <div class="find-exhibitors-input find-exhibitors-default clearfix form-check">
            <b>Default Reminders</b>
            <div class="find-exhibitors-check">
              <input type="checkbox" name="checkboxG1" id="checkboxG1" class="css-checkbox" formControlName="health">
              <label for="checkboxG1" class="css-label">Attends health fairs ?</label>

            </div>

            <div class="find-exhibitors-check">
              <input type="checkbox" name="checkboxG1" id="checkboxG2" class="css-checkbox" formControlName="workshop">
              <label for="checkboxG2" class="css-label">Offers seminars or workshops ?</label>

            </div>

            <div class="find-exhibitors-check">
              <input type="checkbox" name="checkboxG1" id="checkboxG3" class="css-checkbox" formControlName="screening">
              <label for="checkboxG3" class="css-label">Offers health screenings ?</label>

            </div>

            <div class="find-exhibitors-check">
              <input type="checkbox" name="checkboxG1" id="checkboxG4" class="css-checkbox" formControlName="services">
              <label for="checkboxG4" class="css-label">Offers their services for free ?</label>

            </div>

          </div>

          <div class="buttons button-position">
            <!-- <a class="btn btn-primary">Search</a> -->
            <button class="outline-btn btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
            <div class="back-to-from"><a href="javascript:void(0);" (click)="showAdvance = !showAdvance"
                id="backto-search"><i class="fa fa-angle-left" aria-hidden="true"></i> MAIN SEARCH</a> </div>

          </div>

        </form>

      </div>

    </div>
    <div class="f-e-top">
      <p>Looking for something specific for your event? Search for it here or reach out to us at
        <a href="mailto:events@healthfairsplus.com">events@healthfairsplus.com</a>.
        Health Fairs Plus does not guarantee that any exhibitors is available for your event.
        The exhibitors in our database are independent businesses and set their own schedules and availability for
        events.
      </p>
    </div>
    <div class="find-exhibitors-list">
      <!--User Exibitor Listing section start from here  -->
      <div class="">
        <div *ngIf="!exhibAttributes.pageItems.length" class="search-not-found">{{'NO_RECORD_FOUND' | translate}}</div>
        <div class="exhibitors-row-list" *ngFor="let user of exhibAttributes.pageItems;  let idx = index">
          <div id="editexhibitorstop" [hidden]="(editIndex == idx || exhibAttributes.isIndex == idx)"
            class="exhibitors-row-list-top clearfix">
            <div class="admin-exhibitors-img">
              <a href="javascript:void(0)">
                <img *ngIf="user.image && user.image.type == 'avatar'"
                  src="/assets/images/avatars/{{user.image.name}}" />
                <img *ngIf="user.image && user.image.type != 'avatar'" width="100%"
                  (error)="handleImgError($event, item)"
                  [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/users/' + user.image.name) | encodeUriComponent))"
                  alt="" />
              </a>
            </div>
            <div class="admin-exhibitors-txt">
              <h3><a href="javascript:void(0)">{{user && user.data && user.data.company_name}} </a>
                <!-- <span><img src="/assets/images/review-star.png"
                    alt=""></span> -->

              </h3>
              <div class="exhibitors-tags">
                <ul>
                  <li *ngFor="let industry of user.industries">{{industry.name}}</li>
                  <!--  <li>Office Yoga</li>
              <li>Seated Massage</li>-->
                </ul>
              </div>
              <div class="admin-exhibitors-review">{{user.email}}</div>
              <div class="exhibitors-detail-link"><a id="view-ex-detail" href="javascript:void(0)"
                  (click)="getMember(idx);exhibAttributes.isIndex =idx;getScreeningTaxo()">{{'VIEW_DETAIL' | translate}}</a> </div>


            </div>
            <div class="find-exhibitors-top-r">
              <ul>
                <li><strong
                    *ngIf="user.address && user.address.lat > 0">{{math.round(distance(user.address.lat,user.address.lng,userData.address.lat,userData.address.lng,'N'))}}
                    <i>Miles</i></strong> </li>
                <li><a href="javascript:void(0)" (click)="openRequestPopUp(exhibContatct,user);">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="corporate-users-list-b" *ngIf="exhibAttributes.isIndex ==idx">
            <div *ngIf="exhibAttributes.isIndex ==idx; then memberDetailForm"></div>
          </div>
        </div>
      </div>
      <!-- {{this.exhibAttributes | json}} -->
      <!-- User Exibitor Listing section end from here-->
      <div class="pagination__box" *ngIf="exhibAttributes.count > 10">
        <pagination [totalItems]="exhibAttributes.count" [rotate]="exhibAttributes.rotate"
          [maxSize]="exhibAttributes.maxSize" [itemsPerPage]="exhibAttributes.limits"
          (pageChanged)="pageChangedRecord($event);">
        </pagination>
      </div>
    </div>
  </div>
</div>


<!--User Detail template Start here -->
<ng-template #memberDetailForm>
  <div class="form-close"><button id="paymtclose-2" (click)="exhibAttributes.isIndex = -1;dropIndex = -1"><img
        src="/assets/images/add-form-close.png" alt=""></button></div>

  <div [ngClass]="{'exhibitors-list-row-detail': userRole==20,'corporate-users-list-a': userRole == 10 }"
    id="corporate-users-details-b-1">

    <!--<div class="form-close"><button id="paymtclose-3"><img src="/assets/images/add-form-close.png" alt=""></button></div>-->
    <div class="corporate-step-1">
      <div class="lmd-sm-4 pull-right">
        <div class="corporate-users-list-bg">


          <div class="users-list-bg-details" *ngIf="exhibAttributes.itemArray && exhibAttributes.itemArray.image">
            <i>
              <img _ngcontent-c4="" alt="" src="/assets/images/exhibitors-right-icon.png">
            </i>
            <h5>Exhibitor Information</h5>
            <p>Exhibitor information is provided by the exhibitors and not guaranteed by Health Fairs Plus. We highly
              recommend that you view the websites of all exhibitors and reach out to any exhibitor direct should you
              have any questions or concerns.</p>
          </div>
        </div>

      </div>
      <div class="lmd-sm-8 pull-left">
        <div class="corporate-users-step">
          <div class="corporate-users-s-l">
            <div class="c-u-l-img" *ngIf="exhibAttributes.itemArray && exhibAttributes.itemArray.image">
              <img *ngIf="exhibAttributes.itemArray.image.type == 'avatar'"
                src="/assets/images/avatars/{{exhibAttributes.itemArray.image.name}}" />
              <img *ngIf="exhibAttributes.itemArray.image.type !== 'avatar'" width="100%"
                (error)="handleImgError($event, item)"
                [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/users/' + exhibAttributes.itemArray.image.name) | encodeUriComponent))"
                alt="" />
            </div>
            <div class="c-u-l-text">
              <h3>{{exhibAttributes.itemArray.city}}</h3>
              <p>{{exhibAttributes.itemArray.data.contact}}<br>{{exhibAttributes.itemArray.email}}</p>
              <div class="exhibitors-detail-link"><a id="hide-exhibitors" href="javascript:void(0)"
                  (click)="exhibAttributes.isIndex  = -1;dropIndex = -1">{{'HIDE_DETAILS' | translate}}</a> </div>
            </div>
            <div class="clear"></div>
          </div>
          <!-- <div class="orporate-users-s-h">
            <h2>Atlantic Media Payment </h2>
          </div> -->

          <div class="corporate-step-form">
            <h3 *ngIf="userRole== 10">{{'COMPANY_INFO' | translate}}</h3>
            <div class="view-exhibitor-box-list">
              <div class="view-exhibitor-box-row clearfix">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole == 10 ? 'list-icon-1.png' : 'corporate-list-icon-1.png'}}"
                      alt=""></i> <strong>{{'LOCATION' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r" *ngIf="exhibAttributes.itemArray.address">
                  <h3>{{'LOCATION' | translate}}</h3>
                  <p><span
                      *ngIf="exhibAttributes.itemArray.address.city">{{exhibAttributes.itemArray.address.city}}</span><span
                      *ngIf="exhibAttributes.itemArray.address.state">,{{exhibAttributes.itemArray.address.state}}</span><span
                      *ngIf="exhibAttributes.itemArray.address.zip_code">,{{exhibAttributes.itemArray.address.zip_code}}</span>
                  </p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-2.png' : 'corporate-list-icon-2.png'}}"
                      alt=""></i> <strong>{{'NAME' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r">
                  <h3>{{'NAME' | translate}}</h3>
                  <p>{{exhibAttributes.itemArray.username}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-3.png' : 'corporate-list-icon-11.png'}}"
                      alt=""></i> <strong>{{'PHONE' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r" *ngIf="exhibAttributes.itemArray.data">
                  <h3>{{'PHONE' | translate}}</h3>
                  <p>{{exhibAttributes.itemArray.data.phone_number}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-4.png' : 'corporate-list-icon-12.png'}}"
                      alt=""></i> <strong>{{'EMAIL' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r">
                  <h3>{{'EMAIL' | translate}}</h3>
                  <p>{{exhibAttributes.itemArray.email}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix">
                <div class="view-exhibitor-list-l"> <i><img
                      src="/assets/images/{{userRole ==10 ? 'list-icon-5.png' : 'corporate-list-icon-13.png'}}"
                      alt=""></i> <strong>{{'WEBSITE' | translate}}</strong> </div>
                <div class="view-exhibitor-list-r" *ngIf="exhibAttributes.itemArray.data">
                  <h3>{{'WEBSITE' | translate}}</h3>
                  <p>{{exhibAttributes.itemArray.data.website}}</p>
                </div>
              </div>
              <div class="view-exhibitor-box-row clearfix {{userRole == 10 ? 'a' : 'exhibitor-color'}}">
                <div class="view-exhibitor-list-l">
                  <i><img src="/assets/images/corporate-list-icon-17.png" alt=""></i>
                  <strong class="">{{'SERVICES_OFFERED' | translate}}</strong>
                </div>
                <div class="view-exhibitor-list-r">
                  <h3>{{'SERVICES_OFFERED' | translate}}
                  </h3>
                  <ul *ngIf="exhibAttributes && exhibAttributes.itemArray && exhibAttributes.itemArray.screening">
                    <li *ngFor="let service of exhibAttributes.itemArray.screening">{{ getServices(service) }}</li>
                  </ul>
                </div>
              </div>
              <div *ngIf="userRole == 20">
                <div class="view-exhibitor-box-row clearfix">
                  <div class="view-exhibitor-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-14.png" alt=""></i>
                    <strong>Industries</strong>
                  </div>
                  <div class="view-exhibitor-list-r">
                    <h3>Industries
                    </h3>
                    <ul>
                      <li *ngFor="let indutry of exhibAttributes.itemArray.industries">{{indutry.name}}</li>
                    </ul>
                  </div>
                </div>
                <div class="view-exhibitor-box-row clearfix">
                  <div class="view-exhibitor-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-15.png" alt=""></i>
                    <strong>Areas Exhibitor Attends Events</strong>
                  </div>
                  <div class="view-exhibitor-list-r" *ngIf="exhibAttributes.itemArray.data">
                    <h3>Areas Exhibitor Attends Events
                    </h3>
                    <p>{{exhibAttributes.itemArray.data.attendevent}}</p>
                  </div>
                </div>
                <div class="view-exhibitor-box-row clearfix">
                  <div class="view-exhibitor-list-l">
                    <i><img src="/assets/images/exhibitor-list-icon-15.png" alt=""></i>
                    <strong>Watched Regions</strong>
                  </div>
                  <div class="view-exhibitor-list-r">
                    <h3>Watched Regions
                    </h3>
                    <ul>
                      <li *ngFor="let watch of exhibAttributes.itemArray.region">{{watch.name}}</li>
                      <!-- <li>Canada</li>
                    <li>Delaware</li>
                    <li>Georigia</li>-->

                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div class="clear"></div>
    </div>
  </div>

</ng-template>
<!--USer Detail template end here -->

<!--Exhibitor contact form tempalte  start here-->
<ng-template #exhibContatct>

  <div class="form-pop-txt">
    <button type="button" (click)="closPopup();" class="popclose" data-dismiss="modal" aria-label="Close"><img
        src="/assets/images/pop-close.png" alt=""></button>
    <div class="contact-to-exhibitor-in clearfix">
      <div class="contact-to-exhibitor-right pull-right">
        <h2 *ngIf="exhibAttributes.itemArray && exhibAttributes.itemArray.data"> <span>Connect to</span>
          {{exhibAttributes.itemArray.data.company_name}}</h2>
        <div class="contact-to-exhibitor-txt"> <i><img src="/assets/images/connect-exhibitors-pop-icon.png" alt=""></i>

        </div>
      </div>
      <div class="contact-to-exhibitor-left pull-left">
        <form (ngSubmit)="addMessage()" [formGroup]="contactForm">
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label>{{translatorJson.EVENT_NAME | translate}}</label>
              <input class="" value="" type="text" placeholder="{{translatorJson.EVENT_NAME | translate}}" formControlName="eventName"
                name="eventName">
            </div>
            <div *ngIf="eventName.invalid &&  isSubmitted"
              class="custom_error alert alert-danger">
              {{
                'REQUIRED_MSG_WITH_PARAMS' |
                translate: {'name': translatorJson.EVENT_NAME | translate}
                }}
              <!-- {{
              getValidationErrors({
              formControl: eventName, formControlName:translatorJson.EVENT_NAME,
              minLength: 2, maxLength: 60,
              isTextPattern: true
              })
              }} -->
            </div>
          </div>
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label>Your Email </label>
              <input class="" value="" type="text" placeholder="Email" readonly formControlName="emailId" name="emailId"
                required>
            </div>
            <div *ngIf="contactForm.controls['emailId'].invalid &&  eventSubmit.submitted" class="alert alert-danger">
              <div *ngIf="contactForm.controls['emailId'].errors.required">Email is required.</div>

            </div>
          </div>
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label> {{translatorJson.SUBJECT | translate}}</label>
              <input class="" value="" type="text" placeholder="{{translatorJson.SUBJECT | translate}}"
                formControlName="subject" name="subject">
            </div>
            <div *ngIf="subject.invalid && isSubmitted"
              class="custom_error alert alert-danger">
              {{
                'REQUIRED_MSG_WITH_PARAMS' |
                translate: {'name': translatorJson.SUBJECT | translate}
                }}
              <!-- {{
              getValidationErrors({
              formControl: subject, formControlName: translatorJson.SUBJECT,
              minLength: 2, maxLength: 60,
              isTextPattern: true
              })
              }} -->
            </div>
          </div>
          <div class="contact-to-exhibitor-input">
            <div class="hfp-input-div">
              <label> {{translatorJson.MESSAGE_TYPE | translate}}</label>
              <textarea formControlName="message" required></textarea>
            </div>
            <div *ngIf="message.invalid &&  isSubmitted"
              class="custom_error alert alert-danger">
              {{
                'REQUIRED_MSG_WITH_PARAMS' |
                translate: {'name': translatorJson.MESSAGE_TYPE | translate}
                }}
              <!-- {{
              getValidationErrors({
              formControl: message, formControlName:translatorJson.MESSAGE_TYPE,
              isTextPattern: true
              })
              }} -->
            </div>
          </div>
          <div class="buttons">
            <button class="btn btn-primary">Contact</button>
            <button class="btn btn-default" (click)="closPopup();">Close</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</ng-template>
<!--Exhibitor contact form tempalte end here-->