import { Injectable } from "@angular/core";
import * as qs from "qs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

// Import IIndusties type interface.
import { Credential, CredentialByAdmin } from "../client-schema";
// Import environment config file.
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  // Define the class property.
  private userBaseUrl: string = environment.apiUrl + "/users";
  private loginUrl: string = environment.apiUrl + "/users/login";
  private requestPasswordResetUrl: string =
    environment.apiUrl + "/users/requestPasswordReset";
  private resetPasswordUrl: string =
    environment.apiUrl + "/users/resetPassword";
  constructor(private http: HttpClient, private authService: AuthService) { }

  /**
   * this service add member to collection and return member data.
   */

  addMember(memberObj: any): Observable<any> {
    return this.http.post<any>(this.userBaseUrl, memberObj);
  }

  updatePassword(passwordObj: any): Observable<void> {
    return this.http.patch<void>(
      this.userBaseUrl + "/changePassword",
      passwordObj
    );
  }

  /**
   * this service return total count of User .
   */
  findUserCount(userObj: object): Observable<any> {
    return this.http.get<any>(
      this.userBaseUrl + "/count?" + qs.stringify(userObj)
    );
    console.log("abcd", this.userBaseUrl + "/count?");
  }

  /**
   * this service return User  list according to parms.
   */
  findUserList(userObj: object): Observable<[]> {
    return this.http.get<[]>(this.userBaseUrl + "?" + qs.stringify(userObj));
  }
  /**
   * this service return User  list according to parms.
   */
  findUserListAdvance(userObj: object): Observable<[]> {
    return this.http.post<[]>(this.userBaseUrl + "/advanceSearch", userObj);
  }

  findUserListAdvanceCount(userObj: object): Observable<any> {
    return this.http.post<any>(
      this.userBaseUrl + "/advanceSearchCount",
      userObj
    );
  }

  /**
   *
   * @param userObj user login by super admin
   */
  userLoginByAdmin(credential: CredentialByAdmin): Observable<any> {
    return this.http
      .post<any>(this.userBaseUrl + "/accessBySupAdmin", credential)
      .pipe(
        map((data: any) => {
          if (data && data.token) {
            if (credential.rememberMe && credential.rememberMe == true) {
              this.authService.setUser({
                token: data.token,
                isRemember: credential.rememberMe,
              });
            } else {
              this.authService.setUser({
                token: data.token,
                // isRemember: credential.rememberMe
              });
            }
          }
          return data;
        })
      );
  }

  /**
   * this service return User list according to parms.
   */
  findUserListByAdmin(userObj: object): Observable<[]> {
    return this.http.get<[]>(
      this.userBaseUrl + "/getUserByAdmin?" + qs.stringify(userObj)
    );
  }

  /**
   * this service return User Admin Dash Board to parms.
   */
  findAdminDashBoard(userObj: object): Observable<[]> {
    return this.http.get<[]>(
      this.userBaseUrl + "/getAdminDashBoardValues?" + qs.stringify(userObj)
    );
  }

  /**
   * this service get user using id.
   */
  getUserById(id: string): Observable<any> {
    return this.http.get<any>(this.userBaseUrl + "/" + id);
  }

  /**
   * this service update User using id.
   */
  updateUser(id: string, userObj: object): Observable<any> {
    return this.http.patch<any>(this.userBaseUrl + "/" + id, userObj);
  }
  //send email
  sendEmail(queryObj: object): Observable<any> {
    return this.http.post<any>(this.userBaseUrl + "/emailForUser", queryObj);
  }
  //send email
  sendEmailRegister(queryObj: object): Observable<any> {
    return this.http.post<any>(
      this.userBaseUrl + "/sendEmailForUserRegistration",
      queryObj
    );
  }

  //send email
  sendEmailContact(queryObj: object): Observable<any> {
    return this.http.post<any>(
      this.userBaseUrl + "/sendEmailContactUs",
      queryObj
    );
  }

  /**
   * This service is used for user login
   */
  memberLogin(credential: Credential): Observable<object> {
    // console.log(credential);
    return this.http.post<object>(this.loginUrl, credential).pipe(
      map((data: any) => {
        if (data && data.token) {
          if (credential.rememberMe && credential.rememberMe == true) {
            this.authService.setUser({
              token: data.token,
              isRemember: credential.rememberMe,
            });
          } else {
            this.authService.setUser({
              token: data.token,
              // isRemember: credential.rememberMe
            });
          }
        }
        return data;
      })
    );
  }
  /**
   * This service is used for user login
   */

  requestPasswordReset(emailObj: any): Observable<object> {
    return this.http.post<object>(this.requestPasswordResetUrl, emailObj);
  }

  /**
   * This is used to get all data of user by using userId
   *
   */
  getCurrentUser(): Observable<object> {
    const userId = this.authService.getUserId();
    if (userId) {
      return this.http.get<object>(this.userBaseUrl + "/" + userId);
    } else {
      return null;
    }
  }

  // getCurrentTempUser(): Observable<object> {
  //   const userId = this.authService.getTempUserId();
  //   if (userId) {
  //     return this.http.get<object>(this.userBaseUrl + '/' + userId);
  //   } else {
  //     return null;
  //   }
  // }

  resetPassword(dataObj: any): Observable<object> {
    const authHeader = new Headers();
    let resetUrl = this.resetPasswordUrl;

    if (dataObj && dataObj.token) {
      resetUrl = this.resetPasswordUrl + "?access_token=" + dataObj.token;
    }

    return this.http.post<object>(resetUrl, dataObj);
  }

  changePasswordByAdmin(id: string, userObj: object): Observable<object> {
    return this.http.patch<any>(
      this.userBaseUrl + "/changePasswordByAdmin/" + id,
      userObj
    );
  }
}
