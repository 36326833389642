

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import IIndusties type interface.
import { IIndusties } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';
import { IMenu } from '../client-schema';
// Import environment config file.

import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IndustriesService {   
  private industriesLocalUrl: string = environment.apiUrl + '/taxonomies';
  constructor(private http: HttpClient, private authService: AuthService) { }

  getIndustiesList(queryObj: any): Observable<any> {
     
          
    return this.http.get<any>(this.industriesLocalUrl + '?' + qs.stringify(queryObj));
   }
 
 
}
