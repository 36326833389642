import { Component, OnInit, TemplateRef, ElementRef, Injector } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { forkJoin } from 'rxjs';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { GetRouterLinkService } from '../services/getrouter-link.service';

@Component({
  selector: 'app-taxonomy',
  templateUrl: './taxonomy.component.html',
  styleUrls: ['./taxonomy.component.scss']
})
export class TaxonomyComponent implements OnInit {
  modalRef: BsModalRef;
  taxonomyForm: FormGroup;
  taxonomySearchForm: FormGroup;
  tAttributes: any = {};
  taxonomyParentList: any = [];
  nestedChildId = [];
  userData: any;
  userSlug: string = '';
  userRole: any = null;
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private taxonomyService: TaxonomyService,
    private modalService: BsModalService,
    private elementRef: ElementRef,
    private appComponent: AppComponent,
    private authService: AuthService,
    private loginService: LoginService,
    private router: Router,
    private injector: Injector,
    private utility: Utility) {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {
    // Taxonomy type add form
    this.taxonomyForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      parentId: '',
      status: ['', Validators.required]
    });

    // This  is taxonomy  seach form
    this.taxonomySearchForm = this.fb.group({
      name: ['', []]
    });

    // Copy object
    this.tAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   if (this.appComponent.role === 0) {
    //     // Load data
    //     this.getTaxonomyListCount();
    //   } else {
    //     this.router.navigateByUrl('/');
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }
    // to check auth for login user
    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        if (this.userData.role === 0) {
          // Load data
          this.getTaxonomyListCount();
        } else {
          this.router.navigateByUrl('/');
        }
      }
    }, err => {
      this.router.navigateByUrl('/');
    });


  }

  get iForm() {
    return this.taxonomyForm.controls;
  }

  // This method is used for search taxonomy
  taxonomySearch() {
    if (this.taxonomySearchForm.valid) {
      this.tAttributes.pageItems = [];
      this.getTaxonomyListCount();
    }
  }

  // Manage show/hide taxonomy form
  toggleForm(): void {
    if (!(this.tAttributes.isIndex >= 0) && (this.tAttributes.isActiveIdx == -1)) {
      this.tAttributes.isShow = !this.tAttributes.isShow;
    }
    this.tAttributes.submitted = false;
    this.taxonomyForm.reset();
    this.tAttributes.isIndex = -1;
    this.tAttributes.isActiveIdx = -1;
    this.tAttributes.childIndex = -1;
    this.tAttributes.itemArray = [];
    this.tAttributes.statusSelected = 1;
    // Set default  radio button value
    this.taxonomyForm.addControl('parentId', new FormControl());
    this.taxonomyForm.get('status').setValue(this.tAttributes.statusSelected);
  }

  // Add taxonomy
  addTaxonomy(): void {
    // Check taxonomy validation and save data in db
    if (this.taxonomyForm.valid) {
      this.taxonomyForm.value.status = Number(this.taxonomyForm.value.status);
      let parentId = null;
      if (this.taxonomyForm.value.parentId == null) {
        this.taxonomyForm.removeControl('parentId');
      } else {
        parentId = this.taxonomyForm.value.parentId;
      }
      this.taxonomyForm.value.taxonomyTypeId = this.globals.taxonomyTypesConst.taxonomyId;
      this.taxonomyService.addTaxonomy(this.taxonomyForm.value)
        .subscribe(data => {
          const taxoData = data ? data : {};
          this.taxonomyParentList.push(taxoData);
          this.tAttributes.pageItems = [];
          this.setListItems();
          this.toggleForm();
        }, (err) => {
        });
    } else {
      this.tAttributes.submitted = true;
    }
  }

  // Update taxonomy From Taxonomy Collection
  updateTaxonomy(id: string): void {
    if (this.taxonomyForm.valid) {
      if (this.taxonomyForm.value.parentId == null) {
        this.taxonomyForm.removeControl('parentId');
      }
      this.taxonomyForm.value.status = Number(this.taxonomyForm.value.status);
      this.taxonomyForm.value.taxonomyTypeId = this.globals.taxonomyTypesConst.taxonomyId;
      this.taxonomyService.updateTaxonomy(id, this.taxonomyForm.value)
        .subscribe(data => {
          this.getSingleTaxonomy(id);
          this.taxonomyForm.reset();
          this.modalRef.hide();
        }, (err) => {

        });
    } else {
      this.tAttributes.submitted = true;
    }
  }

  // Delete taxonomy From Taxonomy Collection
  deleteTaxonomyData(pId: number, cId: number): void {
    const singleItem = this.tAttributes.pageItems[pId][cId];
    this.tAttributes.itemArray = singleItem;
    if (singleItem) {
      this.nestedChildId.push(singleItem.id);
      this.getNestedAllChild(this.taxonomyParentList, singleItem.id);
    }
    const queryObj: any = {
      where: { id: { inq: this.nestedChildId } }
    };
    const reqBody: any = {
      status: 2
    };
    this.tAttributes.statusSelected = this.tAttributes.itemArray.status;
    if (cId >= 0 && pId >= 0) {
      this.taxonomyService.updateAllTaxonomy(queryObj, reqBody)
        .subscribe(data => {
          this.tAttributes.pageItems[pId].splice(cId, 1);
          this.tAttributes.pageItems.splice(pId + 1, this.tAttributes.pageItems.length);
        }, (err) => {

        });
    }

  }


  //  Get all Child of given parents
  getNestedAllChild(data: any, itemId: string) {
    itemId = itemId || null;
    data.forEach((v: any, i: number) => {
      if (String(v.parentId) === String(itemId)) {
        this.nestedChildId.push(v.id);
        this.getNestedAllChild(data, v.id);
      }
    });
  }

  // Get single taxonomy
  getSingleTaxonomy(id: string): any {
    this.taxonomyService.getTaxonomyById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.tAttributes.pageItems[this.tAttributes.isActiveIdx].findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          this.tAttributes.pageItems[this.tAttributes.isActiveIdx][getIndex] = Object.assign({}, cuData);
        }
        this.taxonomyForm.addControl('parentId', new FormControl());
      }, (err) => {

      });
  }
  // Get taxonomy list
  getTaxonomyList(): void {
    const queryObj: any = {
      filter: {
        where: {
          status: 1,
          taxonomyTypeId: this.globals.taxonomyTypesConst.taxonomyId
        }
      }
    };

    // This statement for serch taxonomy list
    if (this.taxonomySearchForm && this.taxonomySearchForm.value && this.taxonomySearchForm.value.name) {
      queryObj.filter.where.name = {
        regexp: 'm.*' + this.taxonomySearchForm.value.name + '/mi'
      };
    }
    this.taxonomyService.getTaxonomyList(queryObj)
      .subscribe(data => {
        this.taxonomyParentList = (data && data.length) ? data : [];
        this.setListItems();
      }, (err) => {

      });
  }

  // This is used to set taxonomy list
  setListItems() {
    const taxonomyData = this.taxonomyParentList || [];
    const parentId = null;
    this.tAttributes.pageItems.push(this.getNested(taxonomyData, parentId));
  }

  //  Get Child taxonomy From Main Array
  getNested(data: any, parentId: string): any {
    parentId = parentId || null;
    const localeArr = [];
    data.forEach((v: any, i: number) => {
      if (String(v.parentId) === String(parentId)) {
        const hasChildData = this.getNested(data, v.id);
        if (hasChildData && hasChildData.length) { data[i].child = hasChildData; }
        localeArr.push(data[i]);
      }
    });
    return localeArr;
  }

  // Set Next level for taxonomy
  nextlevelChild(parent: number, index: number, haveChild: boolean) {
    this.tAttributes.pageItems.splice(parent + 1, this.tAttributes.pageItems.length);
    if (this.tAttributes.pageItems[parent][index].child) {
      this.tAttributes.pageItems.push(this.tAttributes.pageItems[parent][index].child);
    }
    if (!haveChild) {
      this.tAttributes.pageItems.push([]);
    }
    this.tAttributes.isActiveIdx = parent;
    this.tAttributes.childIndex = index;
  }
  // Open model box of add/edit taxonomy
  open(content: any, data: any, isEdit: boolean): any {
    if (isEdit) {
      const singleItem = data;
      try {
        // delete singleItem.child;
      } catch (e) { }
      this.tAttributes.itemArray = singleItem;
      this.taxonomyForm.patchValue(this.tAttributes.itemArray);
      this.tAttributes.statusSelected = this.tAttributes.itemArray.status;
    } else {
      this.taxonomyForm.reset();
      this.taxonomyForm.get('parentId').setValue(data.id);
      this.tAttributes.itemArray.parentId = data.id;
    }
    this.modalRef = this.modalService.show(content);
  }

  // Get told type list/count
  getTaxonomyListCount(): void {
    forkJoin([this.getTaxonomyList()]);
  }
}
