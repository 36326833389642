import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { GetRouterLinkService } from '../services/getrouter-link.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private injector: Injector,
    private appComponent: AppComponent,
    private router: Router) { }

  ngOnInit() {
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {

    // } else {
    //   this.router.navigateByUrl("/");
    // }
  }

}
