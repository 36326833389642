import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  FormControl,
} from "@angular/forms";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { environment } from "../../environments/environment";
import { forkJoin, Subject } from "rxjs";
// Import utility method class
import { Utility } from "../utility";
// Handle the global property
import { Globals } from "../globals";
import { PageService } from "./page.service";
import { AuthService } from "../services/auth.service";
import { FoldService } from "../fold/fold.service";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import * as _ from "lodash";
import { LoginService } from "../login/login.service";
import { AppComponent } from "../app.component";
import { Router } from "@angular/router";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import * as TranslatorJson from "./../../assets/i18n/en.json";
@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit {
  searchFForm: FormGroup;
  pageForm: FormGroup;
  searchPageForm: FormGroup;
  pageAttributes: any = {};
  userData: any;
  userSlug: string = "";
  userRole: any = null;
  imageSrc = "";
  authLogin
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  foldArr: any = [];
  pageType: any = [
    { name: "Transactional", value: true },
    { name: "Non-transactional", value: false },
  ];
  isExistFold: boolean = false;
  asyncSelected: string;

  essentialPages: string[] = ['login', 'home',];

  selectedItemOfTypeahead: any;
  addedFoldData: any = [];

  @ViewChild("instance") instance: TypeaheadMatch;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  translatorJson: typeof TranslatorJson;
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private pageService: PageService,
    private foldService: FoldService,
    private authService: AuthService,
    private comp: AppComponent,
    private router: Router,
    private utility: Utility,
    private injector: Injector
  ) {
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  ngOnInit() {
    // Menu add form
    this.pageForm = this.fb.group({
      pageName: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(120),
        ],
      ],
      permalink: ["", [Validators.required, Validators.minLength(2)]],
      transactional: [false, []],
      isAnchorTab: [false, []],
      pageReferenceId: ["", []],
      startDate: ["", []],
      endDate: ["", []],
      foldName: ["", []],
      isSubFold: [false, []],
      foldId: this.fb.array([]),
      subfoldId: this.fb.array([]),
      memberId: ["", [Validators.required]],
      auth: ["", [Validators.required]],
      accessLevel: ['', Validators.required],
      status: ["", Validators.required],
    });

    // Fold search form
    this.searchFForm = this.fb.group({
      searchFold: ["", []],
    });

    // Copy object
    this.pageAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    this.setMemberID();
  }

  get foldIdArray() {
    return this.pageForm.get("foldId") as FormArray;
  }
  // convenience getter for easy access to from fileds from page add form
  get f() {
    return this.pageForm.controls;
  }

  // set member id to form
  setMemberID(): void {
    // this.authLogin = this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.comp)
    // if (this.authLogin) {
    //   const userId = this.authService.getUserId();
    //   this.pageForm.patchValue({
    //     memberId: userId,
    //   });
    //   this.userData = this.comp.userData;
    //   this.getPageListCount();
    // } else {
    //   this.router.navigateByUrl("/");
    // }
    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          const userId = this.authService.getUserId();
          this.pageForm.patchValue({
            memberId: userId,
          });
          this.userData = this.comp.userData;
          if (this.userData.role === 0) {
            // Load data
            this.getPageListCount();
          } else {
            this.router.navigateByUrl("/");
          }
        }
      },
      () => { }
    );
  }

  // Add page
  addPage(): void {
    this.setMemberID();
    this.pageForm.patchValue({
      isAnchorTab: false,
    });
    this.pageForm.patchValue({
      isSubFold: false,
    });
    this.pageForm.patchValue({
      pageReferenceId: "",
    });
    this.pageForm.value.transactional =
      this.pageForm.value.transactional == "true" ? true : false;
    this.pageForm.value.status = Number(this.pageForm.value.status);
    this.pageForm.value.auth = Number(this.pageForm.value.auth);
    // Copy  form object
    if (this.pageForm.valid) {
      this.getPagePermaLinkList();
    } else {
      this.pageAttributes.submitted = true;
    }
  }

  // update page data
  updatePage(): void {
    this.setMemberID();
    this.pageForm.patchValue({
      isAnchorTab: false,
    });
    this.pageForm.patchValue({
      isSubFold: false,
    });
    this.pageForm.patchValue({
      pageReferenceId: "",
    });
    this.pageForm.value.transactional =
      this.pageForm.value.transactional == "true" ? true : false;
    this.pageForm.value.status = Number(this.pageForm.value.status);
    this.pageForm.value.auth = Number(this.pageForm.value.auth);
  }

  // Search fold according to string
  searchFold(): void {
    if (this.searchFForm.valid) {
      this.getPageListCount();
    }
  }

  updatePageData(id: string): void {
    this.updatePage();
    let formObj = Object.assign({}, this.pageForm.value);
    if (formObj) {
      try {
        formObj.foldId = _.map(this.addedFoldData, function (foldData) {
          return foldData.id;
        });
        delete formObj.foldName;
      } catch (error) { }
    }
    if (this.pageForm.valid) {
      this.pageService.updatePage(id, formObj).subscribe(
        () => {
          this.getPage(id);
          this.pageAttributes.isIndex = -1;
          this.pageAttributes.isActiveIdx = -1;
          this.pageForm.reset();
        },
        () => { }
      );
    } else {
      this.pageAttributes.submitted = true;
    }
  }

  // Get single page
  getPage(id: string): any {
    this.pageService.getPageById(id).subscribe(
      (cuData) => {
        // Return object index
        const getIndex: number = this.pageAttributes.pageItems.findIndex(
          (x) => String(x.id) === String(id)
        );
        if (getIndex >= 0) {
          this.pageAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      },
      () => { }
    );
  }

  saveAddPageData(): void {
    let formObj = Object.assign({}, this.pageForm.value);
    if (formObj) {
      try {
        formObj.foldId = _.map(this.addedFoldData, function (foldData) {
          return foldData.id;
        });
        delete formObj.foldName;
      } catch (error) { }
    }
    this.pageService.addPage(formObj).subscribe(
      (data) => {
        this.toggleForm();
        // Push new object on zero index
        this.pageAttributes.pageItems.splice(0, 0, data);
        this.pageAttributes.count += 1;
        this.pageForm.reset();
      },
      () => { }
    );
  }

  // Manage show/hide page form
  toggleForm(): void {
    if (!(this.pageAttributes.isIndex >= 0)) {
      this.pageAttributes.isShow = !this.pageAttributes.isShow;
    }
    this.pageAttributes.submitted = false;
    this.pageForm.reset();
    this.pageAttributes.isIndex = -1;
    this.pageAttributes.isActiveIdx = -1;
    this.pageAttributes.itemArray = [];
    this.pageAttributes.statusSelected = 1;
    this.addedFoldData = [];
    // Set default  radio button value
    this.pageForm.get("status").setValue(this.pageAttributes.statusSelected);
  }

  // Get single page according to index
  getPageForEdit(index: number): void {
    this.toggleForm();
    // this.addedFoldData = [];
    this.pageAttributes.isIndex = this.utility.getIndex(
      index,
      this.pageAttributes.pageItems.length
    );
    this.pageAttributes.isShow = false;
    this.pageAttributes.isActiveIdx = index;
    this.pageAttributes.itemArray = this.pageAttributes.pageItems[index];
    this.pageForm.patchValue(this.pageAttributes.itemArray);
    this.pageAttributes.statusSelected = this.pageAttributes.itemArray.status;

    if (this.pageAttributes.itemArray && this.pageAttributes.itemArray.foldId) {
      let dataArray = this.pageAttributes.itemArray.foldId;
      let addedFoldDataTemp: any = [];
      _.forEach(this.foldArr, function (foldItem) {
        if (foldItem && foldItem.id) {
          _.forEach(dataArray, function (foldIds) {
            if (foldItem.id == foldIds) {
              addedFoldDataTemp.push(foldItem);
            }
          });
        }
      });
      this.addedFoldData = addedFoldDataTemp;
    }
  }

  // Get Page count
  getPageCount(): void {
    const queryObj: any = {
      where: {
        status: { inq: [1, 0] },
      },
    };
    // This statement for serch fold count
    if (
      this.searchFForm &&
      this.searchFForm.value &&
      this.searchFForm.value.searchFold
    ) {
      queryObj.where.pageName = {
        regexp: "m.*" + this.searchFForm.value.searchFold + "/mi",
      };
    }
    // console.log(queryObj);
    this.pageService.getPageCount(queryObj).subscribe(
      (data) => {
        this.pageAttributes.count = data && data.count ? data.count : 0;
      },
      () => { }
    );
  }

  // Update fold
  deletePage(index: number): void {
    if (index >= 0) {
      if (this.pageAttributes.pageItems[index].id) {
        const queryObj: any = {
          status: 2,
        };
        this.pageService
          .updatePage(this.pageAttributes.pageItems[index].id, queryObj)
          .subscribe(
            () => {
              // remove item from array
              this.pageAttributes.pageItems.splice(index, 1);
              this.pageAttributes.count -= 1;
            },
            () => { }
          );
      }
    }
  }

  // Get page total list
  getPageList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        limit: limitNum,
        skip: skipNum,
      },
    };

    // This statement for serch fold list
    if (
      this.searchFForm &&
      this.searchFForm.value &&
      this.searchFForm.value.searchFold
    ) {
      queryObj.filter.where.pageName = {
        regexp: "m.*" + this.searchFForm.value.searchFold + "/mi",
      };
    }

    this.pageService
      .getPageList(queryObj)

      .subscribe(
        (data) => {
          //  console.log("fff",data)
          this.pageAttributes.pageItems = data && data.length ? data : [];
        },
        () => { }
      );
  }

  // Get fold list for slug
  getPagePermaLinkList(): void {
    const queryObj: any = { filter: { fields: { permalink: true } } };
    this.pageService.getPageList(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          var i = 0,
            j = 1,
            len = res.length;
          while (i < len) {
            if (res[i].permalink === this.pageForm.value.permalink) {
              this.pageForm.value.permalink =
                this.pageForm.value.permalink.replace(/\d+$/, "") + j;
              this.pageForm
                .get("permalink")
                .setValue(this.pageForm.value.permalink);
              i = -1;
              j++;
            }
            i++;
          }
        }
        this.saveAddPageData();
      },
      () => { }
    );
  }

  // Get fold total list
  getFoldList(): void {
    const queryObj: any = {
      filter: { where: { status: 1 } },
    };
    this.foldService.getFoldList(queryObj).subscribe(
      (data) => {
        this.foldArr = data && data.length ? data : [];
      },
      () => { }
    );
  }

  onSelectFoldItems(item) {
    if (item) this.selectedItemOfTypeahead = item;
  }

  deleteFold(index: string): void {
    this.addedFoldData.splice(index, 1);
  }

  /**
   * Add fold data to page array
   */
  addFoldData(): void {
    if (this.selectedItemOfTypeahead) {
      this.addedFoldData.push(this.selectedItemOfTypeahead);
      this.foldIdArray.push(new FormControl(this.selectedItemOfTypeahead.id));
    }
    this.selectedItemOfTypeahead = {};
    this.isExistFold = false;
    this.pageForm.patchValue({
      foldName: "",
    });
  }

  onInputPageName(data: string) {
    if (data && data !== "") {
      const permalink = data
        .replace("/page/", "")
        .trim()
        .replace(/[^A-Z0-9-]/gi, "-")
        .replace(/-{2,}/g, "-")
        .toLowerCase();
      this.pageForm.patchValue({
        permalink: permalink,
      });
    }
  }
  // Get total list/count
  getPageListCount(): void {
    forkJoin([
      this.getPageCount(),
      this.getPageList(this.pageAttributes.limits, this.pageAttributes.skips),
      this.getFoldList(),
    ]);
  }
  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.pageAttributes.isIndex = -1;
    this.pageAttributes.isActiveIdx= -1
    this.getPageList(this.pageAttributes.limits, startItem);
  }
}
