import { Injectable } from '@angular/core';
import * as qs from 'qs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

// Import IIndusties type interface.
import { Credential } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
// Define the class property.
 baseUrlExhibReview: string = environment.apiUrl + '/review-events';
 baseUrlCorpoReview: string = environment.apiUrl + '/corporate-event-reviews';
  constructor(
    private http: HttpClient
  ) { }
 
  /**
   * this service add member to collection and return member data.
   */

  addReview(reviewObj: any): Observable<any> {
    return this.http.post<any>(this.baseUrlExhibReview, reviewObj);
  }

  /**
   * This service return event exhibitor review list
   */
  getEventExhibReviewList(qObj: object, url: string): Observable<[]> {
    return this.http.get<[]>(url + '?' + qs.stringify(qObj));
  }

  /**
   * this service return counts of event exhibitor review list .
   */
  getEventExhibReviewCounts(qObj: object,url: string): Observable<any> {
    return this.http.get<any>(url + '/count?' + qs.stringify(qObj));
  }


  /**
   * this service used to add review records for corporate  user.
   */

  addReviewForCorporate(reviewObj: any): Observable<any> {
    return this.http.post<any>(this.baseUrlCorpoReview, reviewObj);
  }

  /**
   * this service is used to get count accoding to condtion
   * @param reviewObj 
   */

  getCounts(reviewObj: any): Observable<any> {
    return this.http.post<any>(this.baseUrlExhibReview + '/countsEventReview', reviewObj);
  }




}
