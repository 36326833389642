import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FoldTypeService } from './fold-type.service';
import { forkJoin } from 'rxjs';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { Globals } from '../globals';

@Component({
  selector: 'app-fold-type',
  templateUrl: './fold-type.component.html',
  styleUrls: ['./fold-type.component.scss']
})
export class FoldTypeComponent implements OnInit {
  // Define the calss property
  foldTypeForm: FormGroup;
  searchFTForm: FormGroup;
  fTAttributes: any = {};
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private foldTypeService: FoldTypeService,
    private utility: Utility) {
  }

  ngOnInit() {
    // Fold type add form
    this.foldTypeForm = this.fb.group({
      foldTypeName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      dataComponent: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      frontComponent: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]],
      status: ['', Validators.required]
    });

    // Fold type search form
    this.searchFTForm = this.fb.group({
      searchFoldType: ['', []]
    });

    // Copy object
    this.fTAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    // Load data
    this.getFoldTypeListCount();
  }

  // convenience getter for easy access to form fields form fold type add form
  get ft() {
    return this.foldTypeForm.controls;
  }

  // convenience getter for easy access to form fileds form search fold type form
  get sft() {
    return this.searchFTForm.controls;
  }

  // Manage show/hide fold type form
  toggleForm(): void {
    if (!(this.fTAttributes.isIndex >= 0)) {
      this.fTAttributes.isShow = !this.fTAttributes.isShow;
    }
    this.fTAttributes.submitted = false;
    this.foldTypeForm.reset();
    this.fTAttributes.isIndex = -1;
    this.fTAttributes.isActiveIdx = -1;
    this.fTAttributes.itemArray = [];
    this.fTAttributes.statusSelected = 1;
    // Set default  radio button value
    this.foldTypeForm.get('status').setValue(this.fTAttributes.statusSelected);
  }

  // Add fold type
  addFoldType(): void {
    // Check fold validation and save data in db
    if (this.foldTypeForm.valid) {
      this.foldTypeForm.value.status = Number(this.foldTypeForm.value.status);
      this.foldTypeService.addFoldType(this.foldTypeForm.value)
        .subscribe(data => {
          this.toggleForm();
          // Push new object on zero index
          this.fTAttributes.pageItems.splice(0, 0, data);
          this.fTAttributes.count += 1;
          this.foldTypeForm.reset();
        }, (err) => {

        });
    } else {
      this.fTAttributes.submitted = true;
    }
  }

  // Get single fold type according to index
  getfoldTypeForEdit(index: number): void {
    this.toggleForm();
    setTimeout(() => {
      this.fTAttributes.isIndex = this.utility.getIndex(index, this.fTAttributes.pageItems.length);
      this.fTAttributes.isShow = false;
      this.fTAttributes.isActiveIdx = index;
      this.fTAttributes.itemArray = this.fTAttributes.pageItems[index];
      this.foldTypeForm.patchValue(this.fTAttributes.itemArray);
      this.fTAttributes.statusSelected = this.fTAttributes.itemArray.status;
    }, 0);
  }

  // Update fold type
  updateFoldType(id: string): void {
    if (this.foldTypeForm.valid) {
      this.foldTypeForm.value.status = Number(this.foldTypeForm.value.status);
      this.foldTypeService.updateFoldType(id, this.foldTypeForm.value)
        .subscribe(data => {
          this.getFoldType(id);
          this.fTAttributes.isIndex = -1;
          this.fTAttributes.isActiveIdx = -1;
          this.foldTypeForm.reset();
        }, (err) => {

        });
    } else {
      this.fTAttributes.submitted = true;
    }
  }

  // Search fold type according to string
  searchFoldType(): void {
    if (this.searchFTForm.valid) {
      this.getFoldTypeListCount();
    }
  }
  // Update fold type
  deleteFoldType(index: number): void {
    if (index >= 0) {
      this.foldTypeForm.patchValue(this.fTAttributes.pageItems[index]);
      this.foldTypeForm.value.status = Number(2);
      this.foldTypeService.updateFoldType(this.fTAttributes.pageItems[index].id, this.foldTypeForm.value)
        .subscribe(data => {
          // remove item from array
          this.fTAttributes.pageItems.splice(index, 1);
          this.fTAttributes.count -= 1;
        }, (err) => {

        });
    }
  }

  // Get single fold type
  getFoldType(id: string): any {
    this.foldTypeService.getFoldTypeById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.fTAttributes.pageItems.findIndex((x) => String(x.id) === String(id));
        if (getIndex >= 0) {
          this.fTAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      }, (err) => {

      });
  }

  // Get fold type total count
  getFoldTypeCount(): void {
    const queryObj: any = {
      where: {
        status: { inq: [1, 0] }
      }
    };
    // This statement for serch fold type count
    if (this.searchFTForm && this.searchFTForm.value && this.searchFTForm.value.searchFoldType) {
      queryObj.where.foldTypeName = {
        regexp: 'm.*' + this.searchFTForm.value.searchFoldType + '/mi'
      };
    }
    this.foldTypeService.getFoldTypeCount(queryObj)
      .subscribe(data => {
        this.fTAttributes.count = (data && data.count) ? data.count : 0;
      }, (err) => {

      });
  }

  // Get fold type total list
  getFoldTypeList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        limit: limitNum,
        skip: skipNum,
      }
    };
    // This statement for serch fold type list
    if (this.searchFTForm && this.searchFTForm.value && this.searchFTForm.value.searchFoldType) {
      queryObj.filter.where.foldTypeName = {
        regexp: 'm.*' + this.searchFTForm.value.searchFoldType + '/mi'
      };
    }
    this.foldTypeService.getFoldTypeList(queryObj)
      .subscribe(data => {
        console.log(data);
        this.fTAttributes.pageItems = (data && data.length) ? data : [];
      }, (err) => {

      });
  }

  // Get told type list/count
  getFoldTypeListCount(): void {
    forkJoin([this.getFoldTypeCount(), this.getFoldTypeList(this.fTAttributes.limits, this.fTAttributes.skips)]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getFoldTypeList(this.fTAttributes.limits, startItem);
  }

}
