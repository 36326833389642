import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  imageServer: any; // Image server object
  siteUrl: string; // Site URL object
  foldData: any = [];
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  activeSlideIndex: 0;
  isUserLoggedIn = false;
  singleSlideOffset = false;
  noWrap = false;
 
  slidesChangeMessage = '';
  
  sideData = [
    
      {
        "id": 1, 
        "img": "slider-img-1.jpg", 
        "thumb": "slider-thumb-1.jpg", 
        "title": "HEALTH FAIRS PLUS ", 
        "description": "has been in operation since 2002 and is based in Haymarket Virginia just outside of Washington, DC.  Our mission is to unite corporate employees and local health and wellness professionals in the interest of promoting proactive health.  Our programs allow us to provide efficient and effective services anywhere you have employees." 
      },
      {
        "id": 2, 
        "img": "slider-img-2.jpg", 
        "thumb": "slider-thumb-2.jpg", 
        "title": "HEALTHFAIRSPLUS.COM ", 
        "description": "is an innovative Virtual corporate wellness resource that give employers access to a variety of local heath and wellness professionals who provide workshops, seminars, screenings and health fair services.HealthFairsPlus.com is an excellent and FREE resource for employers everywhere." 
      },
      {
        "id": 3, 
        "img": "slider-img-3.jpg", 
        "thumb": "slider-thumb-3.jpg", 
        "title": "THERE IS ONLY ONE PLACE ", 
        "description": "to go for all of your corporate health and wellbeing programs.  From Virtual wellness to onsite programs, Health Fairs Plus has become the single go-to resource for employee wellness." 
      },
      {
        "id": 4, 
        "img": "slider-img-5.jpg",
        "thumb": "slider-thumb-4.jpg",  
        "title": "WHAT WE STRIVE TO DO ", 
        "description": "is to make use of the most innovative corporate wellness options available to keep employee health up and corporate healthcare costs down.   Our Virtual tools and resources allow us to provide programs nationwide wide while our database of local health and wellness professionals make it possible to keep a local feel to your programs.  " 
      },
  
   
  ]
  constructor(private authService: AuthService) { 
    this.initAuthLogin();
  }

  ngOnInit() {
  }
  onSlideRangeChange(indexes: number[]): void {
    this.slidesChangeMessage = `Slides have been switched: ${indexes}`;
  }
  
  initAuthLogin() {
    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        this.isUserLoggedIn = isLogin;
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
