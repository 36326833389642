import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-slider',
  templateUrl: './manage-slider.component.html',
  styleUrls: ['./manage-slider.component.scss']
})
export class ManageSliderComponent implements OnInit {
  templateData: any = [];
  constructor() { }

  ngOnInit() {
  }

}
