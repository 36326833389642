import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

export interface TimezoneOption {
  value: {
    name: string;
    label: string;
  };
  label: string;
  offset: number;
}

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private timezoneList: TimezoneOption[] | null = null;

  constructor() { }

  getTimezoneList(): TimezoneOption[] {
    if (this.timezoneList === null) {
      const seenLabels = new Set<string>();
      this.timezoneList = moment.tz.names()
        .map(tz => {
          const offset = moment.tz(tz).utcOffset();
          const offsetString = this.formatOffset(offset);
          const zoneName = this.formatZoneName(tz, offset);
          const fullLabel = `(GMT${offsetString}) ${zoneName}`;
          return {
            value: { name: tz, label: fullLabel },
            label: fullLabel,
            offset: offset
          };
        })
        .filter(tz => {
          if (seenLabels.has(tz.label)) {
            return false;
          }
          seenLabels.add(tz.label);
          return true;
        })
        .sort((a, b) => a.offset - b.offset);
    }
    return this.timezoneList;
  }

  getCurrentTimezone(): TimezoneOption | undefined {
    const currentTz = moment.tz.guess();
    return this.getTimezoneList().find(tz => tz.value.name === currentTz);
  }

  private formatOffset(offset: number): string {
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    return `${offset >= 0 ? '+' : '-'}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  private formatZoneName(tz: string, offset: number): string {
    if (tz === 'Etc/GMT' || tz === 'Etc/UTC') {
      return 'Greenwich Mean Time';
    }
    
    const parts = tz.split('/');
    const lastPart = parts[parts.length - 1].replace(/_/g, ' ');
    
    if (lastPart.startsWith('GMT') || lastPart.startsWith('UTC')) {
      return `Greenwich Mean Time${offset !== 0 ? (offset > 0 ? '+' : '-') + Math.abs(offset / 60) : ''}`;
    }
    
    return lastPart;
  }
}