<div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img" *ngIf="userData && userData.image && userData.image.type">
          <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i></div>
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>Total Unregistered Users
          <span *ngIf="totalUnregisterUsers"> :
            {{totalUnregisterUsers}}</span></h1>
      </div>
      <div class="heading_page_type">
        <ul>
          <li>
            <a (click)="userActiveTab=1;resetSearchForm(1);getUnRegisteredList(1,unregisteredList.limits,unregisteredList.skips);" [ngClass]="{'active': memberStatus == 1 }">
              <i><img src="/assets/images/sm-paid-icon.png" alt="" /></i>
              <div class="paymet-status-right"> <span>{{approved}}</span> {{'APPROVED'| translate }} </div>
            </a>
          </li>
          <li>
            <a (click)="userActiveTab=0;resetSearchForm(0); getUnRegisteredList(0, unregisteredList.limits,unregisteredList.skips);" [ngClass]="{'active': memberStatus == 0 }">
              <i><img src="/assets/images/sm-peding-icon.png" alt="" /></i>
              <div class="paymet-status-right"><span>{{pending}}</span> {{'PENDING'| translate }}</div>
            </a>
          </li>
          <li>
            <a (click)="userActiveTab=2;resetSearchForm(2);getUnRegisteredList(2,unregisteredList.limits,unregisteredList.skips);" [ngClass]="{'active': memberStatus == 2 }">
              <i><img src="/assets/images/sm-declined-icon.png" alt="" /></i>
              <div class="paymet-status-right"> <span>{{decline}}</span> {{'DECLINED'| translate }} </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="main-admin-search unregistered-exhibitor-search">
  <div class="container clearfix main__filter__row">
    <!-- <div class="active-tag-box">
                    <i><img src="images/sm-paid-icon.png" alt="" /></i>  approved <span><a href="#"><img src="images/tag-close-icon.png" alt="" /></a> </span>
                </div> -->
    <div class="main__filter__tag">
      <div class="active-tag-box" *ngIf="memberStatus == 1 && loginUserRole===0">
        <i><img src="/assets/images/sm-paid-icon.png" alt=""></i> Approved <span><a href="javascript:void(0)"><img
              src="/assets/images/tag-close-icon.png" alt=""></a> </span>
      </div>
      <div class="active-tag-box" *ngIf="memberStatus == 0 && loginUserRole===0">
        <i><img src="/assets/images/sm-peding-icon.png" alt=""></i> Pending <span><a href="javascript:void(0)"><img
              src="/assets/images/tag-close-icon.png" alt=""></a> </span>
      </div>
      <div class="active-tag-box" *ngIf="memberStatus == 2 && loginUserRole===0">
        <i><img src="/assets/images/sm-declined-icon.png" alt=""></i> Declined <span><a href="javascript:void(0)"><img
              src="/assets/images/tag-close-icon.png" alt=""></a> </span>
      </div>
    </div>
    <div class="pagefilter__section">
      <form [formGroup]="userSearchForm" (ngSubmit)="userSearch();">
        <div class="pagefilter__section__in">

          <div class="pagefilter-search-box">
            <input type="text" value="" placeholder="Search Unregister" formControlName="username" 
            (keyup)="userSearch();" />
          </div>
          <div class="pagefilter-search-date">

            <input class="date-icon" type="text" name="createdDate"
            bsDaterangepicker placeholder="mm-dd-yyyy" [maxDate]="maxDateCorpSearch"
              formControlName="createdDate" >

              <!-- value="{{ userSearchForm.value.username | date }}" [minDate]="minStartDate" -->
          </div>
          <div class="pagefilter-search-button">
            <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>
<div class="show_search_filter_data" *ngIf="loginUserRole == 0 && exhibitorSearch == 1">
  <div class="container">
    <div class="s_r">Search Result : {{unregisteredList.count}} <strong>

        <!-- <span *ngIf="userActiveTab == 1">{{approvedSearch}}</span>
        <span *ngIf="userActiveTab == 0">{{pendingSearch}}</span>
        <span *ngIf="userActiveTab == 2">{{declinesearch}}</span> -->
      </strong></div>
    <div class="s_r_t">

      <ul>
        <li *ngIf="userSearchForm.value.createdDate "><span>{{userSearchForm.value.createdDate[0] | date}} -
            {{userSearchForm.value.createdDate[1] | date}}</span>

          <a href="javascript:void(0)" (click)="removeEventDate()"></a></li>
        <li *ngIf="userSearchForm.value.username "><span>{{this.userSearchForm.value.username}}</span>
          <a href="javascript:void(0)" (click)="removeEventName()"></a></li>
      </ul>
    </div>
  </div>
</div>
<div class="unregistered-users-pg">
  <div class="container">
    <div class="unregistered-users-list">
      <div *ngIf="unregisteredList.pageItems.length" class="unregistered-users-stc">
        <ul>
          <li>Email</li>
          <li>Date</li>
          <li>Event</li>
          <li>Invitation Send By</li>
          <!-- <li>Action</li> -->
        </ul>
      </div>
      <div class="unregistered-users-row">
        <div *ngIf="!unregisteredList.pageItems.length" class="search-not-found">{{'NO_RECORD_FOUND' | translate}}</div>
        <div *ngFor="let unrgister of unregisteredList.pageItems;let idx = index">
          <div class="unregistered-users-top" id="un-user-top-1">
            <div class="u-u-top-em">
              <div class="u-u-top-em-img"><img src="/assets/images/avatars/avatar-35.png" alt=""></div>
              <div class="u-u-top-em-txt"> <strong>{{unrgister.email}}</strong>
                <div *ngIf="unrgister.status == 0" class="exhibitors-detail-link"><a id="register-user-1"
                    href="javascript:void(0)" (click)="getEmailTempForEdit(idx);">Register User</a> </div>
              </div>
            </div>
            <div class="u-u-top-st"><strong>{{unrgister.eventDate | date}}</strong></div>
            <div class="u-u-top-ev"> <strong *ngIf="unrgister.eventName">{{unrgister.eventName}}</strong><strong *ngIf="!unrgister.eventName">&nbsp;</strong></div>
            <div class="u-u-top-in"><strong *ngIf="unrgister.users[0]?.username">{{unrgister.users[0]?.username}}</strong><strong *ngIf="!unrgister.users[0]?.username">&nbsp;</strong></div>
            <div class="unregister_list_action"><a href="javascript:void(0)" (click)="deleteUnregisterExhibitor(unrgister._id)">Delete</a></div>
            <!-- <div class="paymentaction-box">
              <div class="payment-action" >
                <div class="action dropdown" dropdown > <a href="#" class="dropdown-toggle" dropdownToggle
                    aria-controls="dropdown-menu" (click)="false">Action </a>
                  <ul class="dropdown-menu" *dropdownMenu id="dropdown-menu">
                      <li class="event-edit-icon" >
                        <a href="javascript:void(0)" (click)="deleteUnregisterExhibitor(unrgister._id)">
                          Delete</a>
                      </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <div class="clear"></div>
          </div>
          <div *ngIf="unregisteredList.isIndex==idx; then unregisterForm"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="pagination__box" *ngIf="unregisteredList.count > 10">
    <pagination [totalItems]="unregisteredList.count" [rotate]="unregisteredList.rotate"
      [maxSize]="unregisteredList.maxSize" [itemsPerPage]="unregisteredList.limits"  [(ngModel)]="currentPage"
      (pageChanged)="pageChanged($event);">
    </pagination>
  </div>
</div>
<ng-template #unregisterForm>
  <div class="unregistered-users-btm" id="un-user-btm-1">
    <div class="form-close"><button id="close-1" (click)="toggleForm()">
        <img src="/assets/images/add-form-close.png" alt="" /></button></div>
    <div class="unregistered-users-in">
      <form [formGroup]="registerForm" (ngSubmit)="registerUser(unregisteredList.itemArray.exhibitor[0]._id)">

        <h3>{{unregisteredList.itemArray.eventName}}</h3>
        <div class="unregistered-users-form">

          <div class="un-user-input-row">
            <div class="unregistered-users-form-left">
              <div class="hfp-input-div">
                <label>Username</label>
                <input class="" type="text" placeholder="Username" formControlName="username">
                <div
                  *ngIf="registerTempf.username.invalid && (registerTempf.username.dirty || userssAttributes.submitted )"
                  class="alert alert-danger">
                  <div *ngIf="registerTempf.username.errors.required">Username is required</div>
                </div>
              </div>
            </div>
            <div class="unregistered-users-form-right"><b> Usernames cannot be changed.</b></div>
            <div class="clear"></div>
          </div>

          <div class="un-user-input-row">
            <div class="unregistered-users-form-left">
              <div class="hfp-col-6">
                <div class="hfp-input-div">
                  <label>First Name</label>
                  <input type="text" value="Courtney " placeholder="First Name" formControlName="firstName">
                  <div
                    *ngIf="registerTempf.firstName.invalid && (registerTempf.firstName.dirty || userssAttributes.submitted )"
                    class="alert alert-danger">
                    <div *ngIf="registerTempf.firstName.errors.required">First Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="hfp-col-6">
                <div class="hfp-input-div">
                  <label>Last Name</label>
                  <input type="text" value="McCormick" placeholder="Last Name" formControlName="lastName">
                  <div
                    *ngIf="registerTempf.lastName.invalid && (registerTempf.lastName.dirty || userssAttributes.submitted )"
                    class="alert alert-danger">
                    <div *ngIf="registerTempf.lastName.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clear"></div>
          </div>

          <div class="un-user-input-row">
            <div class="unregistered-users-form-left">
              <div class="hfp-input-div">
                <label>Email</label>
                <input type="text" placeholder="Email" formControlName="email" readonly>
                <div *ngIf="registerTempf.email.invalid && (registerTempf.email.dirty || userssAttributes.submitted)"
                  class="alert alert-danger">
                  <div *ngIf="registerTempf.email.errors.required">Email is required</div>
                </div>
              </div>
            </div>
            <div class="clear"></div>
          </div>

          <div class="un-user-input-row">
            <div class="unregistered-users-form-left">
              <div class="hfp-input-div">
                <label>Website</label>
                <input type="text" placeholder="Website" formControlName="website">
                <!-- <div *ngIf="registerTempf.website.invalid && (registerTempf.website.dirty
                                || userssAttributes.submitted)" class="alert alert-danger">
                  <div *ngIf="registerTempf.website.errors.required">Website is required</div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>


        <div class="un-user-input-row">
          <div class="unregistered-users-form-left">
            <div class="hfp-input-div">
              <label>New Password</label>
              <input type="password" placeholder="New Password" formControlName="password">
              <div *ngIf="registerTempf.password.invalid
                             && (registerTempf.password.dirty || userssAttributes.submitted)"
                class="alert alert-danger">
                <div *ngIf="registerTempf.password.errors.required">New Password is required</div>


              </div>
            </div>
          </div>
          <!-- <div class="unregistered-users-form-right"><b> If you would like to change the password type a new
                            one.
                            Otherwise leave this blank.</b></div> -->
          <div class="clear"></div>
        </div>
        <div class="un-user-input-row">
          <div class="unregistered-users-form-left">
            <div class="hfp-input-div">
              <label>Confirm New Password</label>
              <input type="password" placeholder="Confirm New Password" formControlName="confirmPassword">
              <div *ngIf="registerTempf.confirmPassword.invalid
                             && (registerTempf.confirmPassword.dirty || userssAttributes.submitted)"
                class="alert alert-danger">
                <div *ngIf="registerTempf.confirmPassword.errors.required">Confirm New Password is
                  required</div>
              </div>
              <div *ngIf="registerTempRefError && registerTempRefError.mismatch && userssAttributes.submitted && (registerTempf.confirmPassword.value)"
                class="alert alert-danger">
                <div *ngIf="registerTempRefError && registerTempRefError.mismatch">Confirm Password Not Matched</div>
              </div>
            </div>
          </div>
          <div class="unregistered-users-form-right"><b> Type your new password again.</b></div>
          <div class="clear"></div>
        </div>
        <div class="un-user-input-row">
          <div class="unregistered-users-form-left form-check">
            <div class="find-exhibitors-check">
              <input type="checkbox" name="checkboxG1" id="checkboxG1" formControlName="emailCheckBox" isSelected="true"
                class="css-checkbox">
              <label for="checkboxG1" class="css-label">Send this password to the new user by
                email.</label>
            </div>
          </div>

          <div class="clear"></div>
        </div>

        <div class="un-user-input-row">
          <div class="unregistered-users-form-left">
            <div class="buttons">
              <input type="submit" value="Submit" class="btn btn-primary">
              <a href="javascript:void(0)" id="reset1" (click)="toggleForm()" class="btn btn-default">Cancel</a> </div>
          </div>
          <div class="clear"></div>
        </div>

      </form>
    </div>


  </div>
</ng-template>
