import { Pipe, PipeTransform } from "@angular/core";
import { COMPONENT_LIST } from "../directive/component-list";

@Pipe({ name: "getcomponent" })
export class GetComponentItemsPipe implements PipeTransform {
  /**
   * Return string with first character uppercase and remaning lowercase
   * @param value:any
   */
  transform(componentName: string) {
    return COMPONENT_LIST.filter((component) => {
      return componentName === String(component.componentClass.name);
    })[0].componentClass;
  }
}
