import { Component, OnInit } from "@angular/core";
import { EventService } from "../event/event.service";
import { ExhibitorService } from "../exhibitor-event-payment/exhibitor.service";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { environment } from "../../environments/environment";
import { CreditCardValidators } from "angular-cc-library";

import { DatePipe } from "@angular/common";
import { map, forEach } from "lodash";

import { forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { Globals } from "../globals";

import { TaxonomyService } from "../taxonomy/taxonomy.service";
import { NotifierService } from "angular-notifier";
import { UnregisteredExhibitor } from "../interface/unregister-exhibitor/unregister-exhibitor.interface";
import * as _ from "lodash";
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { ErrorsValidatorOptions } from "../interface/common-validators/common-validators.interface";
import { CommonService } from "../services/common.service";
import * as moment from "moment";

@Component({
  selector: "app-unregister-event-request",
  templateUrl: "./unregister-event-request.component.html",
  providers: [DatePipe],
})
export class UnregisterEventRequestComponent implements OnInit {
  eventReqItem: any;
  eventRequestFormData = {} as UnregisteredExhibitor;
  userData: any;
  screeningParentList: any;
  industriesList: any;
  electricity: boolean;
  unregisterData: any;
  authorizeForm: FormGroup;
  showRequest = 0;
  showPaymentFormForPublic = 0;
  zoom: number = 16;

  authorizeStep = "";
  step3form: FormGroup;
  savedMember: any;
  userInfoForm: FormGroup;
  userInfoObj: any;
  finalFormObj: any;
  paymentSubmitted = false;
  userBillingSubmitted = false;
  finalformgSubmitted = false;
  prodList: Array<string | any>;
  eventData: any;
  indusList: any = [];
  screeningList: any = [];

  eventsAttributes: any = {};
  showForm: boolean = false;
  showFirstForm: boolean = true;
  raffelType: any = [
    { name: "Yes", value: "true" },
    { name: "No", value: "false" },
  ];
  translatorJson: typeof TranslatorJson;

  electricityNeed: any = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];
  users = [
    { id: "anjmao", name: "Anjmao" },
    { id: "varnas", name: "Tadeus Varnas" },
  ];
  eventApprovalType = [
    { id: "free", name: "Free" },
    { id: "paid", name: "Paid" },
    { id: "hfp", name: "Hfp" },
  ];

  constructor(
    private eventService: EventService,
    private exhibitorService: ExhibitorService,
    private fb: FormBuilder,
    public globals: Globals,
    private taxonomyService: TaxonomyService,
    private notifier: NotifierService,
    private router: Router,
    private datePipe: DatePipe,
    private commonService: CommonService
  ) {
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  ngOnInit() {
        this.eventsAttributes = Object.assign(
      {},
      this.globals.adminPaginationAttrs
    );
    let dbId = this.router.url.substr(this.router.url.lastIndexOf("/") + 1);
    if (atob(dbId) == "payment=1") {
      //  let dbId2 = this.router.url.substr(this.router.url.lastIndexOf('/') + 0);
      let splitUrl = this.router.url.split("/");

      this.eventService.getUnregisterData(splitUrl[2]).subscribe(
        (resData) => {
          if (typeof resData === "object") {
            this.unregisterData = resData;
            if (resData.status == 0) {
              let whereUser = {
                filter: {
                  where: {
                    email: resData.email,
                  },
                },
              };
              this.eventService.findUserList(whereUser).subscribe((resss) => {
                this.userData =  resss && resss[0];
                let where = {
                  filter: {
                    where: {
                      id: resData.eventId,
                    },
                  },
                };
                this.eventService.getLocalEventListData(where).subscribe(
                  (res) => {
                    this.eventReqItem = res[0];
                    this.eventData = res;
                    //  console.log(this.eventData);
                    this.electricity = true;
                    this.getScreeningTaxo();

                    const newTaxoData = this.eventReqItem.exhibitorReq.concat(
                      this.eventReqItem.screenReq
                    );
                    this.getScreeningTaxoDetail(newTaxoData);

                    //   this.getScreeningTaxo(newTaxoData);

                  },
                  (err) => { }
                );
              });
            } else {
              this.router.navigate(["/"]);
            }
          } else {
            this.router.navigate(["/"]);
          }
        },
        (err) => this.router.navigate(["/"])
      );
    } else {
      // this.showRequest = 1;
      let splitUrl = this.router.url.split("/");
      this.eventService.getUnregisterData(splitUrl[2]).subscribe(
        (resData) => {
          if (typeof resData === "object") {
            this.unregisterData = resData;
            if (resData.status == 0) {
              let whereUser = {
                filter: {
                  where: {
                    email: resData.email,
                  },
                },
              };
              this.eventService.findUserList(whereUser).subscribe((resss) => {
                this.userData = resss && resss[0];
                let where = {
                  filter: {
                    where: {
                      id: resData.eventId,
                    },
                  },
                };
                this.eventService.getLocalEventListData(where).subscribe(
                  (res) => {
                    this.eventReqItem = res[0];
                    this.eventData = res;
                    // console.log(this.eventData);
                    this.electricity = true;
                    this.getScreeningTaxo();
                    const newTaxoData = this.eventReqItem.exhibitorReq.concat(
                      this.eventReqItem.screenReq
                    );
                    this.getScreeningTaxoDetail(newTaxoData);
                  },
                  (err) => { }
                );
              });
            } else {
              this.router.navigate(["/"]);
            }
          } else {
            this.router.navigate(["/"]);
          }
        },
        (err) => this.router.navigate(["/"])
      );
    }
    this.step3form = this.fb.group({
      description: ["", []],
    });
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {

    // } else {
    //   this.router.navigateByUrl("/");
    // }

    // Taxonomy type add form

  }
  showPaymentForm(): void {
    this.showFirstForm = false;

    this.showRequest = 1;
    this.showPaymentFormForPublic = 0;
  }
  firstFormInit() {
    this.authorizeForm = this.fb.group({
      card: this.fb.group({
        cardNumber: [
          "",
          [Validators.required, CreditCardValidators.validateCCNumber],
        ],
        expireAt: [
          "",
          [
            Validators.required,
            CreditCardValidators.validateExpDate,
            Validators.maxLength(7),
          ],
        ],
        cvv: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
          ],
        ],
        fullName: ["", [Validators.required, Validators.minLength(2)]],
        amount: [
          this.globals.registerPaymentAmount,
          [Validators.required, Validators.min(1)],
        ],
      }),
    });
    this.showPaymentFormForPublic = 1;
    this.authorizeStep = "event-step-b-1";
    //this.googleAddress('autocomplete1');
  }

  ///start payment

  // get power() { return this.userInfoForm.controls; }
  paymentSend() {
    if (this.authorizeForm.valid) {
      this.initBillingInfo();
      // console.log("I m in..22");
      const authorizeObj: any = Object.assign({}, this.authorizeForm.value);
      this.savedMember = authorizeObj;
      // console.log(this.authorizeForm.value);
      // return;
      this.authorizeStep = "event-step-b-2";

      if (this.userInfoObj) {
        this.userInfoForm.patchValue(this.userInfoObj);
        // console.log(this.savedMember);
        ///console.log(this.userInfoForm);return
      }
    } else {
      this.paymentSubmitted = true;
    }
  }

  backInintFirstForm() {
    this.firstFormInit();
    this.authorizeStep = "event-step-b-1";
    this.authorizeForm.patchValue(this.savedMember);
    setTimeout(() => {
      const cardNumberInput = document.querySelector('.card-nmr') as HTMLElement;
      if (cardNumberInput) {
        const event = new Event('input');
        cardNumberInput.dispatchEvent(event); 
      }
    })
  }

  //second form
  initBillingInfo() {
    this.userInfoForm = this.fb.group({
      users: this.fb.group({
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        company: ["", []],
        address: ["", Validators.required],
        city: ["", Validators.required],

        state: ["", Validators.required],
        zipcode: ["", Validators.required],
        country: ["", Validators.required],

        phone: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(12),
          ],
        ],
        email: [this.userData.email, [Validators.required, Validators.email]],
      }),
    });
    setTimeout(() => {
      this.googleAddress("autocomplete1");
      this.googleAddress("autocomplete2");
    }, 2000);
  }

  /**
   * Render for Google autocomplete
   */
  ngAfterViewInit() {
    this.googleAddress("autocomplete");
  }

  get phoneNumberGroup(){
    return this.userInfoForm.get('users') as FormGroup
  }
  get data() {
    return this.phoneNumberGroup.controls;
  }

  get phone(){
    return this.data.phone
   }

   getValidationErrors(options: ErrorsValidatorOptions): string {
    return this.commonService.getErrorMessage(options);
  }

  /*
   * Google Address
   */
  googleAddress(id: string) {
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>document.getElementById(id),
      {
        types: ["geocode"],
      }
    );
    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      const place = autocompleteFrom.getPlace();
      if (place.address_components && place.address_components.length) {
        if (place.formatted_address) {
          this.userInfoForm.patchValue({
            address: { addressOne: place.formatted_address },
          });
        }
        for (let i = 0; i < place.address_components.length; i++) {
          this.userInfoForm.patchValue({
            address: { lat: place.geometry.location.lat() },
          });

          this.userInfoForm.patchValue({
            address: { lng: place.geometry.location.lng() },
          });
          this.userInfoForm.patchValue({
            location: [
              place.geometry.location.lng(),
              place.geometry.location.lat(),
            ],
          });

          let addressType = place.address_components[i].types[0];
          if (addressType === "administrative_area_level_2") {
            this.userInfoForm.patchValue({
              address: { city: place.address_components[i].long_name },
            });
          }
          if (addressType === "administrative_area_level_1") {
            this.userInfoForm.patchValue({
              address: { state: place.address_components[i].long_name },
            });
          }
          if (addressType === "postal_code") {
            this.userInfoForm.patchValue({
              address: { zip_code: place.address_components[i].long_name },
            });
          }
        }
      }
    });
  }

  //get formControls() { return this.authorizeForm.controls; }
  userInfoSubmitForm() {
    if (this.userInfoForm.valid) {
      const infObj: any = Object.assign({}, this.userInfoForm.value);
      this.userInfoObj = infObj;
      this.authorizeStep = "event-step-b-2";
      //  this.initBillingInfo();
      // this.thirdFormInit();
      if (this.userInfoObj) {
        this.userInfoForm.patchValue(this.userInfoObj);
        //this.finalFormObj = finalObj;
        // console.log(this.savedMember);
      }
    } else {
      this.userBillingSubmitted = true;
    }
  }
  // thirdFormInit() {
  //   this.step3form = this.fb.group({
  //     description: ["", []],
  //   });
  // }

  finalSubmitForm() {
    if (this.userInfoForm.valid) {
      //   console.log(this.step3form.value);
      const finalObj: any = Object.assign({});
      if (finalObj) {
        this.newRequest();

        this.finalFormObj = finalObj;
        const firstFormVal: any = Object.assign({}, this.savedMember.card);
        const secondFormVal: any = Object.assign({}, this.userInfoObj.users);
        // const finalFormVal: any = this.finalFormObj.description;

        this.prodList = map(this.eventData, function (x) {
          return {
            eventId: x.id,
            eventName: x.eventName,
            eventPrice: firstFormVal.amount,
            recordId: x.id,
          };
        });
        // console.log(this.prodList);

        const sendObj: any = {
          userName: this.userData.username,
          userId: this.userData.id,
          userEmail: this.userData.email,
          cardNumber: firstFormVal.cardNumber.replace(/ /g, ""),
          cardExpiry: firstFormVal.expireAt
            .replace(/\//g, "")
            .replace(/ /g, ""),
          //  cardExpiry: "0922",
          cardCvv: firstFormVal.cvv.replace(/ /g, ""),
          // prodDesc: finalFormVal,
          firstName: secondFormVal.firstName,
          lastName: secondFormVal.lastName,
          totalPayment: this.unregisterData.amount,

          address: {
            address: secondFormVal.address,
            city: secondFormVal.city,
            state: secondFormVal.state,
            zip: secondFormVal.zipcode,
            country: secondFormVal.country,
          },
          prodList: this.prodList,
          phone: secondFormVal.phone,
          fullName: firstFormVal.fullName
          // orderDescription: finalFormVal,
        };

        this.exhibitorService.unregisterPaymentEvent(sendObj).subscribe(
          (data) => {
            // console.log(data);
            if (data.status == 400) {
              this.notifier.notify("error", data.desc);
            } else if (data.id != "") {
              //console.log(data);
              //  this.toggleForm();
              this.step3form.reset();
              this.userInfoForm.reset();
              this.authorizeForm.reset();
              //email start
              let temEmail: any = {};
              temEmail.userId = this.userData.id;
              temEmail.slug = "register-payment-template";
              temEmail.subject = "Registration Payment";

              temEmail.keys = {
                USERNAME: this.userData.username,
                transactionId: data.transId,
                invoiceNumber: data.invoiceNumber,
                amount: this.unregisterData.amount,
                companyName:this.userData && this.userData.data && this.userData.data.company_name
              };
              this.emailSends(temEmail);
              //email end
              this.notifier.notify("success", "Your Payment is Successful.");
              this.showRequest = 1;
              this.authorizeStep = "";
              // this.router.navigateByUrl('/login');
            }
            // this.finalformgSubmitted = false;
            // Push new object on zero index
            // this.wAttributes.pageItems.splice(0, 0, data);
            // this.wAttributes.count += 1;
          },
          (err) => {
            this.notifier.notify("error", err.error.error.message);
            //console.log(err);
          }
        );
        /**/
        // console.log(this.savedMember);
        // console.log(this.userInfoObj);
        // console.log(this.finalFormObj);
      }
    } else {
      //   this.finalformgSubmitted = true;
    }
  }




  /**
   * This function is used to get screening taxo list.
   */
  getScreeningTaxo(): void {
    forkJoin([
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId,
          },
        },
      }),
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.industryId,
          },
        },
      }),
    ]).subscribe(
      (results) => {
        this.screeningParentList = results[0] ? results[0] : [];
        this.industriesList = results[1] ? results[1] : [];
        if (this.userData.industry && this.userData.industry.length > 0) {
          let selectedIndustry: any = [];
          this.industriesList = selectedIndustry;
        }

        //screening
        if (this.eventData && this.eventData[0].screenReq && this.eventData[0].screenReq.length > 0) {
          let selectedScreening: any = [];
          this.screeningParentList.forEach(async (value) => {
            _.find(this.eventData[0].screenReq, function (o) {
              if (String(o) == String(value.id)) {
                selectedScreening.push(value);
              }
            });
          });

          this.screeningParentList = selectedScreening;
        }
      },
      (err) => { }
    );
  }
  // Get screening taxo list
  getScreeningTaxoDetail(ids): void {
    const queryObj = {
      filter: {
        where: {
          status: 1,
          id: { inq: ids },
        },
      },
    };
    //   console.log(queryObj);
    forkJoin([this.taxonomyService.getTaxonomyListNew(queryObj)]).subscribe(
      (results) => {
        const taxoList = results[0] ? results[0] : [];
        forEach(taxoList, (data, key) => {
          if (
            data.taxonomyTypeId == this.globals.taxonomyTypesConst.industryId
          ) {
            this.indusList.push(data);
          }
          if (
            data.taxonomyTypeId == this.globals.taxonomyTypesConst.screeningId
          ) {
            this.screeningList.push(data);
          }
        });
      },
      (err) => { }
    );
  }

  backInintSecondForm() {
    this.initBillingInfo();
    this.authorizeStep = "event-step-b-2";
    this.userInfoForm.patchValue(this.userInfoObj);
  }

  /*Google Direction*/
  initMap() {
    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer();
    directionsDisplay.setPanel(document.getElementById("right-panel"));
    this.calculateAndDisplayRoute(directionsService, directionsDisplay);
  }

  calculateAndDisplayRoute(directionsService, directionsDisplay) {
    directionsService.route(
      {
        origin: { lat: 26.9124, lng: 75.7873 },
        destination: {
          lat: this.eventReqItem.eventAddress.lat,
          lng: this.eventReqItem.eventAddress.lng,
        },
        //  optimizeWaypoints: true,
        travelMode: "DRIVING",
      },
      function (response, status) {
        if (status === "OK") {
          directionsDisplay.setDirections(response);
        } else {
          console.log("Directions request failed due to " + status);
        }
      }
    );
  }

  newRequestHandle() {
      this.showRequest = 0;

      // console.log(atob(dbId));
      // return
      let dbId = this.router.url.substr(this.router.url.lastIndexOf("/") + 1);
      if (atob(dbId) == "payment=1") {
        this.firstFormInit();

        //  let dbId2 = this.router.url.substr(this.router.url.lastIndexOf('/') + 0);
        // let splitUrl=this.router.url.split("/");

        this.notifier.notify(
          "success",
          "For your event registration to be completed you have to pay the requisite event fee."
        );
      } else {
        this.newRequest();
      }
  }

  searchTaxo(term: string, item: any) {
    // console.log(term,item);
    let strSearchLow = term.toLowerCase();
    let objectLowerCase = item.name.toLowerCase();
    var n = objectLowerCase.startsWith(strSearchLow);
    if (n && n == true) {
      return item;
    }
  }

  /**
   * This function is used for event request for exhibitor
   */
  newRequest() {
    const eventReqObj = Object.assign({}, this.eventRequestFormData);
      eventReqObj.userid = this.userData.id;
      eventReqObj.localEventId = this.eventReqItem.id;
      eventReqObj.eventSlug = this.eventReqItem.permalink;
      eventReqObj.status = 1;
      eventReqObj.eventType = this.eventReqItem.eventType;
      let selectedEventDate = moment(this.eventReqItem.eventDate).format("YYYY-MM-DD");
      let selectedEventEndDateTimeStamp: any = moment(this.eventReqItem.endDateTime).format("THH:mm:ss");
      let finalEventDate = selectedEventDate + selectedEventEndDateTimeStamp;
        eventReqObj.eventDate = finalEventDate
       let timeZone = this.getTimeZone();
      eventReqObj.timeZone = timeZone    
      //   return
      this.eventService.addRequestForEvent(eventReqObj).subscribe(
        (data) => {
          const queryObj = {
            status: 1,
          };

          this.eventService
            .updateUnregister(this.unregisterData.id, queryObj)
            .subscribe(
              (data) => {
                let temEmail: any = {};
                temEmail.userId = this.eventReqItem.memberId;
                let urls =
                  environment.sentEmailHost +
                  "/view-exhibitors/" +
                  this.eventReqItem.permalink;
                temEmail.slug = "event-request";
                //  temEmail.subject = 'Your Event Approved';
                //   temEmail.keys = { userName: res };
                temEmail.keys = {
                  EVENT: this.eventReqItem.eventName,
                  USERNAME: this.eventReqItem.corpData[0].username,
                  INQUIRY_URL: `<a href=${urls} >Click Here</a>`, 
                  COMPANY_NAME:this.eventReqItem.corpData[0].data.company_name,
                  CORPORATE_CONTACT_NAME: this.eventReqItem.corpData[0].contact,
                };
                this.emailSends(temEmail);

                //email send to exhibitor
                let exhibitorEmail: any = {};
                exhibitorEmail.userId = this.userData.id;
                exhibitorEmail.slug = "corporate-event-accept";
                let url =
                  environment.sentEmailHost +
                  "/event-detail/" +
                  this.eventReqItem.permalink;
                //  temEmail.subject = 'Your Event Approved';
                //   temEmail.keys = { userName: res };
                exhibitorEmail.keys = {
                  EVENT: this.eventReqItem.eventName,
                  USERNAME: this.userData.username,
                  CORPORATE_USER_NAME: this.eventReqItem.corpData[0].username,
                  EVENT_DATE: this.datePipe.transform(
                    this.eventReqItem.eventDate,
                    "MMM d, y"
                  ),
                  EVENT_ADDRESS: this.eventReqItem.eventAddress.location
                    ? this.eventReqItem.eventAddress.location
                    : this.eventReqItem.eventAddress.formatted_address,
                  EVENT_START_TIME: this.datePipe.transform(
                    this.eventReqItem.startDateTime,
                    "h:mm a"
                  ),
                  EVENT_END_TIME: this.datePipe.transform(
                    this.eventReqItem.endDateTime,
                    "h:mm a"
                  ),
                  RAFFLE: this.eventReqItem.requestReffleItem,

                  CONTACT: this.eventReqItem.corpData[0].data.contact,
                  //    CONTACT_NUMBER: this.eventReqItem.PhoneNo,
                  PARKING_INST: this.eventReqItem.parkingInstructions,
                  CHECK_IN: this.eventReqItem.checkIn,
                  EVENT_URL: `<a href=${url} >Click Here</a>`,
                  COMPANY_NAME:this.eventReqItem.corpData[0].data.company_name,
                  CORPORATE_CONTACT_NAME: this.eventReqItem.corpData[0].contact,
                  EMAIL:this.eventReqItem.corpData[0].email,
                  PHONE_NUMBER: this.eventReqItem.phone
                  // userName: this.eventReqItem.hostedBy
                };
                this.emailSends(exhibitorEmail);
                //end email send to exhibitor

                // this.eventReqItem.eventReqExist=false;
                // this.eventReqItem['eventReqData']=data;
                this.notifier.notify("success", "Request submitted successfully");
                this.showForm = false;
                this.router.navigate(["/"]);
              },
              (err) => { }
            );
          // this.closPopup();
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
            // this.closPopup();
          }
        }
      );
  }
  getTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  }
  emailSends(sendObj: any) {
    this.eventService.sendEmails(sendObj).subscribe(
      (data) => {
        console.log("email send success");
        console.log(data);
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
        }
      }
    );
  }
  onAddreessChange(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>event.srcElement,
      {
        types: ["geocode"],
      }
    );
    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      const place = autocompleteFrom.getPlace();
      if (place.address_components && place.address_components.length) {
        if (place.formatted_address) {
          this.userInfoForm.patchValue({
            users: { addressOne: place.formatted_address },
          });
        }
        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          if (addressType === "administrative_area_level_2") {
            this.userInfoForm.patchValue({
              users: { city: place.address_components[i].long_name },
            });
          }
          if (addressType === "administrative_area_level_1") {
            this.userInfoForm.patchValue({
              users: { state: place.address_components[i].long_name },
            });
          }
          if (addressType === "postal_code") {
            this.userInfoForm.patchValue({
              users: { zipcode: place.address_components[i].long_name },
            });
          }
        }
      }
    });
  }

  onSubmitRequest(data: any) {
    this.eventRequestFormData = data;
    this.newRequestHandle();
  }

  finalPaymentSubmitFormWithUserInfo(){
    forkJoin([
      this.userInfoSubmitForm(),
      this.finalSubmitForm()
    ]);
  }
  getServices(serviceId:string):string{
    let selectedService:string
     this.screeningParentList.forEach((value) => {
      if (value._id == serviceId) {
        selectedService =  value.name;
      }
    });
    if(selectedService)   return selectedService 
  }
}
