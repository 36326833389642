import {EventInviteDetails } from "src/app/interface/event-details/event_detail.interface";
import { CommonStatus } from "src/app/constants/common-status.constants";
import { SystemConstants } from "src/app/constants/system.constants";
import { UserRole } from "src/app/constants/user-role.constants";

export class EventUserDetails {
    /**
     *
     *
     * @type {string}
     * @memberof EventUserDetails
     * this field contains email for the invitee of event
     */
    username:string[];
    role: number;
    status: number;
    eventStatus: number;
    password: string;
    email: string[];
    image: {
        type: string,
        name: string,
    };
    id:string;
    length:number
    errMsg:string
    address: {
        formatted_address: string,
        state: string,
        city: string,
        zip_code: string,
        lat: number,
        lng: number,
        loc: [number, number],
        addressOne: string,
    }
    data: {
        number_employee: number,
        company_name: string,
        contact: string,
        phone_number: string,
        title: string,
        website: string,
        medical: string,
        // insurance: string,
        // attendevent: string,
        // infoFair: string,
        // offered: string,
        reminder: [number, number, number, number],
    }
  industry: string[];
  screening: string[];
    
    constructor(eventInviteData:EventInviteDetails) {
        this.username = eventInviteData.email,
        this.role = UserRole.EXHIBITOR,
        this.status = CommonStatus.PENDING,
        this.eventStatus = CommonStatus.PENDING,
        this.password = SystemConstants.STRING
        this.email =  eventInviteData.email,
        this.image = {
            type: SystemConstants.AVATAR,
            name: SystemConstants.AVATAR_IMG
        }
    }
   
};