<div class="main-heading fixedbar" style="background-image:url(/assets/images/dashboard.jpg);">
  <div class="img-overly"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
          <div class="main-user-img-upload" hidden>
            <input type="file">
            <i class="fa fa-camera" aria-hidden="true"></i></div>
          <img src="/assets/images/main-user-img.jpg" alt=""></div>
        <h2>Van Arnold</h2>
      </div>
      <div class="main-heading-right">
        <h1>Screenings industry</h1>
      </div>

    </div>
  </div>
</div>


<div class="main-admin-search screenings-industry-search">
  <div class="container clearfix">


      <div class="main-admin-search-user-box">
        <input type="text" value="" placeholder="Search User">
      </div>

      <div class="main-admin-search-button">
        <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
      </div>

    <div class="main-admin-add"  *ngIf="globals.developmentKey">
      <a class="outline-btn" href="javascript:void(0);"  (click)="toggleForm()" ><i class="fa fa-plus" aria-hidden="true"></i> {{"ADD_TAXONOMY_TYPE" | translate}}</a>
    </div>
  </div>
</div>

<!-- Define form template here-->
<div *ngIf="tTAttributes.isShow; then taxonomyTypeFormTemp"></div>

<!-- Taxonomy Type List Section content Start here-->
<div class="add-box-list-section">
  <div class="container">
    <div class="hfp-row clearfix">
      <div *ngFor="let data of tTAttributes.pageItems let idx = index">
          <div class="hfp-col-3" [ngClass]="{active:tTAttributes.isActiveIdx==idx}">
          <div class="a-b-l-s-div manage-page-box">
            <div class="manage-page-img" style="background-image:url(/assets/images/admin-pg-img-1.jpg);"></div>
            <div class="img-overly"></div>
            <div class="box_content_center">
              <div class="box_content_center_in">
              <h3>{{ data.name | truncate:[43, '...'] | capitalize }}</h3>
            <div class="a-d-edit">
              <ul>
                <li><a (click)="getTaxonomyTypeForEdit(idx);" href="javascript:void(0)"><i class="fa fa-pencil"
                      aria-hidden="true"></i></a>
                </li>
                <li *ngIf="globals.developmentKey"><a href="javascript:void(0)" (click)="deleteTaxonomyType(idx)"><i class="fa fa-trash-o"
                      aria-hidden="true"></i></a>
                </li>
              </ul>
            </div>
              </div></div>
          </div>
        </div>
        <div class="hfp-row clearfix ad-form-edit" *ngIf="tTAttributes.isIndex==idx;">
          <div class="hfp-col-12">
            <div *ngIf="tTAttributes.isIndex==idx; then taxonomyTypeFormTemp"></div>
          </div>
        </div>
      </div>
    </div>
    <pagination [totalItems]="tTAttributes.count" [rotate]="tTAttributes.rotate" [maxSize]="tTAttributes.maxSize"
      [itemsPerPage]="tTAttributes.limits" (pageChanged)="pageChanged($event);">
    </pagination>
  </div>
</div>
<!-- Taxonomy Type List Section content End here-->

<!-- Taxonomy Type Form template content Start here-->
<ng-template #taxonomyTypeFormTemp>
<div class="" [ngClass]="{'edit_form_type': !tTAttributes.isShow}">
  <div class="container">
    <div class="add-new-container">
      <div class="form-close"><button (click)="toggleForm()" id="close-1"><img src="/assets/images/add-form-close.png" alt=""></button></div>
      <h2>{{ (tTAttributes.itemArray.id ? "EDIT_TAXONOMY_TYPE" : "ADD_NEW_TAXONOMY_TYPE")  | translate}}</h2>
      <form [formGroup]="taxonomyTypeForm" (ngSubmit)="tTAttributes.itemArray.id ? updateTaxonomyType(tTAttributes.itemArray.id) : addTaxonomyType()">
      <div class="fold-form">
        <div class="hfp-input-div">
          <label>{{"TAXONOMY_TYPE_NAME" | translate}}</label>
          <input class="" type="text" placeholder="{{'ENTER_TAXONOMY_TYPE_NAME' | translate}}" formControlName="name">
          <div *ngIf="tt.name.invalid && (tt.name.dirty || tTAttributes.submitted )" class="alert alert-danger">
            <div *ngIf="tt.name.errors.required">{{"NAME_IS_REQUIRED" | translate}}</div>
            <div *ngIf="tt.name.errors.minlength">{{"NAME_MUST_BE_AT_LEAST_2_CHARACTERS" | translate}}</div>
            <div *ngIf="tt.name.errors.maxlength">{{"NAME_SHOULD_NOT_MORE_120_CHARACTERS"
              | translate}}</div>
          </div>
        </div>
        <div class="hfp-input-div">
          <label>{{"STATUS" | translate}}</label>
          <div class="switch-field Status-tab">
            <div *ngFor="let status of globals.statusType; let idx = index;">
              <input type="radio" id="switch_left{{idx}}" name="status" formControlName="status" value="{{status.value}}" [checked]="tTAttributes.statusSelected==status.value" />
              <label for="switch_left{{idx}}">{{status.name}}</label>
            </div>
          </div>
          <div *ngIf="tt.status.invalid && (tt.status.dirty || tTAttributes.submitted )" class="alert alert-danger">
            <div *ngIf="tt.status.errors.required">{{"STATUS_IS_REQUIRED" | translate}}</div>
          </div>
        </div>
        <div class="buttons">
          <button class="btn btn-primary">{{"SUBMIT" | translate}}</button>
          <button class="btn btn-default" (click)="toggleForm()">{{"CANCLE" | translate}}</button>
        </div>
      </div>
      </form>
    </div>
  </div>
</div>
</ng-template>
