import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import IIndusties type interface.
import { IIndusties } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndustriesService {
   // Define the class property.
   private url: string = environment.apiUrl + '/industries';
   constructor(private http: HttpClient) { }
  /**
   * This service add Industies
   */

  addIndusties(industrieObj: IIndusties): Observable<IIndusties> {
    return this.http.post<IIndusties>(this.url, industrieObj);
  }

  /**
   * This service return total cound of Industies
   */
  getIndustiesList(industrieObj: object): Observable<[]> {
    return this.http.get <[]>(this.url + '?' + qs.stringify(industrieObj));
  }

  /**
   * This service return total count of Industies
   */
  getIndustiesCount(industrieObj: object): Observable<any> {
    return this.http.get<any>(this.url + '/count?' + qs.stringify(industrieObj));
  }

  /**
   * this service update Industies.
   */
  updateIndusties(id: string, industrieObj: any): Observable<IIndusties> {
    return this.http.patch<IIndusties>(this.url + '/' + id, industrieObj);
  }
 /**
  * this service get Industies using id.
  */
  getIndustiesById(id: string): Observable<IIndusties> {
    return this.http.get<IIndusties>(this.url + '/' + id);
  }
}
