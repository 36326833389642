import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { SharedService } from '../shared.service';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { EventService } from '../event/event.service';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { UnregisteredExhibitorsService } from '../unregistered-exhibitors/unregistered-exhibitors.service';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
// Import utility method class
import { Utility } from '../utility';
import { FileUploader } from 'ng2-file-upload';
// Handle the global property
import { NotifierService } from 'angular-notifier';
import { Globals } from '../globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import * as TranslatorJson from '../../assets/i18n/en.json'
import { SystemConstants } from '../constants/system.constants';
import { CommonStatus } from '../constants/common-status.constants';
import { UserRole } from '../constants/user-role.constants';

@Component({
  selector: 'app-event-inquiries',
  templateUrl: './event-inquiries.component.html',
  styleUrls: ['./event-inquiries.component.scss'],
  providers: [DatePipe]
})
export class EventInquiriesComponent implements OnInit {
  userData: any;
  resetSearchForm: any = false;
  userSlug: string = '';
  userRole: any = null;
  loginUserRole: any = null;
  showStatus = false;
  pending: any;
  approved: any;
  decline: any;
  pendingFix: any;
  approvedFix: any;
  declineFix: any;
  totalRequest = 0;
  eventReqAttributes: any = {};
  userListOfEventReq: any = [];
  showExhibStatus = false;
  showEventListIndx: number;
  showExhibListIndx: number;
  eventSingleItem: any = {};
  userSingleItem: any = {};
  eventInqurieSearchForm: FormGroup;
  eventInqStatus = 1;
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  enquirySearch: number = -1;
  filterOptions = [
    { id: 'event', name: 'Filter by events' },
    { id: 'date', name: 'Filter by date' },
    { id: 'exhibitor', name: 'Filter by exhibitors' }
  ];
 translatorJson: typeof TranslatorJson;
 systemConstants = SystemConstants;
 commonStatus =CommonStatus
 currentPage = this.systemConstants.DEFAULT_CURRENT_PAGE
 private notifier: NotifierService;
  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private appComponent: AppComponent,
    private taxonomyService: TaxonomyService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: BsModalService,
    public globals: Globals,
    private loginService: LoginService,
    private eventService: EventService,
    private utility: Utility,
    private datePipe: DatePipe,
    private injector: Injector,
    private unregisteredExhibitorsService: UnregisteredExhibitorsService,
  ) { 
    this.notifier = notifierService;
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  ngOnInit() {

    // This  is event Inq  seach form
    this.eventInqurieSearchForm = this.fb.group({
      fieldName: ['', []],
      filterCont: ['', []],
      eventDate: ['', []],
    });

    // Copy object
    this.eventReqAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   forkJoin([
    //     this.getRequestCountsAndTotaInquiries(),

    //     // this.getCountsForEventListWithReqNgLoad(this.eventInqStatus),
    //     // this.getCountsForEventListWithReqNgLoad(0),
    //     // this.getCountsForEventListWithReqNgLoad(2),
    //     this.getEventListWithReq(this.eventInqStatus, this.eventReqAttributes.limits, 0),
    //   ]);
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        if(this.loginUserRole === UserRole.SUPER_ADMIN){
          forkJoin([
            this.getRequestCountsAndTotaInquiries(),
            this.getCountsForEventListWithReq(this.eventInqStatus),
            // this.getCountsForEventListWithReqNgLoad(this.eventInqStatus),
            // this.getCountsForEventListWithReqNgLoad(0),
            // this.getCountsForEventListWithReqNgLoad(2),
            this.getEventListWithReq(this.eventInqStatus, this.eventReqAttributes.limits, 0),
          ]);
        }else{
          this.router.navigateByUrl('/home');
        }
      
      } else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });

  }

  callFromTabs(statusNumber: number, limitNum: number, skipNum: number): void {
    // this.userListOfEventReq = [];
    this.eventReqAttributes.pageItems = [];
    if (this.resetSearchForm) {
      this.eventInqurieSearchForm.reset();
      forkJoin([
        // this.getRequestCountsAndTotaInquiries(),
        this.getCountsForEventListWithReqNgLoad(this.eventInqStatus),
        this.getCountsForEventListWithReqNgLoad(0),
        this.getCountsForEventListWithReqNgLoad(2)
      ]);
    }
    forkJoin([
      this.getCountsForEventListWithReq(statusNumber),
      this.getEventListWithReq(statusNumber, this.eventReqAttributes.limits, this.eventReqAttributes.skips)]);
  }
  searchEventEnquiry(statusNumber: number, limitNum: number, skipNum: number): void {
    this.userListOfEventReq = [];
    this.enquirySearch = 1;
    this.eventReqAttributes.pageItems = [];
    if (this.resetSearchForm) {
      this.eventInqurieSearchForm.reset();
      forkJoin([
        // this.getRequestCountsAndTotaInquiries(),
        this.getCountsForEventListWithReqNgLoad(this.eventInqStatus),
        this.getCountsForEventListWithReqNgLoad(0),
        this.getCountsForEventListWithReqNgLoad(2)
      ]);
    }
    forkJoin([
      this.getCountsForEventListWithReq(statusNumber),
      this.getEventListWithReq(statusNumber, this.eventReqAttributes.limits, this.eventReqAttributes.skips)]);
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getEventListWithReq(this.eventInqStatus, this.eventReqAttributes.limits, startItem);
  }

  /**
   * This function is used to get request counts.
   */
  getRequestCountsAndTotaInquiries(): void {

    forkJoin([
      this.eventService.findEventReqCount({ where: { status: 0 } }),
      this.eventService.findEventReqCount({ where: { status: 1 } }),
      this.eventService.findEventReqCount({ where: { status: 2 } })
    ]).subscribe(results => {
      this.pendingFix = results[0] ? results[0] : 0;


      let pendings = results[0] ? results[0] : 0;
      let approveds = results[1] ? results[1] : 0;
      let declines = results[2] ? results[2] : 0;
      this.approvedFix = results[1] ? results[1] : 0;
      this.declineFix = results[2] ? results[2] : 0;
      // eventReqAttributes
      this.totalRequest = (Number(pendings) + Number(approveds) + Number(declines));
      if (this.eventInqStatus == 0) {
        this.eventReqAttributes.count = pendings

      }
      else if (this.eventInqStatus == 1) {
        this.eventReqAttributes.count = approveds

      }
      else if (this.eventInqStatus == 2) {
        this.eventReqAttributes.count = declines

      }
      /*  if (this.pending.length == 0) {
          this.pending = 0;
        }
        if (this.approved.length == 0) {
          this.approved = 0;
        }
        if (this.decline.length == 0) {
          this.decline = 0;
        }
       
  
  */
      // 
    }, (err) => { });

  }


  // Get Exhibitor list for Event
  getExhibitorList(eventId: string): void {
    const queryObj: any = { filter: { where: { localEventId: eventId, status: this.eventInqStatus } } };
    this.eventService.getEventExibitorList(queryObj)
      .subscribe(data => {
        this.userListOfEventReq = (data && data.length) ? data : [];
      }, (err) => { });
  }

  /**
   * this function is used to get list of event req
   * @param status number
   */
  getEventListWithReq(statusNumber: number, limitNum: number, skipNum: number): void {
    this.eventInqStatus = statusNumber;
    const queryObj: any = { status: statusNumber, limit: limitNum, skip: skipNum };
    // This statement for serch widget type list
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value
      && this.eventInqurieSearchForm.value.filterCont == 'exhibitor'
      && this.eventInqurieSearchForm.value.fieldName) {
      queryObj.firstName = this.eventInqurieSearchForm.value.fieldName;
    }

    // This statement for serch widget type list
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value
      && this.eventInqurieSearchForm.value.filterCont == 'event'
      && this.eventInqurieSearchForm.value.fieldName) {
      /* const perma = this.eventInqurieSearchForm.value.fieldName.replace('/event/', '').trim().replace(/[^A-Z0-9-]/ig, '-').
       replace(/-{2,}/g, '-').toLowerCase();*/
      queryObj.eventSlug = this.eventInqurieSearchForm.value.fieldName;

      // queryObj.eventSlug = this.eventInqurieSearchForm.value.fieldName;
    }

    // This statement for serch widget type list
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value
      && this.eventInqurieSearchForm.value.filterCont == 'date'
      && this.eventInqurieSearchForm.value.eventDate) {


      var start = new Date(this.eventInqurieSearchForm.value.eventDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventInqurieSearchForm.value.eventDate[1]);
      end.setHours(23, 59, 59, 999);

      queryObj.created = { gt: start, lte: end };
    }

    this.eventService.getEventReqInquiresForAdminData(queryObj)
      .subscribe(data => {
        this.eventReqAttributes.pageItems = (data && data.length) ? data : [];

      }, (err) => { });
  }

  /**
   * this function is used to get list of event req
   * @param status number
   */
  getCountsForEventListWithReq(statusNumber: number): void {
    const queryObj: any = { status: statusNumber };
    // This statement for search
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value && this.eventInqurieSearchForm.value.filterCont == 'exhibitor'
      && this.eventInqurieSearchForm.value.fieldName) {
      queryObj.firstName = this.eventInqurieSearchForm.value.fieldName;
    }

    // This statement for search
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value && this.eventInqurieSearchForm.value.filterCont == 'event'
      && this.eventInqurieSearchForm.value.fieldName) {
      /*  const perma = this.eventInqurieSearchForm.value.fieldName.replace('/event/', '').trim().replace(/[^A-Z0-9-]/ig, '-').
        replace(/-{2,}/g, '-').toLowerCase();*/
      queryObj.eventSlug = this.eventInqurieSearchForm.value.fieldName;
    }

    // This statement for search
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value && this.eventInqurieSearchForm.value.filterCont == 'date'
      && this.eventInqurieSearchForm.value.eventDate) {

      var start = new Date(this.eventInqurieSearchForm.value.eventDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventInqurieSearchForm.value.eventDate[1]);
      end.setHours(23, 59, 59, 999);

      queryObj.created = { gt: start, lte: end };
    }
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",this.resetSearchForm);




    //console.log();
    this.eventService.getCountsEventReqInquiresForAdmin(queryObj)
      .subscribe(data => {
        this.eventReqAttributes.count = (data && data.count) ? data.count : 0;

        if (this.eventInqStatus === 1) {
          // console.log(data);
          //  this.adminApprovedCount = (data && data.count) ? data.count : 0;
          this.approved = data ? data.count : 0;

        }
        if (this.eventInqStatus === 0) {

          this.pending = data ? data.count : 0;
          //this.eventsAttributes.count = (data && data.count) ? data.count : 0;
        }
        if (this.eventInqStatus === 2) {
          this.decline = data ? data.count : 0;
          //  this.eventsAttributes.count = (data && data.count) ? data.count : 0;
        }


      }, (err) => { });
  }
  /**
  * this function is used to get list of event req
  * @param status number
  */
  getCountsForEventListWithReqNgLoad(statusNumber: number): void {
    const queryObj: any = { status: statusNumber };
    // This statement for search
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value && this.eventInqurieSearchForm.value.filterCont == 'exhibitor'
      && this.eventInqurieSearchForm.value.fieldName) {
      queryObj.firstName = this.eventInqurieSearchForm.value.fieldName;
    }

    // This statement for search
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value && this.eventInqurieSearchForm.value.filterCont == 'event'
      && this.eventInqurieSearchForm.value.fieldName) {
      /*  const perma = this.eventInqurieSearchForm.value.fieldName.replace('/event/', '').trim().replace(/[^A-Z0-9-]/ig, '-').
        replace(/-{2,}/g, '-').toLowerCase();*/
      queryObj.eventSlug = this.eventInqurieSearchForm.value.fieldName;
    }

    // This statement for search
    if (this.eventInqurieSearchForm && this.eventInqurieSearchForm.value && this.eventInqurieSearchForm.value.filterCont == 'date'
      && this.eventInqurieSearchForm.value.eventDate) {
      var start = new Date(this.eventInqurieSearchForm.value.eventDate);
      start.setHours(0, 0, 0, 0);
      queryObj.created = start;
    }
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",this.resetSearchForm);




    //console.log();
    this.eventService.getCountsEventReqInquiresForAdmin(queryObj)
      .subscribe(data => {
        this.eventReqAttributes.count = (data && data.count) ? data.count : 0;

        if (statusNumber === 1) {
          // console.log(data);
          //  this.adminApprovedCount = (data && data.count) ? data.count : 0;
          this.approved = data ? data.count : 0;

        }
        if (statusNumber === 0) {

          this.pending = data ? data.count : 0;
          //this.eventsAttributes.count = (data && data.count) ? data.count : 0;
        }
        if (statusNumber === 2) {
          this.decline = data ? data.count : 0;
          //  this.eventsAttributes.count = (data && data.count) ? data.count : 0;
        }
        //  this.totalRequest = (Number(this.pending) + Number(this.approved) + Number(this.decline));


      }, (err) => { });
  }

  acceptInvitationOfEvent(id: string, index: number, actionNumber: number): void {
    const queryObj: any = { status: 1, cancelBeforePaid: 0 };
    this.eventService.updateEventReqById(id, queryObj)
      .subscribe(data => {
        this.getTotalEventInquiriesAndCount(this.commonStatus.ACTIVE, this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP)
        this.getTotalEventInquiriesAndCount(this.commonStatus.PENDING, this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP)
        this.getTotalEventInquiriesAndCount(this.commonStatus.DECLINE, this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP)                     
        
        let temEmail: any = {};
        temEmail.userId = this.userListOfEventReq[index].id;
        //
        temEmail.slug = 'corporate-event-accept';
        let url = environment.sentEmailHost + "/event-detail/" + this.userListOfEventReq[index].eventReqData.eventSlug;
        //  temEmail.subject = 'Your Event Approved';
        temEmail.keys = {
          EVENT: this.eventSingleItem.eventName, USERNAME: this.userListOfEventReq[index].username,
          CORPORATE_USER_NAME: this.eventSingleItem.corpData[0].username,
          EVENT_DATE: this.datePipe.transform(this.eventSingleItem.eventDate, 'MMM d, y'),
          EVENT_ADDRESS: this.eventSingleItem.eventAddress.location ? this.eventSingleItem.eventAddress.location : this.eventSingleItem.eventAddress.formatted_address,
          EVENT_START_TIME: this.datePipe.transform(this.eventSingleItem.startDateTime, 'h:mm a'),
          EVENT_END_TIME: this.datePipe.transform(this.eventSingleItem.endDateTime, 'h:mm a'),
          CONTACT: this.eventSingleItem.onsiteContact,
          PARKING_INST: this.eventSingleItem.parkingInstructions,
          CHECK_IN: this.eventSingleItem.checkIn,
          EVENT_URL: `<a href=${url} >Click Here</a>`,
          COMPANY_NAME:this.eventSingleItem.company, 
          EMAIL: this.eventSingleItem.corpData[0].email,
          PHONE_NUMBER:this.eventSingleItem.phone,
          TIME_ZONE: this.eventSingleItem && this.eventSingleItem.timeZone && this.eventSingleItem.timeZone.label ? this.eventSingleItem.timeZone.label : 'NA'
        };
        this.emailSends(temEmail);
        this.userListOfEventReq[index].eventReqData.status = 1;
        this.userListOfEventReq[index].eventReqStatus = 1;
        this.userListOfEventReq.splice(index, 1);
        this.approved = this.approved + 1;
        if (actionNumber == 0) {
          this.pending = this.pending - 1;
        }
        else if (actionNumber == 2) {
          this.decline = this.decline - 1;
        }

        this.showExhibListIndx = -1

      }, (err) => { });
  }

  emailSends(sendObj: any) {
    this.eventService.sendEmail(sendObj)
      .subscribe(data => {
        // console.log('email send success');
        console.log(data);
      }, (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      });
  }
  declineInvitationOfEvent(ids: string, index: number, oldStatus: number): void {
    const queryObj: any = { status: 2, cancelBeforePaid: 0 };
    const queryObjs: any = {
      filter: {
        where: {
          email: this.userListOfEventReq[index].email
        }
      }
    };
    // console.log(queryObjs);
    // console.log(oldStatus);
    // console.log(this.userListOfEventReq[index].id);
    // return
    let userId = this.userListOfEventReq[index].id;
    this.eventService.updateEventReqById(ids, queryObj)
      .subscribe(data => {
        this.getTotalEventInquiriesAndCount(this.commonStatus.ACTIVE, this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP)
        this.getTotalEventInquiriesAndCount(this.commonStatus.DECLINE, this.systemConstants.DEFAULT_LIMIT,this.systemConstants.DEFAULT_SKIP)

        //email code start 
        let temEmail: any = {};
        temEmail.userId = userId;
        //

        this.unregisteredExhibitorsService.findUnregisteredList(queryObjs)
          .subscribe(invitationList => {
            //corporate-event-declined-unregister-exhibitor
            // console.log(invitationList);

            if (oldStatus == 1) {
              temEmail.slug = 'corporate-cancelled-to-exhibitor';
            }
            else {
              if (invitationList && invitationList.length) {
                temEmail.slug = 'corporate-event-declined-unregister-exhibitor';
              } else {
                temEmail.slug = 'corporate-event-declined';
              }
            }



            //  temEmail.subject = 'Your Event Approved';
            temEmail.keys = {
              EVENT: this.userListOfEventReq[index].eventReqData.eventSlug, USERNAME: this.userListOfEventReq[index].username,
              CORPORATE_USER_NAME: this.eventSingleItem.corpData[0].username,
              EVENT_DATE: this.datePipe.transform(this.eventSingleItem.eventDate, 'MMM d, y'),
              EVENT_ADDRESS: this.eventSingleItem.eventAddress.location ? this.eventSingleItem.eventAddress.location : this.eventSingleItem.eventAddress.formatted_address,
              EVENT_START_TIME: this.datePipe.transform(this.eventSingleItem.startDateTime, 'h:mm a'),
              EVENT_END_TIME: this.datePipe.transform(this.eventSingleItem.endDateTime, 'h:mm a'),
              COMPANY_NAME:this.eventSingleItem.company, 
              TIME_ZONE: this.eventSingleItem && this.eventSingleItem.timeZone && this.eventSingleItem.timeZone.label ? this.eventSingleItem.timeZone.label : 'NA'
            };
            this.emailSends(temEmail);
            this.userListOfEventReq[index].eventReqData.status = 2;
            this.userListOfEventReq[index].eventReqStatus = 2;
            this.userListOfEventReq.splice(index, 1);
            this.decline = this.decline + 1;
            this.approved = this.approved - 1;
            this.showExhibListIndx = -1;

            // this.totalCountCalculation();
          }, (err) => {
          });
        ///email code ends
      }, (err) => { });
  }

  getEventReqById(id: string): void {
    this.eventService.getEventRequestById(id)
      .subscribe(cuData => {
        // Return object index
        const getIndex: number = this.userListOfEventReq.findIndex((x) => String(x.eventReqData.id) === String(id));
        if (getIndex >= 0) {
          this.userListOfEventReq[getIndex].eventReqData = Object.assign({}, cuData);
          this.userListOfEventReq[getIndex].eventReqStatus = cuData.status;
        }
      }, (err) => { });
  }


  removeEventName(): void {
    this.eventInqurieSearchForm.get('fieldName').setValue("");
    this.searchEventEnquiry(this.eventInqStatus, 12, 0);
  }
  removeEventDate(): void {
    this.eventInqurieSearchForm.get('eventDate').setValue("");
    this.searchEventEnquiry(this.eventInqStatus, 12, 0);
  }
  getTotalEventInquiriesAndCount(status:number,limit:number ,skip:number){
    this.currentPage =  this.systemConstants.DEFAULT_CURRENT_PAGE;
    this.enquirySearch=this.systemConstants.DEFAULT_ZERO;
    this.showEventListIndx = -1;
    this.resetSearchForm=true;
    this.getRequestCountsAndTotaInquiries();
    this.callFromTabs(status,limit,skip);
  }

  deleteInvitationOfEvent(ids: string, index: number, oldStatus: number){
    const queryObj: any = { status: 4};
    this.eventService.updateEventReqById(ids, queryObj)
      .subscribe(data => {
        this.notifier.notify('success', 'Deleted Successful.');
        this.showEventListIndx = -1
      this.getListAndCountForInquire()
      })
  
  }

  getListAndCountForInquire(){
    forkJoin([
      this.getRequestCountsAndTotaInquiries(),
      this.getCountsForEventListWithReq(this.eventInqStatus),
      this.getEventListWithReq(this.eventInqStatus, this.eventReqAttributes.limits, 0),
    ]);
  }
}
