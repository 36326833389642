/// <reference types="@types/googlemaps" />
import {
  Component,
  OnInit,
  AfterViewInit,
  NgZone,
  ElementRef,
  ViewChild,
  Injector,
} from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../login/login.service";
import { EventService } from "../event/event.service";
import { ExportService } from "./export.service";
import { UnregisteredExhibitorsService } from "../unregistered-exhibitors/unregistered-exhibitors.service";

import { DatePipe } from "@angular/common";
import { ProfileService } from "../profile/profile.service";
import { AppComponent } from "../app.component";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { forkJoin } from "rxjs";
import { Observable, Subject } from "rxjs";
import { TaxonomyService } from "../taxonomy/taxonomy.service";
import * as _ from "lodash";
import { Globals } from "../globals";
import { NotifierService } from "angular-notifier";
// import { } from 'googlemaps';
import { MapsAPILoader, AgmMap } from "@agm/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { GoogleMapsAPIWrapper } from "@agm/core/services";
import { MouseEvent } from "@agm/core";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { AboutComponent } from "../about/about.component";
import { EventDetail } from "src/app/interface/event-details/event_detail.interface";
import * as translateJson from '../../assets/i18n/en.json'
import { UserRole } from "../constants/user-role.constants";
import * as moment from "moment";
import { CommonStatus } from "../constants/common-status.constants";

@Component({
  selector: "app-event-detail",
  templateUrl: "./event-detail.component.html",
  styleUrls: ["./event-detail.component.scss"],
  providers: [DatePipe],
})
export class EventDetailComponent implements OnInit {
  eventSlug = "";
  tempSlug = "";
  userData: any;
  loginUserRole: any = null;
  tempEventData: any;
  localEvents: boolean = false;
  zoom: number = 16;
  screeningList: any = [];
  indusList: any = [];
  private notifier: NotifierService;
  isRouteVisible = false;
  isRouteDirectionsTextDisplayed: boolean;
  exhibListAttributes: any = {};
  customers: any = [];
  eventPaymentCheckOutList: any = [];
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  eventRequestData: any;
  noMapData = -1;
  latitude: number;
  longitude: number;
  private geoCoder;
  addressForm: FormGroup;
  modalRef: BsModalRef;
  isShow: any;
  destination:string | google.maps.Place | google.maps.LatLng | google.maps.LatLngLiteral;
  origin:string | google.maps.Place | google.maps.LatLng | google.maps.LatLngLiteral;
  userRoleStatus = UserRole;
  localEventDetails:EventDetail[]=[]
  commonStatus = CommonStatus;
  isEditRequestFormEnabled:boolean =false;

  @ViewChild("search")
  public searchElementRef: ElementRef;
  map: AgmMap;
  miles = 10;
  searchOn: number = 0;
  translateJson: typeof translateJson = Object.assign({}, (translateJson as any).default);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taxonomyService: TaxonomyService,
    private appComponent: AppComponent,
    private eventService: EventService,
    private notifierService: NotifierService,
    public globals: Globals,
    private exportService: ExportService,
    private profileService: ProfileService,
    private datePipe: DatePipe,
    private unregisteredExhibitorsService: UnregisteredExhibitorsService,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private fb: FormBuilder,
    private injector: Injector
  ) {
    this.notifier = notifierService;
    this.route.params.subscribe((params) => {
      if (params["slug"]) {
        this.tempSlug =
          this.router.url.split("/").length == 4
            ? this.router.url.split("/")[2]
            : "";
        this.eventSlug = params["slug"];
        this.eventSlug = decodeURIComponent(this.eventSlug);
      } else {
        this.tempSlug =
          this.router.url.split("/").length == 4
            ? this.router.url.split("/")[2]
            : "";
        this.eventSlug = this.router.url.substr(
          this.router.url.lastIndexOf("/") + 1
        );

        this.eventSlug = decodeURIComponent(this.eventSlug);
      }
    });
  }

  ngOnInit() {
    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;

    //   this.loginUserRole = this.appComponent.role;
    //   alert(this.loginUserRole)
    //   if (this.tempSlug == "preview") {
    //     this.getTempEventData(this.eventSlug);
    //   } else {
    //     this.localEvents = true;
    //     if (this.loginUserRole == 10 || this.loginUserRole == 0) {
    //       this.getEventDetailAndExbitorOnly(this.eventSlug);
    //     } else {
    //       this.getLocalEventData(this.eventSlug);
    //     }
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.appComponent.userData;
          this.loginUserRole = this.userData.role;
          if (this.tempSlug == "preview") {
            this.getTempEventData(this.eventSlug);
          } else {
            this.localEvents = true;
            if (this.loginUserRole == 10 || this.loginUserRole == 0) {
              this.getEventDetailAndExbitorOnly(this.eventSlug);
            } else {
              this.getLocalEventData(this.eventSlug);
            }
          }
        } else {
          this.router.navigateByUrl("/");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    // Copy object
    this.exhibListAttributes = Object.assign(
      {},
      this.globals.adminPaginationAttrs
    );
  }

  initAutoComplete(): any {
    ///start map code
    this.addressForm = this.fb.group({
      addressOne: ["", []],
    });

      this.mapsAPILoader.load().then(() => {
        // this.setCurrentLocation();
        this.latitude = 47.751076;
        this.longitude = -120.740135;
        this.zoom = 7;
        this.geoCoder = new google.maps.Geocoder();
        let autocomplete = new google.maps.places.Autocomplete(
          <HTMLInputElement>document.getElementById("eventMap"),
          {}
        );
        google.maps.event.addListener(autocomplete, "place_changed", () => {
          // console.log("yeahh");
          this.ngZone.run(() => {
            this.searchOn = 1;
            // to get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            // to verify result from map
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            // to set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            // this.userData.address.lat ? this.userData.address.lat : 42.266271;
            // this.userData.address.lng ? this.userData.address.lng : -72.6700016 ;
            // this.getAddress(this.latitude, this.longitude);
            this.zoom = 7;
          });
        });
      });


    ///end map code
  }

  /**
   * Render for Google autocomplete
   */
  ngAfterViewInit() {
    // console.log()
    //   this.googleAddress('autocomplete');
  }

  /*
   * Google Address
   */
  googleAddress(id: string) {
    console.log("I am in...");

    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>document.getElementById(id),
      {
        types: ["geocode"],
      }
    );
    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      const place = autocompleteFrom.getPlace();
      if (place.address_components && place.address_components.length) {
        /* if (place.formatted_address) {
              this.signUpForm.patchValue({
                'address': { addressOne: place.formatted_address }
              });
            }
            for (let i = 0; i < place.address_components.length; i++) {

              this.signUpForm.patchValue({
                'address': { lat: place.geometry.location.lat() }
              });

              this.signUpForm.patchValue({
                'address': { lng: place.geometry.location.lng() }
              });
              this.signUpForm.patchValue({
                'location': [place.geometry.location.lng(), place.geometry.location.lat()]
              });

              let addressType = place.address_components[i].types[0];
              if (addressType === 'administrative_area_level_2') {
                this.signUpForm.patchValue({
                  'address': { city: place.address_components[i].long_name }
                });
              }
              if (addressType === 'administrative_area_level_1') {
                this.signUpForm.patchValue({
                  'address': { state: place.address_components[i].long_name }
                });
              }
              if (addressType === 'postal_code') {

                this.signUpForm.patchValue({
                  'address': { zip_code: place.address_components[i].long_name }
                });
              }
            }*/
      }
    });
  }
  // Google autocomplete address for address field
  onSearchAddressForRegion(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>event.srcElement,
      {
        types: ["geocode"],
      }
    );

    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      this.ngZone.run(() => {
        // to get the place result
        let place: google.maps.places.PlaceResult = autocompleteFrom.getPlace();
        // to verify result from map
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // to set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        /// this.getAddress(this.latitude, this.longitude);
        this.zoom = 7;
      });
    });
  }

  getTempEventData(slug: string): any {
    this.getTempEventBySlug(slug).subscribe(
      (res) => {
        if (res && res.length > 0) {
          this.tempEventData = res[0];
          // const newTaxoData = this.tempEventData.exhibitorReq.concat(
          //   this.tempEventData.screenReq
          // );
          const newTaxoData = this.tempEventData.screenReq
          this.getScreeningTaxo(newTaxoData);
        }
      },
      (err) => console.log(err)
    );
  }

  getLocalEventData(slug: string): any {
    this.getLocalEventBySlug(slug).subscribe(
      (res) => {
        if (res && res.length > 0) {
          this.localEventDetails= res
          this.tempEventData = res[0];

          let where = {
            localEventId: res[0].id,
            userid: this.userData.id,
          };
      
          this.eventService.getEventExhibitor(where).subscribe((cuData) => {
            this.eventRequestData = cuData && cuData.length ? cuData[0] : {};
          });
              
          // const newTaxoData = this.tempEventData.exhibitorReq.concat(
          //   this.tempEventData.screenReq
          // );
          const newTaxoData = this.tempEventData.screenReq
          this.getScreeningTaxo(newTaxoData);
        }
      },
      (err) => console.log(err)
    );
  }
  // to get event deatil for preview
  getTempEventBySlug(slug: any): any {
    return new Observable((observer) => {
      let where = {
        filter: {
          where: {
            //  status: 1,
            permalink: slug,
          },
        },
      };
      this.eventService.getTempEventList(where).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => observer.next(err)
      );
    });
  }

  // to get event deatil for preview
  getLocalEventBySlug(slug: any): any {
    return new Observable((observer) => {
      let where = {
        filter: {
          where: {
            permalink: slug,
            exhibitorId: this.userData.id,
            email :this.userData.email
          },
        },
      };
      this.eventService.getLocalEventList(where).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => observer.next(err)
      );
    });
  }

  setEventCheckout(item: any, eventData: any): void {
    item.id = item._id;
    item.eventData = eventData;
    item.eventData.id = eventData._id;
    this.eventPaymentCheckOutList.push(item);
    //  console.log(this.eventPaymentCheckOutList);
    this.router.navigateByUrl("/exhibitor-event-payment");
    this.profileService.setOption(this.eventPaymentCheckOutList);
  }

  // Get screening taxo list
  getScreeningTaxo(ids): void {
    const queryObj = {
      filter: {
        where: {
          status: 1,
          id: { inq: ids },
        },
      },
    };
    //   console.log(queryObj);
    forkJoin([this.taxonomyService.getTaxonomyListNew(queryObj)]).subscribe(
      (results) => {
        const taxoList = results[0] ? results[0] : [];
        _.forEach(taxoList, (data, key) => {
          if (
            data.taxonomyTypeId == this.globals.taxonomyTypesConst.industryId
          ) {
            this.indusList.push(data);
          }
          if (
            data.taxonomyTypeId == this.globals.taxonomyTypesConst.screeningId
          ) {
            this.screeningList.push(data);
          }
        });
      },
      (err) => { }
    );
  }

  // this is used for to save event after preview

  postEventData(tepObj): any {
    try {
      delete tepObj.id;
    } catch (e) { }
    tepObj.status = 0;
    if (tepObj.media.length == 0) {
      const imageName = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      const randomImgName =
        imageName[Math.floor(Math.random() * imageName.length)];
      tepObj.media[0] = randomImgName + ".png";
    }
    if (this.loginUserRole === 0) {
      tepObj.status = 1;
    }
    tepObj.otherExhibitor = tepObj.otherExhibitor ? tepObj.otherExhibitor.split(',') :'';
    this.eventService.addLocalEvent(tepObj).subscribe(
      (eventData: EventDetail) => {
        let otherExhibitor = tepObj.otherExhibitor
        if(otherExhibitor && otherExhibitor.length){
          this.eventService.sendInviteEmail(eventData,{email: otherExhibitor})    
        }
        this.notifier.notify("success", "Event submitted successfully");
        this.router.navigate(["event"]);
      },
      (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify("error", err.error.error.message);
        }
      }
    );
  }

  // this is used for to draft event after preview

  saveDaraftEvent(tepObj): any {
    try {
      delete tepObj.id;
    } catch (e) { }
    tepObj.status = 3;
    this.eventService.addLocalEvent(tepObj).subscribe(
      (data) => {
        this.notifier.notify("success", "Event submitted successfully");
        this.router.navigate(["event"]);
      },
      (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify("error", err.error.error.message);
        }
      }
    );
  }

  displayTextDirection() {
    this.isRouteVisible = false
    this.isRouteDirectionsTextDisplayed = true;
    this.origin = { lat: this.latitude, lng: this.longitude },
    this.destination = {
      lat: this.tempEventData.eventAddress.lat,
      lng: this.tempEventData.eventAddress.lng,
    }
    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer();
    //
    directionsDisplay.setPanel(null);
    if (document.getElementById("right-panel") != null) {
    document.getElementById("right-panel").innerHTML = "<div></div>";
    }

    directionsDisplay.setPanel(document.getElementById("right-panel"));
    this.calculateAndDisplayRoutes(directionsService, directionsDisplay)
  }

  calculateAndDisplayRoutes(directionsService, directionsDisplay) {
    let userLatitude = this.latitude;
    let userLongitude = this.longitude;
    directionsService.route(
      {
        origin: { lat: userLatitude, lng: userLongitude },
        //   optimizeWaypoints: true,
        destination: {
          lat: this.tempEventData.eventAddress.lat,
          lng: this.tempEventData.eventAddress.lng,
        },
        //  optimizeWaypoints: true,
        travelMode: "DRIVING",
      },
      function (response, status) {
        if (status === "OK") {
          directionsDisplay.setDirections(response);
        } else {
          window.alert(
            "Unable to create path of driving direction.Please fill proper address"
          );

          console.log("Directions request failed due to " + status);
        }
      }
    );

    this.searchOn = 0;
  }



  /*Google Direction*/
  displayRoute() {
    this.isRouteVisible = true;
    this.isRouteDirectionsTextDisplayed = false;
    this.origin = { lat: this.latitude, lng: this.longitude },
    this.destination = {
      lat: this.tempEventData.eventAddress.lat,
      lng: this.tempEventData.eventAddress.lng,
    }
    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer();
    directionsDisplay.setPanel(null);

    if (document.getElementById("right-panel") != null) {
      document.getElementById("right-panel").innerHTML = "<div></div>";
    }
    directionsDisplay.setPanel(document.getElementById("right-panel"));
  } 

  
  // Get Exhibitor list for Event
  getExhibitorList(limitNum: number, skipNum: number): void {
    const queryObj: any = { filter: { where: { eventSlug: this.eventSlug } } };
    this.eventService.getEventExibitorList(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          this.exhibListAttributes.pageItems = res;
          // console.log("====>");
          // console.log(this.exhibListAttributes.pageItems);
          // console.log("<<<<====>");
          //value.hasOwnProperty('address.formatted_address')

          let arrays = res;
          this.customers = [];
          for (var value of arrays) {
            const firstName: string = value.eventReqData.hasOwnProperty(
              "firstName"
            )
              ? value.eventReqData.firstName
              : "";
            const lastName: string = value.eventReqData.hasOwnProperty(
              "lastName"
            )
              ? value.eventReqData.lastName
              : "";
            const name: string = firstName + lastName;
            const address: string = value.hasOwnProperty(
              "address.formatted_address"
            )
              ? value.address.formatted_address
              : "";
            const phone: string = value.eventReqData.hasOwnProperty("phone")
              ? value.eventReqData.phone
              : "";
            const eventType: string = value.eventReqData.hasOwnProperty(
              "eventType"
            )
              ? value.eventReqData.eventType
              : "";
            const email: string = value.eventReqData.hasOwnProperty("email")
              ? value.eventReqData.email
              : "";
            const contactName: string = value.eventReqData.hasOwnProperty(
              "contactName"
            )
              ? value.eventReqData.contactName
              : "";
            const electricity: string = value.eventReqData.hasOwnProperty(
              "electricity"
            )
              ? value.eventReqData.electricity
              : "";
            const numberOfChairs: string = value.eventReqData.hasOwnProperty(
              "numberOfChairs"
            )
              ? value.eventReqData.numberOfChairs
              : "0";
            const numberOfTable: string = value.eventReqData.hasOwnProperty(
              "numberOfTable"
            )
              ? value.eventReqData.numberOfTable
              : "0";
            const paymentSpecified: string =
              value.eventReqData.hasOwnProperty("paymentSpecified") &&
                value.eventReqData.paymentSpecified == 1
                ? "Done"
                : "Not Done";
            var attendee = "";
            let eventStatus: string;
            if (
              value.eventReqData.attendees &&
              value.eventReqData.attendees.length
            ) {
              {
                for (var i = 0; i < value.eventReqData.attendees.length; i++) {
                  var valueAttendee = value.eventReqData.attendees[i];
                  attendee +=
                    "Name: " +
                    valueAttendee.name +
                    " Email: " +
                    valueAttendee.email;
                  if (i < value.eventReqData.attendees.length - 1) {
                    attendee += ",";
                  }
                }
            }
            }
            // const raffle:string = value.eventReqData.hasOwnProperty(
            //   "raffleItem"
            // )
            const additionalNeeds:string = value.eventReqData.hasOwnProperty(
              "additionalNeeds"
            )

            switch (value.eventReqStatus) {
              case 0:
                eventStatus = "Pending";
                break;
              case 1:
                eventStatus = "Approved";
                break;
              case 2:
                eventStatus = "Declined";
                break;
              default:
                eventStatus = "Pending";
            }

            this.customers.push({
              "Exhibitor Name": name,
              "Phone Number": phone,
              Address: address,
              EventType: eventType,
              Email: email,
              Electricity: electricity,
              ContactName: contactName,
              Attendees: attendee,
              "Number Of Chairs": numberOfChairs,
              "Number Of Table": numberOfTable,
              "Payment Specified": paymentSpecified,
              "Request Status": eventStatus,
              // "Raffle":raffle,
              "Additional Needs":additionalNeeds,
            });
          }
        }
      },
      (err) => { }
    );
  }
  export() {
    this.exportService.exportExcel(this.customers, "customers");
  }
  exportExhibitor() {
    this.exportService.exportExcel(this.customers, "customers");
  }

  acceptInvitationOfEvent(id: string, oldStatus: number): void {
    const queryObj: any = { status: 1 };
    this.eventService.updateEventReqById(id, queryObj).subscribe(
      (data) => {
        this.getEventReqById(id, oldStatus);
      },
      (err) => { }
    );
  }

  declineInvitationOfEvent(id: string, oldStatus: number): void {
    const queryObj: any = { status: 2 };
    this.eventService.updateEventReqById(id, queryObj).subscribe(
      (data) => {
        this.getEventReqById(id, oldStatus);
      },
      (err) => { }
    );
  }

  getEventReqById(id: string, oldStatus: number): void {
    this.eventService.getEventRequestById(id).subscribe(
      (cuData) => {
        // Return object index
        const getIndex: number = this.exhibListAttributes.pageItems.findIndex(
          (x) => String(x.eventReqData.id) === String(id)
        );
        if (getIndex >= 0) {
          this.exhibListAttributes.pageItems[
            getIndex
          ].eventReqData = Object.assign({}, cuData);
          this.exhibListAttributes.pageItems[getIndex].eventReqStatus =
            cuData.status;
          //start email code

          const sts = cuData.status;
          if (sts === 1) {
            let temEmail: any = {};
            temEmail.userId = cuData.userid;
            temEmail.slug = "corporate-event-accept";
            let url =
              environment.sentEmailHost +
              "/event-detail/" +
              this.tempEventData.permalink;

            temEmail.keys = {
              EVENT: this.tempEventData.eventName,
              USERNAME: this.exhibListAttributes.pageItems[getIndex].username,
              CORPORATE_USER_NAME: this.tempEventData.corpData[0].username,
              EVENT_DATE: this.datePipe.transform(
                this.tempEventData.eventDate,
                "MMM d, y"
              ),
              EVENT_ADDRESS: this.tempEventData.eventAddress.location
                ? this.tempEventData.eventAddress.location
                : this.tempEventData.eventAddress.formatted_address,
              EVENT_START_TIME: this.datePipe.transform(
                this.tempEventData.startDateTime,
                "h:mm a"
              ),
              EVENT_END_TIME: this.datePipe.transform(
                this.tempEventData.endDateTime,
                "h:mm a"
              ),
              CONTACT:  this.tempEventData.corpData[0].data.contact,
              PARKING_INST: this.tempEventData.parkingInstructions,
              CHECK_IN: this.tempEventData.checkIn,
              EVENT_URL: `<a href=${url} >Click Here</a>`,
              COMPANY_NAME:this.tempEventData.company, 
              EMAIL: this.tempEventData.corpData[0].email,
              PHONE_NUMBER:this.tempEventData.phone
            };

            this.emailSends(temEmail);
          } else if (sts === 2) {
            let temEmail: any = {};
            temEmail.userId = cuData.userid;
            // temEmail.slug = 'event-has-been-declined-template';
            // temEmail.subject = 'Your Event Declined';
            //temEmail.keys = { USERNAME: this.exhibListAttributes.pageItems[getIndex].username, eventName: this.exhibListAttributes.pageItems[getIndex].eventReqData.eventSlug };
            //   this.emailSends(temEmail);

            const queryObjs: any = {
              filter: {
                where: {
                  email: cuData.email,
                },
              },
            };

            this.unregisteredExhibitorsService
              .findUnregisteredList(queryObjs)
              .subscribe((invitationList) => {
                if (oldStatus == 1) {
                  temEmail.slug = "corporate-cancelled-to-exhibitor";
                } else {
                  if (invitationList && invitationList.length) {
                    temEmail.slug =
                      "corporate-event-declined-unregister-exhibitor";
                  } else {
                    temEmail.slug = "corporate-event-declined";
                  }
                }

                //  temEmail.subject = 'Your Event Approved';
                temEmail.keys = {
                  EVENT: this.tempEventData.eventName,
                  USERNAME: this.exhibListAttributes.pageItems[getIndex]
                    .username,
                  CORPORATE_USER_NAME: this.userData.username,
                  EVENT_DATE: this.datePipe.transform(
                    this.tempEventData.eventDate,
                    "MMM d, y"
                  ),
                  EVENT_ADDRESS: this.tempEventData.eventAddress.location
                    ? this.tempEventData.eventAddress.location
                    : this.tempEventData.eventAddress.formatted_address,
                  EVENT_START_TIME: this.datePipe.transform(
                    this.tempEventData.startDateTime,
                    "h:mm a"
                  ),
                  EVENT_END_TIME: this.datePipe.transform(
                    this.tempEventData.endDateTime,
                    "h:mm a"
                  ),
                  COMPANY_NAME:this.tempEventData.company,
                };
                this.emailSends(temEmail);
              });
          }
          //event-has-been-declined-template

          // end email code
        }
      },
      (err) => { }
    );
  }

  emailSends(sendObj: any) {
    this.eventService.sendEmail(sendObj).subscribe(
      (data) => {
        console.log("email send success");
        //  console.log(data);
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify("error", err.error.error.message);
        }
      }
    );
  }

  // to count of events
  getExhibitorReqCounts(): void {
    const queryObj: any = {
      where: {
        eventSlug: this.eventSlug,
      },
    };
    this.eventService.findEventReqCount(queryObj).subscribe(
      (data) => {
        this.exhibListAttributes.count =  data ? data : 0;
      },
      (err) => { }
    );
  }

  // Get Event Detail and Exhibitor List
  getEventDetailAndExbitorOnly(eventSlg: string): void {
    forkJoin([
      this.getExhibitorList(
        this.exhibListAttributes.limits,
        this.exhibListAttributes.skips
      ),
      this.getLocalEventData(eventSlg),
      this.getExhibitorReqCounts(),
    ]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.getExhibitorList(this.exhibListAttributes.limits, startItem);
  }
}
