import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-html',
  templateUrl: './manage-html.component.html',
  styleUrls: ['./manage-html.component.scss']
})
export class ManageHtmlComponent implements OnInit {
  templateData: any = [];
  constructor() { }

  ngOnInit() {
  }

}
