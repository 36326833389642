import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { EventService } from '../event/event.service';
import { ReviewService } from '../review-events/review.service';
import { AppComponent } from '../app.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { forkJoin } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import * as _ from 'lodash';
import { Globals } from '../globals';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { GetRouterLinkService } from '../services/getrouter-link.service';

@Component({
  selector: 'app-corporate-review-event',
  templateUrl: './corporate-review-event.component.html',
  styleUrls: ['./corporate-review-event.component.scss']
})
export class CorporateReviewEventComponent implements OnInit {

  submits = {
    currentStep: 'step1',
    step1: {
      visibility: true,
      submitted: false
    },
    step2: {
      visibility: false,
      submitted: false
    },
    step3: {
      visibility: false,
      submitted: false
    }
  };
  private notifier: NotifierService;
  eventSlug = '';
  tempSlug = '';
  userData: any;
  loginUserRole: any = null;
  eventData: any;
  eventReviewForm: FormGroup;
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  submitSuccess = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taxonomyService: TaxonomyService,
    private appComponent: AppComponent,
    private eventService: EventService,
    public reviewService: ReviewService,
    private notifierService: NotifierService,
    private injector: Injector,
    private fb: FormBuilder,
    public globals: Globals) {
    this.notifier = notifierService;
    this.route.params.subscribe((params) => {
      if (params['slug']) {
        this.tempSlug = this.router.url.split("/").length == 4 ? this.router.url.split("/")[2] : '';
        this.eventSlug = params['slug'];

      } else {
        this.tempSlug = this.router.url.split("/").length == 4 ? this.router.url.split("/")[2] : '';
        this.eventSlug = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);
      }
    });
  }

  ngOnInit() {

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   this.formInit();
    //   this.getLocalEventData(this.eventSlug);
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        this.formInit();
        this.getLocalEventData(this.eventSlug);
      } else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });
  }


  formInit(): void {
    this.eventReviewForm = this.fb.group({
      companyName: [this.userData.data.company_name, [Validators.required]],
      contactName: [this.userData.username, [Validators.required]],
      contactEmail: [this.userData.email, [Validators.required]],
      exhibReview: this.fb.group({
        exhibArrivedOnTime: ['', Validators.required],
        exhibProvideUsefulInfo: ['', Validators.required],
        exhibProfessionalManner: ['', Validators.required],
        exhibInteractedEmp: ['', Validators.required],
        exhibPracticeHard: ['', Validators.required],
        aboutExhibForUncomfort: '',
        additionalFeedback: '',
      }),
      hfpReview: this.fb.group({
        happyWithSelection: ['', Validators.required],
        exhibProperlyRespond: ['', Validators.required],
        hfpKeptUpdate: ['', Validators.required],
        hfpProvideAllService: ['', Validators.required],
        anyMissingHfpService: ''
      }),
      onSiteReview: this.fb.group({
        hfpStaffAssigned: ['', Validators.required],
        staffGreetAndHelp: ['', Validators.required],
        staffMemberProfessional: ['', Validators.required],
        anyUnprofessionalFeed: '',
        staffFollowRules: ['', Validators.required],
        staffNotFollowRules: '',
        additionalFeedback: '',
      }),
      overallSummary: this.fb.group({
        evaluationOutcome: ['', Validators.required],
        evaluationOutcomeMiss: '',
        hfpEvalService: this.fb.group({
          couldNotDone: false,
          veryHelpFul: false,
          helpFulBut: false,
          notQuitHelpFul: false,
          helpOut: false,
        }),
        hfpServiceAgain: this.fb.group({
          yes: false,
          yesFewModifi: false,
          convinceMe: false,
          no: false
        }),
        additionalFeedback: '',
      }),
      status: [1, [Validators.required]]
    });
  }

  get overallSumary() {
    const overAllSumry = this.eventReviewForm.get('overallSummary') as FormGroup;
    return overAllSumry.controls;
  }

  get exhibReviews() {
    return this.eventReviewForm.get('exhibReview') as FormGroup;
  }

  get hfpReviewsGroup() {
    return this.eventReviewForm.get('hfpReview') as FormGroup;
  }
  get onSiteReviewGroup() {
    return this.eventReviewForm.get('onSiteReview') as FormGroup;
  }
  get overallSummaryGroup() {
    return this.eventReviewForm.get('overallSummary') as FormGroup;
  }

  getLocalEventData(slug: string): any {
    console.log(slug);
    this.getLocalEventBySlug(slug).subscribe((res) => {
      this.eventData = res && res.length > 0 ? res[0] : {};

    }, err => console.log(err));
  }

  /**
   * This function is to get eventDetail by passing event slug.
   * @param slug
   */
  getLocalEventBySlug(slug: any): any {
    return new Observable((observer) => {
      let where = { filter: { where: { permalink: slug, status: 1 } } };
      this.eventService.getLocalEventList(where).subscribe((res) => {
        observer.next(res);
      }, (err) => observer.next(err));
    });
  }



  /**
   * Function for previous setps of from
   */
  previous(key) {
    this.submits['currentStep'] = key;
  }

  /**
   * This function will use for check box evlauation summary.
   * @param option any
   * @param event any
   */
  onCheckboxChange(option, event) {
    if (event.target.checked) {
      this.overallSumary.hfpEvalService.get(option.control).setValue(true);
    } else {
      this.overallSumary.hfpEvalService.get(option.control).setValue(false);
    }
  }

  /**
   * This function will use for check box hfp service.
   * @param option any
   * @param event any
   */

  onCheckboxChangeService(option, event) {
    if (event.target.checked) {
      this.overallSumary.hfpServiceAgain.get(option.control).setValue(true);
    } else {
      this.overallSumary.hfpServiceAgain.get(option.control).setValue(false);
    }
  }

  /*
   * Function for to move next step and check validation
   */
  next(key, valid, nextStep) {

    this.submits['currentStep'] = key;
    this.submits[key]['submitted'] = true;
    if (nextStep == 'step2' && !valid) {
      this.notifier.notify('error', 'Please attempt mandatory fields for next step.');
    }
    if (nextStep == 'step3' && !valid) {
      this.notifier.notify('error', 'Please attempt mandatory fields for next step.');
    }
    if (nextStep == 'submit' && !valid) {
      this.notifier.notify('error', 'Please give rating before submit.');
    }

    if (valid) {
      this.submits['currentStep'] = nextStep;
      if (nextStep == 'submit') {
        if (this.eventReviewForm.valid) {
          var obj = this.eventReviewForm.value;

          obj.eventName = this.eventData.eventName;
          obj.eventDate = this.eventData.eventDate;
          obj.media = this.eventData.media;
          obj.eventSlug = this.eventData.permalink;
          obj.eventLocation = this.eventData.eventAddress;
          obj.eventId = this.eventData.id;
          obj.memberId = this.userData.id;
          this.addEventReviewForCorpo(obj);
          console.log(this.eventReviewForm.value);
        } else {
          this.submits['currentStep'] = 'step3';
          nextStep = 'step3';
          this.notifier.notify('error', 'Please fill all mandatory fields.');
        }
      }
    }
  }


  addEventReviewForCorpo(obj: any) {
    this.reviewService.addReviewForCorporate(obj).subscribe(data => {
      this.notifier.notify('success', 'Event Reviewed Successful!');
      this.submitSuccess = true;
    }, (err) => {
      if (err && err.error && err.error.error && err.error.error.message) {
        this.notifier.notify('error', err.error.error.message);
      }
    });
  }


}
