<div class="sub-tab-hd" *ngIf="templateData && templateData.length">
  <div class="hfpbox-row-fold" *ngFor="let data of templateData; let idx = index">
    <div class="hfpbox-row-fold-top clearfix">

      <div class="hfpbox-row-fold-hd">
        <h1>{{data.title ? data.title : 'Container' + (idx + 1)}}</h1>
        <span></span>
      </div>
      <div class="hfpbox-row-fold-btn">
        <ul>
          <li><a href="javascript:void(0)" id="edit1" title="Edit" (click)="editTempData(idx);isIndex=idx">Edit</a></li>
          <li><a href="javascript:void(0)" title="Trash" (click)="deleteTempData(idx);">Delete</a></li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
    <!-- use for Form Template at particular condtion-->
    <div *ngIf="isIndex==idx; then foldTypeTemplate"></div>
  </div>
</div>

<div *ngIf="isShowTemp; then foldTypeTemplate"></div>

<ng-template #foldTypeTemplate>
<div class="add-sub-form">
    <form [formGroup]="templateForm" (ngSubmit)="isIndex!= -1 ? updateData(isIndex) : addData()">
  <div class="hfp-input-div">
    <label for="title">Title&nbsp;<span class="span_star">*</span></label>
    <input type="text" name="title" placeholder="Home page article fold"   formControlName="title" minlength="2" maxlength="20" required>
  </div>
  <div *ngIf="sf.title.invalid && (sf.title.dirty || fAttributes.submitted)" class="custom_error">
    <div *ngIf="sf.title.errors.required">!!Title is required !! </div>
    <div *ngIf="sf.title.errors.minlength">Title should have two Characters
    </div>
    <div *ngIf="sf.title.errors.maxlength">Title is too Large !!!</div>
  </div> 


    
  <div class="hfp-input-div" style="width: 100%;">
    <label>Description</label>
    <div [froalaEditor] formControlName ="description">Hello, Froala!</div>
  </div>

  <div class="hfp-input-div">
    <label>Image</label>
   <div class="browse_image_set">
    <div class="browse-image-box">

      <input  type="file" formControlName="image"  ng2FileSelect [uploader]="uploader" (change)="onImageSelect($event)" >
      <div class="add-logo-icon"><i class="fa fa-image" aria-hidden="true"></i></div>
      <span>Browse Image</span> </div>

    <div class="browse-imgplaceholder">
      <img  src="imageSrc ? imageSrc:(imageCdn + 'w_300,h_300/' + ((imageBaseUrl + '/fold/' + data.image) | encodeUriComponent))" alt="img"  />
    </div>

   </div>

  </div>

  <div class="buttons">
    <button class="btn btn-primary" type="submit">Save</button>
    <button class="btn btn-default" (click)=" this.isIndex = -1;">Cancel</button>
  </div>
</form>
</div>
</ng-template>
<div class="add-fold-link">
  <a href="javascript:void(0)" (click)="addMore()" class="text_link ng-binding" translate="ADD_MORE">Add More</a>
</div>
