import { Component, Injector, OnInit } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl
} from "@angular/forms";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { FoldService } from "./fold.service";
import { environment } from "../../environments/environment";
import { FoldTypeService } from "../fold-type/fold-type.service";
import { forkJoin } from "rxjs";
// Import utility method class
import { Utility } from "../utility";
// Handle the global property
import { Globals } from "../globals";
import { DataService } from "../data.service";
import { AuthService } from "../services/auth.service";
import { AppComponent } from "../app.component";
import { Router } from "@angular/router";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { ManageImage } from "../interface/manage-image/manage-image";
import * as TranslatorJson from "./../../assets/i18n/en.json";
@Component({
  selector: "app-fold",
  templateUrl: "./fold.component.html",
  styleUrls: ["./fold.component.scss"],
})
export class FoldComponent implements OnInit {
  // Define the calss property
  foldForm: FormGroup;
  searchFForm: FormGroup;
  fAttributes: any = {};
  foldTypeData: any = [];
  foldType = "";
  foldTemplateData: any = [];
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  addFolds = {
    foldName: "",
    foldTagLine: "",
    foldTypeId: "",
    widgetId: [],
    image: [],
    auth: 0,
    startDate: "",
    endDate: "",
    data: [],
    help: { text: "", isShow: false },
    order: [],
    status: "1",
  };
  templateData: any = [];
  clientSchema: any = {};
  isShow: boolean = false;
  userData: any;
  userSlug: string = "";
  userRole: any = null;
  allowEditors = ["ManageImageComponent"];
  isManageImageComponent = false;
  foldFormData: ManageImage;
  translatorJson: typeof TranslatorJson;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    public globals: Globals,
    private foldService: FoldService,
    private foldTypeService: FoldTypeService,
    private appComponent: AppComponent,
    private router: Router,
    private utility: Utility,
    private injector: Injector,
    private authService: AuthService
  ) {
    /***Used for implement fold logic */
    // this.dataService.foldTempData.subscribe((data: any) => {
    //   this.foldTemplateData = data && data.length ? data : [];
    // });

    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }
  ngOnInit() {
    // Fold add form
    this.foldForm = this.fb.group({
      foldName: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(120),
        ],
      ],
      slug: ["", []],
      foldTagLine: [""],
      foldTypeId: ["", [Validators.required]],
      widgetId: ["", []],
      auth: ["", [Validators.required]],
      accessLevel: ['', Validators.required],
      image: ["", []],
      order: ["", []],
      data: ["", []],
      help: ["", []],
      status: ["", Validators.required],
    });

    // Fold search form
    this.searchFForm = this.fb.group({
      searchFold: ["", []],
    });

    // Copy object
    this.fAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.getFoldListCount();
    //   // Load data

    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          this.userData = this.appComponent.userData;
          if (this.userData.role === 0) {
            // Load data
            this.getFoldListCount();
          } else {
            this.router.navigateByUrl("/");
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // convenience getter for easy access to from fileds from fold add form
  get f() {
    return this.foldForm.controls;
  }

  // convenience getter for easy access to form fileds form search fold form
  get sf() {
    return this.searchFForm.controls;
  }

  // Manage show/hide fold form
  toggleForm(): void {
    if (!(this.fAttributes.isIndex >= 0)) {
      this.fAttributes.isShow = !this.fAttributes.isShow;
    }
    this.fAttributes.submitted = false;
    this.foldForm.reset();
    this.fAttributes.isIndex = -1;
    this.fAttributes.isActiveIdx = -1;
    this.fAttributes.itemArray = [];
    this.fAttributes.statusSelected = 1;
    // Set default  radio button value
    this.foldForm.get("status").setValue(this.fAttributes.statusSelected);
    this.isShow = !this.isShow;
    this.addFolds.foldTypeId = "";
  }

  // Add fold
  addFold(): void {
    this.foldForm.get("data").setValue(this.foldTemplateData);
    this.foldForm.get("help").setValue({ text: "Hello Help", isShow: false });
    const slugName = this.generateSlug(this.foldForm.value.foldName);
    this.foldForm.get("slug").setValue(slugName);
    this.foldForm.get("foldTagLine").setValue(slugName);
    // this.foldForm.get('startDate').setValue('');
    //this.foldForm.get('endDate').setValue('');
    this.foldForm.get("image").setValue([]);
    this.foldForm.get("order").setValue([]);
    // Check fold validation and save data in db
    if (this.foldForm.valid) {
      this.getFoldListBySlug();
    } else {
      this.fAttributes.submitted = true;
    }
  }

  saveFoldData(): void {
    if (this.foldForm.valid) {
      this.foldForm.value.status = Number(this.foldForm.value.status);
      this.foldForm.value.auth = Number(this.foldForm.value.auth);
      this.foldService.addFold(this.foldForm.value).subscribe(
        (data) => {
          this.toggleForm();
          // Push new object on zero index
          this.fAttributes.pageItems.splice(0, 0, data);
          this.fAttributes.count += 1;
          this.foldForm.reset();
        },
        (err) => { }
      );
    } else {
      this.fAttributes.submitted = true;
    }
  }

  // Get single fold according to index
  getfoldForEdit(index: number): void {
    this.toggleForm();
    this.fAttributes.isIndex = this.utility.getIndex(
      index,
      this.fAttributes.pageItems.length
    );
    this.fAttributes.isShow = false;
    this.fAttributes.isActiveIdx = index;
    this.fAttributes.itemArray = this.fAttributes.pageItems[index];
    this.foldForm.patchValue(this.fAttributes.itemArray);
    this.fAttributes.statusSelected = this.fAttributes.itemArray.status;
    this.templateData = JSON.stringify(this.fAttributes.itemArray);
    const foldTypeId = this.fAttributes.itemArray.foldTypeId;
    this.addFolds.foldTypeId = foldTypeId;
    const getIndex: number = this.foldTypeData.findIndex(
      (x) => String(x.id) === String(foldTypeId)
    );
    if (getIndex >= 0) {
      const foldTypeData = this.foldTypeData[getIndex];
      this.foldType = foldTypeData.dataComponent;
      if (this.allowEditors.includes(this.foldType)) {
        this.isManageImageComponent = true;
      }
    }
    this.setFoldData();
    this.dataService.sendFoldData(this.templateData);
  }

  // Update fold
  updateFold(id: string): void {
    if (this.foldTemplateData && this.foldTemplateData.length > 0) {
      this.foldForm.get("data").setValue(this.foldTemplateData);
    }
    if (this.foldForm.valid) {
      this.foldForm.value.status = Number(this.foldForm.value.status);
      this.foldForm.value.auth = Number(this.foldForm.value.auth);
      this.foldService.updateFold(id, this.foldForm.value).subscribe(
        (data) => {
          this.getFold(id);
          this.fAttributes.isIndex = -1;
          this.fAttributes.isActiveIdx = -1;
          this.foldForm.reset();
        },
        (err) => { }
      );
    } else {
      console.log(this.foldForm);
      this.fAttributes.submitted = true;
    }
  }

  // Search fold according to string
  searchFold(): void {
    if (this.searchFForm.valid) {
      this.getFoldListCount();
    }
  }
  // Update fold
  deleteFold(index: number): void {
    if (index >= 0) {
      this.foldForm.patchValue(this.fAttributes.pageItems[index]);
      this.foldForm.value.status = Number(2);
      this.foldService
        .updateFold(this.fAttributes.pageItems[index].id, this.foldForm.value)
        .subscribe(
          (data) => {
            // remove item from array
            this.fAttributes.pageItems.splice(index, 1);
            this.fAttributes.count -= 1;
          },
          (err) => { }
        );
    }
  }

  // Get single fold
  getFold(id: string): any {
    this.foldService.getFoldById(id).subscribe(
      (cuData) => {
        // Return object index
        const getIndex: number = this.fAttributes.pageItems.findIndex(
          (x) => String(x.id) === String(id)
        );
        if (getIndex >= 0) {
          this.fAttributes.pageItems[getIndex] = Object.assign({}, cuData);
        }
      },
      (err) => { }
    );
  }

  // Get fold total count
  getFoldCount(): void {
    const queryObj: any = {
      where: {
        status: { inq: [1, 0] },
      },
    };
    // This statement for serch fold count
    if (
      this.searchFForm &&
      this.searchFForm.value &&
      this.searchFForm.value.searchFold
    ) {
      queryObj.where.foldName = {
        regexp: "m.*" + this.searchFForm.value.searchFold + "/mi",
      };
    }

    this.foldService.getFoldCount(queryObj).subscribe(
      (data) => {
        this.fAttributes.count = data && data.count ? data.count : 0;
      },
      (err) => { }
    );
  }

  // Get fold total list
  getFoldList(limitNum: number, skipNum: number): void {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        limit: limitNum,
        skip: skipNum,
      },
    };
    // This statement for serch fold list
    if (
      this.searchFForm &&
      this.searchFForm.value &&
      this.searchFForm.value.searchFold
    ) {
      queryObj.filter.where.foldName = {
        regexp: "m.*" + this.searchFForm.value.searchFold + "/mi",
      };
    }

    this.foldService.getFoldList(queryObj).subscribe(
      (data) => {
        this.fAttributes.pageItems = data && data.length ? data : [];
      },
      (err) => { }
    );
  }

  // Get fold list for slug
  getFoldListBySlug(): void {
    const queryObj: any = { filter: { fields: { slug: true } } };
    this.foldService.getFoldList(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          var i = 0,
            j = 1,
            len = res.length;
          while (i < len) {
            if (res[i].slug === this.foldForm.value.slug) {
              this.foldForm.value.slug =
                this.foldForm.value.slug.replace(/\d+$/, "") + j;
              this.foldForm.get("slug").setValue(this.foldForm.value.slug);
              i = -1;
              j++;
            }
            i++;
          }
        }
        this.saveFoldData();
      },
      (err) => { }
    );
  }

  // Get fold type list
  getFoldTypeList(): void {
    const queryObj: any = {
      filter: { where: { status: 1 }, order: "foldTypeName ASC" },
    };

    this.foldTypeService.getFoldTypeList(queryObj).subscribe(
      (data) => {
        this.foldTypeData = data && data.length ? data : [];
      },
      (err) => { }
    );
  }

  // to get fold template
  getFoldTypeTemplate(): any {
    if (this.foldForm.value.foldTypeId) {
      this.addFolds.data = [];
      const getIndex: number = this.foldTypeData.findIndex(
        (x) => String(x.id) === String(this.foldForm.value.foldTypeId)
      );
      if (getIndex >= 0) {
        const foldTypeData = this.foldTypeData[getIndex];
        this.foldType = foldTypeData.dataComponent;
        this.addFolds.foldTypeId = foldTypeData.id;
        this.addFolds.data.push(foldTypeData.clientSchema[0]);
        this.templateData = JSON.stringify(this.addFolds);
      }
    }
  }

  // This is used to generate slug according to fold name
  generateSlug(str: string): string {
    return str
      .toLowerCase()
      .replace(/[^a-z]/gi, "-")
      .replace(/-+/g, "-")
      .replace(/^-|-$/g, "");
  }

  // Get told list/count
  getFoldListCount(): void {
    forkJoin([
      this.getFoldCount(),
      this.getFoldList(this.fAttributes.limits, this.fAttributes.skips),
      this.getFoldTypeList(),
    ]);
  }

  // This method is used for pagination
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.fAttributes.isIndex = -1;
    this.fAttributes.isActiveIdx= -1
    this.getFoldList(this.fAttributes.limits, startItem);
  }
  getFoldData(data: ManageImage) {
    this.foldTemplateData = [data]
  }

  private setFoldData() {
    this.foldFormData = {} as ManageImage;
    if (this.foldForm.get('data') && this.foldForm.get('data').value && this.foldForm.get('data').value.length > 0) {
      this.foldFormData = this.foldForm.get('data').value[0];
    }
  }
}

// const queryObj: any = {
//   filter: {
//     where: {
//       status: 1,
//       taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId
//     }
//   }
// };

