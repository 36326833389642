import { Component, OnInit, ElementRef, ViewChild, Injector } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { Globals } from "../globals";
import { LoginService } from "../login/login.service";
import { NotifierService } from "angular-notifier";
// import {} from "googlemaps"; //Can't understand where it is used to be checked in future
import { MapsAPILoader } from "@agm/core";
import { FileUploader } from "ng2-file-upload";
import { environment } from "../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { ReCaptcha2Component } from "ngx-captcha";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  userData: any;

  @ViewChild("captchaElem") captchaElem: ReCaptcha2Component;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  public authorizeStep = "";
  paymentSubmitted = false;
  userBillingSubmitted = false;
  finalformgSubmitted = false;

  userInfoForm: FormGroup;
  step3form: FormGroup;
  region: FormArray;

  signUpForm: FormGroup;

  imageProfile: FormGroup;
  addressProfile: FormGroup;
  registerData: any = {};

  avatars: any = [];

  miles: number = 20;
  location: any = [];
  private notifier: NotifierService;
  isExibitor = false;
  industriesList: any = [];
  imgUrl: string;

  fAttributes: any = {};
  public uploader: FileUploader = new FileUploader({
    url:
      environment.apiUrl + "/containers/upload?folderName=users&fileType=image",
  });
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private loginService: LoginService,
    notifierService: NotifierService,
    public mapsApiLoader: MapsAPILoader,
    private route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private appComponent: AppComponent,
  ) {
    this.notifier = notifierService;
    // this is used to get all list of avatar already in folder
    for (let i = 1; i < 91; i++) {
      this.avatars.push("avatar-" + i + ".png");
    }
  }

  ngOnInit() {
    //new

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.signupFormInitialization();
    //   this.fAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    // } else {
    //   this.router.navigateByUrl("/");
    // }
    this.signupFormInitialization();
    this.fAttributes = Object.assign({}, this.globals.adminPaginationAttrs);

  }

  signupFormInitialization(): void {
    this.signUpForm = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],

      //industry: new FormControl(null, Validators.required),
      region: this.fb.array([]),
      data: this.fb.group({
        subject: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(250),
          ],
        ],
        message: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(250),
          ],
        ],
        // verificationcode: ['', []],
      }),
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            new RegExp("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}")
          ),
        ],
      ],

      recaptcha: ["", Validators.required],
    });
  }

  get sf() {
    return this.signUpForm.controls;
  }

  get dataForm() {
    const dataForm = this.signUpForm.get("data") as FormGroup;
    return dataForm.controls;
  }

  //email send

  emailSends(sendObj: any) {
    this.loginService.sendEmailRegister(sendObj).subscribe(
      (data) => {
        console.log("email send success");
        console.log(data);
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      }
    );
  }

  handleExpire() { }
  handleReset() { }

  // to get data group from main form
  get getDataFromSigupForm() {
    return this.signUpForm.get("data") as FormGroup;
  }

  get getRemainderArray() {
    return this.getDataFromSigupForm.get("reminder") as FormArray;
  }

  signUpSubmit() {
    //email start
    let temEmail: any = {};
    //    temEmail.userId = "events_healthfairsplus_com@hfp2dev.lmdcloud.com";
    temEmail.userId = "info@healthfairsplus.com";
    temEmail.slug = "contact-template";
    temEmail.subject = "Contact Us";

    temEmail.keys = {
      // Name: this.registerData.Name,
      // USERNAME:this.registerData.yourname,
      name: this.signUpForm.value.name,
      email: this.signUpForm.value.email,
      subject: this.getDataFromSigupForm.value.subject,
      message: this.getDataFromSigupForm.value.message,
    };

    if (this.signUpForm.valid) {
      this.loginService.sendEmailContact(temEmail).subscribe(
        () => {
          //  console.log("prakash",res)
          this.notifier.notify(
            "success",
            "Our team will reach out to you soon."
          );
          this.signUpForm.reset();
          this.captchaElem.resetCaptcha();
          // this.loginCall();
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    } else {
      //this.signUpSubmitt= true;
    }
  }
}
