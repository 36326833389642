import { Injectable } from "@angular/core";
import { UserRole } from "./constants/user-role.constants";
@Injectable()
export class Globals {
  developmentKey = true;
  // Define the class property.
  statusType = [
    { name: "ACTIVE", value: 1 },
    { name: "INACTIVE", value: 0 },
  ];
  // Define data access authorization.
  auth = [
    { name: "Login", value: 1 },
    { name: "Logout", value: 2 },
    { name: "Both", value: 3 },
  ];

  // this is used for flora parameter
  floraOptions: object = {
    charCounterCount: true,
    plainPaste: true,
    toolbarInline: false,
    height: 150,
    imageUploadURL: "/api/v1/Containers/froala/upload",
    fileUploadURL: "/api/v1/Containers/froala/upload",
    fileAllowedTypes: ["*"],
    useClasses: false,
    attribution: false,
    events: {
      "froalaEditor.image.error": function (e, editor, error) {
        console.error(error);
      },
    },
    key: 'FF3C2F2D1A5B3A5pD1D1D1C4E1J4A14B3A7B4kpA-8H-8whuxD-13isqshpuA6kfg==',
    placeholderText: "Add Content here",
    toolbarButtons: [
      "fullscreen",
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "|",
      "fontFamily",
      "fontSize",
      "color",
      "inlineStyle",
      "paragraphStyle",
      "|",
      "paragraphFormat",
      "align",
      "formatOL",
      "formatUL",
      "outdent",
      "indent",
      "quote",
      "-",
      "insertLink",
      "insertTable",
      "|",
      "emoticons",
      "specialCharacters",
      "insertHR",
      "selectAll",
      "clearFormatting",
      "|",
      "print",
      "spellChecker",
      "help",
      "html",
      "|",
      "undo",
      "redo",
    ],
    //toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'print', 'spellChecker', 'help', 'html', '|', 'undo', 'redo']
  };

  // define auth level as admin/companyadmin
  acl: Array<object> = [
    {
      value: UserRole.SUPER_ADMIN,
      name: "Super Admin",
    },
    {
      value: UserRole.COMPANY_ADMIN,
      name: "Company Admin",
    },
    {
      value: UserRole.EXHIBITOR,
      name: "Exhibitor",
    },
    {
      value: UserRole.PUBLIC_USER,
      name: "Public User",
    },
  ];

  adminPaginationAttrs = {
    statusSelected: "1",
    submitted: false,
    isShow: false,
    rotate: true,
    maxSize: 10,
    limits: 10,
    skips: 0,
    isIndex: -1,
    isActiveIdx: -1,
    childIndex: -1,
    count: 0,
    pageItems: [],
    itemArray: [],
  };
  eventSatisficationRate: Array<object> = [
    { name: "Very Satisfied", value: "Very Satisfied" },
    { name: "Satisfied", value: "Satisfied" },
    { name: "Neutral", value: "Neutral" },
    { name: "Dissatisfied", value: "Dissatisfied" },
    { name: "Very Dissatisfied", value: "Very Dissatisfied" },
  ];

  eventLikeRate: Array<object> = [
    { name: "Very Likely", value: 5 },
    { name: "Likely", value: 4 },
    { name: "Neutral", value: 3 },
    { name: "Unlikely", value: 2 },
    { name: "Very Unlikely", value: 1 },
  ];

  yesMostThemSomeThemReview: Array<object> = [
    { name: "Yes", value: "Yes" },
    { name: "Most of them", value: "Most of them" },
    { name: "Some of them", value: "Some of them" },
    { name: "No", value: "No" },
  ];

  reviewYesSomeWhatOption: Array<object> = [
    { name: "Yes", value: "Yes" },
    { name: "Somewhat", value: "Somewhat" },
    { name: "No", value: "No" },
  ];
  reviewYesSomeWhatNAOption: Array<object> = [
    { name: "Yes", value: "Yes" },
    { name: "Somewhat", value: "Somewhat" },
    { name: "Not Applicable", value: "Not Applicable" },
    { name: "No", value: "No" },
  ];
  reYesSomeWhatStaffNotAsinOpt: Array<object> = [
    { name: "Yes", value: "Yes" },
    { name: "Somewhat", value: "Somewhat" },
    { name: "No", value: "No" },
    { name: "Staff was not assigned", value: "Staff was not assigned" },
  ];

  extremelyGoodPoorReview: Array<object> = [
    { name: "Extremely Successful", value: "Extremely Successful" },
    { name: "Very Good", value: "Very Good" },
    { name: "Could have been better", value: "Could have been better" },
    { name: "Poor", value: "Poor" },
    { name: "Total Bust", value: "Total Bust" },
  ];

  hfpServiceEvaluation: Array<object> = [
    {
      name: "I could not have done it without you.",
      value: true,
      control: "couldNotDone",
    },
    {
      name: "Very Helpful, we made a great team.",
      value: true,
      control: "veryHelpFul",
    },
    {
      name: "Helpful, but we would like you to do more",
      value: true,
      control: "helpFulBut",
    },
    {
      name: "Not quite as helpful as I expected",
      value: true,
      control: "notQuitHelpFul",
    },
    {
      name: "You helped out? I did not even notice!",
      value: true,
      control: "helpOut",
    },
  ];

  hfpServiceAgainReview: Array<object> = [
    { name: "Yes, absolutely!", value: true, control: "yes" },
    {
      name: "Yes, with a few modifications.",
      value: true,
      control: "yesFewModifi",
    },
    {
      name: "You would have to convince me",
      value: true,
      control: "convinceMe",
    },
    { name: "No, probably not", value: true, control: "no" },
  ];

  taxonomyTypesConst = {
    screeningId: "5b7ba5025ee376051498b789",
    industryId: "5b7ba50c5ee376051498b78a",
    taxonomyId: "5c656ba77318bc2c846e210d",
  };
  registerPaymentAmount = 100;
}
