import {
  Directive,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type,
} from "@angular/core";

@Directive({
  selector: "[appFrontFactory]",
})
export class FrontFactoryDirective {
  @Input() component: Type<any>;
  @Input() foldData: any;
  currentCompIndex = -1;
  @Input() userData: any;
  @Output() dateEmitter = new EventEmitter();
  message: string = "";
  constructor(
    private vcRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    this.vcRef.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.component
    );
    const compRef = this.vcRef.createComponent(factory);
    (<any>compRef.instance).foldData = JSON.parse(this.foldData);
    if (this.userData) {
      (<any>compRef.instance).userData = this.userData;
    }
  }
}
