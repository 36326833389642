import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberComponent } from './components/phone-number.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [PhoneNumberComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule
  ],
  exports: [PhoneNumberComponent],
})
export class PhoneNumberModule { }
