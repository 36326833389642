<div class="content-txt-page">
  <div class="heading-top">
    <div class="container">
      <h1>Exhibitor Terms and Conditions</h1>
    </div>
  </div>
  <div class="content-txt-page-btm">
    <div class="container">
      <p>HealthFairsPlus.com was created to help unite corporations and local health and wellness professionals in the interest of promoting proactive health.&nbsp; Any health and wellness professional or small business who registers for the database on this site (these business and professionals will be referred to as “Exhibitor(s)“) and gets contacted through this site is strongly encouraged to conduct their own research of the group, company, person(s) and/or entity which contacted them.&nbsp; Health Fairs Plus does its best to monitor all users of HealthFairsPlus.com.&nbsp; Do NOT assume that all inquires through this site are legitimate invitations into events.&nbsp; PLEASE MAKE SURE THAT YOU KNOW WHO YOU ARE RESPONDING TO.</p>
      <p>Exhibitors who fill out an Virtual registration received via email for a single event (including benefit providers) are published on the exhibitor list for the event in which they registered ONLY.&nbsp; They are not listed anywhere else on this site, nor is their information available to anyone other than the host company of the event in which the exhibitor registered for.&nbsp; However, these terms and conditions still apply.&nbsp; If an exhibitor would like to be listed in a searchable database so that other companies may find their services and such that the exhibitor will be made aware of other events in their area which the exhibitor may be interested in attending, the exhibitor must fill out an exhibitor registration on this site.</p>
      <p>Exhibitors who register on this site and/or for a single event through this site do so with the understanding that their information (including name, email, phone, website, physical address and any other information the exhibitor provides on the registration) will be available for corporations and other persons to view.&nbsp; Exhibitors understand that they may be contacted and/or solicited to attend events and speaking engagements by corporations and companies.&nbsp; Exhibitors who wish to remove information from their profiles or exhibitors who wish to remove their profiles from this site may do so by submitting a request to Health Fairs Plus HERE</p>
      <p>Health Fairs Plus makes NO GUARUANTEES as to the number or quality of the inquires and invitations received by corporations and companies through this site.&nbsp; Fees paid are solely for the listing of the Exhibitor’s profile and nothing else.</p>
      <p>Exhibitors who register and accept invitations into events through HealthFairsPlus.com MUST FOLLOW THESE PROTOCOLS.&nbsp; Failure to do so can result in the removal of the exhibitor’s profile from this site and/or the removal of the exhibitor from the event(s) in which they registered.</p>
      <p>Exhibitor agrees to arrive at least 30 minutes prior to the events in which they choose to attend and stay for its advertised duration, unless otherwise agreed to in advance.&nbsp; Exhibitor agrees to interact with corporate employees in a professional manner which coincides with the solicitation policies set forth and enforced by the host company of an event. Distribution of business cards, literature, and other related marketing materials is usually encouraged for those employees who request such materials at an event.&nbsp; Sales transactions are prohibited, unless agreed to in writing by the host company prior to the event.</p>
      <p>Exhibitors are responsible for their actions and/or information distributed during the events in which they are attending. Health Fairs Plus assumes no liability for Exhibitors and or Exhibitor’s employees, products, or company.&nbsp; Exhibitors agree to distribute only information in which they are reasonably certified, licensed and legally allowed to discuss.</p>
      <p>Exhibitors who register on this site can only post information and services in which they are reasonably certified to offer.&nbsp; Exhibitors who register on this site verify that the information and services posted are accurate and within the legal realm of what the Exhibitor is legally allowed to provide.</p>
      <p>The Exhibitor must have obtained and be up to date on all of the necessary licenses, certifications, insurance, and permits needed to conduct business legally in their respective field in the city, county, and state in which they operate.&nbsp; Exhibitors who register on this site, or for any events through this site, must be able to verify that they have obtained the appropriate licenses, certifications, insurance, and permits necessary to conduct business legally.&nbsp; Health Fairs Plus and/or a Company hosting an event MAY REQUEST a copy of one or more of these documents prior to allowing the exhibitor to come on site.</p>
      <p>Exhibitors may not hold liable Health Fairs Plus, or the host company of an event for any actions of the Exhibitor or persons in their employ while participating in the event. Health Fairs Plus is NOT responsible for any injury, loss, or damage that may result from attending an event.&nbsp;&nbsp; Please use caution when entering and exiting buildings with heavy or valuable equipment.&nbsp; Be sure that tables and chairs are strong enough to support any items that you may set upon them.</p>
      <p>Exhibitors may not be exclusive in their products and services provided, unless prior arrangements have been made between exhibitors and Health Fairs Plus or the Host Company of an event.</p>
      <p>Exhibitor may not choose to accept all invitations received through HealthFairsPlus.com.&nbsp; Should the exhibitor DECLINE an invitation, PLEASE BE SURE TO COMMUNICATE with the person or company that has sent the invitation.&nbsp; Please do not ignore invitations even when you do not plan to accept.</p>
      <p>Exhibitors agree not to use HealthFairsPlus.com as a lead list or recruiting tool for soliciting invitations into corporate wellness events (Health Fairs, Seminars, Workshops, etc) or to solicit any other business from the host companies of the events posted on this website through means other than this website.&nbsp; Do not try to contact companies direct unless they have given you permission to do so.&nbsp; HealthFairsPlus.com strives to protect its clients from unwarranted solicitation.&nbsp; HealthFairsPlus.com will remove users who solicit companies without approval and pursue legal action if necessary.</p>
      <p>Exhibitors who must cancel their participation in an event must contact the host corporation or company of the event in a timely fashion (at least two weeks prior).&nbsp; Exhibitors must contact the host company of a corporation by submitting a cancellation through this site.</p>
      <p>Exhibitors may choose to post information, blogs, educational materials and advice on this site.&nbsp; Exhibitors who register on this site agree to only post information, blogs, educational materials, and advice in which they are certified and legally allowed to distribute.&nbsp; No information, blogs or materials posted can violate copyright laws, HIPAA laws, or any other local, state, or federal laws that govern the distribution of materials and/or advice, or the posting of information via the Internet.</p>
      <p><strong>Code of Conduct</strong></p>
      <p>In addition, all board members, employees, contractors, vendors, and exhibitors (all five groups are referred to as “business participants”) with Health Fairs Plus must follow this code of conduct:</p>
      <ul>
        <li>All business participants must act ethically, professionally, fairly, and in the interest of good business at all times.&nbsp; Business participants will act in a manner which will preserve the integrity of Health Fairs Plus at all times.</li>
        <li>All business participants must act in accordance with the law in all aspects pertaining to any and all business conducted on behalf of, as part a part of, or through Health Fairs Plus.</li>
        <li>Health Fairs Plus has a zero tolerance policy regarding sexual and personal harassment.&nbsp; Health Fairs Plus will&nbsp; not work with any business participants who engages in sexual and/or personal harassment.</li>
        <li>Business participants may not share, discuss, or debate, any issue with another business participant on social media or through any other public domain.&nbsp; In addition, business participants may not speak negatively, or participate in any act considered to be bullying (in person or cyber bullying) of another business participant or another individual or group.&nbsp; Health Fairs Plus strives to achieve a safe and friendly work atmosphere for all.</li>
        <li>Business participants may not engage in verbal, physical, or emotional abuse nor can they attempt to intimidate or degrade another business participant.</li>
        <li>All business participants will treat everyone fairly and equally in regards to race, religion, gender, gender identity, nationality, marital status, political beliefs, and sexual orientation.</li>
        <li>All business participants will take responsibility for their actions and work in a civil and responsible manner to resolve any disputes or issues.</li>
        <li>Business participants who exercise poor judgement in their actions outside of their business with Health Fairs Plus can still portray a negative view of Heath Fairs Plus.&nbsp; HFP will not work with any business participant that does not portray the conduct listed above in their every day lives.</li>
      </ul>
      <p>Additionally, Health Fairs Plus reserves the right to remove an exhibitor from their database should the exhibitor:</p>
      <p>Violate the solicitation policies of a host corporation of an event (seminar, workshop, and/or health fair)</p>
      <p>Receive multiple complaints from employees, corporations, or any other persons regarding the products/services provided during an event or provided offsite to employees or any other person.</p>
      <p>Conduct themselves in an unprofessional manner ANYTIME during an event or outside of an event (including acting unprofessional with employees or contractors from host corporations of events or from Health Fairs Plus).</p>
      <p>Provide false information regarding their products/services on their registration, during an event, or when interacting with a corporation, employee or any other person.</p>
      <p>Become inactive in attending events received through www.healthfairsplus.com, or in responding to inquiries from employees and/or corporations received through www.healthfairsplus.com</p>
      <p>Engage in any illegal activity.</p>
      <p>Solicit invitations or other business from the host companies of the events posted through means other than through HealthFairsPlus.com</p>
      <p>FEES ARE FORFEITED WHEN AN EXHIBITOR IS REMOVED FOR ONE OF THE ABOVE LISTED REASONS!</p>
      <p>&nbsp;</p>
      <p>The terms and conditions set forth herein are the sole contract between Health Fairs Plus and the Exhibitors&nbsp; who register to be listed in the database on this site.&nbsp; Payments for registration are accepted by Health Fairs Plus with the understanding that the Exhibitor has been made aware of, and agrees to all conditions of this agreement prior to sending payment or credit card authorization. Health Fairs Plus acts only as a listing site for the Exhibitor and other suppliers of services. Health Fairs Plus shall not be held responsible for any injury, loss, or damage caused by actions out of their control, or by any action or negligence of attendants or third party who are not in the employ of Health Fairs Plus.</p>
      <p>&nbsp;</p>
      <p>Exhibitors agree to indemnify and hold harmless Health Fairs Plus, and its affiliates for any claim, action, liability, loss, or damage that may occur to the host company of an event or the host company’s employees as a results of the exhibitor’s action, information or representative, &nbsp;while participating in an event listed on this site.&nbsp; Should the Exhibitor fail to defend, protect, and save harmless Health Fairs Plus, Health Fairs Plus, and/or its affiliates for any claim pursuant to this section, Health Fairs Plus Health Fairs Plus and its affiliates have full rights to defend, pay, or settle the claim without further notice to the exhibitor and reclaim all fees and expenses from the exhibitor.</p>
        </div>
  </div>

</div>
