<div class="sub-tab-hd" *ngIf="templateData && templateData.length">
  <div class="hfpbox-row-fold" *ngFor="let data of templateData; let idx = index">
    <div class="hfpbox-row-fold-top clearfix">

      <div class="hfpbox-row-fold-hd">
        <h1>{{data.title ? data.title : 'Container' + (idx + 1)}}</h1>
        <span></span>
      </div>
      <div class="hfpbox-row-fold-btn">
        <ul>
          <li><a href="javascript:void(0)" id="edit1" title="Edit" (click)="editTempData(idx);isIndex=idx">Edit</a></li>
          <li><a href="javascript:void(0)" title="Trash" (click)="deleteTempData(idx);">Delete</a></li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
    <!-- use for Form Template at particular condtion-->
    <div *ngIf="isIndex==idx; then foldTypeTemplate"></div>
  </div>
</div>

<div *ngIf="isShowTemp; then foldTypeTemplate"></div>

<ng-template #foldTypeTemplate>
<div class="add-sub-form">
    <form [formGroup]="templateForm" (ngSubmit)="isIndex!= -1 ? updateData(isIndex) : addData()">
  <div class="hfp-input-div">
    <label>Title</label>
    <input type="text" name="title" placeholder="Home page article fold" formControlName="title">
  </div>

  <div class="hfp-input-div" style="width: 100%;">
    <label>Description</label>
    <div [froalaEditor] formControlName ="description">Hello, Froala!</div>
  </div>

  <div class="buttons">
    <button class="btn btn-primary" type="submit">Save</button>
    <button class="btn btn-default" (click)=" this.isIndex = -1;">Cancel</button>
  </div>
</form>
</div>
</ng-template>
<div class="add-fold-link">
  <a href="javascript:void(0)" (click)="addMore()" class="text_link ng-binding" translate="ADD_MORE">Add More</a>
</div>
