import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as translateJson from '../../assets/i18n/en.json'
import { EventService } from '../event/event.service';
import { UnregisteredExhibitor } from '../interface/unregister-exhibitor/unregister-exhibitor.interface';
import { NotifierService } from "angular-notifier";
import { environment } from 'src/environments/environment';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { Globals } from "../globals";
import { forkJoin } from 'rxjs';
import * as _ from "lodash";
import { EventDetail } from '../interface/event-details/event-detail.interface';
import { EventUserDetails } from '../event/model/event-user-details';
import { ScreeningParent } from '../interface/screening-parent/screening-parent.interface';
import { Industries } from '../interface/industries/industries.interface';
import { UnregisterEventRequestComponent } from '../unregister-event-request/unregister-event-request.component';
import { EventUnregisterUserTemplateData } from '../event/model/event-unregister-user-template-data';
import { CommonStatus } from '../constants/common-status.constants';
import { UserRoleStatus } from '../event/model/event';
import { SystemConstants } from '../constants/system.constants';
import { UserRole } from '../constants/user-role.constants';

@Component({
  selector: 'app-request-invitation',
  templateUrl: './request-invitation.component.html',
  styleUrls: ['./request-invitation.component.scss']
})
export class RequestInvitationComponent implements OnInit {

@Input() selectedEventTemplateDetails:EventDetail;
@Input() selectedExhibitorUser:EventUserDetails;
@Input() isEditRequestFormEnabled:boolean;
@Output() triggerExhibitorEventList: EventEmitter<any> = new EventEmitter();

unregisterEventRequestModalRef: BsModalRef;
isDynamicEventUrl:boolean;
screeningParentList:  ScreeningParent[] = [];
industriesList:Industries[]=[]
translateJson: typeof translateJson 
commonStatus: typeof CommonStatus
userRoleStatus:typeof UserRoleStatus

  constructor( private modalService: BsModalService,
    private eventService :EventService,
    private notifier: NotifierService,
    private taxonomyService: TaxonomyService,
    public globals: Globals,

    ) {
      this.initializeMembersOfClass()
  }
  
  initializeMembersOfClass() {
    this.translateJson = Object.assign({}, (translateJson as any).default);
    this.commonStatus=CommonStatus;
    this.userRoleStatus = UserRoleStatus
  } 
  ngOnInit(): void {
  }
  openAndEditRequestPopUp(content:TemplateRef<UnregisterEventRequestComponent> , localEvent: EventDetail){
    this.selectedEventTemplateDetails = localEvent;
    this.unregisterEventRequestModalRef = this.modalService.show(
      content,
      Object.assign({}, {
        class: "exhibitor-event-edit-pop", backdrop: true,
        ignoreBackdropClick: true,
      })
    );

  }
  

  onSubmitRequest(data: UnregisteredExhibitor) {
    this.selectedEventTemplateDetails.eventReqData && this.selectedEventTemplateDetails.eventReqData.id ? this.updateRequest(data) : this.createNewRequest(data);
  }

  updateRequest(queryObj: UnregisteredExhibitor) {
    const eventReqObj:UnregisteredExhibitor = Object.assign({} ,queryObj);
    if(this.selectedEventTemplateDetails && this.selectedEventTemplateDetails.eventReqStatus === 3)eventReqObj.status = CommonStatus.PENDING;
    this.eventService
      .updateEventReqById(this.selectedEventTemplateDetails.eventReqData.id, eventReqObj).subscribe(() => {
        this.triggerExhibitorEventList.emit()
          let templateEmailDetails: EventUnregisterUserTemplateData = {};
          templateEmailDetails.userId = this.selectedEventTemplateDetails.memberId;
          templateEmailDetails.slug = SystemConstants.EVENT_REQUEST;
          templateEmailDetails.keys = {
            EVENT: this.selectedEventTemplateDetails.eventName,
            USERNAME: this.selectedEventTemplateDetails.corpData[0].username,
            CORPORATE_CONTACT_NAME: this.selectedEventTemplateDetails.corpData[0].contact,
            COMPANY_NAME: this.selectedEventTemplateDetails.corpData[0].company_name,
          };
          this.selectedEventTemplateDetails.eventReqData = Object.assign({}, queryObj, { id: this.selectedEventTemplateDetails.eventReqData.id });

          this.notifier.notify(this.translateJson.SUCCESS, this.translateJson.REQUEST_SENT_SUCCESSFULLY);
          this.closePopup();
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
          }
        }
      );
  }
  createNewRequest(queryObj: UnregisteredExhibitor) {
    const eventReqObj = Object.assign({} as UnregisteredExhibitor , queryObj);
    eventReqObj.userid = this.selectedExhibitorUser.id;
    eventReqObj.localEventId = this.selectedEventTemplateDetails.id
      ? this.selectedEventTemplateDetails.id
      : this.selectedEventTemplateDetails._id;
      let selectedEventDate = moment(this.selectedEventTemplateDetails.eventDate).format("YYYY-MM-DD");
      let selectedEventEndDateTimeStamp: any = moment(this.selectedEventTemplateDetails.endDateTime).format("THH:mm:ss");
      let finalEventDate = selectedEventDate + selectedEventEndDateTimeStamp;
      eventReqObj.eventDate = finalEventDate
     let timeZone = this.getTimeZone();
    eventReqObj.timeZone = timeZone    
    eventReqObj.eventSlug = this.selectedEventTemplateDetails.permalink;
    eventReqObj.status = CommonStatus.PENDING;
    eventReqObj.eventType = this.selectedEventTemplateDetails.eventType;
    this.eventService.addRequestForEvent(eventReqObj).subscribe(
      (data) => {
        let templateEmailDetails: EventUnregisterUserTemplateData = {};
        templateEmailDetails.userId = this.selectedEventTemplateDetails.memberId;
        templateEmailDetails.slug = SystemConstants.EVENT_REQUEST;
        let url =
          environment.sentEmailHost +
          "/view-exhibitors/" +
          this.selectedEventTemplateDetails.permalink;
        let anchorUrl = "<a href='" + url + "'>Click Here</a>";
        templateEmailDetails.keys = {
          EVENT: this.selectedEventTemplateDetails.eventName,
          USERNAME: this.selectedEventTemplateDetails.corpData[0].username,
          CORPORATE_CONTACT_NAME: this.selectedEventTemplateDetails.corpData[0].contact,
          COMPANY_NAME: this.selectedEventTemplateDetails.corpData[0].company_name,
          INQUIRY_URL: anchorUrl
        };
        this.eventService.emailSends(templateEmailDetails);
        this.selectedEventTemplateDetails.eventReqExist = true;
        this.selectedEventTemplateDetails.eventReqData = data;
        this.notifier.notify(this.translateJson.SUCCESS, this.translateJson.REQUEST_SENT_SUCCESSFULLY);
        this.closePopup();
        this.triggerExhibitorEventList.emit()
        this.selectedEventTemplateDetails.disable = true;
      },
      (err) => {
        if (
          err &&
          err.error &&
          err.error.error &&
          err.error.error.message.message
        ) {
          this.notifier.notify(SystemConstants.ERROR, err.error.error.message.message);
          this.closePopup();
        }
      }
    );
  }
 getTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  }
closePopup(): void {
  this.unregisterEventRequestModalRef.hide();
}
  getScreeningTaxo(): void {
    forkJoin([
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: CommonStatus.ACTIVE,
            taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId,
          },
        },
      }),
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: CommonStatus.ACTIVE,
            taxonomyTypeId: this.globals.taxonomyTypesConst.industryId,
          },
        },
      }),
    ]).subscribe(
      (results) => {
        this.screeningParentList = results[0] ? results[0] : [];
        this.industriesList = results[1] ? results[1] : [];
        // if (!this.isDynamicEventUrl) {
          // if (
          //   this.selectedExhibitorUser.industry &&
          //   this.selectedExhibitorUser.industry.length > SystemConstants.DEFAULT_ZERO &&
          //   this.selectedExhibitorUser.role == this.userRoleStatus.EXHIBITOR
          // )
          if (
            this.selectedEventTemplateDetails.screenReq &&
            this.selectedEventTemplateDetails.screenReq.length >  SystemConstants.DEFAULT_ZERO  &&
            this.selectedExhibitorUser.role == UserRoleStatus.EXHIBITOR
          ) {
            let selectedScreening: ScreeningParent[] = [];
            this.screeningParentList.forEach(async (value) => {
              _.find(this.selectedEventTemplateDetails.screenReq, function (o) {
                if (String(o) == String(value.id)) {
                  selectedScreening.push(value);
                }
              });
            });
            // let selectedExhibitorIndustriesList: Industries[] = [];
            // this.industriesList.forEach(async (value) => {
            //   _.find(this.selectedExhibitorUser.industry, function (o) {
            //     if (String(o) == String(value.id)) {
            //       selectedExhibitorIndustriesList.push(value);
            //     }
            //   });
            // });

            this.screeningParentList = selectedScreening;
            // this.industriesList =selectedExhibitorIndustriesList;

          }
        // }
      },
      () => { }
    );

  }
}
