<div class="content-txt-page">
  <div class="heading-top">
    <div class="container">
      <h1>Refund Policy</h1>
    </div>
  </div>


  <div class="content-txt-page-btm">
    <div class="container">
      <h3>Refund Policy for Registration</h3>
      <p>Registering at HealthFairsPlus.com gives user the ability to view and request invitations into upcoming events in their area.  Because the fees for registration are for information only, we do not issue refunds of registration fees once the order is complete.  This policy helps us protect against those users who want to simply collect the names of our clients and use them for purposes other than those allowed under the <a routerLink="/exhibitor-terms-and-conditions" href="" target="_blank"> exhibitor terms and conditions</a>.  If you would like to know the number or types of events displayed in your area prior to registration, please email a request to <a href="mailto:info@healthfairsplus.com">info@healthfairsplus.com</a> and we will be happy to provide that information prior to your registration.  We do NOT issue refunds for registration for the following reasons:</p>
      <p>- You are not satisfied for any reason with the list of events<br>
      - You are not able to attend any events that are listed<br>
      - You are not accepted into one or more of the events that you request an invitation for<br>
       - You are no longer attending events and wish to remove your account from HealthFairsPlus.com</p>
      <h3>Refund Policies for event registrations</h3>
      <p>HealthFairsPlus.com will issue refunds for fees paid to attend events under the following conditions:</p>
      <p>
        - The exhibitor who paid the fee cancels their registration more than two weeks prior to the event<br>
        - The exhibitor who paid the fee cancels their registration less than two weeks prior to the event, but a replacement exhibitor is found to fill the space.<br>
        - The event is cancelled or rescheduled from the date in which the exhibitor signed up and paid for.
      </p>
      <p>HealthFairsPlus.com will NOT refund event fees under the following conditions:</p>
      <p>- The exhibitor who paid the fee cancels with less than 3 days notice of the event.<br>
        - The exhibitor who paid the fee is not able to attend the event because they did not follow the proper security and check-in protocols set forth for the event.<br>
        - The exhibitor who paid the fee cannot set up because they did not show during the designated set up time.<br>
        - The exhibitor who pays the fees is removed from the event for violate events rules including solicitation policies.<br>
        - The exhibitor who pays the fees fails to show for an event without prior communication as to why.</p>
      <p>If you have questions about our refund policies, please contact us at <a href="mailto:info@healthfairsplus.com">info@healthfairsplus.com</a> or 703-743-2735</p>
    </div>
  </div>

</div>



