<div class="content-txt-page">
  <div class="heading-top">
    <div class="container">
      <h1>Corporate Terms and Conditions</h1>
    </div>
  </div>
  <div class="content-txt-page-btm">
    <div class="container">
      <p><strong>HealthFairsPlus.com</strong> was created to help unite corporations and local health and wellness professionals in the interest of promoting proactive health.&nbsp; HealthFairsPlus.com consist of information, blogs, and educational materials provided by health and wellness professionals worldwide as well as a database of exhibitors that provide health and wellness services for employees, corporations, organizations, groups and companies (referred to as <strong>Exhibitor(s)</strong>). &nbsp;HealthFairsPlus.com and any distributor or licensed provider (referred to as affiliates)&nbsp;of HealthFairsPlus.com&nbsp;acts only as a listing&nbsp;website for these exhibitors and their information, posts, materials, advice and blogs. &nbsp;Exhibitors have registered with the understanding that the information, posts, materials, advice, blogs&nbsp;and services they have listed are accurate and legal and that each has the proper licenses, certifications, liability insurance and/or permits to conduct their listed&nbsp;services legally in the city, county, and states in which they operate.</p>
      <p>Exhibitors who have registered on this site do so because they are interested in receiving information about corporate wellness events. &nbsp;Alanola Health Services, LLC, Health Fairs Plus and its affiliates&nbsp;do our best to continually monitor the business and legal standing of each and every exhibitor in the database.&nbsp; However, IT IS THE RESPONSIBILITY of the corporations, companies, organizations, groups, employees, and&nbsp;any other person&nbsp;who uses this site (all referred to as “the registered” in this agreement) to verify such information before&nbsp;inviting exhibitors to events.&nbsp; Alanola Health Services, LLC, Health Fairs Plus and its affiliates require exhibitors to agree to terms and conditions regarding their business and professional licenses, certifications, and insurance as well as their behavior when attending events and posting information.&nbsp; These terms and conditions can be viewed <a title="Exhibitor Terms and Conditions" href="" routerLink="/exhibitor-terms-and-conditions" target="_blank">HERE</a>.&nbsp; Alanola Health Services, LLC, Health Fairs Plus and its affiliates&nbsp;remove exhibitors who consistently violate these terms and conditions when we are aware of the violations. &nbsp;Removing exhibitors who violate the terms and agreements of this site does NOT imply that Alanola Health Services, LLC, Health Fairs Plus or its affiliates are responsible in any way for the actions or conduct of the exhibitors listed on this site.</p>
      <p>Alanola Health Services LLC and Health Fairs Plus assumes NO LIABILITY for the actions of the exhibitors listed on this site, their products, their information,&nbsp;blogs, posts, advice or people in their employ. &nbsp;Corporations, companies, organizations, groups, employees and&nbsp;any other persons who use this site for information or to find exhibitors for events or gatherings of any sort do so voluntarily and at their own risk.&nbsp; It is STRONGLY RECOMMENDED that those using this site conduct additional research regarding the information, post, blogs, advice and materials provided, as well as conduct the proper research on any exhibitor they choose to enter into an agreement or contract with for any purpose.</p>
      <p>Corporations, Organizations, Companies, and Groups who register on this site certify that they are using this site for their respective company, group or organization in order to find health and wellness information and professionals who can help with wellness events.&nbsp; The Registered agree not to distribute the information found on this site nor the information provided by exhibitors including their contact information, posts, blogs, materials and/or advice to any third parties.&nbsp; Any such violations of this term will be prosecuted to the fullest extent of the law.</p>
      <p>The Registered agrees not to share their username and password with any third party or any corporation, company, organization, group or person not registered on this site.&nbsp; Alanola Health Services, LLC strives to protect the information of the exhibitors and companies who are registered on this site.</p>
      <p>The Registered agrees to indemnify and hold harmless Alanola Health Services, LLC, Health Fairs Plus and its affiliates for any claim, action, liability, loss, or damage that may&nbsp;occur to the exhibitors registered on this site while participating&nbsp;in an event hosted by the Registered.&nbsp; Should the Registered fail to defend, protect, and save harmless&nbsp;Alanola Health Services, LLC, Health Fairs Plus, and/or its&nbsp;affiliates for any claim pursuant to this section, Alanola Health Services, LLC Health Fairs Plus and its&nbsp;affiliates&nbsp;have full rights to defend, pay, or&nbsp;settle the claim without further notice to the Registered and reclaim all fees and expenses from the Registered.&nbsp;&nbsp;PLEASE MAKE SURE that tables and chairs are sturdy, and that no trip hazards are in the way when inviting exhibitors onsite.</p>
      <p>When using this site to recruit exhibitors for an onsite event at the Registered’s location,</p>
      <p>The&nbsp;Registered reserves the right to accept or reject any and all exhibitors invited to their event through this site<strong>.</strong>&nbsp; The&nbsp;<strong><em>Registered</em></strong>&nbsp;confirms that they have approved an exhibitor to be onsite by ACCEPTING their invitation request.&nbsp;&nbsp;The Registered understands&nbsp;that all exhibitors&nbsp;accepted to be&nbsp;onsite are done so at the approval of the&nbsp;Registered AND ON BEHALF of the Registered<strong><em>.&nbsp; </em></strong></p>
      <p>The Corporations, companies, organizations and groups who register on this site do so with the understanding that they have agreed to the terms and conditions listed above.</p>
    </div>
  </div>

</div>
