<div *ngIf="tempEventData"  class="event-detail-hd no-top-hd event-step-preview"  [ngStyle]="{'background-image': 'url(assets/images/event/defaultEventImages/' + tempEventData.media+')'}">
  <div class="event-bg-overly"></div>
  <div class="event-bg-overly-2"></div>
  <div class="container">
    <div class="main-heading-top clearfix">
      <div class="main-user-info">
        <div class="main-user-img">
            <div class="main-user-img-upload" hidden><input type="file" />
             <i class="fa fa-camera" aria-hidden="true"></i>
            </div>
          <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
          <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

        </div>
        <h2>{{userData.username}}</h2>
      </div>
      <div class="main-heading-right">
        <h1>{{tempEventData && tempEventData.corpData && tempEventData.corpData[0] && tempEventData.corpData[0].data && tempEventData.corpData[0].data.company_name}}</h1>
        <span *ngIf="tempEventData.corpData">{{tempEventData.eventName}}</span>
        <ul>
            <li><i><img src="assets/images/event-date-icon.png" alt="" /></i>
              <span>{{tempEventData.eventDate | date: 'MMMM d, yyyy' }} <br>  {{tempEventData.startDateTime | date:'shortTime'}} - {{tempEventData.endDateTime | date:'shortTime'}}</span>
            </li>
            <li><i><img src="assets/images/event-location-icon.png" alt="" /></i><span> {{tempEventData.eventAddress.location}}<br *ngIf="tempEventData.address2">{{tempEventData.address2}}</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- {{tempEventData | json}} -->

<div class="event-detail-mid " *ngIf="tempEventData">
  <div class="container">
    <div class="event-detail-mid-in corporate-detail-pg margin-top-m" [ngClass]="{'event-details-page-ex': userData.role == 20}">
      <div class="clearfix">
        <div class="hfp-col-8">
          <div class="event-d-mid-in-left">
            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-1.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-1.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-1.png" alt="" />
                </i>
                <strong>Onsite Employees</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Onsite Employees</h3>
                <p>{{tempEventData.onsiteEmp}}</p>
              </div>
            </div>
            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-2.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-2.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-2.png" alt="" />
                </i>
                <strong>The Day Of Contact</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>The Day Of Contact</h3>

                <p>{{tempEventData.onsiteContact}}</p>
              </div>
            </div>
            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-11.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-11.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-11.png" alt="" />
                </i>
                <strong>Phone Number</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Phone Number</h3>
                <p>{{tempEventData.phone}}</p>
              </div>
            </div>

            <!-- <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-2.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-2.png" alt="" />
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-2.png" alt="" />
                </i>

                <strong>Exhibitor Requests</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Category Request</h3>
                <ul>
                    <li *ngFor="let industry of indusList">{{industry.name}}</li>
                </ul>
              </div>
            </div> -->
            <div class="event-detail-list clearfix" *ngIf="userData.role === 10 || userData.role === 0">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-3.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-3.png" alt="" />
                  <!-- <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-3.png" alt="" /> -->
                </i>
                <strong>Services Requested</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Services Requested</h3>
                <ul>
                    <li *ngFor="let screen of screeningList">{{screen.name}}</li>
                </ul>
              </div>
            </div>
            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-4.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-4.png" alt="" />
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-4.png" alt="" />
                </i>
                <strong>Other Request</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Other Exhibitors</h3>
                <p>{{tempEventData.otherExhibitor}}</p>
              </div>
            </div>
            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-15.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-15.png" alt="" />
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-15.png" alt="" />
                </i>
                <strong>Event address</strong>
              </div>
              <div class="event-detail-list-r" *ngIf="tempEventData && tempEventData.regions">
                <h3>Event address</h3>
                <ul>
                  <li *ngFor="let region of tempEventData.regions">{{region.name}}</li>
                </ul>
              </div>
            </div>
          <!-- hiding beacuse we are not storeing raffer item this time -->
            <!-- <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-6.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-6.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-6.png" alt="" />
                </i>
                <strong>Raffle Item</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Raffle Item</h3>
                <p *ngIf="tempEventData.requestReffleItem == true">Raffle requested</p>
                <p *ngIf="tempEventData.requestReffleItem == false">Raffle not requested</p>
              </div>
            </div> -->

            
            <!-- TODO:- Hiding Attendance Fee -->


            <!-- <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-7.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-7.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-7.png" alt="" />
                </i>
                <strong>Attendance Fee</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Attendance Fee</h3>
                <p *ngIf="tempEventData.attendanceFee != 'hfp'">{{tempEventData.attendanceFee | titlecase}}</p>
                <p *ngIf="tempEventData.attendanceFee == 'hfp'">{{tempEventData.attendanceFee | uppercase}}</p>
              </div>
            </div> -->
            <div class="event-detail-list clearfix" *ngIf="loginUserRole == 20">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-8.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-8.png" alt="" />
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-8.png" alt="" />
                </i>
                <strong>Invitation Request Status</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Invitation Request Status</h3>
                <!-- {{eventRequestData | json}}
                {{eventRequestData?.length | json}} -->
                <p *ngIf="eventRequestData?.status == 0">Pending</p>
                <p *ngIf="eventRequestData?.status == 1">Apporved</p>
                <p *ngIf="!eventRequestData?.length && !(eventRequestData?.status == 0) && !(eventRequestData?.status == 1)" >You have not sent a request for this event</p>
              </div>
            </div>

            <!-- TODO :- hiding the step 4 details -->

            <!-- <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-9.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-9.png" alt="" />
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-9.png" alt="" />
                </i>
                <strong>Insurance Carriers</strong>
              </div>
              <div class="event-detail-list-r">
                    <h3>Medical Insurance</h3>
                    <p>{{tempEventData.medicalCarriers}}</p>

                    <h3>Dental Insurance</h3>
                    <p>{{tempEventData.dentalCarries}}</p>

                    <h3>Vision Insurance</h3>
                    <p>{{tempEventData.visionCarries}} </p>
              </div>
            </div> -->
            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-10.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-10.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-10.png" alt="" />
                </i>
                <strong>Event Details</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Parking Instructions</h3>
                <p>{{tempEventData.parkingInstructions}}</p>

                <h3>Check In/Security Instructions</h3>
                <p>{{tempEventData.checkIn}}</p>

              </div>

            </div>
            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-10.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-10.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-10.png" alt="" />
                </i>
                <strong>Additional Event Details</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Additional Event Details</h3>


                <p  [innerHTML]="tempEventData.eventDescription"></p>
              </div>
            </div>

            <div class="event-detail-list clearfix">
              <div class="event-detail-list-l">
                <i>
                  <img *ngIf="userData.role == 0" src="assets/images/corporate-list-icon-5.png" alt="" />
                  <img *ngIf="userData.role == 10" src="assets/images/corporate-list-icon-5.png" alt="" />
                  <img *ngIf="userData.role == 20" src="assets/images/exhibitor-list-icon-5.png" alt="" />
                </i>
                <strong>Website</strong>
              </div>
              <div class="event-detail-list-r">
                <h3>Website </h3>


                <p  [innerHTML]="tempEventData.website"></p>
              </div>
            </div>

          </div>
        </div>

        <!-- Exibitor List participate list start from here-->
        <div class="hfp-col-4" *ngIf="localEvents == true">
          <div class="corporate-event-mid-right">
            <div class="PayForEventBtn" *ngIf="userData.role == 20 && tempEventData.payButtonShow == 1">
              <a id="view-ex-detail" href="javascript:void(0)" (click)="setEventCheckout(tempEventData.paymentData,tempEventData)" >Pay For Event</a>
            </div>
            <div class="download-list-btn" *ngIf="exhibListAttributes.pageItems && exhibListAttributes.pageItems.length > 0">
              <a href="javascript:void(0)" (click)="exportExhibitor()">Download Exhibitor List</a>
            </div>
          <div class="download-list-btn" *ngIf="userData.role === userRoleStatus.EXHIBITOR">
            <app-request-invitation class="request-invitation-btn" *ngIf="!tempEventData.eventReqExist && userData?.role === userRoleStatus.EXHIBITOR
            && tempEventData.openCloseStatus === commonStatus.ACTIVE"
                            [selectedEventTemplateDetails]="tempEventData"
                            [selectedExhibitorUser]="userData">
            </app-request-invitation>
            </div>
            <div class="search-exhibitor" hidden *ngIf="exhibListAttributes.count > 0">
              <input type="text" value="" placeholder="Search Exhibitor">
              <input type="submit" value="">
            </div>
            <div class="exhibitorright-list" *ngIf="loginUserRole === 10 && exhibListAttributes.count === 0">
              <h2 *ngIf="tempEventData.status === 0">Your event is awaiting approval</h2>
              <h2 *ngIf="tempEventData.status === 2">Your event has been declined</h2>
            </div>

            <div class="exhibitorright-list" *ngIf="(loginUserRole === 0 || loginUserRole === 10) && exhibListAttributes.count > 0">
              <h2>Exhibitor - {{exhibListAttributes.pageItems.length}}</h2>
             <div class="exhibitorright-scroll">
              <div class="exhibitorright-box-list" *ngFor="let user of exhibListAttributes.pageItems;  let idx = index">
                <div class="exhibitorright-row">
                  <div class="exhibitorright-row-dropdown">
                    <button><img src="assets/images/setting-icon.png" alt=""></button>
                    <div class="ex-row-drp-out">
                      <div class="ex-row-drp-in">
                        <a *ngIf="user.eventReqStatus == 0 || user.eventReqStatus == 2" href="javascript:void(0)" (click)="acceptInvitationOfEvent(user.eventReqData.id);">Accept </a>
                        <a *ngIf="user.eventReqStatus == 1 || user.eventReqStatus == 0 " href="javascript:void(0)" (click)="declineInvitationOfEvent(user.eventReqData.id,user.eventReqStatus);">Decline </a>
                      </div>
                    </div>   </div>
                 <div class="exhibitorright__right__hd clearfix">
                  <h4>{{user && user.data && user.data.company_name  ? user && user.data && user.data.company_name : user.username}}</h4>
                     <div *ngIf="user.eventReqStatus == 0" class="status-txt"><i><img width="20px" src="/assets/images/sm-peding-icon.png" alt=""></i>Pending</div>
                      <div *ngIf="user.eventReqStatus == 1" class="status-txt"><i><img width="20px" src="/assets/images/sm-paid-icon.png" alt=""></i>  Approved</div>
                      <!-- <div *ngIf="user.eventReqStatus == 2" class="status-txt"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>  Declined</div> -->


                      <div *ngIf="user.eventReqStatus == 2 && user.eventReqData.approveStatus == 1" class="status-txt"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>  Canceled</div>
                      <div *ngIf="user.eventReqStatus == 2 && user.eventReqData.approveStatus == 0" class="status-txt"><i><img width="20px" src="/assets/images/declined-icon.png" alt=""></i>  Declined</div>


                 </div><!--4 months ago-->
                  <div class="exhibitor-date"><span>{{user.eventReqData.created | moment}}</span>
                     <!-- <i><img src="assets/images/review-star.png" alt=""></i> -->
                    </div>
                  <p *ngIf="user && user.address">{{user.address?.formatted_address}}</p>
                 <!--<a href="#">View Detail</a>-->
                </div>

              </div>
             </div>
             <div class="exhibitor-pagi">
               <pagination *ngIf="exhibListAttributes.count > exhibListAttributes.limits"
                [totalItems]="exhibListAttributes.count"
                [rotate]="exhibListAttributes.rotate" [maxSize]="exhibListAttributes.maxSize"
                       [itemsPerPage]="exhibListAttributes.limits" (pageChanged)="pageChanged($event);">
             </pagination>
             </div>
            </div>
          </div>
        </div>
        <!-- Exibitor List participate list end here-->
      </div>
    </div>
    <div class="goback" hidden>
      <a href="#"><i class="fa fa-angle-double-left" aria-hidden="true">
      </i> Go Back Previous </a>
    </div>
  </div>
</div>

<!-- event preview  edit and draft bottom bar start here-->
<!-- (click)="eventComp.open(content, tempEventData,'temp');" -->

  <div *ngIf="localEvents == false" class="event-preview-fixed">
    <h1>Event Preview</h1>
    <p></p>
    <div class="event-preview-btn">
      <button (click)="postEventData(tempEventData)" [class.disabled]="true">
        <i class="fa fa-check" aria-hidden="true"></i> Post</button>
    <a  *ngIf="tempEventData && tempEventData.permalink" routerLink="event/update-event/{{tempEventData.permalink}}" ><i class="fa fa-pencil" aria-hidden="true"></i> Edit</a>  <!---->
      <a href="javascript:void(0)"  *ngIf="userData.role == 10" (click)="saveDaraftEvent(tempEventData)"><i class="fa fa-file-code-o" aria-hidden="true"></i> Save as draft</a>
    </div>
  </div>



<!--event preview edit and draft end here -->
<div class="event-map-section">
  <div class="event-map-section-top">
    <div class="container" *ngIf="tempEventData && tempEventData.eventAddress">
      <ul>
        <li>
          <a  href="javascript:void(0)" (click)="isShow=!isShow;initAutoComplete();"  id="open-direction-box">Driving Directions</a>
        </li>
        <li class="li-map-icon">{{tempEventData.eventAddress?.location}}</li>
      </ul>
    </div>
    <div class="direction-from-box" *ngIf="isShow">




      <form [formGroup]="addressForm"  autocomplete="off">

        <div class="direction-input">

          <input class="input-field map-icon" id="eventMap" name="addressOne"
          (keydown.enter)="$event.preventDefault();" (click)="onSearchAddressForRegion($event)"  placeholder="Search Nearest Location" autocorrect="off"
          autocapitalize="off"  spellcheck="off" type="text" #search>
        </div>
        <div class="direction-btn"><button (click)="displayTextDirection()">     {{translateJson.GET_DIRECTION | translate}}</button></div>
  
        <div class="direction-btn"><button (click)="displayRoute()">{{translateJson.GET_ROUTE | translate}}</button></div>
        <div class="direction-close"><button id="direction-close" (click)="isShow=!isShow"><img src="assets/images/Direction-close.png" /></button></div>
      </form>
    </div>

  </div>

  <div class="event-map-section-btm">
    <div class="map-responsive" *ngIf="tempEventData && tempEventData.eventAddress">
        <agm-map style="height : 100%" [latitude]="tempEventData.eventAddress?.lat" [longitude]="tempEventData.eventAddress?.lng"
         [zoom]="zoom">
            <agm-marker [latitude]="tempEventData.eventAddress?.lat"
             [longitude]="tempEventData.eventAddress?.lng" [markerDraggable]="false"></agm-marker>
             <agm-direction 
                          [origin]="origin" 
                          [destination]="destination">
              </agm-direction>
        </agm-map>
    </div>
  </div>

  <div class="direction-map-list">
    <div class="direction-map-add clearfix" *ngIf="isRouteVisible===true">
      <div class="direction-map-add-txt"*ngIf="isRouteVisible===false"></div>
     <div class="direction-map-add-txt-close"><a href="/event"> {{translateJson.CLOSE_ROUTE | translate}} <i><img src="assets/images/close-icon.png" /></i></a></div> 
    </div>
    <div class="direction-map-list-row">
      <div id="right-panel" class="map-dic-list"></div>
    </div>
  </div>

</div>





