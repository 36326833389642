import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { routesData } from '../menu/MENU_LIST';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GetRouterLinkService {

  constructor(private injector: Injector,
    private router: Router,
    private activateRoute: ActivatedRoute) { }

  getRouterLinkFromPageItemsService(options: { [key: string]: any }) {
    if (options.linkTo.type !== "P") {
      this.router.navigate(['/']);
    } else {
      let pageInfo = [];
      pageInfo = options.pageItems.filter((pItem) => {
        return pItem.id === options.linkTo.page;
      });
      console.log(pageInfo)
      if (!(pageInfo && pageInfo.length > 0)) {
        this.router.navigate([options.linkTo.page]);
      } else {
        if (pageInfo[0] && pageInfo[0].permalink && pageInfo[0].foldId && pageInfo[0].foldId.length > 0) {
          // const foldInfo = options.foldList.filter((fold) => {
          //   return fold.id === pageInfo[0].foldId[0]
          // })
          let routerLink: any = routesData.filter((routes) => {
            return routes.path === String(pageInfo[0].permalink)
          })[0]?.path
          if (routerLink) {
            this.router.navigate([routerLink])
          } else {
            this.router.navigate([pageInfo[0].permalink])
          }
        }
        else {
          this.router.navigate(['/']);
        }
      }

    }
  }


  getPageAccordingUsers(appComponent: AppComponent): boolean {
    let authLogin: boolean;
    this.injector.get(AuthService).isLoggedIn.subscribe
      ((isLogin) => {
        if (!isLogin) {
          appComponent.role = 21;
          if ((appComponent.pageData.accessLevel === appComponent.role) && (appComponent.pageData.auth === 2 || appComponent.pageData.auth === 3)) {
            authLogin = true
          } else {
            this.router.navigate(['/'])
            authLogin = false
          }
        } else {
          if (appComponent.role === 0 || appComponent.pageData.accessLevel === 21) {
            authLogin = true;
          } else {
            if ((appComponent.pageData.accessLevel === appComponent.role) && (appComponent.pageData.auth === 1 || appComponent.pageData.auth === 3)) {
              authLogin = true
            }
            else {
              this.router.navigate(['/'])
              authLogin = false;
            }
          }
        }
      })
    return authLogin
  }
}
