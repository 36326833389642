<footer>
  <div class="container">
    <div class="footer-left">
      <img src="/assets/images/new-logo-SVG.png" alt="" />
      <div class="copyright">Copyright © 2020, All Rights Reserved.</div>
    </div>
    <div class="footer-right">
      <!-- <ul>
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/about-us">About Us</a></li>
        <li><a routerLink="/contact-us">Contact Us</a></li>
      <li><a routerLink="/blog">Blog</a></li>
       <li><a routerLink="/news">News</a></li>
        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
      <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
      </ul> -->


      <div *ngIf="isUserLoggedIn">
        <ul >
          <li *ngFor="let menu of menuItems; let index = index">
            <a *ngIf="(menu.menuType.toLowerCase() === 'footer menu' && menu.status === 1 &&
             (isAuthForLoggedIn_And_PublicUser(menu.auth, menu.accessLevel) || isAuthForLoggedIn_And_SpecificUser(menu.auth, menu.accessLevel, userRole)
             || isAuthforBoth_And_PublicUser(menu.auth, menu.accessLevel)))"
              (click)="getRouterLinkFromPageItems({linkTo: menu.linkTo, index: index})" routerLinkActive='active' 
              [ngClass] = "{'active': index === selectedRouterLinkIndex }" >
                {{menu.menuName | capitalize}}
              </a>
              <!-- <a *ngIf="(menu.linkTo.type === 'L' && ((menu.auth === 1  && menu.accessLevel === userRole) || (menu.auth === 3)))"
               routerLink = "menu.url" routerLinkActive='active' >
                {{menu.menuName | capitalize}}
              </a> -->
          </li>
        </ul>
      </div>

      <div *ngIf="!isUserLoggedIn">
        <ul >
          <li *ngFor="let menu of menuItems; let index = index">
            <a *ngIf="((menu.menuType.toLowerCase() === 'footer menu' && menu.status === 1) && ((menu.auth === 3 && menu.accessLevel === 21) || 
            ( menu.accessLevel === 21 && menu.auth == 2)))"
            (click)="getRouterLinkFromPageItems({linkTo: menu.linkTo, index: index})" routerLinkActive='active' 
            [ngClass] = "{'active': index === selectedRouterLinkIndex }" >
              {{menu.menuName | capitalize}}
            </a>
            <!-- <a *ngIf="(menu.linkTo.type === 'L' && ((menu.auth === 1  && menu.accessLevel === userRole) || (menu.auth === 3)))"
             routerLink = "menu.url" routerLinkActive='active' >
              {{menu.menuName | capitalize}}
            </a> -->
          </li>
        </ul>
      </div>

      <!-- <ng-template #footerMenu let-menuItems="menuItems">
        <ul *ngIf="!isUserLoggedIn">
          <li *ngFor="let menu of menuItems">
            <div *ngIf="menu.status === 1 && menu.menuType === 'Footer Menu'">
              <a *ngIf="(((menu.auth === 2  && menu.accessLevel === userRole) || (menu.auth === 3)))"
              (click)="getRouterLinkFromPageItems(menu.linkTo)" routerLinkActive='active'>
                {{menu.menuName | capitalize}}
              </a>
               <a *ngIf="(menu.linkTo.type === 'L' && ((menu.auth === 1  && menu.accessLevel === userRole) || (menu.auth === 3)))"
               [routerLink] = "menu.url" routerLinkActive='active' >
                {{menu.menuName | capitalize}}
              </a> 
            </div>
          </li>
        </ul>
      </ng-template>
      <ng-container *ngTemplateOutlet="footerMenu; context: footerContext"></ng-container> -->
    </div>
  </div>
</footer>