import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { DataService } from '../../../data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'app-manage-hero',
  templateUrl: './manage-hero.component.html',
  styleUrls: ['./manage-hero.component.scss']
})
export class ManageHeroComponent implements OnInit {
  @ViewChild('activeFrameinputFile') inputFrameVariable: ElementRef;
  @Input() foldData;
  data: any = {};
  isShowTemp = true;
  tempFoldData: any = [];
  isIndex = -1;
  templateData: any = [];
  filePreviewPath: string;
  holdImgdata: any = [];
  localUrl: any[];
  filePreviewUrl: any[];
  templateForm: FormGroup;
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;

  public uploader: FileUploader = new FileUploader({
    url: environment.apiUrl + '/containers/upload?folderName=fold'
  });

  constructor(private fb: FormBuilder, private modalService: BsModalService, private dataservice: DataService) {
    this.data = { title: '', description: '', image: [] }
  }
  ngOnInit() {
    
    // Fold add form
    this.templateForm = this.fb.group({
      title: ['', Validators.required, []],
      description: ['', []],
      image: [null, Validators.required]
    });

    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const data = JSON.parse(response);

      if (status === 200 && data && data.files && data.files[0] && data.files[0].filename) {
        this.addFoldTemplateAtSubmit(data.files[0].filename);
      } else {
        if (data && data.error && data.error.message) {
          //this.notifier.notify('error', data.error.message);
        } else {
          //this.notifier.notify('error', 'Something went wrong!');
        }
      }
      this.templateForm.reset();
    };
  }

  get sf() {
    return this.templateForm.controls;
  }

  /**
   * Add fold template data to fold collection
   */
  addData(): void {
    if (this.templateForm.valid) {
      this.uploader.uploadAll();

    }
  }

  addFoldTemplateAtSubmit(imgName: string): void {
    this.templateForm.value.image = imgName;
    
   
    if (this.templateForm.valid) {
      this.data = this.templateForm.value;
      if (this.templateData && this.templateData.length === 1) {
        this.templateData = [this.data];
      } else if (this.templateData && this.templateData.length > 1) {
        this.templateData[this.templateData.length - 1] = this.data;
      }
      this.dataservice.sendTempData(this.templateData);
      this.isShowTemp = false;
    }
  }



  /**
   * Edit Fold Template Data to fold collection
   * @param index
   */
  editTempData(index: number): void {
    this.data = this.templateData[index];
    const tempData = Object.assign({}, this.data);
    this.imageSrc = "";
    if (tempData && tempData.image) {
      try {
        delete tempData.image;
      } catch (error) {
      }
    }
    this.templateForm.patchValue(tempData);
  }

  /**
   * Add More Fold Template Data In An Array
   */
  addMore(): void {
    this.templateForm.reset();
    this.filePreviewPath = '';
    this.data = { 'image': [] };
    this.isShowTemp = true;
    this.templateData.push(this.data);
  }

  /**
   * Delete fold template Data by id from an array
   * @param index
   */
  deleteTempData(index: number): void {
    this.templateData.splice(index, 1);
    this.dataservice.sendTempData(this.templateData);
  }

  /**
   * Send Data to fold component by sendTempData servie
   * @param index
   * @param data
   */
  updateData(index: number): any {
    this.templateData[index] = this.data;
    this.isIndex = -1;
    this.dataservice.sendTempData(this.templateData);
  }
  onImageSelect(event) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageSrc = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

}
