<div class="">
  <div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
    <div class="img-overly"></div>
    <div class="container">
      <div class="main-heading-top clearfix">
        <div class="main-user-info">
          <div class="main-user-img sdss">
            <div class="main-user-img-upload" hidden><input type="file" /><i class="fa fa-camera" aria-hidden="true"></i></div>
            <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
            <img  *ngIf="userData.image.type != 'avatar'" width="100%" (error)="handleImgError($event, item)" [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

          </div>
          <h2>{{userData.username}}</h2>
        </div>

        <div class="main-heading-right">
          <h1>Review</h1>
        </div>

        <div class="top-event-link">
          <ul>
            <li><a [ngClass]="{'active': tempSlug =='corporate-review'}" href="javascript.void(0)"
                routerLink="admin-event-review/corporate-review"><span><img
                    src="/assets/images/corporate-review-icon.png" alt="icon" /></span>
                Review by<br>
                Corporates </a> </li>
            <li><a [ngClass]="{'active': tempSlug =='exhibitors-review'}" href="javascript.void(0)"
                routerLink="admin-event-review/exhibitors-review"><span><img
                    src="/assets/images/exhibitors-review-icon.png" alt="icon" /></span> Review by<br>
                Exhibitors </a> </li>
          </ul>

        </div>

      </div>

    </div>
  </div>

  <div class="main-admin-search super-admin-review-search-bar">
    <div class="container clearfix">

      <form [formGroup]="eventReviewSearchForm">
        <div class="main-admin-search-user-box">
          <input type="text" value="" placeholder="Search" formControlName="eventName">
        </div>
        <div class="main-admin-search-button">
          <button class="outline-btn"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
        </div>
      </form>
    </div>
  </div>

  <div class="super-admin-section">
    <div class="container">
      <div *ngIf="adminReviewAttributes.pageItems && !adminReviewAttributes.pageItems.length" class="search-not-found">
       {{translatorJson.NO_RECORD_FOUND | translate}} </div>
      <div class="super-admin-review-row" *ngFor="let data of adminReviewAttributes.pageItems let idx = index">
        <div class="super-admin-review-top clearfix" [hidden]="(adminReviewAttributes.isIndex == idx)">
          <div class="review-event-img" >
            <img *ngIf="data.media && data.media.length" width="100%" [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + data.media[0]) | encodeUriComponent))" alt="" />
          </div>
          <div class="super-admin-review-top-txt">
            <h3>{{data.eventName | truncate:[43, '...'] | capitalize}}</h3>
            <div class="admin-exhibitors-review"> {{data.created | date: 'MMMM d, yyyy'}} </div>
            <div class="exhibitors-detail-link"><a id="review-detail" (click)="showViewExhibReviewDetail(data);adminReviewAttributes.isIndex =idx;" href="javascript:void(0)">View Detail</a> </div>
          </div>
          <div class="super-admin-review-user">{{data.companyName}}</div>
          <div *ngIf="tempSlug =='corporate-review'">
            <div class="super-admin-review-one">Exhibitor Review</div>
            <div class="super-admin-review-two">HFP Review</div>
            <div class="super-admin-review-three">Staff Review</div>
          </div>
        </div>
        <div *ngIf="adminReviewAttributes.isIndex ==idx">
          <div *ngIf="tempSlug =='exhibitors-review'; then exhibEventReviewTemp"></div>
        </div>
        <div *ngIf="adminReviewAttributes.isIndex ==idx">
          <div *ngIf="tempSlug =='corporate-review'; then corporateReviewTemp"></div>
        </div>
      </div>
      <div class="pagination__box" *ngIf="adminReviewAttributes.count > adminReviewAttributes.limits">
        <pagination [totalItems]="adminReviewAttributes.count" [rotate]="adminReviewAttributes.rotate" [maxSize]="adminReviewAttributes.maxSize" [itemsPerPage]="adminReviewAttributes.limits" (pageChanged)="pageChanged($event);"[(ngModel)]="currentPage"></pagination>
      </div>
    </div>

  </div>
</div>

<!--exhibitor event review detail template start -->
<ng-template #exhibEventReviewTemp>
  <div class="super-admin-review-btm" *ngIf="adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.id;">
    <div class="inquiries-form-close"><button (click)="closeViewExhibReviewDetail();adminReviewAttributes.isIndex = -1;"><img src="/assets/images/inquiries-close.png" alt=""></button></div>
    <div class="review-details-top clearfix">
      <div class="review-event-img" >
        <img *ngIf="adminReviewAttributes.itemArray.media && adminReviewAttributes.itemArray.media.length" width="100%" [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + adminReviewAttributes.itemArray.media[0]) | encodeUriComponent))" alt="" />
      </div>
      <div class="super-admin-review-top-txt">
        <h3>{{adminReviewAttributes.itemArray.eventName}}</h3>
        <div class="admin-exhibitors-review">{{adminReviewAttributes.itemArray.companyName}}</div>
        <div class="admin-exhibitors-review">{{adminReviewAttributes.itemArray.created | date: 'MMMM d, yyyy' }}</div>
        <div class="exhibitors-detail-link"><a (click)="closeViewExhibReviewDetail();adminReviewAttributes.isIndex = -1;" href="javascript:void(0)">Hide Detail</a> </div>
      </div>
    </div>
    <div class="review-tabs">
      <div class="panel-group" aria-multiselectable="true">
        <div class="panel panel-default" >
          <div class="panel-heading">
            <a class="collapsed" [ngClass]="{'collapse': showExhibStatus}" (click)="showExhibStatus = !showExhibStatus" aria-controls="collapseOne">How satisfied
              were you with each of the following:
            </a>
          </div>
          <div *ngIf="showExhibStatus">
            <div class="panel-body" *ngIf="adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.eventSatisfiedRate">
              <ul>
                <li>
                  <strong>Parking, Check-in, and Set-up
                  </strong>
                  <p>{{adminReviewAttributes.itemArray.eventSatisfiedRate.parkingCheckinSetup}}</p>
                </li>

                <li>
                  <strong>Friendliness and Availability of Corporate Staff
                  </strong>
                  <p>{{adminReviewAttributes.itemArray.eventSatisfiedRate.availCorporateStaff}}</p>
                </li>

                <li>
                  <strong>Positioning and Available Space for your Booth/Table
                  </strong>
                  <p>{{adminReviewAttributes.itemArray.eventSatisfiedRate.availabeSpace}}</p>
                </li>

                <li>
                  <strong>Overall Organization of the Health Fair
                  </strong>
                  <p>{{adminReviewAttributes.itemArray.eventSatisfiedRate.overAllHfp}}</p>
                </li>

                <li>
                  <strong>Level of Employee Attendance
                  </strong>
                  <p>{{adminReviewAttributes.itemArray.eventSatisfiedRate.levelOfEmpAttend}}</p>
                </li>

                <li>
                  <strong>Overall, how satisfied were you with this Event?
                  </strong>
                  <p>{{adminReviewAttributes.itemArray.eventSatisfiedRate.overSatisfied}}</p>
                </li>

                <li>
                  <strong>How likely are you to attend this event again?
                  </strong>
                  <p *ngIf="adminReviewAttributes.itemArray.eventLikeStatus == 1">Very Unlikely</p>
                  <p *ngIf="adminReviewAttributes.itemArray.eventLikeStatus == 2"> Unlikely</p>
                  <p *ngIf="adminReviewAttributes.itemArray.eventLikeStatus == 3">Neutral</p>
                  <p *ngIf="adminReviewAttributes.itemArray.eventLikeStatus == 4">Likely</p>
                  <p *ngIf="adminReviewAttributes.itemArray.eventLikeStatus == 5">Very Likely</p>
                </li>

                <li>
                  <strong>Approximately how many employees visited your booth?</strong>
                  <p>{{adminReviewAttributes.itemArray.approxEmpVisited}}</p>
                </li>

                <li>
                  <strong>Please provide additional Comments and Feedback regarding your overall evaluation of this event. Everything you say is important to us...and we listen!</strong>
                  <p>{{adminReviewAttributes.itemArray.eventCommentsFeedback}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--exhibitor event review detail template end -->

<ng-template #corporateReviewTemp>

  <div class="super-admin-review-btm" *ngIf="adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.id;">
    <div class="inquiries-form-close"><button (click)="closeViewExhibReviewDetail();adminReviewAttributes.isIndex = -1;"><img src="/assets/images/inquiries-close.png" alt=""></button></div>
    <div class="review-details-top clearfix">

      <div class="review-event-img" >
          <img *ngIf="adminReviewAttributes.itemArray.media && adminReviewAttributes.itemArray.media.length" width="100%" [src]="(imageCdn + 'w_100,h_100/' + ((imageBaseUrl + '/event/' + adminReviewAttributes.itemArray.media[0]) | encodeUriComponent))" alt="" />
        </div>

      <div class="super-admin-review-top-txt">
        <h3>{{adminReviewAttributes.itemArray.eventName}}</h3>
        <div class="admin-exhibitors-review">{{adminReviewAttributes.itemArray.companyName}}</div>
        <div class="admin-exhibitors-review">{{adminReviewAttributes.itemArray.created | date: 'MMMM d, yyyy'}}</div>
        <div class="exhibitors-detail-link"><a (click)="closeViewExhibReviewDetail();adminReviewAttributes.isIndex = -1;" href="javascript:void(0)">Hide Detail</a> </div>
      </div>
    </div>
    <!-- review tab start-->
    <div class="review-tabs">
      <div class="panel-group">
        <div class="panel panel-default">
          <div class="panel-heading">
            <a class="collapsed"  [ngClass]="{'collapse': showExhibStatus && adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.exhibReview}" href="javascript:void(0)" (click)="showExhibStatus = !showExhibStatus;showHfpStatus = false;showSummarytatus = false">Exhibitor Reviews</a>
          </div>
          <div>
            <div class="panel-body"
              *ngIf="showExhibStatus && adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.exhibReview">
              <ul>
                <li>
                  <strong>Do you feel the exhibitors arrived on time and were properly set up before the start of the event?</strong>
                  <p>{{adminReviewAttributes.itemArray.exhibReview.exhibArrivedOnTime}}</p>
                </li>
                <li>
                  <strong>Do you feel the exhibitors provided useful information in regard to the health and wellness of your employees?</strong>
                  <p>{{adminReviewAttributes.itemArray.exhibReview.exhibProvideUsefulInfo}}</p>
                </li>
                <li>
                  <strong>Do you feel the exhibitors conducted themselves in a professional manner while at the event?</strong>
                  <p>{{adminReviewAttributes.itemArray.exhibReview.exhibProfessionalManner}}</p>
                </li>
                <li>
                  <strong>Did any exhibitor practice hard sales tactics which made employees uncomfortable?</strong>
                  <p>{{adminReviewAttributes.itemArray.exhibReview.exhibPracticeHard}}</p>
                </li>
                <li>
                  <strong>If yes, please tell us who:</strong>
                  <p>{{adminReviewAttributes.itemArray.exhibReview.aboutExhibForUncomfort}} </p>
                </li>
                <li>
                  <strong>Please provide additional Comments and Feedback regarding the Exhibitors:</strong>
                  <p>{{adminReviewAttributes.itemArray.exhibReview.additionalFeedback}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>



        <div class="panel panel-default">

          <div class="panel-heading">
            <a class="collapsed" [ngClass]="{'collapse': showHfpStatus && adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.hfpReview}" (click)="showExhibStatus = false ;showHfpStatus = !showHfpStatus;showSummarytatus = false">HFP Reviews</a>
          </div>
          <div>
            <div class="panel-body"
              *ngIf="showHfpStatus && adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.hfpReview">
              <ul>
                <li>
                  <strong>Were you happy with the selection of categories from which you could choose exhibitors?</strong>
                  <p>{{adminReviewAttributes.itemArray.hfpReview.happyWithSelection}}</p>
                </li>

                <li>
                  <strong>Do you feel that the exhibitors properly represented the categories, topics, and/or services in which they were invited to represent?</strong>
                  <p>{{adminReviewAttributes.itemArray.hfpReview.exhibProperlyRespond}}</p>
                </li>

                <li>
                  <strong>Do you feel that Health Fairs Plus kept you updated on our progress while planning your event?</strong>
                  <p>{{adminReviewAttributes.itemArray.hfpReview.hfpKeptUpdate}}</p>
                </li>
                <li>
                  <strong>Did Health Fairs Plus provide all the services in which we committed to provide? (Including any items you requested such as exhibitor lists, biometric screenings, food, games, special items, etc.)</strong>
                  <p>{{adminReviewAttributes.itemArray.hfpReview.hfpProvideAllService}}</p>
                </li>
                <li>
                  <strong>If not, what did we miss?</strong>
                  <p>{{adminReviewAttributes.itemArray.hfpReview.anyMissingHfpService}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="panel panel-default">

          <div class="panel-heading">
            <a class="collapsed" [ngClass]="{'collapse': showSummarytatus && adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.onSiteReview}" (click)="showExhibStatus = false ; showHfpStatus = false; showSummarytatus = !showSummarytatus;">Staff Reviews</a>
          </div>
          <div>
            <div class="panel-body" *ngIf="showSummarytatus && adminReviewAttributes.itemArray && adminReviewAttributes.itemArray.onSiteReview">
              <ul>
                <li>
                  <strong>If Health Fairs Plus staff was assigned to your event, did our staff arrive prepared and at least one hour prior to your event?</strong>
                  <p>{{adminReviewAttributes.itemArray.onSiteReview.hfpStaffAssigned}}</p>
                </li>

                <li>
                  <strong>IF STAFF WAS ASSIGNED TO ATTEND, did our staff greet exhibitors and help them find their tables?</strong>
                  <p>{{adminReviewAttributes.itemArray.onSiteReview.staffGreetAndHelp}}</p>
                </li>

                <li>
                  <strong>Did our staff members conduct themselves in a professional manner at all times?</strong>
                  <p>{{adminReviewAttributes.itemArray.onSiteReview.staffMemberProfessional}}</p>
                </li>
                <li>
                  <strong>If not, what did we miss?</strong>
                  <p>{{adminReviewAttributes.itemArray.onSiteReview.anyUnprofessionalFeed}}</p>
                </li>
                <li>
                  <strong>Did our staff members follow all the rules set forth by your company including rules regarding parking, check-in, and security?</strong>
                  <p>{{adminReviewAttributes.itemArray.onSiteReview.staffFollowRules}}</p>
                </li>
                <li>
                  <strong>If no, please explain</strong>
                  <p>{{adminReviewAttributes.itemArray.onSiteReview.staffNotFollowRules}} </p>
                </li>

                <li>
                  <strong>Please provide additional Comments and Feedback regarding Health Fairs Plus and Staff:</strong>
                  <p>{{adminReviewAttributes.itemArray.onSiteReview.additionalFeedback}} </p>
                </li>

                <li>
                  <strong>How would you evaluate the outcome of your event?</strong>
                  <p>{{adminReviewAttributes.itemArray.overallSummary.evaluationOutcome}}</p>
                </li>

                <li>
                  <strong>If not, what did we miss?</strong>
                  <p>{{adminReviewAttributes.itemArray.overallSummary.evaluationOutcomeMiss}} </p>
                </li>

                <li *ngIf="adminReviewAttributes.itemArray.overallSummary">
                  <strong>How would you evaluate the services of Health Fairs Plus?</strong>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpEvalService && adminReviewAttributes.itemArray.overallSummary.hfpEvalService.couldNotDone">
                    I couldn't have done it without you.</p>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpEvalService && adminReviewAttributes.itemArray.overallSummary.hfpEvalService.veryHelpFul">
                    Very Helpful, we made a great team.</p>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpEvalService && adminReviewAttributes.itemArray.overallSummary.hfpEvalService.helpFulBut">
                    Helpful, but we would like you to do more.</p>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpEvalService && adminReviewAttributes.itemArray.overallSummary.hfpEvalService.notQuitHelpFul">
                    Not quite as helpful as I expected</p>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpEvalService && adminReviewAttributes.itemArray.overallSummary.hfpEvalService.helpOut">
                    You helped out? I did not even notice!</p>
                </li>

                <li *ngIf="adminReviewAttributes.itemArray.overallSummary">
                  <strong>Would you use our services again?</strong>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain && adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain.yes">
                    Yes, absolutely!</p>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain && adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain.yesFewModifi">
                    Yes, with a few modifications.</p>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain && adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain.convinceMe">
                    You would have to convince me</p>
                  <p
                    *ngIf="adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain && adminReviewAttributes.itemArray.overallSummary.hfpServiceAgain.no">
                    No, probably not</p>
                </li>

                <li>
                  <strong>Please provide additional Comments and Feedback regarding your overall evaluation of this
                    event. Everything you say is important to us...and we listen!
                  </strong>
                  <p>{{adminReviewAttributes.itemArray.overallSummary.additionalFeedback}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- review tab end-->
  </div>
</ng-template>
