import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as qs from 'qs';

// Import ITaxonomy type interface.
import { ITaxonomy } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {
  // Define the class property.
  private url: string = environment.apiUrl + '/taxonomies';
  constructor(private http: HttpClient) { }
  /**
   * This service add Taxonomy
   */

  addTaxonomy(taxonomyObj: ITaxonomy): Observable<ITaxonomy> {
    return this.http.post<ITaxonomy>(this.url, taxonomyObj);
  }

  /**
   * This service return total cound of Taxonomy
   */
  getTaxonomyList(taxonomyObj: object): Observable<[]> {
    return this.http.get<[]>(this.url + '?' + qs.stringify(taxonomyObj));
  }
  
  /**
   * This service return total cound of Taxonomy
   */
  getTaxonomyListNew(taxonomyObj: object): Observable<[]> {
    //return this.http.post<[]>(this.url + '/showdata?' + qs.stringify(taxonomyObj));
    return this.http.post<[]>(this.url+'/showdata', taxonomyObj);
  }

  /**
 * This service return  state list
 */
  getStateList(taxonomyObj: object): Observable<[]> {
    return this.http.get<[]>(environment.apiUrl + '/states' + '?' + qs.stringify(taxonomyObj));
  }

  /**
 * This service return  state list
 */
  getCity(taxonomyObj: object): Observable<[]> {
    return this.http.get<[]>(environment.apiUrl + '/cities' + '?' + qs.stringify(taxonomyObj));
  }



  /**
   * This service return total count of Taxonomy
   */
  getTaxonomyCount(taxonomyObj: object): Observable<any> {
    return this.http.get<any>(this.url + '/count?' + qs.stringify(taxonomyObj));
  }

  /**
   * this service update Taxonomy.
   */
  updateTaxonomy(id: string, taxonomyObj: ITaxonomy): Observable<ITaxonomy> {
    return this.http.patch<ITaxonomy>(this.url + '/' + id, taxonomyObj);
  }

  /**
   * this service update all Taxonomy.
   */
  updateAllTaxonomy(query: Object, taxonomyObj: ITaxonomy): Observable<ITaxonomy> {
    return this.http.patch<ITaxonomy>(this.url + '?' + qs.stringify( query), taxonomyObj);
  }
  /**
   * this service get Taxonomy using id.
   */
  getTaxonomyById(id: string): Observable<ITaxonomy> {
    return this.http.get<ITaxonomy>(this.url + '/' + id);
  }
}
