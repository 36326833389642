import { Component, Injector, Input, OnInit, TemplateRef } from "@angular/core";
import { AppComponent } from "../app.component";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { environment } from "../../environments/environment";
import { LoginService } from "../login/login.service";
import { MenuService } from "../menu/menu.service";
import { SharedService } from "../shared.service";
import { SignupService } from "../signup/signup.service";
import { ProfileService } from "../profile/profile.service";
import * as CryptoJS from "crypto-js";
import { CredentialByAdmin, IMenu } from "../client-schema";
// Handle the global property
import { Globals } from "../globals";
import { NotifierService } from "angular-notifier";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FoldService } from "../fold/fold.service";
import { FoldTypeService } from "../fold-type/fold-type.service";
import { GetRouterLinkService } from "../services/getrouter-link.service";
import { PageService } from "../page/page.service";
import { MenuFormConstant } from "../menu/MENU_LIST";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  host: {
    "(window:scroll)": "onScroll($event)",
  },
})
export class HeaderComponent {
  @Input() activePagePermalink : string;

  hostEvent: boolean = true;
  attendEvent: boolean = false;
  myVar = false;

  isUserLoggedIn = false;
  isScrolled = false;
  submitted: boolean = false;
  currPos = 0;
  startPos = 0;
  passwordForm: FormGroup;
  hideLogoutButton: boolean = true;
  changePos = 100;
  loginUser: any;
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  acl: any;
  isShow: boolean = false;
  allPage: any = [];
  userRole: number = 0;
  totalMszCounts: any;
  modalRef: BsModalRef;
  foldTypeList = [];
  foldList = [];
  selectedRouterLinkIndex: number = -1;
  permalink: string = "";

  layoutSubMenu = [
    {
      subMenuName: "Pages",
      url: "page",
    },
    {
      subMenuName: "Folds",
      url: "fold",
    },
  ];

  settingsSubMenu = [
    {
      subMenuName: "Email Template",
      url: "email-template",
    },
    {
      subMenuName: "Industries",
      url: "industries-list",
    },
    {
      subMenuName: "Servics",
      url: "screening-list",
    },
  ];

  membersSubMenu = [
    {
      subMenuName: "Exhibitors",
      url: "exhibitors",
    },
    {
      subMenuName: "Corporate User",
      url: "corporate-user",
    },
    {
      subMenuName: "Unregistered Exhibitors",
      url: "unregistered-exhibitor",
    }
  ]


  staticMenu = [
    {
      "id": "",
      "menuName": "menus",
      "menuType": "Header Menu",
      "menuSort": 7,
      "linkTo": {
        "type": "P",
        "page": "menu"
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "dashboard",
      "menuSort": 1,
      "menuType": "Header Menu",
      "linkTo": {
        "type": "P",
        "page": "dashboard"
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "events",
      "menuSort": 2,
      "menuType": "Header Menu",
      "linkTo": {
        "type": "P",
        "page": "event"
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "settings",
      "menuType": "Header Menu",
      "menuSort": 8,
      "linkTo": {
        "type": "L",
        "url": null
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
      "subMenu": [
        {
          subMenuName: "Email Template",
          url: "email-template",
        },
        {
          subMenuName: "Industries",
          url: "industries-list",
        },
        {
          subMenuName: "Services",
          url: "screening-list",
        },
      ]
    },
    {
      "id": "",
      "menuName": "layout",
      "menuType": "Header Menu",
      "menuSort": 6,
      "linkTo": {
        "type": "L",
        "url": null
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
      "subMenu": [
        {
          subMenuName: "Pages",
          url: "page",
        },
        {
          subMenuName: "Folds",
          url: "fold",
        },
      ]
    },
    {
      "id": "",
      "menuName": "payments",
      "menuType": "Header Menu",
      "menuSort": 5,
      "linkTo": {
        "type": "P",
        "page": "payment-management"
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "review",
      "menuType": "Header Menu",
      "menuSort": 9,
      "linkTo": {
        "type": "P",
        "page": "admin-event-review"
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "inquiries",
      "menuType": "Header Menu",
      "menuSort": 3,
      "linkTo": {
        "type": "P",
        "page": "event-inquiries"
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "members",
      "menuType": "Header Menu",
      "menuSort": 4,
      "linkTo": {
        "type": "L",
        "url": null
      },
      "auth": 1,
      "accessLevel": 0,
      "status": 1,
      "subMenu": [
        {
          subMenuName: "Exhibitors",
          url: "exhibitors",
        },
        {
          subMenuName: "Corporate User",
          url: "corporate-user",
        },
        {
          subMenuName: "Unregistered Exhibitors",
          url: "unregistered-exhibitor",
        }
      ]
    },
    {
      "id": "",
      "menuName": "find exhibitors",
      "menuType": "Header Menu",
      "menuSort": 0,
      "linkTo": {
        "type": "P",
        "page": "find-exhibitors"
      },
      "auth": 1,
      "accessLevel": 10,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "view events",
      "menuType": "Header Menu",
      "menuSort": 0,
      "linkTo": {
        "type": "P",
        "page": "event"
      },
      "auth": 1,
      "accessLevel": 20,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "view events",
      "menuType": "Header Menu",
      "menuSort": 0,
      "linkTo": {
        "type": "P",
        "page": "event"
      },
      "auth": 1,
      "accessLevel": 10,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "invitation request status",
      "menuType": "Header Menu",
      "menuSort": 0,
      "linkTo": {
        "type": "P",
        "page": "exhibitor-invitation"
      },
      "auth": 1,
      "accessLevel": 20,
      "status": 1,
    },
    {
      "id": "",
      "menuName": "review events",
      "menuType": "Header Menu",
      "menuSort": 0,
      "linkTo": {
        "type": "P",
        "page": "review-events"
      },
      "auth": 1,
      "accessLevel": 20,
      "status": 1,
    },
  ] as Partial<IMenu[]>;



  constructor(
    private comp: AppComponent,
    private authService: AuthService,
    public router: Router,
    public globals: Globals,
    private sharedService: SharedService,
    private loginService: LoginService,
    private signupService: SignupService,
    private profileService: ProfileService,
    private notifier: NotifierService,
    private fb: FormBuilder,
    private menuService: MenuService,
    private injector: Injector
  ) {
    this.acl = this.globals.acl;
    this.initAuthLogin();
    this.updateMenuListArray();
  }


  initAuthLogin() {
    this.authService.isLoggedOut.subscribe((res: boolean) => {
      if (res || localStorage.getItem("RememberMe")) {
        this.userRole = -1;
        this.hideLogoutButton = false;
      } else {
        this.hideLogoutButton = true;
      }
    });

    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        this.isUserLoggedIn = isLogin;
        if (this.isUserLoggedIn) {
          this.getLoginUser();
        }else{
          this.loginUser = ''
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnInit() {
    this.getUpdatedPageItems(true);
    this.backToSuperAdminPasswordForm();
  }

  ngOnChanges() {
    this.initAuthLogin();
  }

  updateMenuListArray() {
    this.menuService.menuItemsObservable.subscribe((options: { flag: IMenu, id: string }) => {
      this.getUpdatedPageItems(false);
      if (options.id) {
        this.updateMenuArray(options);
      } else {
        this.addNewMenu(options);
      }
    });
  }

  addNewMenu(options: { flag: IMenu }) {
    let index = this.staticMenu.findIndex((entry) => {
      return entry.menuName === options.flag.menuName
    })
    if (index === -1) {
      this.staticMenu.splice(0, 0, options.flag);
    }
  }

  updateMenuArray(options: { flag: IMenu, id: string }) {
    let index = this.staticMenu.findIndex((entry) => {
      return entry.id === options.id
    })
    if (index >= 0) {
      const menuId = options.id
      this.staticMenu[index] = { ...options.flag, ...{ id: menuId } };
    } 
  }


  getUpdatedPageItems(callOnlyOnce: boolean) {
    const pageService = this.injector.get(PageService);
    let where = {
      filter: {
        fields: {
          permalink: true,
          pageName: true,
          auth: true,
          foldId: true,
          accessLevel: true,
          status: true,
          id: true
        },
        where: {
          status: 1
        }
      },
    };
    pageService.getPageList(where).subscribe(
      (pageData: any) => {
        if (pageData && pageData.length) {
          this.allPage = pageData;
          if (callOnlyOnce) this.getMenuList(true);
        }
      },
      (err) => console.error(err)
    );
  }

  // #TODO: ##refactor 
  getMenuList(isLoggedIn: boolean) {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        },
        skip: 0,
      },
    };
    this.menuService.getMenuList(queryObj).subscribe(
      (data: any) => {
        if (isLoggedIn) {
          this.staticMenu.forEach((entry) => {
            data.push(entry)
          })
        }
        this.staticMenu = data.sort((firstEl: any, secondEl: any) => {
          return firstEl.menuSort - secondEl.menuSort;
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  backToSuperAdminPasswordForm() {
    this.passwordForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      rememberMe: [true, []],
    });
  }

  getFoldList() {
    let where = {
      filter: {
        fields: {
          foldName: true,
          foldTypeId: true,
          auth: true,
          data: true,
          id: true,
        },
        where: {
          status: 1,
        },
      },
    };
    this.injector.get(FoldService).getFoldList(where).subscribe((foldRes) => {
      this.foldList = foldRes;
    }, (err) => {
      console.error(err);
    })

  }

  getFoldTypeList() {
    const queryObj: any = {
      filter: {
        where: {
          status: { inq: [1, 0] },
        }
      }
    };
    this.injector.get(FoldTypeService).getFoldTypeList(queryObj)
      .subscribe(data => {
        this.foldTypeList = data
      }, (err) => {
        console.error(err);
      });
  }


  getLoginUser() {
    this.loginService.getCurrentUser().subscribe(
      (user) => {
        this.loginUser = user;
        this.sharedService.setSession(user);
        this.userRole = this.loginUser.role;
        // this.getMenu(this.loginUser.role);
        const queryObj = {
          mszReceiverUpdteStatus: 1,
          receiverId: this.loginUser.id,
          status: 1,
        };
        this.profileService.getProfileMessageCounts(queryObj).subscribe(
          (data) => {
            this.totalMszCounts = data.count;
            this.sharedService.messageTemplateData$.subscribe((data) => {
              // console.log(data)
              if (typeof data === "number") {
                this.totalMszCounts = data;
              }
            });
          },
          (err) => { }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * back to the home page
   */

  backToTheSuperAdminPage() {
    if (localStorage.getItem("Em")) {
      const em = CryptoJS.AES.decrypt(
        localStorage.getItem("Em"),
        "q.[;a27ms.292'xs"
      ).toString(CryptoJS.enc.Utf8);
      let key = "key 4 admin1234";

      let queryObj: CredentialByAdmin = {
        email: CryptoJS.AES.encrypt(em, key).toString(),
        rememberMe: true,
      };
      this.loginService.userLoginByAdmin(queryObj).subscribe(
        (res) => {
          this.authService.masqueradeUserRoleSubject.next(null);
          localStorage.removeItem("RememberMe");
          this.authService.loggedOutSource.next(false);
          this.userRole = -1;
          this.hideLogoutButton = true;
          this.router.navigateByUrl("/dashboard")
        },
        (err) => {
          console.log(err.error);
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    }
  }
  /**
   * This is used to logout form and clear all cache and web local storage and redirect at home page
   */
  userLogout() {
    this.authService.clear();
    this.router.navigateByUrl("/");
    this.userRole = -1;
    localStorage.clear();
    this.loginUser = ''
    this.comp.userData = [];
    // this.getMenuList(false)
  }

  /*
   * Method for Ng class for app component
   */
  public mobRes(event): void {
    this.comp.myVarTest = this.comp.myVarTest ? false : true;
    this.myVar = this.myVar ? false : true;
    // this.router.navigate(['/signup'], { queryParams: { page: val } });
  }

  /*On scroll*/
  onScroll(evt) {
    //window object can be wrapper in a service but for now we directly use it
    this.currPos =
      (window.pageYOffset || evt.target.scrollTop) -
      (evt.target.clientTop || 0);
    if (this.currPos >= this.changePos) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  signTabToggle(toggleName) {
    this.signupService.signTabToggle = toggleName;
  }

  getRouterLinkFromPageItems(options: { [key: string]: any }) {
    this.selectedRouterLinkIndex = options.index
    if (options.linkTo.url && options.linkTo.type === MenuFormConstant.MENU_LINK_FLAG) {
      this.menuService.setUrlToNavigateLinkToNextPage(options.linkTo.url);
    } else {
      /* # TODO: ## It will be handle after fold type functionality
      *if (options.menuName.toLowerCase() === 'review') {
        this.router.navigate(['/admin-event-review/corporate-review']);
      } else {
        this.injector.get(GetRouterLinkService).getRouterLinkFromPageItemsService({
          linkTo: options.linkTo,
          pageItems: this.allPage,
          foldList: this.foldList,
        });
      } */

      this.injector.get(GetRouterLinkService).getRouterLinkFromPageItemsService({
        linkTo: options.linkTo,
        pageItems: this.allPage,
        foldList: this.foldList,
      });
    }
  }

  goToSubMenu(subMenuName: string) {
    this.router.navigate([subMenuName])
  }

  getSubMenuArray(options: { [key: string]: any }) {
    var fn;
    let subMenuArray = {
      "layout": () => {
        return this.layoutSubMenu
      },
      'settings': () => {
        return this.settingsSubMenu
      },
      'members': () => {
        return this.membersSubMenu
      },
      'default': () => {
        return []
      }
    }

    if (subMenuArray[options.menuName]) {
      fn = subMenuArray[options.menuName]
    } else {
      fn = subMenuArray['default'];
    }
    return fn()
  }
}
