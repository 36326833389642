<div class="main-heading" style="background-image:url(/assets/images/dashboard.jpg);">
    <div class="img-overly"></div>
    <div class="container" >
      <div class="main-heading-top clearfix">
        <div class="main-user-info">
          <div class="main-user-img">
            <div class="main-user-img-upload" hidden>
              <input type="file" />
              <i class="fa fa-camera" aria-hidden="true"></i></div>
            <img *ngIf="userData.image.type == 'avatar'" src="/assets/images/avatars/{{userData.image.name}}" />
            <img  *ngIf="isUploadedImageCdnImage()" width="100%"  [src]="(imageCdn + 'w_151,h_151/' + ((imageBaseUrl + '/users/' + userData.image.name) | encodeUriComponent))" alt="" />

          </div>
           <h2 *ngIf="userData">{{userData.username}}</h2>
        </div>
        <div class="main-heading-right">
          <h1>Dashboard</h1>
        </div>
      </div>
    </div>
  </div>
<div class="main-dashboard set-dashboard">
    <div class="container">
      <div class="clearfix row">

     <!-------------------Start Events Section ----------------->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="ds-bg event-dashboard-box">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left">
                <h4>Events <span>{{totalEvents}}</span></h4>
              </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/event">View All</a> </div>
              </div>
            </div>
            <div class="m-dashboard-icon"> <img src="/assets/images/dashboard-event-icon.png" alt="" /> </div>
            <div class="box-btm-row clearfix" *ngIf="dashboardDetail && dashboardDetail.eventData">

                <ul>
                    <li  *ngFor="let item of dashboardDetail.eventData;  let idx = index" >
                        <a href="javascript:void(0)" *ngIf="item._id === 1" (click)="goToWithStatus(1,'event')"> <strong>{{item.count}}</strong> Approved </a>
                        <a href="javascript:void(0)" *ngIf="item._id === 0" (click)="goToWithStatus(0,'event')"> <strong>{{item.count}}</strong> Pending </a>
                        <a href="javascript:void(0)" *ngIf="item._id === 2" (click)="goToWithStatus(2,'event')"> <strong>{{item.count}}</strong> Declined </a>
                    </li>

                  </ul>
            </div>
          </div>
        </div>
     <!-------------------End Events Section ----------------->

      <!-------------------Start Exhibitors Section ----------------->

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="ds-bg exhibitors-dashboard-box">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left">
                <h4>Exhibitors <span>{{totalExhib}}</span></h4>
              </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/exhibitors">View All</a> </div>
              </div>
            </div>
            <div class="m-dashboard-icon"> <img src="/assets/images/exhibitors-icon.png" alt="" /> </div>
            <div class="box-btm-row clearfix" *ngIf="dashboardDetail && dashboardDetail.exhibitorData">
              <ul>
                <li  *ngFor="let item of dashboardDetail.exhibitorData;  let idx = index" >
                    <a href="javascript:void(0)" *ngIf="item._id === 1" (click)="goToWithStatus(1,'exhibitors')"> <strong>{{item.count}}</strong> Approved </a>
                    <a href="javascript:void(0)" *ngIf="item._id === 0" (click)="goToWithStatus(0,'exhibitors')"> <strong>{{item.count}}</strong> Pending </a>
                    <a href="javascript:void(0)" *ngIf="item._id === 2" (click)="goToWithStatus(2,'exhibitors')"> <strong>{{item.count}}</strong> Declined </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-------------------End Exhibitors Section ----------------->
      </div>
      <div class="clearfix row">
        <!-------------------Start Corporate Section ----------------->
        <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="dashboardDetail">
          <div class="ds-bg corporate-users-dashboard-box">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left">
                <h4>Corporate Users <span>{{totalCorporate}}</span></h4>
              </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/corporate-user">View All</a> </div>
              </div>
            </div>
            <div class="m-dashboard-icon"> <img src="/assets/images/corporate-users-icon.png" alt="" /> </div>
            <div class="box-btm-row clearfix" *ngIf="dashboardDetail.corporateData">
              <ul>
                <li *ngFor="let item of dashboardDetail.corporateData;  let idx = index" >
                  <a href="javascript:void(0)" *ngIf="item._id === 1" (click)="goToWithStatus(1,'corporate-user')"> <strong>{{item.count}}</strong> Approved </a>
                  <a href="javascript:void(0)" *ngIf="item._id === 0" (click)="goToWithStatus(0,'corporate-user')"> <strong>{{item.count}}</strong> Pending </a>
                  <a href="javascript:void(0)" *ngIf="item._id === 2" (click)="goToWithStatus(2,'corporate-user')"> <strong>{{item.count}}</strong> Declined </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-------------------End Corporate Section ----------------->

        <!-------------------Start Menu Section ----------------->

        <div class="col-lg-3 col-md-3 col-sm-3">
          <div class="ds-bg page-dashboard-box menus-dashboard-box">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left dash-menu-icon"> <img src="/assets/images/menu-icon.png" alt="" /> </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/menu">View All</a> </div>
              </div>
            </div>
            <div class="m-dashboard-txt" *ngIf="dashboardDetail && dashboardDetail.menuCounts">
              <strong *ngIf="dashboardDetail.menuCounts && dashboardDetail.menuCounts.count">{{dashboardDetail.menuCounts.count}}</strong> Menus </div>
          </div>
        </div>

        <!-------------------End Menu Section ----------------->


        <!-------------------Start Exhibitor Section ----------------->
        <div class="col-lg-3 col-md-3 col-sm-3">
          <div class="ds-bg page-dashboard-box  dashboard-unregistered-exhibitors">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left dash-menu-icon"> <img src="/assets/images/exhibitor-list-icon-1.png" alt="" /> </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/unregistered-exhibitor">View All</a> </div>
              </div>

            </div>

            <div class="m-dashboard-txt" *ngIf="dashboardDetail && dashboardDetail.invitationCounts">
              <strong>{{dashboardDetail.invitationCounts.count}}</strong> Unregistered
              Exhibitors </div>
          </div>
        </div>
        <!-------------------End Menu Section ----------------->
      </div>
      <div class="clearfix row">
        <!-------------------Start Page Section ----------------->

        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="ds-bg page-dashboard-box">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left dash-menu-icon"> <img src="/assets/images/page-icon.png" alt="" /> </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/page">View All</a> </div>
              </div>
            </div>
            <div class="m-dashboard-txt" *ngIf="dashboardDetail && dashboardDetail.pageCounts">
              <strong>{{dashboardDetail.pageCounts.count}}</strong> Pages </div>
          </div>
        </div>
        <!-------------------End Menu Section ----------------->

        <!-------------------Start Fold Section ----------------->

        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="ds-bg fold-dashboard-box">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left dash-menu-icon"> <img src="/assets/images/fold-icon.png" alt="" /> </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/fold">View All</a> </div>
              </div>
            </div>
            <div class="m-dashboard-txt" *ngIf="dashboardDetail && dashboardDetail.foldCounts">
              <strong>{{dashboardDetail.foldCounts.count}}</strong> Folds </div>
          </div>
        </div>
        <!-------------------End Menu Section ----------------->

        <!-------------------Start Widgets Section ----------------->
        <div class="col-lg-4 col-md-4 col-sm-4" hidden>
          <div class="ds-bg menu-dashboard-box">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left dash-menu-icon"> <img src="/assets/images/dash-widgets-icon.png" alt="" /> </div>
              <div class="box-top-row-right">
                <div class="view-all-btn"><a href="" routerLink="/widget">View All</a> </div>
              </div>
            </div>
            <div class="m-dashboard-txt"> <strong>{{dashboardDetail.widgetCounts?dashboardDetail.widgetCounts.count:0}}</strong> Widgets </div>
          </div>
        </div>
        <!-------------------End Menu Section ----------------->
      </div>
      <div class="clearfix row">
        <!-------------------Start Payment Section ----------------->



        <div class="col-lg-8 col-md-8 col-sm-8">
          <div class="ds-bg payment-dashboard-box payment-chartbox">
            <div class="box-top-row clearfix">
              <div class="box-top-row-left"> </div>
              <div class="box-top-row-right ">
                <div class="view-all-btn"><a href="" routerLink="/payment-management">View All</a> </div>
              </div>
            </div>
            <div class="payment-dashboard-mid clearfix">
              <div class="payment-ul">
                <ul>
                  <li><a href="javascript:void(0)" (click)="goToWithStatus(1,'payment-management')"><i><img src="/assets/images/sm-paid-icon.png" alt="" /></i> Paid <strong>{{dashboardDetail.paidCounts? dashboardDetail.paidCounts.count:0}}</strong></a> </li>
                  <li><a href="javascript:void(0)" (click)="goToWithStatus(2,'payment-management')"><i><img src="/assets/images/sm-peding-icon.png" alt="" /></i> Pending <strong>{{dashboardDetail.pendingCounts? dashboardDetail.pendingCounts.count:0}}</strong></a> </li>
                  <li><a href="javascript:void(0)" (click)="goToWithStatus(3,'payment-management')"><i><img src="/assets/images/sm-cancel-paid-icon.png" alt="" /></i> Cancel Before Paid <strong>{{dashboardDetail.cancelBeforePaidCounts?dashboardDetail.cancelBeforePaidCounts.count:0}}</strong></a> </li>
                  <!-- <li><a href="javascript:void(0)"><i><img src="/assets/images/sm-payment-overdue-icon.png" alt="" /></i> Payment Overdue <strong>{{dashboardDetail.overDueCounts.count}}</strong></a> </li>
                  <li><a href="javascript:void(0)"><i><img src="/assets/images/sm-paid-but-cancel-icon.png" alt="" /></i> Paid But Cancel <strong>180</strong></a> </li>
                  <li><a href="javascript:void(0)"><i><img src="/assets/images/sm-approval-paid-icon.png" alt="" /></i> Need Approval Paid <strong>1,820</strong></a> </li> -->
                  <li><a href="javascript:void(0)" (click)="goToWithStatus(6,'payment-management')"><i><img src="/assets/images/sm-payment-unspecfied-icon.png" alt="" /></i> Payment Unspecified <strong>{{dashboardDetail.unspecifiedCounts?dashboardDetail.unspecifiedCounts.count:0}}</strong></a> </li>
                </ul>
              </div>
              <div class="payment-chart">  <div #container></div></div>
            </div>
          </div>
        </div>
        <!-------------------End Menu Section ----------------->
      </div>

      </div>
    </div>


