import { Injectable } from '@angular/core';
import * as qs from 'qs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import * as moment from "moment";
// Import IIndusties type interface.
import { Credential } from '../client-schema';
// Import environment config file.
import { environment } from 'src/environments/environment';
import { DatePipe } from "@angular/common";
import { NotifierService } from "angular-notifier";
import { SystemConstants } from '../constants/system.constants';
import { FormControl, FormGroup } from '@angular/forms';
import { EventDetail,EventInviteDetails } from "src/app/interface/event-details/event_detail.interface";
import {EventUserDetails} from './model/event-user-details';
import { ExhibitorEventInvite} from './model/exhibitor-event-invite';
import { EventUnregisterUserTemplateData } from './model/event-unregister-user-template-data';
import * as translateJson from '../../assets/i18n/en.json'

@Injectable({
  providedIn: 'root'
})
export class EventService {

  // Define the class property.
  private tempEventUrl: string = environment.apiUrl + '/temp-events';
  private eventLocalUrl: string = environment.apiUrl + '/local-events';

  private eventReqUrl: string = environment.apiUrl + '/event-requests';
  private eventRegionUrl: string = environment.apiUrl + '/regions';

  private eventPaymentManageUrl: string = environment.apiUrl + '/payment-managements';
  private eventPaymentInvoice: string = environment.apiUrl + '/payment-invoices';
  //exhibitor-invitations/createUser
  private exhibitorInvitation: string = environment.apiUrl + '/exhibitor-invitations';

  private userBaseUrl: string = environment.apiUrl + '/users'; 
  translateJson: typeof translateJson = Object.assign({}, (translateJson as any).default);
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private datePipe: DatePipe,
    private notifier: NotifierService,
  ) { 

  }

  ///start exhibitor invitation

  getUserById(id: string): Observable<any> {
    return this.http.get<any>(this.userBaseUrl + '/' + id);
  }

  findUserList(userObj: object): Observable<any> {
    return this.http.get<[]>(this.userBaseUrl + '?' + qs.stringify(userObj));
  }

  createUnregisteredUser(userObj: any): Observable<any> {
    return this.http.post<any>(this.exhibitorInvitation + '/createUser', userObj);
  }
  createExhibitor(exhibitorObj: any): Observable<any> {
    return this.http.post<any>(this.exhibitorInvitation, exhibitorObj);
  }
  getUnregisterData(eventObj): Observable<any> {
    return this.http.get<any>(this.exhibitorInvitation + '/' + eventObj);
  }
  updateUnregister(id: string, unregisterObj: object): Observable<any> {
    return this.http.patch<any>(this.exhibitorInvitation + '/' + id, unregisterObj);
  }

 /**
  * This service return local event
  */
  getLocalEventListData(eventObj: object): Observable<any> {
    return this.http.get<any>(this.eventLocalUrl + '?' + qs.stringify(eventObj));
  }
  
  getLocalEventListDataByParam(eventObj: object): Observable<any> {
    return this.http.get<any>(this.eventLocalUrl + '/getData?' + qs.stringify(eventObj));
  }

  /// end exhibitor invitation

  /**
   * this service add event to Temp Event collection and return event data.
   */

  addTepEvent(eventObj: any): Observable<any> {
    // console.log(eventObj);
    return this.http.post<any>(this.tempEventUrl, eventObj);
  }

  updateTempEvent(id: string, eventObj: object): Observable<any> {
    return this.http.patch<any>(this.tempEventUrl + '/' + id, eventObj);
  }
  /**
   * This service return event list from Temp collection
   */
  getTempEventList(eventObj: object): Observable<[]> {
    return this.http.get<[]>(this.tempEventUrl + '?' + qs.stringify(eventObj));
  }


  /**
   * this service add event to Local Event collection and return event data.
   */

  addLocalEvent(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventLocalUrl, eventObj);
  }

  /**
   * this service return total count of Local Event .
   */
  findLocalEventCount(queryObj: object): Observable<any> {
    return this.http.get<any>(this.eventLocalUrl + '/count?' + qs.stringify(queryObj));
  }
  findLocalEventCountSuperAdmin(queryObj: object): Observable<any> {
   // return this.http.get<any>(this.eventLocalUrl + '/countForSuperAdmin ?' + qs.stringify(queryObj));
    return this.http.post<any>(this.eventLocalUrl+ '/countForSuperAdmin', queryObj);
  }
  updateLocalEventCountForSuperAdmin(queryObj: object): Observable<any> {
     return this.http.get(this.eventLocalUrl+ '/updateCountForSuperAdmin', queryObj);
   }

  /**
   * This service return local event
   */
  getLocalEventList(eventObj: object): Observable<[]> {
    return this.http.get<[]>(this.eventLocalUrl + '?' + qs.stringify(eventObj));
  }
  getLocalEventListPast(eventObj: object): Observable<[]> {
    return this.http.get<[]>(this.eventLocalUrl + '?' + qs.stringify(eventObj));
  }


  /**
   * this service update Event using id.
   */
  updateEvent(id: string, eventObj: object): Observable<any> {
    return this.http.patch<any>(this.eventLocalUrl + '/' + id, eventObj);
  }


  /**
   * this service add request to EventRequest collection .
   */

  addRequestForEvent(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl, eventObj);
  }

  /**
   * this service get request to EventRequest collection .
   */

  getEventRequestForInvitation(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/findExhibitorEvents', eventObj);
  }
  
  getEventRequestForInvitationCount(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/findExhibitorEventsCount', eventObj);
  }

  
  getNearestExhibitor(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventLocalUrl + '/findNearestUser', eventObj);
  }



  
  /**
   * this service get request to Event counts for exhibitor .
   */

  exhibitorEventsCounts(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventLocalUrl + '/exhibitorEventsCounts', eventObj);
  }

  /**
   * this service update Event Req using id.
   */

  updateEventReqById(id: string, eventObj: object): Observable<any> {
    return this.http.patch<any>(this.eventReqUrl + '/' + id, eventObj);
  }

  updatePaymentById(id: string, paymentObj: object): Observable<any> {
    return this.http.patch<any>(this.eventPaymentManageUrl + '/' + id, paymentObj);
  }
  updatePaidPaymentById(id: string, paymentObj: object): Observable<any> {
    return this.http.patch<any>(this.eventPaymentInvoice + '/' + id, paymentObj);
  }

  deleteEventRequest(id: string): Observable<any> {
    return this.http.delete<any>(this.eventReqUrl + '/' + id, {});
  }

  /**
   * This service return exibitor event
   */
  getExhibitorEventList(eventObj: object): Observable<[]> {
    return this.http.post<any>(this.eventLocalUrl + '/findExhibitorEvents', eventObj);
  }

  /**
   * this service get request to getEventExibitorList .
   */

  getEventExibitorList(eventObj: any): Observable<any> {
    //return this.http.post<any>(this.eventReqUrl + '/findExhibitorsByEvent', eventObj);
    return this.http.get<[]>(this.eventReqUrl + '/findExhibitorsByEvent' + '?' + qs.stringify(eventObj));
  }
  getEventExibitorListCount(eventObj: any): Observable<any> {
    //return this.http.post<any>(this.eventReqUrl + '/findExhibitorsByEvent', eventObj);
    return this.http.get<[]>(this.eventReqUrl + '/findExhibitorsByEventCount' + '?' + qs.stringify(eventObj));
  }
  getEventExhibitor(eventObj: any): Observable<any> {
    //return this.http.post<any>(this.eventReqUrl + '/findExhibitorsByEvent', eventObj);
    return this.http.post<any>(this.eventReqUrl+"/findEventRequest", eventObj);
  }

 

  getEventRequestById(id: string): Observable<any> {
    return this.http.get<any>(this.eventReqUrl + '/' + id);
  }

  getEventRegion(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventRegionUrl + '/getRegionsById', eventObj);
  }

  getEventForCorporate(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventLocalUrl + '/findEventsForCorporate', eventObj);
  }

  /**
   * this service return total count of Local Event for Coroporate user .
   */
  findCountsEventsForCorporate(queryObj: object): Observable<any> {
    return this.http.post<any>(this.eventLocalUrl + '/countsEventsForCorporate', queryObj);
  }

  /**
   * this service return total count of Event Request for a particular event.
   */
  findEventReqCount(queryObj: object): Observable<any> {
    return this.http.get<any>(this.eventReqUrl + '/count?' + qs.stringify(queryObj));
  }

  /**
   * this service send email.
   */
  sendEmail(queryObj: object): Observable<any> {
    return this.http.post<any>(this.eventLocalUrl + '/emailForEvent', queryObj);
  }
  sendEmails(queryObj: object): Observable<any> {
    return this.http.post<any>(this.eventLocalUrl + '/emailForEvents', queryObj);
  }

  

  /**
   * This service is  used to get total req for events. 
   * @param eventObj any
   */
  eventListWithTotalReq(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/eventListWithRequest', eventObj);
  }

  /**
   * This service is  used to get Event Req Inquires For Admin. 
   * @param eventObj any
   */
  getEventReqInquiresForAdmin(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/eventReqInquiresForAdmin', eventObj);
  }
  getEventReqInquiresForAdminData(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/eventReqInquiresForAdminData', eventObj);
  }

  
  /**
   * This service is used to get Event Req Counts . 
   * @param eventObj any
   */
  getCountsEventReqInquiresForAdmin(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/countsEventReqInquiresForAdmin', eventObj);
  }

  /**
   * This service is  used to get req for payment Unspecified. 
   * @param eventObj any
   */
  eventListForPaymentUnspecified(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/eventPaymentUnspecified', eventObj);
  }

  eventPaymentCancelBeforePaid(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/eventPaymentCancelBeforePaid', eventObj);
  }

  
  /**
   * This service is  used to get counts for list Data payment Unspecified. 
   * @param eventObj any
   */
  countsPaymentUnspecifiedListData(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/countsPaymentUnspecifiedListData', eventObj);
  }

  countsCancelBeforePaid(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/countsCancelBeforePaid', eventObj);
  }

  
  
  /**
   * This service is  used to get counts for list Data payment Unspecified. 
   * @param eventObj any
   */
  countsPaymentUnspecifiedListDataForSearch(eventObj: any): Observable<any> {
    return this.http.post<any>(this.eventReqUrl + '/countsPaymentUnspecifiedListDataForSearch', eventObj);
  }
  /**
   * This service is  used crate record for exhibitor to add payment for that particular evnet. 
   * @param manageObj any
   * this manageObj object have eventId,memberId,reqId 
   */
  addEventPaymentToManage(manageObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentManageUrl, manageObj);
  }

  /**
   * This service is  used get couts as pending unspecified paid etc.. 
   * @param manageObj any
   * 
   */
  getCountsPaymentManagement(manageObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentManageUrl + '/countsPaymentManagement', manageObj);
  }

  /**
   * This service is  used get counts for paid events.
   * @param queryObj any
   *
   */
  getCountsForPaidEvents(queryObj: any): Observable<any> {
    return this.http.get<any>(this.eventPaymentInvoice + '/count?' + qs.stringify(queryObj));
  }

  /**
   * This service is  used get paid event list.
   * @param queryObj any
   *
   */
  getPaidEventsList(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentInvoice + '/getPaymentInvoice', queryObj);
  }
  markPaidByAdmin(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentInvoice + '/markBySuperAdmin', queryObj);
  }

  /**
   * This service is  used get paid event list.
   * @param queryObj any
   *
   */
  getPaymentInvoiceListForAdmin(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentInvoice + '/getPaymentInvoiceListForAdmin', queryObj);
  }

  /**
   * This service is  used get paid event list.
   * @param queryObj any
   *
   */
  getCountPaymentInvoiceForAdmin(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentInvoice + '/getCountPaymentInvoiceForAdmin', queryObj);
  }

  /**
   * This service is  used get pending payment user list.
   * @param queryObj any
   *
   */
  getPaymentPendingUserList(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentManageUrl + '/getPaymentPendingUserList', queryObj);
  }

  /**
   * This service is  used get pending overdue user list.
   * @param queryObj any
   *
   */
  getPaymentOverDueUserList(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentManageUrl + '/getPaymentOverDueUserList', queryObj);
  }

  /**
   * This service is  used get counts for overdue user list.
   * @param queryObj any
   *
   */
  getCountsPaymentOverDueUserList(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentManageUrl + '/getCountsPaymentOverDueUserList', queryObj);
  }

  /**
   * This service is used get pending payment list counts.
   * @param queryObj any
   *
   */
  getCountsPaymentPendingUserList(queryObj: any): Observable<any> {
    return this.http.post<any>(this.eventPaymentManageUrl + '/getCountsPaymentPendingUserList', queryObj);
  }
  sendInviteEmail(event:EventDetail, eventInvite:EventInviteDetails){
      let eventUser = new EventUserDetails(eventInvite)
      this.createUnregisteredUser(eventUser).subscribe(
        (createdUnregisteredUsers:EventUserDetails[]) => {
          if (createdUnregisteredUsers && createdUnregisteredUsers.length ? createdUnregisteredUsers : []) {
          let exhibitorInvitation = new ExhibitorEventInvite(eventInvite, event, this.datePipe)
            this.createExhibitor(exhibitorInvitation)
              .subscribe((createdExhibitorInvitation:ExhibitorEventInvite[]) => {
                createdExhibitorInvitation.forEach((createdExhibitor)=>{

               
              // createdUnregisteredUsers.forEach((eventCreatedUserDetails:EventUserDetails)=>{
                // if(createdExhibitor.errMsg){
                //   this.notifier.notify(SystemConstants.ERROR,eventCreatedUserDetails.errMsg);
                // }else{
              let unregisteredUser = new EventUnregisterUserTemplateData(createdExhibitor,this.datePipe,event)
              if (Number(eventInvite.amount) > SystemConstants.DEFAULT_ZERO) {
                    let urls1 = environment.sentEmailHost +
                      "/unregistered-event-request/" +
                      createdExhibitor.id +
                      "/" +
                      btoa("payment=1");
                      unregisteredUser.keys.AMOUNTDATA =
                      "Pay " +
                      eventInvite.amount +
                      " to participate in event.<br /><br />";
                      unregisteredUser.keys.URL = `<a href=${urls1} >Click Here</a>`
                    this.emailSends( unregisteredUser);
                } else {
                    unregisteredUser.keys.AMOUNTDATA = "";
                    let urls = environment.sentEmailHost +
                      "/unregistered-event-request/" +
                      createdExhibitor.id +
                      "/" +
                      btoa("payment=0");
                    unregisteredUser.keys.URL = `<a href=${urls} >Click Here</a>`
                    this.emailSends(unregisteredUser); 
                }
              // }
           });
          // })
          })
          }
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify(SystemConstants.ERROR, err.error.error.message);
          }
        }
        
      );
  }
  emailSends(emailTemplateData: EventUnregisterUserTemplateData) {
    this.sendEmail(emailTemplateData).subscribe(
      () => {
      },
      (err) => {
        if (err && err.error && err.error.error && err.error.error.message) {
        }
      }
    );
  }
getPdf(pdfName: any):any {
  const url = `${environment.apiUrl}/pdfs/${pdfName}`;
  return this.http.get(url)
}

}
